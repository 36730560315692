
import {Injectable} from '@angular/core';
import {RestAuthService} from './gen/rest-auth-service';
import { AuthService } from './auth/authservice.service';
import { OAuthService } from 'angular-oauth2-oidc';


@Injectable()
export class RestAuthImplService implements RestAuthService {

	constructor(public auth: AuthService, private oauthService: OAuthService) { }

	ensureAuth(): boolean {
		// if (!this.auth.isAuthenticated()) {
		//   this.oauthService.initImplicitFlow();
		//   return false;
		// }
		return true;
	}

	signOut(): void {
		// this._authService.redirectToSignOut();
	}




}
