import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {REST_CONFIG_TOKEN, RestConfigService} from '../rest-config-service';
import {Observable} from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import {isNullOrUndefined} from '../../tools';
import * as FileSaver from 'file-saver';
import { TerritoryVm,
	ApiPageResponse,
} from '../models';

@Injectable()
export class TerritoryService {

	constructor(
private _http: HttpClient,
@Inject(REST_CONFIG_TOKEN) private _configService: RestConfigService
	) {
	}

	public searchTerritorys: (
        SearchTerm?: string
        ) =>  Observable<TerritoryVm[]>
		= (
			SearchTerm?: string
		): Observable<TerritoryVm[]> => {
			let queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});

			if (!isNullOrUndefined(SearchTerm)) {
				queryParameters = queryParameters.append('SearchTerm', SearchTerm.toString());
			}

			const uri = '/api/territory/search';

			return this._http
				.get<TerritoryVm[]>(this._configService.apiBaseUrl() + uri,
					{ headers: headers, params: queryParameters  });
		};


}