<div class="party-detail">
  <div>{{data.allocate.partyAccountNumber}} - {{data.allocate.partyName}} <i class="fa fa-question-circle"
      matTooltip="The party may have changed when selecting a new contract line" *ngIf="contractLineChanged"></i></div>
  <div (click)="openContractSearchDialog()">Contract ref: <span>{{data.allocate.contractRef}}</span> <i
      class="fa fa-question-circle" matTooltip="The contract ref may have changed when selecting a new contract line"
      *ngIf="contractLineChanged"></i></div>
</div>
<form [formGroup]="allocationForm" (ngSubmit)="update(allocationForm.getRawValue())">
  <app-spinner [displayProgressSpinner]="loadingCloneEditAllocatedRequestDialog"
    [container]="loadingCloneEditAllocatedRequestDialogBox" #loadingCloneEditAllocatedRequestDialogSpinner>
  </app-spinner>
  <div #loadingCloneEditAllocatedRequestDialogBox>
    <div class="allocationForm">
      <div aria-label="Allocate?">
        <div mat-dialog-content class="dialog-input-container">
          <div class="row-container">
            <div class="small-margin-right">
              <mat-form-field style="margin: 0px 0px; max-width: 250px; padding-right: 10px">
                <input type="text" matInput formControlName="noOfClone" placeholder="Number Of Allocations to Clone"
                  #noOfCloneInput>
              </mat-form-field>
            </div>
          </div>
          <div class="row-container">
            <div class="small-margin-right">
              <mat-form-field style="margin: 0px 0px; max-width: 250px; padding-right: 10px">
                <input type="text" matInput formControlName="depot" [matAutocomplete]="depotAuto" placeholder="Depot"
                  #depotInput oninput="this.value = this.value.toUpperCase()">
              </mat-form-field>

              <mat-autocomplete #depotAuto="matAutocomplete" [displayWith]="displayDepot">
                <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
                <mat-option *ngFor="let depot of filteredDepots?.items " [value]="depot">
                  {{depot?.depotNumber + ' - ' + depot?.depotName}}</mat-option>
              </mat-autocomplete>
            </div>
            <mat-form-field>
              <mat-select formControlName="containerTypeId" placeholder="Container Type*">
                <mat-option *ngFor="let container of containerTypes" [value]="container.containerTypeId">
                  {{container.containerTypeName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <input matInput type="text" aria-label="container number" placeholder="Container Number" class="md-input"
                formControlName="containerNumber" autocomplete="off">
            </mat-form-field>
          </div>
          <div class="row-container">
            <mat-checkbox style="padding-bottom: 10px;" formControlName="isBooked">
              Booking Confirmed
            </mat-checkbox>
          </div>
          <div class="row-container shade">
            <app-date-time-picker placeholder="Pickup From Date & Time" formControlName="pickupDateTime"
              required="true">
            </app-date-time-picker>
            <app-date-time-picker placeholder="Pickup To Date & Time" formControlName="pickupToDateTime">
            </app-date-time-picker>
            <button type="button" mat-flat-button style="height:50px; width:190px;"
              [disabled]="!this.allocationForm.controls.pickupDateTime.value" (click)="getAvailableHauliers()"
              color="primary">Show Rates
            </button>

            <mat-form-field>
              <mat-select name="hpm" id="hpm" placeholder="Available rates" [(value)]="selectedHaulageMatrix"
                (selectionChange)="updateHaualgeRate($event)">
                <mat-option *ngFor="let r of haulageRates" [value]="r">
                  {{r.haulageRate}} &nbsp; {{r.currency}} / {{r.haulierName}} &nbsp; ({{r.haulierAccountNo}}) /
                  {{r.shippingLine}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <button type="button" mat-flat-button style="height: 50px; width: 50px; margin-right:10px;"
              [disabled]="haulageRates.length==0" (click)="fixHaulageRate()" color="primary">Fix</button>
          </div>

          <div class="row-container shade">
            <mat-form-field>
              <input type="text" matInput formControlName="haulier" #haulier [matAutocomplete]="haulierAuto"
                placeholder="Haulier" oninput="this.value = this.value.toUpperCase()">
              <button mat-button *ngIf="showHaulierClear(haulier)" type="button" matSuffix mat-icon-button
                aria-label="Clear" testName="haulierClear" (click)="haulierClear(haulier)">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-autocomplete #haulierAuto="matAutocomplete" [displayWith]="displayHaulier">
              <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
              <mat-option *ngFor="let haulier of filteredHauliers" [value]="haulier">{{haulier?.accountNumber}} -
                {{haulier?.name}}</mat-option>
            </mat-autocomplete>
            <mat-form-field>
              <input matInput type="number" aria-label="agreed haulage rate" placeholder="{{spotRateText}}"
                class="md-input" formControlName="agreedHaulageRate" autocomplete="off"
                (change)="updateHaulageRateDropdown($event)">
            </mat-form-field>
            <mat-form-field>
              <mat-select formControlName="agreedHaulageRateUomId" placeholder="Spot Rate UOM">
                <mat-option *ngFor="let uom of uoms" [value]="uom.id">
                  {{uom.code + ' - ' + uom.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div style="padding-top:10px;">
            <mat-checkbox [(checked)]="data.allocate.firstComeFirstServe" (change)="firstComeServeValueChange($event)"
              #firstComeFirstServeCheckBox>
              First Come First Serve
            </mat-checkbox>
          </div>

          <div class="row-container">
            <mat-form-field style="width: 220px;">
              <mat-select [disabled]="data.allocate.firstComeFirstServe === true" formControlName="appointmentId"
                (selectionChange)="selectAppointment($event.value);" placeholder="Available Appointments"
                [(ngModel)]="savedAppointmentId">
                <mat-option></mat-option>
                <mat-option *ngFor="let app of appointments" [value]="app.appointmentId">
                  {{app.startDate | momentUtcAsLocaleDate}} {{app.deliveryWindowStart}} - {{app.deliveryNumber}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <input matInput type="text" aria-label="customer apt ref" placeholder="Customer Apt Ref" class="md-input"
                formControlName="customerAptRef" autocomplete="off" maxlength="45">
            </mat-form-field>

            <app-date-time-picker [disabled]="editCustomerAppointmentDisabled === true"
              placeholder="Customer Apt From Date & Time" formControlName="customerAptDateTime"
              (change)="customerAppointmentFromDateChangedEvent($event)"></app-date-time-picker>
            <app-date-time-picker [disabled]="editCustomerAppointmentDisabled === true"
              placeholder="Customer Apt To Date & Time" formControlName="customerAptToDateTime"
              (change)="customerAppointmentToDateChangedEvent($event)"></app-date-time-picker>

            <mat-form-field>
              <input matInput type="text" aria-label="comments" placeholder="Comments" class="md-input"
                formControlName="appointmentComment" autocomplete="off" maxlength="800">
            </mat-form-field>
          </div>
          <div class="row-container shade">
            <mat-form-field>
              <textarea cdkTextareaAutosize matInput placeholder="Instructions" class="md-input"
                formControlName="instructions" maxlength="{{instructionsMaxlength}}"></textarea>
              <div class="md-errors-spacer"></div>
            </mat-form-field>
          </div>
          <app-additional-costs [maxCosts]="maxCosts" [uoms]="uoms" [costs]="costs" formControlName="additionalCosts">
          </app-additional-costs>
        </div>
      </div>
    </div>
    <div mat-dialog-actions class="buttons-row">
      <button mat-flat-button type="button" (click)="cancel()">Cancel</button>
      <button mat-flat-button type="submit" color="primary" [disabled]="isSubmit">Allocate</button>
    </div>
  </div>
</form>
