<div>
  <form [formGroup]="contractLineSearchForm" (ngSubmit)="searchContractLines()">
    <div class="row-box">
      <div class="small-margin-right">
        <mat-form-field>
          <input type="text" matInput formControlName="partyAccountNo" placeholder="Party">
        </mat-form-field>
      </div>
      <div class="small-margin-right">
        <mat-form-field>
          <input type="text" matInput formControlName="grade" placeholder="Grade">
        </mat-form-field>
      </div>
      <div class="small-margin-right">
        <mat-form-field>
          <input type="text" matInput formControlName="customerContractRef" placeholder="Customer Contract Ref">
        </mat-form-field>
      </div>
      <button mat-flat-button type="submit" color="primary" testName="search">Search</button>
    </div>    
  </form>
  <div name="displayContractLines">
    <app-spinner [displayProgressSpinner]="isSearching" #spinner></app-spinner>
    <div class="table-container">
      <table class="table">
        <thead>
          <th>Customer Contract Ref</th>
          <th>Party Account No</th>
          <th>Party Name</th>
          <th>Location</th>
          <th>Start Date</th>
          <th>(Sales) Grade</th>
          <th>(Sales) Grade Description</th>
          <th>Broker Ref</th>
          <th>Target Weight</th>
          <th>Delivered Fixed Weight</th>
          <th>Delivered Unfixed Weight</th>
          <th>Delivered Weight</th>
          <th>Balance Weight</th>
        </thead>
        <tbody>
          <tr *ngFor="let cl of contractLines" (click)="contractLineSelected(cl)" [ngClass]="(selectedContractLine == cl) ? 'selected' : ''">
            <td class="margined">{{cl.customerContractRef}}</td>
            <td class="margined">{{cl.partyAccountNo}}</td>
            <td class="margined">{{cl.partyName}}</td>
            <td class="margined">{{cl.location}}</td>
            <td class="margined">{{cl.startDate | momentUtcAsLocaleDate}}</td>
            <td class="margined">{{cl.grade}}</td>
            <td class="margined">{{cl.gradeDescription}}</td>
            <td class="margined">{{cl.brokerRef}}</td>
            <td class="margined">{{cl.targetWeightUomString}}</td>
            <td class="margined">{{cl.deliveredFixedWeightUomString}}</td>
            <td class="margined">{{cl.deliveredUnfixedWeightUomString}}</td>
            <td class="margined">{{cl.deliveredWeightUomString}}</td>
            <td class="margined">{{cl.balanceWeightUomString}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div mat-dialog-actions class="buttons-row">
    <button mat-flat-button type="button" (click)="cancel()">Cancel</button>
    <button mat-flat-button type="submit" (click)="submit()" color="primary">Update</button>
  </div>
</div>