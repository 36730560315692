<mat-form-field>
  <mat-label>{{label}}</mat-label>
  <mat-select [value]="val" (selectionChange)="dateRangeChange($event)" #select>
    <mat-option value="Month">Last 1 Month</mat-option>
    <mat-option value="Quarter">Last 3 Months</mat-option>
    <mat-option value="Half">Last 6 Months</mat-option>
    <mat-option value="Year">Last 12 Months</mat-option>
    <mat-option (click)="showDatePickers = true" value="Range">{{dateRangeStr}}</mat-option>
  </mat-select>
</mat-form-field>
<div [hidden]="!showDatePickers" [ngStyle]="pickersStyle" class="datePickersRange">
  <mat-form-field class="date">
    <input matInput [matDatepicker]="from" placeholder="Date From" #dateFrom [value]="fromD">
    <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
    <mat-datepicker #from></mat-datepicker>
  </mat-form-field>
  <mat-form-field class="date">
    <input matInput [matDatepicker]="to" placeholder="Date To" #dateTo [value]="toD">
    <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
    <mat-datepicker #to></mat-datepicker>
  </mat-form-field>
  <button mat-button type="button" (click)="applyRange()">Apply</button>
  <button mat-button type="button" (click)="showDatePickers = false">Close</button>
</div>