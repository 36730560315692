import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';
import { uniqueId } from 'underscore';
import { ApiPageResponse } from '../gen/models';
import { DepotViewModel } from '../gen/models/DepotViewModel';
import { DepotService } from '../gen/services/depot.service';
import { RouteToMarketService } from '../gen/services/route-to-market.service';
import { SpinnerComponent } from '../spinner/spinner.component';
import { NIL as NIL_UUID } from 'uuid';
@Component({
    selector: 'app-route-to-market-dialog-add',
    templateUrl: './route-to-market-dialog-add.component.html',
    styleUrls: ['./route-to-market-dialog-add.component.scss'],
    providers: [
        {provide: DateAdapter, useClass: MomentDateAdapter},
        {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}}
    ]
})
export class RouteToMarketDialogAddComponent implements OnInit, OnDestroy {

    filteredDepots: ApiPageResponse<DepotViewModel>;
    routeToMarketAddForm: FormGroup;
    spinnerVisible = false;
    updateSubscription: Subscription;
    
    @ViewChild('spinner', { static: true }) spinner: SpinnerComponent;
    
    constructor(public dialogRef: MatDialogRef<RouteToMarketDialogAddComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { depotNo: string, grade: string, route: string },
        private adapter: DateAdapter<any>,
        private depotsService: DepotService,
        private routeToMarketService: RouteToMarketService,
        private fb: FormBuilder,
        private toastr: ToastrService
    ) { 
        this.adapter.setLocale(moment.locale());
    }

    get depot() {
        return this.routeToMarketAddForm.get('depot');
    }

    get depotNo() {	
        const depot = this.depot.value;
        if (depot && typeof (depot) !== 'string') {
            return depot.depotNumber;
        }
        return depot;
    }

    get grade() {
        return this.routeToMarketAddForm.get('grade').value;
    }

    get destination() {
        return this.routeToMarketAddForm.get('destination').value;
    }

    get effectiveDate() {
        return this.routeToMarketAddForm.get('effectiveDate').value;
    }

    get isDsiabled() {
        const depotNo = this.depotNo;
        return (!depotNo || depotNo.length === 0) 
        || (!this.routeToMarketAddForm.value.grade || this.routeToMarketAddForm.value.grade.length == 0) 
        || (!this.routeToMarketAddForm.value.destination || this.routeToMarketAddForm.value.destination.length == 0);
    }

    cancel() {
        this.dialogRef.close();
    }

    filterDepots(val: string) {
        return this.depotsService.searchDepots(val);
    }

    displayDepot(depot?: DepotViewModel): string | undefined {
        let depotString = undefined;
        if (depot && depot.depotNumber && depot.depotNumber !== '' && depot.depotName !== '')
            depotString = depot.depotNumber + ' - ' + depot.depotName;
        else if (depot && depot.depotNumber && depot.depotNumber !== '' && depot.depotName === '')
            depotString = depot.depotNumber;
        return depotString;
    }
    
    ngOnDestroy(): void {
        this.spinnerVisible = false;
        this.spinner.overlayRef.dispose();
        if (this.updateSubscription != null && !this.updateSubscription.closed) {
            this.updateSubscription.unsubscribe();
        }
    }

    ngOnInit(): void {
        this.routeToMarketAddForm = this.fb.group({
            depot: this.data.depotNo,
            grade: this.data.grade,
            destination: this.data.route,
            effectiveDate: null
        });

        this.depot.valueChanges
            .pipe(
                debounceTime(500),
                startWith(null),
                switchMap(v => this.filterDepots(v)))
            .subscribe(d => this.filteredDepots = d);
    }

    ok() {
        this.spinnerVisible = true;

        let effectiveDate: Date = null;

        if (this.effectiveDate) {
            effectiveDate = moment(this.effectiveDate.toISOString()).toDate();
        }

        this.updateSubscription = this.routeToMarketService.updateRoutes([{
            active: true,
            createdBy: NIL_UUID,
            createdDate: moment().toDate(),
            id: 0,
            depotNo: this.depotNo,
            grade: this.grade,
            route: this.destination,
            effectiveDate : effectiveDate
        }]).subscribe(() => {
            this.spinnerVisible = false;
            this.dialogRef.close(true);
        },
        () => {
            this.spinnerVisible = false;
            this.toastr.error('Request to add failed. Please try again', 'Route To Market');
        });
    }
}
