<div class="sidebar-form" id="conv-container">
    <div class="md-toolbar-tools sidebar-header">
        <h3>Comments</h3>
        <button mat-button *ngIf="conversation?.messages.length > 0" class="mail-btn" aria-label="Send email"
            (click)="sendConversationAsEmail()">
            <mat-icon> email</mat-icon>
        </button>
    </div>
    <div class="conversation-container">
        <ol class="discussion">
            <li class="{{getMessageClass(m)}}" *ngFor="let m of conversation?.messages">
                <div class="avatar">
                    <img [src]="'/assets/img/avatar' + getAuthorIndex(m?.author) + '.png'"
                        src="/assets/img/avatar.png" />
                </div>
                <div class="messages">
                    <p [innerHTML]="m.body"></p>
                    <time [attr.datetime]="m.timestamp">{{m.author}} • {{m.isSystemComment ? ' (System)' : ""}}
                        {{m.timestamp | momentUtcAsLocaleDate}}</time>
                </div>
            </li>
        </ol>
    </div>
    <div class="new-comment">
        <form [formGroup]="commentForm"
            (ngSubmit)="commentForm.valid && sendMessage(commentForm.value.newComment, false)">
            <div
                style="overflow:hidden; min-height: 80px; height: 80px; max-height: 80px; width: 400px; padding: 15px 7px; flex-direction:row">

                <button mat-button [matMenuTriggerFor]="menu" aria-label="Open predefined message menu"
                    style="padding:0;width:10%;">
                    <mat-icon>add_circle</mat-icon>
                </button>
                <mat-menu width="4" #menu>
                    <button mat-menu-item *ngFor="let x of predefinedComments"
                        (click)="sendPredefinedMessage(x.comment)">
                        {{x.comment}}
                    </button>
                    <mat-divider style="width:85%;margin:auto;"></mat-divider>
                    <button mat-menu-item onclick="document.getElementById('fileUploadConversations').click();">
                        Attach a photo
                    </button>
                </mat-menu>
                <mat-form-field>
                    <textarea formControlName="newComment" mat-autofocus matInput maxlength="500" style="width: 65%; height: 100%; resize: none"
                    [placeholder]="commentPlaceholder()"></textarea>
                </mat-form-field>
                <button mat-button type="submit">Send</button>
                <input type="file" id="fileUploadConversations" style="display:none;"
                    (change)="uploadFile($event.target.files)" accept="image/*" />
            </div>
        </form>
    </div>
</div>
