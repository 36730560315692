import { Injectable, ElementRef } from '@angular/core';
import { ReplaySubject, Observable, Subject } from 'rxjs';
import { BookingSheetQueryModel, PendingAndAllocatedViewModel, AllocationBookingViewModel } from '../gen/models';

@Injectable({
	providedIn: 'root'
})
export class SidenavService {
	private requestSub = new ReplaySubject<SideNavModel>(1);
	private requestSubClosed = new ReplaySubject<SideNavCloseModel>(1);
	private requestSubBooking = new ReplaySubject<SideNavModelBookingSheet>(1);
	private requestItemAdded = new Subject<any>();
	selectedAllocation$: Observable<SideNavModel> = this.requestSub.asObservable();
	closed$: Observable<SideNavCloseModel> = this.requestSubClosed.asObservable();
	itemAdded$: Observable<any> = this.requestItemAdded.asObservable();
	selectedBooking$: Observable<SideNavModelBookingSheet> = this.requestSubBooking.asObservable();

	constructor() { }

	public next(request: SideNavModel) {
		this.requestSub.next(request);
	}

	public showBookingSchedule(request: SideNavModelBookingSheet) {
		this.requestSubBooking.next(request);
	}

	public close(request: SideNavCloseModel) {
		this.requestSubClosed.next(request);
	}

	public itemAdded(addedItem: any) {
		this.requestItemAdded.next(addedItem);
	}
}

export interface SideNavModel {
  type: string;
  item: PendingAndAllocatedViewModel;
}
export interface SideNavCloseModel {
  closed: boolean;
}
export interface SideNavModelBookingSheet {
  type: string;
  item: AllocationBookingViewModel;
  dateRangeType: number;
}
