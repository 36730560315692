<div class="close" (click)="dialogRef.close()">
  <i class="fa fa-times"></i>
</div>
<div class="header">
  {{data.table}}: {{data.id}}
</div>
<div class="table-container">
  <table>
    <thead>
      <th>Column</th>
      <th>Date Changed (local)</th>
      <th>Date Changed (UK)</th>
      <th>Old Value</th>
      <th>New Value</th>
      <th>User</th>
    </thead>
    <tbody>
      <tr *ngFor="let h of histories">
        <td>{{h.columnFriendlyName ? h.columnFriendlyName : h.columnName}}</td>
        <td>{{h.modifiedDate | momentLocaleDateTime}}</td>
        <td>{{h.modifiedDate | momentUtcAsLocaleDateTime}}</td>
        <td>{{h.oldValue}}</td>
        <td>{{h.newValue}}</td>
        <td>{{h.userName}}</td>
      </tr>
    </tbody>
  </table>
</div>