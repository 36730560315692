import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefined } from '../tools';

@Pipe({
	name: 'shorten'
})
export class ShortenPipe implements PipeTransform {

	transform(value: string, args?: any): any {
		const maxLength = +args;
		if (isNullOrUndefined(value) || value.length <= maxLength ) { return value; }
		return `${value.substring(0, maxLength)}...`;
	}

}
