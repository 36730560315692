<div id="printappointment">
<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
<div class="printappointment" *ngFor="let item of appointments"
style="background-color: white;font-family: Arial, Helvetica, sans-serif;font-size: small;">
<table style="width:715px;">
        <tr>
            <td style="width: 65%;"></td>
            <td style="width: 35%;">
                <img src="/assets/Logo.svg" style="height: 50px;flood-color: gray;" />
            </td>
        </tr>
        <tr style="margin-bottom:20px;">
            <td style="width:10%;">
                <b>Appointment Confirmation</b>
            </td>
            <td></td>
        </tr>
    </table>
    <div>
        <table style="align-self: center;width: 715px;border-collapse: collapse;">
            <tr style="height: 30px;">
                <td colspan="3" style="text-align: center;border: 1px solid gray;"><b>Appointment Information</b></td>
            </tr>
            <tr style="border: 1px solid gray;">
                <td style="border: 1px solid gray;width: 10%;">
                    <table>
                        <tr style="height:25px;">
                            <td>Appointment No</td>
                        </tr>
                        <tr style="height:25px;">
                            <td>Appt Issued To</td>
                        </tr>
                        <tr style="height:25px;">
                            <td>Cust Ref #1</td>
                        </tr>
                        <tr style="height:25px;">
                            <td>Cust Ref #2</td>
                        </tr>
                        <tr style="height:25px;">
                            <td>Haulier:</td>
                        </tr>
                    </table>
                </td>
                <td style="border: 1px solid gray;width: 90%;" colspan="2">
                    <table>
                        <tr style="height:25px;">
                            <td>{{item.contractLineAllocationId}}</td>
                        </tr>
                        <tr style="height:25px;">
                            <td>{{item.partyName}} <br />{{item.partyAddress.address1}} {{item.partyAddress.address2}} {{item.partyAddress.address3}} {{item.partyAddress.address4}} {{item.partyAddress.address5}} {{item.partyAddress.postalCode}}</td>
                        </tr>
                        <tr style="height:25px;">
                            <td>{{item.contractRef}}</td>
                        </tr>
                        <tr style="height:25px;">
                            <td>{{item.customerContractRef}}</td>
                        </tr>
                        <tr style="height:25px;">
                            <td>{{item.haulierName}}</td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr style="height:25px;">
                <td style="border: 1px solid gray;width: 10%;"></td>
                <td style="border: 1px solid gray;width: 45%;">From</td>
                <td style="border: 1px solid gray;width: 45%;">To</td>
            </tr>
            <tr>
                <td style="border: 1px solid gray;width: 10%;">
                    <table>
                        <tr style="height:25px;">
                            <td>Company</td>
                        </tr>
                        <tr style="height:25px;">
                            <td>Address</td>
                        </tr>
                        <tr style="height:25px;">
                            <td>Phone</td>
                        </tr>
                        <tr style="height:25px;">
                            <td>Fax</td>
                        </tr>
                        <tr style="height:25px;">
                            <td>Appointment Ref #</td>
                        </tr>
                        <tr style="height:25px;">
                            <td>Expected Start Date</td>
                        </tr>
                        <tr style="height:25px;">
                            <td>Expected End Date</td>
                        </tr>
                    </table>
                </td>
                <td style="border: 1px solid gray;width: 45%;">
                    <table>
                        <tr style="height:25px;">
                            <td>{{item.fromCompany}}</td>
                        </tr>
                        <tr style="height:25px;">
                            <td>{{item.fromCompanyAddress.address1}} {{item.fromCompanyAddress.address2}} <br />{{item.fromCompanyAddress.address3}} {{item.fromCompanyAddress.address4}} {{item.fromCompanyAddress.address5}} {{item.fromCompanyAddress.postalCode}} </td>
                        </tr>
                        <tr style="height:25px;">
                            <td>{{item.contactTelNo}}</td>
                        </tr>
                        <tr style="height:25px;">
                            <td>{{item.contactFax}}</td>
                        </tr>
                        <tr style="height:25px;">
                            <td>{{item.aptNumber}}</td>
                        </tr>
                        <tr style="height:25px;">
                            <td>{{item.requestedMovementDate | momentUtcAsLocaleDateTime}}</td>
                        </tr>
                        <tr style="height:25px;">
                            <td>{{item.requestedMovementDateEnd | momentUtcAsLocaleDateTime}}</td>
                        </tr>
                    </table>
                </td>
                <td style="border: 1px solid gray;width: 45%;">
                    <table>
                        <tr style="height:25px;">
                            <td>{{item.partyName}}</td>
                        </tr>
                        <tr style="height:25px;">
                            <td>{{item.deliveryAddress.address1}} {{item.deliveryAddress.address2}} <br />{{item.deliveryAddress.address3}} {{item.deliveryAddress.address4}} {{item.deliveryAddress.address5}} {{item.deliveryAddress.postalCode}} </td>
                        </tr>
                        <tr style="height:25px;">
                            <td></td>
                        </tr>
                        <tr style="height:25px;">
                            <td></td>
                        </tr>
                        <tr style="height:25px;">
                            <td>{{item.deliveryNumber}}</td>
                        </tr>
                        <tr style="height:25px;">
                            <td>{{item.startDate | momentUtcAsLocaleDateTime}}</td>
                        </tr>
                        <tr style="height:25px;">
                            <td>{{item.endDate | momentUtcAsLocaleDateTime}}</td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr style="height:25px;">
                <td colspan="3">
                    <b>Item Information</b>
                <td>
            </tr>
            <tr style="height:25px;">
                <td>
                    Item
                </td>
            </tr>
            <tr style="height:25px;">
                <td>
                    {{item.gradeDesc}}
                </td>
            </tr>
            <tr style="height:25px;">
                <td style="width:20%;">
                    <b>Haulage Rate:</b>
                </td>
                <td colspan="2" style="width:80%;">
                    {{item.agreedHaulageRate}}
                </td>
            </tr>
            <tr style="height:60px;">
                <td style="width:10%;">
                    <b>Instructions:</b>
                </td>
                <td colspan="2">
                    {{item.instructions}}
                </td>
            </tr>
        </table>
    </div>
    <p *ngIf="item.pendingDispatch"> Pending Dispatch </p>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <table style="width: 725px;">
        <tr style="height:25px;">
            <td style="width:10%;">Issued By:</td>
            <td style="width:20%;">{{item.issuedBy}}</td>
            <td style="width:10%;">Issue Date:</td>
            <td style="width:25%;">{{item.issuedDate | momentUtcAsLocaleDate}}</td>
            <td style="width:10%;">Printed:</td>
            <td style="width:25%;">{{item.printeDateTime | momentUtcAsLocaleDateTime}}</td>
        </tr>
    </table>
</div>
</div>