import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {REST_CONFIG_TOKEN, RestConfigService} from '../rest-config-service';
import {Observable} from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { AllocatedViewModel,
	AllocationEmailVm,
	SendEmailRequest,
} from '../models';

@Injectable()
export class EmailService {

	constructor(
private _http: HttpClient,
@Inject(REST_CONFIG_TOKEN) private _configService: RestConfigService
	) {
	}

	public deliveryAppointmentRequest: (
        allocations?: AllocatedViewModel[]
        ) => Observable<AllocationEmailVm>
		= (
			allocations?: AllocatedViewModel[]
		): Observable<AllocationEmailVm> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});

			const uri = '/api/Email/allocation/delivery-appointment-request';

			return this._http
				.post<AllocationEmailVm>(this._configService.apiBaseUrl() + uri, JSON.stringify(allocations ),
					{ headers: headers, params: queryParameters  });
		};

	public logistics: (
        allocations?: AllocatedViewModel[]
        ) => Observable<AllocationEmailVm>
		= (
			allocations?: AllocatedViewModel[]
		): Observable<AllocationEmailVm> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});

			const uri = '/api/Email/allocation/logistics';

			return this._http
				.post<AllocationEmailVm>(this._configService.apiBaseUrl() + uri, JSON.stringify(allocations ),
					{ headers: headers, params: queryParameters  });
		};

	public send: (
        request?: SendEmailRequest
        ) => Observable<void>
		= (
			request?: SendEmailRequest
		): Observable<void> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});

			const uri = '/api/Email/send';

			return this._http
				.post<any>(this._configService.apiBaseUrl() + uri, JSON.stringify(request ),
					{ headers: headers, params: queryParameters  });
		};


}

