

<div cdkDropListGroup class="component">
  <form id="userpreferencesForm" name="userpreferencesForm" [formGroup]="userpreferencesForm"
      (ngSubmit)="submitForm()" autocomplete="off">
      
      <app-spinner [displayProgressSpinner]="isLoading" [container]="mainTable" #spinner></app-spinner>
    <div class="row-container top" style="width:100%; height: 100%;" #mainTable>
      <div class="drag-container" style="width:100%; height: 100%;">
        <div class="header">
          <h2>My Preferences</h2>
        </div>

        <div  style="width:100%;">
          <div style="width:50%; display: inline-block;">
            <table>
              <tr>
                <td>
                  <span>Requests Columns Display:</span>
                </td>
                <td>
                  <mat-form-field class=mat-Filer matTooltip="Field chooser" style="width: 400px;" [ngClass]="!(requestTableViews?.length > 0) ? 'display-hidden' : 'display-block'">
                    <mat-select formControlName="requestView">
                        <mat-option *ngFor="let view of requestTableViews" [value]="view">{{view.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field class=mat-Filer matTooltip="Field chooser" style="width: 400px;" [ngClass]="(requestTableViews?.length > 0) ? 'display-hidden' : 'display-block'">
                    <mat-select formControlName="requestsColFields" multiple (selectionChange)="UpdateRequestsColumns($event.value);" [(ngModel)]="requestsCols">
                        <mat-option *ngFor="let field of fieldsListForRequests" [value]="field.name"
                            (onSelectionChange)="showAllRequestsColumns($event);">{{field.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
                <td>
                  <a mat-button color="primary" (click) = "setTableView(requestTable, fieldsListForRequests, requestsCols)">Set table view</a>
                </td>
              </tr>
    
              <tr>
                <td>
                  <span>Allocations Columns Display:</span>
                </td>
                <td>
                  <mat-form-field class=mat-Filer matTooltip="Field chooser" style="width: 400px;" [ngClass]="!(allocationTableViews?.length > 0) ? 'display-hidden' : 'display-block'">
                    <mat-select formControlName="allocationView">
                      <mat-option *ngFor="let view of allocationTableViews" [value]="view">{{view.name}}</mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class=mat-Filer matTooltip="Field chooser" style="width: 400px;" [ngClass]="(allocationTableViews?.length > 0) ? 'display-hidden' : 'display-block'">
                  <mat-select formControlName="allocationsColFields" multiple (selectionChange)="UpdateAllocationsColumns($event.value);"[(ngModel)]="allocationsCols">
                      <mat-option *ngFor="let field of fieldsListForAllocations" [value]="field.name"
                          (onSelectionChange)="showAllAllocationsColumns($event);">{{field.name}}</mat-option>
                  </mat-select>
              </mat-form-field>
                </td>
                <td>
                  <a mat-button color="primary" (click) = "setTableView(allocationTable, fieldsListForAllocations, allocationsCols)">Set table view</a>
                </td>
              </tr>
              <tr>
                  <td>
                    <span>Allocations Allocation Date Filter:</span>
                  </td>
                  <td class=mat-Filer matTooltip="Field chooser" style="width: 400px;">
                    <mat-form-field>
                        <mat-label>Allocation Date</mat-label>
                        <mat-select formControlName="allocationDate">
                          <mat-option value="Month">Last 1 Month</mat-option>
                          <mat-option value="Quarter">Last 3 Months</mat-option>
                          <mat-option value="Half">Last 6 Months</mat-option>
                          <mat-option value="Year">Last 12 Months</mat-option>
                        </mat-select>
                      </mat-form-field>
                  </td>
                  <td></td>
              </tr>
              <tr>
                <td>
                    <span>Allocations Pick up from Date Filter:</span>
                </td>
                <td class=mat-Filer matTooltip="Field chooser" style="width: 400px;">
                    <mat-form-field>
                        <mat-label>Pick up from Date</mat-label>
                        <mat-select formControlName="pickUpFromDate">
                          <mat-option value="Month">Last 1 Month</mat-option>
                          <mat-option value="Quarter">Last 3 Months</mat-option>
                          <mat-option value="Half">Last 6 Months</mat-option>
                          <mat-option value="Year">Last 12 Months</mat-option>
                        </mat-select>
                      </mat-form-field>
                </td>
                <td></td>
            </tr>
            </table>
          </div>
          <div style="width:50%;display: inline-block; vertical-align:top; text-align: center;margin-top: 15px;">
            <button mat-flat-button color="primary" type="submit" testName="save">Save</button>
          </div>
        </div>

        <div>
          <div class="divRow1">
            <mat-form-field  style="width: 350px;">
              <input type="text" placeholder="Select Order Book Type" aria-label="Select Order Book Type" matInput [matAutocomplete]="autoOrderBookType" [formControl]="orderBookTypeControl">
              <mat-hint>Enter text to find order book type</mat-hint>
            </mat-form-field>
            
            <mat-autocomplete #autoOrderBookType="matAutocomplete" [displayWith]="displayOrderBookType">
                <mat-option *ngFor="let obt of filteredOrderBookTypes | async" [value]="selectedOrderBookTypes">
                    <div (click)="orderBookTypeOptionClicked($event, obt)">
                        <mat-checkbox [checked]="obt.selected" (change)="orderBookTypeToggleSelection(obt)" (click)="$event.stopPropagation()">
                            {{ obt.orderBook }}
                        </mat-checkbox>
                    </div>
                </mat-option>
            </mat-autocomplete>
          </div>

          <div class="divRow1">
              <mat-form-field  style="width: 350px;">
                <input type="text" placeholder="Select Territory" aria-label="Select Territory" matInput [matAutocomplete]="autoTerritory" [formControl]="territoryControl">
              <mat-hint>Enter text to find territory</mat-hint>
            </mat-form-field>
            
            <mat-autocomplete #autoTerritory="matAutocomplete" [displayWith]="displayTerritory">
                <mat-option *ngFor="let territory of filteredTerritorys | async" [value]="selectedTerritorys">
                    <div (click)="territoryOptionClicked($event, territory)">
                        <mat-checkbox [checked]="territory.selected" (change)="territoryToggleSelection(territory)" (click)="$event.stopPropagation()">
                            {{ territory.territoryCode + ' ' + territory.territoryName }}
                        </mat-checkbox>
                    </div>
                </mat-option>
            </mat-autocomplete>
          </div>

          <div class="divRow1">
            <mat-form-field  style="width: 350px;">
              <input type="text" placeholder="Select Depot" aria-label="Select Depot" matInput [matAutocomplete]="autoDepot" [formControl]="depotControl">
              <mat-hint>Enter text to find depot</mat-hint>
            </mat-form-field>
          
            <mat-autocomplete #autoDepot="matAutocomplete" [displayWith]="displayDepot">
                <mat-option *ngFor="let depot of filteredDepots | async" [value]="selectedDepots">
                    <div (click)="depotOptionClicked($event, depot)">
                        <mat-checkbox [checked]="depot.selected" (change)="depotToggleSelection(depot)" (click)="$event.stopPropagation()">
                            {{ depot.depotNumber + ' ' + depot.depotName }}
                        </mat-checkbox>
                    </div>
                </mat-option>
            </mat-autocomplete>
          </div>
        </div>

        <div>
          <div  class="divRow2">
            <label>Selected Order Book Type:</label>
            <mat-list dense>
                <mat-list-item *ngIf="selectedOrderBookTypes?.length === 0" style="font-size: 15px;">(None)</mat-list-item>
                <mat-list-item *ngFor="let obt of selectedOrderBookTypes" style="font-size: 15px;">
                  {{ obt.orderBook }}
                </mat-list-item>
            </mat-list>
          </div>

          <div  class="divRow2">
            <label>Selected Territory:</label>
            <mat-list dense>
                <mat-list-item *ngIf="selectedTerritorys?.length === 0" style="font-size: 15px;">(None)</mat-list-item>
                <mat-list-item *ngFor="let territory of selectedTerritorys" style="font-size: 15px;">
                  {{ territory.territoryCode + ' ' + territory.territoryName }}
                </mat-list-item>
            </mat-list>
          </div>

          <div  class="divRow2">
            <label>Selected Depot:</label>
            <mat-list dense>
                <mat-list-item *ngIf="selectedDepots?.length === 0" style="font-size: 15px;">(None)</mat-list-item>
                <mat-list-item *ngFor="let depot of selectedDepots" style="font-size: 15px;">
                  {{ depot.depotNumber + ' ' + depot.depotName }}
                </mat-list-item>
            </mat-list>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>