import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QueryModel, PagedRouteToMarketModel, ImportStatus } from '../models';
import { RouteToMarketViewModel } from '../models/RouteToMarketViewModel';
import { RestConfigService, REST_CONFIG_TOKEN } from '../rest-config-service';

@Injectable()
export class RouteToMarketService {

	constructor(private _http: HttpClient,
    @Inject(REST_CONFIG_TOKEN) private _configService: RestConfigService) { }

	public getRoutes: (
		q?: QueryModel
	) => Observable<PagedRouteToMarketModel>
		= (
			q?: QueryModel
		): Observable<PagedRouteToMarketModel> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

			const uri = '/api/RouteToMarket/routes';

			return this._http
				.post<PagedRouteToMarketModel>(this._configService.apiBaseUrl() + uri, JSON.stringify(q),
					{ headers: headers, params: queryParameters });
		};

	public updateRoutes: (
		updateToRoutes: RouteToMarketViewModel[]
	) => Observable<number>
		= (
			u: RouteToMarketViewModel[]
		): Observable<number> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

			const uri = '/api/RouteToMarket/updateRoutes';

			return this._http
				.post<number>(this._configService.apiBaseUrl() + uri, JSON.stringify(u),
					{ headers: headers, params: queryParameters });
		};

	public uploadRoutes: (
		ContentType?: File
	) => Observable<ImportStatus>
		= (
			ContentType?: File
		): Observable<ImportStatus> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders();
			const form = new FormData();

			form.append('fileKey', ContentType, ContentType.name);
			headers.append('Content-Type', ContentType.type);
			const uri = '/api/RouteToMarket/uploadRoutes';

			return this._http
				.post<ImportStatus>(this._configService.apiBaseUrl() + uri, form,
					{ headers: headers, params: queryParameters  });
		};
}
