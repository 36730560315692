import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { isNullOrUndefined } from '../tools';

@Pipe({
	name: 'momentUtcAsLocaleDateTime'
})
export class MomentUtcAsLocaleDateTimePipe implements PipeTransform {

	//done using https://stackoverflow.com/questions/13315325/ignoring-timezones-when-creating-a-date-in-javascript-momentjs
	transform(value: any, locale?: string): any {
		if (isNullOrUndefined(value)) { return ''; }

        
		if(!isNullOrUndefined(locale) && locale.length > 0) { //optional override of locale
			const m = moment();
			m.locale(locale);
			return m.utc(value).format('lll');
		}
		return moment.utc(value).format('lll'); // same output for all timezones
	}
}
