import { Component, OnInit, ViewChild, OnDestroy  } from '@angular/core';

import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AllocationBookingViewModel, BookingSheetQueryModel, ApiPageResponse, UomVm } from '../gen/models';
import { SortInfo } from '../table-sort/table-sort.directive';
import { BookingSheetService } from '../gen/services/booking-sheet.service';
import { SidenavService } from '../services/sidenav.service';
import { DepotService } from '../gen/services/depot.service';
import { GradeService } from '../gen/services/grade.service';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';
import { isNullOrUndefined } from '../tools';
import { UomsService } from '../gen/services/uoms.service';
import { Utilities } from '../shared/utilites';
import { Subscription } from 'rxjs';
import { SpinnerComponent } from '../spinner/spinner.component';
import { ToastrService } from 'ngx-toastr';
import { ADGroupService } from '../gen/services/adgroup.service';
import { DepotViewModel } from '../gen/models/DepotViewModel';
import { UserPreferencesVm } from '../gen/models/UserPreferencesVm';
import { ActivatedRoute } from '@angular/router';
import { AutocompleteComponent } from '../autocomplete/autocomplete.component';
import { EnumVals, IEnumItem } from '../gen/enums/enums';

//TODO: NOTE IT DOESNT SEEM LIKE THIS COMPONENT IS BEING USED ANY MORE, WHEN REFACTORING REMOVE THIS COMPONENT
@Component({
    selector: 'app-booking-sheet',
    templateUrl: './booking-sheet.component.html',
    styleUrls: ['./booking-sheet.component.scss']
})
export class BookingSheetComponent implements OnInit {
    showSearch = false;
    sortReverse = false;
    isLoading = true;
    noRecord = false;
    hasSearched = false;
    showBookingSheet = false;
    showTransportPlan = false;
    showBookedTransport = false;
    searchForm: FormGroup;
    page = 1;
    pageSize = EnumVals.NoOfResults[1].value;
    collectionSize = 1;
    bookingsheetdata: AllocationBookingViewModel[] = [];
    tableState: BookingSheetQueryModel;
    dateRange: IEnumItem[];
    filteredDepots: ApiPageResponse<DepotViewModel>;
    selectedDateRange: IEnumItem;
    defaultDepot: DepotViewModel;
    heading: string;
    uoms: UomVm[];
    selectedUOMCode = 'MT';
    bookingSheetSubscription: Subscription;
    userPreferencesData: UserPreferencesVm;
    preferedDepot : DepotViewModel;
    defaultTerritory: string;

  @ViewChild('spinner', { static: true }) spinner: SpinnerComponent;
  @ViewChild('gradeInput', { static: true }) gradeInput: AutocompleteComponent;
  @ViewChild('gradeGroupInput', { static: true }) gradeGroupInput: AutocompleteComponent;

  constructor(
    private bookingSheetService: BookingSheetService,
    private fb: FormBuilder,
    private sidenavService: SidenavService,
    private depotsService: DepotService,
    public gradeService: GradeService,
    private uomsService: UomsService,
    private toastr: ToastrService,
    private adGroupService: ADGroupService,
    private route: ActivatedRoute
  ) {
      const userPreferences = this.route.snapshot.data['userpreferencesData'];
      if(userPreferences !== undefined && userPreferences.length > 0)
          this.userPreferencesData = userPreferences[0];
      else
          this.userPreferencesData = {depot: '', territory: '', requestFields: '', allocationFields: '', orderBookType: '', viewMode: '', allocationDate: '', pickUpFromDate: '' };
  }

  applyBulkAdjustment(adj: number) {
      if(adj == null || adj.toString() == '') {
          this.toastr.error('Invalid bulk adjustment value', 'Adjustment');
          return;
      }
      this.isLoading = true;
      const ts = this.tableState;
      this.bookingSheetService.setBulkAllocationBookingAdjustment(adj, ts).subscribe(r => {
          this.isLoading = false;
          this.callServer(this.tableState);
          this.toastr.success('Bulk Adjustment successfully applied', 'Adjustment');
      });
  }

  applyAdjustment(adj: number, item: AllocationBookingViewModel) {
      if(adj === null || adj.toString() === '') {
          this.toastr.error('Invalid adjustment value', 'Adjustment');
          return;
      }

      if(item.depotNumber ==='' || item.heapNo === null || item.heapNo.toString() === '') {
          this.toastr.error('Invalid depot or heap value', 'Adjustment');
          return;
      }

      item.adjustment = adj;
      item.flow = item.flow + (adj != 0 ? (item.flow  * adj/100) : 0);
      this.bookingSheetService.setAllocationBookingAdjustment(item).subscribe
      (r =>
      {
          this.isLoading = false;
          this.toastr.success('Adjustment successfully applied', 'Adjustment');
      }
      );
  }

  get depot() {
      return this.searchForm.get('depot');
  }

  get RangeType() {
      return this.searchForm.get('dateRangeSelection');
  }

  ngOnInit() {
      this.preferedDepot = {depotNumber: this.userPreferencesData.depot, depotName: '', depotShortName: '', selected: false};

      if(isNullOrUndefined(this.userPreferencesData.depot))
          this.preferedDepot.depotNumber =  '';

      if(this.userPreferencesData.territory === '')
          this.defaultTerritory =  null;
      else
          this.defaultTerritory =  this.userPreferencesData.territory;

      this.defaultDepot = this.preferedDepot;
      const deptNo = isNullOrUndefined(this.defaultDepot) ? '' : this.defaultDepot.depotNumber;

      this.dateRange = EnumVals.DateRange;
      this.selectedDateRange = { key: 0, value: '1Day', desc: '1 Day' } as IEnumItem;

      this.searchForm = this.fb.group({
          depot: this.defaultDepot,
          gradeGroup: '',
          salesGrade: '',
          grade: '',
          materialDescription: '',
          switchUomCode: this.selectedUOMCode,
          dateRangeSelection: this.selectedDateRange,
          showAll: false,
          numAdjPattern: [{ value: '', disabled: false }, [Validators.required, Validators.pattern('^[0-9]{1,7}(\.[0-9]+)?$')]],
          useDefaultDepot: true,
      });

      this.tableState = {
          pagination: { number: this.pageSize, start: this.page },
          search: {territory: this.defaultTerritory, depotNumber: deptNo, gradeGroup: '', grade: '', salesGrade: '', materialDescription: '', switchUomCode: this.selectedUOMCode, dateRange: this.selectedDateRange.key, showAll: false,   useDefaultDepot: false,},
          sort: { predicate: 'Grade', reverse: this.sortReverse }
      };

      if(deptNo !=='')
          this.heading = this.defaultDepot.depotNumber + ' - ' + this.defaultDepot.depotName;

      this.callServer(this.tableState);

      this.uomsService.getAll().subscribe(u => this.uoms = u.filter(v => v.code === 'MT' || v.code === 'LB' || v.code === 'GT'));

      this.adGroupService.getMyGroups().subscribe(g => {
          this.showBookingSheet = g.myGroups.includes('BookingSheet');
          this.showTransportPlan =  g.myGroups.includes('TransportPlan');
          this.showBookedTransport =  g.myGroups.includes('BookedTransport');
      });

      this.depot.valueChanges
          .pipe(
              debounceTime(500),
              startWith(null),
              switchMap(v => this.filterDepots(v)))
          .subscribe(d => this.filteredDepots = d);
  }

  ngOnDestroy(): void {
      this.isLoading = false;

      if(this.spinner !== undefined && this.spinner.overlayRef !== undefined)
          this.spinner.overlayRef.dispose();

      if(!isNullOrUndefined(this.bookingSheetSubscription) && !this.bookingSheetSubscription.closed)
          this.bookingSheetSubscription.unsubscribe();
  }

  callServer(tableState: BookingSheetQueryModel) {
      this.isLoading = true;

      if (tableState) {
          this.tableState = tableState;
      }

      if(!this.validateTableState(tableState)){
          this.isLoading = false;
          return;
      }

      this.hasSearched = true;
      if(!isNullOrUndefined(this.bookingSheetSubscription) && !this.bookingSheetSubscription.closed){
          this.bookingSheetSubscription.unsubscribe();
      }

      this.bookingSheetSubscription = this.bookingSheetService.getBookingSheetData(tableState).subscribe(r => {
          this.bookingsheetdata = (r && r.bookings) ? r.bookings : [];
          this.collectionSize = (r && r.recordCount) ? r.recordCount : 0;
          this.isLoading = false;
          this.selectedUOMCode = this.searchForm.controls.switchUomCode.value;
          this.selectedDateRange = this.searchForm.controls.dateRangeSelection.value;

          if(r.depot && r.depot.depotNumber)
          {
              this.heading = (r.depot.depotNumber + (r.depot.depotName ? ' - ' + r.depot.depotName : '')).toUpperCase();
          }

          if (this.tableState.pagination.start === 1) {
              this.page = 1;
          }

      }, err => {
          this.isLoading = false;
      });
      if (!(this.bookingsheetdata.length > 0)) {
          this.noRecord = true;
      }
  }

  filterDepots(val: string) {
      return this.depotsService.searchDepots(val);
  }
  displayDepot(depot?: DepotViewModel): string | undefined {
      let depotString = undefined;
      if(depot && depot.depotNumber && depot.depotNumber !== '' && depot.depotName !== '')
          depotString = depot.depotNumber + ' - ' + depot.depotName;
      else if(depot && depot.depotNumber && depot.depotNumber !== '' && depot.depotName === '')
          depotString = depot.depotNumber;
      return depotString;
  }

  sortByInfo(info: SortInfo) {
      if (info.active) {
          this.tableState.sort.reverse = info.direction != 'asc';
          this.sortReverse = this.tableState.sort.reverse;
          this.tableState.sort.predicate = info.active;
          this.callServer(this.tableState);
      }
  }

  search(formVal: any) {
      let grade = formVal.grade;
      if(isNullOrUndefined(grade) || grade === '')
          grade = this.gradeInput.input.nativeElement.value;
      let gradeGroup = formVal.gradeGroup;
      if(isNullOrUndefined(gradeGroup) || gradeGroup === '')
          gradeGroup = this.gradeGroupInput.input.nativeElement.value;

      this.tableState.search = {territory: this.defaultTerritory, depotNumber: formVal.depot.depotNumber ? formVal.depot.depotNumber: formVal.depot,
          gradeGroup: gradeGroup,
          grade: grade,
          salesGrade: formVal.salesGrade,
          materialDescription: formVal.materialDescription,
          switchUomCode: this.selectedUOMCode,
          dateRange: formVal.dateRangeSelection.key,
          showAll: formVal.showAll};

      this.tableState.pagination = { number: this.pageSize, start: 1 };
      this.callServer(this.tableState);
  }

  searchByDepo(depotNumber: string)
  {
      this.tableState.search.depotNumber = depotNumber;
      this.callServer(this.tableState);
  }

  pageChanged(page: number) {
      this.page = page;
      this.tableState.pagination.start = page;
      this.callServer(this.tableState);
  }

  refresh()
  {
      this.callServer(this.tableState);
  }

  validateTableState(tableState: BookingSheetQueryModel){
      const validateSearchDepot = Utilities.getNestedAttribute(tableState, 'search', 'depotNumber');
      const validateDefaultDepot = Utilities.getNestedAttribute(this.defaultDepot, 'depotNumber');
      if((isNullOrUndefined(validateSearchDepot) || validateSearchDepot == '') && (isNullOrUndefined(validateDefaultDepot) || validateDefaultDepot == '')){
          return false;
      }
      return true;
  }

  switchUOM(uomCode: string)
  {
      this.tableState.search.switchUomCode = uomCode;
      this.callServer(this.tableState);
  }

  exportToExcel()
  {
      this.isLoading = true;
      this.bookingSheetService
          .exportAppointmentsToExcelAndSave(
              'BookingSheet.xlsx',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', this.tableState)
          .subscribe(r => { this.isLoading = false; });
  }

  showSchedule(item: AllocationBookingViewModel)
  {
      const dateRange = this.searchForm.controls.dateRangeSelection.value as IEnumItem;
      this.sideNavShow({ type: 'loadSchedule', item: item, dateRangeType: dateRange.key});
  }

  sideNavShow(info: any) {
      this.sidenavService.showBookingSchedule(info);
  }

  clearFilter(){
      //This is to reset it to default values of the selected
      this.selectedDateRange = { key: 0, value: '1Day', desc: '1 Day' } as IEnumItem;
      this.selectedUOMCode = 'MT';

      this.searchForm.reset({
          depot:'',
          numAdjPattern: [{ value: '', disabled: false }, [Validators.required, Validators.pattern('^[0-9]{1,7}(\.[0-9]+)?$')]],
          materialDescription: '',
          showAll: false,
          switchUomCode: this.selectedUOMCode,
          dateRangeSelection: this.selectedDateRange,
      });
  }
}
