import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { InitModel, UomVm, DepotVm, ApiPageResponse,
    HeapVm, NewAllocation, NewAllocationMaterial } from '../gen/models';
import { Observable, of } from 'rxjs';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators, FormArray, FormGroupDirective, AbstractControl } from '@angular/forms';
import { AllocationService } from '../gen/services/allocation.service';
import { UomsService } from '../gen/services/uoms.service';
import { DepotService } from '../gen/services/depot.service';
import { HeapsService } from '../gen/services/heaps.service';
import { InitService } from '../services/init.service';
import { isNullOrUndefined } from '../tools';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { CustomValidators } from '../shared/custom-validators';
import { DateAdapter } from '@angular/material/core';

@Component({
    selector: 'app-new-request-dialog',
    templateUrl: './new-request-dialog.component.html',
    styleUrls: ['./new-request-dialog.component.scss']
})
export class NewRequestDialogComponent implements OnInit {

    pageModel: InitModel;
    defaultUom: UomVm = { id: 2, code: 'LB', name: 'Pounds' };
    uoms: UomVm[];
    myDate: Date;
    minDate: Date;
    maxDate: Date;
    selectedDepot: DepotVm;
    defaultDepot: DepotVm;
    filteredOptions: Observable<string[]>;
    cloning = false;
    shownLoadTimes: number;
    loadTimesStyle = {};
    allocationModel = {
        depot: undefined,
        requestDate: new Date(),
        readyDate: null,
        expiryDate: null,
        uom: undefined,
        packagingOption: undefined,
        containerSize: undefined,
        materials: [],
        tl: 1,
        loadTimes: [null],
        grade: undefined
    };

    isLoading = false;
    allocationForm: FormGroup;
    materialSubForm: FormGroup;
    depots = [];
    heaps = [];
    increments = ['15 minutes', '20 minutes', '25 minutes', '30 minutes', '45 minutes', '60 minutes', '90 minutes', '120 minutes'];
    incrementMinutes = [15, 20, 25, 30, 45, 60, 90, 120];
    filteredDepots: ApiPageResponse<DepotVm>;
    filteredHeaps: ApiPageResponse<HeapVm>;
    depotSelected: boolean;
    isSubmit = false;
    statuses = [];
    constructor(
    public dialogRef: MatDialogRef<NewRequestDialogComponent>,
    private allocationService: AllocationService,
    private uomsService: UomsService,
    private depotsService: DepotService,
    private heapService: HeapsService,
    private initService: InitService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private _adapter: DateAdapter<any>,) { }

    async ngOnInit() {
        this._adapter.setLocale(window.navigator.language);
        const defaultReadyDate = new Date();
        defaultReadyDate.setDate(defaultReadyDate.getDate() + 7);
        this.allocationForm = this.fb.group({
            depot: ['', Validators.required],
            readyDate: [defaultReadyDate, Validators.required],
            expiryDate: '',
            time: '',
            materials: this.fb.array([])
        });
        this.depot.valueChanges
            .pipe(
                debounceTime(500),
                startWith(null),
                switchMap(v => this.filterDepots(v)))
            .subscribe(d => this.filteredDepots = d);

        this.initService.initData$.subscribe(r => {
            this.pageModel = r;
            if (!isNullOrUndefined(r.defaultDepot)) {
                this.defaultDepot = { depotNumber: r.defaultDepot, depotName: r.defaultDepotName, depotShortName: null, selected: false };
                this.depot.setValue(this.defaultDepot);
                this.depotSelected = true;
                this.addMaterial();
                this.materials.controls[0].get('uom').patchValue(r.defaultUomId); //todo do we want to keep this default? Even when requested weight is not set?
            }
        });
        this.uomsService.getAll().subscribe(r => this.uoms = r);
        this.myDate = new Date();
        this.minDate = new Date(
            this.myDate.getFullYear(),
            this.myDate.getMonth(),
            this.myDate.getDate()
        );
        this.maxDate = new Date(
            this.myDate.getFullYear() + 1,
            this.myDate.getMonth(),
            this.myDate.getDate()
        );
    }

    get depot() {
        return this.allocationForm.get('depot');
    }

    get materials() {
        return this.allocationForm.get('materials') as FormArray;
    }

    get totalWeight() {
        return this.materials.controls.map(c => c.get('weight').value).reduce((a, b) => a + b, 0);
    }
    uom(i: number) {
        return this.materials[i].get('uom');
    }
    status(i: number) {
        return this.materials[i].get('status');
    }

    weight(i: number) {
        return this.materials[i].get('weight');
    }
    tl(i: number) {
        return this.materials[i].get('tl');
    }
    loadTimes(i: number) {
        return this.materials[i].get('loadTimes') as FormArray;
    }
    containerSize(i: number) {
        return this.materials[i].get('containerSize');
    }
    packaging(i: number) {
        return this.materials[i].get('packaging');
    }

    filterDepots(val: string) {
        return this.depotsService.searchDepots(val);
    }
    displayDepot(depot?: DepotVm): string | undefined {
        return depot && depot.depotNumber && depot.depotName ? depot.depotNumber + ' ' + depot.depotName : undefined;
    }

    filterHeaps(searchTerm: string) {
        const formDepot = this.depot.value as DepotVm;
        if(!isNullOrUndefined(formDepot) && !isNullOrUndefined(formDepot.depotNumber) && formDepot.depotNumber !== '' && !isNullOrUndefined(searchTerm) && typeof(searchTerm) === 'string') {
            return this.heapService.search(formDepot.depotNumber, searchTerm);
        }

        return of();
    }

    displayHeap(heap?: HeapVm): string | undefined {
        return heap ? heap.heapShortName + ' ' + heap.heapDesc : undefined;
    }

    submitForm() {
        if (!this.allocationForm.valid) {
            const unsetErrors = this.materials.controls.filter(c => !isNullOrUndefined(c.errors) && !isNullOrUndefined(c.errors.unset)).map(x => x.errors.unset as string);
            if(unsetErrors.length > 0) {
                this.toastr.error(unsetErrors[0], 'Form Invalid');
            }
            return;
        }
        let errorCount = 0;
        this.isLoading = true;
        this.isSubmit = true;
        const value = this.allocationForm.value;
        const readyDate = new Date(moment(value.readyDate).format('YYYY-MM-DD'));
        const expiryDate = new Date(moment(value.expiryDate).format('YYYY-MM-DD'));
        const model: NewAllocation = {
            depot: value.depot,
            requestDate: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
            readyDate: new Date(readyDate.toUTCString()),
            expiryDate: expiryDate,
            materials: value.materials.map(m => {
                const uomId = !isNullOrUndefined(m.uom) ? m.uom : null;
                const containerSizeId = !isNullOrUndefined(m.containerSize) ? m.containerSize : null;
                const packagingId = !isNullOrUndefined(m.packaging) ? m.packaging : null;
                const readyAt = !isNullOrUndefined(m.readyDate)
                    ? new Date(moment(m.readyDate).format('YYYY-MM-DD')) : readyDate;
                const expiryAt = !isNullOrUndefined(m.expiryDate) 
                    ? new Date(moment(m.expiryDate).format('YYYY-MM-DD')) : expiryDate;
                if(moment(readyAt).isAfter(moment(expiryAt))){
                    errorCount++;
                    this.toastr.error(`Date from (${moment(readyAt).format('YYYY-MM-DD')}) should be less than Date to (${moment(expiryAt).format('YYYY-MM-DD')})`, 'Form Invalid');
                    return;
                }
                const mat: NewAllocationMaterial = {
                    grade: m.heap.gradeCode,
                    description: m.gradedescription,
                    volume: m.weight,
                    uomId: uomId,
                    tl: m.tl,
                    comment: m.comment,
                    loadTimes: m.loadTimes.map(l => {
                        return l;
                    }),
                    containerSizeId: containerSizeId,
                    containerPackageId: packagingId,
                    heap: m.heap,
                    readyDate: !isNullOrUndefined(m.readyDate)? new Date(moment(m.readyDate).format('YYYY-MM-DD')) : null,
                    expiryDate: !isNullOrUndefined(m.expiryDate) ? new Date(moment(m.expiryDate).format('YYYY-MM-DD')) : null,
                    status: { id: m.status, name: null, isActive: true },
                };
                return mat;
            })
        };
        if(errorCount > 0)
        {
            this.isLoading = false;
            this.isSubmit = false;
            return;
        }
        this.allocationService.createNewRequest(model).subscribe(r => {
            this.toastr.success('Request successfully created', 'New Request');
            this.closeDialog();
        },
        err => {
            if (err.status === 200) {
                this.toastr.success('Request successfully created', 'New Request');
                this.closeDialog();
            }
            else {
                this.toastr.error('Something went wrong and the request could not be allocated. Please try again later.', 'New Request Failed');
                this.isLoading = false;
                this.isSubmit = false;
            }
        });
    }

    private closeDialog() {
        setTimeout(() => {
            this.isLoading = false;
            this.dialogRef.close('callServer');
        }, 1500);
    }

    reset(formDir: FormGroupDirective) {
        this.allocationForm.reset();
        formDir.resetForm();
        this.depot.setValue(this.defaultDepot);
        this.depotSelected = true;
    }


    addMaterial() {
        const lastItemIndex = this.materials.length - 1;
        if (lastItemIndex > -1) {
            const lastMaterial = this.materials.controls[lastItemIndex] as FormGroup;
            lastMaterial.controls.submitted.patchValue(true);
        }
        const group = this.newMaterial();
        group.reset();
        this.materials.push(group);
        this.toggleContainerSizeAndUom();
    }

    removeMaterial(index: number) {
        this.materials.removeAt(index);
        if (this.materials.length === 0) {
            this.addMaterial();
        }
    }

    copyMaterial(index: number) {
        this.cloning = true;
        const copyFrom = this.materials.controls[index] as FormGroup;
        this.addMaterial();
        const lastItemIndex = this.materials.length - 1;
        const copyTo = this.materials.controls[lastItemIndex] as FormGroup;
        for (const key of Object.keys(copyFrom.controls)) {
            copyTo.controls[key].patchValue(copyFrom.controls[key].value);
        }
        this.cloning = false;
    }

    addMaterialRow()
    {
        this.cloning = true;
        this.addMaterial();
        this.cloning = false;
    }

    newMaterial(): FormGroup {
        this.shownLoadTimes = undefined;
        const group = this.fb.group({
            readyDate: [''],
            expiryDate: [''],
            heap: [{ value: '', disabled: !this.depotSelected }, Validators.required],
            gradedescription: [{ value: '', disabled: !this.depotSelected }, Validators.required],
            weight: [{ value: '', disabled: !this.depotSelected }, Validators.pattern('^[0-9]{1,7}(\.[0-9]+)?$')],
            uom: [{ value: '', disabled: !this.depotSelected }],
            tl: [{ value: 1, disabled: !this.depotSelected }],
            loadTimes: this.fb.array([]),
            containerSize: [{ value: '', disabled: !this.depotSelected }],
            packaging: [{ value: '', disabled: !this.depotSelected }],
            comment: [{ value: '', disabled: !this.depotSelected }],
            status: [''],
            submitted: [false]
        }, {
            validators: [CustomValidators.requiredWhenSet('uom', 'weight', 'Unit', 'Weight')]
        }
        );
        group.controls.uom.patchValue(this.defaultUom.id);

        group.controls.tl.setValidators(Validators.required);

        group.controls.tl.updateValueAndValidity();

        group.controls.tl.valueChanges
            .subscribe(r => this.addRemoveLoadTimes(r, group));

        group.controls.heap.valueChanges
            .subscribe((h: HeapVm) => {
                if (!isNullOrUndefined(h)) {
                    if (!this.cloning) {
                        group.controls.gradedescription.patchValue(h.heapDesc);
                    }
                }
            });

        group.controls.heap.valueChanges
            .pipe(
                debounceTime(500),
                startWith(null),
                switchMap(v =>
                    this.filterHeaps(v)
                )
            )
            .subscribe((r: ApiPageResponse<HeapVm>) => {
                if(!isNullOrUndefined(r)){
                    this.filteredHeaps = r;
                }
            });

        return group;
    }

    private addRemoveLoadTimes(loads, group: FormGroup) {
        const loadTimes = group.controls.loadTimes as FormArray;
        const currentLoadCount = loadTimes.controls.length;
        if (loads < currentLoadCount) {
            loadTimes.controls.splice(loads - 1, currentLoadCount - loads);
        }
        if (loads > currentLoadCount) {
            for (let i = 0; i < loads - currentLoadCount; i++) {
                loadTimes.push(this.fb.group({ time: [{ value: '', disabled: !this.depotSelected }], increment: [{ value: undefined, disabled: !this.depotSelected }] }));
            }
        }
    }

    getUomName(id: number): string {
        return this.uoms.filter(u => u.id === id)[0].name;
    }

    selectDepot(depot: DepotVm) {
        this.selectedDepot = depot;
        this.depotSelected = !isNullOrUndefined(depot);
        this.materials.controls.forEach(c => c.enable());
    }

    clearDepot(input: HTMLInputElement) {
        input.value = '';
        this.depot.patchValue('');
        this.depotSelected = false;
    }

    clearHeap(input: HTMLInputElement, cntl: AbstractControl) {
        input.value = '';
        cntl.patchValue('');
    }

    showHideLoadTimes(index: number, event: MouseEvent) {
        if (this.shownLoadTimes === index) {
            this.shownLoadTimes = undefined;
        } else {
            this.shownLoadTimes = index;
        }
        this.loadTimesStyle = {
            top: `${event.y + 20}px` ,
            left: `${event.x - 90}px`,
            position: 'absolute',
            'z-index': '10',
            'background-color': 'white',
            'box-shadow': '3px 4px 4px #888888',
            border: 'solid 1px #888888',
            padding: '2px'
        };
    }
    hiddenLoadTimes(index: number) {
        return this.shownLoadTimes !== index;
    }

    private toggleContainerSizeAndUom() {
        this.enableContainerSizeAndUom();
    }

    private enableContainerSizeAndUom() {
        this.materials.controls.forEach((c, i) => {
            c.enable();
        });
    }

    getFirstLoadTimeValue(materialIndex: number) {
        if(!isNullOrUndefined(this.materials)
    && !isNullOrUndefined(this.materials.controls)
    && !isNullOrUndefined(this.materials.controls[materialIndex])){
            const ctrl = this.materials.controls[materialIndex] as any;
            return ctrl.controls.loadTimes.controls[0].value.time;
        }

        return null;
    }

    setIncrements(materialIndex:number, increment: string) {
        const incrementIndex = this.increments.indexOf(increment);
        if(incrementIndex > -1){
            const minutesToAdd = this.incrementMinutes[incrementIndex];
            const originalTime = this.getFirstLoadTimeValue(materialIndex);
            const originalMoment = moment.utc(originalTime, 'HH:mm');

            const ctrl = this.materials.controls[materialIndex] as any;

            ctrl.controls.loadTimes.controls.forEach((control, index) => {
                if(index == 0) //skip first one since that's the value we're basing increments off
                    return;
                const incrementedTime = originalMoment.add(minutesToAdd, 'minutes').format('HH:mm');
                control.patchValue({ time: incrementedTime });
            });
        }
    }

    commentPlaceholder(commentControl: AbstractControl) {                
        if (commentControl && commentControl.value &&  commentControl.value.length) {
            return `Provide a comment (${commentControl.value.length} / 500)`;
        }
        
        return 'Provide a comment (optional)';
    }

    gradeDescriptionPlaceholder(gradeDescriptionControl: AbstractControl) {        
        if (gradeDescriptionControl && gradeDescriptionControl.value &&  gradeDescriptionControl.value.length) {
            return `Material description (${gradeDescriptionControl.value.length} / 500)`;
        }
        
        return 'Material description';
    }
}
