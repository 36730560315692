import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { TerritoryService } from '../territory.service';
import { TerritoryVm } from '../../models';
import { Observable } from 'rxjs';

@Injectable()
export class TerritoryResolverService implements Resolve<TerritoryVm[]> {
	constructor(private territorysService: TerritoryService, private router: Router) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TerritoryVm[]> {
		return this.territorysService.searchTerritorys('*');
	}
}