<div class="dialog-header">Update Route To Market</div>
<div class="search-box">
  <form [formGroup]="routeToMarketFilterForm" (ngSubmit)="queryServer(routeToMarketFilterForm)">
    <div class="row-box">
      <div class="small-margin-right">
        <mat-form-field mat-no-float>
          <input matInput type="search" placeholder="Origin" formControlName="depot" readonly="true">
        </mat-form-field>
      </div>
      <div class="small-margin-right">
        <mat-form-field mat-no-float>
          <input matInput type="search" placeholder="Grade" formControlName="grade" oninput="this.value = this.value.toUpperCase()">
        </mat-form-field>
      </div>
      <div class="button-container">
        <button type="submit" mat-stroked-button>Apply Filter</button>
      </div>
    </div>
  </form>
</div>

<app-spinner [displayProgressSpinner]="spinnerVisible"  [container]="routeToMarketTable" #spinner></app-spinner>

<div class="routeToMarketUpdateForm" #routeToMarketTable>
  <table mat-table border="0" cellpadding="0" cellspacing="0" class="table table-hover table-condensed custom-table" [dataSource]="dataSource" matSort (matSortChange)="sortByInfo($event)" multiTemplateDataRows>
    <ng-container matColumnDef="grade">
      <th class="interactive heading-row" mat-header-cell *matHeaderCellDef mat-sort-header="grade" sortActionDescription="Sort by grade">Grade</th>
			<td mat-cell *matCellDef="let element">{{element.grade}}</td>
    </ng-container>
    <ng-container matColumnDef="route">
      <th class="interactive heading-row" mat-header-cell *matHeaderCellDef mat-sort-header="route" sortActionDescription="Sort by destination">Destination</th>
      <td mat-cell *matCellDef="let element">
				<mat-form-field floatLabel="never">
					<input matInput [value]="element.route" (keyup)="onRouteToMarketChange($event, element)" [(ngModel)]="element.route">
				</mat-form-field>
			</td>
    </ng-container>
    <ng-container matColumnDef="effectiveDate">
      <th class="interactive heading-row" mat-header-cell *matHeaderCellDef mat-sort-header="effectiveDate" sortActionDescription="Sort by effective date">Effective Date</th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field floatLabel="never">
          <input matInput [matDatepicker]="picker" (dateChange)="onRouteToMarketChange($event, element)" [value]="element.effectiveDate" [(ngModel)]="element.effectiveDate">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
			</td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th class="interactive heading-row" mat-header-cell *matHeaderCellDef></th>
			<td mat-cell *matCellDef="let element"><button mat-button (click)="deleteRouteToMarket(element)">
        <i class="fa fa-trash"></i>
    </button></td>
    </ng-container>
    <tr class="heading-row sticky" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<div mat-dialog-actions class="buttons-row">
    <button mat-flat-button type="button" (click)="cancel()">Cancel</button>
    <button mat-flat-button type="submit" (click)="update()" color="primary" [disabled]="!routeToMarketUpdated || routeToMarketInvalid">Update</button>
</div>

