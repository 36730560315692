<mat-card layout="column" style="padding: 1px;padding-top:0px;">
  <div id="content">
    <mat-toolbar class="md-hue-3" style="background-color:#eceff1;height: 37px;">
      <form [formGroup]="allocationTableViewForm" novalidate>
        <mat-form-field class=mat-Filer matTooltip="Field chooser" style="width: 18px;margin-right: 20px;">
          <mat-select formControlName="columnsList" multiple (selectionChange)="updateColumns($event);">
            <mat-option *ngFor="let column of columnList" [value]="column"
              (onSelectionChange)="showAllColumns($event);">
              {{column}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class=mat-Filer matTooltip="Field chooser"
          [ngClass]="!(requestTableViews?.length > 0) ? 'display-hidden' : 'display-block'">
          <mat-select formControlName="allocationTableView">
            <mat-option *ngFor="let view of requestTableViews" [value]="view">{{view.viewName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </form>
      <div class="md-toolbar-tools" style="color:rgb(46, 73, 97);">
        <button mat-button (click)="searchClicked()">
          <i class="fa fa-search"></i>
        </button>
        <!-- <button mat-button class="md-raised" (click)="newRequest();" [disabled]="isLoading">New Request</button> -->
        <div *ngIf="canAllocateRequests===true" class="header-container">
          <button mat-button class="hide show-gt-xs header-title" [disabled]="isDisabled()"
            (click)="editAllocation()">Edit Allocation{{selectedAllocations.length > 1 ? 's' : ''}}</button>
          <button mat-button class="md-raised mat-primary header-title hide show-gt-xs" [disabled]="isDisabled()"
            (click)="deleteAllocations()">Delete Allocation{{selectedAllocations.length > 1 ? 's' :
            ''}}</button>
          <button mat-button [matMenuTriggerFor]="lifecycleMenu" class="header-title" [disabled]="isDisabled()">Set
            Allocation
            Lifecycle</button>
          <mat-menu #lifecycleMenu="matMenu">
            <button mat-menu-item *ngFor="let lc of lifecycles" (click)="setLifecycle(lc)">{{lc.name}}</button>
          </mat-menu>
          <button mat-button [matMenuTriggerFor]="bookingStatusMenu" class="header-title" [disabled]="isDisabled()">Set
            Booking
            Status</button>
          <mat-menu #bookingStatusMenu="matMenu">
            <button mat-menu-item *ngFor="let bookingStatus of allocationBookingStatuses" 
            (click)="setBookingStatus(bookingStatus.value)">
            {{ bookingStatus.desc }}</button>
          </mat-menu>
          <button mat-button [matMenuTriggerFor]="emailMenu" class="header-title"
            [disabled]="isDisabled()">Emails</button>
          <mat-menu #emailMenu>
            <button mat-menu-item (click)="sendDeliveryRequestEmail()">Delivery appointment request</button>
            <button mat-menu-item (click)="sendLogisticsEmail()">Logistics</button>
          </mat-menu>
          <button mat-button class="hide show-gt-xs header-title" [disabled]="selectedAllocations.length === 0"
            (click)="printAppointments()">Print</button>
        </div>
        <span flex="" style="-ms-flex: 1; -webkit-flex: 1; flex: 1;"></span>
        <button mat-button class="md-raised mat-button mat-ink-ripple hide-xs header-title" aria-label="Export to Excel"
          testName="exportToExcel" (click)="exportToExcel()">Export to Excel</button>
        <button mat-button class="md-raised mat-button mat-ink-ripple hide-xs header-title"
          aria-label="Pick Up Dt Export" testName="pickUpDateExport" (click)="pickUpDateExport()"
          *ngIf="showSchedulerReport">Pick Up Dt Export</button>
        <button mat-button class="md-raised mat-button mat-ink-ripple hide-xs header-title"
          aria-label="Allocated Dt Export" testName="allocatedDateExport" (click)="allocatedDateExport()"
          *ngIf="showSchedulerReport">Allocated Dt Export</button>
        <button mat-button class="md-raised mat-button mat-ink-ripple hide-xs" aria-label="Allocated Dt Export"
          testName="ultimateSchedulerExport" (click)="ultimateSchedulerExport()"
          *ngIf="showUltimateSchedulerReport">Scheduler (US)</button>
      </div>
    </mat-toolbar>
    <div [hidden]="!showSearch" class="search-box">
      <form [formGroup]="searchForm" (ngSubmit)="search(searchForm.value)">
        <div class="row-box">
          <div class="small-margin-right">
            <mat-form-field style="margin: 0px 0px; max-width: 250px;">
              <input type="text" matInput formControlName="depot" [matAutocomplete]="depotAuto" placeholder="Depot"
                #depotInput oninput="this.value = this.value.toUpperCase()">
            </mat-form-field>

            <mat-autocomplete #depotAuto="matAutocomplete" [displayWith]="displayDepot">
              <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
              <mat-option *ngFor="let depot of filteredDepots?.items " [value]="depot">
                {{depot?.depotNumber + ' - ' + depot?.depotName}}</mat-option>
            </mat-autocomplete>
          </div>
          <div class="small-margin-right">
            <mat-form-field style="margin: 0px 0px; max-width: 80px;" mat-no-float>
              <input matInput type="search" placeholder="Grade Group" formControlName="gradeGroupName">
            </mat-form-field>
          </div>
          <div class="small-margin-right">
            <mat-form-field style="margin: 0px 0px; max-width: 120px;" mat-no-float>
              <input matInput type="search" placeholder="Grade" formControlName="grade" oninput="this.value = this.value.toUpperCase()">
            </mat-form-field>
          </div>
          <div class="small-margin-right">
            <mat-form-field style="margin: 0px 0px; max-width: 80px;" mat-no-float>
              <input matInput type="search" placeholder="Sales Grade" formControlName="salesGrade">
            </mat-form-field>
          </div>
          <div class="small-margin-right">
            <mat-form-field matTooltip="Classification">
              <mat-label>Classification</mat-label>
              <mat-select multiple formControlName="classifications">
                <mat-option *ngFor="let classification of allocationClassifications" [value]="classification.key"
                  (onSelectionChange)="filterSelection($event, classification);">
                  {{classification.value}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="small-margin-right">
            <mat-form-field style="margin: 0px 0px; max-width: 120px;" mat-no-float>
              <input matInput type="search" placeholder="Contract Ref" formControlName="contractRef"
                     oninput="this.value = this.value.toUpperCase()">
            </mat-form-field>
          </div>
          <div class="small-margin-right">
            <mat-form-field style="margin: 0px 0px; max-width: 120px;" mat-no-float>
              <input matInput type="text" placeholder="Allocation Number" formControlName="allocationNumber">
            </mat-form-field>
          </div>
          <div class="small-margin-right">
            <mat-form-field style="margin: 0px 0px; max-width: 120px;" mat-no-float>
              <input matInput type="search" placeholder="Haulier" formControlName="haulierSearchText"
                     oninput="this.value = this.value.toUpperCase()">
            </mat-form-field>
          </div>
          <div class="small-margin-right">
            <mat-form-field style="margin: 0px 0px; max-width: 120px;" mat-no-float>
              <input matInput type="search" placeholder="Ticket Number" formControlName="ticketNumber">
            </mat-form-field>
          </div>
          <div class="small-margin-right">
            <mat-form-field style="margin: 0px 0px; max-width: 120px;" mat-no-float>
              <input matInput type="search" placeholder="Party" formControlName="partySearchText"
                     oninput="this.value = this.value.toUpperCase()">
            </mat-form-field>
          </div>
          <div class="small-margin-right" style="margin-top: auto; margin-bottom: auto;">
            <mat-form-field matTooltip="BookingStatus">
              <mat-select formControlName="isBooked" style="text-transform:none; font-weight:normal"
                placeholder="Booking Status">
                <mat-option *ngFor="let bookingStatus of allocationBookingStatuses" [value]="bookingStatus.value">
                  {{ bookingStatus.desc }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="small-margin-right" style="margin-top: auto; margin-bottom: auto;">

            <mat-checkbox formControlName="includeDeleted">
              Include Deleted
            </mat-checkbox>

          </div>
        </div>
        <div class="row-box">
          <div class="small-margin-right" style="margin-left: -10px;">
            <app-date-range (onDatePicked)="setPickerDate($event, 'allocationRange')" formControlName="dateRange" [fromDate]="allocationRange?.dateFrom" [toDate]="allocationRange?.dateTo"
            [dateType]="allocationRangeType" [dateValue]="allocationDatePreference"></app-date-range>
          </div>

          <div class="small-margin-right" style="margin-left: -10px;">
            <app-date-range (onDatePicked)="setPickerDate($event, 'pickup')" label="Pick up from date" formControlName="pickupFromDateRange" [fromDate]="pickupRange?.dateFrom"
            [toDate]="pickupRange?.dateTo" [dateType]="pickupdateType" [dateValue]="pickUpFromDatePreference">
            </app-date-range>
          </div>
          <div class="small-margin-right" style="margin-top: auto; margin-bottom: auto;">
            <mat-form-field matTooltip="TransportResponsibility">
              <mat-select formControlName="transportResponsibility" style="text-transform:none; font-weight:normal"
                placeholder="Transport Responsibility">
                <mat-option *ngFor="let tr of allocationTransportResponsibilities" [value]="tr.value">
                  {{ tr.desc }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="small-margin-right" style="margin-top: auto; margin-bottom: auto;">
            <mat-form-field matTooltip="Preset filters" >
              <mat-label>Allocation Status:</mat-label>
              <mat-select #matRef multiple formControlName="allocationStatuses" [compareWith]="compareAllocationStatus">
                <mat-option *ngFor="let status of allocationStatuses" [value]="status"
                  (onSelectionChange)="setAllocationSelection($event, status);">{{status.desc}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="small-margin-right" style="margin-top: auto; margin-bottom: auto;">
            <mat-form-field matTooltip="Preset filters">
              <mat-label>KPI type:</mat-label>
              <mat-select #matRef multiple>
                <mat-option *ngFor="let status of kpiTypes" [value]="status.value"
                  (onSelectionChange)="setKpiTypeSelection($event, status);">{{status.desc}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="small-margin-right" style="margin-top: auto; margin-bottom: auto;">
            <mat-form-field matTooltip="Preset filters">
              <mat-label>Contract Header Status:</mat-label>
              <mat-select #matRef multiple formControlName="contractHeaderStatuses" [compareWith]="comparecontractHeaderStatus">
                <mat-option *ngFor="let status of contractHeaderStatuses" [value]="status"
                  (onSelectionChange)="setContractHeaderSelection($event, status);">{{status.desc}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="small-margin-right" style="margin-top: auto; margin-bottom: auto;">
            <mat-form-field matTooltip="Preset filters">
              <mat-label>Filters:</mat-label>
              <mat-select #matRef multiple>
                <mat-option *ngFor="let filter of pageModel?.filterNames" [value]="filter.id"
                  (onSelectionChange)="filterSelection($event, filter);">{{filter.filterName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="small-margin-right" style="margin-top: auto; margin-bottom: auto;">
            <mat-form-field matTooltip="NoOfResults">
              <mat-select formControlName="noOfResults" style="text-transform:none; font-weight:normal"
                placeholder="No Of Results">
                <mat-option *ngFor="let noOfResults of allocationsNoOfResults" [value]="noOfResults.value">
                  {{ noOfResults.value }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="button-container" style="margin-top: 10px;">
            <button mat-stroked-button (click)="selectAll()" type="button">Select All</button>
            <button type="submit" mat-stroked-button>Apply Filter</button>
            <button mat-stroked-button (click)="clearFilter(searchForm.value)" type="button">Clear Filters</button>
            <button mat-stroked-button (click)="copyLink()" type="button">Copy Link</button>
          </div>
        </div>
      </form>
    </div>
    <app-spinner [displayProgressSpinner]="isLoading" #spinner></app-spinner>
    <mat-tab-group (selectedTabChange)="onTabChanged($event);" *ngIf="showAllocationCalendar; else allocationTable">
      <mat-tab label="Allocation Table">
        <ng-container [ngTemplateOutlet]="allocationTable">
        </ng-container>
      </mat-tab>
      <mat-tab label="Calendar">
        <event-calendar-component [allocations]="allocationSubject" [tableState]="tableState"
          (spinnerEmitter)="setSpinner($event)" (selectedallocation)='calendaredit($event)'>
        </event-calendar-component>
      </mat-tab>
    </mat-tab-group>
    <ng-template #allocationTable>
      <div style="width: auto;overflow-y: auto;" [ngStyle]="{'height.px': tableHeight}" #mainTable>
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort (matSortChange)="sortByInfo($event)"
          name="pendingtable" id="pending-table" cellpadding="0" cellspacing="0"
          class="table table-hover table-condensed custom-table" style="width:auto" border="0">

          <ng-container matColumnDef="toggle">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="interactive heading-row"
              sortActionDescription="Sort by number">
            </th>
            <td mat-cell *matCellDef="let element" class="expand-icon">
              <span *ngIf="isGrouped(element)" (click)="toggleGroup(element)" class="icon">
                <i class="fa fa-plus-circle" *ngIf="!element?.showingGroup"></i>
                <i class="fa fa-minus-circle" *ngIf="element?.showingGroup"></i>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="Allocation Number">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="AllocationNumber" class="interactive heading-row"
              sortActionDescription="Sort by Allocation Number">
              Allocation Number
            </th>
            <td mat-cell *matCellDef="let element"> {{isGrouped(element) ? 'Multi' :
              element?.allocationNumber}} </td>
          </ng-container>

          <ng-container matColumnDef="Depot">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="Depot" class="interactive heading-row"
              sortActionDescription="Sort by Depot">
              Depot
            </th>
            <td mat-cell *matCellDef="let element"> {{element.depot}} </td>
          </ng-container>

          <!-- <ng-container matColumnDef="salesGrade">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="salesGrade" class="interactive heading-row"
                            sortActionDescription="Sort by Sales Grader">
                            Sales Grade
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.salesGrade} </td>
                    </ng-container> -->

          <ng-container matColumnDef="Allocation Status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="ContractLineAllocationStatus"
              class="interactive heading-row" sortActionDescription="Sort by Allocation Status">
              Allocation Status
            </th>
            <td mat-cell *matCellDef="let element">
              {{ !element?.active ? 'Deleted' :
              parseAllocationStatus(element?.contractLineAllocationStatus)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="Booking Status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="BookingStatus"
              class="interactive heading-row" sortActionDescription="Sort by Booking Status">
              Booking Status
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.isBooked ? 'Booked' : 'Pending' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Transport Responsibility">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="TransportResponsibility"
              class="interactive heading-row" sortActionDescription="Sort by Transport Responsibility">
              Transport Responsibility
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element?.classification == 'Domestic' ? 'Depot' : 'Central' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Contract Header Status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="ContractHeaderStatus" class="interactive heading-row"
              sortActionDescription="Sort by Contract Header Status">
              Contract Header Status
            </th>
            <td mat-cell *matCellDef="let element">
              {{ !element?.contractHeaderStatus ? 'Deleted' :
              parseContractHeaderStatus(element?.contractHeaderStatus)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="History">
            <th mat-header-cell *matHeaderCellDef class="interactive heading-row"></th>
            <td mat-cell *matCellDef="let element">
              <div class="icon-placeholder">
                <span *ngIf="element?.hasHistory && canAllocateRequests"
                  (click)="showHistory(element.allocationNumber)">
                  <i class="fa fa-history"></i>
                </span>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="Request Date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="RequestDate" class="interactive heading-row"
              sortActionDescription="Sort by Request Date">
              Request Date
            </th>
            <td mat-cell *matCellDef="let element"> {{element.requestDate |momentUtcAsLocaleDate}} </td>
          </ng-container>

          <ng-container matColumnDef="Allocated Date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="AllocatedDate" class="interactive heading-row"
              sortActionDescription="Sort by Allocated Date">
              Allocated Date
            </th>
            <td mat-cell *matCellDef="let element"> {{element?.allocatedDate | momentUtcAsLocaleDate}}</td>
          </ng-container>

          <ng-container matColumnDef="Allocated Weight">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="AllocatedWeight" class="interactive heading-row"
              sortActionDescription="Sort by Allocated Weight">
              Allocated Weight
            </th>
            <td mat-cell *matCellDef="let element"> {{element.allocatedWeight | number: '1.0-1'}} </td>
          </ng-container>

          <ng-container matColumnDef="Delivered Weight">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="DeliveredWeight" class="interactive heading-row"
              sortActionDescription="Sort by deliveredWeight">
              Delivered Weight
            </th>
            <td mat-cell *matCellDef="let element">{{element?.deliveredWeight | number : '1.0-1'}}
              {{element?.uomCode}} </td>
          </ng-container>

          <ng-container matColumnDef="Contract Ref">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="ContractRef" class="interactive heading-row"
              sortActionDescription="Sort by Contract Ref">
              Contract Ref
            </th>
            <td mat-cell *matCellDef="let element"> {{element?.contractRef}}</td>
          </ng-container>

          <ng-container matColumnDef="Party">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="PartyName" class="interactive heading-row"
              sortActionDescription="Sort by Part name">
              Party
            </th>
            <td mat-cell *matCellDef="let element" [matTooltip]="element.partyName">{{element?.party}} -
              {{element?.partyName | shorten : '40'}}</td>
          </ng-container>

          <ng-container matColumnDef="Classification">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="Classification" class="interactive heading-row"
              sortActionDescription="Sort by Classification">
              Classification
            </th>
            <td mat-cell *matCellDef="let element"> {{element.classification}} </td>
          </ng-container>

          <ng-container matColumnDef="Notes/Comments">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="Comment" class="interactive heading-row"
              sortActionDescription="Sort by request comment">
              Notes/Comments
            </th>
            <td mat-cell *matCellDef="let element">
              <div class="icon-placeholder comment-placeholder" *ngIf="element.requestId">
                <span (click)="showComments(element)">
                  <i class="fa fa-lg fa-commenting" *ngIf="element.latestComment"></i>
                  <i class="fa fa-lg fa-comment" *ngIf="!element.latestComment"></i>
                </span>
              </div>
              <div *ngIf="element?.latestComment">
                <div class="note-comment">
                  {{element?.latestComment}}
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="Ready Date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="ReadyDate" class="interactive heading-row"
              sortActionDescription="Sort by Ready Date">
              Ready Date
            </th>
            <td mat-cell *matCellDef="let element"> {{element?.readyDate | momentUtcAsLocaleDate}} </td>
          </ng-container>

          <ng-container matColumnDef="Pick-up From Date & Time">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="RequestedMovementDate"
              class="interactive heading-row" sortActionDescription="Sort by requestedMovementDate">
              Pick-up From Date & Time
            </th>
            <td mat-cell *matCellDef="let element"> {{element?.requestedMovementDate | date: 'MMM dd, yyyy hh:mm
              a' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Pick-up To Date & Time">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="RequestedMovementDateEnd"
              class="interactive heading-row" sortActionDescription="Sort by requestedMovementDateEnd">
              Pick-up To Date & Time
            </th>
            <td mat-cell *matCellDef="let element"> {{element?.requestedMovementDateEnd |
              momentUtcAsLocaleDate}} </td>
          </ng-container>

          <ng-container matColumnDef="Container Type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="ContainerTypeName" class="interactive heading-row"
              sortActionDescription="Sort by containerTypeName">
              Container Type
            </th>
            <td mat-cell *matCellDef="let element"> {{element?.containerTypeName }} </td>
          </ng-container>

          <ng-container matColumnDef="Instructions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="Instructions" class="interactive heading-row"
              sortActionDescription="Sort by instructions">
              Instructions
            </th>
            <td mat-cell *matCellDef="let element" [matTooltip]="element.instructions">
              {{element?.instructions | shorten : '30'}} </td>
          </ng-container>

          <ng-container matColumnDef="Haulier">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="Haulier" class="interactive heading-row"
              sortActionDescription="Sort by haulier">
              Haulier
            </th>
            <td mat-cell *matCellDef="let element"> {{element?.haulier }} </td>
          </ng-container>

          <ng-container matColumnDef="Material Description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="MaterialDescription" class="interactive heading-row"
              sortActionDescription="Sort by materialDescription">
              Material Description
            </th>
            <td mat-cell *matCellDef="let element"> {{element?.materialDescription }} </td>
          </ng-container>

          <ng-container matColumnDef="Grade">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="MultiGradeDetails" class="interactive heading-row"
              sortActionDescription="Sort by multiGradeDetails">
              Grade
            </th>
            <td mat-cell *matCellDef="let element" [matTooltip]="element.salesGrade">
              {{ printGrade(element?.grade, element?.salesGrade )| shorten : '30'}}
              <app-multi-grade-allocation-detail [detail]="element.multiGradeDetails">
              </app-multi-grade-allocation-detail>
            </td>
          </ng-container>

          <ng-container matColumnDef="FCF Serve">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="FirstComeFirstServe" class="interactive heading-row"
              sortActionDescription="Sort by firstComeFirstServe">
              FCF Serve
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-checkbox *ngIf="!isGrouped(element)" [checked]="element?.firstComeFirstServe"
                (change)="fcfsChanged(element, $event)">
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="Missed Drop Off">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="MissedDropOff" class="interactive heading-row"
              sortActionDescription="Sort by missedDropOff">
              Missed Drop Off
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-checkbox *ngIf="!isGrouped(element)" [checked]="element?.missedDropOff"
                (change)="missedDropOffChanged(element, $event)">
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="AwaitingDispatch">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="AwaitingDispatch" class="interactive heading-row"
              sortActionDescription="Sort by awaitingDispatch">
              Awaiting Dispatch
            </th>
            <td mat-cell *matCellDef="let element">
              {{element?.awaitingDispatch ? 'Y' : element?.awaitingDispatch === null ? '' : 'N'}}
            </td>
          </ng-container>

          <ng-container matColumnDef="Apt Number">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="AptNumber" class="interactive heading-row"
              sortActionDescription="Sort by aptNumber">
              Apt Number
            </th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="!isGrouped(element)" (click)="printAppointment(element.contractLineAllocationId)">
                <i class="fa fa-download"></i>
              </span>{{isGrouped(element) ? '' :element?.aptNumber}}
            </td>
          </ng-container>

          <ng-container matColumnDef="Apt Date & Time">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="AptDateTime" class="interactive heading-row"
              sortActionDescription="Sort by aptDateTime">
              Apt Date & Time
            </th>
            <td mat-cell *matCellDef="let element"> {{isGrouped(element) ? '' :element?.aptDateTime |
              momentUtcAsLocaleDateTime}} </td>
          </ng-container>

          <ng-container matColumnDef="Apt Date & Time To">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="AptDateTimeTo" class="interactive heading-row"
              sortActionDescription="Sort by aptDateTimeTo">
              Apt Date & Time To
            </th>
            <td mat-cell *matCellDef="let element"> {{isGrouped(element) ? '' :element?.aptDateTimeTo |
              momentUtcAsLocaleDateTime}}</td>
          </ng-container>

          <ng-container matColumnDef="Ticket Date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="TicketDate" class="interactive heading-row"
              sortActionDescription="Sort by ticketDate">
              Ticket Date
            </th>
            <td mat-cell *matCellDef="let element"> {{isGrouped(element) ? '' :element?.ticketDate |
              momentUtcAsLocaleDateTime}}</td>
          </ng-container>

          <ng-container matColumnDef="Ticket Number">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="TicketNumber" class="interactive heading-row"
              sortActionDescription="Sort by ticketNumber">
              Ticket Number
            </th>
            <td mat-cell *matCellDef="let element"> {{isGrouped(element) ? '' :element?.ticketNumber}}</td>
          </ng-container>

          <ng-container matColumnDef="Additional Costs">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="AdditionalCosts" class="interactive heading-row"
              sortActionDescription="Sort by additionalCosts">
              Additional Costs
            </th>
            <td mat-cell *matCellDef="let element" class="additional-cost">
              <table class="sub-table">
                <tbody>
                  <tr *ngFor="let ac of element?.additionalCosts">
                    <td>{{ac.costCode}}</td>
                    <td>{{ac.partyAccountNo}}</td>
                    <td>{{ac.cost}}/{{ac.costUomCode}}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </ng-container>

          <ng-container matColumnDef="KPI Type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="KpiDescription" class="interactive heading-row"
              sortActionDescription="Sort by kpiDescription">
              KPI Type
            </th>
            <td mat-cell *matCellDef="let element"> {{element.kpiDescription | shorten : '40'}} </td>
          </ng-container>

          <ng-container matColumnDef="KPI Cost">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="KpiCost" class="interactive heading-row"
              sortActionDescription="Sort by kpiCost">
              KPI Cost
            </th>
            <td mat-cell *matCellDef="let element"> {{element.kpiCost}} </td>
          </ng-container>

          <ng-container matColumnDef="Dock Receipt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="DockReceipt" class="interactive heading-row"
              sortActionDescription="Sort by dockReceipt">
              Dock Receipt
            </th>
            <td mat-cell *matCellDef="let element"> {{ getLifecycle(3, element) }} </td>
          </ng-container>

          <ng-container matColumnDef="Booking Requested">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="BookingRequested" class="interactive heading-row"
              sortActionDescription="Sort by bookingRequested">
              Booking Requested
            </th>
            <td mat-cell *matCellDef="let element"> {{ getLifecycle(4, element) }} </td>
          </ng-container>

          <ng-container matColumnDef="Awaiting Appointment">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="AwaitingAppointment" class="interactive heading-row"
              sortActionDescription="Sort by awaitingAppointment">
              Awaiting Appointment
            </th>
            <td mat-cell *matCellDef="let element"> {{ getLifecycle(8, element) }} </td>
          </ng-container>

          <ng-container matColumnDef="Pending Dispatch">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="PendingDispatch" class="interactive heading-row"
              sortActionDescription="Sort by pendingDispatch">
              Pending Dispatch
            </th>
            <td mat-cell *matCellDef="let element"> {{ getLifecycle(5, element) }} </td>
          </ng-container>

          <ng-container matColumnDef="Processing Dispatched">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="ProcessingDispatched" class="interactive heading-row"
              sortActionDescription="Sort by processingDispatched">
              Processing Dispatched
            </th>
            <td mat-cell *matCellDef="let element"> {{ getLifecycle(7, element) }} </td>
          </ng-container>

          <ng-container matColumnDef="Dispatched">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="Dispatched" class="interactive heading-row"
              sortActionDescription="Sort by dispatched">
              Dispatched
            </th>
            <td mat-cell *matCellDef="let element"> {{ getLifecycle(6, element) }} </td>
          </ng-container>

          <ng-container matColumnDef="Inspection Date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="InspectionDate" class="interactive heading-row"
              sortActionDescription="Sort by inspectionDate">
              Inspection Date
            </th>
            <td mat-cell *matCellDef="let element"> {{ getLifecycle(2, element) }} </td>
          </ng-container>



          <!-- allocation-detail-row -->
          <ng-container matColumnDef="allocationDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
              <table class="table sub-table" [style.width.%]="subTableWidth" *ngIf="element.showingGroup">
                <thead>
                  <th *ngIf="allocationDisplayedColumns.includes('Allocation Number')" class="interactive">
                    Allocation Number</th>
                  <th *ngIf="allocationDisplayedColumns.includes('Depot')">Depot</th>
                  <th *ngIf="allocationDisplayedColumns.includes('Allocation Status')" class="interactive">
                    Allocation Status</th>
                  <th *ngIf="allocationDisplayedColumns.includes('Request Date')">Request Date</th>
                  <th *ngIf="allocationDisplayedColumns.includes('Allocated Date')" class="interactive hide-sm hide-xs">
                    Allocated Date</th>
                  <th *ngIf="allocationDisplayedColumns.includes('Allocated Weight')">Allocated Weight
                  </th>
                  <th *ngIf="allocationDisplayedColumns.includes('Delivered Weight')">Delivered Weight
                  </th>
                  <th *ngIf="allocationDisplayedColumns.includes('Contract Ref')">Contract Ref</th>
                  <th *ngIf="allocationDisplayedColumns.includes('Party')" class="interactive">Party</th>
                  <th *ngIf="allocationDisplayedColumns.includes('Classification')" class="interactive">
                    Classification</th>
                  <th *ngIf="allocationDisplayedColumns.includes('Ready Date')" class="interactive">Ready
                    Date</th>
                  <th *ngIf="allocationDisplayedColumns.includes('Pick-up From Date & Time')">
                    Pick-up From Date & Time
                  </th>
                  <th *ngIf="allocationDisplayedColumns.includes('Pick-up To Date & Time')">
                    Pick-up To Date & Time
                  </th>
                  <th *ngIf="allocationDisplayedColumns.includes('Container Type')" class="interactive">
                    Container Type</th>
                  <th *ngIf="allocationDisplayedColumns.includes('Notes/Comments')" class="interactive">
                    Notes/Comments</th>
                  <th *ngIf="allocationDisplayedColumns.includes('Instructions')" class="interactive">
                    Instructions</th>
                  <th *ngIf="allocationDisplayedColumns.includes('Haulier')" class="interactive">Haulier
                  </th>
                  <th *ngIf="allocationDisplayedColumns.includes('Material Description')" class="interactive">
                    Material Description</th>
                  <th *ngIf="allocationDisplayedColumns.includes('Grade')" class="interactive">Grade</th>
                  <th *ngIf="allocationDisplayedColumns.includes('Sales Grade')" class="interactive">Sales
                    Grade</th>
                  <th *ngIf="allocationDisplayedColumns.includes('FCF Serve')" class="interactive">FCF
                    Serve</th>
                  <th *ngIf="allocationDisplayedColumns.includes('Missed Drop Off')" class="interactive">
                    Missed Drop Off</th>
                  <th *ngIf="allocationDisplayedColumns.includes('Awaiting Dispatch')" appTableSort='AwaitingDispatch'
                    (sort)="sortByInfo($event)" class="interactive">
                    Awaiting Dispatch</th>
                  <th *ngIf="allocationDisplayedColumns.includes('Apt Number')" class="interactive">Apt
                    Number</th>
                  <th *ngIf="allocationDisplayedColumns.includes('Apt Date & Time')"
                    class="interactive hide-sm hide-xs">
                    Apt Date & Time</th>
                  <th *ngIf="allocationDisplayedColumns.includes('Apt Date & Time To')"
                    class="interactive hide-sm hide-xs">
                    Apt Date & Time To</th>
                  <th *ngIf="allocationDisplayedColumns.includes('Ticket Date')">Ticket Date</th>
                  <th *ngIf="allocationDisplayedColumns.includes('Ticket Number')">Ticket Number</th>
                  <th *ngIf="allocationDisplayedColumns.includes('Additional Costs')">Additional Costs</th>
                  <th *ngIf="allocationDisplayedColumns.includes('Dock Receipt')" class="interactive">
                    Dock Receipt</th>
                  <th *ngIf="allocationDisplayedColumns.includes('Booking Requested')" class="interactive">
                    Booking Requested</th>
                  <th *ngIf="allocationDisplayedColumns.includes('Awaiting Appointment')" class="interactive">
                    Awaiting Appointment</th>
                  <th *ngIf="allocationDisplayedColumns.includes('Pending Dispatch')" class="interactive">
                    Pending Dispatch</th>
                  <th *ngIf="allocationDisplayedColumns.includes('Processing Dispatched')" class="interactive">
                    Processing Dispatched</th>
                  <th *ngIf="allocationDisplayedColumns.includes('Dispatched')" class="interactive">
                    Dispatched</th>
                  <th *ngIf="allocationDisplayedColumns.includes('Inspection Date')" class="interactive">
                    Inspection Date</th>
                    <th *ngIf="allocationDisplayedColumns.includes('Booking Status')" class="interactive">
                      Booking Status</th>
                    <th *ngIf="allocationDisplayedColumns.includes('Transport Responsibility')" class="interactive">
                      Transport Responsibility</th>
                </thead>
                <tbody>
                  <tr *ngFor="let g of getGroupAllocations(element)" (click)="selectAllocation(g, $event, false)"
                    [ngClass]="{ 'selected' : g.isSelected, ' deleted' : !g.active }">
                    <td *ngIf="allocationDisplayedColumns.includes('Allocation Number')">
                      {{g?.allocationNumber}}
                    </td>
                    <td *ngIf="allocationDisplayedColumns.includes('Depot')">{{g?.depot}}</td>
                    <td *ngIf="allocationDisplayedColumns.includes('Allocation Status')">
                      {{parseAllocationStatus(g?.contractLineAllocationStatus)}}
                    </td>
                    <td *ngIf="allocationDisplayedColumns.includes('Request Date')" class="hide-sm hide-xs">
                      {{g?.requestDate |
                      momentUtcAsLocaleDate}}</td>
                    <td *ngIf="allocationDisplayedColumns.includes('Allocated Date')" class="hide-sm hide-xs">
                      {{g?.allocatedDate | momentUtcAsLocaleDate}}</td>
                    <td *ngIf="allocationDisplayedColumns.includes('Allocated Weight')">
                      {{g?.allocatedWeight | number : '1.0-1'}}
                      {{g?.uomCode}}</td>
                    <td *ngIf="allocationDisplayedColumns.includes('Delivered Weight')">
                      {{g?.deliveredWeight | number : '1.0-1'}}
                      {{g?.uomCode}}</td>
                    <td *ngIf="allocationDisplayedColumns.includes('Contract Ref')">{{g?.contractRef}}
                    </td>
                    <td *ngIf="allocationDisplayedColumns.includes('Party')">{{g?.party}} -
                      {{g?.partyName}}</td>
                    <td *ngIf="allocationDisplayedColumns.includes('Classification')">
                      {{g?.classification}}</td>
                    <td *ngIf="allocationDisplayedColumns.includes('Ready Date')" class="hide-sm hide-xs">
                      {{g?.readyDate | momentUtcAsLocaleDate}}</td>
                    <td *ngIf="allocationDisplayedColumns.includes('Pick-up From Date & Time')" class="hide-sm hide-xs">
                      {{g?.requestedMovementDate | date: 'MMM dd, yyyy hh:mm a' }}
                    </td>
                    <td *ngIf="allocationDisplayedColumns.includes('Pick-up To Date & Time')" class="hide-sm hide-xs">
                      {{g?.requestedMovementDateEnd | momentUtcAsLocaleDateTime}}
                    </td>
                    <td *ngIf="allocationDisplayedColumns.includes('Container Type')">
                      {{g?.containerTypeName}}</td>
                    <td *ngIf="allocationDisplayedColumns.includes('Notes/Comments')" class="hide-sm hide-xs">
                      <div class="icon-placeholder comment-placeholder" *ngIf="g?.requestId">
                        <span (click)="showComments(g)">
                          <i class="fa fa-lg fa-commenting" *ngIf="g?.latestComment"></i>
                          <i class="fa fa-lg fa-comment" *ngIf="!g?.latestComment"></i>
                        </span>
                      </div>
                      <div *ngIf="g?.latestComment">
                        <div class="note-comment">
                          {{g?.latestComment}}
                        </div>
                      </div>
                    </td>
                    <td *ngIf="allocationDisplayedColumns.includes('Instructions')" [matTooltip]="element.instructions">
                      {{g?.instructions
                      | shorten : '40'}}</td>
                    <td *ngIf="allocationDisplayedColumns.includes('Haulier')">{{g?.haulier}}</td>
                    <td *ngIf="allocationDisplayedColumns.includes('Grade')">{{g?.grade}}
                      <app-multi-grade-allocation-detail [detail]="element.multiGradeDetails">
                      </app-multi-grade-allocation-detail>
                    </td>
                    <td *ngIf="allocationDisplayedColumns.includes('Sales Grade')">{{g?.salesGrade}}
                    </td>
                    <td *ngIf="allocationDisplayedColumns.includes('FCF Serve')">
                      <mat-checkbox [checked]="g?.firstComeFirstServe" (change)="fcfsChanged(g, $event)">
                      </mat-checkbox>
                    </td>

                    <td *ngIf="allocationDisplayedColumns.includes('Missed Drop Off')">
                      <mat-checkbox [checked]="g?.missedDropOff" (change)="missedDropOffChanged(g, $event)">
                      </mat-checkbox>
                    </td>

                    <td *ngIf="allocationDisplayedColumns.includes('Awaiting Dispatch')">
                      {{g?.awaitingDispatch ? 'Y' : g?.awaitingDispatch === null ? '' : 'N'}}
                    </td>

                    <td *ngIf="allocationDisplayedColumns.includes('Apt Number')">
                      <span (click)="printAppointment(g.contractLineAllocationId)">
                        <i class="fa fa-download"></i>
                      </span>{{g?.aptNumber}}
                    </td>
                    <td *ngIf="allocationDisplayedColumns.includes('Apt Date & Time')" class="hide-sm hide-xs">
                      {{g?.aptDateTime | momentUtcAsLocaleDateTime}}</td>

                    <td *ngIf="allocationDisplayedColumns.includes('Apt Date & Time To')" class="hide-sm hide-xs">
                      {{g?.aptDateTimeTo | momentUtcAsLocaleDateTime}}</td>
                    <td *ngIf="allocationDisplayedColumns.includes('Ticket Date')" class="hide-sm hide-xs">
                      {{g?.ticketDate | momentUtcAsLocaleDate}}</td>
                    <td *ngIf="allocationDisplayedColumns.includes('Ticket Number')">
                      {{g?.ticketNumber}}</td>
                    <td *ngIf="allocationDisplayedColumns.includes('Additional Costs')">
                      {{g?.additionalCosts}}</td>
                    <td *ngIf="allocationDisplayedColumns.includes('Dock Receipt')">
                      {{g?.dockReceipt}}</td>
                    <td *ngIf="allocationDisplayedColumns.includes('Booking Requested')">
                      {{g?.bookingRequested ? 'Y' : g?.bookingRequested === null ? '' : 'N'}}</td>
                    <td *ngIf="allocationDisplayedColumns.includes('Awaiting Appointment')">
                      {{g?.awaitingAppointment ? 'Y' : g?.awaitingAppointment === null ? '' : 'N'}}</td>
                    <td *ngIf="allocationDisplayedColumns.includes('Pending Dispatch')">
                      {{g?.pendingDispatch ? 'Y' : g?.pendingDispatch === null ? '' : 'N'}}</td>
                    <td *ngIf="allocationDisplayedColumns.includes('Processing Dispatched')">
                      {{g?.proccessingDispatch ? 'Y' : g?.proccessingDispatch === null ? '' : 'N'}}</td>
                    <td *ngIf="allocationDisplayedColumns.includes('Dispatched')">
                      {{g?.Dispatched ? 'Y' : g?.Dispatched === null ? '' : 'N'}}</td>
                    <td *ngIf="allocationDisplayedColumns.includes('Inspection Date')">
                      {{g?.inspectionDate | date: 'MMM dd, yyyy hh:mm a' }}</td>
                    <td *ngIf="allocationDisplayedColumns.includes('Booking Status')">
                      {{g?.isBooked ? 'Booked' : 'Pending' }}</td>
                    <td *ngIf="allocationDisplayedColumns.includes('Transport Responsibility')">
                      {{g?.classification == 'Domestic' ? 'Depot' : 'Central' }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns;" class="heading-row sticky"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{ 'selected' : row.isSelected }"
            style="height: 36px;" (click)="selectAllocation(row, $event, true)"></tr>
          <tr mat-row *matRowDef="let row; columns: ['allocationDetail']" class="allocation-detail-row"></tr>
        </table>
      </div>
      <table style="width: 100%;" *ngIf="allocations.length > 0">
        <tfoot>
          <tr>
            <td style="text-align: center;">
              <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" [maxSize]="3"
                [rotate]="true" [boundaryLinks]="collectionSize >= 100" (pageChange)="pageChanged($event)"
                aria-label="Default pagination"></ngb-pagination>
            </td>
          </tr>
        </tfoot>
      </table>
    </ng-template>
  </div>
</mat-card>
