import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {REST_CONFIG_TOKEN, RestConfigService} from '../rest-config-service';
import {Observable} from 'rxjs';
import {isNullOrUndefined} from '../../tools';

@Injectable()
export class RegionService {

    constructor(
private _http: HttpClient,
@Inject(REST_CONFIG_TOKEN) private _configService: RestConfigService
    ) {
    }

    public searchRegions: (
        SearchTerm?: string
        ) =>  Observable<string[]>
        = (
            SearchTerm?: string
        ): Observable<string[]> => {
            let queryParameters = new HttpParams();
            const headers = new HttpHeaders({'Content-Type': 'application/json'});
            if (!isNullOrUndefined(SearchTerm)) {
                queryParameters = queryParameters.append('SearchTerm', SearchTerm.toString());
            }

            const uri = '/api/allocation/regions';

            return this._http.get<string[]>(this._configService.apiBaseUrl() + uri,{ headers: headers, params: queryParameters  });
        };
}