import { Component, OnInit, Inject, ElementRef, ViewChild, isDevMode } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { CalendarCommonService } from '../event-calendar/event-calendar-common-service';
import { EnumVals } from '../gen/enums/enums';
import { AdditionalCostVm, PendingAndAllocatedViewModel } from '../gen/models';
import { formatDate } from '@angular/common';
@Component({
	selector: 'event-calendar-more-dialog',
	templateUrl: './event-calendar-more-dialog.component.html',
	styleUrls: ['./event-calendar-more-dialog.component.scss']
})

export class EventCalendarMoreDialogComponent implements OnInit {
	pendingAndAllocated: PendingAndAllocatedViewModel[];
  @ViewChild('firstComeFirstServeCheckBox') firstComeFirstServeCheckBox: ElementRef;

  pedingAllocatedExtended: PendingAndAllocatedViewModelExtended;
  constructor(
    private eventCalendarService: CalendarCommonService,
    public dialogRef: MatDialogRef<EventCalendarMoreDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { allocate: PendingAndAllocatedViewModel[] }) { }

  ngOnInit() {
  	this.pendingAndAllocated =  this.data.allocate;
  }

  cancel() {
  	this.dialogRef.close();
  }
  hasAllocations(item: PendingAndAllocatedViewModel): boolean {
  	return item.allocations.length > 0 && item.allocations[0].contractRef !== null;
  }

  toggleAllocations(item: PendingAndAllocatedViewModelExtended) {
  	item.showingAllocations = !item.showingAllocations;
  }
  parseRequestStatus(key: number): string {
  	return EnumVals.RequestStatuses.filter(s => s.key === key)[0].desc;
  }
  parseAllocationStatus(key: number): string {
  	return EnumVals.ContractLineAllocationStatuses.filter(s => s.key === key)[0].desc;
  }
  formatCosts(costs: AdditionalCostVm[]): string {
  	if(costs && costs.length > 0) {
  		const actualCosts = costs.filter(c => c.cost != 0 && c.costUomCode && c.costUomCode.length > 0); //validate for default values
  		if(actualCosts.length > 0)
  			return actualCosts.map(c => `${c.cost} ${c.costUomCode}`).join();
  	}
  	return 'None';
  }
  handleeditEvent(allocationNumber:number): void {
  	this.selectedNavItem(allocationNumber.toString());
  }
  selectedNavItem(item: string) {
  	this.eventCalendarService.emitNavChangeEvent(item);
  }
  displayDate(reqDate:Date)
  {
  	const date = new Date(reqDate);
  	return formatDate(date,'MMM dd, yyyy hh:mm a', 'en-US', '');
  }
}
interface PendingAndAllocatedViewModelExtended extends PendingAndAllocatedViewModel {
    showingAllocations: boolean;
    isSelected: boolean;
  }