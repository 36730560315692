import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContractPartyVm, ContractHeaderViewModel, Haulier, ContainerType, UomVm, ContractLineViewModel, DepotVm, ApiPageResponse, AppointmentVm, InitModel } from '../gen/models';
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl } from '@angular/forms';
import { HauliersService } from '../gen/services/hauliers.service';
import { ContainersService } from '../gen/services/containers.service';
import { AppointmentService } from '../gen/services/appointment.service';
import { UomsService } from '../gen/services/uoms.service';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';
import { isNullOrUndefined } from '../tools';
import { DepotService } from '../gen/services/depot.service';
import { ToastrService } from 'ngx-toastr';
import { InitService } from '../services/init.service';
import * as moment from 'moment-timezone';
import * as _ from 'underscore';
import { AllocateService } from '../gen/services/allocate.service';
import { haulagePriceMatrixVM } from '../gen/models/haulagePriceMatrixVM';
import { SpinnerComponent } from '../spinner/spinner.component';
import { Router } from '@angular/router';
import { CurrencySymbolService } from '../services/currency-symbol.service';
import { ActionConfirmDialogComponent } from '../action-confirm-dialog/action-confirm-dialog.component';
import { AllocateRequestGradeValidateModel } from '../gen/models/AllocateRequestGradeValidateModel';
import { AllocateGetPendingRequestModel } from '../gen/models/AllocateGetPendingRequestModel';
import { EMPTY, Observable } from 'rxjs';


@Component({
    selector: 'app-allocate-create-dialog',
    templateUrl: './allocate-create-dialog.component.html',
    styleUrls: ['./allocate-create-dialog.component.scss']
})
export class AllocateCreateDialogComponent implements OnInit {
    party: ContractPartyVm;
    contract: ContractHeaderViewModel;
    form: FormGroup;
    filteredHauliers: Haulier[];
    filteredDepots: ApiPageResponse<DepotVm>;
    containerTypes: ContainerType[];
    appointments: AppointmentVm[];
    uoms: UomVm[];
    additionalHidden = true;
    bulkAllocate = false;
    bulkAllocationMode: string;
    datePlaceholder: string[] = [];
    weekPlaceholder: string[] = [];
    timePlaceholder: string[] = [];
    groupDaysCount: number[] = [];
    groupWeeksCount: number[] = [];
    firstMonday: string;
    contractLineId: number;
    noLinesPicked = false;
    noLinesPickedForBulk = false;
    noLoads = false;
    noPickupDate = false;
    linesInvalid = false;
    initModel: InitModel;
    defaultAdditionalCostUomId = 0;
    allowedPricingUoms = ['EA', 'HR', 'LD', 'LT', 'MT'];
    isMixedGradeContract = false;
    firstComeFirstServe = false;
    groupAllocations = false;
    haulageRates: haulagePriceMatrixVM[] = [];
    haulagematrixDropdownOnly = false;
    @ViewChild('haulageRatesSpinner') haulageRatesSpinner: SpinnerComponent;
    @ViewChild('loadingCreateDialogSpinner') loadingCreateDialogSpinner: SpinnerComponent;

    containerTypeId: number;
    depotNo: string;
    selectedHaulageMatrix: haulagePriceMatrixVM;
    partyDeliveryPointIds: number[] = [];
    shownLoadTimes: number;
    loadTimesStyle = {};
    increments = ['15 minutes', '20 minutes', '25 minutes', '30 minutes', '45 minutes', '60 minutes', '90 minutes', '120 minutes'];
    incrementMinutes = [15, 20, 25, 30, 45, 60, 90, 120];
    clockClicked = false;
    previousWeightPerContainer: number[] = [];
    remainingInstructionsText: string;
    instructionsMaxlength = 1500;
    loadingCreateDialog = false;
    isSubmit = false;
    bulkAllocateGroupAllocations: false;
    allocateRequestGradeValidateModel: AllocateRequestGradeValidateModel;
    allocateGetPendingRequestModel: AllocateGetPendingRequestModel;
    newContractAllocationIds: any = '';
    isValidDepoWithGrade = true;
    constructor(
        public dialogRef: MatDialogRef<AllocateCreateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { party: ContractPartyVm, contract: ContractHeaderViewModel },
        private fb: FormBuilder,
        private hauliersService: HauliersService,
        private containerService: ContainersService,
        private uomsService: UomsService,
        private depotsService: DepotService,
        private appointmentService: AppointmentService,
        private toastr: ToastrService,
        private initService: InitService,
        private allocateService: AllocateService,
        private router: Router,
        private currencySymbolService: CurrencySymbolService,
        private dialog: MatDialog) { }

    get haulier() {
        return this.form.get('haulier');
    }

    get agreedHaulageRate() {
        return this.form.get('agreedHaulageRate');
    }

    get agreedHaulageRateUomId() {
        return this.form.get('agreedHaulageRateUomId');
    }

    get depot() {
        return this.form.get('depot');
    }

    get maxCosts() {
        return this.initModel.config.maxAllocationCosts;
    }

    get spotRateText() {
        let spotRateText = 'Spot Rate';

        if (this.contract?.haulageCurrencyCode) {
            const currencySymbol = this.currencySymbolService.getCurrencySymbol(this.contract.haulageCurrencyCode);
            if (currencySymbol) {
                spotRateText = `${spotRateText} (${currencySymbol})`;
            }
        }
        return spotRateText;
    }

    ngOnInit() {
        this.party = this.data.party;
        this.contract = this.data.contract;
        this.partyDeliveryPointIds = this.contract.contractLines.map(x => x.partyDeliveryPointId);

        this.isMixedGradeContract = this.contract
            && this.contract.contractLines
            && this.contract.contractLines.find(a => Boolean(a.allocatedViaReference)) !== undefined;

        this.form = this.fb.group({
            depot: ['', Validators.required],
            isMixedLoad: this.isMixedGradeContract,
            containerTypeId: ['', Validators.required],
            containerNumber: '',
            haulier: '',
            instructions: [this.data.contract.comments, Validators.maxLength(this.instructionsMaxlength)],
            additionalCosts: [''],
            allocationAppointments: [''],
            contractLines: this.fb.array(this.initContractLines(this.contract)),
            contractLineId: [''],
            bulkAllocate: [''],
            loadsByDate: this.fb.array(this.addBulkAllocationFormGroup(this.datePlaceholder)),
            loadsByWeek: this.fb.array(this.addBulkAllocationFormGroup(this.weekPlaceholder)),
            bulkAllocationPickupDate: [''],
            bulkAllocationRequiredLoads: [''],
            loadsByTime: this.fb.array([]),
            groupAllocations: [''],
            bulkAllocateIns: [this.data.contract.comments, Validators.maxLength(this.instructionsMaxlength)],
            pickupDateTime: [''],
            agreedHaulageRate: '',
            agreedHaulageRateUomId: '',
            bulkAllocateGroupAllocations: [''],
            balanceWeight: [''],
            approvedToSend: [true],
            isBooked: [false]
        });

        this.form.controls['instructions'].valueChanges.subscribe((newVal: string) => {
            this.remainingInstructionsText = `${newVal.length}/${this.instructionsMaxlength}`;
        });

        this.form.controls['bulkAllocateIns'].valueChanges.subscribe((newVal: string) => {
            this.remainingInstructionsText = `${newVal.length}/${this.instructionsMaxlength}`;
        });

        this.initService.initData$.subscribe(r =>
            this.initModel = r
        );

        this.depot.valueChanges
            .pipe(
                debounceTime(500),
                startWith(null),
                switchMap(v => this.filterDepots(v)))
            .subscribe(d => this.filteredDepots = d);

        this.form.controls.containerTypeId.setValue(this.data.contract.containerTypeID);
        this.containerTypeId = this.data.contract.containerTypeID;
        this.containerService.get().subscribe(c => this.containerTypes = c);

        this.appointmentService
            .getAvailableAppointmentsForParty(this.party.partyAccountNo, this.contract.customerContractRef, 0)
            .subscribe(a => this.appointments = a);

        this.uomsService.getAll().subscribe(u => {
            this.uoms = u;
            if (this.uoms) {
                this.uoms = this.uoms.filter(x => this.allowedPricingUoms.includes(x.code));
                this.defaultAdditionalCostUomId = this.uoms.find(a => a.code === 'LD').id;
                this.form.patchValue({ 'agreedHaulageRateUomId': this.defaultAdditionalCostUomId });
            }
        });

        this.data.contract.contractLines.forEach(l => {
            const line = l as ContractLineVmExtended;
            line.loadsWarning = undefined;
            line.weightWarning = undefined;
        });

        this.haulier.valueChanges
            .pipe(
                debounceTime(500),
                startWith(null),
                switchMap(v => this.filterHauliers(v)))
            .subscribe(r => this.filteredHauliers = r);

        this.form.controls.loadsByDate.valueChanges
            .subscribe(() => this.addRemoveLoadTimes(this.form.get('loadsByDate') as FormArray));

        this.form.controls.loadsByWeek.valueChanges
            .subscribe(() => this.addRemoveLoadTimes(this.form.get('loadsByWeek') as FormArray));
    }
    ngOnDestroy(): void {
        if (this.haulageRatesSpinner !== undefined)
            this.haulageRatesSpinner.overlayRef.dispose();
        if (this.loadingCreateDialogSpinner !== undefined)
            this.loadingCreateDialogSpinner.overlayRef.dispose();
    }

    valueChange(event) {
        if (event.checked) {
            this.firstComeFirstServe = true;
        } else {
            this.firstComeFirstServe = false;
        }
    }

    filterDepots(val: string) {
        return this.depotsService.searchDepots(val);
    }
    displayDepot(depot?: DepotVm): string | undefined {
        return depot ? depot.depotNumber + ' ' + depot.depotName : undefined;
    }

    clearDepot(input: HTMLInputElement) {
        input.value = '';
        this.depot.patchValue('');
        // this.depotSelected = false;
    }

    roundWeight(weight: number) {
        const result = Math.round(weight * 10) / 10;
        return result < 0 ? 0 : result;
    }


    qtyChange(
        qty: string, index: number, loadsInput: HTMLInputElement,
        weightPerInput: HTMLInputElement, line: ContractLineVmExtended) {

        if (this.isMixedGradeContract) {
            this.checkTargets(+qty, 1, line);
        } else if (!loadsInput.disabled && weightPerInput.disabled) {
            this.weightPerChange(weightPerInput, qty, index, loadsInput, line);
        } else if (!weightPerInput.disabled && loadsInput.disabled) {
            this.loadsChange(loadsInput, qty, index, weightPerInput, line);
        } else {
            this.checkTargets(+qty, +loadsInput.value, line);
        }

        if (qty === '' || +qty === 0) {
            this.resetInputs(loadsInput, weightPerInput, index);
            this.getGroup(index).get('requiredWeight').setValidators(null);
        } else {
            this.getGroup(index).get('requiredWeight').setValidators([Validators.required]);
            this.getGroup(index).get('requiredLoads').setValidators([Validators.required]);
            this.getGroup(index).get('requiredWeightPerLoad').setValidators([Validators.required]);
        }
    }

    validateAppointments(appts: any): boolean {
        if (isNullOrUndefined(appts) || appts === '') {
            this.toastr.error('Form Invalid', 'Pickup date is required');
            return false;
        }

        for (const apt of appts) {
            if (isNullOrUndefined(apt.pickupDateTime) || apt.pickupDateTime === '' || !apt.pickupDateTime.isValid()) {
                this.toastr.error('Form Invalid', 'A valid Pickup date is required');
                return false;
            } else if (apt.pickupToDateTime && apt.pickupDateTime > apt.pickupToDateTime) {
                this.toastr.error('Form Invalid', 'Pickup date should be less than pickupToDateTime');
                return false;
            } else if (apt.customerAptToDateTime &&
                (apt.customerAptDateTime === '' || !apt.customerAptDateTime.isValid()
                    || apt.customerAptDateTime > apt.customerAptToDateTime)) {
                this.toastr.error('Form Invalid', 'CustomerAptDateTime should be less than customerAptToDateTime');
                return false;
            } else if (!isNullOrUndefined(apt.agreedHaulageRate) && apt.agreedHaulageRate > 0 && (isNullOrUndefined(apt.agreedHaulageRateUomId) || apt.agreedHaulageRateUomId == 0)) {
                this.toastr.error('Form Invalid', 'Please select a Haulge Spot Rate UOM');
                return false;
            }
        }
        return true;
    }

    selectContractType(event: any) {
        this.containerTypeId = event.value;
    }

    loadsChange(
        loadsInput: HTMLInputElement, qty: string, index: number,
        weightPerInput: HTMLInputElement, line: ContractLineVmExtended) {
        const loads = loadsInput.value;
        const weightPer = +loads > 0 ? +qty / +loads : 0;
        weightPerInput.value = `${weightPer}`;
        // if (!loadsInput.disabled) { weightPerInput.disabled = true; }
        this.getGroup(index).get('requiredWeightPerLoad').setValue(weightPer);
        this.checkTargets(+qty, +loads, line);
    }

    weightPerChange(
        weightPerInput: HTMLInputElement, qty: string, index: number,
        loadsInput: HTMLInputElement, line: ContractLineVmExtended) {
        const weightPer = weightPerInput.value;
        const loads = +weightPer > 0 ? Math.ceil(+qty / +weightPer) : 0;
        loadsInput.value = `${loads}`;
        // if (!weightPerInput.disabled) { loadsInput.disabled = true; }
        this.getGroup(index).get('requiredLoads').setValue(loads);
        this.checkTargets(+qty, +loads, line);
    }

    checkTargets(weight: number, loads: number, line: ContractLineVmExtended) {
        if (line.balanceWeight && weight > line.balanceWeight) {
            line.weightWarning = `The weight: ${weight} exceeds the balance weight: ${line.balanceWeight}`;
        } else {
            line.weightWarning = undefined;
        }

        if (line.balanceLoads && loads > line.balanceLoads) {
            line.loadsWarning = `The loads: ${loads} exceeds the balance loads: ${line.balanceLoads}`;
        } else {
            line.loadsWarning = undefined;
        }
    }

    resetInputs(loadsInput: HTMLInputElement, weightPerInput: HTMLInputElement, index: number) {
        loadsInput.disabled = false;
        weightPerInput.disabled = false;
        loadsInput.value = '';
        weightPerInput.value = '';
        this.getGroup(index).get('requiredLoads').setValue('');
        this.getGroup(index).get('requiredWeightPerLoad').setValue('');
        this.getGroup(index).get('requiredLoads').setValidators(null);
        this.getGroup(index).get('requiredWeightPerLoad').setValidators(null);
    }

    getGroup(index: number): FormGroup {
        const requestsArray = this.form.get('contractLines') as FormArray;
        return requestsArray.controls[index] as FormGroup;
    }

    create(formValue: any) {
        formValue.contractHeaderId = this.data.contract.contractHeaderId;
        formValue.partyAccountNumber = this.data.party.partyAccountNo;
        formValue.contractRef = this.data.contract.customerContractRef;
        formValue.depotNo = formValue.depot.depotNumber;

        if (isNullOrUndefined(formValue.depotNo)) {
            this.toastr.error('Form Invalid', 'Please enter a valid Depot');
            return false;
        }

        const contractLines = formValue.contractLines.map((l, i) => ({
            requiredWeight: l.requiredWeight,
            requiredLoads: this.isMixedGradeContract ? 1 : l.requiredLoads,
            requiredWeightPerLoad: this.isMixedGradeContract ? l.requiredWeight : l.requiredWeightPerLoad,
            contractLineId: l.contractLineId,
            weightUomCode: l.weightUomCode,
            isValid: this.roundWeight(l.balanceWeight) <= 0 ? true : (l.requiredWeight > 0 && (this.isMixedGradeContract || l.requiredLoads > 0)),
            isBlank: this.roundWeight(l.balanceWeight) <= 0 ? false : (!l.requiredWeight && (this.isMixedGradeContract || l.requiredLoads === '')),
            index: i
        }));

        const validLines = contractLines.filter(l => l.isValid && !l.isBlank);
        if (validLines.length === 0 && !this.bulkAllocate) {
            this.noLinesPicked = true;
            return;
        }
        else {
            this.noLinesPicked = false;
        }
        const invalidLines = contractLines.filter(l => !l.isValid && !l.isBlank);
        if (invalidLines.length > 0 && !this.bulkAllocate) {
            this.noLinesPicked = false;
            this.linesInvalid = true;
            return;
        }
        else {
            this.linesInvalid = false;
        }

        if (this.bulkAllocate) {
            if (!isNullOrUndefined(this.agreedHaulageRate.value) && this.agreedHaulageRate.value > 0
                && (isNullOrUndefined(this.agreedHaulageRateUomId.value) || this.agreedHaulageRateUomId.value === '')) {
                this.toastr.error('Form Invalid', 'Please select a Spot Rate UOM.');
                return false;
            }
        }
        else {
            const allocationAppointments = formValue.allocationAppointments;
            const contractLines = formValue.contractLines;

            if (contractLines.length === 1 &&
                contractLines[0].requiredLoads != allocationAppointments.length) {

                if (allocationAppointments.length > 1) {
                    this.toastr.error('Form Invalid', 'Pickup date not set for required loads.');
                    return false;
                } else {
                    for (let i = 1; i < contractLines[0].requiredLoads; i++) {
                        allocationAppointments[i] = allocationAppointments[0];
                    }
                }
            }
            if (!this.validateAppointments(formValue.allocationAppointments)) {
                return false;
            }
        }

        for (const value of formValue.allocationAppointments) {
            value.haulierAccountNumber = value['haulier'].accountNumber;
            if (value.agreedHaulageRateUomId <= 0) {
                value.agreedHaulageRateUomId = this.defaultAdditionalCostUomId;
            }
        }

        formValue.bulkAllocate = this.bulkAllocate;

        if (this.bulkAllocate) {
            const lines = this.contract.contractLines as ContractLineVmExtended[];

            if (!(lines.filter(c => c.isSelected).length > 0)) {
                this.noLinesPickedForBulk = true;
                return;
            }

            formValue.contractLines = contractLines.filter(c => c.contractLineId == lines.filter(c => c.isSelected)[0].contractLineId);
            formValue.haulierAccountNumber = formValue['haulier'].accountNumber;
            if (!(formValue.contractLines.filter(c => c.requiredWeightPerLoad > 0).length > 0)) {
                this.noLinesPickedForBulk = true;
                return;
            }
            else {
                this.noLinesPickedForBulk = false;
            }

            if (this.bulkAllocationMode == 'date') {
                if (formValue.loadsByDate.length == formValue.loadsByDate.filter(l => !(l.requiredLoads > 0)).length) {
                    this.noPickupDate = false;
                    this.noLoads = true;
                    return;
                }
                else
                    this.noLoads = false;
                formValue.bulkAllocationType = 1;
                formValue.pickupDateTime = moment.utc().format('YYYY-MM-DD');
            }
            else if (this.bulkAllocationMode == 'week') {
                if (formValue.loadsByWeek.length == formValue.loadsByWeek.filter(l => !(l.requiredLoads > 0)).length) {
                    this.noPickupDate = false;
                    this.noLoads = true;
                    return;
                }
                else
                    this.noLoads = false;


                formValue.bulkAllocationType = 3;
                formValue.pickupDateTime = moment.utc(this.firstMonday).format('YYYY-MM-DD');
            }
            formValue.instructions = formValue.bulkAllocateIns;
        } else {
            formValue.contractLines = validLines.filter(e =>
                e.requiredWeight != undefined &&
                e.requiredLoads != undefined &&
                e.requiredWeightPerLoad != undefined);
            formValue.pickupDateTime = formValue.allocationAppointments[0].pickupDateTime;
            formValue.groupAllocations = formValue.bulkAllocateGroupAllocations;
        }

        if (this.form.valid) {
            this.isSubmit = true;
            this.loadingCreateDialog = true;
            this.allocateRequestGradeValidateModel = {
                depotNo: formValue.depot.depotNumber,
                grade: !isNullOrUndefined(this.data.party.contracts[0].contractLines[0].grade)
                    ? this.data.party.contracts[0].contractLines[0].grade : null,
                salesGradeId: this.data.party.contracts[0].contractLines[0].salesGradeId,
                salesGrade: !isNullOrUndefined(this.data.party.contracts[0].contractLines[0].grade)
                    ? null : this.data.party.contracts[0].contractLines[0].salesGrade
            };

            this.allocateService.getValidDepoWithGrade(this.allocateRequestGradeValidateModel)
                .subscribe(reqs => {
                    if (!reqs) {
                        this.loadingCreateDialog = false;
                        const message = 'Are you sure you want to proceed?';
                        const body = 'The selected ' + '\'' + formValue.depot.depotNumber + ' ' + formValue.depot.depotName + '\'' + ' Depot does not currently have a heap assigned to handle grade/material.';
                        const dialogRef = this.dialog.open(ActionConfirmDialogComponent, {
                            minWidth: '240px',
                            maxWidth: '80%',
                            maxHeight: '80%',
                            data: {
                                aria: message,
                                title: message,
                                body,
                                model: 'confirm'
                            }
                        });
                        dialogRef.afterClosed().subscribe(r => {
                            if (isNullOrUndefined(r)) {
                                this.isSubmit = false;
                                return;
                            }
                            let warningMsg = '';
                            this.contract.contractLines.forEach(element => {
                                const line = element as ContractLineVmExtended;
                                if (line.weightWarning != undefined) {
                                    warningMsg = warningMsg +
                                        (line.grade == null ? line.salesGrade : line.grade + ' - ' + line.salesGradeDesc) + ' - ' + line.weightWarning + '\n';
                                }
                                if (line.loadsWarning != undefined) {
                                    warningMsg = warningMsg +
                                        (line.grade == null ? line.salesGrade : line.grade + ' - ' + line.salesGradeDesc) + ' - ' + line.loadsWarning + '\n';
                                }
                            });
                            if (warningMsg != '') {
                                const message = 'Are you sure you want to proceed with warnings?';
                                const body = warningMsg;
                                const dialogRef = this.dialog.open(ActionConfirmDialogComponent, {
                                    minWidth: '240px',
                                    maxWidth: '80%',
                                    maxHeight: '80%',
                                    data: {
                                        aria: message,
                                        title: message,
                                        body,
                                        model: 'confirm'
                                    }
                                });
                                dialogRef.afterClosed().subscribe(r => {
                                    if (isNullOrUndefined(r)) {
                                        this.isSubmit = false;
                                        return;
                                    }
                                    this.loadingCreateDialog = true;
                                    this.creatAllocation(formValue);
                                });
                            }
                            else {
                                this.loadingCreateDialog = true;
                                this.creatAllocation(formValue);
                            }
                        });
                    }
                    else {
                        this.creatAllocation(formValue);
                    }
                });
        } else {
            if (!isNullOrUndefined(this.form.errors.dates)) {
                this.toastr.error('Form Invalid', this.form.errors.dates);
            }
        }

    }

    creatAllocation(formValue) {
        this.allocateService.create(formValue).subscribe(a => {
            if (formValue.groupAllocations) {
                let stringAllocation = a.contractLineAllocationIds.length + ' allocations created successfully' + '</br></br>';
                a.contractLineAllocationIds.sort((a, b) => b - a).forEach((contractLineAllocationId, index) => {
                    stringAllocation += contractLineAllocationId + ' - ' + formValue.depotNo + '</br>';
                    this.newContractAllocationIds += contractLineAllocationId + ',';
                });
                this.customSuccess(stringAllocation, 'Allocation(s)');
            } else {
                this.success('Allocation created successfully', 'Allocation');
            }
            this.closeDialog();
        }, err => {
            if (err.error != null && err.error.status === 200) {
                this.success('Allocation created successfully ', 'Allocation');
                this.closeDialog();
            } else {
                this.toastr.error('Something went wrong and the request could not be allocated. Please try again later.', 'Allocate Failed');
                this.isSubmit = false;
            }
            this.loadingCreateDialog = false;
        });
    }

    private closeDialog() {
        setTimeout(() => {
            this.loadingCreateDialog = false;
            this.dialogRef.close();
        }, 1500);
    }

    private success(message: string, title: string) {
        this.toastr.success(message, title, {
            enableHtml: true
        });
    }

    private customSuccess(message: string, title: string) {
        this.toastr.success(message, title, {
            enableHtml: true,
            progressBar: true
        }).onAction.subscribe(() => {
            this.router.navigate(['/allocations'], { state: { data: this.newContractAllocationIds.replace(/(^,)|(,$)/g, '') } });
        });
    }

    cancel() {
        this.dialogRef.close();
    }

    private initContractLines(contract: ContractHeaderViewModel): FormGroup[] {
        const groupArray: FormGroup[] = [];

        for (const line of contract.contractLines) {
            const group = this.fb.group({
                requiredWeight: '',
                requiredLoads: this.roundWeight(line.balanceWeight) <= 0 ? '0' : '',
                requiredWeightPerLoad: this.roundWeight(line.balanceWeight) <= 0 ? '0' : '',
                contractLineId: line.contractLineId,
                weightUomCode: line.weightUomCode,
                balanceWeight: line.balanceWeight,
            });

            groupArray.push(group);
        }

        return groupArray;
    }

    selectAppointment(value: any) {
        this.form.controls.customerAptRef.reset();
        this.form.controls.appointmentComment.reset();
        this.form.controls.customerAptDateTime.reset();
        this.form.controls.customerAptToDateTime.reset();

        if (value > 0) {
            const appointmentDetails = this.appointments.find(a => a.appointmentId === value);
            const startDate = moment.utc(appointmentDetails.startDate);
            const endDate = moment.utc(appointmentDetails.endDate);
            this.form.controls.customerAptDateTime.setValue(startDate);
            this.form.controls.customerAptToDateTime.setValue(endDate);
            this.form.controls.customerAptRef.setValue(appointmentDetails.deliveryNumber);

            this.form.controls.customerAptRef.disable();
            this.form.controls.appointmentComment.disable();
            this.form.controls.customerAptDateTime.disable();
            this.form.controls.customerAptToDateTime.disable();
        }
        else {
            this.form.controls.customerAptRef.enable();
            this.form.controls.appointmentComment.enable();
            this.form.controls.customerAptDateTime.enable();
            this.form.controls.customerAptToDateTime.enable();
        }
    }


    showBulkAllocateMode(event: any) {
        const ctrls = this.form.controls;
        const noOfContractLines = this.form.get('contractLines').value.length;
        if (event.checked) {
            this.bulkAllocate = true;
            this.bulkAllocationMode = 'date';

            const weightPerContainer = this.contract.weightPerContainer;
            for (let i = 0; i < noOfContractLines; i++) {
                const previous = this.getGroup(i).get('requiredWeightPerLoad').value;
                this.previousWeightPerContainer[i] = previous;
                this.getGroup(i).get('requiredWeightPerLoad').setValue(weightPerContainer);
            }
            this.pupulatePlaceholderText();
        }
        else {
            this.bulkAllocate = false;
            for (let i = 0; i < noOfContractLines; i++) {
                this.getGroup(i).get('requiredWeightPerLoad').setValue(this.previousWeightPerContainer[i]);
            }

            this.removeTimeValidation();
        }
    }

    private addBulkAllocationFormGroup(arr: any) {
        this.pupulatePlaceholderText();
        const groupArr: FormGroup[] = [];
        for (let a = 0; a < arr.length; a++) {
            const grp = this.fb.group({
                pickupDateTime: moment.utc(arr[a]).format('YYYY-MM-DD'),
                requiredLoads: null,
                loadTimes: this.fb.array([])
            });
            groupArr.push(grp);
        }
        return groupArr;
    }

    getLoadTimes(index: number, loadType: string): AbstractControl[] {
        const ld = this.form.get(loadType) as FormArray;
        const loadTime = ld.controls[index] as FormGroup;
        if (loadTime != null) {
            const requireLoad = loadTime.get('requiredLoads') as FormGroup;
            if (requireLoad != null && requireLoad != undefined) {
                if (requireLoad.value == null)
                    return [];
            }
        }

        if (ld && ld.length > 0) {
            const loadDate = ld.controls[index] as FormGroup;
            if (loadDate) {
                const loadTimes = loadDate.get('loadTimes') as FormArray;
                return loadTimes.controls;
            }
        }
        return [];
    }

    private addRemoveLoadTimes(frmArray: FormArray) {
        for (let index = 0; index < frmArray.length; index++) {
            const group = frmArray.controls[index] as FormGroup;
            const requiredLoad = group.get('requiredLoads').value;
            const loadTimes = group.get('loadTimes') as FormArray;
            const totalLoadTime = loadTimes.length;

            if (requiredLoad && totalLoadTime != requiredLoad) {
                if (requiredLoad < totalLoadTime) {
                    loadTimes.controls.splice(requiredLoad - 1, totalLoadTime - requiredLoad);
                }
                else {
                    for (let i = 0; i < requiredLoad - totalLoadTime; i++) {
                        loadTimes.push(this.fb.group({ loadTime: null, increment: null }));
                    }
                }
            }

        }
    }

    selectAllocationMode(event: any) {
        this.bulkAllocationMode = event.value;

        if (this.bulkAllocationMode == 'time') {
            this.addTimeValidation();
        }
        else {
            this.removeTimeValidation();
        }
    }

    removeTimeValidation() {
        const ctrls = this.form.controls;

        ctrls.bulkAllocationPickupDate.clearValidators();
        ctrls.bulkAllocationPickupDate.updateValueAndValidity();
        ctrls.bulkAllocationRequiredLoads.clearValidators();
        ctrls.bulkAllocationRequiredLoads.updateValueAndValidity();
    }

    addTimeValidation() {
        const ctrls = this.form.controls;

        ctrls.bulkAllocationPickupDate.setValidators([Validators.required]);
        ctrls.bulkAllocationPickupDate.updateValueAndValidity();
        ctrls.bulkAllocationRequiredLoads.setValidators([Validators.required]);
        ctrls.bulkAllocationRequiredLoads.updateValueAndValidity();
    }

    pupulatePlaceholderText() {
        if (this.datePlaceholder.length > 0)
            return;

        let i = 0;
        let date = new Date();
        const day = date.getDay();
        const diff = date.getDate() - day + (day == 0 ? -6 : 1);
        const dt = new Date(date.setDate(diff));
        if (new Date(date.setDate(diff)) < new Date())
            date = new Date(date.setDate(diff + 7));
        else
            date = new Date(date.setDate(diff));

        while (i < 7) {
            const d = dt.setDate(dt.getDate() + (i > 0 ? 1 : 0));
            this.datePlaceholder.push(moment(d).toDate().toString());
            i++;
        }
        this.groupDaysCount.push((this.datePlaceholder.length / 7) - 1);
        i = 0;
        while (i < 7) {
            const d = date.setDate(date.getDate() + (i > 0 ? 7 : 0));
            if (i == 0) {
                this.firstMonday = moment(d).toDate().toString();
            }
            this.weekPlaceholder.push(moment(d).toDate().toString());
            i++;
        }
        this.groupWeeksCount.push((this.weekPlaceholder.length / 7) - 1);
    }

    addDatesToDatePlcaeholder() {
        let dt = new Date();
        if (this.datePlaceholder.length <= 0) {
            const date = new Date();
            const day = date.getDay();
            const diff = date.getDate() - (day + 1) + (day == 0 ? -6 : 1);
            const dt = new Date(date.setDate(diff));
        } else {
            dt = new Date(this.datePlaceholder[this.datePlaceholder.length - 1]);
        }
        const frmArray = this.form.get('loadsByDate') as FormArray;

        let i = 0;
        while (i < 7) {
            const d = dt.setDate(dt.getDate() + (i > 0 ? 1 : 1));
            const grp = this.fb.group({
                'requiredLoads': '',
                'pickupDateTime': moment.utc(d).format('YYYY-MM-DD'),
                'loadTimes': this.fb.array([])
            });
            frmArray.push(grp);
            this.datePlaceholder.push(moment(d).toDate().toString());
            i++;
        }
        this.groupDaysCount.push((this.datePlaceholder.length / 7) - 1);
    }

    addWeeksToWeekPlaceholder() {
        let dt = new Date();
        if (this.weekPlaceholder.length <= 0) {
            const date = new Date();
            const day = date.getDay();
            const diff = date.getDate() - (day + 1) + (day == 0 ? -6 : 1);
            const dt = new Date(date.setDate(diff));
        } else {
            dt = new Date(this.weekPlaceholder[this.weekPlaceholder.length - 1]);
        }
        const frmArray = this.form.get('loadsByWeek') as FormArray;

        let i = 0;
        while (i < 7) {
            const d = dt.setDate(dt.getDate() + 7);
            const grp = this.fb.group({
                'requiredLoads': '',
                'pickupDateTime': moment.utc(d).format('YYYY-MM-DD'),
                'loadTimes': this.fb.array([])

            });
            frmArray.push(grp);
            this.weekPlaceholder.push(moment(d).toDate().toString());
            i++;
        }
        this.groupWeeksCount.push((this.weekPlaceholder.length / 7) - 1);
    }

    removeDatesFromDatePlcaeholder() {
        const frmArray = this.form.get('loadsByDate') as FormArray;
        if (frmArray.length <= 0)
            return;

        let i = 6;
        while (i >= 0) {
            frmArray.removeAt(frmArray.length - (i + 1));
            this.datePlaceholder.pop();
            i--;
        }
        this.groupDaysCount.pop();
    }

    removeWeeksFromWeekPlaceHolder() {
        const frmArray = this.form.get('loadsByWeek') as FormArray;
        if (frmArray.length <= 0)
            return;

        let i = 6;
        while (i >= 0) {
            frmArray.removeAt(frmArray.length - i);
            this.weekPlaceholder.pop();
            i--;
        }
        this.groupWeeksCount.pop();
    }

    applyLoadTimesToAll() {
        const frmArray = this.form.get('loadsByDate') as FormArray;
        let i = 1;
        const firstLoadTime = frmArray.controls[0].get('loadTimes').value;
        const firstLoad = frmArray.controls[0].get('requiredLoads').value;

        while (i < frmArray.length) {
            frmArray.controls[i].patchValue({ 'requiredLoads': firstLoad });
            frmArray.controls[i].patchValue({ 'loadTimes': firstLoadTime });
            i++;
        }

        this.hideLoadTimes();
    }

    populateControls(event: any) {
        this.timePlaceholder = new Array<string>(+event.target.value);
        const arr = this.form.get('loadsByTime') as FormArray;
        if (this.timePlaceholder.length < arr.length) {
            while (this.timePlaceholder.length < arr.length) {
                arr.removeAt(arr.length - 1);
            }
        }
        const groupArr: FormGroup[] = [];
        while (this.timePlaceholder.length != arr.length) {
            const grp = this.fb.group({
                pickupTime: null
            });
            arr.push(grp);
        }
    }
    assignLineId(id: number) {
        this.contractLineId = id;
    }
    selectLine(item: ContractLineVmExtended) {
        if (!this.bulkAllocate)
            return;
        this.clearSelected();
        if (item.isSelected) {
            item.isSelected = false;
        } else {
            item.isSelected = true;
        }
    }
    clearSelected() {
        const selectedRow = _.findWhere(this.contract.contractLines as ContractLineVmExtended[], { isSelected: true });
        if (selectedRow) { selectedRow.isSelected = false; }
        for (const a of this.contract.contractLines) {
            const item = a as ContractLineVmExtended;
            if (item.isSelected) {
                item.isSelected = false;
            }
        }
    }

    checkForRequests(depot: DepotVm) {
        this.depotNo = depot.depotNumber;
        for (const line of this.contract.contractLines) {
            if (!isNullOrUndefined(line.grade)) { //in case of sales grade contract lines
                this.allocateGetPendingRequestModel = {
                    depotNo: depot.depotNumber,
                    grade: line.grade
                }; this.allocateService.getPendingRequests(this.allocateGetPendingRequestModel)

                    .subscribe(reqs => {
                        if (reqs.length === 0) { return; }
                        const extLine = line as ContractLineVmExtended;
                        const reqStrs = reqs.map(r => `${r.remainingLoads} load(s) - ${r.remainingWeight} ${r.remainingWeightUomCode}`);
                        extLine.requestsMessage = `There are ${reqs.length} requests at ${depot.depotNumber} (${reqStrs.join(', ')})`;
                    });
            }
        }
    }

    toUpperCase(event: any) {
        event.target.value = event.target.value.toUpperCase();
    }

    calculateLoadsCount() {
        if (this.isMixedGradeContract) {
            return 1;
        } else {
            const result = (this.form.controls.contractLines as any).controls.map(control => control.value.requiredLoads)
                .reduce((sum, current) => sum + current, 0);
            return result;
        }
    }

    showHaulierClear(haulierInput: HTMLInputElement): boolean {
        return !isNullOrUndefined(haulierInput.value) && haulierInput.value !== '';
    }

    haulierClear() {
        this.haulier.setValue({ accountNumber: null, name: null });
    }

    updateHaulageRateDropdown(event: any, fixRate = false) {
        if (this.haulageRates.length > 0) {
            this.selectedHaulageMatrix = this.haulageRates[0];
            this.haulagematrixDropdownOnly = fixRate;
        }
    }

    displayHaulier(haulier?: Haulier): string {
        return isNullOrUndefined(haulier.accountNumber) || isNullOrUndefined(haulier.name)
            ? '' : haulier.accountNumber + ' ' + haulier.name;
    }

    filterHauliers(val: string): Observable<Haulier[]> {
        return this.hauliersService.get(val);
    }

    getDepots(line: ContractLineVmExtended): string {
        let depots = '';
        let index = 0;
        if (!isNullOrUndefined(line.contractLineDepotPremiumDepotNos)) {
            line.contractLineDepotPremiumDepotNos.forEach(e => {
                if (index == 0) {
                    depots = depots.concat(e);
                    index++;
                }
                else {
                    depots = depots.concat(',', e);
                }
            });

            return depots;
        }
    }

    hideLoadTimes() {
        if (!this.clockClicked)
            this.shownLoadTimes = undefined;
        else
            this.clockClicked = false;
    }

    showHideLoadTimes(index: number, event: MouseEvent) {
        this.clockClicked = true;
        if (this.shownLoadTimes === index) {
            this.shownLoadTimes = undefined;
        } else {
            this.shownLoadTimes = index;
        }
        this.loadTimesStyle = {
            top: `${event.clientY + 20}px`,
            left: `${event.clientX - 90}px`,
        };
    }

    hiddenLoadTimes(index: number) {
        return this.shownLoadTimes !== index;
    }

    getFirstLoadTimeValue(frmArray: FormArray, index) {
        const group = frmArray.controls[index] as FormGroup;
        const loadTimes = group.get('loadTimes') as FormArray;
        return loadTimes;
    }

    setIncrements(rowNum: number, increment: string, loadType: string) {

        let frmArray: FormArray;
        if (loadType === 'byDates')
            frmArray = this.form.get('loadsByDate') as FormArray;
        else
            frmArray = this.form.get('loadsByWeek') as FormArray;

        const incrementIndex = this.increments.indexOf(increment);
        if (incrementIndex > -1) {
            const minutesToAdd = this.incrementMinutes[incrementIndex];
            const ctrl = this.getFirstLoadTimeValue(frmArray, rowNum);
            const originalTime = ctrl.controls[0].get('loadTime').value;
            const originalMoment = moment.utc(originalTime, 'HH:mm');

            ctrl.controls.forEach((control, index) => {
                if (index == 0) //skip first one since that's the value we're basing increments off
                    return;
                const incrementedTime = originalMoment.add(minutesToAdd, 'minutes').format('HH:mm');
                control.patchValue({ loadTime: incrementedTime });
            });
        }
    }
}

export interface ContractLineVmExtended extends ContractLineViewModel {
    isSelected: boolean;
    requestsMessage: string;
    weightWarning: string;
    loadsWarning: string;
}
