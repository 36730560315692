import { Component, OnInit, ViewChild } from '@angular/core';
import { OAuthService, AuthConfig } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { Router } from '@angular/router';
import { ConfigService } from './services/config-service';
import { SidenavService } from './services/sidenav.service';
import { PrintingService } from './services/printing.service';
import { ToastrService } from 'ngx-toastr';
import { ADGroupService } from './gen/services/adgroup.service';
import { UserPreferencesService } from './gen/services/user-preferences.service';
import { Location } from '@angular/common';
import { InitService } from './services/init.service';
import * as moment from 'moment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    title = 'finlay-ui';
    @ViewChild('sideNav', { static: true }) sideNav;
    sideNavType: string;
    isCentralAdmin = false;
    printing = false;
    username: string;
    isBannerDisplay: boolean;
    showTransportPlan = false;
    showBookedTransport = false;
    isRouteToMarketAdmin = false;
    showInstructionToMove = false;
    showInventoryReservation = false;

    constructor(private oauthService: OAuthService,
        private router: Router,
        private location: Location,
        private configService: ConfigService,
        private sidenavService: SidenavService,
        private adGroupService: ADGroupService,
        private toastr: ToastrService,
        private printingService: PrintingService,
        private userPreferencesService: UserPreferencesService,
        private initService: InitService) {
    }

    ngOnInit(): void {
        console.log(this.location);
        this.configureWithNewConfigApi();

        this.sidenavService.selectedAllocation$.subscribe(a => {
            this.sideNavType = a.type;
            this.sideNav.toggle();
        });

        this.sidenavService.selectedBooking$.subscribe(a => {
            this.sideNavType = a.type;
            this.sideNav.toggle();
        });

        this.printingService.print$.subscribe(p => {
            this.printing = p.printing;
        });

        moment.locale(window.navigator.language); //set the global moment object to the users locale
    }

    allocationCreated(sideNav) {
        sideNav.toggle();
        this.toastr.success('Request successfully created', 'New Request');
        this.sidenavService.close({ closed: true });
    }

    onClose() {
        this.sidenavService.close({ closed: true });
    }

    private async configureWithNewConfigApi() {
        console.log(this.location);
        const redirectUrl = this.location.path(); //activated Route does not work for app.component, only returns '\', use location instead        
        console.log(this.location);
        if (!localStorage.getItem('redirecturl')) {
            localStorage.setItem('redirecturl', redirectUrl);
        }

        const authConfig: AuthConfig = {
            issuer: this.configService.config.authUrl,
            redirectUri: `${this.configService.config.redirectUrl}`,
            silentRefreshRedirectUri: `${this.configService.config.redirectUrl}/silent-refresh.html`,
            clientId: this.configService.config.clientId,
            scope: this.configService.config.scope,
            logoutUrl: this.configService.config.logoutUrl
        };
        this.oauthService.configure(authConfig);
        //https://github.com/manfredsteyer/angular-oauth2-oidc/issues/218#issuecomment-381957741
        this.oauthService.setStorage(localStorage);
        this.oauthService.setupAutomaticSilentRefresh();
        this.oauthService.tokenValidationHandler = new JwksValidationHandler();

        const hasToken = await this.oauthService.loadDiscoveryDocumentAndLogin();
        if (hasToken) {
            this.adGroupService.getMyGroups().subscribe(groups => {
                this.isCentralAdmin = groups.myGroups.includes('AllocationsClerk');
                this.showBookedTransport = groups.myGroups.includes('BookedTransport');
                this.showTransportPlan = groups.myGroups.includes('TransportPlan');
                this.isRouteToMarketAdmin = groups.myGroups.includes('RouteToMarketAdmin');
                this.showInstructionToMove = groups.myGroups.includes('InstructionToMove');
                this.showInventoryReservation = groups.myGroups.includes('InventoryReservation');
            });
            this.getUserInfo();
            this.initService.fetch();
        }
    }

    signOut() {
        this.adGroupService.logOut().subscribe();
        this.oauthService.logOut();
    }

    async getUserInfo() {
        this.userPreferencesService.getUserInfo().subscribe(u => {
            this.username = u.fullName;
            this.requestPageLoad();
        });
    }

    requestPageLoad() {
        if (this.router.url !== '/bookedtransport') {
            this.isBannerDisplay = true;
        }

        const postLoginRedirect = localStorage.getItem('redirecturl');
        if (postLoginRedirect) {
            this.router.navigateByUrl(postLoginRedirect);
            localStorage.removeItem('redirecturl');
        }
        else {
            this.router.navigate(['/requests']);
        }
    }
}
