
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable()
export class ConfigService {
	constructor(private _http: HttpClient) {}

	public config: IConfig | null = null;

	async load(): Promise<void> {
		if (this.config !== null) {
			return;
		}
		this.config = <IConfig> (await this._http.get('config.json').toPromise());
	}
}

export interface IConfig {
  apiUrl: string;
  authUrl: string;
  redirectUrl: string;
  clientId: string;
  scope: string;
  logoutUrl: string;
  showTransportPlanDebugCalendar: boolean;
  instrumentationKey: string;

}

export interface ILocalStoreData {
  accessToken: string;
  loggedIn: boolean;
}
