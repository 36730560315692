<form [formGroup]="form" (ngSubmit)="create(form.value)">
  <app-spinner [displayProgressSpinner]="loadingCreateDialog" [container]="loadingCreateDialogBox"
    #loadingCreateDialogSpinner>
  </app-spinner>
  <div>
    <div class="party-info">
      <div><span>{{party.partyAccountNo}} - {{party.partyName}}</span></div>
      <div>Total Balance: <span>{{data.contract.totalBalance | number: '1.0-1'}}</span></div>
      <div>Contract ref: <span>{{data.contract.customerContractRef}}</span></div>
      <div *ngIf="!isMixedGradeContract">
        <mat-slide-toggle (change)="showBulkAllocateMode($event)">Bulk Allocate</mat-slide-toggle>
      </div>
    </div>
    <div #header>
      <div class="drag-group">
        <div class="contract-info">
          <div>
            <div>Contract Ref: <span>{{contract.customerContractRef}}</span></div>
          </div>
          <div class="comments-wrapper" *ngIf="contract.comments">
            <span><i class="fa fa-lg fa-commenting"></i></span>
            <div class="comments">
              <div class="comments-content">
                {{contract.comments}}
              </div>
            </div>
          </div>
          <div>
            <div>Total balance: <span>{{contract.totalBalance | number : '1.0-1'}} {{contract.weightUomCode}}</span>
            </div>
          </div>
        </div>
        <span *ngIf="isMixedGradeContract" class="multi-grade-info">Allocate a single load of multi grade
          materials</span>
        <table class="table alt-table" #contractLines formArrayName="contractLines">
          <thead>
            <th></th>
            <th>Grade</th>
            <th>Start<br>Date</th>
            <th>Delivery<br>Period</th>
            <th>Balance</th>
            <th>Target</th>
            <th>Allocated</th>
            <th>Delivery<br>Method</th>
            <th>Delivery<br>Point</th>
            <th *ngIf="!bulkAllocate">Weight<br />Required</th>
            <th *ngIf="!bulkAllocate && !isMixedGradeContract">Loads<br />Required</th>
            <th *ngIf="!isMixedGradeContract">Weight per<br />Load</th>
          </thead>
          <tbody>
            <ng-container *ngFor="let line of contract.contractLines; let i = index">
              <tr [formGroupName]="i" (click)="selectLine(line)" [ngClass]="{ 'selected' : line.isSelected }">
                <td>
                  <i class="fa fa-question-circle tool-tip" matTooltip="{{getDepots(line)}}">
                  </i>
                </td>
                <td>{{line.grade == null ? line.salesGrade : line.grade}} - {{line.salesGradeDesc}}</td>
                <td>{{line.startDate | momentUtcAsLocaleDate}}</td>
                <td>{{line.deliveryPeriod}}</td>
                <td>{{line.balanceWeight | number : '1.0-1'}} {{line.weightUomCode}}</td>
                <td>{{line.targetWeight | number : '1.0-1'}} {{line.weightUomCode}}</td>
                <td>{{line.allocatedWeight == null ? 0 : line.allocatedWeight | number: '1.0-1'}} {{line.weightUomCode}}
                </td>
                <td>{{line.deliveryMethod}}</td>
                <td>{{line.deliveryPoint}}</td>
                <td [hidden]="bulkAllocate">
                  <div class="table-input">
                    <input type="number" [min]="0" #qty (input)="qtyChange(qty.value, i, loads, weightPerLoad, line)"
                      formControlName="requiredWeight" />
                  </div>

                  <input type="hidden" formControlName="contractLineId">
                </td>
                <td [hidden]="bulkAllocate || isMixedGradeContract">
                  <div class="table-input">
                    <input type="number" [min]="0" #loads
                      (input)="loadsChange(loads, qty.value, i, weightPerLoad, line)" formControlName="requiredLoads"
                      testName="loads" />
                    <a *ngIf="loads.disabled" aria-label="Clear" testName="loadsClear"
                      (click)="resetInputs(loads, weightPerLoad, i)">
                      <fa-icon icon="times-circle"></fa-icon>
                    </a>
                  </div>
                </td>
                <td [hidden]="isMixedGradeContract">
                  <div class="row-container">
                    <div class="table-input">
                      <input type="number" [min]="0" #weightPerLoad
                        (input)="weightPerChange(weightPerLoad, qty.value, i, loads, line)"
                        formControlName="requiredWeightPerLoad" testName="weightPerLoad" />
                      <a *ngIf="weightPerLoad.disabled" type="button" aria-label="Clear" testName="weightPerLoadClear"
                        (click)="resetInputs(loads, weightPerLoad, i)">
                        <fa-icon icon="times-circle"></fa-icon>
                      </a>
                    </div>
                    <div>{{line.weightUomCode}}</div>
                  </div>
                </td>
              </tr>
              <tr *ngIf="line.requestsMessage">
                <td colspan="11" class="warning"><i class="fa fa-exclamation-triangle" matSuffix></i>
                  {{line.requestsMessage}}</td>
              </tr>
              <tr *ngIf="line.weightWarning">
                <td colspan="11" class="warning"><i class="fa fa-exclamation-triangle" matSuffix></i>
                  {{line.weightWarning}}</td>
              </tr>
              <tr *ngIf="line.loadsWarning">
                <td colspan="11" class="warning"><i class="fa fa-exclamation-triangle" matSuffix></i>
                  {{line.loadsWarning}}</td>
              </tr>
            </ng-container>

          </tbody>
        </table>
        <span *ngIf="noLinesPicked" class="error-message">
          <i class="fa fa-exclamation-triangle"></i>
          You must enter values on at least one line!
        </span>
        <span *ngIf="noLinesPickedForBulk" class="error-message">
          <i class="fa fa-exclamation-triangle"></i>
          You must select one line and enter values!
        </span>
        <span *ngIf="linesInvalid" class="error-message">
          <i class="fa fa-exclamation-triangle"></i>
          One or more lines are invalid!
        </span>
      </div>
    </div>
  </div>

  <div class="allocationForm" (click)="hideLoadTimes()" #loadingCreateDialogBox>
    <div aria-label="Allocate?">
      <div class="dialog-input-container">
        <div class="allocation-mode">
          <div class="row-container">
            <mat-radio-group aria-label="Allocation mode" class="row-container" *ngIf="bulkAllocate"
              (change)="selectAllocationMode($event)">
              <mat-radio-button value="date" checked>Loads by date</mat-radio-button>
              <mat-radio-button value="week">Loads by week</mat-radio-button>
            </mat-radio-group>

            <mat-checkbox *ngIf="bulkAllocate" formControlName="groupAllocations">
              Group Allocations
            </mat-checkbox>
          </div>
          <div class="row-container">
            <mat-form-field>
              <input type="text" matInput formControlName="depot" [matAutocomplete]="depotAuto" placeholder="Depot*"
                #depotInput oninput="this.value = this.value.toUpperCase()">
              <button mat-button *ngIf="depot" matSuffix mat-icon-button aria-label="Clear"
                (click)="clearDepot(depotInput)" testName="depotAutoClear">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <mat-autocomplete #depotAuto="matAutocomplete" [displayWith]="displayDepot">
              <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
              <mat-option *ngFor="let depot of filteredDepots?.items " (onSelectionChange)="checkForRequests(depot)"
                [value]="depot">{{depot?.depotNumber + ' ' + depot?.depotName}}</mat-option>
            </mat-autocomplete>
            <mat-form-field>
              <mat-select formControlName="containerTypeId" placeholder="Container Type*"
                (selectionChange)="selectContractType($event)">
                <mat-option *ngFor="let container of containerTypes" [value]="container.containerTypeId">
                  {{container.containerTypeName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="!bulkAllocate">
              <input matInput type="text" aria-label="container number" placeholder="Container Number" class="md-input"
                formControlName="containerNumber" autocomplete="off">
            </mat-form-field>
            <mat-checkbox *ngIf="!bulkAllocate" formControlName="bulkAllocateGroupAllocations">
              Group Allocations
            </mat-checkbox>
            <mat-checkbox formControlName="isBooked">
              Booking Confirmed
            </mat-checkbox>
          </div>

          <div class="row-container" *ngIf="bulkAllocate">
            <mat-form-field>
              <input type="text" matInput formControlName="haulier" #haulier [matAutocomplete]="haulierAuto"
                placeholder="Haulier" oninput="this.value = this.value.toUpperCase()">
              <button mat-button *ngIf="showHaulierClear(haulier)" type="button" matSuffix mat-icon-button
                aria-label="Clear" testName="haulierClear" (click)="haulierClear(haulier)">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-autocomplete #haulierAuto="matAutocomplete" [displayWith]="displayHaulier">
              <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
              <mat-option *ngFor="let haulier of filteredHauliers" [value]="haulier">{{haulier?.accountNumber}} -
                {{haulier?.name}}</mat-option>
            </mat-autocomplete>
            <mat-form-field>
              <input matInput type="number" aria-label="agreed haulage rate" placeholder="{{spotRateText}}"
                class="md-input" formControlName="agreedHaulageRate" autocomplete="off"
                (change)="updateHaulageRateDropdown($event)">
            </mat-form-field>
            <mat-form-field>
              <mat-select formControlName="agreedHaulageRateUomId" placeholder="Spot Rate UOM">
                <mat-option *ngFor="let uom of uoms" [value]="uom.id">
                  {{uom.code + ' - ' + uom.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div style="padding-top:10px;" *ngIf="!bulkAllocate">
          <app-allocation-appointments *ngIf="!bulkAllocate" formControlName="allocationAppointments"
            [partyDeliveryPointIds]="partyDeliveryPointIds" [depotNo]="depotNo" [loadsCount]="calculateLoadsCount()"
            [data]="data" [contractLineId]="0" [containerTypeId]="containerTypeId" [hidePendingDispatch]="false"
            [appointments]="appointments">
          </app-allocation-appointments>
        </div>
        <div class="row-container1 shade" *ngIf="!bulkAllocate">
          <mat-form-field>
            <textarea cols="50" rows="2" matInput placeholder="Instructions" maxlength="{{instructionsMaxlength}}"
              class="md-input" formControlName="instructions"></textarea>
            <div class="md-errors-spacer"></div>
            <div class="textarea-counter">{{remainingInstructionsText}}</div>
          </mat-form-field>
        </div>
        <app-additional-costs *ngIf="!bulkAllocate" [maxCosts]="maxCosts" [uoms]="uoms"
          [defaultUOMId]="defaultAdditionalCostUomId" formControlName="additionalCosts" [enableCollapsing]="false"
          [hasDefaultRow]="false"></app-additional-costs>
      </div>
    </div>
  </div>
  <div class="bulkallocationForm" *ngIf="bulkAllocate" (click)="hideLoadTimes()">
    <app-spinner [displayProgressSpinner]="loadinghaulageRates" [container]="haulageRatesBox" #haulageRatesSpinner>
    </app-spinner>
    <div aria-label="Bulk Allocate" #haulageRatesBox>
      <div mat-dialog-content class="dialog-input-container">
        <div class="row-container shade allocate-by-date" aria-label="Load by date"
          *ngIf="bulkAllocationMode == 'date' || bulkAllocationMode == null">
          <div class="load-by-dates-wrap">
            <div *ngFor="let j of groupDaysCount;" [ngClass]="{'lastdayrow-width': j==groupDaysCount.length-1}">
              <div style="display: inline-block;"
                *ngFor="let loadsbydate of datePlaceholder.slice(j*7, (j*7+7));let i = index"
                formArrayName="loadsByDate">
                <div [formGroupName]="j*7+i">
                  <mat-form-field>
                    <input matInput type="number" placeholder="{{loadsbydate | date: 'EEE d/M'}}" class="md-input"
                      formControlName="requiredLoads" (focus)="hideLoadTimes()">
                  </mat-form-field>
                  <span style="cursor: pointer" *ngIf="getLoadTimes(j*7+i, 'loadsByDate').length > 0" matSuffix
                    (click)="showHideLoadTimes(j*7+i, $event)">
                    <fa-icon [icon]="'clock'"></fa-icon>
                  </span>
                  <div class="loadTimes" [ngStyle]="loadTimesStyle" [hidden]="hiddenLoadTimes(j*7+i)"
                    (click)="clockClicked = true">
                    <div formArrayName="loadTimes">
                      <div *ngFor="let l of getLoadTimes(j*7+i, 'loadsByDate'); let t = index" [formGroupName]="t">
                        <mat-form-field class="small-form-field">
                          <input matInput id="datetime" formControlName="loadTime" type="time" for
                            placeholder="Pickup load {{t+1}}">
                        </mat-form-field>
                        <mat-form-field *ngIf="t==0">
                          <mat-select id="ddlIncrement" formControlName="increment"
                            placeholder="Pickup other loads at every"
                            (selectionChange)="setIncrements(j*7+i, $event.value, 'byDates')">
                            <mat-option *ngFor="let inc of increments" [value]="inc">{{ inc }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <ng-container *ngIf="i==0 && t==0">
                          <a class="mat-button mat-primary" (click)="applyLoadTimesToAll()">Apply to all</a>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div style="display:inline-block">
                <a style="min-width: 30px;" class="mat-button secondary trash-icon-align"
                  *ngIf="(j*7+6) == datePlaceholder.length-1 && j!=0" (click)="removeDatesFromDatePlcaeholder()">
                  <i class="fa fa-trash"></i>
                </a>
              </div>
            </div>

            <div style="display: block;float: left;width: 50%;">
              <a style="padding: 0;" class="mat-button primary" (click)="addDatesToDatePlcaeholder()">Add days</a>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div mat-dialog-content class="dialog-input-container">
      <div class="row-container shade allocate-by-week" aria-label="Load by week" *ngIf="bulkAllocationMode == 'week'">
        <div class="load-by-weeks-wrap">
          <div *ngFor="let k of groupWeeksCount;">
            <div style="display: inline-block;"
              *ngFor="let loadsbyweek of weekPlaceholder.slice(k*7, (k*7+7)); let i = index"
              formArrayName="loadsByWeek">
              <div [formGroupName]="k*7+i">
                <mat-form-field>
                  <input matInput type="number" placeholder="{{loadsbyweek | date: 'EEE d/M'}}" class="md-input"
                    formControlName="requiredLoads" (focus)="hideLoadTimes()">
                </mat-form-field>
                <span style="cursor: pointer" *ngIf="getLoadTimes(k*7+i, 'loadsByWeek').length > 0" matSuffix
                  (click)="showHideLoadTimes(k*7+i, $event)">
                  <fa-icon [icon]="'clock'"></fa-icon>
                </span>
                <div class="loadTimes" [ngStyle]="loadTimesStyle" [hidden]="hiddenLoadTimes(k*7+i)"
                  (click)="clockClicked = true">
                  <div formArrayName="loadTimes">
                    <div *ngFor="let l of getLoadTimes(k*7+i, 'loadsByWeek'); let t = index" [formGroupName]="t">
                      <mat-form-field class="small-form-field">
                        <input matInput id="datetime" formControlName="loadTime" type="time" for
                          placeholder="Pickup load {{t+1}}">
                      </mat-form-field>

                      <mat-form-field *ngIf="t==0">
                        <mat-select id="ddlIncrement" formControlName="increment"
                          placeholder="Pickup other loads at every"
                          (selectionChange)="setIncrements(k*7+i, $event.value, 'byWeek')">
                          <mat-option *ngFor="let inc of increments" [value]="inc">{{ inc }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="display:inline-block">
              <a style="min-width: 30px;" class="mat-button secondary trash-icon-align"
                *ngIf="(k*7+6) == weekPlaceholder.length-1 && k!=0" (click)="removeWeeksFromWeekPlaceHolder()">
                <i class="fa fa-trash"></i>
              </a>
            </div>
          </div>
          <div style="display: block;float: left;width: 50%;">
            <a style="padding: 0;" class="mat-button primary" (click)="addWeeksToWeekPlaceholder()">Add weeks</a>
          </div>
        </div>
      </div>
    </div>
    <div class="row-container allocation-mode">
      <mat-form-field>
        <textarea matInput placeholder="Instructions" class="md-input" maxlength="{{instructionsMaxlength}}"
          formControlName="bulkAllocateIns"></textarea>
        <div class="md-errors-spacer"></div>
        <div class="textarea-counter">{{remainingInstructionsText}}</div>
      </mat-form-field>
    </div>
    <span *ngIf="noLoads" class="error-message">
      <i class="fa fa-exclamation-triangle"></i>
      Please enter require loads!
    </span>
    <span *ngIf="noPickupDate" class="error-message">
      <i class="fa fa-exclamation-triangle"></i>
      Please enter pickup time!
    </span>
  </div>
  <div mat-dialog-actions class="buttons-row">
    <button mat-flat-button type="button" (click)="cancel()">Cancel</button>
    <button mat-flat-button color="primary" type="submit" [disabled]="isSubmit">Allocate</button>
  </div>
</form>
