import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiPageResponse, UomVm } from '../gen/models';
import { SortInfo } from '../table-sort/table-sort.directive';
import { SidenavService } from '../services/sidenav.service';
import { DepotService } from '../gen/services/depot.service';
import { isNullOrUndefined } from '../tools';
import { GradeService } from '../gen/services/grade.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { TransportPlanService } from '../gen/services/transport-plan.service';
import * as moment from 'moment';
import { TransportPlanViewModel } from '../gen/models/TransportPlanViewModel';
import { TransportPlanWeeklyDataViewModel } from '../gen/models/TransportPlanWeeklyDataViewModel';
import { LocationViewModel } from '../gen/models/LocationViewModel';
import { TransportPlanFavouriteDepotsViewModel } from '../gen/models/TransportPlanFavouriteDepotsViewModel';
import { TransportPlanHeapPrefsViewModel } from '../gen/models/TransportPlanHeapPrefsViewModel';
import { ConfigService } from '../services/config-service';
import { ADGroupService } from '../gen/services/adgroup.service';
import { UserPreferencesVm } from '../gen/models/UserPreferencesVm';
import { DepotViewModel } from '../gen/models/DepotViewModel';
import { ActivatedRoute } from '@angular/router';
import { AutocompleteComponent } from '../autocomplete/autocomplete.component';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { TransportPlanQueryModel } from '../gen/models/TransportPlanQueryModel';
import { EnumVals } from '../gen/enums/enums';
import { UomsService } from '../gen/services/uoms.service';
import { SpinnerComponent } from '../spinner/spinner.component';
import { debounceTime, finalize, map, startWith, switchMap, tap } from 'rxjs/operators';
import { Utilities } from '../shared/utilites';
import { TransportPlanSaveRequestModel } from '../gen/models/TransportPlanSaveRequestModel';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
    selector: 'app-transport-plan',
    templateUrl: './transport-plan.component.html',
    styleUrls: ['./transport-plan.component.scss']
})
export class TransportPlanComponent implements OnInit {
    destinationModelChanged: Subject<string> = new Subject<string>();
    destinationLoading = false;
    showDestinations = false;
    showComment = false;
    favouriteGradesList: number[] = [];
    planDataForm: FormGroup;
    filteredDestinations: ApiPageResponse<LocationViewModel>;
    transportModes: string[] = ['TRUCK', 'CONTAINER', 'RAIL', 'SHIP'];
    activeWeek: moment.Moment;
    activeDay: moment.Moment = moment();
    showSearch = false;
    sortReverse = false;
    isLoading = true;
    noRecord = false;
    hasSearched = false;
    canSave = false;
    searchForm: FormGroup;
    page = 1;
    pageSize: number = EnumVals.NoOfResults[1].value;
    collectionSize = 1;
    transportPlandata: TransportPlanViewModel[] = [];
    tableState: TransportPlanQueryModel;
    filteredDepots: ApiPageResponse<DepotViewModel>;
    defaultDepot: DepotViewModel;
    heading: string;
    uoms: UomVm[];
    selectedUOMCode = 'MT';
    transportPlanSubscription: Subscription;
    transportPlanServiceSubscription: Subscription;
    transportPlanSingledata: TransportPlanViewModel[] = [];
    defaultLoadMT = 20;
    defaultLoadTruckMTTruck = Number(21.4);
    defaultLoadMTShip = 3000;
    defaultLoadMTRail = 550;
    defaultLoadMTContainer = Number(21.4);
    prefs: TransportPlanHeapPrefsViewModel[] = [];
    isWeekDirty = true;
    autoSaveOnCalendarChange = false;
    fileName: string;
    showTransportPlanDebugCalendar = false;
    debugCalendarMinDate: moment.Moment;
    debugCalendarMaxDate: moment.Moment;
    showLiveLoads = false;
    showBookingSheet = false;
    showExportAll = false;
    userPreferencesData: UserPreferencesVm;
    preferedDepot: DepotViewModel;
    defaultTerritory: string;
    hasInvalidInputs = false;
    isDefault = true;
    allToggled = false;

    anyLocation: LocationViewModel = {
        description: 'Any Location',
        longName: 'ANY - Any Location',
        locationId: -9999,
        locationCode: 'ANY',
    };

    ctrlclicked = false;

  @ViewChild('spinner', { static: true }) spinner: SpinnerComponent;
  @ViewChild('debugDatePicker', {
      read: MatInput
  }) debugDate: MatInput;
  @ViewChild('gradeInput') gradeInput: AutocompleteComponent;
  @ViewChild('gradeGroupInput') gradeGroupInput: AutocompleteComponent;
  favSubmitted = false;
  overrideCollission = false;

  tableHeight = 800;

  constructor(
    private dialog: MatDialog,
    private transportSheetPlanService: TransportPlanService,
    private fb: FormBuilder,
    private sidenavService: SidenavService,
    private depotsService: DepotService,
    public gradeService: GradeService,
    private uomsService: UomsService,
    private toastr: ToastrService,
    private configService: ConfigService,
    private adGroupService: ADGroupService,
    private route: ActivatedRoute,
    private clipboard: Clipboard) {
      this.destinationModelChanged.pipe(tap(val => this.destinationLoading = true), //todo move this to ngOnInit()
          debounceTime(500),
          startWith(null))
          .subscribe(model => {
              if (!model || model.length < 2) return;
              if (this.transportPlanServiceSubscription != null && !this.transportPlanServiceSubscription.closed) {
                  this.transportPlanServiceSubscription.unsubscribe();
              }
              this.transportPlanServiceSubscription = this.transportSheetPlanService.getTransportPlanDestination(model).subscribe(
                  x => {
                      this.filteredDestinations = x;
                      this.destinationLoading = false;
                      this.showDestinations = true;
                  });
          });
      const userPreferences = this.route.snapshot.data['userpreferencesData'];
      if (userPreferences !== undefined && userPreferences.length > 0)
          this.userPreferencesData = userPreferences[0];
      else
          this.userPreferencesData = { depot: '', territory: '', requestFields: '', allocationFields: '', orderBookType: '', viewMode: '', allocationDate: '', pickUpFromDate: ''  };

      this.calculateHeight();
  }

  calculateHeight(fromSearch = false) {
      let searchHeight = 0;
      if (fromSearch)
          searchHeight = 50;

      const intFrameHeight = window.innerHeight;
      const calulatedHeight = intFrameHeight - 50 - 48 - 37 - searchHeight - 10; //the height of the inside screen - the top 3 sections (excluding the search - 10 for spacings
      this.tableHeight = calulatedHeight;
  }

  searchClicked() {
      this.showSearch = !this.showSearch;
      this.calculateHeight(this.showSearch);
  }

  saveTransportPlanButtonClick(): void {
      this.saveTransportPlanIfCanSave().subscribe(success => {
          if (success) {
              this.setFavouriteGrades(true);
          }
      });

  }

  saveTransportPlanIfCanSave(showMessage = true): Observable<boolean> {
      const canSave = this.canSaveTransportPlan();
      if (!canSave)
          return of(false);

      return this.saveTransportPlan(showMessage);
  }

  saveTransportPlan(showMessage = true): Observable<boolean> {
      const validInput = this.validateTransportPlanInputs(showMessage);
      if (!validInput)
          return of(false);

      this.isLoading = true;
      const savemodel: TransportPlanSaveRequestModel = {
          plans: this.transportPlandata,
          overrideCollission: this.overrideCollission
      };

      this.overrideCollission = false; //reset

      const obs = this.transportSheetPlanService.saveTransportPlanData(savemodel).pipe(map(
          x => {
              if (x && x.success && x.success === true) {
                  this.transportPlandata = x.transportPlanData;
                  this.toastr.success('Weekly plan data saved successfully', 'Transport Plan');
                  this.isLoading = false;
                  return true;
              }
              else if (x.requireRefresh) {
                  this.toastr.warning('This plan has already been updated by someone else. Save again to override changes or apply filter to refresh.', 'Not Saved');
                  this.overrideCollission = true;
                  this.isLoading = false;
                  return false;
              }
              else {
                  let errorMessage = '';
                  if (x.errors !== null && x.errors.length > 0) {
                      if (errorMessage !== '' && !errorMessage.endsWith('\n'))
                          errorMessage += '\n';
                      errorMessage += x.errors.join('\n');
                  }

                  if (showMessage) {
                      if (errorMessage === '') {
                          errorMessage = 'Weekly plan could not be saved. Please try again';
                      }
                      this.toastr.error(errorMessage, 'Transport Plan');
                  }
                  this.isLoading = false;
                  return false;
              }
          },
          error => {
              this.toastr.error('Weekly plan could not be saved. Please try again', 'Transport Plan');
              this.isLoading = false;
              return false;
          }));

      return obs;
  }

  validateTransportPlanInputs(showMessage = true, setComponentFlag = false): boolean {
      let isValid = true;
      let hasAnyLocationWithTruck = false;
      this.transportPlandata.forEach(data => {
          const wd = data.plan.weeklyData.filter(wd => wd.transportMode === 'TRUCK' && wd.destination.locationCode === 'ANY' && wd.weekTotal > 0);
          if (wd.length > 0) {
              hasAnyLocationWithTruck = true;
          }
      });

      if (hasAnyLocationWithTruck) {
          if (showMessage)
              this.toastr.error('The destination can not be ANY if you want to request trucks.', 'Transport Plan');
          isValid = false;
      }

      if (setComponentFlag)
          this.hasInvalidInputs = !isValid;

      return isValid;
  }

  onKeyEscapeComments(event: any, item?: TransportPlanViewModel, wd?: TransportPlanWeeklyDataViewModel) {
      this.showComment = true;
  }

  deleteFavouriteDepotHeap(item?: TransportPlanViewModel) {
      if (item == null) return;

      const favDepot = item.depotNumber;

      const favDel = new TransportPlanFavouriteDepotsViewModel();
      favDel.depotNo = favDepot;
      favDel.heapNo.push(item.heapNo);
      favDel.delete = true;

      this.transportSheetPlanService.saveTransportPlanFavouriteDepots(favDel).subscribe(
          x => {
              if (x === true) {
                  this.refresh();
                  this.toastr.success('Selected heap is removed from favourites', 'Transport Plan');
              }
              else {
                  this.toastr.error('Can\'t remove selected heap from favourites. Please try again', 'Transport Plan');
              }
          });
  }

  addToFavouriteGrades(event: any, item?: TransportPlanViewModel) {
      const heap = item.heapNo;

      if (event.checked) {
          this.favouriteGradesList.push(heap);
      }
      else {
          const index = this.favouriteGradesList.indexOf(heap);
          this.favouriteGradesList.slice(index, 1);
          this.deleteFavouriteDepotHeap(item);
      }
  }

  setFavouriteGrades(checkHeap = false) {
      this.favSubmitted = true;
      if (this.favouriteGradesList.length > 0) {
          const favDepot = this.tableState.search.depotNumber ? this.tableState.search.depotNumber : this.defaultDepot.depotNumber;

          if (!favDepot) {
              this.toastr.error('Can\'t add selected heaps to your favourites. Please refresh page and try again', 'Transport Plan');
              return;
          }

          const fav = new TransportPlanFavouriteDepotsViewModel();
          fav.depotNo = favDepot;
          fav.heapNo = this.favouriteGradesList;

          this.transportSheetPlanService.saveTransportPlanFavouriteDepots(fav)
              .pipe(finalize(() => this.favSubmitted = false))
              .subscribe(
                  x => {
                      if (x === true) {
                          this.refresh();
                          this.toastr.success('Selected heaps are added to your favourites', 'Transport Plan');
                      }
                      else {
                          this.toastr.error('Selected heaps can\'t be added to your favourites. Please try again', 'Transport Plan');
                      }
                  });

      } else {
          if (!checkHeap) {
              this.toastr.error('Please select some heaps first.', 'Transport Plan');
          }
          this.favSubmitted = false;
      }
  }

  readyOnlyDays(day: number) {
      let readonly = true;
      if (this.activeWeek.startOf('isoWeek').isAfter(moment().startOf('isoWeek'))) {
          readonly = false;
      }
      else if (this.activeWeek.startOf('isoWeek').isBefore(moment().startOf('isoWeek'))) {
          readonly = true;
      }
      else if (this.activeWeek.startOf('isoWeek').isSame(moment().startOf('isoWeek'))) {
          if (this.showTransportPlanDebugCalendar) {
              readonly = day < this.activeDay.day();
          } else {
              readonly = day < moment().day();
          }
      }
      return readonly;
  }

  destinationValueChange(val: string) {
      if (val.length > 1) {
          val = val.toUpperCase();
          this.destinationModelChanged.next(val);
      }
      else {
          this.destinationLoading = false;
          this.showDestinations = false;
      }
  }

  lostFocus(event?: any, wd?: TransportPlanWeeklyDataViewModel) {
      if (event.target.value.length == 0) {
          event.target.value = this.anyLocation.description;
          this.destinationChanged(this.anyLocation, wd);
      }
  }

  displayDestination(lc?: LocationViewModel): string | undefined {
      if (lc) {
          lc.longName = lc.locationCode + ' - ' + lc.description;
      }

      return lc ? lc.locationCode + ' - ' + lc.description : undefined;
  }

  destinationChanged(lc?: LocationViewModel, wd?: TransportPlanWeeklyDataViewModel) {
      if (lc.locationCode === '') return;

      lc.longName = lc.locationCode + ' - ' + lc.description;
      wd.destination = lc;
      wd.userModified = true;
      this.filteredDestinations = null;
      this.validateTransportPlanWeeklyDataViewModel(wd, true);
  }

  updateComments(val: string, wd?: TransportPlanWeeklyDataViewModel) {
      this.showComment = false;

      if (val === null) return;

      wd.userModified = true;
      wd.comments = val;
  }

  updateTransportMode(tm: any, item: TransportPlanViewModel, wd?: TransportPlanWeeklyDataViewModel) {
      if (tm === null || tm.value === null || item === null || wd === null) return;
      wd.userModified = true;
      wd.transportMode = tm.value;
      let defaultLoadMT = -1;

      for (let i = 0; i < item.plan.weeklyData.length; i++) {
          if (item.plan.weeklyData[i].transportMode === wd.transportMode) {
              defaultLoadMT = wd.defaultLoadPerMT;
          }
      }

      if (defaultLoadMT < 0) defaultLoadMT = this.defaultLoadMT;

      switch (tm.value.toUpperCase()) {
      case 'TRUCK':
          defaultLoadMT = this.defaultLoadTruckMTTruck;
          break;
      case 'SHIP':
          defaultLoadMT = this.defaultLoadMTShip;
          break;
      case 'CONTAINER':
          defaultLoadMT = this.defaultLoadMTContainer;
          break;
      case 'RAIL':
          defaultLoadMT = this.defaultLoadMTRail;
          break;
      }
      if (item.plan.weeklyData.filter(a => a.transportMode.toUpperCase() === tm.value.toUpperCase()).length > 1) {
          const recentLoadMT = item.plan.weeklyData.filter(a => a.transportMode.toUpperCase() === tm.value.toUpperCase())[0].loadPerMT;
          if (recentLoadMT !== defaultLoadMT)
              defaultLoadMT = recentLoadMT;
      }

      wd.loadPerMT = defaultLoadMT;

      if (wd.weeklyPlanDataId == 0) {
          wd.defaultLoadPerMT = defaultLoadMT;
      }

      this.validateTransportPlanWeeklyDataViewModel(wd, true);

      this.recalculateForecastNet(item);
  }

  validateTransportPlanWeeklyDataViewModel(weeklyData: TransportPlanWeeklyDataViewModel, setComponentFlag = true): boolean {
      let isValid = true;
      if (weeklyData.weekTotal > 0 && weeklyData.transportMode === 'TRUCK' && weeklyData.destination.locationCode === 'ANY')
          isValid = false;

      if (setComponentFlag)
          this.hasInvalidInputs = !isValid;

      return isValid;
  }

  async setCurrentWeekCalendar(overrideDate: Date = null) {
      this.isLoading = true;
      if (overrideDate) {
          this.activeWeek = moment(overrideDate).startOf('isoWeek');
          this.activeDay = moment(overrideDate);
      } else {
          this.activeWeek = moment().startOf('isoWeek');
          this.activeDay = moment();
      }

      this.tableState.search.startDate = this.activeWeek.startOf('isoWeek').format('YYYY-MM-DD');
      this.tableState.search.endDate = this.activeWeek.endOf('week').format('YYYY-MM-DD');
      this.tableState.search.currentDay = this.activeDay;
      this.callServer(this.tableState);
  }

  async loadPreviousWeek() {
      this.saveTransportPlanIfCanSave().subscribe(success => {
          this.loadPreviousWeekData();
      });
  }

  async loadNextWeek() {
      this.saveTransportPlanIfCanSave().subscribe(success => {
          if (!success) {
              this.toastr.warning('Past week plan not saved', 'Transport Plan');
          }
          this.loadNexWeekData();
      });
  }

  canSaveTransportPlan(): boolean {
      const week = this.activeWeek.clone();
      this.canSave = !week.startOf('isoWeek').isBefore(moment().startOf('isoWeek'));
      return this.canSave;
  }

  loadNexWeekData() {
      this.activeWeek = this.activeWeek.add(1, 'week');
      this.tableState.search.startDate = this.activeWeek.startOf('isoWeek').format('YYYY-MM-DD');
      this.tableState.search.endDate = this.activeWeek.endOf('week').format('YYYY-MM-DD');
      this.tableState.search.currentDay = this.activeDay;
      this.callServer(this.tableState);
      this.canSaveTransportPlan(); //recalculate if we can save
  }

  loadPreviousWeekData() {
      this.activeWeek = this.activeWeek.add(-1, 'week');
      this.tableState.search.startDate = this.activeWeek.startOf('isoWeek').format('YYYY-MM-DD');
      this.tableState.search.endDate = this.activeWeek.endOf('week').format('YYYY-MM-DD');
      this.tableState.search.currentDay = this.activeDay;
      this.callServer(this.tableState);
      this.canSaveTransportPlan(); //recalculate if we can save
  }

  deleteWeeklyData(item?: TransportPlanViewModel, wd?: TransportPlanWeeklyDataViewModel) {
      if (item.plan == null || wd == null) return;

      if (this.activeWeek.startOf('isoWeek').isBefore(moment().startOf('isoWeek'))) {
          this.toastr.info('Can\'t delete Old data', 'Transport Plan');
          return;
      }

      const index = item.plan.weeklyData.indexOf(wd);
      item.plan.weeklyData[index].toBeDeleted = true;
      item.plan.weeklyData[index].userModified = true;
      this.recalculateForecastNet(item);
      let isDefaultRecRemove = true;
      if (this.transportPlandata.length > 0) {
          for (const bspd of this.transportPlandata) {
              if (bspd.plan.weeklyData.filter(d => d.transportMode === wd.transportMode).length > 0) {
                  isDefaultRecRemove = false;
                  break;
              }
          }
      }
  }

  realLengthChecker(item?: TransportPlanViewModel) {
      if (item.plan == null) return;

      const length = item.plan.weeklyData.filter(e => e.toBeDeleted != true).length;
      return length > 1;
  }

  addWeeklyData(item: TransportPlanViewModel) {
      if (this.activeWeek.startOf('isoWeek').isBefore(moment().startOf('isoWeek'))) {
          this.toastr.info('Can\'t add in previous dates', 'Transport Plan');
          return;
      }

      const defaultLoadTruck = item.plan.heapLoadMTPrefs.find(x => x.transportMode.toUpperCase() == 'TRUCK');

      item.plan.weeklyData.push(
          {
              weeklyPlanDataId: 0,
              planDataId: item.plan.planDataId,
              loadPerMT: defaultLoadTruck.defaultLoadMT,
              loadPerMTUserDefined: false,
              defaultLoadPerMT: defaultLoadTruck.defaultLoadMT,
              transportMode: 'TRUCK',
              destination: { locationCode: 'ANY', locationId: -9999, description: 'Any Location', longName: 'ANY - Any Location' },
              monday: 0,
              tuesday: 0,
              wednesday: 0,
              thursday: 0,
              friday: 0,
              saturday: 0,
              sunday: 0,
              weekTotal: 0,
              comments: '',
              lastModifiedDate: moment().toDate(),
              userModified: true,
              toBeDeleted: false
          });
  }

  getFirstWeeklyData(item: TransportPlanViewModel): TransportPlanWeeklyDataViewModel[] {
      if (!item.plan.weeklyData) {
          const defaultLoadTruck = item.plan.heapLoadMTPrefs.find(x => x.transportMode.toUpperCase() == 'TRUCK');
          item.plan.weeklyData = [
              {
                  weeklyPlanDataId: 0,
                  planDataId: item.plan.planDataId,
                  loadPerMT: defaultLoadTruck.defaultLoadMT,
                  loadPerMTUserDefined: false,
                  defaultLoadPerMT: defaultLoadTruck.defaultLoadMT,
                  transportMode: 'TRUCK',
                  destination: { locationCode: 'ANY', locationId: -9999, description: 'Any Location', longName: 'ANY - Any Location' },
                  monday: 0,
                  tuesday: 0,
                  wednesday: 0,
                  thursday: 0,
                  friday: 0,
                  saturday: 0,
                  sunday: 0,
                  weekTotal: 0,
                  comments: '',
                  lastModifiedDate: moment().toDate(),
                  userModified: true,
                  toBeDeleted: false
              }
          ];
      }
      const arrResult: TransportPlanWeeklyDataViewModel[] = [];
      arrResult.push(item.plan.weeklyData.find(wd => !wd.toBeDeleted));
      return arrResult;
  }

  skipFirst(item: TransportPlanViewModel): TransportPlanWeeklyDataViewModel[] {
      if (item.plan.weeklyData.length > 1) {
          return item.plan.weeklyData.filter(fd => fd != item.plan.weeklyData.find(wd => !wd.toBeDeleted));
      }
  }

  getWeeklyDataCount(item: TransportPlanViewModel): number {
      return item.plan.weeklyData.filter(wd => !wd.toBeDeleted).length;
  }

  toggleBookedInfo(item: TransportPlanViewModel) {
      item.toggleBooked = !item.toggleBooked;
  }

  toggleAllBookedInfo() {
      if (!this.allToggled) {
          this.transportPlandata.forEach(element => {
              element.toggleBooked = true;
          });
          this.allToggled = true;
      } else if (this.allToggled) {
          this.transportPlandata.forEach(element => {
              element.toggleBooked = false;
          });
          this.allToggled = false;
      }
  }

  updateOpeningStock(adj: any, item?: TransportPlanViewModel) {
      if (adj == '') {
          adj = item.plan.startWeekStockFromBI;
      }

      item.plan.startWeekStock = Number(adj);
      item.plan.userModified = true;
      this.recalculateForecastNet(item);
  }

  updateForecastMT(adj: number, item?: TransportPlanViewModel) {
      item.plan.forecastMt = Number(adj);
      item.plan.userModified = true;
      this.recalculateForecastNet(item);
  }

  updateForecastLoad(val: any, item?: TransportPlanViewModel, wd?: TransportPlanWeeklyDataViewModel) {
      let adj = val.target.value;

      if (adj === '') {
          const pref = item.plan.heapLoadMTPrefs.find(x => x.transportMode.toUpperCase() == wd.transportMode);
          adj = Number(pref.defaultLoadMTGradeDensity);
      } else {
          wd.loadPerMTUserDefined = true;
      }

      wd.userModified = true;
      wd.loadPerMT = Math.abs(Number(adj));
      val.target.value = wd.loadPerMT;

      for (let i = 0; i < item.plan.weeklyData.length; i++) {
          if (item.plan.weeklyData[i].transportMode === wd.transportMode && item.plan.weeklyData[i].weekTotal === 0) {
              item.plan.weeklyData[i].loadPerMT = wd.loadPerMT;
          }
      }

      switch (wd.transportMode.toUpperCase()) {
      case 'TRUCK':
          item.plan.heapLoadMTPrefs.find(x => x.transportMode.toUpperCase() == 'TRUCK').defaultLoadMT = wd.loadPerMT;
          break;
      case 'SHIP':
          item.plan.heapLoadMTPrefs.find(x => x.transportMode.toUpperCase() == 'SHIP').defaultLoadMT = wd.loadPerMT;
          break;
      case 'CONTAINER':
          item.plan.heapLoadMTPrefs.find(x => x.transportMode.toUpperCase() == 'CONTAINER').defaultLoadMT = wd.loadPerMT;
          break;
      case 'RAIL':
          item.plan.heapLoadMTPrefs.find(x => x.transportMode.toUpperCase() == 'RAIL').defaultLoadMT = wd.loadPerMT;
          break;
      }

      this.recalculateForecastNet(item);
  }

  recalculateForecastNet(item: TransportPlanViewModel) {
      const dayIndexMap = [{ day: 'monday', index: 0 }, { day: 'tuesday', index: 1 }, { day: 'wednesday', index: 2 }, { day: 'thursday', index: 3 }, { day: 'friday', index: 4 }, { day: 'saturday', index: 5 }, { day: 'sunday', index: 6 }];
      let calcDays = dayIndexMap;
      //check if we're looking at the current week
      const selectedDay = this.activeDay.clone();
      const selectedWeek = this.activeWeek.clone(); //clone these properties because moment().startOf() mutates the date value.
      const isActualCurrentWeek = selectedDay.startOf('isoWeek').isSame(selectedWeek.startOf('isoWeek'));
      if (isActualCurrentWeek) {
          const todayIndex = Number(this.activeDay.isoWeekday()) - 1; //isoWeekday starts on Monday = 1, but our day enums start at Monday = 0
          calcDays = dayIndexMap.filter(days => days.index >= todayIndex);
      }

      let sumWeekTotal = 0.0;
      //loop only over the relevant days, summing up the number of loads times the 'loadsPerMT' (MT per load)
      calcDays.forEach((weekday) => {
          item.plan.weeklyData.filter(wd => !wd.toBeDeleted).forEach((wd) => {
              const dayLoads = Number(Reflect.get(wd, weekday.day)); //get the number of loads for a given day with reflection
              const weightPerLoad = wd.loadPerMT;
              sumWeekTotal += dayLoads * weightPerLoad;
          });
      });

      item.plan.forecastNet = item.plan.startWeekStock + item.plan.forecastMt - sumWeekTotal;
      item.plan.userModified = true;
  }

  recalculateWeeklyTotal(adj: number, day: number, item?: TransportPlanViewModel, wd?: TransportPlanWeeklyDataViewModel) {
      adj = Number(adj);
      switch (day) {
      case 1:
          wd.monday = adj;
          break;
      case 2:
          wd.tuesday = adj;
          break;
      case 3:
          wd.wednesday = adj;
          break;
      case 4:
          wd.thursday = adj;
          break;
      case 5:
          wd.friday = adj;
          break;
      case 6:
          wd.saturday = adj;
          break;
      case 7:
          wd.sunday = adj;
          break;
      }
      wd.weekTotal = wd.monday + wd.tuesday + wd.wednesday + wd.thursday + wd.friday + wd.saturday + wd.sunday;
      wd.userModified = true;
      this.validateTransportPlanWeeklyDataViewModel(wd);
      this.recalculateForecastNet(item);
  }

  get depot() {
      return this.searchForm.get('depot');
  }

  ngOnInit() {
      this.adGroupService.getMyGroups().subscribe(g => {
          this.showBookingSheet = g.myGroups.includes('BookingSheet');
          this.showLiveLoads = g.myGroups.includes('BookedTransport');
          this.showExportAll = g.myGroups.includes('TransportPlanDownload');
      });

      const depot = this.route.snapshot.queryParamMap.get('depot');
      const grade = this.route.snapshot.queryParamMap.get('grade');
      const gradeGroup = this.route.snapshot.queryParamMap.get('gradegroup');
      const showFav = this.route.snapshot.queryParamMap.get('showfav');
      let showFavouriteHeapsOnly = true;
      if (showFav != null) {
          showFavouriteHeapsOnly = showFav.toLowerCase() === 'true';
      }

      this.preferedDepot = { depotNumber: this.userPreferencesData.depot, depotName: '', depotShortName: '', selected: false };
      if (isNullOrUndefined(this.userPreferencesData.depot))
          this.preferedDepot.depotNumber = '';

      if (this.userPreferencesData.territory === '')
          this.defaultTerritory = null;
      else
          this.defaultTerritory = this.userPreferencesData.territory;

      const retrievedDepot = { depotNumber: depot, depotName: '', depotShortName: '', selected: false };

      this.defaultDepot = !isNullOrUndefined(retrievedDepot.depotNumber) ? retrievedDepot : this.preferedDepot;
      const deptNo = isNullOrUndefined(this.defaultDepot.depotNumber) ? '' : this.defaultDepot.depotNumber;

      moment.updateLocale('en', {
          week: {
              dow: 1,
              doy: 4
          }
      });
      this.activeWeek = moment().startOf('isoWeek');
      this.canSaveTransportPlan();

      this.planDataForm = this.fb.group({ //todo remove?

      });

      this.searchForm = this.fb.group({
          startDate: this.activeWeek.startOf('isoWeek').format('YYYY-MM-DD'),
          endDate: this.activeWeek.endOf('week').format('YYYY-MM-DD'),
          useDefaultDepot: true,
          depot: this.defaultDepot,
          gradeGroup: gradeGroup,
          grade: grade,
          materialDescription: '',
          switchUomCode: this.selectedUOMCode,
          showFavouriteHeapsOnly: showFavouriteHeapsOnly,
          numAdjPattern: [{ value: '', disabled: false }, [Validators.required, Validators.pattern('^[0-9]{1,7}(\.[0-9]+)?$')]],
      });

      this.tableState = {
          pagination: { number: this.pageSize, start: this.page },
          search: {
              territory: this.defaultTerritory,
              depotNumber: deptNo,
              gradeGroup: gradeGroup,
              grade: grade,
              materialDescription: '',
              switchUomCode: this.selectedUOMCode,
              showAll: !showFavouriteHeapsOnly,
              startDate: this.activeWeek.startOf('isoWeek').format('YYYY-MM-DD'),
              endDate: this.activeWeek.endOf('week').format('YYYY-MM-DD'),
              useDefaultDepot: false,
              salesGrade: ''
          },
          sort: { predicate: null, reverse: this.sortReverse }
      };

      if (deptNo !== '')
          this.heading = this.defaultDepot.depotNumber + ' - ' + this.defaultDepot.depotName;

      this.callServer(this.tableState);

      this.depot.valueChanges
          .pipe(
              debounceTime(500),
              startWith(null),
              switchMap(v => this.filterDepots(v)))
          .subscribe(d => this.filteredDepots = d);

      this.showTransportPlanDebugCalendar = this.configService.config.showTransportPlanDebugCalendar;
      this.debugCalendarMinDate = moment().startOf('isoWeek');
      this.debugCalendarMaxDate = moment().endOf('week');
  }

  ngOnDestroy(): void {
      this.isLoading = false;
      this.spinner.overlayRef.dispose();
      if (!isNullOrUndefined(this.transportPlanSubscription) && !this.transportPlanSubscription.closed) {
          this.transportPlanSubscription.unsubscribe();
      }
      if (this.transportPlanServiceSubscription != null && !this.transportPlanServiceSubscription.closed) {
          this.transportPlanServiceSubscription.unsubscribe();
      }
  }

  getText(key: number) {
      switch (key) {
      case 3:
          return '1 Wk';
      case 4:
          return '2 Wk';
      case 5:
          return '3 Wk';
      case 6:
          return '4 Wk';
      case 8:
          return '8 Wk';
      case 9:
          return '12 Wk';
      case 10:
          return '24 Wk';
      }
  }

  clonePreviousWeekData() {
      this.isLoading = true;

      if (!this.validateTableState(this.tableState)) {
          this.isLoading = false;
          return;
      }

      if (!isNullOrUndefined(this.transportPlanSubscription) && !this.transportPlanSubscription.closed) {
          this.transportPlanSubscription.unsubscribe();
      }

      this.hasSearched = false;
      this.transportPlanSubscription = this.transportSheetPlanService.getPreviousTransportPlanData(this.tableState).subscribe(r => {
          this.transportPlandata = (r && r.bookings) ? r.bookings : [];
          this.collectionSize = (r && r.recordCount) ? r.recordCount : 1;
          this.selectedUOMCode = this.searchForm.controls.switchUomCode.value;

          this.favouriteGradesList = [];

          if (r && r.depot && r.depot.depotNumber) {
              this.defaultDepot = r.depot;
              this.heading = (r.depot.depotNumber + (r.depot.depotName ? ' - ' + r.depot.depotName : '')).toUpperCase();
          }

          if (this.tableState.pagination.start === 1) {
              this.page = 1;
          }
          this.isLoading = false;
          this.hasSearched = true;

          if (!(this.transportPlandata.length > 0)) {
              this.noRecord = true;
              this.toastr.info('No plan set for selected week and heaps.', 'Transport Plan');
          }
      }, error => {
          this.isLoading = false;
          this.hasSearched = false;
          if (!(this.transportPlandata.length > 0)) {
              this.noRecord = true;
          }
      });
  }

  callServer(tableState: TransportPlanQueryModel) {
      this.overrideCollission = false;
      this.isLoading = true;

      if (tableState) {
          this.tableState = tableState;
      }

      if (!this.validateTableState(tableState)) {
          this.isLoading = false;
          return;
      }

      if (!isNullOrUndefined(this.transportPlanSubscription) && !this.transportPlanSubscription.closed) {
          this.transportPlanSubscription.unsubscribe();
      }

      this.hasSearched = false;
      this.transportPlanSubscription = this.transportSheetPlanService.getTransportPlanData(tableState).subscribe(r => {
          this.transportPlandata = (r && r.bookings) ? r.bookings : [];
          this.collectionSize = (r && r.recordCount) ? r.recordCount : 1;
          this.selectedUOMCode = this.searchForm.controls.switchUomCode.value;

          this.favouriteGradesList = [];

          if (r && r.depot && r.depot.depotNumber) {
              this.defaultDepot = r.depot;
              this.heading = (r.depot.depotNumber + (r.depot.depotName ? ' - ' + r.depot.depotName : '')).toUpperCase();
          }

          if (this.tableState.pagination.start === 1) {
              this.page = 1;
          }
          this.isLoading = false;
          this.hasSearched = true;

          if (!(this.transportPlandata.length > 0)) {
              this.noRecord = true;
              this.toastr.info('No plan set for selected week and heaps.', 'Transport Plan');
          }
      }, error => {
          this.isLoading = false;
          this.hasSearched = false;
          if (!(this.transportPlandata.length > 0)) {
              this.noRecord = true;
          }
      });
  }

  filterDepots(val: string) {
      return this.depotsService.searchDepots(val);
  }

  displayDepot(depot?: DepotViewModel): string | undefined {
      let depotString = undefined;
      if (depot && depot.depotNumber && depot.depotNumber !== '' && depot.depotName !== '')
          depotString = depot.depotNumber + ' - ' + depot.depotName;
      else if (depot && depot.depotNumber && depot.depotNumber !== '' && depot.depotName === '')
          depotString = depot.depotNumber;
      return depotString;
  }

  sortByInfo(info: SortInfo) {
      if (info.active) {
          this.ctrlclicked = false;
          this.tableState.sort.reverse = info.direction != 'asc';
          this.sortReverse = this.tableState.sort.reverse;
          this.tableState.sort.predicate = info.active;
          this.isDefault = this.tableState.sort.reverse;
          this.callServer(this.tableState);
      }
  }

  sortByForcastNet(info: SortInfo) {
      this.ctrlclicked = true;
      this.tableState.sort.reverse = false;
      this.sortReverse = false;
      this.tableState.sort.predicate = null;
      this.callServer(this.tableState);
  }

  search(formVal: any) {
      let grade = formVal.grade;
      if (isNullOrUndefined(grade) || grade === '')
          grade = this.gradeInput.input.nativeElement.value;
      let gradeGroup = formVal.gradeGroup;
      if (isNullOrUndefined(gradeGroup) || gradeGroup === '')
          gradeGroup = this.gradeGroupInput.input.nativeElement.value;

      this.tableState.search = {
          territory: this.defaultTerritory, depotNumber: formVal.depot.depotNumber ? formVal.depot.depotNumber : formVal.depot,
          gradeGroup: gradeGroup,
          grade: grade,
          materialDescription: formVal.materialDescription,
          switchUomCode: this.selectedUOMCode,
          showAll: !formVal.showFavouriteHeapsOnly,
          startDate: this.activeWeek.startOf('isoWeek').format('YYYY-MM-DD'),
          endDate: this.activeWeek.endOf('week').format('YYYY-MM-DD'),
          currentDay: this.showTransportPlanDebugCalendar ? this.activeDay : null,
          salesGrade: ''
      };
      this.tableState.sort = { predicate: null, reverse: false };
      this.tableState.pagination = { number: this.pageSize, start: 1 };
      this.callServer(this.tableState);
  }

  searchByDepo(depotNumber: string) {
      this.tableState.search.depotNumber = depotNumber;
      this.callServer(this.tableState);
  }

  pageChanged(page: number) {
      if (!this.hasInvalidInputs) {
          this.saveTransportPlanIfCanSave().subscribe(success => {
              this.page = page;
              this.tableState.pagination.start = page;
              this.callServer(this.tableState);
          });
      } else {
          this.hasInvalidInputs = false; //reset validation flag
          this.page = page;
          this.tableState.pagination.start = page;
          this.callServer(this.tableState);
      }
  }

  refresh() {
      this.callServer(this.tableState);
  }

  validateTableState(tableState: TransportPlanQueryModel) {
      const validateSearchDepot = Utilities.getNestedAttribute(tableState, 'search', 'depotNumber');
      const validateDefaultDepot = Utilities.getNestedAttribute(this.defaultDepot, 'depotNumber');
      if ((isNullOrUndefined(validateSearchDepot) || validateSearchDepot == '') && (isNullOrUndefined(validateDefaultDepot) || validateDefaultDepot == '')) {
          return false;
      }
      return true;
  }

  switchUOM(uomCode: string) {
      this.tableState.search.switchUomCode = uomCode;
      this.callServer(this.tableState);
  }

  async exportToExcel() {
      let depotNo = this.tableState.search.depotNumber;
      if (depotNo === '')
          this.tableState.search.useDefaultDepot = true;
      else
          this.tableState.search.useDefaultDepot = false;

      if (!isNullOrUndefined(this.defaultDepot))
          depotNo = this.defaultDepot.depotNumber;

      const dt = this.activeWeek.startOf('isoWeek').format('YYYY-MM-DD');

      const fileName = 'Truck Request_' + dt + '_' + depotNo.toUpperCase() + '.xlsx';

      this.saveTransportPlanIfCanSave().subscribe(success => {
          if (!success)
              return;
          this.callServerForExportToExcel(fileName);
      });
  }

  async exportToExcelAllTrucks() {
      const from = this.activeWeek.startOf('isoWeek').format('DD-MM-YYYY');
      const to = this.activeWeek.endOf('week').format('DD-MM-YYYY');

      const fileName = 'Truck Request for the week from_' + from + '_to_' + to + '.xlsx';

      this.saveTransportPlanIfCanSave().subscribe(success => {
          if (!success)
              return;
          this.callServerForExportToExcelAllTrucks(fileName);
      });
  }

  async exportToExcelAll() {
      const from = this.activeWeek.startOf('isoWeek').format('DD-MM-YYYY');
      const to = this.activeWeek.endOf('week').format('DD-MM-YYYY');

      const fileName = 'Truck Request for the week from_' + from + '_to_' + to + '.xlsx';

      this.saveTransportPlanIfCanSave().subscribe(success => {
          if (!success)
              return;
          this.callServerForExportToExcelAll(fileName);
      });
  }

  async exportToExcelForFerian() {
      let depotNo = this.tableState.search.depotNumber;
      if (depotNo === '')
          this.tableState.search.useDefaultDepot = true;
      else
          this.tableState.search.useDefaultDepot = false;

      if (!isNullOrUndefined(this.defaultDepot))
          depotNo = this.defaultDepot.depotNumber;

      this.isLoading = true;

      const dt = this.activeWeek.startOf('isoWeek').format('YYYY-MM-DD');
      const fileName = 'Truck Request_' + dt + '_' + depotNo.toUpperCase() + '.xlsx';

      this.saveTransportPlanIfCanSave().subscribe(success => {
          if (!success)
              return;
          this.callServerForExportToExcelForFerian(fileName);
      });
  }

  async exportToExcelForFerianAll() {
      this.isLoading = true;

      const from = this.activeWeek.startOf('isoWeek').format('DD-MM-YYYY');
      const to = this.activeWeek.endOf('week').format('DD-MM-YYYY');

      const fileName = 'Truck Request for the week from_' + from + '_to_' + to + '.xlsx';

      this.saveTransportPlanIfCanSave().subscribe(success => {
          if (!success)
              return;
          this.callServerForExportToExcelForFerianAll(fileName);
      });
  }

  callServerForExportToExcel(fileName: string) {
      this.isLoading = true;
      this.transportSheetPlanService
          .exportTransportPlanToExcel(
              fileName,
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', this.tableState)
          .subscribe(r => { this.isLoading = false; },
              error => {
                  this.isLoading = false;
                  this.toastr.error('Error trying to export to Excel. Please try again.', 'Transport Plan');
              });
  }

  callServerForExportToExcelAllTrucks(fileName: string) {
      this.transportSheetPlanService
          .exportTransportPlanToExcelAllTrucks(
              fileName,
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', this.tableState)
          .subscribe(r => { this.isLoading = false; },
              error => {
                  this.isLoading = false;
                  this.toastr.error('Error trying to export to Excel. Please try again.', 'Transport Plan');
              });
  }

  callServerForExportToExcelAll(fileName: string) {
      this.transportSheetPlanService
          .exportTransportPlanToExcelAll(
              fileName,
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', this.tableState)
          .subscribe(r => { this.isLoading = false; },
              error => {
                  this.isLoading = false;
                  this.toastr.error('Error trying to export to Excel. Please try again.', 'Transport Plan');
              });
  }

  callServerForExportToExcelForFerian(fileName: string) {
      this.transportSheetPlanService
          .exportTransportPlanForFerian(
              fileName,
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', this.tableState)
          .subscribe(r => { this.isLoading = false; },
              error => {
                  this.isLoading = false;
                  this.toastr.error('Error trying to export to Excel. Please try again.', 'Transport Plan');
              });
  }

  callServerForExportToExcelForFerianAll(fileName: string) {
      this.transportSheetPlanService
          .exportTransportPlanForFerianAll(
              fileName,
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', this.tableState)
          .subscribe(r => { this.isLoading = false; },
              error => {
                  this.isLoading = false;
                  this.toastr.error('Error trying to export to Excel. Please try again.', 'Transport Plan');
              });
  }

  setDebugDate(event: Date) {
      this.setCurrentWeekCalendar(event);
  }

  weeklyDataFilter(weeklyData: TransportPlanWeeklyDataViewModel): boolean {
      return !weeklyData.toBeDeleted;
  }

  clearFilter() {
      this.searchForm.reset({
          startDate: this.activeWeek.startOf('isoWeek').format('YYYY-MM-DD'),
          endDate: this.activeWeek.endOf('week').format('YYYY-MM-DD'),
          useDefaultDepot: true,
          depot: '',
          gradeGroup: '',
          grade: '',
          materialDescription: '',
          switchUomCode: this.selectedUOMCode,
          showAll: false,
          numAdjPattern: [{ value: '', disabled: false }, [Validators.required, Validators.pattern('^[0-9]{1,7}(\.[0-9]+)?$')]]
      });
  }

  selectAllContent(event: any) {
      if (!isNullOrUndefined(event) && !isNullOrUndefined(event.target))
          event.target.select();
  }

  copyLink() {
      try {
          const depot = this.searchForm.get('depot').value;
          if (isNullOrUndefined(depot) || depot == '') {
              this.toastr.error('Depot selection required', 'Error');
              return;
          }
          const url = new URL(location.origin + location.pathname);

          url.searchParams.append('depot', '');
          url.searchParams.append('grade', '');
          url.searchParams.append('gradegroup', '');
          url.searchParams.append('showfav', '');

          if (typeof (depot) === 'string' && depot.includes(',')) {
              //The following splits multiple depot search using the ',' then sets all the text to uppercase and removes any spacing from the front and end of the text
              //after which we take the first 6 letters which will be the depot number which is used in the search
              const splitdepotNos = depot.split(',').map(element => element.toUpperCase().trim().slice(0, 7));
              const depotNos = splitdepotNos.join(', ');
              url.searchParams.set('depot', depotNos);
          }
          else {
              if (depot.depotNumber != null && depot.depotNumber != undefined && depot.depotNumber != '') {
                  url.searchParams.set('depot', depot.depotNumber);
              }
              else if (!isNullOrUndefined(depot)) {
                  url.searchParams.set('depot', depot.toUpperCase());
              }
          }

          const grade = this.searchForm.get('grade').value;
          if (grade != null && grade != undefined && grade != '') {
              url.searchParams.set('grade', grade);
          }

          const gradeGroup = this.searchForm.get('gradeGroup').value;
          if (gradeGroup != null && gradeGroup != undefined && gradeGroup != '') {
              url.searchParams.set('gradegroup', gradeGroup);
          }

          const showFav = this.searchForm.get('showFavouriteHeapsOnly').value;
          if (showFav != null && showFav != undefined && showFav != '') {
              url.searchParams.set('showfav', showFav);
          }

          this.clipboard.copy(url.toString());

          this.toastr.success('Link successfully copied to clipboard', 'Success');
      } catch (e) {
          this.toastr.error('An error occured while copying the filters. Please try again.', 'Error');
      }
  }
}

export interface IEnumItem {
  key: number;
  value: string;
  desc: string;
}
