<div cdkDropListGroup class="component">
  <div>
    <div class="drag-container" [ngClass]="{'hideRequests': hideRequestsSection}">
      <span matTooltip="Show Requests">
        <button testName="hideRequests" (click)="toggleShowRequestsSection()" [hidden]="!hideRequestsSection"
          mat-button>
          <fa-icon [icon]="'caret-right'" class="carret-right"></fa-icon>
        </button>
      </span>
      <div class="request-section" [hidden]="hideRequestsSection">
        <div class="header">
          <h2>Requests</h2>
          <div>
            <button *ngIf="selectedRowCount > 1" mat-button (click)="clearSelectedRows()" class="clearButton">
              <i class="fa fa-times-circle"></i>
              Clear selected rows
            </button>

            <span matTooltip="Hide Requests">
              <button testName="hideRequests" (click)="toggleShowRequestsSection()" mat-button>
                <fa-icon [icon]="'caret-left'" class="caret-left"></fa-icon>
              </button>
            </span>
          </div>
        </div>
        <div class="row-box">
          <div class="group-by">
            <span [ngClass]="{'selected': groupBy === 'depot'}" matTooltip="group by Depot"
              (click)="groupRequests('depot')">
              <i class="fa fa-industry"></i>
            </span>
            <span [ngClass]="{'selected': groupBy === 'readyDate'}" matTooltip="group by Ready Date"
              (click)="groupRequests('readyDate')">
              <i class="fa fa-clock-o"></i>
            </span>
            <span [ngClass]="{'selected': groupBy === 'grade'}" matTooltip="group by Grade"
              (click)="groupRequests('grade')">
              <i class="fa fa-clipboard"></i>
            </span>
            <span [ngClass]="{'selected': selectedRowCount === 1 && !selectedItemHasChild}" matTooltip="Edit" (click)="editRequest()">
              <i class="fa fa-edit"></i>
            </span>
          </div>
          <div class="small-margin-right">
            <app-autocomplete autoCompleteDelay="750" #requestDepotInputAvail [displayWith]="displayDepot"
              [tooltip]="depotTooltip" [getResults]="allocateService.getDepots" placeholder="Depot"
              (selectionChange)="searchRequests('depotFilter', $event.depotNumber == undefined ? $event: $event.depotNumber)"
              (cleared)="clearRequestInput('depotFilter')" (searching)="loadingRequests = $event"
              testName="requestAcDepot"></app-autocomplete>
          </div>
          <div class="small-margin-right">
            <app-autocomplete autoCompleteDelay="750" #gradeTypeInputAvail [getResults]="gradeService.getGradeTypes"
              placeholder="Grade Type" (selectionChange)="searchRequests('gradeTypeFilter', $event)"
              (cleared)="clearRequestInput('gradeTypeFilter')" (searching)="loadingRequests = $event"
              testName="requestAcGradeType"></app-autocomplete>
          </div>
          <div class="small-margin-right">
            <app-autocomplete autoCompleteDelay="750" #gradeGroupInputAvail [getResults]="gradeService.getGradeGroups"
              placeholder="Grade Group" (selectionChange)="searchRequests('gradeGroupFilter', $event)"
              (cleared)="clearRequestInput('gradeGroupFilter')" (searching)="loadingRequests = $event"
              testName="requestAcGradeGroup"></app-autocomplete>
          </div>
          <div class="small-margin-right">
            <app-autocomplete autoCompleteDelay="750" #gradeInputAvail [getResults]="gradeService.getGrade"
              placeholder="Grade" (selectionChange)="searchRequests('gradeFilter', $event); showGradeButton = true"
              (cleared)="clearRequestInput('gradeFilter'); showGradeButton = false"
              (searching)="loadingRequests = $event" testName="requestAcGrade"></app-autocomplete>
          </div>
          <div class="small-margin-right">
            <mat-checkbox #reservationInput *ngIf="showInventoryReservation" (change)="searchRequestsForCheckBox('isReservation', $event)" style="margin-top: 15% ;">
              Reservation
            </mat-checkbox>
          </div>
          <div class="group-by small-margin-right">
            <span [ngClass]="{'selected': true}" matTooltip="Clear filters" (click)="clearRequestFilers()"><i
                class="fa fa-eraser"></i></span>
          </div>
          <div>
            <button mat-button
              (click)="searchContracts('gradeFilter', gradeInputAvail.value); gradeInputDest.value = gradeInputAvail.value;"
              matTooltip="use grade for contract search" *ngIf="showGradeButton">
              <mat-icon>arrow_right</mat-icon>
            </button>
          </div>
        </div>
        <app-spinner [displayProgressSpinner]="loadingRequests" [container]="gradeBox" #requestSpinner></app-spinner>
        <div class="grade-box" #gradeBox>


          <table *ngFor="let r of requests; let j = index" class="table availability-table" matSort matSortDisableClear
            (matSortChange)="sortRequestData($event, j)">
            <thead>
              <th mat-sort-header="grade" *ngIf="groupBy === 'depot'">{{r.key}}</th>
              <th mat-sort-header="depot" *ngIf="groupBy !== 'depot'">{{r.key}}</th>
              <th mat-sort-header="description">Description</th>
              <th mat-sort-header="status">Lifecycle<br>Status</th>
              <th mat-sort-header="requestDate">Request Date</th>
              <th mat-sort-header="readyDate" *ngIf="groupBy !== 'readyDate'">Ready Date</th>
              <th mat-sort-header="grade" *ngIf="groupBy === 'readyDate'">Grade</th>
              <th mat-sort-header="remainingWeight">Remaining<br>Weight</th>
              <th mat-sort-header="remainingLoads">Remaining<br>Loads</th>
              <th></th>
              <th mat-sort-header="containerPackaging">Container/<br />
                Packaging</th>
              <th></th>
            </thead>
            <tbody>
              <tr *ngFor="let item of r.requests; let i = index" testName="requestRow"
                [ngClass]="{'selected': selectedRows[j].indexOf(i) > -1}"
                (click)="toggleRow(j, i, item);requestSelection(item);">
                <td *ngIf="groupBy === 'depot'">{{item.grade}}
                  <app-allocate-grouped-request-detail [detail]="item.groupDetail">
                  </app-allocate-grouped-request-detail>
                </td>
                <td *ngIf="groupBy !== 'depot'">{{item.depotNo}}</td>
                <td matTooltip="{{item.description}}">{{item.description | shorten : '40'}}</td>
                <td>{{item.status}}                            
                    <span class="material-symbols-outlined" *ngIf="item.isReservation">
                    all_match
                </span>
            </td>
                <td>{{item.requestDate | momentUtcAsLocaleDate}}</td>
                <td *ngIf="groupBy !== 'readyDate'">{{item.readyDate | momentUtcAsLocaleDate}}</td>
                <td *ngIf="groupBy === 'readyDate'">{{item.grade}}</td>
                <td>{{(item.remainingWeight !== null) ? (item.remainingWeight | number : '1.0-2') : ""}}
                  {{(item.remainingWeightUomCode !== null) ? item.remainingWeightUomCode : ""}}</td>
                <td>{{item.remainingLoads}}</td>
                <td>
                  <div class="icon-placeholder comments-wrapper">
                    <span (click)="showRequestComments(item)" *ngIf="item.comments && item.comments.length > 0"><i
                        class="fa fa-lg fa-commenting"></i></span>
                    <span (click)="showRequestComments(item)" *ngIf="!item.comments || item.comments.length === 0"><i
                        class="fa fa-lg fa-comment"></i></span>
                    <div class="comments comments-hover" *ngIf="item.comments && item.comments.length > 0">
                      <div class="comments-options" (click)="copyAllocateRequestCommentsToClipboard(item.comments)">
                        <div>
                          <i class="fa fa-lg fa-copy"></i>
                        </div>
                        <div>
                          <p class="comment-copy-header">Copy All</p>
                        </div>
                      </div>
                      <div *ngFor="let c of item.comments; let i = index"
                        [ngClass]="{'odd': (i % 2 === 1), 'even': (i % 2 === 0)}" class="comments-content">
                        <div style="float: right; margin: 2px;">
                          <i class="fa fa-lg fa-copy" style="font-size: 14px;"
                            (click)="copyAllocateRequestCommentsToClipboard([c])"></i>
                        </div>
                        <div>
                          <p class="comment-text" [innerHtml]="c.comment"></p>
                        </div>
                        <div class="comment-reference">{{c.author}} - {{c.isSystemComment ?
                          '('+systemComment+')' : ""}}
                          {{c.commentDate | momentUtcAsLocaleDateTime}}
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td>{{item.containerPackaging}}</td>
                <td *ngIf="!isInBasket(item.requestId) && item.depotNo != 'NODEPOT'">
                  <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="item" class="drag-list">
                    <div class="hand" cdkDrag>
                      <span class="paper">
                        <i class="fa fa-hand-paper-o"></i>
                      </span>
                    </div>
                  </div>
                </td>

                <td *ngIf="isInBasket(item.requestId)" class="basket-icon">
                  <fa-icon [icon]="'shopping-cart'" size="lg"></fa-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div [hidden]="!bulkUpdateMode" class="search-box">
          <form [formGroup]="bulkUpdateForm" (ngSubmit)="bulkUpdate(bulkUpdateForm.value)">
            <div class="row-container">
              <mat-form-field mat-no-float style="margin-top: 0px">
                <mat-select id="ddlLifecycleStatus" name="ddlLifecycleStatus" formControlName="lifecycleStatusId"
                  style="text-transform:none; font-weight:normal" placeholder="Lifecycle Status">
                  <mat-option [value]="">
                  </mat-option>
                  <mat-option *ngFor="let s of lifecycleStatuses" [value]="s.id">
                    {{ s.name }}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field style="padding-left:15px; margin: 0px 0px; width: 500px;" mat-no-float>
                <input matInput type="text" aria-multiline="true" placeholder="Comment" formControlName="comment">
              </mat-form-field>
              <button type="submit" mat-button>Bulk Update</button>
            </div>
          </form>
        </div>
      </div>
    </div>


    <div class="drag-container" [ngClass]="{'fullyExpanded': hideRequestsSection}">
      <h2>Contracts</h2>
      <div [hidden]="hideRequestsSection">
        <div class="row-box" style="overflow-y: auto;">
          <div>
            <button mat-button
              (click)="gradeInputAvail.value = gradeInputDest.value; searchRequests('gradeFilter', gradeInputDest.value)"
              matTooltip="use grade for contract search" *ngIf="showGradeButton">
              <mat-icon>arrow_left</mat-icon>
            </button>
          </div>
          <div class="small-margin-right">
            <app-autocomplete autoCompleteDelay="750" #gradeInputDest [getResults]="gradeService.getGrade"
              placeholder="Grade" (selectionChange)="searchContracts('gradeFilter', $event); showGradeButton = true"
              (cleared)="clearContractInput('gradeFilter'); showGradeButton = false"
              (searching)="loadingContracts = $event" testName="contractAcGrade"></app-autocomplete>
          </div>
          <div class="small-margin-right">
            <mat-form-field style="min-width: 200px;">
              <mat-label id="orderBookLabel2"></mat-label>
              <input type="text" matInput [matAutocomplete]="autoOrderBookType" [formControl]="orderBookTypeControl">
              <mat-hint>Type to filter</mat-hint>
            </mat-form-field>
            <mat-autocomplete #autoOrderBookType="matAutocomplete" [displayWith]="displayOrderBookType">
              <mat-option *ngFor="let obt of filteredOrderBookTypes | async" [value]="selectedOrderBookTypes">
                <div (click)="orderBookTypeOptionClicked($event, obt)">
                  <mat-checkbox [checked]="obt.selected" (change)="orderBookTypeToggleSelection(obt)"
                    (click)="$event.stopPropagation()" [matTooltip]="obt.orderBook">
                    {{ obt.orderBook }}
                  </mat-checkbox>
                </div>
              </mat-option>
            </mat-autocomplete>
          </div>
          <div class="small-margin-right">
            <app-autocomplete autoCompleteDelay="750" #partyInputDest [displayWith]="displayParty"
              [tooltip]="partyTooltip" [getResults]="allocateService.getParty" placeholder="Party"
              (selectionChange)="searchContracts('partyFilter', $event.partyAccountNo == undefined ? $event: $event.partyAccountNo)"
              (cleared)="clearContractInput('partyFilter')" (searching)="loadingContracts = $event"
              testName="contractAcParty"></app-autocomplete>
          </div>
          <div class="small-margin-right margin-center">
            <mat-checkbox #internalSalesInputDest (change)="togglePurchases($event)" style="font-size: small;">
              Include Internal Sales
            </mat-checkbox>
          </div>
        </div>

        <div class="row-box">
          <div class="small-margin-right">
            <app-autocomplete autoCompleteDelay="750" #contractRefInputDest
              [getResults]="allocateService.getContractRefs" placeholder="Contract Ref."
              (selectionChange)="searchContracts('contractRef', $event)"
              (focusout)="setValueForSearchContracts('contractRef', $event.target.value)"
              (cleared)="clearContractInput('contractRef')" (searching)="loadingContracts = $event"
              testName="contractAcContractRef"></app-autocomplete>
          </div>
          <div class="small-margin-right">
            <app-autocomplete autoCompleteDelay="750" #contractDepotInputAvail [displayWith]="displayDepot"
              [tooltip]="depotTooltip" [getResults]="allocateService.getDepots" placeholder="Depot"
              (selectionChange)="searchContracts('depotNo', $event.depotNumber == undefined ? $event: $event.depotNumber)"
              (focusout)="setValueForSearchContracts('depotNo', $event.target.value)"
              (cleared)="clearContractInput('depotNo')" (searching)="loadingRequests = $event"
              testName="contractAcDepot">
            </app-autocomplete>
          </div>
          <div class="small-margin-right">
            <mat-form-field class="row-box-datewidth">
              <input matInput [matDatepicker]="fromPicker1" #fromDateInputDest placeholder="from date"
                (dateChange)="searchContracts('fromDate', $event.value)">
              <mat-datepicker-toggle matSuffix [for]="fromPicker1"></mat-datepicker-toggle>
              <mat-datepicker #fromPicker1></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="small-margin-right">
            <mat-form-field class="row-box-datewidth">
              <input matInput [matDatepicker]="toPicker1" #toDateInputDest placeholder="to date"
                (dateChange)="searchContracts('toDate', $event.value)">
              <mat-datepicker-toggle matSuffix [for]="toPicker1"></mat-datepicker-toggle>
              <mat-datepicker #toPicker1></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="group-by">
            <span [ngClass]="{'selected': true}" matTooltip="Apply Filter"><button testName="applyFilter"
                [disabled]="loadingContracts" (click)="showContracts()" mat-button>Apply Filter</button></span>
            <span (click)="toggleAdditional()">
              <mat-icon *ngIf="advancedFilterCount > 0" class="fa fa-filter" matBadge="{{advancedFilterCount}}">
              </mat-icon>
              <mat-icon *ngIf="advancedFilterCount == 0" class="fa fa-filter"></mat-icon>
            </span>
            <span [ngClass]="{'selected': true}" matTooltip="Clear filters" (click)="clearContractFilers()"><i
                class="fa fa-eraser"></i></span>
            <span [ngClass]="{'selected': true}" matTooltip="Sort by contracts date"
              (click)="searchContracts('contractsSortByDate','true')"><i class="fa fa-sort"></i></span>
          </div>
        </div>

        <div class="row-box fill" [hidden]="showAdditionalFilter">
          <div class="spacing-right">
            <mat-form-field mat-no-float style="width: 250px">
              <input matInput type="text" #commentInputDest placeholder="Comments"
                (input)="onCommentChange($event.target.value)" />
              <button mat-button *ngIf="showCommentCloseButton" matSuffix mat-icon-button aria-label="Clear"
                (click)="clearComment()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>

          <div class="spacing-right" style="max-width: 300px; width: 300px;">
            <app-autocomplete autoCompleteDelay="750" #salesGradeInputDest
              [getResults]="salesGradeService.getSalesGrade" placeholder="Sales Grade"
              (selectionChange)="searchContracts('salesGradeFilter', $event); showSalesGradeButton = true"
              (cleared)="clearContractInput('salesGradeFilter'); showSalesGradeButton = false"
              (searching)="loadingContracts = $event">
            </app-autocomplete>
          </div>

          <div>
            <mat-form-field>
              <mat-label>Ok To Load</mat-label>
              <mat-select [(ngModel)]="okToLoad">
                <mat-option [value]=""></mat-option>
                <mat-option [value]="true">Yes</mat-option>
                <mat-option [value]="false">No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row-box fill" [hidden]="showAdditionalFilter">
          <div class="spacing-right">
            <mat-form-field>
              <mat-label>Classification</mat-label>
              <mat-select [(ngModel)]="classification">
                <mat-option [value]=""></mat-option>
                <mat-option [value]="'domestic'">Domestic</mat-option>
                <mat-option [value]="'export'">Export</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="spacing-right" >
            <mat-form-field mat-no-float style="width: 250px">
              <input matInput type="text" #deliveryPointInputDest placeholder="Delivery Point"
                (input)="onDeliveryPointChange($event.target.value)" />
              <button mat-button *ngIf="showDeliveryPointCloseButton" matSuffix mat-icon-button aria-label="Clear"
                (click)="clearDeliveryPoint()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field>
              <mat-select [(ngModel)]="contractsPageSize" placeholder="No Of Results">
                <mat-option *ngFor="let noOfResult of noOfContractResults" [value]="noOfResult.value">
                  {{ noOfResult.desc }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div [hidden]="!hideRequestsSection">
        <div class="row-box" style="overflow-y: auto;">
          <div>
            <button mat-button
              (click)="gradeInputAvail.value = gradeInputDest.value; searchRequests('gradeFilter', gradeInputDest.value)"
              matTooltip="use grade for contract search" *ngIf="showGradeButton">
              <mat-icon>arrow_left</mat-icon>
            </button>
          </div>
          <div class="small-margin-right">
            <app-autocomplete autoCompleteDelay="750" #gradeInputDest [getResults]="gradeService.getGrade"
              placeholder="Grade" (selectionChange)="searchContracts('gradeFilter', $event); showGradeButton = true"
              (cleared)="clearContractInput('gradeFilter'); showGradeButton = false"
              (searching)="loadingContracts = $event" testName="contractAcGrade"></app-autocomplete>
          </div>
          <div class="small-margin-right">
            <mat-form-field style="min-width: 200px;">
              <mat-label id="orderBookLabel1"></mat-label>
              <input type="text" matInput [matAutocomplete]="autoOrderBookType" [formControl]="orderBookTypeControl">
              <mat-hint>Type to filter</mat-hint>
            </mat-form-field>
            <mat-autocomplete #autoOrderBookType="matAutocomplete" [displayWith]="displayOrderBookType">
              <mat-option *ngFor="let obt of filteredOrderBookTypes | async" [value]="selectedOrderBookTypes">
                <div (click)="orderBookTypeOptionClicked($event, obt)">
                  <mat-checkbox [checked]="obt.selected" (change)="orderBookTypeToggleSelection(obt)"
                    (click)="$event.stopPropagation()" [matTooltip]="obt.orderBook">
                    {{ obt.orderBook }}
                  </mat-checkbox>
                </div>
              </mat-option>
            </mat-autocomplete>
          </div>
          <div class="small-margin-right">
            <app-autocomplete autoCompleteDelay="750" #partyInputDest [displayWith]="displayParty"
              [tooltip]="partyTooltip" [getResults]="allocateService.getParty" placeholder="Party"
              (selectionChange)="searchContracts('partyFilter', $event.partyAccountNo == undefined ? $event: $event.partyAccountNo)"
              (cleared)="clearContractInput('partyFilter')" (searching)="loadingContracts = $event"
              testName="contractAcParty"></app-autocomplete>
          </div>
          <div class="small-margin-right margin-center">
            <mat-checkbox #internalSalesInputDest (change)="togglePurchases($event)" style="font-size: small;">
              Include Internal Sales
            </mat-checkbox>
          </div>
          <div class="small-margin-right">
            <app-autocomplete autoCompleteDelay="750" #contractRefInputDest
              [getResults]="allocateService.getContractRefs" placeholder="Contract Ref."
              (selectionChange)="searchContracts('contractRef', $event)" (cleared)="clearContractInput('contractRef')"
              (searching)="loadingContracts = $event" testName="contractAcContractRef"></app-autocomplete>
          </div>
          <div class="small-margin-right">
            <app-autocomplete autoCompleteDelay="750" #contractDepotInputAvail [displayWith]="displayDepot"
              [tooltip]="depotTooltip" [getResults]="allocateService.getDepots" placeholder="Depot"
              (selectionChange)="searchContracts('depotNo', $event.depotNumber == undefined ? $event: $event.depotNumber)"
              (cleared)="clearContractInput('depotNo')" (searching)="loadingRequests = $event"
              testName="contractAcDepot">
            </app-autocomplete>
          </div>
          <div class="small-margin-right">
            <mat-form-field class="row-box-datewidth">
              <input matInput [matDatepicker]="fromPicker2" #fromDateInputDest placeholder="from date"
                (dateChange)="searchContracts('fromDate', $event.value)">
              <mat-datepicker-toggle matSuffix [for]="fromPicker2"></mat-datepicker-toggle>
              <mat-datepicker #fromPicker2></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="small-margin-right">
            <mat-form-field class="row-box-datewidth">
              <input matInput [matDatepicker]="toPicker2" #toDateInputDest placeholder="to date"
                (dateChange)="searchContracts('toDate', $event.value)">
              <mat-datepicker-toggle matSuffix [for]="toPicker2"></mat-datepicker-toggle>
              <mat-datepicker #toPicker2></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="group-by">
            <span [ngClass]="{'selected': true}" matTooltip="Apply Filter"><button testName="applyFilter"
                [disabled]="loadingContracts" (click)="showContracts()" mat-button>Apply Filter</button></span>
            <span (click)="toggleAdditional()">
              <mat-icon *ngIf="advancedFilterCount > 0" class="fa fa-filter" matBadge="{{advancedFilterCount}}">
              </mat-icon>
              <mat-icon *ngIf="advancedFilterCount == 0" class="fa fa-filter"></mat-icon>
            </span>
            <span [ngClass]="{'selected': true}" matTooltip="Clear filters" (click)="clearContractFilers()"><i
                class="fa fa-eraser"></i></span>
            <span [ngClass]="{'selected': true}" matTooltip="Sort by contracts date"
              (click)="searchContracts('contractsSortByDate','true')"><i class="fa fa-sort"></i></span>
          </div>
        </div>
        <div>
          <div class="row-box fill" [hidden]="showAdditionalFilter">
            <div class="spacing-right">
              <mat-form-field mat-no-float style="width: 250px">
                <input matInput type="text" #commentInputDest placeholder="Comments"
                  (input)="onCommentChange($event.target.value)" />
                <button mat-button *ngIf="showCommentCloseButton" matSuffix mat-icon-button aria-label="Clear"
                  (click)="clearComment()">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>

            <div class="spacing-right" style="max-width: 300px; width: 300px;">
              <app-autocomplete autoCompleteDelay="750" #salesGradeInputDest
                [getResults]="salesGradeService.getSalesGrade" placeholder="Sales Grade"
                (selectionChange)="searchContracts('salesGradeFilter', $event); showSalesGradeButton = true"
                (cleared)="clearContractInput('salesGradeFilter'); showSalesGradeButton = false"
                (searching)="loadingContracts = $event">
              </app-autocomplete>
            </div>

            <div class="spacing-right">
              <mat-form-field>
                <mat-label>Ok To Load</mat-label>
                <mat-select [(ngModel)]="okToLoad">
                  <mat-option [value]=""></mat-option>
                  <mat-option [value]="true">Yes</mat-option>
                  <mat-option [value]="false">No</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="spacing-right">
              <mat-form-field>
                <mat-label>Classification</mat-label>
                <mat-select [(ngModel)]="classification">
                  <mat-option [value]=""></mat-option>
                  <mat-option [value]="'domestic'">Domestic</mat-option>
                  <mat-option [value]="'export'">Export</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div>
              <mat-form-field mat-no-float style="width: 250px">
                <input matInput type="text" #deliveryPointInputDest placeholder="Delivery Point"
                  (input)="onDeliveryPointChange($event.target.value)" />
                <button mat-button *ngIf="showDeliveryPointCloseButton" matSuffix mat-icon-button aria-label="Clear"
                  (click)="clearDeliveryPoint()">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <app-spinner [displayProgressSpinner]="loadingContracts" [container]="contractBox" #contractSpinner>
      </app-spinner>
      <div class="contract-box" #contractBox>
        <div *ngIf="noContractsFound" class="search-warning">
          <mat-icon>warning</mat-icon> There were no contracts found for that search!
        </div>
        <div *ngFor="let group of contractsGroup">
          <h3 *ngIf="group.pagedParties && group.pagedParties.parties && group.pagedParties.parties.length > 0">{{group.contractType}}</h3>
          <div *ngFor="let party of group.pagedParties.parties">
            <div class="customerSection">
              <div class="party-info">
                <div><span>{{party.partyAccountNo}} - {{party.partyName}}</span></div>
                <div (click)="toggleVisibility(header)">
                  <i [ngClass]="{'fa-chevron-up': !header.hidden, 'fa-chevron-down': header.hidden}"
                    class="fa collapse"></i>
                </div>
              </div>
              <div #header>
                <div *ngFor="let contract of party.contracts" class="drag-group">
                  <div class="customerSection">
                    <div class="contract-info">
                      <div>
                        <div>Contract Ref: <span>{{contract.customerContractRef}}</span></div>
                        <div>Tracking: <span>{{contract.contractTrackingName}}</span></div>
                      </div>
                      <div cdkDropList (cdkDropListDropped)="dropTruck($event, contract, party)">
                        <button mat-button (click)="clickTruck(contract, party)" testName="truckBtn">
                          <fa-icon [icon]="'truck'" size="2x"></fa-icon>
                        </button>
                      </div>
                      <div class="comments-wrapper" *ngIf="contract.comments">
                        <span><i class="fa fa-lg fa-commenting"></i></span>
                        <div class="comments comments-hover">
                          <div class="comments-options" (click)="copyTextToClipboard(contract.comments, true)">
                            <div>
                              <i class="fa fa-lg fa-copy"></i>
                            </div>
                            <div>
                              <p class="comment-copy-header">Copy All</p>
                            </div>
                          </div>
                          <div class="comments-content">
                            {{contract.comments}}
                          </div>
                        </div>
                      </div>
                      <div cdkDropList (cdkDropListDropped)="dropBasket($event, contract, party)">
                        <button mat-button [disabled]="showBasket || !isMixedGradeContract(contract)"
                          (click)="clickBasket($event, contract, party)">
                          <fa-icon [icon]="'shopping-cart'" size="2x"></fa-icon>
                        </button>
                      </div>
                      <div>
                        <div>Total balance: <span>{{contract.totalBalance == null ? 0 : contract.totalBalance | number
                            :
                            '1.0-1'}} {{contract.weightUomCode}}</span></div>
                        <div>Open Allocations: <span>{{contract.openAllocations == null ? 0 : contract.openAllocations
                            |
                            number : '1.0-1'}} {{contract.weightUomCode}}</span></div>
                      </div>
                      <div>
                        <div>Total balance loads: <span>{{contract.totalBalanceLoads == null ? 0 :
                            contract.totalBalanceLoads | number : '1.0-1'}}</span></div>
                        <div>Total target loads: <span>{{contract.totalTargetLoads == null ? 0 :
                            contract.totalTargetLoads
                            |
                            number : '1.0-1'}}</span></div>
                      </div>
                      <div (click)="toggleVisibility(contractLines)">
                        <i [ngClass]="{'fa-chevron-up': !contractLines.hidden, 'fa-chevron-down': contractLines.hidden}"
                          class="fa fa-chevron-up collapse"></i>
                      </div>
                    </div>
                    <div class="contract-info" *ngIf="contract.deliveryNotes">
                      <div>Delivery Notes: <span>{{contract.deliveryNotes}}</span></div>
                      <div class="show-allocation" (click)="showAllocationLoads(contract)">
                        <span
                          [ngClass]="{'fa-chevron-up': contract.showAllocations, 'fa-chevron-down': !contract.showAllocations}"
                          class="fa"></span>
                      </div>
                    </div>
                    <div class="allocation-container" #allocationContainer [class.opened]="contract.showAllocations">
                      <app-spinner [displayProgressSpinner]="loadingContractAllocation" [container]="contractBox"
                        #requestSpinner></app-spinner>
                      <table class="table alt-table">
                        <thead class="thead-light">
                          <tr>
                            <th colspan="5">Allocation Load</th>
                          </tr>
                          <th>Depot</th>
                          <th>Pick Up Date</th>
                          <th>Grade</th>
                          <th>Haulier</th>
                          <th>Loads</th>
                        </thead>
                        <tbody>
                          <tr *ngIf="contract.allocationLoads?.length <= 0">
                            <td colspan="5"> No record found. </td>
                          </tr>
                          <tr *ngFor="let load of contract.allocationLoads">
                            <td>{{load.depotNo}}</td>
                            <td>{{load.requestedMovementDate | momentUtcAsLocaleDate }}</td>
                            <td>{{load.grade}}</td>
                            <td>{{load.haulierAccountNo}}</td>
                            <td>{{load.load}}</td>
                          </tr>
                        </tbody>
                      </table>
                      <br />
                    </div>
                    <table class="table alt-table" #contractLines>
                      <thead class="thead-light">
                        <tr *ngIf="contract.showAllocations">
                          <th colspan="10">Load balance</th>
                        </tr>
                        <th>Grade</th>
                        <th>Start<br>Date</th>
                        <th>Delivery<br>Period</th>
                        <th>Balance</th>
                        <th>Target</th>
                        <th>Loads</th>
                        <th>Allocated</th>
                        <th>Delivery<br>Method</th>
                        <th>Delivery<br>Point</th>
                        <th>Depot</th>
                      </thead>
                      <tbody>
                        <tr *ngFor="let line of contract.contractLines">
                          <td>{{line.grade == null ? line.salesGrade : line.grade}} - {{line.salesGradeDesc}}</td>
                          <td>{{line.startDate | momentUtcAsLocaleDate}}</td>
                          <td>{{abbreviateDate(line.deliveryPeriod)}}</td>
                          <td>{{line.balanceWeight | number : '1.0-1'}}</td>
                          <td>{{line.targetWeight | number : '1.0-1'}}</td>
                          <td *ngIf="!line.allocatedViaReference">{{line.allocatedLoads == null ? 0 :
                            line.allocatedLoads
                            |
                            number : '1.0-1'}} / {{line.targetLoads == null ? 0 : line.targetLoads | number :
                            '1.0-1'}}
                          </td>
                          <td *ngIf="line.allocatedViaReference?.length > 0">via</td>
                          <td>{{line.allocatedWeight == null ? 0 : line.allocatedWeight | number: '1.0-1'}}</td>
                          <td>
                            <div class="delivery-info">
                              <div class="comments-wrapper" *ngIf="line.comments">
                                <span><i class="fa fa-lg fa-commenting"></i></span>
                                <div class="comments comments-hover">
                                  <div class="comments-content">
                                    {{line.comments}}
                                    <div style="float: right; margin-right: 1px">
                                      <i class="fa fa-lg fa-copy"
                                        (click)="copyTextToClipboard(line.comments, true)"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="delivery-method">{{line.deliveryMethod[0]}}</div>
                            </div>
                          </td>
                          <td>{{line.deliveryPoint}}</td>
                          <td
                            matTooltip="{{line.contractLineDepotPremiumDepotNos && line.contractLineDepotPremiumDepotNos.length > 1 ? line.contractLineDepotPremiumDepotNos.join('&#13;') : ''}}"
                            [matTooltipClass]="multiline-tooltip">{{line.contractLineDepotPremiumDepotNos &&
                            line.contractLineDepotPremiumDepotNos.length > 1 ? 'MULTI' :
                            line.contractLineDepotPremiumDepotNos ? line.contractLineDepotPremiumDepotNos[0] : 'ANY'}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <table style="width: 100%;" *ngIf="contractsCollectionSize > 0">
        <tfoot>
            <tr>
                <td style="text-align: center;">
                  <ngb-pagination [collectionSize]="contractsCollectionSize" [(page)]="contractsPage" [pageSize]="contractsPageSize"
                      [maxSize]="5" [rotate]="true" [boundaryLinks]="contractsCollectionSize >= 100"
                      (pageChange)="contractsPageChanged($event)" aria-label="Default pagination"></ngb-pagination>
                </td>
            </tr>
        </tfoot>
      </table>

    </div>

    <div *ngIf="showBasket" class="drag-container basket" [ngStyle]="getBasketStyle()">

      <div cdkDropList (cdkDropListDropped)="dropBasket($event)">
        <fa-icon [icon]="'shopping-cart'" size="lg"></fa-icon>
        <table class="table basket-table">
          <thead>
            <th>Depot</th>
            <th>Grade</th>
            <th>Request Date</th>
            <th></th>
          </thead>
          <tbody>
            <tr *ngFor="let r of selectedRequests; let i = index">
              <td>{{r.depotNo}}</td>
              <td>{{r.grade}}</td>
              <td>{{r.requestDate | momentUtcAsLocaleDate}}</td>
              <th>
                <fa-icon [icon]="'trash'" (click)="selectedRequests.splice(i,1)"></fa-icon>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <button mat-button (click)="cancelBasket()">Cancel</button>
      <button mat-button (click)="submitBasket()" [disabled]="selectedRequests.length == 0">Submit</button>
    </div>
  </div>
</div>