<mat-sidenav-container>
    <mat-sidenav mode="over" #sideNav position="end" [ngSwitch]="sideNavType">
        <app-conversation-view *ngSwitchCase="'comments'"></app-conversation-view>
        <app-load-times-view *ngSwitchCase="'loadTimes'"></app-load-times-view>
        <app-load-schedule-view *ngSwitchCase="'loadSchedule'"></app-load-schedule-view>
        <app-attachments-view (close)="onClose(sideNav)" *ngSwitchCase="'attachments'"></app-attachments-view>
    </mat-sidenav>
    <mat-sidenav-content>
        <div *ngIf="isBannerDisplay">
            <mat-toolbar class="md-whiteframe-glow-z1 site-content-toolbar" style="height: 55px;" *ngIf="!printing">
                <div class="md-toolbar-tools docs-toolbar-tools" tabIndex="-1">
                    <a [routerLink]="['/requests']" class="main-link">
                        <img src="/assets/Logo.svg" style="height: 30px" />
                        &nbsp;
                        Yard Management
                    </a>
                    <a [routerLink]="['/requests']" [routerLinkActive]="['active-route-link']" class="route-link">Requests and Reservations</a>
                    <a *ngIf="isCentralAdmin" [routerLink]="['/allocate']" [routerLinkActive]="['active-route-link']" class="route-link">Allocate</a>
                    <a [routerLink]="['/allocations']" [routerLinkActive]="['active-route-link']" class="route-link">Allocations</a>
                    <a [routerLink]="['/transportplan']" [routerLinkActive]="['active-route-link']" class="route-link" *ngIf="showTransportPlan">Transport Plan</a>
                    <a [routerLink]="['/bookedtransport']" [routerLinkActive]="['active-route-link']" class="route-link" *ngIf="showBookedTransport">Live Loads</a>
                    <a *ngIf="isCentralAdmin" [routerLink]="['/appointment']" [routerLinkActive]="['active-route-link']" class="route-link">Appointment</a>
                    <a *ngIf="showInstructionToMove" [routerLink]="['/instructiontomove']" [routerLinkActive]="['active-route-link']" class="route-link">Instruction to Move</a>
                    <a [routerLink]="['/routetomarket']" [routerLinkActive]="['active-route-link']" class="route-link">Route to Market<span *ngIf="isRouteToMarketAdmin"> Admin</span></a>
                    <span style="flex: 1 1 auto"></span>
                    <!-- TODO:  add a switch to check if on ferrous movements screen to show switch-->
                    <a [routerLink]="['/userpreferences']" class="route-link">{{username}}</a>
                    <button mat-button (click)="signOut()" mat-button matTooltip="sign out">
                        <mat-icon class="fa fa-sign-out" alt="sign out"></mat-icon>
                    </button>
                </div>
            </mat-toolbar>
        </div>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
<router-outlet name="print"></router-outlet>
