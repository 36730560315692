import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { isNullOrUndefined } from './tools';

@Pipe({
	name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {

	transform(value: any, args?: any): any {
		if (isNullOrUndefined(value)) { return ''; }
		const mnt = moment.utc('01/01/2020 ' + value);
		return mnt.format('LT');
	}

}
