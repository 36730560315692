import { Component, OnInit, Input, Output, EventEmitter, ViewChild, forwardRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { isNullOrUndefined } from '../tools';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
    selector: 'app-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AutocompleteComponent),
            multi: true
        }
    ]
})
export class AutocompleteComponent implements OnInit, ControlValueAccessor {

    private filter$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  @Input() autoCompleteDelay: number;
  @Input() displayWith: (obj: any) => string = this.defaultDisplayWith;
  @Input() getResults: (filter: string) => Observable<any>;
  @Input() placeholder: string;
  @Input() defaultValue: string;
  @Input() tooltip: (obj: any) => string = this.defaultTooltip;
  @Input() formOutputProperty: string;
  @Output() selectionChange = new EventEmitter<any>();
  @Output() cleared = new EventEmitter<void>();
  @ViewChild('input', { static: true }) input: any;
  @Input() errorStateMatcher: ErrorStateMatcher = new ErrorStateMatcher();
  loading = false;
  filterVal: string;
  results: any[] = [];
  spinDia = 100;
  spinColor = 'primary';
  autoComplete = new FormControl();
  chosen = false;

  constructor() { }

  get value() {
      return this.input.nativeElement.value;
  }
  set value(val: string) {
      this.input.nativeElement.value = val;
  }

  propagateChange = (_: any) => { };

  ngOnInit() {
      if (this.defaultValue) {
          this.filter(this.defaultValue);
          this.value = this.defaultValue;
          this.autoComplete.setValue(this.defaultValue);
      }

      this.filter$.pipe(debounceTime(this.autoCompleteDelay)).subscribe(f => {
          if (isNullOrUndefined(f) || f === '') { return; }
          this.loading = true;
          if (this.chosen) {
              this.loading = false;
          } else {
              this.getResults(f).subscribe(r => {
                  this.results = this.chosen ? [] : r;
                  this.loading = false;
              });
          }
      });
  }

  writeValue(obj: any): void {
      if (!isNullOrUndefined(obj)) {
          this.autoComplete.patchValue(obj);
      }
  }
  registerOnChange(fn: any): void {
      this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
      // throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
      this.input.setDisabledState(isDisabled);
  }

  filter(filter: string) {
      this.results = [];
      this.filterVal = filter;
      this.filter$.next(filter);
  }

  choose(chosen: any) {
      this.chosen = true;
      this.results = [];
      if (this.selectionChange) {
          this.selectionChange.emit(chosen);
      }
      if (this.propagateChange) {
          const val = isNullOrUndefined(this.formOutputProperty) ? chosen : chosen[this.formOutputProperty];
          this.propagateChange(val);
      }
  }

  clearRequestInput(input: HTMLInputElement) {
      input.value = '';
      this.results = [];
      if (this.cleared) {
          this.cleared.emit();
      }
      if (this.propagateChange) {
          this.propagateChange(null);
      }
  }

  defaultDisplayWith(obj: any) {
      return obj;
  }
  defaultTooltip(obj: any) {
      return obj;
  }

  inputValue(event: any) {
      this.chosen = false;
      if (event.target.value === '') {
          this.clearRequestInput(this.input.nativeElement);
      } else {
          this.value = event.target.value.toUpperCase();
          this.value = this.value.toUpperCase();
          this.filter(event.target.value);
      }
  }

  enter(value: string) {
      this.choose(value);
  }
}
