import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChangeHistoryService } from '../gen/services/change-history.service';
import { ChangeHistoryVm } from '../gen/models';
import { isNullOrUndefined } from '../tools';

@Component({
	selector: 'app-change-history-dialog',
	templateUrl: './change-history-dialog.component.html',
	styleUrls: ['./change-history-dialog.component.scss']
})
export class ChangeHistoryDialogComponent implements OnInit {
	histories: ChangeHistoryVm[] = [];

	constructor(
    public dialogRef: MatDialogRef<ChangeHistoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {id: number, table: string},
    private changeHistoryService: ChangeHistoryService) { }

	ngOnInit() {
		this.changeHistoryService.get(this.data.table, this.data.id).subscribe(r => this.histories = !isNullOrUndefined(r) ? r.sort((a, b) => this.compare(new Date(a.modifiedDate), new Date(b.modifiedDate), false)) : []);
	}

	private compare(a: Date, b: Date, isAsc: boolean) {
		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}

}
