<mat-card layout="column" style="padding: 1px;padding-top:0px;">
    <div id="content">
         <mat-toolbar class="md-hue-3" style="background-color:#eceff1;height: 37px;">
            <div class="md-toolbar-tools" style="color:rgb(46, 73, 97);">
                <button mat-button (click)="showSearchOptions()">
                    <i class="fa fa-search"></i>
                </button>
                <span style=" width: fit-content; overflow-y: auto;">
                <button mat-button class="md-raised" (click)="newRequest();" [disabled]="isLoading">New Instruction to move</button>

                <button *ngIf="selectedIsRequest" mat-button class="md-raised"
                    [disabled]="!canEditSelectedRequest" (click)="editRequest()">Edit Instruction to move</button>
                <button *ngIf="selectedIsRequest" mat-button class="md-raised mat-primary"
                    [disabled]="!canEditSelectedRequest" (click)="deleteRequest()"> Delete Instruction to move </button>
                </span>
                <span flex="" style="-ms-flex: 1; -webkit-flex: 1; flex: 1;"></span>
            </div>
        </mat-toolbar> 

         <div class="search-box">
            <form [hidden]="!showSearch" [formGroup]="searchForm" (ngSubmit)="search(searchForm.value)">
                <div class="row-box">
                    <div class="small-margin-right">
                        <mat-form-field style="margin: 0px 0px; max-width: 250px;">
                            <input type="text" matInput formControlName="depot" [matAutocomplete]="depotAuto"
                                placeholder="Depot" #depotInput oninput="this.value = this.value.toUpperCase()">
                        </mat-form-field>
                        <mat-autocomplete #depotAuto="matAutocomplete" [displayWith]="displayDepot">
                            <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
                            <mat-option *ngFor="let depot of filteredDepots?.items " [value]="depot">
                                {{depot?.depotNumber}} {{depot?.depotName}}</mat-option>
                        </mat-autocomplete>
                    </div>
                    <div class="small-margin-right">
                        <mat-form-field style="margin: 0px 0px; max-width: 250px;">
                            <input type="text" matInput formControlName="destination" [matAutocomplete]="destinationAuto"
                                placeholder="Destination" #destinationInput oninput="this.value = this.value.toUpperCase()">
                        </mat-form-field>
                        <mat-autocomplete #destinationAuto="matAutocomplete" [displayWith]="displayDestination">
                            <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
                            <mat-option *ngFor="let depot of filteredDepots?.items " [value]="depot">
                                {{depot?.depotNumber}} {{depot?.depotName}}</mat-option>
                        </mat-autocomplete>
                    </div>
                    <div class="small-margin-right">
                        <mat-form-field style="margin: 0px 0px; max-width: 120px;" mat-no-float>
                            <input matInput [matDatepicker]="picker1" placeholder="Due From Date" formControlName="dueFrom">
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="small-margin-right">
                        <mat-form-field style="margin: 0px 0px; max-width: 120px;" mat-no-float>
                            <input matInput [matDatepicker]="picker" placeholder="Due By Date" formControlName="dueBy">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="small-margin-right">
                        <mat-form-field class="dialog-input-container" flex>
                            <mat-select formControlName="transportMode" placeholder="Transport Mode">
                                <mat-option></mat-option>
                                <mat-option *ngFor="let tm of transportModes" [value]="tm">{{tm }}</mat-option>
                              </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="small-margin-right">
                        <mat-form-field style="margin: 0px 0px; max-width: 150px;" mat-no-float>
                            <mat-select formControlName="isActive" placeholder="Instruction to Move Status">
                                <mat-option></mat-option>
                                <mat-option *ngFor="let tm of onHold" [value]="tm.value">{{tm.name}}</mat-option>
                              </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="small-margin-right">
                        <mat-form-field style="margin: 0px 0px; max-width: 120px;" mat-no-float>
                            <input matInput type="search" placeholder="Priority" formControlName="priority"
                                   oninput="this.value = this.value.toUpperCase()">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row-box">
                    <div class="small-margin-right">
                          <mat-form-field style="margin: 0px 0px; max-width: 250px;">
                            <input type="text" matInput formControlName="contractHeaderId" [matAutocomplete]="contractHeaderIdAuto"
                                placeholder="Contract Ref" #contractHeaderIdInput 
                                (input)="contractRefValueChange($event.target.value)">
                        </mat-form-field>
                        <mat-autocomplete #contractHeaderIdAuto="matAutocomplete" [displayWith]="displayContractRef">
                            <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
                            <mat-option *ngFor="let depot of filteredContractRef" [value]="depot">
                                {{depot.contractRef + ' - ' + depot.partyName}}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                    <div class="small-margin-right">
                        <mat-form-field style="margin: 0px 0px; max-width: 250px;">
                            <input type="text" matInput formControlName="grade" [matAutocomplete]="gradeAuto"
                                placeholder="Grade" #gradeInput 
                                (input)="gradeValueChange($event.target.value)">
                        </mat-form-field>
                        <mat-autocomplete #gradeAuto="matAutocomplete" [displayWith]="displayGrade">
                            <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
                            <mat-option *ngFor="let grade of filteredGrade" [value]="grade">
                                {{grade}}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                    <div class="small-margin-right">
                        <mat-form-field style="margin: 0px 0px; max-width: 90px;" mat-no-float>
                            <input matInput  type="number" min=0  placeholder="Weight" formControlName="tonnesToMove">
                        </mat-form-field>
                    </div>
                    <div class="small-margin-right">
                        <mat-form-field style="margin: 0px 0px; max-width: 80px;" mat-no-float>
                            <input matInput type="search" placeholder="Loads" formControlName="loadsToMove">
                        </mat-form-field>
                    </div>
                    <div class="small-margin-right">
                        <mat-form-field style="margin: 0px 0px; max-width: 120px;" mat-no-float>
                            <input matInput [matDatepicker]="picker2" placeholder="Created Date" formControlName="createdDate">
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="button-container">
                        <button type="submit" mat-stroked-button>Apply Filter</button>
                        <button mat-stroked-button (click)="clearFilter()" type="button">Clear Filters</button>
                        <button mat-stroked-button (click)="copyLink()" type="button">Copy Link</button>
                    </div>
                </div>
            </form>
        </div> 
        
        <app-spinner [displayProgressSpinner]="isLoading" #spinner></app-spinner>
         <div class="table-responsive">
            <div style="width: 100%; overflow-y: auto;" [ngStyle]="{'height.px': tableHeight}" #mainTable>
                <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort
                    (matSortChange)="sortByInfo($event)" name="pendingtable" id="pending-table" cellpadding="0"
                    cellspacing="0" class="table table-hover table-condensed custom-table" border="0"
                    [style.width.%]="tableWidth">
                    <ng-container matColumnDef="depot">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="depot"
                            class="interactive heading-row">
                            Origin
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.depot}} </td>
                    </ng-container>
                    <ng-container matColumnDef="destination">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="destination" class="interactive heading-row">
                            Destination
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.destination}} </td>
                    </ng-container>
                    <ng-container matColumnDef="dueFrom">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="dueFrom"
                            class="interactive heading-row" sortActionDescription="Sort by DueFrom">
                            Due From Date
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.dueFrom | momentUtcAsLocaleDate}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="dueBy">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="dueBy" class="interactive heading-row"
                            sortActionDescription="Sort by DueBy">
                            Due By Date
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.dueBy | momentUtcAsLocaleDate}} </td>
                    </ng-container>
                    <ng-container matColumnDef="transportMode">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="transportMode" class="interactive heading-row"
                            sortActionDescription="Sort by TransportMode">
                            Transport Mode
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.transportMode }} </td>
                    </ng-container>
                    <ng-container matColumnDef="isActive">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="isActive"
                            class="interactive heading-row" sortActionDescription="Sort by IsActive">
                            Status
                        </th>
                        <td mat-cell *matCellDef="let element">
                           {{element.isActive ? 'Active' : 'In Active'}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="priority">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="priority"
                            class="interactive heading-row" sortActionDescription="Sort by Priority">
                            Priority
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.priority}} </td>
                    </ng-container>
                    <ng-container matColumnDef="contractHeader">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="contractHeader" class="interactive heading-row"
                            sortActionDescription="Sort by ContractHeaderId">
                            ContractNumber
                        </th>
                        <td mat-cell *matCellDef="let element">{{element.contractHeader}}
                            </td>
                    </ng-container>
                    <ng-container matColumnDef="grade">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="grade"
                            class="interactive heading-row" sortActionDescription="Sort by Grade">
                            Grade
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.grade}}
                            </td>
                    </ng-container>
                    <ng-container matColumnDef="tonnesToMove">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="tonnesToMove"
                            class="interactive heading-row" sortActionDescription="Sort by TonnesToMove">
                            Weight
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.tonnesToMove}} </td>
                    </ng-container>

                    <ng-container matColumnDef="loadsToMove">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="loadsToMove"
                            class="interactive heading-row" sortActionDescription="Sort by LoadsToMove">
                            Loads
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.loadsToMove}} </td>
                    </ng-container>
                    <ng-container matColumnDef="containerSize">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="containerSize"
                            class="interactive heading-row" sortActionDescription="Sort by ContainerSizeId">
                            Container
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.containerSize}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="packageOption">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="packageOption" class="interactive heading-row"
                            sortActionDescription="Sort by PackageOptionId">
                            Packaging
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.packageOption}} </td>
                    </ng-container>
                    <ng-container matColumnDef="uom">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="uom" class="interactive heading-row"
                            sortActionDescription="Sort by UomId">
                            Unit
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.uom}} </td>
                    </ng-container>
                    <ng-container matColumnDef="createdDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="createdDate" class="interactive heading-row"
                            sortActionDescription="Sort by CreatedDate">
                        Create Date
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.createdDate | momentUtcAsLocaleDateTime}} </td>
                    </ng-container>
                    <ng-container matColumnDef="requestId">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="requestId" class="interactive heading-row"
                            sortActionDescription="Sort by RequestId">
                        Request Id
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.requestId }} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="heading-row sticky"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                        [ngClass]="{ 'selected' : row.isSelected }" style="height: 36px;"
                        (click)="selectRequest(row, $event)"></tr>
                </table>
            </div>
             <table style="width: 100%;" *ngIf="pendingAndAllocated?.length > 0">
                <tfoot>
                    <tr>
                        <td style="text-align: center;">
                            <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                                [maxSize]="5" [rotate]="true" [boundaryLinks]="collectionSize >= 100"
                                (pageChange)="pageChanged($event)" aria-label="Default pagination"></ngb-pagination>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</mat-card>
