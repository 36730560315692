<span *ngIf="detail != undefined && detail.length> 0" 
  (mouseenter)="showHideDetail(true, $event)" 
  (mouseleave)="showHideDetail(false, $event)" >
  ...
</span>
<div *ngIf="showDetail" [ngStyle]="detailStyle">
  <table>
    <thead>
      <th>Grade</th>
      <th>Description</th>
      <th>Required Weight</th>
    </thead>
    <tbody>
      <tr *ngFor="let d of detail">
        <td>{{d.grade}}</td>
        <td>{{d.heap}}</td>
        <td>{{d.requiredWeight}} {{d.uomCode}}</td>
      </tr>
    </tbody>
  </table>
</div>