import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {REST_CONFIG_TOKEN, RestConfigService} from '../rest-config-service';
import {Observable, of} from 'rxjs';
import { Haulier,
} from '../models';
import { haulagePriceMatrixVM } from '../models/haulagePriceMatrixVM';

@Injectable()
export class HauliersService {

    constructor(
private _http: HttpClient,
@Inject(REST_CONFIG_TOKEN) private _configService: RestConfigService
    ) {
    }

    public get: (
        searchTerm?: string
        ) => Observable<Haulier[]>
        = (
            searchTerm?: string
        ): Observable<Haulier[]> => {
            if(!searchTerm) return of([] as Haulier[]);
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({'Content-Type': 'application/json'});

            const uri = `/api/hauliers/${searchTerm}`;

            return this._http
                .get<Haulier[]>(this._configService.apiBaseUrl() + uri,
                    { headers: headers, params: queryParameters  });
        };
    
    public getHaulageRates: (
        request?: haulagePriceMatrixVM
        ) => Observable<haulagePriceMatrixVM[]>
        = (
            request?: haulagePriceMatrixVM
        ): Observable<haulagePriceMatrixVM[]> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({'Content-Type': 'application/json'});

            const uri = '/api/Allocate/haulagerate';
            return this._http
                .post<haulagePriceMatrixVM[]>(this._configService.apiBaseUrl() + uri, JSON.stringify(request),
                    { headers: headers, params: queryParameters  });
        };

}

