import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { REST_CONFIG_TOKEN, RestConfigService } from '../rest-config-service';
import { Observable } from 'rxjs';

import {

    InstructionToMoveVm,
    QueryModel,

} from '../models';
import { NewInstructionToMove } from '../models/NewInstructructionToMove';
import { InstructionToMoveViewModel } from '../models/InstructionToMoveViewModel';


@Injectable()
export class InstructionToMoveService {

    constructor(
        private _http: HttpClient,
        @Inject(REST_CONFIG_TOKEN) private _configService: RestConfigService
    ) {
    }


    public createInstructionToMove: (
        model?: NewInstructionToMove
    ) => Observable<InstructionToMoveVm[]>
        = (
            model?: NewInstructionToMove
        ): Observable<InstructionToMoveVm[]> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            const uri = '/api/InstructionsToMove/new';

            return this._http
                .post<InstructionToMoveVm[]>(this._configService.apiBaseUrl() + uri, JSON.stringify(model),
                    { headers: headers, params: queryParameters });
        };


    public getInstructionToMove: (
        filter?: QueryModel
    ) => Observable<InstructionToMoveVm[]>
        = (
            filter?: QueryModel
        ): Observable<InstructionToMoveVm[]> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            const uri = '/api/InstructionsToMove/instructionToMove';

            return this._http
                .post<InstructionToMoveVm[]>(this._configService.apiBaseUrl() + uri, JSON.stringify(filter),
                    { headers: headers, params: queryParameters });
        };

		
    public updateInstructionToMove: (
			model?: NewInstructionToMove,
		) => Observable<InstructionToMoveVm[]>
        = (
            model?: NewInstructionToMove
        ): Observable<InstructionToMoveVm[]> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
	
	
            const uri = '/api/InstructionsToMove/update';
	
            return this._http
                .put<InstructionToMoveVm[]>(this._configService.apiBaseUrl() + uri, JSON.stringify(model),
                    { headers: headers, params: queryParameters });
        };
		
    public deleteInstructionToMove: (
		requestId?: number
	) => Observable<void>
        = (
            requestId?: number
        ): Observable<void> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            const uri = `/api/InstructionsToMove/instructionMove/${requestId}`;

            return this._http
                .delete<any>(this._configService.apiBaseUrl() + uri,
                    { headers: headers, params: queryParameters });
        };

    public getRequests: (
		q?: QueryModel
	) => Observable<InstructionToMoveViewModel>
        = (
            q?: QueryModel
        ): Observable<InstructionToMoveViewModel> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            const uri = '/api/InstructionsToMove/requests';

            return this._http
                .post<InstructionToMoveViewModel>(this._configService.apiBaseUrl() + uri, JSON.stringify(q),
                    { headers: headers, params: queryParameters });
        };

}

