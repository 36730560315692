import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {REST_CONFIG_TOKEN, RestConfigService} from '../rest-config-service';
import {Observable} from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import {isNullOrUndefined} from '../../tools';
import * as FileSaver from 'file-saver';
import { MissedCategory,
	AppointmentQueryModel,
	PagedAppoinmentVm,
	AppointmentVm,
	PrintAppointmentVm,
	NewAppointment,
	EditAppointment,
	ImportStatus,
} from '../models';

@Injectable()
export class AppointmentService {

	constructor(
private _http: HttpClient,
@Inject(REST_CONFIG_TOKEN) private _configService: RestConfigService
	) {
	}

	public getMissedCategory: (
                ) => Observable<MissedCategory[]>
		= (
		): Observable<MissedCategory[]> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});
			const uri = '/api/Appointment/missedcategory';

			return this._http
				.get<MissedCategory[]>(this._configService.apiBaseUrl() + uri,
					{ headers: headers, params: queryParameters  });
		};
	public getAppointments: (
        aq?: AppointmentQueryModel
        ) => Observable<PagedAppoinmentVm>
		= (
			aq?: AppointmentQueryModel
		): Observable<PagedAppoinmentVm> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});

			const uri = '/api/Appointment/getappointments';

			return this._http
				.post<PagedAppoinmentVm>(this._configService.apiBaseUrl() + uri, JSON.stringify(aq ),
					{ headers: headers, params: queryParameters  });
		};
	public getAppointmentsForParty: (
        PartyAccountNo: string,
        ContractReference?: string
        ) => Observable<AppointmentVm[]>
		= (
			PartyAccountNo: string,
			ContractReference?: string
		): Observable<AppointmentVm[]> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});

			const uri = '/api/Appointment/getappointmentsforparty';

			return this._http
				.post<AppointmentVm[]>(
					this._configService.apiBaseUrl() + uri,
					JSON.stringify({ PartyAccountNo, ContractReference}),
					{ headers: headers, params: queryParameters  });
		};
	public getAvailableAppointmentsForParty: (
        PartyAccountNo: string,
        ContractReference: string,
        CurrentAppointmentId: number
        ) => Observable<AppointmentVm[]>
		= (
			PartyAccountNo: string,
			ContractReference: string,
			CurrentAppointmentId: number
		): Observable<AppointmentVm[]> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});

			const uri = '/api/Appointment/getavailableappointmentsforparty';

			const requestContent = CurrentAppointmentId > 0 ?
				JSON.stringify({ PartyAccountNo, ContractReference, CurrentAppointmentId}) :
				JSON.stringify({ PartyAccountNo, ContractReference});

			return this._http.post<AppointmentVm[]>(
				this._configService.apiBaseUrl() + uri, requestContent, { headers: headers, params: queryParameters });
		};
	public getAppointmentsToPrint: (
        aq?: number[]
        ) => Observable<PrintAppointmentVm[]>
		= (
			aq?: number[]
		): Observable<PrintAppointmentVm[]> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});

			const uri = '/api/Appointment/getappointmentstoprint';

			return this._http
				.post<PrintAppointmentVm[]>(this._configService.apiBaseUrl() + uri, JSON.stringify(aq ),
					{ headers: headers, params: queryParameters  });
		};
	public exportAppointmentsToExcelAndSave(fileName: string, responseType: string,
		aq?: AppointmentQueryModel
	): Observable<Object> {
		const headers = new HttpHeaders({'Content-Type': 'application/json'});
		const uri = this._configService.apiBaseUrl() + '/api/Appointment/getappointments/export';
		return this._http.post(uri, JSON.stringify(aq ), {
			responseType: 'blob', headers
		} )
			.pipe(map((response) => {
				const blob = new Blob([response], {type: responseType});
				FileSaver.saveAs(blob, fileName);
				return {};
			}))
		;
	}

	public createNewAppointment: (
        model?: NewAppointment
        ) => Observable<boolean>
		= (
			model?: NewAppointment
		): Observable<boolean> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});

			const uri = '/api/Appointment/new';

			return this._http
				.post<boolean>(this._configService.apiBaseUrl() + uri, JSON.stringify(model ),
					{ headers: headers, params: queryParameters  });
		};
	public updateAppointment: (
        model?: EditAppointment
        ) => Observable<boolean>
		= (
			model?: EditAppointment
		): Observable<boolean> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});

			const uri = '/api/Appointment/update';

			return this._http
				.post<boolean>(this._configService.apiBaseUrl() + uri, JSON.stringify(model ),
					{ headers: headers, params: queryParameters  });
		};
	public deleteAppointment: (
        appointmentId?: number
        ) => Observable<boolean>
		= (
			appointmentId?: number
		): Observable<boolean> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});

			const uri = '/api/Appointment/delete';

			return this._http
				.post<boolean>(this._configService.apiBaseUrl() + uri, JSON.stringify(appointmentId ),
					{ headers: headers, params: queryParameters  });
		};
	public uploadExcel: (
        ContentType?: File
        ) => Observable<ImportStatus>
		= (
			ContentType?: File
		): Observable<ImportStatus> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders();
			const form = new FormData();

			form.append('fileKey', ContentType, ContentType.name);
			headers.append('Content-Type', ContentType.type);
			const uri = '/api/Appointment/uploadexcel';

			return this._http
				.post<ImportStatus>(this._configService.apiBaseUrl() + uri, form,
					{ headers: headers, params: queryParameters  });
		};

	public checkReferenceNumberIsUnique(appointmentReference: string, partyAccountNo: string, exceptAppointmentId: number | undefined = undefined): Observable<boolean> {
		const uri = `/api/Appointment/referenceunique?appointmentReference=${appointmentReference}&partyAccountNo=${partyAccountNo}&exceptAppointmentId=${exceptAppointmentId}`;
		return this._http.get<boolean>(this._configService.apiBaseUrl() + uri);
	}
}

