import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {REST_AUTH_TOKEN, RestAuthService} from './rest-auth-service';
import {REST_TOKEN_TOKEN, RestTokenService} from './rest-token-service';

    @Injectable()
export class JWTInterceptor implements HttpInterceptor {

	constructor(@Inject(REST_AUTH_TOKEN) private _authService: RestAuthService,
                    @Inject(REST_TOKEN_TOKEN) private _tokenService: RestTokenService) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		const token = this._tokenService.token();

		req = req.clone({
			setHeaders: { authorization: 'Bearer ' + token }
		});

		return next.handle(req);
	}

}
