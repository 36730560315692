import { Component, OnInit, Inject, ElementRef, ViewChild, isDevMode } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AllocateModel } from '../gen/models';
import { formatDate } from '@angular/common';
@Component({
	selector: 'app-view-allocated-request-dialog',
	templateUrl: './view-allocated-request-dialog.component.html',
	styleUrls: ['./view-allocated-request-dialog.component.scss']
})

export class ViewAllocatedRequestDialogComponent implements OnInit {

  @ViewChild('firstComeFirstServeCheckBox') firstComeFirstServeCheckBox: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<ViewAllocatedRequestDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { allocate: AllocateModel }) { }


  ngOnInit() {
  }

  cancel() {
  	this.dialogRef.close();
  }

  displayDate(reqDate: string) {
  	return ((reqDate) ? formatDate(new Date(reqDate), 'MMM dd, yyyy hh:mm a', 'en-US', '') : '');
  }
}