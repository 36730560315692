import { Component, OnInit, Input, ViewChild, TemplateRef, ViewContainerRef, ElementRef } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { OverlayService } from '../services/overlay.service';

@Component({
	selector: 'app-spinner',
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  @Input() color?: ThemePalette;
  @Input() diameter?: number = 100;
  @Input() mode?: ProgressSpinnerMode = 'indeterminate';
  @Input() strokeWidth?: number;
  @Input() value?: number;
  @Input() backdropEnabled = false;
  @Input() displayProgressSpinner: boolean;
  @Input() container: ElementRef;

  @ViewChild('progressSpinnerRef', { static: true })
  private progressSpinnerRef: TemplateRef<any>;
  private progressSpinnerOverlayConfig: OverlayConfig;
  overlayRef: OverlayRef;
  constructor(private vcRef: ViewContainerRef, private overlayService: OverlayService) { }
  ngOnInit() {
  	// Config for Overlay Service
  	this.progressSpinnerOverlayConfig = {
  		hasBackdrop: this.backdropEnabled
  	};
  	if (this.container) {
  		this.progressSpinnerOverlayConfig['positionStrategy'] = this.overlayService.positionCenter(this.container);
  	} else {
  		this.progressSpinnerOverlayConfig['positionStrategy'] = this.overlayService.positionGloballyCenter();
  	}
  	// Create Overlay for progress spinner
  	this.overlayRef = this.overlayService.createOverlay(this.progressSpinnerOverlayConfig);
  }
  ngDoCheck() {
  	// Based on status of displayProgressSpinner attach/detach overlay to progress spinner template
  	if (this.displayProgressSpinner && !this.overlayRef.hasAttached()) {
  		this.overlayService.attachTemplatePortal(this.overlayRef, this.progressSpinnerRef, this.vcRef);
  	} else if (!this.displayProgressSpinner && this.overlayRef.hasAttached()) {
  		this.overlayRef.detach();
  	}
  }
}
