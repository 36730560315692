import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { REST_CONFIG_TOKEN, RestConfigService } from '../rest-config-service';
import { Observable } from 'rxjs';
import { DepotViewModel } from '../models/DepotViewModel';
import { LiveLoadsQueryModel } from '../models/LiveLoadsQueryModel';
import { LiveLoadsModel } from '../models/LiveLoadsModel';
import { YardViewModel } from '../models/YardViewModel';

@Injectable()
export class LiveLoadsService {

	constructor(
        private _http: HttpClient,
        @Inject(REST_CONFIG_TOKEN) private _configService: RestConfigService
	) {
	}

	public getYards: () => Observable<YardViewModel[]>
		= (): Observable<YardViewModel[]> => {
			const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

			const uri = '/api/LiveLoads/yards';

			return this._http
				.get<YardViewModel[]>(this._configService.apiBaseUrl() + uri,
					{ headers: headers });
		};

	public getLiveLoadsData: (
        bt?: LiveLoadsQueryModel
    ) => Observable<LiveLoadsModel[]>
		= (
			bt?: LiveLoadsQueryModel
		): Observable<LiveLoadsModel[]> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

			const uri = '/api/LiveLoads/getliveloadsdata';

			return this._http
				.post<LiveLoadsModel[]>(this._configService.apiBaseUrl() + uri, JSON.stringify(bt),
					{ headers: headers, params: queryParameters });
		};

	public saveLiveLoadsInstruction: (
        bt?: LiveLoadsModel
    ) => Observable<boolean>
		= (
			bt?: LiveLoadsModel
		): Observable<boolean> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

			const uri = '/api/LiveLoads/saveliveloadsinstruction';

			return this._http
				.post<boolean>(this._configService.apiBaseUrl() + uri, JSON.stringify(bt),
					{ headers: headers, params: queryParameters });
		};
}

