import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-autocomplete-disclaimer',
	templateUrl: './autocomplete-disclaimer.component.html',
	styleUrls: ['./autocomplete-disclaimer.component.scss']
})
export class AutocompleteDisclaimerComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
