<mat-card layout="column" style="padding: 1px;padding-top:0px;">
    <div id="content">
        <mat-toolbar class="md-hue-3" style="background-color:#eceff1;height: 37px;">
            <div class="md-toolbar-tools" style="color:rgb(46, 73, 97);">
                <button mat-button (click)="refresh()">
                    <i class="fa fa-refresh"></i>
                </button>
                <button mat-button (click)="searchClicked()">
                    <i class="fa fa-search"></i>
                </button>
                <mat-label class="heading">{{heading}}</mat-label>

                <span flex="" style="-ms-flex: 1; -webkit-flex: 1; flex: 1;"></span>

                <button [disabled]="isLoading || !defaultDepot" mat-button
                    class="md-raised mat-button mat-ink-ripple hide-xs" aria-label="Export to Excel"
                    (click)="exportToExcel()">Export to Excel (Trucks)</button>

                <button *ngIf="showExportAll" [disabled]="isLoading || !defaultDepot" mat-button
                    class="md-raised mat-button mat-ink-ripple hide-xs" aria-label="Export to Excel(Trucks - All)"
                    (click)="exportToExcelAllTrucks()">Export to Excel (Trucks - All)</button>

                <button *ngIf="showExportAll" [disabled]="isLoading || !defaultDepot" mat-button
                    class="md-raised mat-button mat-ink-ripple hide-xs" aria-label="Export to Excel(All)"
                    (click)="exportToExcelAll()">Export to Excel (All)</button>

                <button *ngIf="showExportAll" [disabled]="isLoading || !defaultDepot" mat-button
                    class="md-raised mat-button mat-ink-ripple hide-xs" aria-label="Export to Excel (Ferian)"
                    (click)="exportToExcelForFerian()">Export to Excel (Ferian)</button>

                <button *ngIf="showExportAll" [disabled]="isLoading || !defaultDepot" mat-button
                    class="md-raised mat-button mat-ink-ripple hide-xs" aria-label="Export to Excel(Ferian - All)"
                    (click)="exportToExcelForFerianAll()">Export to Excel (Ferian - All)</button>
            </div>
        </mat-toolbar>
        <div [hidden]="!showSearch" class="search-box">
            <form [formGroup]="searchForm" (ngSubmit)="search(searchForm.value)">
                <div class="row-container">
                    <div>
                        <mat-form-field style="width: 250px;">
                            <input type="text" matInput formControlName="depot" [matAutocomplete]="depotAuto"
                                placeholder="Depot" #depotInput oninput="this.value = this.value.toUpperCase()"
                                matTooltip="Separate Depot Nos with commas to search for multiple Depots"
                                matTooltipPosition="above">
                        </mat-form-field>

                        <mat-autocomplete #depotAuto="matAutocomplete" [displayWith]="displayDepot">
                            <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
                            <mat-option *ngFor="let depot of filteredDepots?.items " [value]="depot">
                                {{depot?.depotNumber + ' - ' + depot?.depotName}}</mat-option>
                        </mat-autocomplete>
                    </div>
                    <div style="padding-left: 10px;">
                        <app-autocomplete autoCompleteDelay="750" formControlName="gradeGroup" #gradeGroupInput
                            [getResults]="gradeService.getGradeGroups" placeholder="Grade Group"></app-autocomplete>
                    </div>
                    <div style="padding-left: 10px">
                        <app-autocomplete autoCompleteDelay="750" formControlName="grade" #gradeInput
                            [getResults]="gradeService.getGrade" placeholder="Grade"></app-autocomplete>
                    </div>
                    <mat-form-field style="margin: 0px 0px;" mat-no-float>
                        <input matInput type="search" placeholder="Material Description"
                            formControlName="materialDescription">
                    </mat-form-field>

                    <mat-checkbox formControlName="showFavouriteHeapsOnly" matTooltip="Favourite Heaps only">Show
                        Favourite Heaps
                    </mat-checkbox>

                    <input type="hidden" formControlName="startDate">

                    <input type="hidden" formControlName="endDate">

                    <button style="width: 100px;margin: 5px;" mat-stroked-button type="submit">Apply Filter</button>
                    <button style="width: 100px;margin: 5px;" mat-stroked-button (click)="clearFilter()"
                        type="button">Clear Filters</button>
                    <button style="width: 100px;margin: 5px;" mat-stroked-button (click)="copyLink()" type="button">Copy
                        Link</button>
                </div>
            </form>
        </div>

        <app-spinner [displayProgressSpinner]="isLoading" #spinner></app-spinner>

        <div [ngStyle]="{'height.px': tableHeight}">
            <table name="bookingtable" id="booking-table" class="table table-hover table-condensed custom-table">
                <thead class="sticky" style="background-color: white;">
                    <tr>
                        <th colspan="8" style="text-align: right;">
                            <button [disabled]="isLoading" matTooltip="previous week" mat-button
                                (click)="loadPreviousWeek()">
                                <i class="fa fa-arrow-left"></i>
                            </button>
                        </th>
                        <th colspan="3">W/C {{activeWeek.startOf('isoWeek').format('LL')}}</th>

                        <th>
                            <button [disabled]="isLoading" class="calendar" mat-button matTooltip="current week"
                                (click)="setCurrentWeekCalendar()">
                                <i class="fa fa-calendar"></i>
                            </button>
                        </th>

                        <th colspan="2" style="text-align: right;">
                            <button [disabled]="isLoading" matTooltip="next week" mat-button (click)="loadNextWeek()">
                                <i class="fa fa-arrow-right"></i>
                            </button>
                        </th>

                        <th>
                            <button [disabled]="isLoading" *ngIf="showExportAll" mat-stroked-button
                                matTooltip="Copy previous week schedule" (click)="clonePreviousWeekData()">
                                Copy
                            </button>
                        </th>

                        <th>
                            <button [disabled]="isLoading || !canSave" mat-stroked-button matTooltip="save"
                                (click)="saveTransportPlanButtonClick()">
                                Save
                            </button>
                        </th>

                        <th colspan="3" [hidden]="showTransportPlanDebugCalendar"></th>

                        <div [hidden]="!showTransportPlanDebugCalendar">
                            <mat-form-field class="row-box-datewidth">
                                <input matInput [matDatepicker]="debugDatePicker" #debugDate placeholder="DEBUG Date"
                                    (dateChange)="setDebugDate($event.value)" [min]="debugCalendarMinDate"
                                    [max]="debugCalendarMaxDate">
                                <mat-datepicker-toggle matSuffix [for]="debugDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #debugDatePicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </tr>
                    <tr class="heading-row">
                        <th class="interactive hide-sm hide-xs fav">
                            <button mat-button (click)="setFavouriteGrades()" [disabled]="favSubmitted">
                                <i class="fa fa-star" aria-hidden="true"></i>
                            </button>
                        </th>
                        <th appTableSort="Grade" (sort)="sortByInfo($event)" [reverseIsDefault]="isDefault"
                            class="interactive hide-sm hide-xs hidden-sm grade">
                            Grade
                        </th>
                        <th colspan="2" appTableSort="HeapShortName" (sort)="sortByInfo($event)" [reverseIsDefault]="isDefault"
                            class="interactive hide-sm hide-xs heap">Heap
                        </th>
                        
                        <th class="interactive hide-sm hide-xs button">&nbsp;</th>

                        <th colspan="2" class="interactive hide-sm hide-xs destination" id="DestinationHeader">Destinations</th>
                        <th class="interactive hide-sm hide-xs transport-mode">Transport <br> Mode</th>
                        <th class="interactive hide-sm hide-xs md-load">MT/<br>Load</th>

                        <th class="interactive hide-sm hide-xs day">Mon</th>
                        <th class="interactive hide-sm hide-xs day">Tue</th>
                        <th class="interactive hide-sm hide-xs day">Wed</th>
                        <th class="interactive hide-sm hide-xs day">Thu</th>
                        <th class="interactive hide-sm hide-xs day">Fri</th>
                        <th class="interactive hide-sm hide-xs day">Sat</th>
                        <th class="interactive hide-sm hide-xs day hidden-sm">Sun</th>
                        <th class="interactive hide-sm hide-xs week-total">Week Total</th>

                        <th colspan="2" class="interactive hide-sm hide-xs comments">Com&shy;ments</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="!isLoading && transportPlandata.length == 0 && !hasSearched">
                        <td colspan="10" style="text-align: left; color:red">
                            <mat-label>No Default Depot found. Please select a Depot to search for.</mat-label>
                        </td>
                    </tr>
                    <tr *ngIf="!isLoading && transportPlandata.length == 0 && hasSearched">
                        <td colspan="10" style="text-align: left">
                            <mat-label>No records found!</mat-label>
                        </td>
                    </tr>
                    <ng-template ngFor let-item [ngForOf]="transportPlandata" *ngIf="transportPlandata.length > 0">
                        <tr>
                            <td hide-sm hide-xs class="fav" [attr.rowspan]="getWeeklyDataCount(item)">
                                <div class="transport-plan-heap">
                                    <mat-checkbox #heap matTooltip="select heap" [checked]="item.isFavDepotHeap"
                                        class="transport-plan" (change)="addToFavouriteGrades($event, item)">
                                    </mat-checkbox>
                                </div>
                            </td>

                            <td hide-sm hide-xs class="hidden-sm" [attr.rowspan]="getWeeklyDataCount(item)">
                                <label matTooltip={{item.heapNo}}>{{item.grade}}</label>
                            </td>

                            <td colspan="2" hide-sm hide-xs class="heap" [attr.rowspan]="getWeeklyDataCount(item)">
                                <div>
                                    <label matTooltip={{item.heapDesc}}>{{item.heapShortName}}</label>
                                </div>
                            </td>

                            <td hide-sm hide-xs [attr.rowspan]="getWeeklyDataCount(item)">
                                <div class="button-wrapper">
                                    <button mat-button class="gray-button" (click)="addWeeklyData(item)"
                                        style="text-align: center; flex-grow: 1;">
                                        <i class="fa fa-plus-square"></i>
                                    </button>
                                </div>
                            </td>

                            <ng-template ngFor let-wd
                                [ngForOf]="getFirstWeeklyData(item) | genericfilter: weeklyDataFilter">
                                <td colspan="2" hide-sm hide-xs style="flex-direction: row;" class="destination">
                                    <button *ngIf="realLengthChecker(item)" class="delete-weekly" mat-button
                                        [disabled]="item.plan.weeklyData.length==1"
                                        (click)="deleteWeeklyData(item, wd)">
                                        <i class="fa fa-trash"></i>
                                    </button>

                                    <div>
                                        <mat-form-field>
                                            <input type="text" matInput [matAutocomplete]="destinationAuto"
                                                placeholder="" (blur)="lostFocus($event, wd)" #wd.destination
                                                [value]="wd.destination.description"
                                                matTooltip={{wd.destination.longName}}
                                                (input)="destinationValueChange($event.target.value)"
                                                (focus)="selectAllContent($event)">
                                        </mat-form-field>
                                        <div class="destination-error"
                                            *ngIf="(wd.transportMode === 'TRUCK' && wd.destination.locationCode === 'ANY' && wd.weekTotal > 0)">
                                            <mat-error>
                                                ANY is not allowed for Trucks
                                            </mat-error>
                                        </div>
                                        <mat-autocomplete [panelWidth]="400" #destinationAuto="matAutocomplete"
                                            [displayWith]="displayDestination"
                                            (optionSelected)="destinationChanged($event.option.value, wd)">
                                            <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
                                            <mat-option disabled *ngIf="destinationLoading" class="loading"
                                                style="padding-left: 182.5px; display: flex; align-items: center;">
                                                <mat-spinner diameter="35"></mat-spinner>
                                            </mat-option>
                                            <ng-container *ngIf="showDestinations">
                                                <mat-option *ngFor="let lc of filteredDestinations?.items" [value]="lc">
                                                    {{lc.locationCode + ' - ' + lc.description}}
                                                </mat-option>
                                            </ng-container>
                                        </mat-autocomplete>
                                    </div>
                                </td>

                                <td hide-sm hide-xs class="transport-mode">
                                    <mat-select [(value)]="wd.transportMode"
                                        (selectionChange)="updateTransportMode($event, item, wd)"
                                        style="text-transform:none;" placeholder="transport mode">
                                        <mat-option *ngFor="let tm of transportModes" [value]="tm">
                                            {{ tm }}
                                        </mat-option>
                                    </mat-select>
                                </td>

                                <td hide-sm hide-xs class="md-load">
                                    <mat-form-field style="text-align: center;">
                                        <input type="number" min="0" matInput #wd.loadPerMT
                                            value="{{wd.loadPerMT.toFixed(0)}}" title="{{wd.loadPerMT}}"
                                            (change)="updateForecastLoad($event, item, wd)">
                                    </mat-form-field>
                                </td>

                                <td hide-sm hide-xs class="day">
                                    <div class="day-tbl">
                                        <div class="day-row">
                                            <div class="day-cell">
                                                <mat-form-field>
                                                    <input matInput type="number" min="0" max="999"
                                                        oninput="this.value = Math.abs(this.value)" #wd.monday
                                                        (change)="recalculateWeeklyTotal($event.target.value, 1, item, wd)"
                                                        value="{{wd.monday}}" [disabled]="readyOnlyDays(1)">
                                                    <label>{{item.booked[0]}}</label>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </td>

                                <td hide-sm hide-xs class="day">
                                    <div class="day-tbl">
                                        <div class="day-row">
                                            <div class="day-cell">
                                                <mat-form-field>
                                                    <input matInput type="number" min="0" max="999"
                                                        oninput="this.value = Math.abs(this.value)" #wd.tuesday
                                                        (change)="recalculateWeeklyTotal($event.target.value, 2, item, wd)"
                                                        value="{{wd.tuesday}}" [disabled]="readyOnlyDays(2)">
                                                    <label>{{item.booked[1]}}</label>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </td>

                                <td hide-sm hide-xs class="day">
                                    <div class="day-tbl">
                                        <div class="day-row">
                                            <div class="day-cell">
                                                <mat-form-field>
                                                    <input matInput type="number" min="0" max="999"
                                                        oninput="this.value = Math.abs(this.value)" #wd.wednesday
                                                        (change)="recalculateWeeklyTotal($event.target.value, 3, item, wd)"
                                                        value="{{wd.wednesday}}" [disabled]="readyOnlyDays(3)">
                                                    <label>{{item.booked[2]}}</label>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </td>

                                <td hide-sm hide-xs class="day">
                                    <div class="day-tbl">
                                        <div class="day-row">
                                            <div class="day-cell">
                                                <mat-form-field>
                                                    <input matInput type="number" min="0" max="999"
                                                        oninput="this.value = Math.abs(this.value)"
                                                        (change)="recalculateWeeklyTotal($event.target.value, 4, item, wd)"
                                                        value="{{wd.thursday}}" [disabled]="readyOnlyDays(4)">
                                                    <label>{{item.booked[3]}}</label>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </td>

                                <td hide-sm hide-xs class="day">
                                    <div class="day-tbl">
                                        <div class="day-row">
                                            <div class="day-cell">
                                                <mat-form-field>
                                                    <input matInput type="number" min="0" max="999"
                                                        oninput="this.value = Math.abs(this.value)" #wd.friday
                                                        (change)="recalculateWeeklyTotal($event.target.value, 5, item, wd)"
                                                        value="{{wd.friday}}" [disabled]="readyOnlyDays(5)">
                                                    <label>{{item.booked[4]}}</label>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </td>

                                <td hide-sm hide-xs class="day">
                                    <div class="day-tbl">
                                        <div class="day-row">
                                            <div class="day-cell">
                                                <mat-form-field>
                                                    <input matInput type="number" min="0" max="999"
                                                        oninput="this.value = Math.abs(this.value)" #wd.saturday
                                                        (change)="recalculateWeeklyTotal($event.target.value,6, item, wd)"
                                                        value="{{wd.saturday}}" [disabled]="true">
                                                    <label>{{item.booked[5]}}</label>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </td>

                                <td hide-sm hide-xs class="day hidden-sm">
                                    <div class="day-tbl">
                                        <div class="day-row">
                                            <div class="day-cell">
                                                <mat-form-field>
                                                    <input matInput type="number" min="0" max="999"
                                                        oninput="this.value = Math.abs(this.value)" #wd.sunday
                                                        (change)="recalculateWeeklyTotal($event.target.value, 7, item, wd)"
                                                        value="{{wd.sunday}}" [disabled]="true">
                                                    <label>{{item.booked[6]}}</label>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </td>

                                <td hide-sm hide-xs class="week-total">
                                    <mat-form-field style="text-align: center;">
                                        <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                            matInput #wd.weekTotal value="{{wd.weekTotal}}" [readonly]="true">
                                    </mat-form-field>
                                </td>

                                <td colspan="2" hide-sm hide-xs class="comments">
                                    <mat-form-field style=" text-align: left;">
                                        <input type="text" matInput #wd.comments
                                            (change)="updateComments($event.target.value, wd)" value="{{wd.comments}}"
                                            matTooltip={{wd.comments}}
                                            (keyup.escape)="onKeyEscapeComments($event, item, wd)">
                                    </mat-form-field>

                                </td>
                            </ng-template>
                            
                        </tr>
                        <ng-template ngFor let-wd [ngForOf]="skipFirst(item) | genericfilter: weeklyDataFilter">
                            <tr>
                                <td colspan="2" hide-sm hide-xs style="flex-direction: row;" class="destination">
                                    <button *ngIf="realLengthChecker(item)" class="delete-weekly" mat-button
                                        [disabled]="item.plan.weeklyData.length==1"
                                        (click)="deleteWeeklyData(item, wd)">
                                        <i class="fa fa-trash"></i>
                                    </button>

                                    <div>
                                        <mat-form-field>
                                            <input type="text" matInput [matAutocomplete]="destinationAuto"
                                                placeholder="" (blur)="lostFocus($event, wd)" #wd.destination
                                                [value]="wd.destination.description"
                                                matTooltip={{wd.destination.longName}}
                                                (input)="destinationValueChange($event.target.value)"
                                                (focus)="selectAllContent($event)">
                                        </mat-form-field>
                                        <div class="destination-error"
                                            *ngIf="(wd.transportMode === 'TRUCK' && wd.destination.locationCode === 'ANY' && wd.weekTotal > 0)">
                                            <mat-error>
                                                ANY is not allowed for Trucks
                                            </mat-error>
                                        </div>
                                        <mat-autocomplete [panelWidth]="400" #destinationAuto="matAutocomplete"
                                            [displayWith]="displayDestination"
                                            (optionSelected)="destinationChanged($event.option.value, wd)">
                                            <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
                                            <mat-option disabled *ngIf="destinationLoading" class="loading"
                                                style="padding-left: 182.5px; display: flex; align-items: center;">
                                                <mat-spinner diameter="35"></mat-spinner>
                                            </mat-option>
                                            <ng-container *ngIf="showDestinations">
                                                <mat-option *ngFor="let lc of filteredDestinations?.items" [value]="lc">
                                                    {{lc.locationCode + ' - ' + lc.description}}
                                                </mat-option>
                                            </ng-container>
                                        </mat-autocomplete>
                                    </div>
                                </td>

                                <td hide-sm hide-xs class="transport-mode">
                                    <mat-select [(value)]="wd.transportMode"
                                        (selectionChange)="updateTransportMode($event, item, wd)"
                                        style="text-transform:none;" placeholder="transport mode">
                                        <mat-option *ngFor="let tm of transportModes" [value]="tm">
                                            {{ tm }}
                                        </mat-option>
                                    </mat-select>
                                </td>

                                <td hide-sm hide-xs class="md-load">
                                    <mat-form-field style="text-align: center;">
                                        <input type="number" min="0" matInput #wd.loadPerMT
                                            value="{{wd.loadPerMT.toFixed(0)}}" title="{{wd.loadPerMT}}"
                                            (change)="updateForecastLoad($event, item, wd)">
                                    </mat-form-field>
                                </td>

                                <td hide-sm hide-xs class="day">
                                    <div class="day-tbl">
                                        <div class="day-row">
                                            <div class="day-cell">
                                                <mat-form-field>
                                                    <input matInput type="number" min="0" max="999"
                                                        oninput="this.value = Math.abs(this.value)" #wd.monday
                                                        (change)="recalculateWeeklyTotal($event.target.value, 1, item, wd)"
                                                        value="{{wd.monday}}" [disabled]="readyOnlyDays(1)">
                                                    <label>{{item.booked[0]}}</label>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </td>

                                <td hide-sm hide-xs class="day">
                                    <div class="day-tbl">
                                        <div class="day-row">
                                            <div class="day-cell">
                                                <mat-form-field>
                                                    <input matInput type="number" min="0" max="999"
                                                        oninput="this.value = Math.abs(this.value)" #wd.tuesday
                                                        (change)="recalculateWeeklyTotal($event.target.value, 2, item, wd)"
                                                        value="{{wd.tuesday}}" [disabled]="readyOnlyDays(2)">
                                                    <label>{{item.booked[1]}}</label>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </td>

                                <td hide-sm hide-xs class="day">
                                    <div class="day-tbl">
                                        <div class="day-row">
                                            <div class="day-cell">
                                                <mat-form-field>
                                                    <input matInput type="number" min="0" max="999"
                                                        oninput="this.value = Math.abs(this.value)" #wd.wednesday
                                                        (change)="recalculateWeeklyTotal($event.target.value, 3, item, wd)"
                                                        value="{{wd.wednesday}}" [disabled]="readyOnlyDays(3)">
                                                    <label>{{item.booked[2]}}</label>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </td>

                                <td hide-sm hide-xs class="day">
                                    <div class="day-tbl">
                                        <div class="day-row">
                                            <div class="day-cell">
                                                <mat-form-field>
                                                    <input matInput type="number" min="0" max="999"
                                                        oninput="this.value = Math.abs(this.value)"
                                                        (change)="recalculateWeeklyTotal($event.target.value, 4, item, wd)"
                                                        value="{{wd.thursday}}" [disabled]="readyOnlyDays(4)">
                                                    <label>{{item.booked[3]}}</label>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </td>

                                <td hide-sm hide-xs class="day">
                                    <div class="day-tbl">
                                        <div class="day-row">
                                            <div class="day-cell">
                                                <mat-form-field>
                                                    <input matInput type="number" min="0" max="999"
                                                        oninput="this.value = Math.abs(this.value)" #wd.friday
                                                        (change)="recalculateWeeklyTotal($event.target.value, 5, item, wd)"
                                                        value="{{wd.friday}}" [disabled]="readyOnlyDays(5)">
                                                    <label>{{item.booked[4]}}</label>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </td>

                                <td hide-sm hide-xs class="day">
                                    <div class="day-tbl">
                                        <div class="day-row">
                                            <div class="day-cell">
                                                <mat-form-field>
                                                    <input matInput type="number" min="0" max="999"
                                                        oninput="this.value = Math.abs(this.value)" #wd.saturday
                                                        (change)="recalculateWeeklyTotal($event.target.value,6, item, wd)"
                                                        value="{{wd.saturday}}" [disabled]="true">
                                                    <label>{{item.booked[5]}}</label>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </td>

                                <td hide-sm hide-xs class="day hidden-sm">
                                    <div class="day-tbl">
                                        <div class="day-row">
                                            <div class="day-cell">
                                                <mat-form-field>
                                                    <input matInput type="number" min="0" max="999"
                                                        oninput="this.value = Math.abs(this.value)" #wd.sunday
                                                        (change)="recalculateWeeklyTotal($event.target.value, 7, item, wd)"
                                                        value="{{wd.sunday}}" [disabled]="true">
                                                    <label>{{item.booked[6]}}</label>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </td>

                                <td hide-sm hide-xs class="week-total">
                                    <mat-form-field style="text-align: center;">
                                        <input type="number" min="0" oninput="this.value = Math.abs(this.value)"
                                            matInput #wd.weekTotal value="{{wd.weekTotal}}" [readonly]="true">
                                    </mat-form-field>
                                </td>

                                <td colspan="2" hide-sm hide-xs class="comments">
                                    <mat-form-field style=" text-align: left;">
                                        <input type="text" matInput #wd.comments
                                            (change)="updateComments($event.target.value, wd)" value="{{wd.comments}}"
                                            matTooltip={{wd.comments}}
                                            (keyup.escape)="onKeyEscapeComments($event, item, wd)">
                                    </mat-form-field>

                                </td>
                            </tr>
                        </ng-template>
                    </ng-template>
                </tbody>
            </table>
            <table style="width: 100%;" *ngIf="transportPlandata.length > 0">
                <tfoot>
                    <tr>
                        <td colspan="9"></td> <!-- spacer -->
                        <td colspan="5" class="warning" *ngIf="hasInvalidInputs && transportPlandata.length > 1">
                            If you navigate to the next/previous page you will lose your changes.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="9"></td> <!-- spacer -->
                        <td colspan="20">
                            <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                                (pageChange)="pageChanged($event)" aria-label="Default pagination">
                            </ngb-pagination>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</mat-card>
