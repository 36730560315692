import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router, Params } from '@angular/router';
import { AuthService } from './authservice.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
	providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
	constructor(public auth: AuthService, private oauthService: OAuthService, private router: Router) {}
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		if (!this.auth.isAuthenticated()) {
			this.oauthService.initImplicitFlow();
			return false;
		}
		return true;
	}
}