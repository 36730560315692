import {Injectable} from '@angular/core';
import {RestTokenService} from './gen/rest-token-service';
import { OAuthService } from 'angular-oauth2-oidc';


@Injectable()
export class RestTokenImplService implements RestTokenService {

	constructor(private oauthService: OAuthService) { }


	token(): string {
		return this.oauthService.getAccessToken();
	}

}
