import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { InitModel } from '../gen/models';
import { AllocationService } from '../gen/services/allocation.service';

@Injectable({
    providedIn: 'root'
})
export class InitService {
    initData$ = new BehaviorSubject<InitModel>(null);

    constructor(private allocationService: AllocationService) { }

    fetch() {
        this.allocationService.init().subscribe(r => this.initData$.next(r));
    }
}
