import { Component, OnInit, Inject, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import {
	Haulier, ContractHeaderViewModel, ContractPartyVm, ContainerType, UomVm,
	AllocateModel, ContractLineViewModel, AllocateRequestVm, AppointmentVm, InitModel, AllocateRequestWeightLoads
} from '../gen/models';
import { FormBuilder, FormGroup, Validators, FormArray, AbstractControl, FormControl } from '@angular/forms';
import { HauliersService } from '../gen/services/hauliers.service';
import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContainersService } from '../gen/services/containers.service';
import { UomsService } from '../gen/services/uoms.service';
import { isNullOrUndefined } from '../tools';
import { AppointmentService } from '../gen/services/appointment.service';
import { ToastrService } from 'ngx-toastr';
import { InitService } from '../services/init.service';
import * as moment from 'moment-timezone';
import { ContractLineVmExtended } from '../allocate-create-dialog/allocate-create-dialog.component';
import { haulagePriceMatrixVM } from '../gen/models/haulagePriceMatrixVM';
import { SpinnerComponent } from '../spinner/spinner.component';
import { AllocateService } from '../gen/services/allocate.service';

@Component({
	selector: 'app-allocate-multi-grade-dialog',
	templateUrl: './allocate-multi-grade-dialog.component.html',
	styleUrls: ['./allocate-multi-grade-dialog.component.scss']
})
export class AllocateMultiGradeDialogComponent implements OnInit {
	@ViewChild('additional') additional;
	@ViewChild('loads') loads: HTMLInputElement;
	@ViewChild('weightPerLoad') weightPerLoad: HTMLInputElement;
	@ViewChild('qty') qty: HTMLInputElement;
	@ViewChild('haulageRatesSpinner') haulageRatesSpinner: SpinnerComponent;
	@ViewChild('tabs', { static: true }) tabGroup: MatTabGroup;
	amounts: number[];
	selectedTab = 'requests';
	canAllocate = false;
	allocationForm: FormGroup;
	form: FormGroup;
	filteredHauliers: Haulier[];
	containerTypes: ContainerType[];
	uoms: UomVm[];
	additionalHidden = true;
	noLinesPickedForBulk = false;
	noLoads = false;
	requestWithnoLoads = '';
	noPickupDate = false;
	bulkAllocate = false;
	isBooked = false;
	bulkAllocationMode: string[] = [];
	datePlaceholder: string[][] = [];
	weekPlaceholder: string[][] = [];
	timePlaceholder: string[] = [];
	groupDaysCount: number[][] = [];
	groupWeeksCount: number[][] = [];
	firstMonday: string;
	selectedContractLine: ContractLineViewModel[] = [];
	requestGradesToAllocate: string[] = [];
	requestsToAllocate: any[];
	contractLinesToAllocate: string[];
	selectedRequest: AllocatedGrade;
	selectedContractLineGrade: string;
	selectedAllocate: AllocatedGrade;
	allocatedGrades: AllocatedGrade[] = [];
	appointments: AppointmentVm[];
	initModel: InitModel;
	defaultAdditionalCostUomId = 0;
	isMixedGradeContract = false;
	haulageRates: haulagePriceMatrixVM[] = [];
	haulagematrixDropdownOnly = false;
	datetimeFromToolTipText = 'No Request Ready Date found';
	containerTypeId: number[] = [];
	depotNo: string[] = [];
	shownLoadTimes: number[] = [];
	clockClicked = false;
	loadTimesStyle = {};
	increments = ['15 minutes', '20 minutes', '25 minutes', '30 minutes', '45 minutes', '60 minutes', '90 minutes', '120 minutes'];
	incrementMinutes = [15, 20, 25, 30, 45, 60, 90, 120];
	remainingInstructionsText: string;
	instructionsMaxlength = 1500;
	minRequiredLoad = 0;
	selectedTabIndex = 0;
	@ViewChild('loadingCreateDialogSpinner') loadingCreateDialogSpinner: SpinnerComponent;
	loadingCreateDialog = false;
	isSubmit = false;

	constructor(public dialogRef: MatDialogRef<AllocateMultiGradeDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { party: ContractPartyVm, contract: ContractHeaderViewModel, requests: any[] },
		private fb: FormBuilder,
		private hauliersService: HauliersService,
		private containerService: ContainersService,
		private uomsService: UomsService,
		private appointmentService: AppointmentService,
		private toastr: ToastrService,
		private initService: InitService,
		private changeDetectorRef: ChangeDetectorRef,
		private allocateService: AllocateService
	) {
		this.datePlaceholder = [];
		this.weekPlaceholder = [];
		this.shownLoadTimes = [];
	}

	get haulier() {
		return this.allocationForm.get('haulier');
	}

	get maxCosts() {
		return this.initModel.config.maxAllocationCosts;
	}

	ngOnInit() {
		this.prepopulateModels();
		this.setupFormControls();
		this.setupLoadInitialData();
		this.selectedTabIndex = 0;
	}

	private prepopulateModels() {
		for (let i = 0; i < this.data.requests.length; i++) {
			this.selectedContractLine[i] = this.data.contract.contractLines[0];
			this.containerTypeId[i] = this.data.contract.containerTypeID;
			this.depotNo[i] = this.data.requests[i].depotNo;
		}
		this.isMixedGradeContract = this.data.contract
			&& this.data.contract.contractLines
			&& this.data.contract.contractLines.find(a => Boolean(a.allocatedViaReference)) !== undefined;

		this.uomsService.getAll().subscribe(u => {
			this.uoms = u;
			if (this.uoms) {
				this.defaultAdditionalCostUomId = this.uoms.find(a => a.code === 'LD').id;
			}
		});
	}

	private setupLoadInitialData() {
		this.initService.initData$.subscribe(r => this.initModel = r
		);

		this.requestsToAllocate = this.data.requests
			.map(r => ({ requestId: r.requestId, grade: r.grade }))
			.filter(g => this.data.contract.contractLines.map(l => l.grade).indexOf(g.grade) === -1)
			.filter((v, i, s) => s.indexOf(v) === i)
			.sort((a, b) => a.grade.localeCompare(b.grade));

		this.contractLinesToAllocate = this.data.contract.contractLines
			.map(r => r.grade === null || r.grade === '' ? r.salesGrade : r.grade)
			.filter((v, i, s) => s.indexOf(v) === i)
			.sort((a, b) => a.localeCompare(b));

		this.appointmentService
			.getAvailableAppointmentsForParty(this.data.party.partyAccountNo, this.data.contract.customerContractRef, 0)
			.subscribe(a => this.appointments = a);
		this.containerService.get().subscribe(c => this.containerTypes = c);

		if (this.requestGradesToAllocate.length > 0) { this.autoMapGrades(); }

		this.data.contract.contractLines.forEach(l => {
			const line = l as ContractLineVmExtended;
			line.loadsWarning = undefined;
			line.weightWarning = undefined;
		});

		if (this.data.requests && this.data.requests.length === 1 && this.data.requests[0] && this.data.requests[0].readyDate) {
			this.datetimeFromToolTipText = 'Request Ready Date ' + (new Date(this.data.requests[0].readyDate).toLocaleString());
		}
	}
	private setupFormControls() {
		this.allocationForm = this.fb.group({
			haulier: [''],
			containerTypeId: ['', Validators.required],
			containerNumber: [''],
			allocationDetail: this.fb.array(this.addAllocationDetailFormGroup(this.data.requests)),
			requests: this.fb.array(this.initRequests(this.data)),
			bulkAllocate: [''],
			isBooked: [''],
			bulkAllocationPickupDate: [''],
			bulkAllocationRequiredLoads: [''],
			bulkAllocateIns: [this.data.contract.comments],
			instructions: [this.data.contract.comments],
			allocationTypes: []
		});

		this.allocationForm.controls['instructions'].valueChanges.subscribe((newVal: string) => {
			this.remainingInstructionsText = `${newVal.length}/${this.instructionsMaxlength}`;
		});
		this.allocationForm.controls['bulkAllocateIns'].valueChanges.subscribe((newVal: string) => {
			this.remainingInstructionsText = `${newVal.length}/${this.instructionsMaxlength}`;
		});

		this.allocationForm.controls.containerTypeId.setValue(this.data.contract.containerTypeID);
	}

	private addAllocationDetailFormGroup(arr: any) {
		const groupArr: FormGroup[] = [];
		let i = 0;
		for (const a of arr) {
			this.datePlaceholder[i] = [];
			this.weekPlaceholder[i] = [];
			this.groupDaysCount[i] = [];
			this.groupWeeksCount[i] = [];
			this.shownLoadTimes[i] = undefined;
			const grp = this.fb.group({
				requestId: [a.requestId],
				haulier: [''],
				containerTypeId: [this.data.contract.containerTypeID, Validators.required],
				containerNumber: [''],
				allocationAppointments: [''],
				instructions: [this.data.contract.comments],
				additionalCosts: [''],
				loadsByDate: this.fb.array(this.addBulkAllocationFormGroup(i, this.datePlaceholder[i])),
				loadsByWeek: this.fb.array(this.addBulkAllocationFormGroup(i, this.weekPlaceholder[i])),
				loadsByTime: this.fb.array([]),
				groupAllocations: [''],
				bulkAllocationPickupDate: [''],
				bulkAllocationRequiredLoads: [''],
				bulkAllocateIns: [''],
				bulkAllocateGroupAllocations: ['']
			});

			grp.controls['instructions'].valueChanges.subscribe((newVal: string) => {
				this.remainingInstructionsText = `${newVal.length}/${this.instructionsMaxlength}`;
			});

			grp.controls['bulkAllocateIns'].valueChanges.subscribe((newVal: string) => {
				this.remainingInstructionsText = `${newVal.length}/${this.instructionsMaxlength}`;
			});

			groupArr.push(grp);

			grp.controls.loadsByDate.valueChanges
				.subscribe(r => this.addRemoveLoadTimes(grp.get('loadsByDate') as FormArray));

			grp.controls.loadsByWeek.valueChanges
				.subscribe(r => this.addRemoveLoadTimes(grp.get('loadsByWeek') as FormArray));

			i++;
		}


		return groupArr;
	}

	ngOnDestroy(): void {
		if (this.haulageRatesSpinner !== undefined && this.haulageRatesSpinner.overlayRef !== undefined)
			this.haulageRatesSpinner.overlayRef.dispose();
		if (this.loadingCreateDialogSpinner !== undefined)
			this.loadingCreateDialogSpinner.overlayRef.dispose();
	}

	unallocatedRequests() {
		return this.requestsToAllocate.filter(r => !this.allocatedGrades.find(m => m.requestGrade === r.grade));
	}

	initRequests(data: { party: ContractPartyVm; contract: ContractHeaderViewModel; requests: any[]; }): FormGroup[] {
		const groupArr: FormGroup[] = [];
		for (const r of data.requests) {
			const contractLines = this.initContractLines(r.grade);
			if (contractLines.length === 0) {
				this.requestGradesToAllocate.push(r.grade);
				this.addRequestGroup(groupArr, r, {}, contractLines);
			} else {
				const grp = this.addRequestGroup(groupArr, r, {}, contractLines);
				this.calculateRequired(r).subscribe(c => {
					grp.controls.requiredWeight.patchValue(c.requiredWeight);
					grp.controls.requiredWeightUomId.patchValue(c.contractUomId);
					grp.controls.requiredLoads.patchValue(c.requiredLoads);
					grp.controls.requiredWeightPerLoad.patchValue(c.requiredWeightPerLoad);
					r.convertedWeight = c.convertedWeight;
				});
			}
		}
		return groupArr;
	}

	private addRequestGroup(groupArr: FormGroup[], r: any, c: any, contractLines: FormGroup[]): FormGroup {
		const grp = this.fb.group({
			requestId: r.requestId,
			depotNo: r.depotNo,
			requiredWeight: c.requiredWeight,
			requiredWeightUomId: c.requiredWeightUomId,
			requiredLoads: c.requiredLoads,
			requiredWeightPerLoad: c.requiredWeightPerLoad,
			contractLines: this.fb.array(contractLines),
			hasLinked: contractLines.length > 0,
			fullyAllocate: false
		});
		groupArr.push(grp);
		return grp;
	}

	initContractLines(grade: string): FormGroup[] {
		const groupArr: FormGroup[] = [];
		const lines = this.getContractLines(grade);
		for (const l of lines) {
			const group = this.fb.group({
				contractLineId: l.contractLineId,
				requiredWeight: { value: '', disabled: this.roundWeight(l.balanceWeight) === 0 },
				requiredLoads: { value: '', disabled: this.roundWeight(l.balanceWeight) === 0 },
				requiredWeightPerLoad: { value: '', disabled: this.roundWeight(l.balanceWeight) === 0 },
				weightUomCode: l.weightUomCode
			});
			groupArr.push(group);
		}
		return groupArr;
	}

	take(request: any) {
		request.taken = true;
	}

	qtyChange(request: AllocateRequestVmExtended, qty: string, index: number, loadsInput: HTMLInputElement,
		weightPerInput: HTMLInputElement): number {
		request.takenQty = +qty;
		if (!loadsInput.disabled && weightPerInput.disabled) {
			this.weightPerChange(weightPerInput.value, qty, index, loadsInput);
		} else if (!weightPerInput.disabled && loadsInput.disabled) {
			this.loadsChange(loadsInput.value, qty, index, weightPerInput);
		} else {
			const loads = loadsInput.value;
			const weightPer = +loads > 0 ? +qty / +loads : 0;
			this.getRequestGroup(index).get('requiredWeightPerLoad').setValue(weightPer);
			const line = this.selectedContractLine[index] as ContractLineVmExtended;
			this.checkTargets(+qty, +loadsInput.value, line);
		} '';
		if (qty === '' || +qty === 0) {
			this.resetInputs(null, loadsInput, weightPerInput, index, null);
			this.getRequestGroup(index).get('requiredWeight').setValidators(null);
		} else {
			this.getRequestGroup(index).get('requiredWeight').setValidators([Validators.required]);
			this.getRequestGroup(index).get('requiredLoads').setValidators([Validators.required]);
			this.getRequestGroup(index).get('requiredWeightPerLoad').setValidators([Validators.required]);
		}
		return +qty;
	}

	get total() {
		const takenTotal = this.data.requests
			.map(r => {
				return r.takenQty ? r.takenQty : r.remainingWeight;
			})
			.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
		return this.roundWeight(takenTotal);
	}

	cancel() {
		this.dialogRef.close();
	}

	selectTab(tab: string) {
		this.selectedTab = tab;
	}

	roundWeight(weight: number) {
		return Math.round(weight * 10) / 10;
	}

	getDepots(line: ContractLineViewModel): string {
		let depots = '';
		let index = 0;
		if (!isNullOrUndefined(line.contractLineDepotPremiumDepotNos)) {
			line.contractLineDepotPremiumDepotNos.forEach(e => {
				if (index == 0) {
					depots = depots.concat(e);
					index++;
				}
				else {
					depots = depots.concat(',', e);
				}
			});

			return depots;
		}
	}

	getContractLines(grade: string): ContractLineViewModel[] {
		let lines = this.data.contract.contractLines.filter(l => l.grade === grade);
		if (isNullOrUndefined(lines) || lines.length === 0) { //fall back to sales grade
			lines = this.data.contract.contractLines.filter(l => l.salesGrade === grade);
		}
		if (this.allocatedGrades.length > 0 && (isNullOrUndefined(lines) || lines.length === 0)) {
			const allocatedContractLineGrades = this.allocatedGrades.filter(m => m.requestGrade === grade);
			if (allocatedContractLineGrades.length > 0) {
				const allocatedContractLineGrade = allocatedContractLineGrades[0].contractLineGrade;
				lines = this.data.contract.contractLines.filter(l => l.grade === allocatedContractLineGrade);
				if (isNullOrUndefined(lines) || lines.length === 0) { //fall back to sales grade
					lines = this.data.contract.contractLines.filter(l => l.salesGrade === allocatedContractLineGrade);
				}
			}
		}
		return lines;
	}

	allocate(formValue: AllocateModel): boolean {
		formValue.contractHeaderId = this.data.contract.contractHeaderId;
		formValue.isMixedLoad = this.isMixedGradeContract;
		formValue.partyAccountNumber = this.data.party.partyAccountNo;
		formValue.contractRef = this.data.contract.customerContractRef;
		formValue.bulkAllocate = this.bulkAllocate;
		formValue.isBooked = this.isBooked;
		formValue.allocationTypes = [];
		for (const value of formValue.allocationDetail) {
			for (const appt of value.allocationAppointments) {
				appt.haulierAccountNumber = appt['haulier'].accountNumber;
			}
			if (value.agreedHaulageRateUomId <= 0) {
				value.agreedHaulageRateUomId = this.defaultAdditionalCostUomId;
			}
		}

		if (this.bulkAllocate) {
			for (let i = 0; i < formValue.allocationDetail.length; i++) {
				this.requestWithnoLoads = `Request ${i + 1}`;
				const allocateDetail = formValue.allocationDetail[i];
				if (this.bulkAllocationMode[i] == 'date') {
					if (allocateDetail.loadsByDate.length == allocateDetail.loadsByDate.filter(l => !(l.requiredLoads > 0)).length) {
						this.noPickupDate = false;
						this.noLoads = true;
						return true;
					}
					else
						this.noLoads = false;
					formValue.allocationTypes[i] = 1;
					formValue.bulkAllocationType = 1;
					allocateDetail.pickupDateTime = moment.utc().format('YYYY-MM-DD');
				}
				else if (this.bulkAllocationMode[i] == 'time') {
					if (allocateDetail.loadsByTime.length == allocateDetail.loadsByTime.filter(l => isNullOrUndefined(l.pickupTime)).length) {
						this.noLoads = false;
						this.noPickupDate = true;
						return true;
					}
					else
						this.noPickupDate = false;
					formValue.allocationTypes[i] = 2;
					formValue.bulkAllocationType = 2;
					allocateDetail.pickupDateTime = allocateDetail.bulkAllocationPickupDate;
				}
				else if (this.bulkAllocationMode[i] == 'week') {
					if (allocateDetail.loadsByWeek.length == allocateDetail.loadsByWeek.filter(l => !(l.requiredLoads > 0)).length) {
						this.noPickupDate = false;
						this.noLoads = true;
						return true;
					}
					else
						this.noLoads = false;
					formValue.allocationTypes[i] = 3;
					formValue.bulkAllocationType = 3;
					allocateDetail.pickupDateTime = moment.utc(this.firstMonday).format('YYYY-MM-DD');
				}
				allocateDetail.instructions = this.allocationForm.controls.bulkAllocateIns.value;
			}
		}
		else { //todo: try and use form validation instead. We can't easily because these fields are not always required (e.g. bulk allocate or when using 'fully allocate')
			if (formValue.requests.length > 0) {
				const requestsWithoutLoads = formValue.requests.filter(r => r.requiredLoads === 0);
				const ctrls = this.allocationForm.controls.allocationDetail;
				if (requestsWithoutLoads.length !== 0) {
					this.setFormTab(ctrls, requestsWithoutLoads[0].requestId);
					this.toastr.error('Form Invalid', 'Required Loads must be set.');
					return false;
				}

				const requestsWithoutWeight = formValue.requests.filter(r => r.requiredWeight === 0);
				if (requestsWithoutWeight.length !== 0) {
					this.setFormTab(ctrls, requestsWithoutWeight[0].requestId);
					this.toastr.error('Form Invalid', 'Required Weight must bet set.');
					return false;
				}
			}
		}

		if (formValue.requests[0].contractLines.length > 1) {
			for (let i = 0; i < formValue.requests.length; i++) {
				formValue.requests[i].contractLines = [{
					contractLineId: this.selectedContractLine[i].contractLineId
				}];
			}
		}

		if (!this.bulkAllocate) {
			let i = 0;
			for (const req of formValue.requests) {
				const allocationDetail = formValue.allocationDetail.filter(a => a.requestId == req.requestId);
				const allocationAppointments = allocationDetail[0].allocationAppointments;
				const loads = req.requiredLoads;

				if (allocationAppointments && loads != allocationAppointments.length) {

					if (allocationAppointments.length > 1) {
						this.tabGroup.selectedIndex = i;
						this.toastr.error('Form Invalid', 'Pickup date not set for required loads.');
						return false;
					} else {
						for (let i = 1; i < loads; i++) {
							allocationAppointments[i] = allocationAppointments[0];
						}
					}

				}
				i++;
			}

			if (!this.validateForm(this.allocationForm.controls.allocationDetail)) {
				return false;
			}
		}

		if (this.allocationForm.valid) {
			this.isSubmit = true;
			this.loadingCreateDialog = true;
			if (formValue.requests.length > 1) {
				for (let i = 0; i < formValue.requests.length; i++) {
					const requestObject = JSON.parse(JSON.stringify(formValue));
					requestObject.requests = [];
					requestObject.allocationDetail = [];
					requestObject.requests[0] = formValue.requests[i];
					requestObject.bulkAllocationType = formValue.allocationTypes[i];
					requestObject.allocationDetail[0] = formValue.allocationDetail.find(x => x.requestId == requestObject.requests[0].requestId);
					this.allocateService.allocate(requestObject).subscribe(a => {
						this.success('Allocation successfully created', 'Allocation');
					}, err => {
						if (err.error != null && err.error.status === 200) {
							this.success('Allocation successfully created', 'Allocation');
						} else {
							this.toastr.error('Something went wrong and the request could not be allocated. Please try again later.', 'Allocate Failed');
							this.isSubmit = false;
						}
						this.loadingCreateDialog = false;
						return false;
					});
				}
				this.closeDialog();
			}
			else {
				this.allocateService.allocate(formValue).subscribe(a => {
					this.success('Allocation successfully created', 'Allocation');
					this.closeDialog();
				}, err => {
					if (err.error != null && err.error.status === 200) {
						this.success('Allocation successfully created', 'Allocation');
						this.closeDialog();
					} else {
						this.toastr.error('Something went wrong and the request could not be allocated. Please try again later.', 'Allocate Failed');
						this.isSubmit = false;
					}
					this.loadingCreateDialog = false;
				});
			}
		} else {
			if (!isNullOrUndefined(this.allocationForm.errors)
				&& !isNullOrUndefined(this.allocationForm.controls.errors)
				&& !isNullOrUndefined(this.allocationForm.errors.dates)) {
				this.toastr.error('Form Invalid', this.allocationForm.errors.dates);
				return false;
			}
		}

		return true;
	}

	private closeDialog() {
		setTimeout(() => {
			this.loadingCreateDialog = false;
			this.dialogRef.close();
		}, 1500);
	}

	private success(message: string, title: string) {
		this.toastr.success(message, title);
	}

	setFormTab(ctrls: any, requestId: any) {
		let i = 0;
		for (const fg of ctrls.controls) {
			if (fg.controls.requestId.value === requestId) {
				this.tabGroup.selectedIndex = i;
			}
			i++;
		}
	}

	validateForm(ctrls: any): boolean {
		let i = 0;
		for (const fg of ctrls.controls) {
			if (!isNullOrUndefined(fg.controls.containerTypeId.errors)) {
				this.tabGroup.selectedIndex = i;
				this.toastr.error('ContainerType is required', 'Form Invalid');
				return false;
			}
			if (!this.validateAppointments(fg.controls.allocationAppointments)) {
				this.tabGroup.selectedIndex = i;
				return false;
			}
			i++;
		}
		return true;
	}

	validateAppointments(appts: any): boolean {
		if (isNullOrUndefined(appts.value) || appts.value === '') {
			this.toastr.error('Pickup date is required', 'Form Invalid');
			return false;
		}
		for (const apt of appts.value) {
			if (isNullOrUndefined(apt.pickupDateTime) || apt.pickupDateTime === '' || !apt.pickupDateTime.isValid()) {
				this.toastr.error('A valid Pickup date is required', 'Form Invalid');
				return false;
			} else if (apt.pickupToDateTime && apt.pickupDateTime > apt.pickupToDateTime) {
				this.toastr.error('Pickup date should be less than pickupToDateTime', 'Form Invalid');
				return false;
			} else if (apt.customerAptToDateTime &&
				(apt.customerAptDateTime === '' || !apt.customerAptDateTime.isValid()
					|| apt.customerAptDateTime > apt.customerAptToDateTime)) {
				this.toastr.error('CustomerAptDateTime should be less than customerAptToDateTime', 'Form Invalid');
				return false;
			} else if (!isNullOrUndefined(apt.agreedHaulageRate) && apt.agreedHaulageRate > 0 && (isNullOrUndefined(apt.agreedHaulageRateUomId) || apt.agreedHaulageRateUomId == 0)) {
				this.toastr.error('Please select a Haulge Spot Rate UOM', 'Form Invalid');
				return false;
			}
			return true;
		}
	}

	loadsChange(
		loads: string, qty: string, index: number,
		weightPerInput: HTMLInputElement) {
		const weightPer = +loads > 0 ? +qty / +loads : 0;
		weightPerInput.value = `${weightPer}`;
		weightPerInput.disabled = true;
		this.getRequestGroup(index).get('requiredWeightPerLoad').setValue(weightPer);
		const line = this.selectedContractLine[index] as ContractLineVmExtended;
		this.checkTargets(+qty, +loads, line);
	}

	weightPerChange(
		weightPer: string, qty: string, index: number,
		loadsInput: HTMLInputElement) {
		const loads = +weightPer > 0 ? Math.ceil(+qty / +weightPer) : 0;
		loadsInput.value = `${loads}`;
		loadsInput.disabled = true;
		this.getRequestGroup(index).get('requiredLoads').setValue(loads);
		const line = this.selectedContractLine[index] as ContractLineVmExtended;
		this.checkTargets(+qty, +loads, line);
	}

	checkTargets(weight: number, loads: number, line: ContractLineVmExtended) {
		if (line.balanceWeight && weight > line.balanceWeight) {
			line.weightWarning = `The weight: ${weight} exceeds the balance weight: ${line.balanceWeight}`;
		} else {
			line.weightWarning = undefined;
		}

		if (line.balanceLoads && loads > line.balanceLoads) {
			line.loadsWarning = `The loads: ${loads} exceeds the balance loads: ${line.balanceLoads}`;
		} else {
			line.loadsWarning = undefined;
		}
	}

	resetInputs(qtyInput: HTMLInputElement, loadsInput: HTMLInputElement, weightPerInput: HTMLInputElement, index: number, request: any) {
		this.calculateRequired(request).subscribe(c => {
			loadsInput.disabled = false;
			weightPerInput.disabled = false;
			qtyInput.value = `${c.requiredWeight}`;
			loadsInput.value = `${c.requiredLoads}`;
			weightPerInput.value = `${c.requiredWeightPerLoad}`;
			this.getRequestGroup(index).get('requiredWeight').setValue(c.requiredWeight);
			this.getRequestGroup(index).get('requiredLoads').setValue(c.requiredLoads);
			this.getRequestGroup(index).get('requiredWeightPerLoad').setValue(c.requiredWeightPerLoad);
		});
	}

	getRequestGroup(index: number): FormGroup {
		const requestsArray = this.allocationForm.get('requests') as FormArray;
		return requestsArray.controls[index] as FormGroup;
	}

	selectContractLine(line: ContractLineViewModel, index: number) {
		// only allow select contract line if it's not a mixed load
		// and there is more than one contract line available
		this.selectedContractLine[index] = line;
	}

	selectContractType(event: any, index: number) {
		this.containerTypeId[index] = event.value;
	}

	contractLineClass(line: ContractLineViewModel, index: number) {
		// only allow select contract line if it's not a mixed load
		// and there is more than one contract line available
		if (this.data.contract.contractLines.length > 1) {
			if (this.selectedContractLine[index].contractLineId === line.contractLineId) {
				return 'selectable selected';
			}
			return 'selectable';
		}
	}

	autoMapGrades() {
		if (this.requestsToAllocate.length === 1
			&& this.contractLinesToAllocate.length === 1) {
			this.selectRequestGrade(this.requestsToAllocate[0]);
			this.selectContractLineGrade(this.contractLinesToAllocate[0]);
		}
	}

	selectRequestGrade(request: any) {
		this.selectedRequest = {
			requestId: request.requestId,
			requestGrade: request.grade,
			contractLineGrade: null
		};
	}

	requestGradeClass(grade: string) {
		return this.selectedRequest && this.selectedRequest.requestGrade === grade ? 'grade-line-selected' : 'grade-line';
	}

	selectContractLineGrade(grade: string) {
		this.selectedContractLineGrade = grade;
	}

	contractLineGradeClass(grade: string) {
		return this.selectedContractLineGrade === grade ? 'grade-line-selected' : 'grade-line';
	}

	selectAllocateLine(allocateGrade: AllocatedGrade) {
		this.selectedAllocate = allocateGrade;
	}

	allocateLineClass(allocate: AllocatedGrade) {
		return this.selectedAllocate === allocate ? 'grade-line-selected' : 'grade-line';
	}

	allocateGrades() {
		if (this.selectedRequest && this.selectedContractLineGrade) {
			this.selectedRequest.contractLineGrade = this.selectedContractLineGrade;
			const getSelectedRequest = this.requestsToAllocate.filter(u => u.grade === this.selectedRequest.requestGrade);
			if (getSelectedRequest.length === 1) {
				this.addAllocatedGrades(this.selectedRequest);
			}
			else {
				for (const req of getSelectedRequest) {
					const allocateRequest: AllocatedGrade = {
						requestId: req.requestId,
						requestGrade: req.grade,
						contractLineGrade: this.selectedContractLineGrade
					};
					this.addAllocatedGrades(allocateRequest);
				}
			}

			for (let i = 0; i < this.data.requests.length; i++) {
				this.selectedContractLine[i] = this.data.contract.contractLines
					.filter(c => c.grade === this.selectedContractLineGrade
						|| c.salesGrade === this.selectedContractLineGrade)[0];
			}

			this.selectedRequest = null;
			this.selectedContractLineGrade = null;
			this.initAllocatedRequests();
			this.tabGroup.realignInkBar();
		}
	}

	addAllocatedGrades(request: AllocatedGrade) {
		this.allocatedGrades.push(request);
		this.requestGradesToAllocate.splice(this.requestGradesToAllocate.indexOf(request.requestGrade), 1);
	}

	unallocateGrades() {
		if (this.selectedAllocate) {
			this.requestGradesToAllocate.push(this.selectedAllocate.requestGrade);
			this.allocatedGrades.splice(this.allocatedGrades.indexOf(this.selectedAllocate), 1);
			this.requestsToAllocate.find(u => u.grade === this.selectedAllocate.requestGrade).allocated = false;

			this.selectedAllocate = null;
			this.initAllocatedRequests();
		}
	}

	private initAllocatedRequests() {
		for (const mg of this.allocatedGrades) {
			const request = this.data.requests.filter(r => r.requestId === mg.requestId)[0];
			const requestsFormArray = this.allocationForm.controls.requests as FormArray;
			const requestFormGroup = requestsFormArray.controls.filter(c => c.get('requestId').value === mg.requestId)[0] as FormGroup;
			requestFormGroup.controls.contractLines = this.fb.array(this.initContractLines(mg.requestGrade));

			this.calculateRequired(request).subscribe(calc => {
				requestFormGroup.controls.requiredWeight.patchValue(calc.requiredWeight);
				requestFormGroup.controls.requiredLoads.patchValue(calc.requiredLoads);
				requestFormGroup.controls.requiredWeightPerLoad.patchValue(calc.requiredWeightPerLoad);
				requestFormGroup.controls.requiredWeightUomId.patchValue(calc.contractUomId);
				request.convertedWeight = calc.convertedWeight;
			});
		}
	}

	private calculateRequired(request: AllocateRequestVmExtended): Observable<AllocateRequestWeightLoads> {
		const contractUomId = this.getContractLines(request.grade)[0].weightUomId;
		const requestUomId = request.remainingWeightUomId;

		//if the request has a Uom and the Uom is not the same as the contract line, get the conversion rate, otherwise assume 1
		const obs: Observable<number> = (!isNullOrUndefined(requestUomId) && contractUomId !== requestUomId) ? this.uomsService.getConversionFactor(request.remainingWeightUomId, contractUomId) : of(1);

		return obs.pipe(map(c => {
			const weightloads: AllocateRequestWeightLoads = { contractUomId: contractUomId };
			if (isNullOrUndefined(request.remainingWeightUomId)) {
				return weightloads;
			}
			weightloads.convertedWeight = request.remainingWeight * c;
			const index = this.data.requests.indexOf(request);
			request.takenQty = this.getBalanceForGrade(request.grade, weightloads.convertedWeight, index);
			weightloads.requiredWeight = this.roundWeight(request.takenQty);
			const maxRequiredWeightPerLoad = request.remainingLoads > 0 ? this.roundWeight(weightloads.convertedWeight) / request.remainingLoads : 0;
			weightloads.requiredLoads = maxRequiredWeightPerLoad > 0 ? Math.ceil(weightloads.requiredWeight / maxRequiredWeightPerLoad) : 0;
			weightloads.requiredWeightPerLoad = weightloads.requiredLoads > 0 ? weightloads.requiredWeight / weightloads.requiredLoads : 0;

			return weightloads;
		}));
	}

	selectAppointment(value: any) {

		this.allocationForm.controls.customerAptRef.reset();
		this.allocationForm.controls.appointmentComment.reset();
		this.allocationForm.controls.customerAptDateTime.reset();
		this.allocationForm.controls.customerAptToDateTime.reset();

		if (value > 0) {

			const appointmentDetails = this.appointments.find(a => a.appointmentId === value);
			const startDate = moment.utc(appointmentDetails.startDate);
			const endDate = moment.utc(appointmentDetails.endDate);
			this.allocationForm.controls.customerAptDateTime.setValue(startDate);
			this.allocationForm.controls.customerAptToDateTime.setValue(endDate);
			this.allocationForm.controls.customerAptRef.setValue(appointmentDetails.deliveryNumber);

			this.allocationForm.controls.customerAptRef.disable();
			this.allocationForm.controls.appointmentComment.disable();
			this.allocationForm.controls.customerAptDateTime.disable();
			this.allocationForm.controls.customerAptToDateTime.disable();
		} else {
			this.allocationForm.controls.customerAptRef.enable();
			this.allocationForm.controls.appointmentComment.enable();
			this.allocationForm.controls.customerAptDateTime.enable();
			this.allocationForm.controls.customerAptToDateTime.enable();
		}
	}



	private getBalanceForGrade(grade: string, lineAmt: number, index: number): number {
		let balance = 0;
		if (this.data.requests.length > 1) {
			// there is more than one request so there's more than one grade
			// we need to find the correct balance
			const lines = this.getContractLines(grade);
			if (isNullOrUndefined(lines[0])) {
				balance = 0;
			} else {
				balance = lines[0].balanceWeight;
			}
		} else {
			// there's only one request and therefore only one grade
			// we need to pick the selected contract line just in case there's more than one
			balance = this.selectedContractLine[index].balanceWeight;
		}
		return balance < lineAmt ? balance : lineAmt;
	}

	showBulkAllocateMode(event: any) {
		const ctrls = this.allocationForm.controls.allocationDetail;
		if (event.checked) {
			this.bulkAllocate = true;

			for (let requestIndex = 0; requestIndex < this.data.requests.length; requestIndex++) {
				this.bulkAllocationMode[requestIndex] = 'date';
				this.pupulatePlaceholderText(requestIndex);
			}
		}
		else {
			this.bulkAllocate = false;
			this.setBulkAllocationValidation(ctrls, false);
		}

		if (!this.bulkAllocate && !this.isMixedGradeContract)
			this.minRequiredLoad = 1;
	}

	private addBulkAllocationFormGroup(i: number, arr: any) {
		this.pupulatePlaceholderText(i);
		const groupArr: FormGroup[] = [];
		for (const a of arr) {
			const grp = this.fb.group({
				pickupDateTime: null,
				requiredLoads: null,
				loadTimes: this.fb.array([])
			});
			groupArr.push(grp);
		}
		return groupArr;
	}

	selectAllocationMode(allocationIndex: number, event: any) {
		this.bulkAllocationMode[allocationIndex] = event.value;
		const ctrls = this.allocationForm.controls.allocationDetail;
		if (this.bulkAllocationMode[allocationIndex] == 'time') {
			this.setBulkAllocationValidation(ctrls, true);
		}
		else {
			this.setBulkAllocationValidation(ctrls, false);
		}
	}

	setBulkAllocationValidation(ctrls: any, validate: boolean) {
		for (const c of ctrls.controls) {
			if (validate) {
				c.controls.bulkAllocationPickupDate.setValidators([Validators.required]);
				c.controls.bulkAllocationPickupDate.updateValueAndValidity();
				c.controls.bulkAllocationRequiredLoads.setValidators([Validators.required]);
				c.controls.bulkAllocationRequiredLoads.updateValueAndValidity();
			}
			else {
				c.controls.bulkAllocationPickupDate.clearValidators();
				c.controls.bulkAllocationPickupDate.updateValueAndValidity();
				c.controls.bulkAllocationRequiredLoads.clearValidators();
				c.controls.bulkAllocationRequiredLoads.updateValueAndValidity();
			}
		}
	}

	/********OLD Implementation**************/
	// pupulatePlaceholderText() {
	//   if (this.datePlaceholder.length > 0)
	//     return;
	//   var dt = new Date();
	//   var i = 0;
	//   while (i < 14) {
	//     var d = dt.setDate(dt.getDate() + (i > 0 ? 1 : 0));
	//     this.datePlaceholder.push(moment(d).format('DD-MMM'));
	//     i++;
	//   }

	//   i = 0;
	//   var date = new Date();
	//   var day = date.getDay();
	//   var diff = date.getDate() - day + (day == 0 ? -6 : 1);

	//   if (new Date(date.setDate(diff)) < new Date())
	//     date = new Date(date.setDate(diff + 7));
	//   else
	//     date = new Date(date.setDate(diff));

	//   while (i < 6) {
	//     var d = date.setDate(date.getDate() + (i > 0 ? 7 : 0));
	//     if (i == 0) {
	//       this.firstMonday = moment(d).format("YYYY-MM-DD");
	//     }
	//     this.weekPlaceholder.push(moment(d).format('DD-MMM'));
	//     i++;
	//   }
	// }

	pupulatePlaceholderText(requestIndex: number) {
		if (this.datePlaceholder[requestIndex].length > 0)
			return;

		let i = 0;
		const date = new Date();
		const day = date.getDay();
		const diff = date.getDate() - day + (day == 0 ? -6 : 1);
		const dt = new Date(date.setDate(diff));

		while (i < 7) {
			const d = dt.setDate(dt.getDate() + (i > 0 ? 1 : 0));
			this.datePlaceholder[requestIndex][i] = moment(d).toDate().toString();
			i++;
		}
		this.groupDaysCount[requestIndex][0] = ((this.datePlaceholder[requestIndex].length / 7) - 1);
		i = 0;
		while (i < 7) {
			const d = date.setDate(date.getDate() + (i > 0 ? 7 : 0));
			if (i == 0) {
				this.firstMonday = moment(d).toDate().toString();
			}
			this.weekPlaceholder[requestIndex][i] = moment(d).toDate().toString();
			i++;
		}
		this.groupWeeksCount[requestIndex][0] = ((this.weekPlaceholder[requestIndex].length / 7) - 1);
	}

	private addRemoveLoadTimes(frmArray: FormArray) {
		for (let index = 0; index < frmArray.length; index++) {
			const group = frmArray.controls[index] as FormGroup;
			const requiredLoad = group.get('requiredLoads').value;
			const loadTimes = group.get('loadTimes') as FormArray;
			const totalLoadTime = loadTimes.length;

			if (requiredLoad && totalLoadTime != requiredLoad) {
				if (requiredLoad < totalLoadTime) {
					loadTimes.controls.splice(requiredLoad - 1, totalLoadTime - requiredLoad);
				}
				else {
					for (let i = 0; i < requiredLoad - totalLoadTime; i++) {
						loadTimes.push(this.fb.group({ loadTime: null, increment: null }));
					}
				}
			}

		}
	}
	addDatesToDatePlaceholder(allocationIndex: number) {
		let dt = new Date();
		if (this.datePlaceholder.length <= 0) {
			const date = new Date();
			const day = date.getDay();
			const diff = date.getDate() - (day + 1) + (day == 0 ? -6 : 1);
			dt = new Date(date.setDate(diff));
		} else {
			dt = new Date(this.datePlaceholder[allocationIndex][this.datePlaceholder[allocationIndex].length - 1]);
		}
		const frmArray1 = this.allocationForm.controls.allocationDetail as FormArray;
		const frmGroup = frmArray1.controls[allocationIndex] as FormGroup;
		const frmArray = frmGroup.get('loadsByDate') as FormArray;
		let datePlaceHolderLength = this.datePlaceholder[allocationIndex].length;
		const groupDaysLength = this.groupDaysCount[allocationIndex].length;
		let i = 0;
		while (i < 7) {
			const d = dt.setDate(dt.getDate() + (i > 0 ? 1 : 1));
			const grp = this.fb.group({
				'requiredLoads': '',
				'pickupDateTime': '',
				'loadTimes': this.fb.array([])

			});
			frmArray.push(grp);
			this.datePlaceholder[allocationIndex][datePlaceHolderLength] = moment(d).toDate().toString();
			i++;
			datePlaceHolderLength++;
		}
		this.groupDaysCount[allocationIndex][groupDaysLength] = (this.datePlaceholder[allocationIndex].length / 7) - 1;
	}

	removeDatesFromDatePlaceholder(allocationIndex: number) {
		const frmArray1 = this.allocationForm.controls.allocationDetail as FormArray;
		const frmGroup = frmArray1.controls[allocationIndex] as FormGroup;
		const frmArray = frmGroup.get('loadsByDate') as FormArray;
		if (frmArray.length <= 0)
			return;

		let i = 6;
		while (i >= 0) {
			frmArray.removeAt(frmArray.length - i);
			this.datePlaceholder[allocationIndex].pop();
			i--;
		}
		this.groupDaysCount[allocationIndex].pop();
	}

	addWeeksToWeekPlaceholder(allocationIndex) {
		let dt = new Date();
		if (this.weekPlaceholder.length <= 0) {
			const date = new Date();
			const day = date.getDay();
			const diff = date.getDate() - (day + 1) + (day == 0 ? -6 : 1);
			dt = new Date(date.setDate(diff));
		} else {
			dt = new Date(this.weekPlaceholder[allocationIndex][this.weekPlaceholder[allocationIndex].length - 1]);
		}
		const frmArray1 = this.allocationForm.controls.allocationDetail as FormArray;
		const frmGroup = frmArray1.controls[allocationIndex] as FormGroup;
		const frmArray = frmGroup.get('loadsByWeek') as FormArray;

		let weekPlaceHolderLength = this.weekPlaceholder[allocationIndex].length;
		const groupWeeksLength = this.groupWeeksCount[allocationIndex].length;

		let i = 0;
		while (i < 7) {
			const d = dt.setDate(dt.getDate() + 7);
			const grp = this.fb.group({
				'requiredLoads': '',
				'pickupDateTime': '',
				'loadTimes': this.fb.array([])

			});
			frmArray.push(grp);
			this.weekPlaceholder[allocationIndex][weekPlaceHolderLength] = moment(d).toDate().toString();
			weekPlaceHolderLength++;
			i++;
		}
		this.groupWeeksCount[allocationIndex][groupWeeksLength] = ((this.weekPlaceholder[allocationIndex].length / 7) - 1);
	}

	removeWeeksFromWeekPlaceHolder(allocationIndex) {
		const frmArray1 = this.allocationForm.controls.allocationDetail as FormArray;
		const frmGroup = frmArray1.controls[allocationIndex] as FormGroup;
		const frmArray = frmGroup.get('loadsByWeek') as FormArray;

		if (frmArray.length <= 0)
			return;

		let i = 6;
		while (i >= 0) {
			frmArray.removeAt(frmArray.length - i);
			this.weekPlaceholder[allocationIndex].pop();
			i--;
		}
		this.groupWeeksCount[allocationIndex].pop();
	}
	hideLoadTimes(requestIndex: number) {
		if (!this.clockClicked && requestIndex != null)
			this.shownLoadTimes[requestIndex] = undefined;
		else {
			if (!this.clockClicked && requestIndex == null) {
				for (let i = 0; i < this.data.requests.length; i++) {
					this.shownLoadTimes[i] = undefined;
				}
			}
			this.clockClicked = false;
		}
	}

	showHideLoadTimes(requestIndex: number, index: number, event: MouseEvent) {
		this.clockClicked = true;
		if (this.shownLoadTimes[requestIndex] === index) {
			this.shownLoadTimes[requestIndex] = undefined;
		} else {
			this.shownLoadTimes[requestIndex] = index;
		}
		this.loadTimesStyle = {
			top: `${event.clientY + 17}px`,
			left: `${event.clientX - 82}px`,
		};
	}
	hiddenLoadTimes(requestIndex: number, index: number) {
		return this.shownLoadTimes[requestIndex] !== index;
	}

	applyLoadTimesToAll(allocationIndex) {
		const frmArray1 = this.allocationForm.controls.allocationDetail as FormArray;
		const frmGroup = frmArray1.controls[allocationIndex] as FormGroup;
		const frmArray = frmGroup.get('loadsByDate') as FormArray;
		let i = 1;
		const firstLoadTime = frmArray.controls[0].get('loadTimes').value;
		const firstLoad = frmArray.controls[0].get('requiredLoads').value;

		while (i < frmArray.length) {
			frmArray.controls[i].patchValue({ 'requiredLoads': firstLoad });
			frmArray.controls[i].patchValue({ 'loadTimes': firstLoadTime });
			i++;
		}
		this.shownLoadTimes[allocationIndex] = undefined;
		this.hideLoadTimes(allocationIndex);
	}
	getLoadTimes(allocationIndex: number, index: number, loadType: string): AbstractControl[] {
		const frmArray1 = this.allocationForm.controls.allocationDetail as FormArray;
		const frmGroup = frmArray1.controls[allocationIndex] as FormGroup;
		const ld = frmGroup.get(loadType) as FormArray;
		const loadTime = ld.controls[index] as FormGroup;
		if (loadTime != null) {
			const requireLoad = loadTime.get('requiredLoads') as FormGroup;
			if (requireLoad != null && requireLoad != undefined) {
				if (requireLoad.value == null)
					return [];
			}
		}
		if (ld && ld.length > 0) {
			const loadDate = ld.controls[index] as FormGroup;
			if (loadDate) {
				const loadTimes = loadDate.get('loadTimes') as FormArray;
				return loadTimes.controls;
			}
		}
		return [];
	}
	populateControls(event: any) {
		this.timePlaceholder = new Array<string>(+event.target.value);
		const ctrls: any = this.allocationForm.controls.allocationDetail;
		for (const c of ctrls.controls) {
			const arr = c.controls.loadsByTime;
			if (this.timePlaceholder.length < arr.length) {
				while (this.timePlaceholder.length < arr.length) {
					arr.removeAt(arr.length - 1);
				}
			}
			while (this.timePlaceholder.length != arr.length) {
				const grp = this.fb.group({
					pickupTime: null,
					pickupDateTime: null,
					loadTimes: this.fb.array([])
				});
				arr.push(grp);
			}
		}
	}
	setIncrements(allocationIndex: number, materialIndex: number, increment: string, loadType: string) {
		const frmArray1 = this.allocationForm.controls.allocationDetail as FormArray;
		const frmGroup = frmArray1.controls[allocationIndex] as FormGroup;
		let frmArray: FormArray;
		if (loadType === 'byDates') {
			frmArray = frmGroup.get('loadsByDate') as FormArray;
		}
		else
			frmArray = frmGroup.get('loadsByWeek') as FormArray;

		const incrementIndex = this.increments.indexOf(increment);
		if (incrementIndex > -1) {
			const minutesToAdd = this.incrementMinutes[incrementIndex];
			const ctrl = this.getFirstLoadTimeValue(frmArray, materialIndex);
			const originalTime = ctrl.controls[0].get('loadTime').value;
			const originalMoment = moment.utc(originalTime, 'HH:mm');

			ctrl.controls.forEach((control, index) => {
				if (index == 0) //skip first one since that's the value we're basing increments off
					return;
				const incrementedTime = originalMoment.add(minutesToAdd, 'minutes').format('HH:mm');
				control.patchValue({ loadTime: incrementedTime });
			});
		}
	}
	calculateLoadsCount() {
		if (this.isMixedGradeContract) {
			return 1;
		} else {
			const result = (this.allocationForm.controls.requests as any).controls.map(control => control.value.requiredLoads)
				.reduce((sum, current) => sum + current, 0);
			return result;
		}
	}
	getFirstLoadTimeValue(frmArray: FormArray, index) {
		const group = frmArray.controls[index] as FormGroup;
		const loadTimes = group.get('loadTimes') as FormArray;
		return loadTimes;
	}
}

interface AllocatedGrade {
	requestId: number;
	requestGrade: string;
	contractLineGrade: string;
}

interface AllocateRequestVmExtended extends AllocateRequestVm {
	takenQty: number;
}
