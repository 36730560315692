<div class="party-detail">
	<div>Allocation Details {{(data?.allocate?.appointmentId) ? ' - ' + data.allocate.appointmentId : ''}}</div>
	<div>Contract ref: <span>{{data.allocate.contractRef}}</span></div>
</div>
<div class="allocationForm">
	<div class="dialog-input-container">
		<mat-grid-list cols="8" rowHeight="50px">
			<mat-grid-tile colspan="2">
				<mat-label style="font-weight: bold;">Allocation No:</mat-label>
			</mat-grid-tile>
			<mat-grid-tile colspan="2">
				<div class="mat-grid-tile-content-wrapper">
					<span class="full-width">{{data.allocate.contractLineAllocationId}}</span>
				</div>
			</mat-grid-tile>
			<mat-grid-tile colspan="2">
				<mat-label style="font-weight: bold;">Depot No:</mat-label>
			</mat-grid-tile>
			<mat-grid-tile colspan="2">
				<div class="mat-grid-tile-content-wrapper">
					<span class="full-width">{{data.allocate.depotNo}}</span>
				</div>
			</mat-grid-tile>

			<mat-grid-tile colspan="2">
				<mat-label style="font-weight: bold;">Haulier Name:</mat-label>
			</mat-grid-tile>
			<mat-grid-tile colspan="2">
				<div class="mat-grid-tile-content-wrapper">
					<span class="full-width">{{data.allocate.haulierName}}</span>
				</div>
			</mat-grid-tile>
			<mat-grid-tile colspan="2">
				<mat-label style="font-weight: bold;">Party Name:</mat-label>
			</mat-grid-tile>
			<mat-grid-tile colspan="2">
				<div class="mat-grid-tile-content-wrapper">
					<span class="full-width">{{data.allocate.partyName}}</span>
				</div>
			</mat-grid-tile>

			<mat-grid-tile colspan="2">
				<mat-label style="font-weight: bold;">Pickup Date:</mat-label>
			</mat-grid-tile>
			<mat-grid-tile colspan="2">
				<div class="mat-grid-tile-content-wrapper">
					<span class="full-width">
						{{displayDate(data?.allocate?.pickupDateTime)}}
					</span>
				</div>
			</mat-grid-tile>
			<mat-grid-tile colspan="2">
				<mat-label style="font-weight: bold;">Pickup To Date:</mat-label>
			</mat-grid-tile>
			<mat-grid-tile colspan="2">
				<div class="mat-grid-tile-content-wrapper">
					<span class="full-width">
						{{displayDate(data?.allocate?.pickupToDateTime)}}
					</span>
				</div>
			</mat-grid-tile>

		</mat-grid-list>
	</div>

</div>
<div mat-dialog-actions class="buttons-row">
	<button mat-flat-button type="button" (click)="cancel()">Close</button>
</div>