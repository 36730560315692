import {ErrorHandler, NgModule} from '@angular/core';
import {ErrorService} from './error-service';
import {ConfigService} from './config-service';
import {BrowserUtilsService} from './browser-utils.service';


@NgModule({
	providers: [
		ErrorService,
		ConfigService,
		BrowserUtilsService,
		// {provide: ErrorHandler, useClass: JsErrorLoggingService}
	]
})

export class ServicesModule {
}

