<div class="dialog-header">{{title}}</div>
<app-spinner [displayProgressSpinner]="loadingNewAppointmentDialog" [container]="loadingNewAppointmentDialogBox"
    #loadingNewAppointmentDialogSpinner>
  </app-spinner>
<div aria-label="Appointment" class="dialog-body" #loadingNewAppointmentDialogBox>
    <form [formGroup]="newAppointmentForm" (ngSubmit)="ok()">
        <div>
            <app-autocomplete class="dialog-input-container" formControlName="customer" autoCompleteDelay="750"
                [displayWith]="displayCustomer" [tooltip]="displayCustomer" [getResults]="partiesService.get"
                (selectionChange)="loadContract($event)" placeholder="Customer">
            </app-autocomplete>
            <mat-form-field class="dialog-input-container" flex>
                <mat-select formControlName="contract" placeholder="Contract" [(ngModel)]="dbContractHeaderId">
                    <mat-option *ngFor="let con of contracts" [value]="con.contractHeaderId">{{ con?.contractRef }} - {{
                        con?.customerContractRef }}
                    </mat-option>
                </mat-select>
            </mat-form-field>


            <mat-form-field class="dialog-input-container" flex>
                <input matInput formControlName="deliveryNumber" placeholder="Delivery Number" required>
                <mat-spinner [diameter]="20" [strokeWidth]="2" matSuffix *ngIf="loadingReferenceUnique"
                    [color]="spinColor"></mat-spinner>
                <mat-error *ngIf="deliveryNumber.touched && deliveryNumber.errors?.nonUnique">Delivery Number must be
                    unique
                </mat-error>
                <mat-error *ngIf="deliveryNumber.touched && deliveryNumber.errors?.required">This is required!
                </mat-error>
            </mat-form-field>
            <div class="dates-wrapper">
                <app-date-time-picker placeholder="Customer Apt From Date & Time" formControlName="customerAptDateTime">
                </app-date-time-picker>
                <app-date-time-picker placeholder="Customer Apt To Date & Time" formControlName="customerAptToDateTime">
                </app-date-time-picker>
            </div>
            <mat-form-field class="dialog-input-container" flex *ngIf="editMode">
                <mat-select formControlName="missedCategory" placeholder="Missed Category"
                    [(ngModel)]="dbMissedCategory">
                    <mat-option *ngFor="let mis of missedCategories" [value]="mis.id">{{ mis?.name }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="dialog-input-container" flex>
                <textarea matInput formControlName="comments" placeholder="Provide a comment (optional)"
                    mat-maxlength="800" rows="3"></textarea>
            </mat-form-field>
        </div>
        <div mat-dialog-actions layout="row" class="dialog-flex-justify-center dialog-margin-bottom-10">
            <button mat-flat-button (click)="cancel()" type="button" class="md-raised md-ink-ripple">Cancel</button>
            <button mat-flat-button ng-disabled="newAppointmentForm.$invalid || !isReferenceNumberUnique" type="submit"
                color="primary" class="md-raised md-primary md-ink-ripple" [disabled]="isSubmit">Submit</button>
        </div>
        <div layout="row">
            <a href="assets/Import_Appointments.xlsx" style="font-size:8pt">Download template</a>
        </div>
    </form>
</div>