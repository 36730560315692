<mat-card layout="column" style="padding: 1px;padding-top:0px;">
    <div id="content">
        <mat-toolbar class="md-hue-3" style="background-color:#eceff1;height: 37px;">
            <div class="md-toolbar-tools" style="color:rgb(46, 73, 97);">
                <button mat-button (click)="showSearch = !showSearch">
                    <i class="fa fa-search"></i>
                </button>
                <button mat-button class="md-raised" (click)="newAppointment()">New</button>
                <button mat-button class="md-raised" [disabled]="!enableEdit" (click)="editAppointment()">Edit</button>
                <button mat-button class="md-raised" (click)="importAppointment()">Import</button>
                <button mat-button class="md-raised" [disabled]="!enableEdit"
                    (click)="deleteAppointment()">Delete</button>
                <button mat-button class="md-raised" [disabled]="!enablePrint"
                    (click)="printAppointment()">Print</button>
                <button mat-button class="md-raised" (click)="exportToExcel()">Export</button>
            </div>
        </mat-toolbar>
        <div [hidden]="!showSearch" class="search-box">
            <form [formGroup]="searchForm" (ngSubmit)="search(searchForm.value)">
                <div class="row-container searchPanel">
                    <mat-form-field mat-no-float>
                        <input matInput type="search" placeholder="Customer" formControlName="customer"
                               oninput="this.value = this.value.toUpperCase()">
                    </mat-form-field>
                    <mat-form-field mat-no-float>
                        <input matInput type="search" placeholder="Contract" formControlName="contractRef"
                               oninput="this.value = this.value.toUpperCase()">
                    </mat-form-field>
                    <mat-form-field mat-no-float>
                        <input matInput type="search" placeholder="Grade"
                            formControlName="grade">
                    </mat-form-field>
                    <mat-form-field mat-no-float>
                        <input matInput type="search" placeholder="Material Description"
                            formControlName="materialDescription">
                    </mat-form-field>
                    <mat-form-field mat-no-float>
                        <input matInput type="search" placeholder="Delivery Number" formControlName="deliveryNumber">
                    </mat-form-field>
                    <mat-form-field mat-no-float>
                        <input matInput type="search" placeholder="Start Date" formControlName="startDate">
                    </mat-form-field>
                    <mat-form-field mat-no-float>
                        <input matInput type="search" placeholder="Delivery Window Start"
                            formControlName="deliveryWindowStart">
                    </mat-form-field>
                    <mat-form-field mat-no-float>
                        <input matInput type="search" placeholder="End Date" formControlName="endDate">
                    </mat-form-field>
                    <mat-form-field mat-no-float>
                        <input matInput type="search" placeholder="Delivery Window End"
                            formControlName="deliveryWindowEnd">
                    </mat-form-field>
                    <mat-form-field mat-no-float>
                        <input matInput type="search" placeholder="Comments" formControlName="comments">
                    </mat-form-field>
                    <mat-form-field mat-no-float>
                          <mat-select placeholder="Status" formControlName="status">
                            <mat-option value="New">New</mat-option>
                            <mat-option value="Used">Used</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-checkbox style="margin-top: 20px;" formControlName="readyToPrint"
                        matTooltip="Show all allocated appointments with Haulier to print">Print</mat-checkbox>
                    <div class="button-container" style="margin-top: 15px;margin-left: 5px;">
                        <button mat-stroked-button type="submit">Apply Filter</button>
                    </div>
                    <div class="button-container" style="margin-top: 15px;margin-left: 10px;">
                        <button mat-stroked-button (click)="clearFilter()" type="button">Clear Filters</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="table-responsive">
            <app-spinner [displayProgressSpinner]="isLoading" [container]="mainTable" #spinner></app-spinner>
            <div style="width: 100%;overflow-y: auto;min-height: 800px" #mainTable>
                <table name="appointmentstable" id="appointments-table" cellpadding="0" cellspacing="0"
                    class="table table-hover table-condensed custom-table" width="100%">
                    <thead>
                        <tr class="heading-row">
                            <th style="width: 30px"></th>
                            <th style="width: 250px;" appTableSort="Customer" (sort)="sortByInfo($event)"
                                class="interactive hide-sm hide-xs">Customer</th>
                            <th style="width: 30px"></th>
                            <th style="width: 130px;" appTableSort="ContractRef" (sort)="sortByInfo($event)"
                                class="interactive hide-sm hide-xs">Contract Ref</th>
                            <th style="width: 250px;" appTableSort="Grade" (sort)="sortByInfo($event)"
                                class="interactive hide-sm hide-xs">Grade</th>
                            <th style="width: 0px;"></th>
                            <th style="width: 300px;" appTableSort="MaterialDescription" (sort)="sortByInfo($event)"
                                class="interactive hide-sm hide-xs">Material Description</th>
                            <th style="width: 100px;" appTableSort="DeliveryNumber" (sort)="sortByInfo($event)"
                                class="interactive hide-sm hide-xs">Delivery Number</th>
                            <th style="width: 120px;" appTableSort="StartDate" (sort)="sortByInfo($event)"
                                class="interactive hide-sm hide-xs">Delivery Start Date</th>
                            <th style="width: 120px;" appTableSort="DeliveryWindowStart" (sort)="sortByInfo($event)"
                                class="interactive hide-sm hide-xs">Delivery Window Start</th>
                            <th style="width: 120px;" appTableSort="EndDate" (sort)="sortByInfo($event)"
                                class="interactive hide-sm hide-xs">Delivery End Date</th>
                            <th style="width: 120px;" appTableSort="DeliveryWindowEnd" (sort)="sortByInfo($event)"
                                class="interactive hide-sm hide-xs">Delivery Window End</th>
                            <th appTableSort="Comments" (sort)="sortByInfo($event)" class="interactive hide-sm hide-xs">
                                Comments</th>
                            <th style="width: 120px;" appTableSort="Status" (sort)="sortByInfo($event)"
                                class="interactive hide-sm hide-xs">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="!isLoading && appointments.length == 0">
                            <td colspan="9">
                                <mat-label>No records found!</mat-label>
                            </td>
                        </tr>
                        <ng-template ngFor let-item [ngForOf]="appointments" *ngIf="appointments.length > 0">
                            <tr style="height: 36px;" (click)="selectAppointment(item, $event)"
                                [ngClass]="{ 'selected' : item.isSelected, 'allocated': item.contractLineAllocationId > 0 }">
                                <td style="width: 30px; padding-left: 10px">
                                    <span *ngIf="item.print" (click)="printAppointment(item.contractLineAllocationId)">
                                        <i class="fa fa-print"></i>
                                    </span>
                                </td>
                                <td hide-sm hide-xs>{{item.customer}}</td>
                                <td>
                                    <span *ngIf="item.contractLineAllocationId > 0"
                                        [matTooltip]="'Allocation No.:' + item.contractLineAllocationId">
                                        <i class="fa fa-print"></i>
                                    </span>
                                </td>
                                <td hide-sm hide-xs>{{item.contractRef}}</td>
                                <td matTooltip="{{item.grade}}" class="textoverflow">
                                        {{item.grade}}</td>
                                <th style="width: 0px;"></th>
                                <td matTooltip="{{item.materialDescription}}" class="textoverflow">
                                        {{item.materialDescription}}</td>
                                <td hide-sm hide-xs>{{item.deliveryNumber}}</td>
                                <td hide-sm hide-xs>{{item.startDate | momentUtcAsLocaleDate}}</td>
                                <td hide-sm hide-xs>{{item.deliveryWindowStart | timeFormat}}</td>
                                <td hide-sm hide-xs>{{item.endDate | momentUtcAsLocaleDate}}</td>
                                <td hide-sm hide-xs>{{item.deliveryWindowEnd | timeFormat}}</td>
                                <td matTooltip="{{item.comments}}" class="textoverflow">{{item.comments}}</td>
                                <td hide-sm hide-xs>{{item.status}}</td>
                            </tr>
                        </ng-template>
                    </tbody>
                </table>
            </div>
            <table style="width: 100%;" *ngIf="appointments.length > 0">
                <tfoot>
                    <tr>
                        <td style="text-align: center;">
                            <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                                (pageChange)="pageChanged($event)" aria-label="Default pagination"></ngb-pagination>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</mat-card>
