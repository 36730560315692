import { DepotVm } from '../models';
import { TerritoryVm } from '../models/TerritoryVm';
import { UserPreferencesVm } from '../models/UserPreferencesVm';
import { UserInfoVm } from '../models/UserInfoVm';
import { OrderBookTypeVm } from '../models/OrderBookTypeVm';
import { YardViewModel } from '../models/YardViewModel';
import { UserPreferenceTableEnum, UserTableViewVm } from '../models/UserTableViewVm';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { RestConfigService, REST_CONFIG_TOKEN } from '../rest-config-service';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from 'src/app/tools';

@Injectable()
export class UserPreferencesService {
	constructor(
        private _http: HttpClient,
        @Inject(REST_CONFIG_TOKEN) private _configService: RestConfigService
	) {
	}
	public getUserInfo: (
    ) => Observable<UserInfoVm>
		= (
		): Observable<UserInfoVm> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
			const uri = '/api/UserPreferences/userinfo';

			return this._http
				.get<UserInfoVm>(this._configService.apiBaseUrl() + uri,
					{ headers: headers, params: queryParameters });
		};
	public getDepot: (
        filter?: string
    ) => Observable<DepotVm[]>
		= (
			filter?: string
		): Observable<DepotVm[]> => {
			let queryParameters = new HttpParams();
			const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

			if (!isNullOrUndefined(filter)) {
				queryParameters = queryParameters.append('filter', filter.toString());
			}

			const uri = '/api/UserPreferences/depot';

			return this._http
				.get<DepotVm[]>(this._configService.apiBaseUrl() + uri,
					{ headers: headers, params: queryParameters });
		};
	public getDefaultYard: (
    ) => Observable<YardViewModel>
		= (
		): Observable<YardViewModel> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

			const uri = '/api/UserPreferences/defaultyard';

			return this._http
				.get<YardViewModel>(this._configService.apiBaseUrl() + uri,
					{ headers: headers, params: queryParameters });
		};
	public getTerritory: (
        territoryCode?: string,
    ) => Observable<TerritoryVm[]>
		= (
			territoryCode?: string,
		): Observable<TerritoryVm[]> => {
			let queryParameters = new HttpParams();
			const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

			if (!isNullOrUndefined(territoryCode)) {
				queryParameters = queryParameters.append('territoryCode', territoryCode.toString());
			}

			const uri = '/api/UserPreferences/territory';

			return this._http
				.get<TerritoryVm[]>(this._configService.apiBaseUrl() + uri,
					{ headers: headers, params: queryParameters });
		};
	public getUserPreference: (
    ) => Observable<UserPreferencesVm[]>
		= (
		): Observable<UserPreferencesVm[]> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

			const uri = '/api/UserPreferences/get';

			return this._http
				.get<UserPreferencesVm[]>(this._configService.apiBaseUrl() + uri,
					{ headers: headers, params: queryParameters });
		};
	public updateUserPreferences: (
        model?: UserPreferencesVm
    ) => Observable<boolean>
		= (
			model?: UserPreferencesVm
		): Observable<boolean> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
			const uri = '/api/UserPreferences/update';
			return this._http
				.post<boolean>(this._configService.apiBaseUrl() + uri, JSON.stringify(model),
					{ headers: headers, params: queryParameters });
		};

	public updateViewMode: (
        model?: boolean
    ) => Observable<boolean>
		= (
			model?: boolean
		): Observable<boolean> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
			const uri = '/api/UserPreferences/updateViewMode';
			return this._http
				.post<boolean>(this._configService.apiBaseUrl() + uri, JSON.stringify(model),
					{ headers: headers, params: queryParameters });
		};

	public listOfOrderBookTypes: (
        filter?: string
    ) => Observable<OrderBookTypeVm[]>
		= (
			filter?: string
		): Observable<OrderBookTypeVm[]> => {
			let queryParameters = new HttpParams();
			const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

			if (!isNullOrUndefined(filter)) {
				queryParameters = queryParameters.append('filter', filter.toString());
			}

			const uri = '/api/UserPreferences/order-book-type';

			return this._http
				.get<OrderBookTypeVm[]>(this._configService.apiBaseUrl() + uri,
					{ headers: headers, params: queryParameters });
		};

	public getUserTableView: (
        userPreferenceTable?: UserPreferenceTableEnum
    ) => Observable<UserTableViewVm[]>
		= (
			userPreferenceTable?: UserPreferenceTableEnum
		): Observable<UserTableViewVm[]> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

			const uri = `/api/UserPreferences/user-table-view/${userPreferenceTable}`;

			return this._http
				.get<UserTableViewVm[]>(this._configService.apiBaseUrl() + uri,
					{ headers: headers, params: queryParameters });
		};


	public saveUserTableView: (
        model?: UserTableViewVm
    ) => Observable<boolean>
		= (
			model?: UserTableViewVm
		): Observable<boolean> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
			const uri = '/api/UserPreferences/addUserTableView';
			return this._http
				.post<boolean>(this._configService.apiBaseUrl() + uri, JSON.stringify(model),
					{ headers: headers, params: queryParameters });
		};

	public isUserPreferenceExists: (
                model?: UserTableViewVm
                ) => Observable<boolean>
		= (
			model?: UserTableViewVm
		): Observable<boolean> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
			const uri = '/api/UserPreferences/IsUserPreferenceExists';
			return this._http
				.post<boolean>(this._configService.apiBaseUrl() + uri, JSON.stringify(model),
					{ headers: headers, params: queryParameters });
		};

	public selectTableView: (
            viewId?: string
        ) => Observable<boolean>
		= (
			viewId?: string
		): Observable<boolean> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        
			const uri = `/api/UserPreferences/selectTableView/${viewId}`;
    
			return this._http
				.post<boolean>(this._configService.apiBaseUrl() + uri,
					{ headers: headers, params: queryParameters });
		};
}