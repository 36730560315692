import { Component } from '@angular/core';
import { Toast, ToastrService, ToastPackage} from 'ngx-toastr';
  
export interface IToastButton {
	id: string;
	title: string;
}
  
@Component({
    selector: '[custom-toast-component]',
    styleUrls: ['./custom-toast.component.scss'],
    templateUrl: './custom-toast.component.html',
})
export class CustomToastComponent extends Toast {

    constructor(
		protected toastrService: ToastrService,
		public toastPackage: ToastPackage,
    ) {
        super(toastrService, toastPackage);
    }

    action() {
        this.toastPackage.triggerAction();
        this.toastrService.clear();
        return false;
    }
}