<div class="content-container sidebar-form" style="max-width: 900px;margin-bottom:0;" id="attach-container">
    <mat-card layout-fill>
        <div class="md-hue-2" id="conversation-header">
            <div class="md-toolbar-tools sidebar-header">
                <h2 flex md-truncate>Attachments </h2>
                <button mat-button class="md-fab md-mini md-primary" aria-label="Add attachment" onclick="document.getElementById('fileUploadAttachments').click();">
                    <mat-icon>add_circle</mat-icon>
                </button>
            </div>
        </div>
        <div style="padding:0;width:95%;margin:10px auto;">
            <mat-card *ngFor="let a of attachments?.urls">
                <img [src]="a" class="md-card-image" alt="{{a}}">
                <mat-card-actions layout="row" layout-align="end center">
                    <a href="{{a}}" target="_blank" class="md-button">View</a>
                </mat-card-actions>
            </mat-card>
        </div>
        <mat-spinner *ngIf="loading"></mat-spinner>
        <mat-error *ngIf="uploadError" class="mat-error" role="alert">The file failed to upload</mat-error>
        <input type="file" id="fileUploadAttachments" style="display:none;" (change)="uploadFile($event.target.files)" accept="image/*" />
    </mat-card>
</div>
