import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {REST_CONFIG_TOKEN, RestConfigService} from '../rest-config-service';
import {Observable} from 'rxjs';
import { ADGroupsModel } from '../models';

@Injectable()
export class ADGroupService {

	constructor(
private _http: HttpClient,
@Inject(REST_CONFIG_TOKEN) private _configService: RestConfigService
	) {
	}

	public getMyGroups: () => Observable<ADGroupsModel> = (): Observable<ADGroupsModel> => {
		const queryParameters = new HttpParams();
		const headers = new HttpHeaders({'Content-Type': 'application/json'});
		const uri = '/api/ADGroup/mygroups';

		return this._http.get<ADGroupsModel>(this._configService.apiBaseUrl() + uri, { headers: headers, params: queryParameters });
	};
	public logOut: () => Observable<string> = (): Observable<string> => 
	{
		const queryParameters = new HttpParams();
		const headers = new HttpHeaders({'Content-Type': 'application/json'});
		const uri = '/api/ADGroup/logout';

		return this._http.post<string>(this._configService.apiBaseUrl() + uri, JSON.stringify(''), { headers: headers, params: queryParameters });
	};
    
}

