import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {REST_CONFIG_TOKEN, RestConfigService} from '../rest-config-service';
import {Observable} from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import {isNullOrUndefined} from '../../tools';
import * as FileSaver from 'file-saver';
import { UomVm,
} from '../models';

@Injectable()
export class UomsService {

	constructor(
private _http: HttpClient,
@Inject(REST_CONFIG_TOKEN) private _configService: RestConfigService
	) {
	}

	public getAll: (
                ) => Observable<UomVm[]>
		= (
		): Observable<UomVm[]> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});
			const uri = '/api/uoms';

			return this._http
				.get<UomVm[]>(this._configService.apiBaseUrl() + uri,
					{ headers: headers, params: queryParameters  });
		};
	public getConversionFactor: (
        fromUomId?: number,
toUomId?: number
        ) => Observable<number>
		= (
			fromUomId?: number,
			toUomId?: number
		): Observable<number> => {
			let queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});

			if (!isNullOrUndefined(fromUomId)) {
				queryParameters = queryParameters.append('fromUomId', fromUomId.toString());
			}


			if (!isNullOrUndefined(toUomId)) {
				queryParameters = queryParameters.append('toUomId', toUomId.toString());
			}

			const uri = '/api/uoms/conversion';

			return this._http
				.get<number>(this._configService.apiBaseUrl() + uri,
					{ headers: headers, params: queryParameters  });
		};


}

