import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
	HttpResponse,
	HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { ConfigService } from '../services/config-service';
import { isNullOrUndefined } from '../tools';
import { AppInsightsService } from '../services/app-insights.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

	constructor(private toastr: ToastrService,    
    private appInsightsService: AppInsightsService
	) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request)
			.pipe(
				catchError((error: HttpErrorResponse) => {
					this.appInsightsService.logException(error);
					let formattedError = '';
					if (error.error instanceof ErrorEvent) { // client-side error            
						formattedError = error.error.message;
					} else if (typeof (error.error) === 'string') { // server-side error            
						formattedError = `<b>${error.error}</b><br/>Error Code: ${error.status} (${error.statusText})`;
					} else if (!isNullOrUndefined(error.error.error) && typeof (error.error.error) === 'string') {
						formattedError = error.error.error;
					} else {
						formattedError = 'An error occurred.<br/>Please try again.';
					}

					const timeOutReg = new RegExp(/time[d]?[\-\s]?out/gi);
					if (error.status === 500) {
						if ((typeof (error.message) === 'string' && timeOutReg.test(error.message))
              || (typeof (error.error) === 'string' && timeOutReg.test(error.error))
              || (!isNullOrUndefined(error.error.error) && typeof (error.error.error) === 'string' && timeOutReg.test(error.error.error))) {
							formattedError = 'The operation timed out.<br/>Please try again.';
						}
					}

					this.toastr.error(formattedError, 'Error', { enableHtml: true });

					return throwError({ error: error, formattedError: formattedError });
				})
			);
	}
}
