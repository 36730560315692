import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AllocationService } from '../gen/services/allocation.service';
import {
    InitModel, QueryModel,
    PendingAndAllocatedViewModel, AllocatedViewModel,
    AdditionalCostVm, FilterNames, TerritoryVm
} from '../gen/models';
import { UomsService } from '../gen/services/uoms.service';
import { SortInfo } from '../table-sort/table-sort.directive';
import * as _ from 'underscore';
import { InitService } from '../services/init.service';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { EditAllocatedRequestDialogComponent } from '../edit-allocated-request-dialog/edit-allocated-request-dialog.component';
import { EditRequestDialogComponent } from '../edit-request-dialog/edit-request-dialog.component';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { SidenavService } from '../services/sidenav.service';
import { isNullOrUndefined, isUndefined } from '../tools';
import { EnumVals, RequestStatuses, IEnumItem, ListOfColumnNames } from '../gen/enums/enums';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { AllocateService } from '../gen/services/allocate.service';
import { GradeService } from '../gen/services/grade.service';
import { ActionConfirmDialogComponent } from '../action-confirm-dialog/action-confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { SpinnerComponent } from '../spinner/spinner.component';
import { ChangeHistoryDialogComponent } from '../change-history-dialog/change-history-dialog.component';
import { NewRequestDialogComponent } from '../new-request-dialog/new-request-dialog.component';
import { Subscription } from 'rxjs';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';
import { BulkUpdateModel } from '../gen/models/BulkUpdateModel';
import { DepotViewModel } from '../gen/models/DepotViewModel';
import { ApiPageResponse } from '../gen/models/ApiPageResponse';
import { DepotService } from '../gen/services/depot.service';
import { ADGroupService } from '../gen/services/adgroup.service';
import { ActivatedRoute } from '@angular/router';
import { UserPreferencesVm } from '../gen/models/UserPreferencesVm';
import { UserPreferencesService } from '../gen/services/user-preferences.service';
import { UserPreferenceTableEnum, UserTableViewVm } from '../gen/models/UserTableViewVm';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Clipboard } from '@angular/cdk/clipboard';
import { NewInventoryReservationDialogComponent } from '../new-inventory-reservations-dialog/new-inventory-reservation-dialog.component';
import { EditInventoryReservationDialogComponent } from '../edit-inventory-reservations/edit-inventory-reservation-dialog.component';
import { RegionService } from '../gen/services/region.service';
import { NewInstructionToMoveRequestDialogComponent } from '../new-instruction-to-move-request-dialog/new-instruction-to-move-request-dialog.component';


@Component({
    selector: 'app-requests',
    templateUrl: './requests.component.html',
    styleUrls: ['./requests.component.scss']
})
export class RequestsComponent implements OnInit, OnDestroy {
    public pageModel: InitModel;
    public commentsId: number;
    public loadTimesItem: any;
    public attachmentsItem: any;
    bulkUpdateMode = false;
    isLoading = true;
    showSearch = false;
    itemsByPage = 20;
    pendingAndAllocated: PendingAndAllocatedViewModel[] = [];
    selectedRequests: PendingAndAllocatedViewModel[] = [];
    groupedAllocations: AllocatedViewModelExtended[] = [];
    onHold = [{ name: 'Active', value: true }, { name: 'In Active', value: false }];
    requestStatuses: IEnumItem[];
    selectedRequestStatuses: number[] = [];
    allocationStatuses: IEnumItem[];
    selectedAllocationStatuses: number[] = [];
    hasCentralTeamRole = false;
    canEditSelectedRequest = false;
    canEditSelectedReservation = false;
    canDeleteSelectedRequest = false;
    canAddNewInstructionToMove = false;
    canEditSelectedAllocation = false;
    canDeleteSelected = false;
    canFullyAllocateSelected = false;
    statusList: string[];
    selectedIsRequest = true;
    selectedIsAllocation = false;
    selectedRequestHasAllocations = false;
    defaultUoms = [];
    tableState: QueryModel;
    originatorEv: any;
    pendingAllocationsPath = '/Resources/pending_allocations.csv';
    selectedRequestStatus: RequestStatuses = RequestStatuses.Open;
    page = 1;
    pageSize = EnumVals.NoOfResults[1].value;
    collectionSize = 1;
    sortReverse = true;
    requestStatusColumn = 'RequestStatus';
    defaultFilterStatus = 'Pending Requests';
    public tableWidth: number;
    subTableWidth: number;
    searchForm: FormGroup;
    bulkUpdateForm: FormGroup;
    noRecord = false;
    showAllFields = false;
    sCols: string[] = [];
    querySubscription: Subscription;
    showBookingSheet = false;
    showTransportPlan = false;
    showBookedTransport = false;
    filteredDepots: ApiPageResponse<DepotViewModel>;
    filteredRegion: string[];
    fieldsList: { name: string, colWidth: number, type: string }[];
    userPreferencesData: UserPreferencesVm = { depot: '', territory: '', requestFields: '', allocationFields: '', orderBookType: '', viewMode: '', allocationDate: '', pickUpFromDate: '' };
    preferedDepot: DepotViewModel;
    preferedTerritory: TerritoryVm;
    requestColumns: string[] = [];
    defaultTerritory: string;

    @ViewChild('spinner', { static: true }) spinner: SpinnerComponent;
    @ViewChild('matRef', { static: true }) matRef: MatSelect;
    @ViewChild(MatSort) sort: MatSort;
    displayedColumns: string[] = [];
    allocationDisplayedColumns: string[] = ['Allocation Number', 'Allocation Status', 'Depot', 'Request Date', 'Delivered Weight', 'Ready Date',
        'Allocated Date', 'Contract Ref', 'Pick-up From Date & Time',
        'Pick-up To Date & Time', 'Additional Costs', 'Allocated Weight', 'Party', 'Classification',
        'Instructions', 'Haulier', 'Sales Grade', 'Apt Number', 'Apt Date & Time', 'Ticket Date', 'Ticket Number',
        'Awaiting Dispatch', 'Pick-up From Date & Time', 'Pick-up To Date & Time', 'Container Type', 'Notes/Comments',
        'Material Description', 'Dock Receipt', 'Booking Requested', 'Awaiting Appointment', 'Pending Dispatch', 'Processing Dispatched', 'Dispatched',
        'Inspection Date', 'Grade', 'FCF Serve', 'AwaitingDispatch'];
    instructionDisplayedColumns: string[] = ['Creation Date', 'Unit', 'Packaging', 'Container', 'Loads', 'Weight',
        'Grade', 'Transport Mode', 'Contract Number',
        'Priority', 'Due By Date', 'Due From Date', 'Destination', 'ID Number'];
    expandedElement: AllocatedViewModelExtended | null;
    dataSource = new MatTableDataSource(this.pendingAndAllocated);
    userTableView: UserTableViewVm[];
    selectedUserTableView: UserTableViewVm;
    requestTableViews: UserTableViewVm[];
    requestTableViewForm: FormGroup;
    columnList: string[];
    defaultView: UserTableViewVm[] = [
        {
            id: -1,
            table: UserPreferenceTableEnum.Request,
            userId: -1,
            isDeleted: false,
            isSelected: true,
            viewName: 'Default View',
            userTableViewConfigs: [
                { id: -1, columnName: 'Request Status', isSelected: true, index: 1 },
                { id: -1, columnName: 'Request Date', isSelected: true, index: 2 },
                { id: -1, columnName: 'Status', isSelected: true, index: 3 },
                { id: -1, columnName: 'Depot No', isSelected: true, index: 4 },
                { id: -1, columnName: 'Heap', isSelected: true, index: 5 },
                { id: -1, columnName: 'Ready Date', isSelected: true, index: 6 },
                { id: -1, columnName: 'Requested Material Detail', isSelected: true, index: 7 },
                { id: -1, columnName: 'Weight', isSelected: false, index: 8 },
                { id: -1, columnName: 'Rem. Weight', isSelected: true, index: 9 },
                { id: -1, columnName: 'Rem. Loads', isSelected: true, index: 10 },
                { id: -1, columnName: 'Container - Packaging', isSelected: true, index: 11 },
                { id: -1, columnName: 'Attachment', isSelected: true, index: 12 },
                { id: -1, columnName: 'Notes/Comments', isSelected: false, index: 13 },
                { id: -1, columnName: 'Grade', isSelected: true, index: 14 },
            ]
        }
    ];

    tableHeight = 800;
    showInstructionToMove: boolean;
    showInventoryReservation: boolean;

    constructor(private allocationService: AllocationService,
        private initService: InitService,
        private uomsService: UomsService,
        private dialog: MatDialog,
        private sidenavService: SidenavService,
        private fb: FormBuilder,
        private allocateService: AllocateService,
        private gradeService: GradeService,
        private depotsService: DepotService,
        private toastr: ToastrService,
        private adGroupService: ADGroupService,
        private route: ActivatedRoute,
        private userPreferenceService: UserPreferencesService,
        private regionService: RegionService,
        private clipboard: Clipboard) {

        const userPreferences = this.route.snapshot.data['userpreferencesData'];
        if (userPreferences !== undefined && userPreferences.length > 0) {
            this.userPreferencesData = userPreferences[0];
        }

        this.requestTableViewForm = this.fb.group({
            requestTableView: [''],
            columnsList: ['']
        });
    }

    ngOnInit() {
        this.fieldsList = ListOfColumnNames.fieldsListForRequests.filter(f => f.type === 'request');

        const depot = this.route.snapshot.queryParamMap.get('depot');
        const region = this.route.snapshot.queryParamMap.get('region');
        const grade = this.route.snapshot.queryParamMap.get('grade');
        const gradeGroup = this.route.snapshot.queryParamMap.get('gradegroup');
        const heap = this.route.snapshot.queryParamMap.get('heap');
        const isActive = this.route.snapshot.queryParamMap.get('isActive');

        if (this.userPreferencesData.territory === '')
            this.defaultTerritory = null;
        else
            this.defaultTerritory = this.userPreferencesData.territory;

        this.preferedDepot = { depotNumber: this.userPreferencesData.depot, depotName: '', depotShortName: '', selected: false };
        const retrievedDepot = { depotNumber: depot, depotName: '', depotShortName: '', selected: false };

        if (this.userPreferencesData.requestFields !== '')
            this.requestColumns = JSON.parse(this.userPreferencesData.requestFields);

        const defaultDepot = !isNullOrUndefined(retrievedDepot.depotNumber) ? retrievedDepot : this.preferedDepot;
        const depotNo = (defaultDepot.depotNumber === '') ? null : defaultDepot.depotNumber;

        this.initService.initData$.subscribe(r =>
            this.pageModel = r
        );
        
        this.adGroupService.getMyGroups().subscribe(g => {
            this.showBookingSheet = g.myGroups.includes('BookingSheet');
            this.showTransportPlan = g.myGroups.includes('TransportPlan');
            this.showBookedTransport = g.myGroups.includes('BookedTransport');
            this.hasCentralTeamRole = g.myGroups.includes('AllocationsClerk');
            this.showInstructionToMove = g.myGroups.includes('InstructionToMove');
            this.showInventoryReservation = g.myGroups.includes('InventoryReservation');
        });
        
        this.allocationService.getStatuses().subscribe(r => this.statusList = r);
        this.uomsService.getAll().subscribe(r => this.defaultUoms = r);
        const ts = this.getDefaultSearch(depotNo, this.sortReverse, this.defaultTerritory, heap != '' ? heap : null, grade != '' ? grade : null, gradeGroup != '' ? gradeGroup : null, region);
        this.callServer(ts);

        //this.assignDefaultColumns();
        this.requestStatuses = EnumVals.RequestStatuses;
        this.allocationStatuses = EnumVals.ContractLineAllocationStatuses;

        const openRequestStatus = this.requestStatuses.filter(rs => rs.desc === 'Open');

        this.bulkUpdateForm = this.fb.group({ lifecycleStatusId: 0, comment: '' });

        this.searchForm = this.fb.group({
            region: '',
            depot: { depotNumber: depotNo, depotName: '', depotShortName: '', selected: false },
            heap: heap,
            requestId: 0,
            grade: grade,
            gradeGroupName: gradeGroup,
            containerPackaging: '',
            lifecycleStatusId: 0,
            comment: '',
            salesGrade: '',
            classification: '',
            contractRef: '',
            allocationNumber: '',
            instructionNumber: '',
            haulierAccountNo: '',
            ticketNumber: '',
            partyAccountNo: '',
            requestStatusesControl: new FormControl(openRequestStatus),
            allocationStatusesControl: new FormControl(),
            isActive: '',
        });

        this.depot.valueChanges
            .pipe(
                debounceTime(500),
                startWith(null),
                switchMap(v => this.filterDepots(v)))
            .subscribe(d => this.filteredDepots = d);
        this.region.valueChanges
            .pipe(
                debounceTime(500),
                startWith(null),
                switchMap(v => this.filterRegion(v)))
            .subscribe(d => this.filteredRegion = d);
        this.requestTableViewForm.get('requestTableView')
            .valueChanges
            .subscribe(value => this.displayedColumns = this.SetDisplayColumns(value));

        this.userPreferenceService
            .getUserTableView(UserPreferenceTableEnum.Request)
            .subscribe(view => {
                if (isNullOrUndefined(view) || view.length === 0) {
                    view = this.defaultView;
                }
                this.displayedColumns = this.MapDisplayedColumns(view);
            });

        this.calculateHeight();
    }

    calculateHeight(fromSearch = false) {
        let searchHeight = 0;

        const intFrameHeight = window.innerHeight;

        if (fromSearch)
            searchHeight = window.innerHeight * 0.15;

        const calulatedHeight = intFrameHeight - 50 - 48 - 37 - searchHeight - 36 - 10; //the height of the inside screen - the top 3 sections (excluding the search - 10 for spacings
        this.tableHeight = calulatedHeight;
    }

    SetDisplayColumns(selectedView: UserTableViewVm): string[] {
        if (!selectedView) {
            return this.fieldsList.map(f => f.name);
        }

        this.selectedUserTableView = selectedView;
        const cols = this.selectedUserTableView.userTableViewConfigs.filter(col => col.isSelected && col.columnName != 'Show All').sort((a, b) => {
            return a.index - b.index;
        }).map(x => x.columnName);

        cols.unshift('toggle');
        this.sCols = cols;
        this.requestColumns = cols;
        return cols;
    }

    MapDisplayedColumns(view: UserTableViewVm[]): string[] {
        this.requestTableViews = view;
        this.selectedUserTableView = view.find(v => v.isSelected);
        if (this.selectedUserTableView) {
            this.requestTableViewForm.patchValue({
                'requestTableView': this.selectedUserTableView,
                'columnsList': this.selectedUserTableView.userTableViewConfigs.filter(f => f.isSelected).map(m => m.columnName)
            });
        }
        this.columnList = this.selectedUserTableView ? this.selectedUserTableView.userTableViewConfigs.map(config => config.columnName) : [];
        this.columnList.unshift('Show All');
        this.sCols.unshift('Show All');
        return this.SetDisplayColumns(this.selectedUserTableView);
    }

    AddColumnIntoDisplayList(columns: string[]) {
        if (columns.includes('Show All') && this.showAllColumns) {
            this.displayedColumns = this.selectedUserTableView.userTableViewConfigs.filter(col => col.columnName != 'Show All').sort((a, b) => {
                return a.index - b.index;
            }).map(x => x.columnName);

            this.displayedColumns.unshift('toggle');

            this.requestTableViewForm.patchValue({
                'columnsList': this.columnList
            });
        } else if (!columns.includes('Show All') && this.showAllColumns) {
            const cols = this.selectedUserTableView.userTableViewConfigs.filter(col => columns.includes(col.columnName)).sort((a, b) => {
                return a.index - b.index;
            }).map(x => x.columnName);

            this.requestTableViewForm.patchValue({
                'columnsList': cols
            });

            cols.unshift('toggle');

            this.displayedColumns = cols;
        } else {
            this.selectedUserTableView.userTableViewConfigs.forEach(config => {
                if (columns.includes(config.columnName)) {
                    config.isSelected = true;
                } else {
                    config.isSelected = false;
                }
            });

            const cols = this.selectedUserTableView.userTableViewConfigs.filter(col => col.isSelected).sort((a, b) => {
                return a.index - b.index;
            }).map(x => x.columnName);

            this.requestTableViewForm.patchValue({
                'columnsList': cols
            });

            cols.unshift('toggle');

            this.displayedColumns = cols;
        }
    }

    filterDepots(val: string) {
        return this.depotsService.searchDepotsByRegion(val, this.region.value);
    }
    filterRegion(val: string) {
        return this.regionService.searchRegions(val);
    }
    get depot() {
        return this.searchForm.get('depot');
    }

    get region() {
        return this.searchForm.get('region');
    }
    get requestId() {
        return this.searchForm.get('requestId');
    }
    displayRegion(region?: any): string | undefined {
        return region ? region : undefined;
    }
    displayDepot(depot?: DepotViewModel): string | undefined {
        let depotString = undefined;
        if (depot && depot.depotNumber && depot.depotNumber !== '' && depot.depotName !== '')
            depotString = depot.depotNumber + ' - ' + depot.depotName;
        else if (depot && depot.depotNumber && depot.depotNumber !== '' && depot.depotName === '')
            depotString = depot.depotNumber;
        return depotString;
    }

    ngOnDestroy(): void {
        this.isLoading = false;
        this.spinner.overlayRef.dispose();
        if (this.querySubscription != null && !this.querySubscription.closed) {
            this.querySubscription.unsubscribe();
        }
    }

    showSearchOptions() {
        this.showSearch = !this.showSearch;
        this.bulkUpdateMode = !this.showSearch && this.selectedRequests.length > 1;
        this.calculateHeight(this.showSearch);
    }

    sideNavShow(info: any) {
        this.sidenavService.next(info);
    }
    getPackagingSummary(item) {
        const containerSize = _.find(this.pageModel.containerSizes, (cs) => cs.id === item.ContainerSizeId);
        const packagingOption = _.find(this.pageModel.packageOptions, (po) => po.id === item.PackageOptionId);
        return JSON.stringify(containerSize.displayName + ' - ' + packagingOption.displayName);
    }

    callServer(tableState: QueryModel) {
        this.isLoading = true;

        if (tableState) {
            this.tableState = tableState;
        }

        if (this.querySubscription != null && !this.querySubscription.closed) {
            this.querySubscription.unsubscribe();
        }

        this.querySubscription = this.allocationService.getRequests(tableState).subscribe(r => {
            this.pendingAndAllocated = r.requestsAndAllocated;
            this.dataSource = new MatTableDataSource(this.pendingAndAllocated);
            this.disableEditingControls();
            this.dataSource.sort = this.sort;
            if (r.groupedAllocations) {
                this.groupedAllocations = r.groupedAllocations.map(p => p as AllocatedViewModelExtended);
            }
            this.collectionSize = r.recordCount;
            if (this.tableState.pagination.start === 1) {
                this.page = 1;
            }
            this.isLoading = false;
        }, err => {
            this.isLoading = false;
        });
        if (!(this.pendingAndAllocated.length > 0)) {
            this.noRecord = true;
            this.page = 1;
        }
    }

    getUOM(uomId) {
        const uom = _.clone(this.defaultUoms.find(u => u.Id === uomId));
        if (uom) { return uom.Code; }
        return null;
    }

    openMenu($mdOpenMenu, ev) {
        this.originatorEv = ev;
        $mdOpenMenu(ev);
    }

    sortByInfo(info: SortInfo) {
        if (info.active) {
            this.tableState.sort.reverse = info.direction != 'asc';
            this.sortReverse = this.tableState.sort.reverse;
            this.tableState.sort.predicate = info.active;
            this.callServer(this.tableState);
        }
    }
    newReservation() {
        const dialogRef = this.dialog.open(NewInventoryReservationDialogComponent, {
            maxHeight: '80%',
            maxWidth: '100%',
            disableClose: true,
            data: this.userPreferencesData
        });

        dialogRef.afterClosed().subscribe(r => {
            this.disableEditingControls();
            if (isUndefined(r)) { return; }
            this.callServer(this.tableState);
        });
    }
    newRequest() {
        const dialogRef = this.dialog.open(NewRequestDialogComponent, {
            maxHeight: '80%',
            maxWidth: '100%',
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe(r => {
            this.disableEditingControls();
            this.clearSelected();
            if (isUndefined(r)) { return; }
            this.callServer(this.tableState);
        });
    }

    showComments(item) {
        this.sideNavShow({ type: 'comments', item });
        this.sidenavService.closed$.subscribe(sidenav => {
            if (sidenav && sidenav.closed) {
                this.callServer(this.tableState);
            }
        });
    }

    showLoadTimes(item) {
        this.sideNavShow({ type: 'loadTimes', item });
    }

    showAttachments(item) {
        this.sideNavShow({ type: 'attachments', item });
        this.sidenavService.closed$.subscribe(a => {
            if (isUndefined(a)) { return; }
            if (a.closed) {
                this.callServer(this.tableState);
            }
        });
    }

    pageChanged(page: number) {
        this.page = page;
        this.tableState.pagination.start = page;
        this.callServer(this.tableState);
    }
    filterByStatus(id: number) {
        this.selectedRequestStatus = id;
        this.tableState.search.predicateObject[this.requestStatusColumn] = id;
        this.tableState.pagination.start = 1;
        this.callServer(this.tableState);
    }

    bulkUpdate(formVal: any) {

        if (formVal.lifecycleStatusId == 0 && !formVal.comment) {
            this.toastr.info('Please select lifecycle status or add comment', 'Bulk Update');
            return;
        }

        this.isLoading = true;
        const model = new BulkUpdateModel();
        model.comment = formVal.comment,
        model.lifecycleStatusId = formVal.lifecycleStatusId;
        model.requestIds = this.selectedRequests.map(x => x.requestId);

        this.allocationService.bulkUpdateRequests(model).subscribe(r => {
            if (r) {
                this.bulkUpdateMode = false;
                this.selectedRequests = [];
                this.toastr.success('Requests updated successfully', 'Bulk Update');
                this.callServer(this.tableState);
            } else {
                this.toastr.error('Requests update failed. Please try again', 'Bulk Update');
            }
        });
        this.isLoading = false;
    }

    search(formVal: any) {
        const presetFilters = this.pageModel.filterNames as FilterNamesExtended[];

        let depot = formVal.depot;
        if (typeof (depot) !== 'string')
            depot = depot.depotNumber;

        const region = formVal.region;
        let requestStatusFilter = [];
        if (!isNullOrUndefined(formVal.requestStatusesControl) && formVal.requestStatusesControl.length > 0)
            requestStatusFilter = (formVal.requestStatusesControl as IEnumItem[]).map((val) => val.key);
        let allocationRequestStatusFilter = [];
        if (!isNullOrUndefined(formVal.allocationStatusesControl) && formVal.allocationStatusesControl.length > 0)
            allocationRequestStatusFilter = (formVal.allocationStatusesControl as IEnumItem[]).map((val) => val.key);

        const predicate: any = {
            territory: this.defaultTerritory,
            depot: depot,
            region: region,
            heap: formVal.heap,
            requestId: formVal.requestId,
            grade: formVal.grade,
            gradeGroupName: formVal.gradeGroupName,
            containerPackaging: formVal.containerPackaging,
            lifecycleStatusId: formVal.lifecycleStatusId,
            comment: formVal.comment,
            salesGrade: formVal.salesGrade,
            classification: formVal.classification,
            contractRef: formVal.contractRef,
            allocationNumber: formVal.allocationNumber,
            instructionNumber: formVal.instructionNumber,
            IsActive: formVal.isActive,
            haulierAccountNo: formVal.haulierAccountNo,
            ticketNumber: formVal.ticketNumber,
            partyAccountNo: formVal.partyAccountNo,
            requestStatuses: requestStatusFilter,
            allocationStatuses: allocationRequestStatusFilter,
            includeReservation: this.showInventoryReservation
        };
        this.tableState.search.predicateObject = predicate;
        this.tableState.search.predicateObject.presetFilterIds = presetFilters.filter((a) => a.isSelected).map(function (a) { return a.id; });
        this.callServer(this.tableState);
    }

    getTickedBoxes(boxes: any): string[] {
        const keyNames = Object.keys(boxes);
        return keyNames.filter(k => boxes[k]);
    }

    selectRequest(item: PendingAndAllocatedViewModelExtended, event: MouseEvent) {

        if (this.showSearch) this.showSearch = false;

        if (event.ctrlKey) {
            const index = this.selectedRequests.findIndex(x => x.requestId == item.requestId);
            if (index === -1) {
                this.selectedRequests.push(item);
            } else {
                this.selectedRequests.splice(index, 1);
            }
        }

        if (!event.ctrlKey) {
            this.selectedRequests = [];
            this.selectedRequests.push(item);
            this.clearSelected();
        }

        this.bulkUpdateMode = this.selectedRequests.length > 1;
        this.selectedIsRequest = true;
        this.selectedIsAllocation = false;
        this.selectedRequestHasAllocations = item.allocations.length > 0 && item.allocations[0].allocationNumber !== 0;
        this.toggleRequestEditingControls(item);
    }

    toggleRequestEditingControls(item: PendingAndAllocatedViewModelExtended) {
        if (item.isSelected) { //de-select it
            item.isSelected = false;
            this.disableEditingControls();
        } else {
            item.isSelected = true;
            this.canEditSelectedRequest = true;

            this.canDeleteSelectedRequest = true && !this.hasActiveAllocationsOrReservation(item);
            if (item.heap != null && item.heap != undefined && item.heap != '') {
                this.canAddNewInstructionToMove = true;
            }
            else {
                this.canAddNewInstructionToMove = false;
            }
            this.canDeleteSelected = true;
            this.canFullyAllocateSelected = item.requestStatus !== RequestStatuses.FullyAllocated;
        }
    }

    disableEditingControls() {
        this.canAddNewInstructionToMove = false;
        this.canEditSelectedRequest = false;
        this.canEditSelectedReservation = false;
        this.canDeleteSelectedRequest = false;
        this.canDeleteSelected = false;
        this.canFullyAllocateSelected = false;
    }

    selectAllocation(item: AllocatedViewModelExtended, header: boolean) {
        this.clearSelected();
        this.selectedIsAllocation = true;
        this.selectedIsRequest = false;
        if (item.isSelected || (item.allocationNumber >= 300000000)) {
            item.isSelected = false;
            this.canEditSelectedAllocation = false;
            this.canDeleteSelected = false;
        } else {
            item.isSelected = true;
            const isGroupHeader = (header && this.isGrouped(item));
            this.canEditSelectedAllocation = isGroupHeader ? false : this.hasCentralTeamRole && item.active;
            this.canDeleteSelected = !isGroupHeader && item.active;
        }
    }

    clearSelected() {
        const selectedRow = _.findWhere(this.pendingAndAllocated as PendingAndAllocatedViewModelExtended[], { isSelected: true });
        if (selectedRow) {
            selectedRow.isSelected = false;
        }
        for (const a of this.pendingAndAllocated) {
            if (this.hasAllocations(a)) {
                for (const i of a.allocations) {
                    const item = i as AllocatedViewModelExtended;
                    if (item.isSelected) {
                        item.isSelected = false;
                    }
                }
            }
        }
        for (const a of this.groupedAllocations) {
            a.isSelected = false;
        }
    }

    success(message: string, title: string, selectedAllocation: PendingAndAllocatedViewModelExtended) {
        if (selectedAllocation) {
            this.toggleRequestEditingControls(selectedAllocation);
        }
        this.callServer(this.tableState);
        this.toastr.success(message, title);
    }

    editReservation(selectedAllocation: PendingAndAllocatedViewModelExtended) {
        const dialogRef = this.dialog.open(EditInventoryReservationDialogComponent, {
            minWidth: '240px',
            maxWidth: '80%',
            maxHeight: '80%',
            data: selectedAllocation,
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe(r => {
            if (selectedAllocation) {
                this.toggleRequestEditingControls(selectedAllocation);
            }
            this.callServer(this.tableState);
        });
    }
    isReservation(element: any): boolean {
        if (element.isReservation != null && element.isReservation) {
            return true;
        }
        return false;
    }
    editRequest() {
        const selectedAllocation = _.findWhere(this.pendingAndAllocated as PendingAndAllocatedViewModelExtended[], { isSelected: true });
        if (selectedAllocation.isReservation != null && selectedAllocation.isReservation) {
            this.editReservation(selectedAllocation);
        }
        else {
            const dialogRef = this.dialog.open(EditRequestDialogComponent, {
                minWidth: '240px',
                maxWidth: '80%',
                maxHeight: '80%',
                data: selectedAllocation,
                disableClose: true,
            });

            dialogRef.afterClosed().subscribe(r => {
                if (selectedAllocation) {
                    this.toggleRequestEditingControls(selectedAllocation);
                }
                this.callServer(this.tableState);
            });
        }
    }

    addNewInstructionToMoveFromRequest() {
        const selectedAllocation = _.findWhere(this.pendingAndAllocated as PendingAndAllocatedViewModelExtended[], { isSelected: true });

        const dialogRef = this.dialog.open(NewInstructionToMoveRequestDialogComponent, {
            minWidth: '240px',
            maxWidth: '80%',
            maxHeight: '80%',
            data: selectedAllocation,
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe(r => {
            if (selectedAllocation) {
                this.toggleRequestEditingControls(selectedAllocation);
            }
            this.callServer(this.tableState);
        });

    }

    deleteRequest() {
        const selectedAllocation = _.findWhere(this.pendingAndAllocated as PendingAndAllocatedViewModelExtended[], { isSelected: true });
        const dialogRef = this.dialog.open(ActionConfirmDialogComponent, {
            minWidth: '240px',
            maxWidth: '80%',
            maxHeight: '80%',
            data: {
                type: 'request',
                model: selectedAllocation,
                action: 'delete'
            },
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe(r => {
            if (isUndefined(r)) { return; }
            this.allocationService.deleteRequest(r.requestId).subscribe(a => {
                this.disableEditingControls();
                this.success('Request successfully deleted', 'Delete Request', selectedAllocation);
            },
            err => {
                this.disableEditingControls();
                if (err.status === 200) {
                    this.success('Request successfully deleted', 'Delete Request', selectedAllocation);
                } else {
                    this.dialog.open(AlertDialogComponent, {
                        minWidth: '240px',
                        maxWidth: '80%',
                        maxHeight: '80%',
                        data: {
                            ok: 'Ok',
                            aria: 'Delete Failed',
                            title: 'Delete Failed',
                            body: 'Something went wrong and the request could not be deleted. Please try again later.'
                        }
                    });
                }
            });
        });
    }

    fullyAllocate() {
        const selectedAllocation = _.findWhere(this.pendingAndAllocated as PendingAndAllocatedViewModelExtended[], { isSelected: true });
        const dialogRef = this.dialog.open(ActionConfirmDialogComponent, {
            minWidth: '240px',
            maxWidth: '80%',
            maxHeight: '80%',
            data: {
                type: 'request',
                model: selectedAllocation,
                action: 'fully allocate'
            },
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe(r => {
            if (isUndefined(r)) { return; }
            this.allocationService.fullyAllocate(r.requestId).subscribe(a => {
                this.disableEditingControls();
                this.success('Request successfully fully allocated', 'Fully Allocate Request', selectedAllocation);
            },
            err => {
                this.disableEditingControls();
                if (err.status === 200) {
                    this.success('Request successfully fully allocated', 'Fully Allocate Request', selectedAllocation);
                } else {
                    this.dialog.open(AlertDialogComponent, {
                        minWidth: '240px',
                        maxWidth: '80%',
                        maxHeight: '80%',
                        data: {
                            ok: 'Ok',
                            aria: 'Fully Allocate Failed',
                            title: 'Fully Allocate Failed',
                            body: 'Something went wrong and the request could not be fully allocated. Please try again later.'
                        }
                    });
                }
            });
        });
    }

    getSelectedAllocation(): AllocatedViewModelExtended {
        let selectedAllocation = _.findWhere(
            this.pendingAndAllocated
                .map(p => p.allocations as AllocatedViewModelExtended[]).reduce((m, i) => m.concat(i), []), { isSelected: true });

        if (isNullOrUndefined(selectedAllocation)) {
            selectedAllocation = _.findWhere(
                this.groupedAllocations
                    .map(p => p).reduce((m, i) => m.concat(i), []), { isSelected: true });
        }
        return selectedAllocation;
    }

    editAllocation() {
        const selectedAllocation = this.getSelectedAllocation();
        this.allocateService.getAllocation(selectedAllocation.allocationNumber)
            .subscribe(m => {
                const dialogRef = this.dialog.open(EditAllocatedRequestDialogComponent, {
                    minWidth: '240px',
                    maxWidth: '80%',
                    maxHeight: '80%',
                    data: { allocate: m, request: selectedAllocation.requestId },
                    disableClose: true,
                });
                dialogRef.afterClosed().subscribe(c => { this.search(this.searchForm.value); });
            });
    }

    deleteAllocation() {
        const selectedAllocation = this.getSelectedAllocation();

        const dialogRef = this.dialog.open(ActionConfirmDialogComponent, {
            minWidth: '240px',
            maxWidth: '80%',
            maxHeight: '80%',
            data: {
                type: 'allocation',
                model: selectedAllocation,
                action: 'delete'
            }
        });

        dialogRef.afterClosed().subscribe(r => {
            if (isUndefined(r)) { return; }
            let restore = false;
            if (r.requestId > 0) {

                this.allocateService.hasAllocationRequest(r.allocationNumber)
                    .subscribe(hasRequest => {
                        if (hasRequest) {
                            const isOpenRequest = r.requestRemainingWeight == null;
                            const weightString = isOpenRequest ? '' : `${r.allocatedWeight}${r.uomCode} and`;
                            let bodyMessage = `This will restore the request with ${weightString} 1 load. `;
                            bodyMessage += 'Do you want to restore the request ?';

                            const dialogConfirmRef = this.dialog.open(AlertDialogComponent, {
                                minWidth: '240px',
                                maxWidth: '80%',
                                maxHeight: '80%',
                                data: {
                                    ok: 'Yes',
                                    cancel: 'No',
                                    aria: 'Deleting Allocation',
                                    title: 'Deleting Allocation',
                                    body: bodyMessage,
                                    model: 'confirm'
                                }
                            });

                            dialogConfirmRef.afterClosed().subscribe(s => {
                                if (!isUndefined(s) && s === 'ok') {
                                    restore = true;
                                }
                                this.delete(r.allocationNumber, restore);
                            });

                        } else {
                            this.delete(r.allocationNumber, restore);
                        }
                    });

            } else {
                this.delete(r.allocationNumber, restore);
            }
        });
    }

    delete(contractLineAllocationId: number, restoreRequest: boolean) {
        this.allocateService.deleteAllocations(restoreRequest, [contractLineAllocationId]).subscribe(a => {
            this.success('Allocation successfully deleted', 'Delete Request', null);
        },
        err => {
            if (err.status === 200) {
                this.success('Allocation successfully deleted', 'Delete Request', null);
            } else {
                this.dialog.open(AlertDialogComponent, {
                    minWidth: '240px',
                    maxWidth: '80%',
                    maxHeight: '80%',
                    data: {
                        ok: 'Ok',
                        aria: 'Delete Failed',
                        title: 'Delete Failed',
                        body: 'Something went wrong and the allocation could not be deleted. Please try again later.'
                    }
                });
            }
        });
    }

    exportToExcel() {
        const selectedRequestStatusValue =
            EnumVals.RequestStatuses.filter(r => r.key === this.selectedRequestStatus)[0].value;
        this.allocationService
            .exportRequestsToExcel(
                'PendingAndAllocated.xlsx',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', this.tableState)
            .subscribe();
    }

    filterTableByLifecycleStatus(selectedOption) {
        this.tableState.search.predicateObject.lifecycleStatusId = selectedOption;
        this.callServer(this.tableState);
    }

    filterTableByContainerPackaging(selectedOption) {
        this.tableState.search.predicateObject.containerPackaging = selectedOption;
        this.callServer(this.tableState);
    }

    filterTableByStatus(selectedOption) {
        this.tableState.search.predicateObject.status = selectedOption;
        this.callServer(this.tableState);
    }
    filterTableByReview(selectedOption) {
        this.tableState.search.predicateObject.review = selectedOption;
        this.callServer(this.tableState);
    }
    updateColumns(value: any) {
        if (value.length !== this.columnList.length && !this.showAllFields) {
            value = value.filter(x => x != 'Show All');
        }
        if (value.length === this.columnList.length - 1 && !this.showAllFields) {
            value.push('Show All');
        }
        if (!isNullOrUndefined(value)) {
            this.AddColumnIntoDisplayList(value);
            const val = value;
            if (this.showAllFields && val.includes('Show All')) {
                this.sCols = this.fieldsList.map(f => f.name);
            } else if (this.showAllFields && !val.includes('Show All')) {
                this.sCols = this.requestColumns;
            } else if (val.length > 0) {
                this.sCols = val;
            } else {
                this.sCols = this.requestColumns;
            }
            this.setTableWidth();
        }
    }
    showAllColumns(event: any) {
        if (event.source.value === 'Show All') {
            this.showAllFields = true;
        } else {
            this.showAllFields = false;
        }
    }

    filterSelection(event: any, filterName: FilterNamesExtended) {
        if (event.source.selected)
            filterName.isSelected = true;
        else
            filterName.isSelected = false;
    }

    selectAllocationStatus(event: any, enumKey: number) {
        const selected: boolean = (event && event.source && event.source.selected);
        if (selected) {
            if (!this.selectedAllocationStatuses.includes(enumKey)) {
                this.selectedAllocationStatuses.push(enumKey);
            }
        } else {
            this.selectedAllocationStatuses.splice(this.selectedAllocationStatuses.indexOf(enumKey), 1);
        }
    }

    selectRequestStatus(event: any, enumKey: number) {
        const selected: boolean = (event && event.source && event.source.selected);
        if (selected) {
            if (!this.selectedRequestStatuses.includes(enumKey)) {
                this.selectedRequestStatuses.push(enumKey);
            }
        } else {
            this.selectedRequestStatuses.splice(this.selectedRequestStatuses.indexOf(enumKey), 1);
        }
    }

    compareRequestStatus(rs1: any, rs2: any): boolean {
        if (rs1 === undefined || rs2 === undefined)
            return false;

        if (rs1.desc && rs2.desc && rs1.desc === rs2.desc)
            return true;
        else
            return false;
    }

    assignDefaultColumns() {
        if (this.requestColumns.length > 0)
            this.sCols = this.requestColumns;
        else
            this.sCols = ListOfColumnNames.defaultSelectedColsForRequests;

        if (!isNullOrUndefined(this.sCols))
            this.setTableWidth();
    }

    setTableWidth() {
        this.tableWidth = this.fieldsList
            .filter(f => f.type === 'request' && this.displayedColumns.indexOf(f.name) > -1)
            .map(f => f.colWidth)
            .reduce((a, b) => a + b, 0);

        this.subTableWidth = this.fieldsList
            .filter(f => f.type === 'allocation' && this.allocationDisplayedColumns.indexOf(f.name) > -1)
            .map(f => f.colWidth)
            .reduce((a, b) => a + b, 0);
        // this.tableWidth = this.sCols.length * this.defaultWidth;
    }

    hasAllocations(item: PendingAndAllocatedViewModel): boolean {
        return (item.allocations.length > 0 && item.allocations[0].contractRef !== null);
    }
    hasAllocationsOrReservation(item: PendingAndAllocatedViewModel): boolean {
        const validInstructions = item.instructions.filter(x => x.id != -1);
        return ((item.allocations.length > 0 && item.allocations[0].contractRef !== null)
            || validInstructions.length > 0);
    }
    hasActiveAllocationsOrReservation(item: PendingAndAllocatedViewModel): boolean {
        const validInstructions = item.instructions.filter(x => x.id != -1 && x.isActive);
        const validAllocations = item.allocations.filter(x => x.allocationNumber != -1 && x.active);
        return validAllocations.length > 0 || validInstructions.length > 0;
    }
    hasReservation(item: PendingAndAllocatedViewModel): boolean {
        const validInstructions = item.instructions.filter(x => x.id != -1);
        return validInstructions.length > 0;
    }

    toggleAllocations(item: PendingAndAllocatedViewModelExtended) {
        item.showingAllocations = !item.showingAllocations;
    }
    parseRequestStatus(key: number): string {
        return EnumVals.RequestStatuses.filter(s => s.key === key)[0].desc;
    }
    parseAllocationStatus(key: number): string {
        return EnumVals.ContractLineAllocationStatuses.filter(s => s.key === key)[0].desc;
    }

    isDeleted(item: AllocatedViewModelExtended): boolean {
        if (this.isGrouped(item)) {
            return this.isAllDeleted(item);
        } else {
            return !item.active;
        }
    }

    isAllDeleted(item: AllocatedViewModelExtended): boolean {
        return !(this.groupedAllocations.filter(g => g.groupId === item.groupId
            && g.active).length > 0);
    }

    formatCosts(costs: AdditionalCostVm[]): string {
        if (costs && costs.length > 0) {
            const actualCosts = costs.filter(c => c.cost != 0 && c.costUomCode && c.costUomCode.length > 0); //validate for default values
            if (actualCosts.length > 0)
                return actualCosts.map(c => `${c.cost} ${c.costUomCode}`).join();
        }
        return 'None';
    }

    requestHistory(id: number) {
        const dialogRef = this.dialog.open(ChangeHistoryDialogComponent, {
            minWidth: '240px',
            maxWidth: '80%',
            maxHeight: '80%',
            data: { id, table: 'AllocationRequest' }
        });
    }

    getDefaultSearch(depotNo: string, sortReverse: boolean, territory: string, heap: string = null,
        grade: string = null, gradeGroup: string = null, region: string = null): QueryModel {
        const queryModel: QueryModel = {
            pagination: { number: this.pageSize, start: 0 },
            search: {
                predicateObject: {
                    grade: grade,
                    gradeDescription: null,
                    gradeGroupName: gradeGroup,
                    salesGrade: null,
                    heap: heap,
                    heapDescription: null,
                    requestedGrade: null,
                    requestedGradeDescription: null,

                    depot: depotNo,
                    region: region,
                    depotName: null,
                    territory: territory,

                    contractRef: null,
                    classification: null,
                    classifications: [],

                    partyAccountNo: null,
                    partyName: null,
                    partySearchText: null,
                    haulierAccountNo: null,
                    haulierName: null,
                    haulierSearchText: null,

                    ticketNumber: null,

                    allocationNumber: null,
                    instructionNumber: null,
                    containerPackaging: null,
                    containerTypeName: null,
                    requestStatuses: ['Open'],
                    allocationStatuses: [],
                    contractHeaderStatuses: [],
                    presetFilterIds: [],
                    lifecycleStatusId: 0,
                    comment: null,
                    comments: null,
                    status: null,
                    description: null,
                    review: null,
                    firstComeFirstServe: false,
                    awaitingDispatch: null,

                    dateRange: null,
                    pickupFromDateRange: null,
                    includeDeleted: false,
                    kpiTypes: [],
                    destination: null,
                    route: null,
                    includeReservation: this.showInventoryReservation
                }
            },
            sort: { predicate: null, reverse: sortReverse }
        };
        return queryModel;
    }

    toggleGroup(item: AllocatedViewModelExtended) {
        item.showingGroup = !item.showingGroup;
    }

    getGroupAllocations(groupId: any): AllocatedViewModelExtended[] {
        return this.groupedAllocations.filter(g => g.groupId === groupId);
    }

    isGrouped(item: AllocatedViewModelExtended): boolean {
        const isGrouped = !isNullOrUndefined(item.groupId) && item.groupId !== '';
        return isGrouped;
    }

    clearFilter() {
        this.matRef.options.forEach((data: MatOption) => data.deselect());

        this.searchForm.reset({
            depot: '',
            heap: '',
            grade: '',
            gradeGroupName: '',
            instructionNumber: null,
            containerPackaging: '',
            lifecycleStatusId: 0,
            requestId: 0,
            comment: '',
            salesGrade: '',
            classification: '',
            contractRef: '',
            allocationNumber: '',
            haulierAccountNo: '',
            ticketNumber: '',
            partyAccountNo: '',
            isActive: ''
        });
    }

    copyLink() {
        try {
            const depot = this.searchForm.get('depot').value;
            if (isNullOrUndefined(depot) || depot == '') {
                this.toastr.error('Depot selection required', 'Error');
                return;
            }

            const url = new URL(location.origin + location.pathname);

            url.searchParams.append('depot', '');
            url.searchParams.append('grade', '');
            url.searchParams.append('gradegroup', '');
            url.searchParams.append('heap', '');

            if (typeof (depot) === 'string' && depot.includes(',')) {
                //The following splits multiple depot search using the ',' then sets all the text to uppercase and removes any spacing from the front and end of the text
                //after which we take the first 6 letters which will be the depot number which is used in the search
                const splitdepotNos = depot.split(',').map(element => element.toUpperCase().trim().slice(0, 7));
                const depotNos = splitdepotNos.join(', ');
                url.searchParams.set('depot', depotNos);
            }
            else {
                if (depot.depotNumber != null && depot.depotNumber != undefined && depot.depotNumber != '') {
                    url.searchParams.set('depot', depot.depotNumber);
                }
                else if (!isNullOrUndefined(depot) && typeof (depot) === 'string') {
                    url.searchParams.set('depot', depot.toUpperCase());
                }
            }

            const grade = this.searchForm.get('grade').value;
            if (grade != null && grade != undefined && grade != '') {
                url.searchParams.set('grade', grade);
            }

            const region = this.searchForm.get('region').value;
            if (region != null && region != undefined && region != '') {
                url.searchParams.set('region', region);
            }
            const requestId = this.searchForm.get('requestId').value;
            if (requestId != null && requestId != undefined && requestId != '') {
                url.searchParams.set('requestId', requestId);
            }

            const gradeGroup = this.searchForm.get('gradeGroupName').value;
            if (gradeGroup != null && gradeGroup != undefined && gradeGroup != '') {
                url.searchParams.set('gradegroup', gradeGroup);
            }

            const heap = this.searchForm.get('heap').value;
            if (heap != null && heap != undefined && heap != '') {
                url.searchParams.set('heap', heap);
            }

            this.clipboard.copy(url.toString());

            this.toastr.success('Link successfully copied to clipboard', 'Success');
        } catch (e) {
            this.toastr.error('An error occured while copying the filters. Please try again.', 'Error');
        }
    }
}

interface PendingAndAllocatedViewModelExtended extends PendingAndAllocatedViewModel {
    showingAllocations: boolean;
    isSelected: boolean;
}

interface AllocatedViewModelExtended extends AllocatedViewModel {
    showingGroup: boolean;
    isSelected: boolean;
}

interface FilterNamesExtended extends FilterNames {
    isSelected: boolean;
}
