import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { AppointmentService } from '../gen/services/appointment.service';
import { AppointmentQueryModel, AppointmentVm, ImportStatus } from '../gen/models';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SortInfo } from '../table-sort/table-sort.directive';
import { MatDialog } from '@angular/material/dialog';
import { NewAppointmentDialogComponent } from '../new-appointment-dialog/new-appointment-dialog.component';
import { ImportAppointmentDialogComponent } from '../import-appointment-dialog/import-appointment-dialog.component';
import { isUndefined, isNullOrUndefined } from '../tools';
import { ToastrService } from 'ngx-toastr';
import { ActionConfirmDialogComponent } from '../action-confirm-dialog/action-confirm-dialog.component';
import { SpinnerComponent } from '../spinner/spinner.component';
import { Subscription } from 'rxjs';
import { EnumVals } from '../gen/enums/enums';

@Component({
	selector: 'app-appointment',
	templateUrl: './appointment.component.html',
	styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent implements OnInit, OnDestroy {
	showSearch = false;
	isLoading = true;
	tableState: AppointmentQueryModel;
	appointments: AppointmentVm[] = [];
	appointment: AppointmentVm = null;
	importStatus: ImportStatus;
	contractLineAllocationIds: string = null;
	isAllocated = false;
	// enableEdit = false;
	searchForm: FormGroup;
	page = 1;
	pageSize = EnumVals.NoOfResults[1].value;
	collectionSize = 1;
	noRecord = false;
	sortReverse = true;
	querySubscription: Subscription;

  @ViewChild('spinner', { static: true }) spinner: SpinnerComponent;

  constructor(
    private appointmentService: AppointmentService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private toastr: ToastrService) { }

  get enablePrint(): boolean {
  	return this.selectedAppointments
      && this.selectedAppointments.length > 0
      && this.selectedAppointments.every(a => a.print);
  }

  get enableEdit(): boolean {
  	if (!this.selectedAppointments ||
      this.selectedAppointments.length === 0) { return false; }
  	if (this.selectedAppointments.length > 1) { return false; }
  	const allocationId = this.selectedAppointments[0].contractLineAllocationId;
  	return true;
  }

  get selectedAppointments(): AppointmentVmExtended[] {
  	return this.extendedAppointments.filter(a => a.isSelected);
  }

  get extendedAppointments(): AppointmentVmExtended[] {
  	return this.appointments as AppointmentVmExtended[];
  }

  ngOnInit() {

  	this.callServer({
  		pagination: { number: this.pageSize, start: 0 },
  		search: {
  			customer: null,
  			contractRef: null,
  			grade: null,
  			materialDescription: null,
  			deliveryNumber: null,
  			startDate: null,
  			deliveryWindowStart: null,
  			endDate: null,
  			deliveryWindowEnd: null,
  			comments: null,
  			readyToPrint: null,
  			status: null
  		},
  		sort: { predicate: null, reverse: this.sortReverse }
  	});

  	this.searchForm = this.fb.group({
  		customer: '',
  		contractRef: '',
  		grade: '',
  		materialDescription: '',
  		deliveryNumber: '',
  		startDate: '',
  		deliveryWindowStart: '',
  		endDate: '',
  		deliveryWindowEnd: '',
  		comments: '',
  		readyToPrint: false,
  		status: ''
  	});

  }
  ngOnDestroy(): void {
  	this.isLoading = false;
  	this.spinner.overlayRef.dispose();
  	if (this.querySubscription != null && !this.querySubscription.closed) {
  		this.querySubscription.unsubscribe();
  	}
  }

  callServer(tableState: AppointmentQueryModel) {
  	this.isLoading = true;

  	if (tableState) {
  		this.tableState = tableState;
  	}

  	if (this.querySubscription != null && !this.querySubscription.closed) {
  		this.querySubscription.unsubscribe();
  	}

  	this.querySubscription = this.appointmentService.getAppointments(tableState).subscribe(r => {
  		this.appointments = r.appointments;
  		this.collectionSize = r.recordCount;
  		this.isLoading = false;
  		this.isAllocated = false;
  		// this.enableEdit = false;
  		if (this.tableState.pagination.start === 1) {
  			this.page = 1;
  		}
  		this.setPrint();
  	});
  	if (!(this.appointments.length > 0)) {
  		this.noRecord = true;
  	}
  }

  sortByInfo(info: SortInfo) {
  	if (info.active) {
  		this.tableState.sort.reverse = info.direction != 'asc';
  		this.sortReverse = this.tableState.sort.reverse;
  		this.tableState.sort.predicate = info.active;
  		this.callServer(this.tableState);
  	}
  }

  newAppointment() {
  	const dialogRef = this.dialog.open(NewAppointmentDialogComponent, {
  		minWidth: '240px',
  		maxWidth: '80%',
  		maxHeight: '80%',
  		data: null,
  		disableClose: true,
  	});

  	dialogRef.afterClosed().subscribe(r => {
  		this.callServer(this.tableState);
  	});
  }

  editAppointment() {
  	const dialogRef = this.dialog.open(NewAppointmentDialogComponent, {
  		minWidth: '240px',
  		maxWidth: '80%',
  		maxHeight: '80%',
  		data: this.appointment,
  		disableClose: true,
  	});

  	dialogRef.afterClosed().subscribe(r => {
  		this.callServer(this.tableState);
  	});
  }

  deleteAppointment() {
  	const dialogRef = this.dialog.open(ActionConfirmDialogComponent, {
  		minWidth: '240px',
  		maxWidth: '80%',
  		maxHeight: '80%',
  		data: {
  			type: 'appointment',
  			model: this.appointment,
  			action: 'delete'
  		},
  		disableClose: true,
  	});

  	dialogRef.afterClosed().subscribe(r => {
  		if (isUndefined(r)) { return; }
  		this.appointmentService.deleteAppointment(r.appointmentId).subscribe(a => {
  			this.success('Appointment successfully deleted', 'Delete Appointment');
  		},
  		err => {
  			if (err.status === 200) {
  				this.success('Appointment successfully deleted', 'Delete Appointment');
  			} else {
  				this.dialog.open(AlertDialogComponent, {
  					minWidth: '240px',
  					maxWidth: '80%',
  					maxHeight: '80%',
  					data: {
  						ok: 'Ok',
  						aria: 'Delete Failed',
  						title: 'Delete Failed',
  						body: 'Something went wrong and the appointment could not be deleted. Please try again later.'
  					}
  				});
  			}
  		});
  	});
  }

  search(formVal: any) {
  	const filter: any = {
  		customer: formVal.customer,
  		contractRef: formVal.contractRef,
  		grade: formVal.grade,
  		materialDescription: formVal.materialDescription,
  		deliveryNumber: formVal.deliveryNumber,
  		startDate: formVal.startDate,
  		deliveryWindowStart: formVal.deliveryWindowStart,
  		endDate: formVal.endDate,
  		deliveryWindowEnd: formVal.deliveryWindowEnd,
  		comments: formVal.comments,
  		readyToPrint: formVal.readyToPrint,
  		status: formVal.status
  	};
  	this.tableState.search = filter;
  	this.tableState.pagination.start = 0;
  	this.callServer(this.tableState);
  }
  exportToExcel() {
  	this.isLoading = true;
  	this.appointmentService
  		.exportAppointmentsToExcelAndSave(
  			'Appointments.xlsx',
  			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', this.tableState)
  		.subscribe(r => { this.isLoading = false; });
  }
  checkboxLabel(val: any) {

  }

  setPrint() {
  	for (const a of this.extendedAppointments) {
  		if (a.contractLineAllocationId > 0 && a.haulierAccountNo != null && a.haulierAccountNo !== '') {
  			a.print = true;
  		} else {
  			a.print = false;
  		}
  	}

  	// this.enablePrint();
  }

  // enablePrint() {
  //   const extenedAppointments = this.appointments as AppointmentVmExtended[];
  //   const selectedRowsToPrint = extenedAppointments.filter((a) => a.print);
  //   if (selectedRowsToPrint != null && selectedRowsToPrint.length > 0) {
  //     this.isAllocated = true;
  //   } else {
  //     this.isAllocated = false;
  //   }
  // }
  selectAppointment(item: AppointmentVmExtended, event: MouseEvent) {
  	if (!event.ctrlKey) {
  		this.clearSelected();
  	}
  	// if (!(item.contractLineAllocationId > 0)) {
  	//   this.enableEdit = true;
  	// } else {
  	//   this.enableEdit = false;
  	// }
  	if (item.isSelected) {
  		item.isSelected = false;
  	} else {
  		item.isSelected = true;
  		this.appointment = item;
  	}
  }


  clearSelected() {
  	for (const a of this.extendedAppointments) {
  		if (a.isSelected) {
  			a.isSelected = false;
  		}
  	}
  }
  printAppointment(contractLineAllocationId?: number) {
  	if (isNullOrUndefined(contractLineAllocationId)) {
  		this.contractLineAllocationIds = null;
  		const selectedRowsToPrint = this.extendedAppointments.filter((a) => a.print && a.isSelected);
  		for (const a of selectedRowsToPrint) {
  			if (this.contractLineAllocationIds == null) {
  				this.contractLineAllocationIds = a.contractLineAllocationId.toString();
  			} else {
  				this.contractLineAllocationIds = this.contractLineAllocationIds + ',' + a.contractLineAllocationId.toString();
  			}
  		}
  		if (this.contractLineAllocationIds != null) {
  			window.open('print-appointment/' + this.contractLineAllocationIds);
  		}
  	} else {
  		window.open('print-appointment/' + contractLineAllocationId);
  	}
  }

  importAppointment() {
  	const dialogRef = this.dialog.open(ImportAppointmentDialogComponent, {
  		minWidth: '140px',
  		maxWidth: '60%',
  		maxHeight: '40%',
  		data: null,
  		disableClose: true,
  	});

  	dialogRef.afterClosed().subscribe(r => {
  		if (isUndefined(r)) { return; }
  		this.importStatus = r;
  		if (this.importStatus.importSuccess) {
  			this.callServer(this.tableState);
  		}
  	});

  }
  pageChanged(page: number) {
  	this.page = page;
  	this.tableState.pagination.start = page;
  	this.callServer(this.tableState);
  }
  success(message: string, title: string) {
  	this.callServer(this.tableState);
  	this.toastr.success(message, title);
  }

  clearFilter() {
  	this.searchForm.reset();
  }

}
interface AppointmentVmExtended extends AppointmentVm {
  isSelected: boolean;
  print: boolean;
}
