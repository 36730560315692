<div style="height: 450px;">
  <app-spinner [displayProgressSpinner]="loadingEditMultiAllocatedRequestDialog"
    [container]="loadingEditMultiAllocatedRequestDialogBox" #loadingEditMultiAllocatedRequestDialogSpinner>
  </app-spinner>
  <div #loadingEditMultiAllocatedRequestDialogBox>
    <form [formGroup]="allocationForm" (ngSubmit)="update()">

      <table class="table">
        <thead>
          <th>Selected</th>
          <th>Allocation<br>Number</th>
          <th>Depot</th>
          <th>Allocation<br>Status</th>
          <th>Allocated<br>Date</th>
          <th>Contract Ref</th>
          <th>Party</th>
          <th>Classification</th>
          <th>Grade</th>
          <th>Sales Grade</th>
          <th>Pick-up Date & Time</th>
          <th>Pick-up To Date & Time</th>
          <th>Custome Apt Ref</th>
          <th>Customer Apt From Date & Time</th>
          <th>Customer Apt To Date & Time</th>
          <th>Instructions</th>
          <th>Additional<br>Costs</th>
          <th>Haulier</th>
          <th>Missed Drop Off</th>
          <th>Agreed Haulage Rate</th>
          <th>Agreed Haulage Rate UOM</th>
        </thead>
        <tbody>
          <tr *ngFor="let a of data.allocations">
            <td>
              <mat-checkbox matTooltip="edit selected" [checked]="a.isSelected" (change)="valueChange(a, $event)">

              </mat-checkbox>
            </td>
            <td>{{a.allocationNumber}}</td>
            <td>{{a.depot}}</td>
            <td>{{parseAllocationStatus(a?.contractLineAllocationStatus)}}</td>
            <td>{{a?.allocatedDate | momentUtcAsLocaleDate}}</td>
            <td>{{a.contractRef}}</td>
            <td>{{a.party}}</td>
            <td>{{a.classification}}</td>
            <td>{{a.grade}}</td>
            <td>{{a.salesGrade}}</td>
            <td>{{a.requestedMovementDate | momentUtcAsLocaleDateTime}}</td>
            <td>{{a.requestedMovementDateEnd | momentUtcAsLocaleDateTime}}</td>
            <td>{{a.aptNumber}}</td>
            <td>{{a?.aptDateTime | momentUtcAsLocaleDateTime}}</td>
            <td>{{a?.aptDateTimeTo | momentUtcAsLocaleDateTime}}</td>
            <td matTooltipClass="instructionsTooltip" [matTooltip]="a?.instructions">{{a?.instructions | shorten : '10'}}</td>
            <td>
              <table class="sub-table">
                <tbody>
                  <tr *ngFor="let ac of a.additionalCosts" [ngStyle]="getRowStyle(ac.costId)">
                    <td>{{ac.costCode}}</td>
                    <td>{{ac.partyAccountNo}}</td>
                    <td>{{ac.cost}}/{{ac.costUomCode}}</td>
                    <td>
                      <a (click)="toggleRemoveCost(ac.costId)" class="link-btn">
                        <fa-icon [icon]="trash"></fa-icon>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>

            <td>{{a.haulier}}</td>
            <td>{{a.missedDropOff ? 'Y' : 'N'}}</td>
            <td>{{a.agreedHaulageRate}}</td>
            <td>{{a.agreedHaulageRateUomCode}}</td>
          </tr>
          <tr>
            <td colspan="21">
              <div class="row-container-center">
                <mat-form-field>
                  <input matInput type="text" aria-label="contract ref" placeholder="Contract Ref" class="md-input"
                    formControlName="contractRef" autocomplete="off" maxlength="45" (keydown)="openContractSearchDialog($event)" (mousedown)="openContractSearchDialog($event)">
                </mat-form-field>
                <mat-form-field>
                  <input matInput type="text" aria-label="depot no" placeholder="Depot No" class="md-input"
                    formControlName="depotNo" autocomplete="off" maxlength="45" oninput="this.value = this.value.toUpperCase()">
                </mat-form-field>

                <mat-form-field>
                  <mat-select formControlName="containerTypeId" placeholder="Container Type">
                    <mat-option *ngFor="let container of containerTypes" [value]="container.containerTypeId">
                      {{container.containerTypeName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>



                <app-date-time-picker placeholder="Pickup From Date & Time" formControlName="pickupFromDateTime"
                  (change)="updatePickupDate()">
                </app-date-time-picker>
                <app-date-time-picker placeholder="Pickup To Date & Time" formControlName="pickupToDateTime"
                  (change)="updatePickupDate()">
                </app-date-time-picker>
                <button type="button" mat-flat-button style="height:50px;" [disabled]="this.showRatesDisabled"
                  (click)="getAvailableHauliers()" color="primary">Show Rates
                </button>

                <mat-form-field>
                  <mat-select name="hpm" id="hpm" placeholder="Available rates" [(value)]="selectedHaulageMatrix"
                    (selectionChange)="updateHaualgeRate($event)">
                    <mat-option *ngFor="let r of haulageRates" [value]="r">
                      {{r.haulageRate}} &nbsp; {{r.currency}} / {{r.haulierName}} &nbsp; ({{r.haulierAccountNo}}) /
                      {{r.shippingLine}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <button type="button" mat-flat-button style="height: 50px; margin-right:10px;"
                  [disabled]="haulageRates.length==0" (click)="fixHaulageRate()" color="primary">Fix</button>
              </div>
              <div class="row-container-center">
                <mat-form-field>
                  <input matInput type="text" aria-label="customer apt ref" placeholder="Customer Apt Ref"
                    class="md-input" formControlName="customerAptRef" autocomplete="off" maxlength="45">
                </mat-form-field>

                <app-date-time-picker placeholder="Customer Apt From Date & Time" formControlName="customerAptDateTime">
                </app-date-time-picker>
                <app-date-time-picker placeholder="Customer Apt To Date & Time" formControlName="customerAptToDateTime">
                </app-date-time-picker>

                <mat-form-field>
                  <input type="text" matInput formControlName="haulier" #haulier [matAutocomplete]="haulierAuto"
                    placeholder="Haulier" testName="haulier" oninput="this.value = this.value.toUpperCase()">
                  <button mat-button *ngIf="showHaulierClear(haulier)" type="button" matSuffix mat-icon-button
                    aria-label="Clear" testName="haulierClear" (click)="haulierClear()">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <mat-autocomplete #haulierAuto="matAutocomplete" [displayWith]="displayHaulier">
                  <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
                  <mat-option *ngFor="let haulier of filteredHauliers" [value]="haulier">{{haulier?.accountNumber}} -
                    {{haulier?.name}}</mat-option>
                </mat-autocomplete>
                <mat-form-field>
                  <input matInput type="number" aria-label="agreed haulage rate" placeholder="{{spotRateText}}" testName="rate"
                    class="md-input" formControlName="agreedHaulageRate" autocomplete="off"
                    (change)="updateHaulageRateDropdown()">
                </mat-form-field>
                <mat-form-field>
                  <mat-select formControlName="agreedHaulageRateUomId" testName="uom" placeholder="Spot Rate UOM">
                    <mat-option *ngFor="let uom of uoms" [value]="uom.id">
                      {{uom.code + ' - ' + uom.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <span *ngIf="dateError" class="error-message">
        <i class="fa fa-exclamation-triangle"></i>
        {{dateErrorDesc}}
      </span>

      <div class="row-container">
        <app-additional-costs [showCollapse]="false" class="additionalCosts" [maxCosts]="maxCosts" [uoms]="uoms"
          formControlName="additionalCosts"></app-additional-costs>
      </div>
      <div class="row-container">
          <mat-radio-group aria-label="Instructions mode" (change)="changeInstructionsEditMode()" [(ngModel)]="instructionsEditMode" [ngModelOptions]="{standalone: true}">
            <mat-radio-button value="Append">Append</mat-radio-button>
            <mat-radio-button value="Edit">Edit</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="row-container">
        <mat-form-field>
          <textarea cdkTextareaAutosize matInput placeholder="Instructions" [maxlength]="instructionsMaxlength" class="md-input"
            formControlName="instructions" (keyup)="onInstructionsKeyUp()"></textarea>
          <div class="md-errors-spacer"></div>
          <div class="textarea-counter">{{remainingInstructionsText}}</div>
        </mat-form-field>
      </div>

      <div mat-dialog-actions class="buttons-row">
        <button mat-flat-button type="button" (click)="cancel()">Close</button>
        <button mat-flat-button type="submit" color="primary" testName="update" [disabled]="isSubmit && allocationForm.pristine && costIdsToRemove.length === 0">Update</button>
        <button mat-flat-button type="button" (click)="updateAndPrintAllocations()" color="primary" testName="update$print" [disabled]="isSubmit && allocationForm.pristine && costIdsToRemove.length === 0">Update & Print</button>
      </div>
    </form>
  </div>
</div>
