import { Component, OnInit, Input } from '@angular/core';
import { AllocateRequestGroupDetail } from '../gen/models';
import { BehaviorSubject } from 'rxjs';
import { delay, debounceTime } from 'rxjs/operators';
import { isNullOrUndefined } from '../tools';

@Component({
	selector: 'app-allocate-grouped-request-detail',
	templateUrl: './allocate-grouped-request-detail.component.html',
	styleUrls: ['./allocate-grouped-request-detail.component.scss']
})
export class AllocateGroupedRequestDetailComponent implements OnInit {

  @Input() detail: AllocateRequestGroupDetail[];
  showDetail = false;
  detailStyle = {};
  choords = new BehaviorSubject<ShowCoords>(null);

  constructor() { }

  ngOnInit() {
  	this.choords
  		.pipe(delay(200))
  		.subscribe(sc => {
  			if (isNullOrUndefined(sc)) { return; }
  			this.showDetail = sc.show;
  			this.detailStyle = {
  				top: `${sc.y}px` ,
  				left: `${sc.x}px`,
  				position: 'absolute',
  				'z-index': '10',
  				'background-color': 'white',
  				'box-shadow': '3px 4px 4px #888888',
  				border: 'solid 1px #888888',
  				padding: '2px'
  			};

  		});

  }

  showHideDetail(show: boolean, event: MouseEvent) {
  	this.choords.next({
  		show, x: event.x, y: event.y
  	});
  }


}

interface ShowCoords {
  show: boolean;
  x: number;
  y: number;
}
