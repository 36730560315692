import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AllocationsComponent } from './allocations/allocations.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BookingSheetComponent } from './booking-sheet/booking-sheet.component';
import { ConversationViewComponent } from './conversation-view/conversation-view.component';
import { LoadTimesViewComponent } from './load-times-view/load-times-view.component';
import { LoadScheduleViewComponent } from './load-schedule-view/load-schedule-view.component';
import { AttachmentsViewComponent } from './attachments-view/attachments-view.component';
import { GenServicesModule } from './gen/gen-services-module';
import { EditAllocatedRequestDialogComponent } from './edit-allocated-request-dialog/edit-allocated-request-dialog.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { REST_AUTH_TOKEN } from './gen/rest-auth-service';
import { REST_CONFIG_TOKEN } from './gen/rest-config-service';
import { REST_TOKEN_TOKEN } from './gen/rest-token-service';
import { ServicesModule } from './services/services.module';
import { RestAuthImplService } from './rest-auth-impl-service';
import { RestConfigImplService } from './rest-config-impl-service';
import { RestTokenImplService } from './rest-token-impl-service';
import { StartupService } from './startup.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import { MomentLocalePipe } from './pipes/moment-locale.pipe';
import { MomentLocaleDateTimePipe } from './pipes/moment-locale-date-time.pipe';
import { MomentLocalTimePipe } from './pipes/moment-local-time.pipe';
import { MomentUtcAsLocaleDateTimePipe } from './pipes/moment-utc-as-locale-date-time.pipe';
import { MomentUtcAsLocaleDatePipe } from './pipes/moment-utc-as-locale-date.pipe';
import { MomentUtcAsLocaleTimePipe } from './pipes/moment-utc-as-locale-time.pipe';
import { GenericFilterPipe } from './pipes/generic-filter-pipe';
import { TimeFormatPipe } from './time-format.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TableSortDirective } from './table-sort/table-sort.directive';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AngularMaterialModule } from './shared/angular-material.module';
import { MAT_DATE_FORMATS, MatNativeDateModule} from '@angular/material/core';
import { EditRequestDialogComponent } from './edit-request-dialog/edit-request-dialog.component';
import { NewAppointmentDialogComponent } from './new-appointment-dialog/new-appointment-dialog.component';
import { ImportAppointmentDialogComponent } from './import-appointment-dialog/import-appointment-dialog.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { AllocateComponent } from './allocate/allocate.component';
import { AppointmentComponent } from './appointment/appointment.component';
import { PrintAppointmentComponent } from './print-appointment/print-appointment.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AllocateDialogComponent } from './allocate-dialog/allocate-dialog.component';
import { AllocateMultiGradeDialogComponent } from './allocate-multi-grade-dialog/allocate-multi-grade-dialog.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CloneEditAllocatedRequestDialogComponent } from './clone-edit-allocated-request-dialog/clone-edit-allocated-request-dialog.component';
import {
    faTrash,
    faTruck,
    faShoppingBasket,
    faHandPaper,
    faTimes,
    faHandRock,
    faTimesCircle,
    faPlusCircle,
    faMinusCircle,
    faSearch,
    faShoppingCart,
    faIndustry,
    faStopwatch,
    faClipboardCheck,
    faCircleNotch,
    faHistory,
    faObjectGroup,
    faObjectUngroup,
    faCopy,
    faClock,
    faClone,
    faLink,
    faPrint,
    faCalendar,
    faDownload,
    faSort,
    fas,
    faCaretLeft,
    faCaretRight
} from '@fortawesome/free-solid-svg-icons';
import { ShortenPipe } from './pipes/shorten.pipe';
import { ActionConfirmDialogComponent } from './action-confirm-dialog/action-confirm-dialog.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';
import { AllocateCreateDialogComponent } from './allocate-create-dialog/allocate-create-dialog.component';
import { ToastrModule } from 'ngx-toastr';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { MultiselectComponent } from './multiselect/multiselect.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { RequestsComponent } from './requests/requests.component';
import { InstructionToMoveComponent } from './instruction-to-move/instruction-to-move.component';
import { ErrorInterceptor } from './shared/error.interceptor';
import { EditMultiAllocatedRequestDialogComponent } from './edit-multi-allocated-request-dialog/edit-multi-allocated-request-dialog.component';
import { AdditionalCostsComponent } from './additional-costs/additional-costs.component';
import { ChangeHistoryDialogComponent } from './change-history-dialog/change-history-dialog.component';
import { NewRequestDialogComponent } from './new-request-dialog/new-request-dialog.component';
import { MultiGradeAllocationDetailComponent } from './multi-grade-allocation-detail/multi-grade-allocation-detail.component';
import { AllocateGroupedRequestDetailComponent } from './allocate-grouped-request-detail/allocate-grouped-request-detail.component';
import { SingleDataCaptureDialogComponent } from './single-data-capture-dialog/single-data-capture-dialog.component';
import { SendMailDialogComponent } from './send-mail-dialog/send-mail-dialog.component';
import { DateRangeComponent } from './date-range/date-range.component';
import { AllocationAppointmentsComponent } from './allocation-appointments/allocation-appointments.component';
import { AutocompleteDisclaimerComponent } from './autocomplete-disclaimer/autocomplete-disclaimer.component';
import { ADGroupService } from './gen/services/adgroup.service';
import { UserPreferencesComponent } from './user-preferences/user-preferences.component';
import { LiveLoadsComponent } from './live-loads/live-loads.component';
import { TerritoryResolverService } from './gen/services/resolver/territoryResolver.service';
import { DepotResolverService } from './gen/services/resolver/depotResolver.service';
import { OrderBookTypeResolver } from './gen/services/resolver/orderBookTypeResolver.service';
import { UserPreferencesResolver } from './gen/services/resolver/userPreferencesResolver.service';
import { ContractLineSearchComponent } from './contract-line-search/contract-line-search.component';
import { CtrlClickDirective } from './ctrl-click.directive';

/* Calendar Packages*/
import { CalendarModule,DateAdapter } from 'angular-calendar';
import {MatTabsModule} from '@angular/material/tabs';
import { EventCalendarComponent } from './event-calendar/event-calendar.component';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CalendarCommonService } from './event-calendar/event-calendar-common-service';
import { ViewAllocatedRequestDialogComponent } from './view-allocated-request-dialog/view-allocated-request-dialog.component';
import { EventCalendarMoreDialogComponent } from './event-calendar-more/event-calendar-more-dialog.component';
import { KpiTrackingComponent } from './kpi-tracking/kpi-tracking.component';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AppWeekViewComponent } from './modules/week/app-week-view/app-week-view.component';
import { AppWeekViewEventComponent } from './modules/week/app-week-view-event/app-week-view-event.component';
import { CalendarDayViewComponent } from './modules/day/calendar-day-view.component';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { UserTableViewSettingComponent } from './user-table-view-setting/user-table-view-setting.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { TransportPlanComponent } from './transport-plan/transport-plan.component';
import { MatSelectModule } from '@angular/material/select';
import { CustomToastComponent } from './modules/custom-toast/custom-toast.component';
import { InstructionToMoveService } from './gen/services/instruction-to-move.service';
import { NewInstructionToMoveDialogComponent } from './new-instruction-to-move-dialog/new-instruction-to-move-dialog.component';
import { EditInstructionToMoveDialogComponent } from './edit-instruction-to-move-dialog/edit-instruction-to-move-dialog.component';
import { RouteToMarketComponent } from './route-to-market/route-to-market.component';
import { RouteToMarketDialogUpdateComponent } from './route-to-market-dialog-update/route-to-market-dialog-update.component';
import { RouteToMarketDialogImportComponent } from './route-to-market-dialog-import/route-to-market-dialog-import.component';
import { RouteToMarketDialogAddComponent } from './route-to-market-dialog-add/route-to-market-dialog-add.component';
import { NewInventoryReservationDialogComponent } from './new-inventory-reservations-dialog/new-inventory-reservation-dialog.component';
import { EditInventoryReservationDialogComponent } from './edit-inventory-reservations/edit-inventory-reservation-dialog.component';
import { AllocateInventoryComponent } from './allocate-inventory/allocate-inventory.component';
import { AllocateInventoryService } from './gen/services/allocate-inventory.service';
import { NewInstructionToMoveRequestDialogComponent } from './new-instruction-to-move-request-dialog/new-instruction-to-move-request-dialog.component';

export const MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'LL',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@NgModule({
    declarations: [
        AppComponent,
        AllocationsComponent,
        BookingSheetComponent,
        TransportPlanComponent,
        ConversationViewComponent,
        LoadTimesViewComponent,
        LoadScheduleViewComponent,
        AttachmentsViewComponent,
        EditAllocatedRequestDialogComponent,
        MomentLocalePipe,
        MomentLocaleDateTimePipe,
        GenericFilterPipe,
        TimeFormatPipe,
        MomentLocalTimePipe,
        MomentUtcAsLocaleDateTimePipe,
        MomentUtcAsLocaleDatePipe,
        MomentUtcAsLocaleTimePipe,
        ShortenPipe,
        TableSortDirective,
        EditRequestDialogComponent,
        NewAppointmentDialogComponent,
        ImportAppointmentDialogComponent,
        AlertDialogComponent,
        AllocateComponent,
        AppointmentComponent,
        PrintAppointmentComponent,
        AllocateDialogComponent,
        AllocateMultiGradeDialogComponent,
        ActionConfirmDialogComponent,
        DateTimePickerComponent,
        AllocateCreateDialogComponent,
        AutocompleteComponent,
        MultiselectComponent,
        SpinnerComponent,
        RequestsComponent,
        InstructionToMoveComponent,
        EditMultiAllocatedRequestDialogComponent,
        AdditionalCostsComponent,
        ChangeHistoryDialogComponent,
        NewRequestDialogComponent,
        MultiGradeAllocationDetailComponent,
        AllocateGroupedRequestDetailComponent,
        SingleDataCaptureDialogComponent,
        SendMailDialogComponent,
        DateRangeComponent,
        AllocationAppointmentsComponent,
        AutocompleteDisclaimerComponent,
        AutocompleteDisclaimerComponent,
        UserPreferencesComponent,
        LiveLoadsComponent,
        ContractLineSearchComponent,
        CtrlClickDirective,
        EventCalendarComponent,
        ViewAllocatedRequestDialogComponent,
        EventCalendarMoreDialogComponent,
        CalendarDayViewComponent,
        AppWeekViewComponent,
        AppWeekViewEventComponent,
        KpiTrackingComponent,
        UserTableViewSettingComponent,
        CloneEditAllocatedRequestDialogComponent,
        NewInstructionToMoveDialogComponent,
        EditInstructionToMoveDialogComponent,
        RouteToMarketComponent,
        RouteToMarketDialogUpdateComponent,
        RouteToMarketDialogImportComponent,
        RouteToMarketDialogAddComponent,
        CustomToastComponent,
        NewInventoryReservationDialogComponent,
        EditInventoryReservationDialogComponent,
        AllocateInventoryComponent,
        NewInstructionToMoveRequestDialogComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        AngularMaterialModule,
        GenServicesModule,
        ServicesModule,
        LayoutModule,
        NgbModule,
        OAuthModule.forRoot(),
        DragDropModule,
        FontAwesomeModule,
        NgxMaterialTimepickerModule,
        ToastrModule.forRoot({
            toastComponent: CustomToastComponent
        }),
        MatNativeDateModule,
        FlatpickrModule.forRoot(),
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        MatTabsModule,
        MatGridListModule,
        MatSelectModule
    ],
    providers: [
        StartupService,
        {provide: APP_INITIALIZER, useFactory: startupFactory, deps: [StartupService], multi: true},
        {provide: REST_AUTH_TOKEN, useClass: RestAuthImplService},
        {provide: REST_CONFIG_TOKEN, useClass: RestConfigImplService},
        {provide: REST_TOKEN_TOKEN, useClass: RestTokenImplService},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
        ADGroupService,
        TerritoryResolverService,
        DepotResolverService,
        OrderBookTypeResolver,
        UserPreferencesResolver,
        CalendarCommonService,
        InstructionToMoveService,
        AllocateInventoryService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    bootstrap: [AppComponent],
    entryComponents: [
        EditRequestDialogComponent,
        EditAllocatedRequestDialogComponent,
        ContractLineSearchComponent,
        AlertDialogComponent,
        AllocateDialogComponent,
        AllocateMultiGradeDialogComponent,
        ActionConfirmDialogComponent,
        AllocateCreateDialogComponent,
        NewAppointmentDialogComponent,
        ImportAppointmentDialogComponent,
        EditMultiAllocatedRequestDialogComponent,
        ChangeHistoryDialogComponent,
        NewRequestDialogComponent,
        SingleDataCaptureDialogComponent,
        SendMailDialogComponent,
        ViewAllocatedRequestDialogComponent,
        EventCalendarMoreDialogComponent,
        UserTableViewSettingComponent,
        NewInstructionToMoveDialogComponent,
        EditInstructionToMoveDialogComponent,
        NewInventoryReservationDialogComponent,
        EditInventoryReservationDialogComponent
    ]
})
export class AppModule {
    constructor(library: FaIconLibrary) {
        library.addIconPacks(fas);
        library.addIcons(
            faTrash,
            faTruck,
            faShoppingBasket,
            faHandPaper,
            faTimes,
            faHandRock,
            faTimesCircle,
            faPlusCircle,
            faMinusCircle,
            faSearch,
            faShoppingCart,
            faIndustry,
            faStopwatch,
            faClipboardCheck,
            faCircleNotch,
            faHistory,
            faObjectGroup,
            faObjectUngroup,
            faCopy,
            faClock,
            faClone,
            faLink,
            faPrint,
            faDownload,
            faSort
        );
    }
}

export function startupFactory(service: StartupService) {
    return (): Promise<void> => service.load();
}
