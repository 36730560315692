<mat-form-field class="chip-list">
  <mat-chip-list #chipList class="chip">
    <mat-chip class="chip"
      *ngFor="let r of results"
      [selectable]="selectable"
      [removable]="removable"
      (removed)="remove(r)">
      {{r}}
      <mat-icon class="chip" matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input
      [placeholder]="placeholder"
      #autoInput
      (input)="inputValue($event)"
      [formControl]="autoComplete"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
      <mat-spinner [diameter]="20" [strokeWidth]="2" matSuffix *ngIf="loading" [color]="spinColor"></mat-spinner>
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected(displayValueWith($event))">
    <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
    <mat-option *ngFor="let r of allResults" [value]="r">
      {{displayWith(r)}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
