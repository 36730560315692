<div class="mastdiv">
  <div [ngClass]="mastFormStyle">
    <!-- Masthead - START -->
    <div class="masthead">
      <div class="masthead-logo"> <img src="../../assets/svg/logo-masthead-emryardmanagement.svg"
          class="svg-logo-desktop"> <img src="../../assets/svg/logo-masthead-emryardmgmt.svg" class="svg-logo-mobile">
      </div>
      <div class="masthead-divider"></div>
      <div class="masthead-livedot"> <img src="../../assets/svg/icon-livedot.svg"
          class="svg-livedot animated-dur-1s infinite flashlive"> </div>
      <div class="masthead-livestatus">
        <p
          class="masthead-livestatus-font font-qanelassoft-extrabold fontcolour-white animated-dur-0pt25s fadeIn anim-delay-0pt5s">
          LIVE</p>
      </div>
      <div class="masthead-updatedstatus">
        <p
          class="masthead-updatedstatus-font font-roboto-regular fontcolour-white animated-dur-0pt25s fadeIn anim-delay-0pt5s">
          <span class="full-text-masthead">Updated:</span><span class="short-text-masthead"></span><br>
          <span class="font-roboto-bold fontcolour-secondary-yellow" *ngIf="!isPaused">1 <span
              class="full-text-masthead">minute</span><span class="short-text-masthead">mins</span> ago</span>
          <span class="font-roboto-bold fontcolour-secondary-yellow" *ngIf="isPaused"> Paused</span>
        </p>
      </div>
      <div class="right-controls-display">
        <div class="mode-position">
          <div class="mode--switch--container">
            <div [ngClass]="modeSwitchStyle" data="mode--switch" (click)="viewModeClicked()">
              <div [ngClass]="modeButtonStyle" data="mode--button">
                <img class="the--button" data="the--button" [src]=viewModeImagePath />
              </div>
            </div>
          </div>
        </div>

        <div class="masthead-button-hamburger">
          <div class="svg-icon-hamburger animated-dur-0pt25s fadeInRight anim-delay-0pt5s" (click)="btnHomeClicked()">
          </div>
        </div>
      </div>
    </div>
    <!-- Masthead - END -->
    <!-- Masthead Spacer - START -->
    <div class="spacer-masthead"></div>
    <!-- Masthead Spacer - END -->
    <!-- Main Area (Grid) - START -->
    <div class="animated-dur-0pt5s fadeIn anim-delay-0pt5s">
      <div class="wrapper-topbottom">
        <div class="wrapper-leftright">
          <!-- Top Row - START -->
          <div class="bigboxes-vfifth-x2-span2">
            <!-- Yard Name, Date and Tabs - START -->

            <form [formGroup]="newLiveLoadsForm">
              <div class="bigbox">
                <div class="littlebox-grid-1colx4rows-nogap">
                  <div ngClass="grid-minibox-row1col1span1 flex flex-wrap space-between noborder">
                    <div [ngClass]="matFieldStyle">
                      <mat-form-field>
                        <input type="text" placeholder="Select Yard" aria-label="Yard" matInput
                                [formControl]="yardControl" [matAutocomplete]="autoYard" spellcheck="false"
                                testName="yardSelector">
                        <mat-autocomplete #autoYard="matAutocomplete">
                          <mat-option *ngFor="let yard of filteredYards | async"
                                      [value]="yard.yardCode + ' - ' + yard.yardName" (onSelectionChange)="onSelectionChange(yard)">
                            {{yard.yardCode}} - {{yard.yardName}}
                          </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngFor="let validation of validation_msgs.yardControl">
                          <div *ngIf="yardControl.hasError(validation.type)">
                            {{validation.message}}
                          </div>
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <app-autocomplete style="margin-left: auto" autoCompleteDelay="750" [displayWith]="displayCustomer"
                      formControlName="customer" [tooltip]="displayCustomer" [getResults]="partiesService.get"
                      placeholder="Customer" [ngClass]="matFieldStyle" class="customerSearch"
                      (selectionChange)="searchCustomerLoads($event)" (cleared)="clearCustomerLoads()">
                    </app-autocomplete>
                    <div class="clearStyle" [ngClass]="btnClearStyle" (click)="clearFilter()">
                      <span>Clear</span>
                    </div>
                    <div class="container-printericon " (click)="btnPrintClicked()">
                      <div class="svg-icon-printer"></div>
                    </div>
                  </div>
                  <div class="grid-minibox-row2col1span1 flex flex-wrap noborder search-criteria">
                    <div class="flex flex-wrap space-between" [ngClass]="matFieldStyle">
                      <mat-form-field class="search-text-size">
                        <input type="text" autocomplete="off" matInput placeholder="Search" (keyup)="onKeyupEvent($event)" [value]=searchTerm />
                      </mat-form-field>
                      <mat-form-field>
                        <mat-select (selectionChange)="statusChange()" formControlName="status" placeholder="Status">
                          <mat-option value="">Any</mat-option>
                          <mat-option value="Abandoned">Abandoned</mat-option>
                          <mat-option value="Booked">Booked</mat-option>
                          <mat-option value="Completed">Completed</mat-option>
                          <mat-option value="InProgress">In Progress</mat-option>
                          <mat-option value="OnRoute">On Route</mat-option>
                          <mat-option value="Requested">Requested</mat-option>
                          <mat-option value="Scheduled">Scheduled</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <app-date-range (onDatePicked)="setPickerDate($event, 'pickup')"
                                      (onDateApply)="onDateApply($event,'pickup')" label="Pick date range"
                                      [fromDate]="pickupRange?.dateFrom" [toDate]="pickupRange?.dateTo"
                                      [dateType]="pickupDateType" [ngClass]="dateFontStyle"
                                      [dateValue]="datePreference">
                      </app-date-range>
                    </div>
                  </div>
                  <div class="grid-minibox-row3col1span1 space-between flex noborder">
                    <div>
                      <span [ngClass]="dayFontStyle">{{dayOfToday}} </span><span [ngClass]="dateFontStyle">{{dateOfToday}}</span>
                    </div>
                    <div>
                        <span [ngClass]="dayFontStyle">Avg. Time On Site : <span [ngClass]="dateFontStyle">{{displayTime(avgOnsiteTime)}}</span></span>
                    </div>
                  </div>
                  <div class="grid-minibox-row4col1span1 noborder flex flex-wrap" *ngIf="isShown">
                    <div [ngClass]="btnThisWeekStyle" (click)="btnThisWeekClicked()" testName="thisWeek">
                      <span class="full-text">This week</span>
                      <span class="short-text">This wk</span>
                    </div>
                    <div class="spacer-button-liveloads"></div>
                    <div [ngClass]="btnYesterdayStyle" (click)="btnYesterdayClicked()" testName="yesterday">
                      <span class="full-text">Yesterday</span>
                      <span class="short-text">Ystd</span>
                    </div>
                    <div class="spacer-button-liveloads"></div>
                    <div [ngClass]="btnTodayStyle" (click)="btnTodayClicked()" testName="today">
                      <span class="full-text">Today</span>
                      <span class="short-text">Today</span>
                    </div>
                    <div class="spacer-button-liveloads"></div>
                    <div [ngClass]="btnTomorrowStyle" (click)="btnTomorrowClicked()" testName="tomorrow">
                      <span class="full-text">Tomorrow</span>
                      <span class="short-text">Tmrw</span>
                    </div>
                    <div class="spacer-button-liveloads"></div>
                    <div [ngClass]="btnNextWeekStyle" (click)="btnNextWeekClicked()" testName="nextWeek">
                      <span class="full-text">Next Week</span>
                      <span class="short-text">Next Wk</span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <!-- Yard Name, Date and Tabs - END -->
            <!-- Counters - START -->
            <div class="bigbox">
              <div class="toggleContainer" [hidden]="!showCounterToggle">
                <button class="toggleCounter pull-right" [ngClass]="toggleCounterStyle" (click)="toggle()">
                  {{toggleCounterState}}
                </button>
                <div class="toggleSpace"></div>
              </div>
              <div class="littlebox-grid-5colsx1row" [hidden]="!showCounter">

                <div class="grid-minibox-row1col1span1" [ngClass]="btnPlannedBgStyle" (click)="btnPlannedClicked()"
                     testName="planned">
                  <div class="displaypanel-inside-top">
                    <div class="displaypanel-inside-inside-20pc-withmargintop">
                      <div class="displaypanel-header-font font-roboto-bold" [ngClass]="btnPlannedFontStyle">Planned
                      </div>
                    </div>
                    <div class="displaypanel-inside-inside-44pc">
                      <div class="displaypanel-value-font font-qanelassoft-bold" [ngClass]="btnPlannedCountFontStyle">
                        {{plannedCounter}}</div>
                    </div>
                  </div>
                  <div class="displaypanel-inside-bottom">
                    <div class="displaypanel-indicator displaypanel-indicator-font font-roboto-medium"
                         [ngClass]="btnPlannedIndicatorStyle">{{plannedViewStatus}}</div>
                  </div>
                </div>

                <div class="grid-minibox-row1col2span1" [ngClass]="btnInProgressBgStyle"
                     (click)="btnInProgressClicked()" testName="onsite">
                  <div class="displaypanel-inside-top">
                    <div class="displaypanel-inside-inside-20pc-withmargintop">
                      <div class="displaypanel-header-font font-roboto-bold" [ngClass]="counterFontStyle">On Site</div>
                    </div>
                    <div class="displaypanel-inside-inside-44pc">
                      <div class="displaypanel-value-font font-qanelassoft-bold" [ngClass]="counterCountFontStyle">
                        {{onSiteCounter}}</div>
                    </div>
                  </div>
                  <div class="displaypanel-inside-bottom">
                    <div class="displaypanel-indicator displaypanel-indicator-font font-roboto-medium"
                         [ngClass]="btnInProgressIndicatorStyle">{{inProgressViewStatus}}</div>
                  </div>
                </div>

                <div class="grid-minibox-row1col3span1" [ngClass]="btnOnRouteBgStyle" (click)="btnOnRouteClicked()"
                     testName="onroute">
                  <div class="displaypanel-inside-top">
                    <div class="displaypanel-inside-inside-20pc-withmargintop">
                      <div class="displaypanel-header-font font-roboto-bold" [ngClass]="counterFontStyle">On Route</div>
                    </div>
                    <div class="displaypanel-inside-inside-44pc">
                      <div class="displaypanel-value-font font-qanelassoft-bold" [ngClass]="counterCountFontStyle">
                        {{onRouteCounter}}</div>
                    </div>
                  </div>
                  <div class="displaypanel-inside-bottom">
                    <div class="displaypanel-indicator displaypanel-indicator-font font-roboto-medium"
                         [ngClass]="btnOnRouteIndicatorStyle">{{onRouteViewStatus}}</div>
                  </div>
                </div>

                <div class="grid-minibox-row1col4span1" [ngClass]="btnInboundBgStyle" (click)="btnInboundClicked()"
                     testName="inbound">
                  <div class="displaypanel-inside-top">
                    <div class="displaypanel-inside-inside-20pc-withmargintop">
                      <div class="displaypanel-header-font font-roboto-bold" [ngClass]="btnInboundFontStyle">Inbound
                      </div>
                    </div>
                    <div class="displaypanel-inside-inside-44pc">
                      <div class="displaypanel-value-font font-qanelassoft-bold" [ngClass]="btnInboundCountFontStyle">
                        {{inboundCount}}</div>
                    </div>
                  </div>
                  <div class="displaypanel-inside-bottom">
                    <div class="displaypanel-indicator displaypanel-indicator-font font-roboto-medium"
                         [ngClass]="btnInboundIndicatorStyle">{{inboundViewStatus}}</div>
                  </div>
                </div>

                <div class="grid-minibox-row1col5span1" [ngClass]="btnOutboundBgStyle" (click)="btnOutboundClicked()"
                     testName="outbound">
                  <div class="displaypanel-inside-top">
                    <div class="displaypanel-inside-inside-20pc-withmargintop">
                      <div class="displaypanel-header-font font-roboto-bold" [ngClass]="btnOutboundFontStyle">Outbound
                      </div>
                    </div>
                    <div class="displaypanel-inside-inside-44pc">
                      <div class="displaypanel-value-font font-qanelassoft-bold" [ngClass]="btnOutboundCountFontStyle">
                        {{outboundCount}}</div>
                    </div>
                  </div>
                  <div class="displaypanel-inside-bottom">
                    <div class="displaypanel-indicator displaypanel-indicator-font font-roboto-medium"
                         [ngClass]="btnOutboundIndicatorStyle">{{outboundViewStatus}}</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Counters - END -->
          </div>
          <!-- Top Row - END -->
          <!-- Bottom Row - START -->
          <app-spinner [displayProgressSpinner]="isLoading" [container]="mainTable" #spinner></app-spinner>
          <div class="bigboxes-vfifth-x3-span1" #mainTable>
            <!-- Table - START -->
            <div class="bigbox">
              <div class="littlebox-grid-1colx1row">
                <div class="grid-minibox-row1col1span1">
                  <div class="bubblewrap-max-thebiggerstuff table-on-mobile">
                    <div class="scrollbar letitscroll">
                      <table>
                        <colgroup>
                          <col style="width: auto">
                          <col style="width: auto">
                          <col style="width: auto">
                          <!-- Col 1 -->
                          <col style="width: 20%">
                          <!-- Col 2 -->
                          <col style="width: auto">
                          <!-- Col 3 -->
                          <col style="width: auto">
                          <!-- Col 4 -->
                          <col style="width: auto">
                          <!-- Col 5 -->
                          <col style="width: auto">
                          <!-- Col 6 -->
                          <col style="width: auto">
                          <!-- Col 7 -->
                          <col style="width: auto">
                          <!-- Col 8 -->
                          <col style="width: auto">
                          <!-- Col 9 -->
                          <col style="width: auto">
                        </colgroup>
                        <thead>
                        <tr style="z-index: 100;">
                          <th [ngClass]="thStyle" appTableSort="Direction" thirdClickReset="true"
                              (sort)="sortByInfo($event)" class="interactive" [reverseIsDefault]="isDefault">
                            <div [ngClass]="{ 'hideControl':showMobileView, 'showControl':!showMobileView }">
                              Direction
                            </div>
                            <div [ngClass]="{ 'showControl':showMobileView, 'hideControl':!showMobileView }">
                              <div class="flex" *ngIf="!isInboundClicked">
                                <img src="../../assets/svg/arrow-up-backgroundblue.svg" width="8px">
                                <img src="../../assets/svg/arrow-down-backgroundblue.svg" width="8px">
                              </div>
                              <div class="flex" *ngIf="isInboundClicked">
                                <img src="../../assets/svg/arrow-up-greyed.svg" width="8px">
                                <img src="../../assets/svg/arrow-down-greyed.svg" width="8px">
                              </div>
                            </div>
                          </th>
                          <th [ngClass]="thStyle" appTableSort="ScheduledDate" thirdClickReset="true"
                              (sort)="sortByInfo($event)" class="interactive d-none-mobile" [reverseIsDefault]="isDefault">
                            Date
                          </th>
                          <th [ngClass]="thStyle" appTableSort="Type" thirdClickReset="true"
                              (sort)="sortByInfo($event)" class="interactive d-none-mobile">
                            Type
                          </th>
                          <th [ngClass]="thStyle" appTableSort="HaulierName" thirdClickReset="true"
                              [reverseIsDefault]="isDefault" (sort)="sortByInfo($event)" class="interactive">
                            Haulier
                          </th>
                          <th [ngClass]="thStyle" appTableSort="JobNumber" thirdClickReset="true"
                              [reverseIsDefault]="isDefault" (sort)="sortByInfo($event)" class="interactive">
                            Job No.
                          </th>
                          <th [ngClass]="thStyle" appTableSort="CustomerLocation" thirdClickReset="true"
                              [reverseIsDefault]="isDefault" (sort)="sortByInfo($event)" class="interactive">
                            Customer
                          </th>
                          <th [ngClass]="thStyle" appTableSort="Planned" thirdClickReset="true"
                              [reverseIsDefault]="isDefault" (sort)="sortByInfo($event)" class="interactive">
                            Planned
                          </th>
                          <th [ngClass]="thStyle" appTableSort="Instruction" thirdClickReset="true"
                              [reverseIsDefault]="isDefault" (sort)="sortByInfo($event)"
                              class="interactive d-none-mobile">
                            Instructions
                          </th>
                          <th [ngClass]="thStyle" appTableSort="Actual" thirdClickReset="true"
                              [reverseIsDefault]="isDefault" (sort)="sortByInfo($event)" class="interactive">
                            Actual
                          </th>
                          <th [ngClass]="thStyle" appTableSort="Remaining" thirdClickReset="true"
                              [reverseIsDefault]="isDefault" (sort)="sortByInfo($event)"
                              class="interactive d-none-mobile">
                            Remaining
                          </th>
                          <th [ngClass]="thStyle" appTableSort="Status" thirdClickReset="true"
                              [reverseIsDefault]="isDefault" (sort)="sortByInfo($event)"
                              class="interactive d-none-mobile">
                            Status
                          </th>
                          <th [ngClass]="thStyle" class="interactive d-none-mobile">
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr [ngClass]="tableStyle" *ngIf="!isLoading && filteredTransportData.length == 0">
                          <td colspan="12">
                            <p [ngClass]="tdStyle" l>No records found!</p>
                          </td>
                        </tr>
                        <ng-template ngFor let-item [ngForOf]="filteredTransportData"
                                     *ngIf="filteredTransportData.length > 0" let-i=index>
                          <tr [ngClass]="tableStyle">
                            <td>
                              <div [ngClass]="{ 'hideControl':showMobileView, 'showControl':!showMobileView }">
                                <p [ngClass]="tdTypeStatusStyle">{{item.direction}}</p>
                              </div>
                              <div [ngClass]="{ 'showControl':showMobileView, 'hideControl':!showMobileView }">
                                <img src='../../assets/svg/arrow-{{getImageUrl(item.direction,i)}}.svg' width="8px">
                              </div>
                            </td>
                            <td class="d-none-mobile">
                              <p [ngClass]="tdStyle">{{getLocalDate(item.scheduledDate)}}</p>
                            </td>
                            <td class="d-none-mobile">
                              <p [ngClass]="tdStyle">{{item.type}}</p>
                            </td>

                            <td>
                              <p [ngClass]="tdStyle" class="tooltip">
                                {{!showMobileView ? item.haulierName: item.haulierName| slice:0:7 }}
                                <span class="tooltiptext" [hidden]="!showMobileView">{{item.haulierName}}</span>
                              </p>
                            </td>
                            <td>
                              <p [ngClass]="tdStyle">{{item.jobNumber != null ? item.jobNumber : ""}}</p>
                            </td>
                            <td>
                              <p [ngClass]="tdStyle" class="tooltip">
                                {{!showMobileView ? item.customerLocation: item.customerLocation| slice:0:7 }}
                                <span class="tooltiptext" [hidden]="!showMobileView">{{item.customerLocation}}</span>
                              </p>
                            </td>
                            <td>
                              <p [ngClass]="tdStyle">{{item.planned}}</p>
                            </td>
                            <td class="d-none-mobile" (click)="setInstructionEdit(item.jobNumber, i)">
                              <p *ngIf="!item.isInstructionEdit" [ngClass]="tdInstructionStyle">{{item.instruction}}
                              </p>
                              <input [hidden]="!item.isInstructionEdit" type="text" [ngClass]="textInstructionStyle"
                                     (focusout)="btnSaveClicked(item)"
                                     (ngModelChange)="instructionChange(item.jobNumber, $event)"
                                     [(ngModel)]="item.instruction" #instruction />
                            </td>
                            <td>
                              <p [ngClass]="tdStyle">{{item.actual}}</p>
                            </td>
                            <td class="d-none-mobile">
                              <p [ngClass]="tdStyle">{{item.remaining}}</p>
                            </td>
                            <td class="d-none-mobile">
                              <p *ngIf="item.status === 'Completed'" [ngClass]="[tdTypeStatusStyle, 'flex']">{{item.status}}</p>
                              <p *ngIf="item.status === 'InProgress'" [ngClass]="[tdTypeStatusStyle, 'flex']">In Progress {{displayTime(item.duration)}}</p>
                              <p *ngIf="item.status === 'Scheduled'" [ngClass]="[tdTypeStatusStyle, 'flex']">{{item.status}}</p>
                              <p *ngIf="item.status === 'Requested'" [ngClass]="[tdTypeStatusStyle, 'flex']">{{item.status}}</p>
                              <p *ngIf="item.status === 'Abandoned'" [ngClass]="[tdTypeStatusStyle, 'flex']">{{item.status}}</p>
                              <p *ngIf="item.status === 'OnRoute'" [ngClass]="[tdTypeStatusStyle, 'flex']">On Route</p>
                              <p *ngIf="item.status === 'Booked'" [ngClass]="[tdTypeStatusStyle, 'flex']">Booked</p>
                            </td>
                            <td class="d-none-mobile">
                              <p *ngIf="item.status === 'Completed'" [ngClass]="[tdTypeStatusStyle, 'flex']"><span class="dot-completed"></span></p>
                              <p *ngIf="item.status === 'InProgress'" [ngClass]="[tdTypeStatusStyle, 'flex']"><span class="dot-inprogress"></span></p>
                              <p *ngIf="item.status === 'Scheduled'" [ngClass]="[tdTypeStatusStyle, 'flex']"><span class="dot-scheduled"></span></p>
                              <p *ngIf="item.status === 'Requested'" [ngClass]="[tdTypeStatusStyle, 'flex']"><span class="dot-requested"></span></p>
                              <p *ngIf="item.status === 'Abandoned'" [ngClass]="[tdTypeStatusStyle, 'flex']"><span class="dot-abandoned"></span></p>
                              <p *ngIf="item.status === 'OnRoute'" [ngClass]="[tdTypeStatusStyle, 'flex']"><span class="dot-onroute"></span></p>
                              <p *ngIf="item.status === 'Booked'" [ngClass]="[tdTypeStatusStyle, 'flex']"><span class="dot-booked"></span></p>
                            </td>
                          </tr>
                        </ng-template>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Table - END -->
          </div>
          <!-- Bottom Row - END -->
        </div>
      </div>
    </div>
    <!-- Main Area (Grid) - END -->
    <!-- After Grid Spacer - START -->
    <div class="spacer-aftergrid"></div>
    <!-- After Grid Spacer - END -->
  </div>
</div>
