import { Component, OnInit, Input } from '@angular/core';
import { SidenavService } from '../services/sidenav.service';

@Component({
	selector: 'app-load-times-view',
	templateUrl: './load-times-view.component.html',
	styleUrls: ['./load-times-view.component.scss']
})
export class LoadTimesViewComponent implements OnInit {

	loadTimes: string[];
	constructor(private sidenavService: SidenavService) { }

	ngOnInit() {
		this.sidenavService.selectedAllocation$.subscribe(a => {
			if (a.item.loadTimes) {
				this.loadTimes = a.item.loadTimes.split(',');
			}
		});
	}

}
