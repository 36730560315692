<ng-container [formGroup]="additionalCosts">
  <div *ngIf="enableCollapsing" [hidden]="!showCollapse" (click)="toggleVisibility()"
   class="additional-fields">
    Additional costs
      <i [ngClass]="{'fa-chevron-up': !additionalHidden, 'fa-chevron-down': additionalHidden}"
      class="fa fa-chevron-up" *ngIf="enableCollapsing"></i>
  </div>
  <div *ngIf="!enableCollapsing" (click)="toggleVisibility()" class="section-header">
    Additional Costs
    <span class="default-add-section" *ngIf="!hasDefaultRow && additionalCosts.length === 0">
      <button mat-button (click)="addAdditionalCost(null, null, null, null, null, defaultUOMId)" class="addButton">
        <i class="fa fa-plus-circle"></i>
      </button>
    </span>
  </div>
  <div [hidden]="(additionalHidden && showCollapse) && enableCollapsing">
      <div *ngFor="let ac of additionalCosts?.controls; let i = index"
        [formGroupName]="i" class="additional-row-container" layout="row">
        <input type="hidden" formControlName="costId">
        <mat-form-field>
          <mat-select formControlName="costCode" [errorStateMatcher]="errorMatcher"
            placeholder="Cost Code">
            <mat-option *ngFor="let costCode of costCodes" [value]="costCode.code">
                {{costCode.codeDescription}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <app-autocomplete
        formControlName="partyAccountNo"
        autoCompleteDelay="750"
        [displayWith]="displayParty"
        [tooltip]="partyTooltip"
        [getResults]="allocateService.getPartiesForAdditionalCost"
        placeholder="Party"
        formOutputProperty="partyAccountNo"
        testName="contractAcParty"
        [errorStateMatcher]="errorMatcher"
        ></app-autocomplete>
        <mat-form-field>
          <input matInput type="number" aria-label="addtional cost"
            placeholder="Additional Cost" autocomplete="off"
            formControlName="rateUomValue" [errorStateMatcher]="errorMatcher">
        </mat-form-field>
        <mat-form-field>
          <mat-select formControlName="rateUomId"  [errorStateMatcher]="errorMatcher"
            placeholder="UOM">
            <mat-option *ngFor="let uom of uoms" [value]="uom.id">
                {{uom.code + ' - ' + uom.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-button (click)="removeAdditionalCost(i)" class="addButton">
          <fa-icon [icon]="trash"></fa-icon>
        </button>
        <button *ngIf="showAddBtn(i)" mat-button (click)="addAdditionalCost(null, null, null, null, null, defaultUOMId)" class="addButton">
          <i class="fa fa-plus-circle"></i>
        </button>
      </div>

  </div>
</ng-container>
