import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { DepotVm } from '../../models';
import { Observable } from 'rxjs';
import { DepotService } from '../../services/depot.service';

@Injectable()
export class DepotResolverService implements Resolve<DepotVm[]> {
	constructor(private depotService: DepotService, private router: Router) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DepotVm[]> {
		return this.depotService.listOfDepots('*');
	}
}