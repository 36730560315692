import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
    InitModel, DepotVm, ApiPageResponse,
    InstructionToMoveVm, AllocateModel
} from '../gen/models';
import { Subject } from 'rxjs';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators, FormArray, FormGroupDirective } from '@angular/forms';
import { DepotService } from '../gen/services/depot.service';
import { InitService } from '../services/init.service';
import { isNullOrUndefined } from '../tools';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { DateAdapter } from '@angular/material/core';
import { InstructionToMoveService } from '../gen/services/instruction-to-move.service';
import { NewInstructionToMove } from '../gen/models/NewInstructructionToMove';
import { GradeService } from '../gen/services/grade.service';
import { AllocateService } from '../gen/services/allocate.service';
import { CustomValidators } from '../shared/custom-validators';
import { AllocationService } from '../gen/services/allocation.service';
import { ActionConfirmDialogComponent } from '../action-confirm-dialog/action-confirm-dialog.component';

@Component({
    selector: 'app-new-instruction-to-move-request-dialog',
    templateUrl: './new-instruction-to-move-request-dialog.component.html',
    styleUrls: ['./new-instruction-to-move-request-dialog.component.scss']
})
export class NewInstructionToMoveRequestDialogComponent implements OnInit {

    pageModel: InitModel;
    myDate: Date;
    minDate: Date;
    maxDate: Date;
    remainingWeight: number;
    remainingLoads: number;
    transportModes: string[] = ['TRUCK', 'CONTAINER', 'RAIL', 'SHIP'];
    onHold = [{ name: 'Yes', value: true }, { name: 'No', value: false }];
    cloning = false;
    shownLoadTimes: number;
    isLoading = false;
    instructionToMoveForm: FormGroup;
    depots = [];
    heaps = [];
    filteredDepots: ApiPageResponse<DepotVm>;
    isSubmit = false;
    destinationModelChanged: Subject<string> = new Subject<string>();
    destinationIndex: number;
    filteredContractRef: ApiPageResponse<AllocateModel>;
    contractHeaderIndex: number;
    contractRefModelChanged: Subject<string> = new Subject<string>();
    constructor(
        public gradeService: GradeService,
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<NewInstructionToMoveRequestDialogComponent>,
        private instructionToMoveService: InstructionToMoveService,
        private depotsService: DepotService,
        private initService: InitService,
        private fb: FormBuilder,
        private toastr: ToastrService,
        private _adapter: DateAdapter<any>,
        private allocateService: AllocateService,
        private allocationService: AllocationService,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
        this._adapter.setLocale(window.navigator.language);
        const defaultReadyDate = new Date();
        defaultReadyDate.setDate(defaultReadyDate.getDate() + 7);
        this.instructionToMoveForm = this.fb.group({
            materials: this.fb.array([])
        });
        this.remainingWeight = this.data.remainingWeight;
        this.remainingLoads = this.data.remainingLoads;
        this.initService.initData$.subscribe(r => {
            this.pageModel = r;
            this.addMaterial();
        });
        this.myDate = new Date();
        this.minDate = new Date(
            this.myDate.getFullYear(),
            this.myDate.getMonth(),
            this.myDate.getDate()
        );
        this.maxDate = new Date(
            this.myDate.getFullYear() + 1,
            this.myDate.getMonth(),
            this.myDate.getDate()
        );
    }

    get materials() {
        return this.instructionToMoveForm.get('materials') as FormArray;
    }

    get destination() {
        return this.materials.controls[this.destinationIndex].get('destination');
    }

    get contractHeader() {
        return this.materials.controls[this.contractHeaderIndex].get('contractHeaderId');
    }

    weight(i: number) {
        return this.materials[i].get('weight');
    }
    tl(i: number) {
        return this.materials[i].get('tl');
    }
    loadTimes(i: number) {
        return this.materials[i].get('loadTimes') as FormArray;
    }

    filterDepots(val: string) {
        return this.depotsService.searchDepots(val);
    }

    filterContractRef(val: string) {
        return this.allocateService.GetContractRefsForIntructionToMove(val);
    }

    displayDestination(depot?: DepotVm): string | undefined {
        return depot && depot.depotNumber && depot.depotName ? depot.depotNumber + ' ' + depot.depotName : undefined;
    }

    displayContractRef(contractRef?: AllocateModel): string | undefined {
        return contractRef && contractRef.contractRef ? contractRef.contractRef + ' ' + contractRef.partyName : undefined;
    }

    submitForm() {
        if (this.remainingWeight < 0) {
            this.toastr.error('Not enough weight available to allocate', 'Form Invalid');
        }
        else if (this.remainingLoads < 0) {
            this.toastr.error('Not enough load available to allocate', 'Form Invalid');
        }
        else{
            this.submit();
        }
        
    }

    submit(){
        if (!this.instructionToMoveForm.valid) {
            const unsetErrors = this.materials.controls.filter(c => !isNullOrUndefined(c.errors) && !isNullOrUndefined(c.errors.unset)).map(x => x.errors.unset as string);
            if (unsetErrors.length > 0) {
                this.toastr.error(unsetErrors[0], 'Form Invalid');
            }
            const dateErros = this.materials.controls.filter(c => !isNullOrUndefined(c.errors) && !isNullOrUndefined(c.errors.dates)).map(x => x.errors.dates as string);
            if (dateErros.length > 0) {
                this.toastr.error(dateErros[0], 'Form Invalid');
            }
            return;
        }

        this.isLoading = true;
        this.isSubmit = true;
        const value = this.instructionToMoveForm.value;
        const localMoment =  moment().local();
        const stringDate = localMoment.format('YYYY-MM-DDTHH:mm:ss');
        const model: NewInstructionToMove = {
            requestId: this.data.requestId,
            plannedMovements: value.materials.map(m => {

                const priority = !isNullOrUndefined(m.priority) ? m.priority : null;
                const packagingId = !isNullOrUndefined(this.data.packageOptionId) ? this.data.packageOptionId : null;
                const contractHeader = !isNullOrUndefined(m.contractHeaderId?.contractHeaderId) ? m.contractHeaderId.contractHeaderId : null;
                const containerType = !isNullOrUndefined(this.data.containerSizeId) ? this.data.containerSizeId : null;
                const mat: InstructionToMoveVm = {
                    Destination: m.destination.depotNumber,
                    Depot: this.data.depot,
                    Grade: this.data.grade,
                    ContractHeaderId: contractHeader,
                    TonnesToMove: m.tonnesToMove,
                    LoadsToMove: m.loadsToMove,
                    DueFrom: new Date(moment(m.dueFrom).format('YYYY-MM-DD')),
                    DueBy: new Date(moment(this.data.expiryDate).format('YYYY-MM-DD')),
                    Hold: m.hold,
                    Priority: priority,
                    TransportMode: m.transportMode,
                    ContainerSizeId: containerType,
                    PackageOptionId: packagingId,
                    UomId: this.data.uomId,
                    CreatedDate: stringDate
                };
                return mat;
            })
        };

        this.instructionToMoveService.createInstructionToMove(model).subscribe(() => {
            this.toastr.success('Instruction to Move successfully created', 'New Instruction to Move');
            this.closeDialog();
        },
        err => {
            if (err.status === 200) {
                this.toastr.success('Instruction to Move successfully created', 'New Instruction to Move');
                this.closeDialog();
            }
            else {
                this.toastr.error('Something went wrong and the Instruction to Move could not be created. Please try again later.', 'New Request Failed');
                this.isLoading = false;
                this.isSubmit = false;
            }
        });
    }

    private closeDialog() {
        setTimeout(() => {
            this.isLoading = false;
            this.dialogRef.close('callServer');
        }, 1500);
    }

    reset(formDir: FormGroupDirective) {
        this.instructionToMoveForm.reset();
        formDir.resetForm();
    }

    calculateRemainingWeight() {

        const value = this.instructionToMoveForm.value;
        this.remainingWeight = this.data.remainingWeight;
        value.materials.map(m => { this.remainingWeight -= m.tonnesToMove; });
    }

    calculateRemainingLoad() {

        const value = this.instructionToMoveForm.value;
        this.remainingLoads = this.data.remainingLoads;
        value.materials.map(m => { this.remainingLoads -= m.loadsToMove; });
    }
    addMaterial() {

        const lastItemIndex = this.materials.length - 1;
        if (lastItemIndex > -1) {
            const lastMaterial = this.materials.controls[lastItemIndex] as FormGroup;
            lastMaterial.controls.submitted.patchValue(true);
        }

        const group = this.newMaterialFromExistingData();
        this.materials.push(group);
        this.toggleContainerSizeAndUom();
    }

    removeMaterial(index: number) {
        this.materials.removeAt(index);
        if (this.materials.length === 0) {
            this.addMaterial();
        }
        
        this.calculateRemainingLoad();
        this.calculateRemainingWeight();
    }

    copyMaterial(index: number) {
        this.cloning = true;
        const copyFrom = this.materials.controls[index] as FormGroup;
        this.addMaterial();
        const lastItemIndex = this.materials.length - 1;
        const copyTo = this.materials.controls[lastItemIndex] as FormGroup;
        for (const key of Object.keys(copyFrom.controls)) {
            copyTo.controls[key].patchValue(copyFrom.controls[key].value);
        }
        this.calculateRemainingLoad();
        this.calculateRemainingWeight();
        this.cloning = false;
    }

    addMaterialRow() {
        this.cloning = true;
        this.addMaterial();
        this.cloning = false;
    }

    newMaterialFromExistingData(): FormGroup {
        this.shownLoadTimes = undefined;
        const group = this.fb.group({
            destination: ['', Validators.required],
            dueFrom: ['', Validators.required],
            transportMode: ['', Validators.required],
            hold: ['', Validators.required],
            priority: ['', Validators.required],
            contractHeaderId: [''],
            tonnesToMove: [{ value: this.remainingWeight< 0 ? 0: this.remainingWeight, disabled: false }, 
                [Validators.required, Validators.pattern('^[0-9]{1,7}(.[0-9]+)?$'), Validators.min(1)]],
            loadsToMove: [{ value: this.remainingLoads < 0 ? 0: this.remainingLoads, disabled: false }, [Validators.required, Validators.min(1)]],
            submitted: [false]
        },
        {
            validators:
                    [CustomValidators.dateLessThanExactDate('dueFrom', this.data.expiryDate)]
        });
        group.get('contractHeaderId').valueChanges
            .pipe(
                debounceTime(500),
                startWith(null),
                switchMap(v => this.filterContractRef(v)))
            .subscribe(d => this.filteredContractRef = d);

        group.get('destination').valueChanges
            .pipe(
                debounceTime(500),
                startWith(null),
                switchMap(v => this.filterDepots(v)))
            .subscribe(d => this.filteredDepots = d);
        return group;
    }

    private toggleContainerSizeAndUom() {
        this.enableContainerSizeAndUom();
    }

    private enableContainerSizeAndUom() {
        this.materials.controls.forEach((c) => {
            c.enable();
        });
    }

    destinationValueChange(val: string, index: number) {
        if (val.length > 1) {
            val = val.toUpperCase();
            this.destinationModelChanged.next(val);
            this.destinationIndex = index;
        }
    }

    contractRefValueChange(val: string, index: number) {
        val = val.toUpperCase();
        this.contractRefModelChanged.next(val);
        this.contractHeaderIndex = index;
    }
}