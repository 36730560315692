<div class="party-detail">
  <div>{{data.party.partyAccountNo}} - {{data.party.partyName}}</div>
  <div>Total Balance: <span>{{data.contract.totalBalance | number: '1.0-1'}}</span></div>
  <div>Contract ref: <span>{{data.contract.customerContractRef}}</span></div>
  <div *ngIf="!isMixedGradeContract">
    <mat-slide-toggle (change)="showBulkAllocateMode($event)">Bulk Allocate</mat-slide-toggle>
  </div>
</div>
<span *ngIf="requestGradesToAllocate.length === 0 && isMixedGradeContract" class="multi-grade-info">Allocate a single
  load of multi grade materials</span>
<div class="allocate-area" *ngIf="requestGradesToAllocate.length > 0" testName="nonMatchingGrades">
  <div class="allocate-area-title">Non-matching grades
    <div class="allocate-area-warning" *ngIf="requestsToAllocate.length > 1 || contractLinesToAllocate.length > 1"><i
        class="fa fa-exclamation-triangle warning" matSuffix></i>
      Please select grades from request and contract side to match</div>
  </div>
  <div class="allocate-area-row">
    <div>
      <div class="allocate-caption">Request Grades</div>
      <div *ngFor="let r of unallocatedRequests()" testName="requestGrade" (click)="selectRequestGrade(r)"
        [ngClass]="requestGradeClass(r.grade)">
        {{r.grade}}
      </div>
    </div>
    <div>
      <div class="allocate-caption">Contract Grades</div>
      <div *ngFor="let g of contractLinesToAllocate" testName="contractGrade" (click)="selectContractLineGrade(g)"
        [ngClass]="contractLineGradeClass(g)">
        {{g}}
      </div>
    </div>
    <div class="shuttle-buttons">
      <button mat-button (click)="allocateGrades()" testName="match"
        [disabled]="!selectedRequest || !selectedContractLineGrade">>></button>
      <button mat-button (click)="unallocateGrades()" testName="unmatch" [disabled]="!selectedAllocate"></button>
    </div>
    <div>
      <div class="allocate-caption">Allocattions</div>
      <div *ngFor="let g of allocatedGrades" class="matchLineGradeClass(g)" testName="matchGrade"
        (click)="selectAllocateLine(g)" [ngClass]="allocateLineClass(g)">
        {{g.requestGrade}} => {{g.contractLineGrade}}
      </div>
    </div>
  </div>
  <button mat-button type="button" (click)="cancel()">CANCEL</button>
</div>

<form [formGroup]="allocationForm" [hidden]="requestGradesToAllocate.length !== 0"
  (ngSubmit)="allocate(allocationForm.value)" testName="allocationForm" (click)="hideLoadTimes(null)">
  <app-spinner [displayProgressSpinner]="loadingCreateDialog" [container]="loadingCreateDialogBox"
    #loadingCreateDialogSpinner></app-spinner>
  <div #loadingCreateDialogBox>
    <mat-tab-group dynamicHeight [(selectedIndex)]="selectedTabIndex" #tabs>
      <mat-tab *ngFor="let r of data.requests; let i = index" label="Request {{(i+1)}}">
        <div aria-label="Requests">
          <div>
            <table class="table parent-table" formArrayName="requests">
              <thead>
                <th>Depot</th>
                <th>Grade</th>
                <th>Request Date</th>
                <th>Ready Date</th>
                <th>Heap</th>
                <th>Description</th>
                <th>Container/<br />Packaging</th>
                <th>Status</th>
                <th></th>
                <th>Weight<br />Available</th>
                <th *ngIf="!isMixedGradeContract">Loads<br />Available</th>
                <th *ngIf="!bulkAllocate">Weight<br />Required</th>
                <th *ngIf="!bulkAllocate && !isMixedGradeContract">Loads<br />Required</th>
                <th *ngIf="!isMixedGradeContract">Weight per<br />Load</th>
                <th *ngIf="!bulkAllocate">Fully<br>Allocate</th>
              </thead>
              <tbody [formGroupName]="i">
                <tr testName="request">
                  <td testName="depotNo">{{r.depotNo}}</td>
                  <td testName="grade">{{r.grade}}</td>
                  <td testName="requestDate">{{r.requestDate | momentUtcAsLocaleDate}}</td>
                  <td testName="readyDate">{{r.readyDate | momentUtcAsLocaleDateTime}}</td>
                  <td>{{r.heap}}</td>
                  <td testName="description">{{r.description}}</td>
                  <td>{{r.containerPackaging}}</td>
                  <td>{{r.status}}</td>
                  <td>
                    <div *ngIf="r.comments && r.comments.length > 0" class="comments-wrapper">
                      <span><i class="fa fa-lg fa-commenting"></i></span>
                      <div class="comments">
                        <div *ngFor="let c of r.comments; let i = index"
                          [ngClass]="{'odd': (i % 2 === 1), 'even': (i % 2 === 0)}" class="comments-content">
                          <div>
                            <p [innerHtml]="c.comment"></p>
                          </div>
                          <div>{{c.author}} - {{c.commentDate | momentUtcAsLocaleDateTime}}</div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>{{(r.convertedWeight !== null) ? (r.convertedWeight | number: '1.0-1') : "N/A"}}</td>
                  <td *ngIf="!isMixedGradeContract">{{r.remainingLoads}}</td>
                  <td [hidden]="bulkAllocate">
                    <mat-form-field width>
                      <input matInput type="number" [max]="roundWeight(r.convertedWeight)" [min]="0" #qty
                        (input)="qtyChange(r, qty.value, i, loads, weightPerLoad)" formControlName="requiredWeight" />
                    </mat-form-field>
                    <input type="hidden" formControlName="requestId" [value]="r.requestId">
                    <input type="hidden" formControlName="depotNo" [value]="r.depotNo">
                    <input type="hidden" formControlName="requiredWeightUomId">
                  </td>
                  <td [hidden]="bulkAllocate || isMixedGradeContract">
                    <mat-form-field>
                      <input matInput type="number" [max]="r.remainingLoads" [min]="minRequiredLoad" #loads
                        (input)="loadsChange(loads.value, qty.value, i, weightPerLoad)" formControlName="requiredLoads"
                        testName="loads" />
                      <button mat-button *ngIf="loads.disabled" matSuffix mat-icon-button aria-label="Clear"
                        testName="loadsClear" (click)="resetInputs(qty, loads, weightPerLoad, i, r)">
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-form-field>
                  </td>
                  <td [hidden]="isMixedGradeContract">
                    <mat-form-field>
                      <input matInput type="number" [max]="roundWeight(r.convertedWeight)" [min]="0" #weightPerLoad
                        (input)="weightPerChange(weightPerLoad.value, qty.value, i, loads)"
                        formControlName="requiredWeightPerLoad" testName="weightPerLoad" />
                      <button mat-button *ngIf="weightPerLoad.disabled" type="button" matSuffix mat-icon-button
                        aria-label="Clear" testName="weightPerLoadClear"
                        (click)="resetInputs(qty, loads, weightPerLoad, i, r)">
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-form-field>
                  </td>
                  <td [hidden]="bulkAllocate">
                    <mat-checkbox formControlName="fullyAllocate"></mat-checkbox>
                  </td>
                </tr>
                <tr>
                  <td colspan="10">
                    <table formArrayName="contractLines" class="child-table">
                      <thead>
                        <th></th>
                        <th>Contract Line No.</th>
                        <th>Grade</th>
                        <th>Description</th>
                        <th>Min Tonnage</th>
                        <th>Max Tonnage</th>
                        <th>Target</th>
                        <th>Delivered</th>
                        <th>Balance Weight</th>
                        <th>Delivery Point</th>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let line of getContractLines(r.grade); let j = index">
                          <tr (click)="selectContractLine(line, i)" [ngClass]="contractLineClass(line, i)"
                            [formGroupName]="j">
                            <td>
                              <i class="fa fa-question-circle tool-tip" matTooltip="{{getDepots(line)}}">
                              </i>
                            </td>
                            <td testName="contractLineNo">{{line.contractLineNo}}
                              <input type="hidden" formControlName="contractLineId" [value]="line.contractLineId">
                            </td>
                            <td testName="contractGrade">{{line.grade}}</td>
                            <td>{{line.salesGradeDesc}}</td>
                            <td>{{line.toleranceLowerLimit | number : '1.0-1'}} {{line.weightUomCode}}</td>
                            <td>{{line.toleranceUpperLimit | number : '1.0-1'}} {{line.weightUomCode}}</td>
                            <td>{{line.targetWeight | number : '1.0-1'}} {{line.weightUomCode}}</td>
                            <td>{{line.deliveredTonnage | number : '1.0-1'}}</td>
                            <td>{{line.balanceWeight | number: '1.0-1'}} {{line.weightUomCode}}</td>
                            <td>{{line.deliveryPoint}}</td>
                          </tr>
                          <tr *ngIf="line.weightWarning">
                            <td colspan="11" class="warning"><i class="fa fa-exclamation-triangle" matSuffix></i>
                              {{line.weightWarning}}</td>
                          </tr>
                          <tr *ngIf="line.loadsWarning">
                            <td colspan="11" class="warning"><i class="fa fa-exclamation-triangle" matSuffix></i>
                              {{line.loadsWarning}}</td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div formArrayName="allocationDetail">
          <div [formGroupName]="i">
            <div class="bottomTotal">
              <div style="width: 100%;" *ngIf="bulkAllocate">
                <mat-radio-group aria-label="Allocation mode" *ngIf="bulkAllocate"
                  (change)="selectAllocationMode(i,$event)">
                  <mat-radio-button value="date" checked>Loads by date</mat-radio-button>
                  <!-- <mat-radio-button value="time">Loads by time</mat-radio-button> -->
                  <mat-radio-button value="week">Loads by week</mat-radio-button>
                </mat-radio-group>
                <mat-checkbox *ngIf="bulkAllocate" formControlName="bulkAllocateGroupAllocations">
                  Group Allocations
                </mat-checkbox>
              </div>
              <div style="float: left;width: 100%;">
                <mat-form-field>
                  <mat-select formControlName="containerTypeId" placeholder="Container Type*"
                    (selectionChange)="selectContractType($event,i)">
                    <mat-option *ngFor="let container of containerTypes" [value]="container.containerTypeId">
                      {{container.containerTypeName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="!bulkAllocate">
                  <input matInput type="text" aria-label="container number" placeholder="Container Number"
                    class="md-input" formControlName="containerNumber" autocomplete="off">
                </mat-form-field>
                Total: {{total || 0}}
                <mat-checkbox *ngIf="!bulkAllocate" formControlName="groupAllocations" testname="bulkAllocateGroup">
                  Group Allocations
                </mat-checkbox>
                <mat-checkbox [(ngModel)]="isBooked"
                [ngModelOptions]="{standalone: true}">
                  Booking Confirmed
                </mat-checkbox>
              </div>
            </div>
            <app-spinner *ngIf="!bulkAllocate" [displayProgressSpinner]="loadinghaulageRates"
              [container]="haulageRatesBox" #haulageRatesSpinner></app-spinner>
            <div class="allocationForm" *ngIf="!bulkAllocate" #haulageRatesBox>
              <div aria-label="Allocate?">
                <div class="dialog-input-container">
                  <app-allocation-appointments formControlName="allocationAppointments"
                    [partyDeliveryPointIds]="selectedContractLine[i].partyDeliveryPointId"
                    [loadsCount]="calculateLoadsCount()" [data]="data" [depotNo]="this.depotNo[i]"
                    [contractLineId]="selectedContractLine[i].contractLineId"
                    [containerTypeId]="this.containerTypeId[i]" [appointments]="appointments">
                  </app-allocation-appointments>

                  <div class="row-container shade">
                    <mat-form-field>
                      <textarea cols="50" rows="2" matInput placeholder="Instructions"
                        maxlength="{{instructionsMaxlength}}" class="md-input"
                        formControlName="instructions"></textarea>
                      <div class="md-errors-spacer"></div>
                      <div class="textarea-counter">{{remainingInstructionsText}}</div>
                    </mat-form-field>
                  </div>
                  <app-additional-costs [maxCosts]="maxCosts" [uoms]="uoms" formControlName="additionalCosts"
                    [defaultUOMId]="defaultAdditionalCostUomId" [enableCollapsing]="false" [hasDefaultRow]="false">
                  </app-additional-costs>
                </div>
              </div>
            </div>
            <div class="bulkallocationForm" *ngIf="bulkAllocate">
              <div aria-label="Bulk Allocate">
                <div mat-dialog-content class="dialog-input-container">
                  <div class="row-container shade allocate-by-date" aria-label="Load by date"
                    *ngIf="bulkAllocationMode[i] == 'date' || bulkAllocationMode[i] == null">
                    <div class="load-by-dates-wrap">
                      <div *ngFor="let j of groupDaysCount[i];"
                        [ngClass]="{'lastdayrow-width': j==groupDaysCount.length-1}">
                        <div style="display: inline-block;position: relative;height: 50px;"
                          *ngFor="let loadsbydate of datePlaceholder[i].slice(j*7, (j*7+7));let ind = index"
                          formArrayName="loadsByDate">
                          <div [formGroupName]="j*7+ind">
                            <mat-form-field>
                              <input matInput type="number" placeholder="{{loadsbydate | date: 'EEE d/M'}}"
                                class="md-input" formControlName="requiredLoads" (focus)="hideLoadTimes(i)">
                            </mat-form-field>
                            <span style="cursor: pointer;" *ngIf="getLoadTimes(i,j*7+ind, 'loadsByDate').length > 0"
                              matSuffix (click)="showHideLoadTimes(i,j*7+ind, $event)">
                              <fa-icon [icon]="'clock'"></fa-icon>
                            </span>
                            <div style="position: fixed;" class="loadTimes" [ngStyle]="loadTimesStyle"
                              [hidden]="hiddenLoadTimes(i,j*7+ind)" (click)="clockClicked = true">
                              <div formArrayName="loadTimes">
                                <div *ngFor="let l of getLoadTimes(i,j*7+ind, 'loadsByDate'); let t = index"
                                  [formGroupName]="t">
                                  <mat-form-field class="small-form-field">
                                    <input matInput id="datetime" formControlName="loadTime" type="time" for
                                      placeholder="Pickup load {{t+1}}">
                                  </mat-form-field>
                                  <mat-form-field *ngIf="t==0">
                                    <mat-select id="ddlIncrement" formControlName="increment"
                                      placeholder="Pickup other loads at every"
                                      (selectionChange)="setIncrements(i,j*7+ind, $event.value, 'byDates')">
                                      <mat-option *ngFor="let inc of increments" [value]="inc">{{ inc }}</mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                  <ng-container *ngIf="ind==0 && t==0">
                                    <a class="mat-button mat-primary" (click)="applyLoadTimesToAll(i)">Apply to all</a>
                                  </ng-container>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div style="display:inline-block">
                          <a style="min-width: 30px;" class="mat-button secondary trash-icon-align"
                            *ngIf="(j*7+6) == datePlaceholder[i].length-1 && j!=0"
                            (click)="removeDatesFromDatePlaceholder(i)">
                            <i class="fa fa-trash"></i>
                          </a>
                        </div>
                      </div>

                      <div style="display: block;float: left;width: 50%;">
                        <a style="padding: 0;" class="mat-button primary" (click)="addDatesToDatePlaceholder(i)">Add
                          days</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div mat-dialog-content class="dialog-input-container">
                <div class="row-container shade allocate-by-time" aria-label="Load by date"
                  *ngIf="bulkAllocationMode[i] == 'time'">
                  <mat-form-field style="width: 90px">
                    <input matInput [matDatepicker]="picker" placeholder="Date"
                      formControlName="bulkAllocationPickupDate">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field>
                    <input matInput type="number" placeholder="Loads" class="md-input" autocomplete="off"
                      (change)="populateControls($event);" formControlName="bulkAllocationRequiredLoads">
                  </mat-form-field>
                  <div *ngFor="let val of timePlaceholder;let i = index" formArrayName="loadsByTime">
                    <div [formGroupName]="i">
                      <mat-form-field>
                        <input matInput type="time" placeholder="Pickup time" formControlName="pickupTime">
                      </mat-form-field>
                      <ngx-material-timepicker #timePicker [format]="24"></ngx-material-timepicker>
                    </div>
                  </div>

                </div>
              </div>
              <div mat-dialog-content class="dialog-input-container">
                <div class="row-container shade allocate-by-week" aria-label="Load by week"
                  *ngIf="bulkAllocationMode[i] == 'week'" style="padding-right: 150px;">
                  <div class="load-by-weeks-wrap">
                    <div *ngFor="let k of groupWeeksCount[i];">
                      <div style="display: inline-block;position: relative;height: 50px;"
                        *ngFor="let loadsbyweek of weekPlaceholder[i].slice(k*7, (k*7+7)); let loadByWeekIndex = index"
                        formArrayName="loadsByWeek">
                        <div [formGroupName]="k*7+loadByWeekIndex">
                          <mat-form-field>
                            <input matInput type="number" placeholder="{{loadsbyweek | date: 'EEE d/M'}}"
                              class="md-input" formControlName="requiredLoads" (focus)="hideLoadTimes()">
                          </mat-form-field>

                          <span style="cursor: pointer;"
                            *ngIf="getLoadTimes(i,k*7+loadByWeekIndex, 'loadsByWeek').length > 0" matSuffix
                            (click)="showHideLoadTimes(i,k*7+loadByWeekIndex, $event)">
                            <fa-icon [icon]="'clock'"></fa-icon>
                          </span>
                          <div style="position: fixed;" class="loadTimes" [ngStyle]="loadTimesStyle"
                            [hidden]="hiddenLoadTimes(i,k*7+loadByWeekIndex)" (click)="clockClicked = true">
                            <div formArrayName="loadTimes">
                              <div *ngFor="let l of getLoadTimes(i,k*7+loadByWeekIndex, 'loadsByWeek'); let t = index"
                                [formGroupName]="t">
                                <mat-form-field class="small-form-field">
                                  <input matInput id="datetime" formControlName="loadTime" type="time" for
                                    placeholder="Pickup load {{t+1}}">
                                </mat-form-field>

                                <mat-form-field *ngIf="t==0">
                                  <mat-select id="ddlIncrement" formControlName="increment"
                                    placeholder="Pickup other loads at every"
                                    (selectionChange)="setIncrements(i,k*7+loadByWeekIndex, $event.value, 'byWeek')">
                                    <mat-option *ngFor="let inc of increments" [value]="inc">{{ inc }}</mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style="display:inline-block">
                        <a style="min-width: 30px;" class="mat-button secondary trash-icon-align"
                          *ngIf="(k*7+6) == weekPlaceholder[i].length-1 && k!=0"
                          (click)="removeWeeksFromWeekPlaceHolder(i)">
                          <i class="fa fa-trash"></i>
                        </a>
                      </div>
                    </div>
                    <div style="display: block;float: left;width: 50%;">
                      <a style="padding: 0;" class="mat-button primary" (click)="addWeeksToWeekPlaceholder(i)">Add
                        weeks</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row-container allocation-mode">
                <mat-form-field>
                  <textarea cols="50" rows="2" matInput placeholder="Instructions" maxlength="{{instructionsMaxlength}}"
                    class="md-input" formControlName="bulkAllocateIns"></textarea>
                  <div class="md-errors-spacer"></div>
                  <div class="textarea-counter">{{remainingInstructionsText}}</div>
                </mat-form-field>
              </div>
              <span *ngIf="noLoads" class="error-message">
                <i class="fa fa-exclamation-triangle"></i>
                Please enter require loads in {{requestWithnoLoads}}!
              </span>
              <span *ngIf="noPickupDate" class="error-message">
                <i class="fa fa-exclamation-triangle"></i>
                Please enter pickup time in {{requestWithnoLoads}}!
              </span>
            </div>
          </div>
        </div>
        <div mat-dialog-actions class="buttons-row">
          <button mat-flat-button type="button" (click)="cancel()">Cancel</button>
          <button mat-flat-button type="submit" color="primary" [disabled]="isSubmit">Allocate</button>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</form>
