import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class TableSortService {
	private selectedColumnSubject: BehaviorSubject<string> = new BehaviorSubject<string> ('');
	constructor() { }

	public selectedColumn(): Observable<string> {
		return this.selectedColumnSubject;
	}

	public setSelectedColumn(column: string) {
		this.selectedColumnSubject.next(column);
	}
}
