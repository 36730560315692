import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { AllocateService } from '../gen/services/allocate.service';
import { GradeService } from '../gen/services/grade.service';
import { AllocationService } from '../gen/services/allocation.service';
import {
    ContractHeaderViewModel, ContractLineViewModel, PartyVm, DepotVm, ContractGroupVm,
    GroupedRequestVm, AllocateRequestVm, ContractPartyVm, RequestSearchFilter, ContractSearchFilterQueryModel, AllocationRequestStatus} from '../gen/models';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { Sort } from '@angular/material/sort';
import { AllocateDialogComponent } from '../allocate-dialog/allocate-dialog.component';
import { AllocateMultiGradeDialogComponent } from '../allocate-multi-grade-dialog/allocate-multi-grade-dialog.component';
import { isUndefined, isNullOrUndefined } from '../tools';
import * as moment from 'moment';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { ActionConfirmDialogComponent } from '../action-confirm-dialog/action-confirm-dialog.component';
import { Observable, Subscription } from 'rxjs';
import { finalize, map, startWith } from 'rxjs/operators';
import { AllocateCreateDialogComponent } from '../allocate-create-dialog/allocate-create-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { SpinnerComponent } from '../spinner/spinner.component';
import { AutocompleteComponent } from '../autocomplete/autocomplete.component';
import { EditRequestDialogComponent } from '../edit-request-dialog/edit-request-dialog.component';
import { SidenavService } from '../services/sidenav.service';
import { BulkUpdateModel } from '../gen/models/BulkUpdateModel';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { AllocateRequestCommentVm } from '../gen/models/AllocateRequestCommentVm';
import { ActivatedRoute } from '@angular/router';
import { UserPreferencesVm } from '../gen/models/UserPreferencesVm';
import { OrderBookTypeVm } from '../gen/models/OrderBookTypeVm';
import { SalesGradeService } from '../gen/services/sales-grade.service';
import { EnumVals, IEnumItem } from '../gen/enums/enums';
import { AllocateInventoryService } from '../gen/services/allocate-inventory.service';
@Component({
    selector: 'app-allocate-inventory',
    templateUrl: './allocate-inventory.component.html',
    styleUrls: ['./allocate-inventory.component.scss']
})
export class AllocateInventoryComponent implements OnInit {
    noOfContractResults: IEnumItem[] = EnumVals.NoOfResults;
    requests: GroupedRequestVm[] = [];
    requestSearchFilter: RequestSearchFilter = {
        fromDate: null,
        toDate: null,
        gradeFilter: [],
        gradeTypeFilter: [],
        gradeGroupFilter: [],
        depotFilter: [],
        orderBookFilter: null,
        partyFilter: null,
        groupByField: null,
        lifecycleStatusId: 0,
        territoryFilter: []
    };
    contractSearchFilterQueryModel: ContractSearchFilterQueryModel = {
        pagination: {
            start: 1,
            number: 10
        },
        search: {
            fromDate: undefined,
            toDate: undefined,
            gradeFilter: [],
            salesGradeFilter: [],
            orderBookFilter: [],
            partyFilter: [],
            contractRef: null,
            includeInternalSales: null,
            okToLoad: null,
            depotNo: null,
            territoryFilter: [],
            comment: null,
            classification: null,
            deliveryPoint: null
        },
        sort: {
            predicate: null,
            reverse: false
        }
    };
    contractFilter: any = {};
    showGradeButton = false;
    showSalesGradeButton = false;
    noContractsFound = false;
    contractsGroup: ContractGroupVm[] = [];
    selectedRows: number[][] = [];
    depot: string[] = [];
    salesGrade: string[] = [];
    grade: string[] = [];
    gradeType: string[] = [];
    gradeGroup: string[] = [];
    selectedRowCount = 0;
    lifecycleStatuses: AllocationRequestStatus[] = [];
    bulkUpdateRequests: AllocateRequestVm[] = [];
    bulkUpdateMode = false;
    bulkUpdateForm: FormGroup;
    selectedRequests: AllocateRequestVm[] = [];
    dragSelectedRequests: AllocateRequestVm[] = [];
    selectedRequest: AllocateRequestVm;
    selectedContract: ContractHeaderViewModel;
    showBasket = false;
    loadingRequests = false;
    loadingContracts = false;
    selectedParty: ContractPartyVm;
    basketStyle: {};
    defaultOrderBookFilter: string;
    groupBy = 'depot';
    addedCommentSubscription: Subscription;
    defaultDepot: DepotVm;
    defaultTerritories: string[];
    userPreferencesData: UserPreferencesVm;
    showCommentCloseButton = false;
    showDeliveryPointCloseButton = false;

    orderBookTypeControl = new FormControl();
    orderBookTypes: OrderBookTypeVm[] = [];
    selectedOrderBookTypes: OrderBookTypeVm[] = new Array<OrderBookTypeVm>();
    orderBookPanelOpenState = false;
    filteredOrderBookTypes: Observable<OrderBookTypeVm[]>;
    lastOrderBookTypeFilter = '';

    showAdditionalFilter = true;
    advancedFilterCount = 0;
    loadingContractAllocation = false;
    hideRequestsSection = false;
    systemComment = 'System';
  @ViewChild('requestSpinner') requestSpinner: SpinnerComponent;
  @ViewChild('contractSpinner') contractSpinner: SpinnerComponent;

  @ViewChild('requestDepotInputAvail') requestDepotInputAvail: AutocompleteComponent;
  @ViewChild('contractDepotInputAvail') contractDepotInputAvail: AutocompleteComponent;
  @ViewChild('gradeTypeInputAvail') gradeTypeInputAvail: AutocompleteComponent;
  @ViewChild('gradeGroupInputAvail') gradeGroupInputAvail: AutocompleteComponent;
  @ViewChild('gradeInputAvail') gradeInputAvail: AutocompleteComponent;
  @ViewChild('gradeInputDest') gradeInputDest: AutocompleteComponent;
  @ViewChild('salesGradeInputDest') salesGradeInputDest: AutocompleteComponent;
  @ViewChild('commentInputDest') commentInputDest: ElementRef;
  @ViewChild('deliveryPointInputDest') deliveryPointInputDest: ElementRef;
  @ViewChild('orderInputDest') orderInputDest: AutocompleteComponent;
  @ViewChild('partyInputDest', { static: true }) partyInputDest: AutocompleteComponent;
  @ViewChild('contractRefInputDest', { static: true }) contractRefInputDest: AutocompleteComponent;
  @ViewChild('fromDateInputDest', {
      read: MatInput,
      static: true
  }) fromDateInputDest: MatInput;
  @ViewChild('toDateInputDest', {
      read: MatInput,
      static: true
  }) toDateInputDest: MatInput;
  @ViewChild('internalSalesInputDest', { static: true }) internalSalesInputDest: HTMLInputElement;

  private _okToLoad = '';
  private _classification = '';

  constructor(public allocateService: AllocateService,
    public gradeService: GradeService,
    private allocationService: AllocationService,
    private sidenavService: SidenavService,
    public salesGradeService: SalesGradeService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public allocateInventoryService: AllocateInventoryService) {

      const userPreferences = this.route.snapshot.data['userpreferencesData'];
      if (userPreferences !== undefined && userPreferences.length > 0)
          this.userPreferencesData = userPreferences[0];
      else
          this.userPreferencesData = { depot: '', territory: '', requestFields: '', allocationFields: '', orderBookType: '', viewMode: '', allocationDate: '', pickUpFromDate: ''  };

      const orderBookTypeList = this.route.snapshot.data['orderBookTypeList'];
      if (orderBookTypeList !== undefined && orderBookTypeList.length > 0)
          this.orderBookTypes = orderBookTypeList;
      else
          this.orderBookTypes = [];
  }

  get contractsCollectionSize(): number {
      return Math.max(...this.contractsGroup.map(c => c?.pagedParties?.recordCount ?? 0));
  }

  get contractsPage(): number {
      return this.contractSearchFilterQueryModel.pagination.start;
  }

  set contractsPage(value: number) {
      this.contractSearchFilterQueryModel.pagination.start = value;
  }

  get contractsPageSize(): number {
      return this.contractSearchFilterQueryModel.pagination.number;
  }

  set contractsPageSize(value: number) {
      this.contractSearchFilterQueryModel.pagination.number = value;
  }

  get okToLoad(): string {
      return this._okToLoad;
  }

  set okToLoad(value: string) {
      this._okToLoad = value;
      this.contractSearchFilterQueryModel.search.okToLoad = value == undefined ? null : (value.toString() === 'true');
  }

  get classification(): string {
      return this._classification;
  }

  set classification(value: string) {
      this._classification = value;
      this.contractSearchFilterQueryModel.search.classification = value;
  }

  displayParty(party: PartyVm) {
      if (isNullOrUndefined(party)) { return ''; }
      return `${party.partyAccountNo} - ${party.partyName}`;
  }

  partyTooltip(party: PartyVm) {
      return party.partyName;
  }

  displayDepot(depot: DepotVm) {
      if (isNullOrUndefined(depot)) { return ''; }
      return `${depot.depotNumber} - ${depot.depotName}`;
  }

  depotTooltip(depot: DepotVm) {
      return depot.depotName;
  }

  abbreviateDate(dateText: string) {
      if (isNullOrUndefined(dateText) || dateText === '')
          return 'N/A';
      return moment(dateText).format('MMM \'YY');
  }

  ngOnInit() {
      this.defaultOrderBookFilter = this.userPreferencesData.orderBookType;
      this.orderBookTypeControl.setValue(this.userPreferencesData.orderBookType);
      if (this.userPreferencesData.territory == undefined || this.userPreferencesData.territory == null || this.userPreferencesData.territory === '') {
          this.defaultTerritories = [];
      }
      else {
          const splitTerritories = this.userPreferencesData.territory.split(',');
          const trimmedTerritroies = splitTerritories.map(terr => { return terr.trim(); });
          this.defaultTerritories = trimmedTerritroies;
      }

      this.searchRequests(null, null);
      this.bulkUpdateForm = this.fb.group({ lifecycleStatusId: 0, comment: '' });
      this.allocateService.getLifecycleStatus().subscribe(s => this.lifecycleStatuses = s);

      this.filteredOrderBookTypes = this.orderBookTypeControl.valueChanges.pipe(
          startWith<string | OrderBookTypeVm[]>(''),
          map(value => typeof value === 'string' ? value : this.lastOrderBookTypeFilter),
          map(filter => this.orderBookTypeFilter(filter))
      );
      this.assignOrderBookType();
  }

  ngAfterViewInit() {
      this.setOrderBookLabel();
  }

  ngOnDestroy(): void {
      this.requestSpinner.overlayRef.dispose();
      this.contractSpinner.overlayRef.dispose();
  }

  clearRequestInput(prop: string, preventRefresh = false) {
      if (Array.isArray(this.requestSearchFilter[prop])) {
          this.requestSearchFilter[prop] = [];
      } else {
          if (prop == 'lifecycleStatusId') {
              this.requestSearchFilter[prop] = 0;
          }
          else {
              this.requestSearchFilter[prop] = null;
          }
      }

      if (!preventRefresh) {
          this.searchRequests(prop, null);
      }
  }

  clearContractInput(prop: string, preventRefresh = false) {
      if (prop === 'gradeFilter' || prop === 'orderBookFilter' || prop === 'partyFilter') {
          this.contractSearchFilterQueryModel.search[prop] = [];
      }
      else {
          this.contractSearchFilterQueryModel.search[prop] = null;
      }
      if (this.shouldSearch() && !preventRefresh) {
          this.searchContracts(prop, null);
      } else {
          this.noContractsFound = false;
          this.contractsGroup = [];
      }
  }

  displayOrderBookType(orderBookType: OrderBookTypeVm[] | string): string | undefined {
      let displayValue: string;
      if (Array.isArray(orderBookType)) {
          orderBookType.forEach((orderBookType, index) => {
              if (index === 0) {
                  displayValue = orderBookType.orderBook;
              } else {
                  displayValue += ', ' + orderBookType.orderBook;
              }
          });
      } else {
          displayValue = orderBookType;
      }
      return null;
  }

  orderBookTypeFilter(orderBookTypeFilter: string): OrderBookTypeVm[] {
      this.lastOrderBookTypeFilter = orderBookTypeFilter;
      if (orderBookTypeFilter) {
          return this.orderBookTypes.filter(option => {
              return option.orderBook.toLowerCase().indexOf(orderBookTypeFilter.toLowerCase()) >= 0;
          });
      } else {
          return this.orderBookTypes;
      }
  }

  clearOrderBook() {
      this.orderBookTypeControl.setValue('');
      this.clearContractInput('orderBookFilter', true);

      //deselects any items in list
      this.selectedOrderBookTypes.forEach(element => {
          element.selected = false;
      });

      //sets list to empty array
      this.selectedOrderBookTypes = [];

      //updates label
      this.setOrderBookLabel();
  }

  orderBookTypeOptionClicked(event: Event, orderBookType: OrderBookTypeVm) {
      event.stopPropagation();
      this.orderBookTypeToggleSelection(orderBookType);
  }

  orderBookTypeToggleSelection(orderBookType: OrderBookTypeVm) {
      orderBookType.selected = !orderBookType.selected;
      if (orderBookType.selected) {
          this.selectedOrderBookTypes.push(orderBookType);
      } else {
          const i = this.selectedOrderBookTypes.findIndex(value => value.orderBook === orderBookType.orderBook);
          this.selectedOrderBookTypes.splice(i, 1);
      }

      this.orderBookTypeControl.setValue(this.selectedOrderBookTypes);
      this.contractSearchFilterQueryModel.search.orderBookFilter = [];
      this.selectedOrderBookTypes.map(v => {
          this.contractSearchFilterQueryModel.search.orderBookFilter.push(v.orderBook);
      });

      this.setOrderBookLabel();
  }

  private setOrderBookLabel() {
      const label1 = (<HTMLLabelElement>document.getElementById('orderBookLabel1'));
      const label2 = (<HTMLLabelElement>document.getElementById('orderBookLabel2'));

      try {
          if (label1 != null && label2 != null) {
              label1.innerHTML = this.selectedOrderBookTypes[0] ? this.selectedOrderBookTypes[0].orderBook : 'Select Order Book Type';
              label1.className = 'dark-text';
              label2.innerHTML = this.selectedOrderBookTypes[0] ? this.selectedOrderBookTypes[0].orderBook : 'Select Order Book Type';
              label2.className = 'dark-text';
          } else if (this.contractSearchFilterQueryModel.search.orderBookFilter.length >= 2) {
              label1.innerHTML = 'Multi';
              label1.className = 'dark-text';
              label2.innerHTML = 'Multi';
              label2.className = 'dark-text';
          } else {
              label1.innerHTML = 'Select Order Book Type';
              label1.className = '';
              label2.innerHTML = 'Select Order Book Type';
              label2.className = '';
          }
      }
      catch (e) {
          console.log(e);
          if (label1 != null && label2 != null) {
              label1.innerHTML = 'Select Order Book Type';
              label1.className = '';
              label2.innerHTML = 'Select Order Book Type';
              label2.className = '';
          }
      }
  }

  async assignOrderBookType() {
      if (!isNullOrUndefined(this.userPreferencesData.orderBookType)) {
          this.userPreferencesData.orderBookType.split(', ').forEach(orderBook => {
              if (orderBook) {
                  const obt = this.orderBookTypes.filter(option => { return option.orderBook.toLowerCase().indexOf(orderBook.toLowerCase()) >= 0; })[0];
                  this.orderBookTypeToggleSelection(obt);
              }
          });
      }
  }

  async toggleOrderBookPanelOpenState(override: boolean = undefined) {
      if (override === undefined) {
          this.orderBookPanelOpenState = !this.orderBookPanelOpenState;
      } else {
          this.orderBookPanelOpenState = override;
      }
  }

  groupRequests(groupByField: string) {
      if (groupByField !== this.groupBy) {
          this.groupBy = groupByField;
          this.searchRequests('groupByField', groupByField);
      }
  }

  searchRequests(property: string, value: string) {
      if (property !== null) {
          if (Array.isArray(this.requestSearchFilter[property])) {
              if (value !== null && value !== '') {
                  if (!this.requestSearchFilter[property].includes(value)) {
                      this.requestSearchFilter[property].pop();
                      this.requestSearchFilter[property].push(value);
                  }
              }
              else {
                  this.requestSearchFilter[property] = [];
              }
          }
          else {
              if (value !== null && value !== '') {
                  this.requestSearchFilter[property] = value;
              }
              else {
                  this.requestSearchFilter[property] = null;
              }
          }
      }

      this.requestSearchFilter['territoryFilter'] = this.defaultTerritories;

      this.showRequests();
  }

  showRequests() {
      this.loadingRequests = true;
      this.allocateInventoryService.getRequests(
          this.requestSearchFilter).subscribe(d => {
          this.requests = d;
          this.clearSelectedRows();
          this.loadingRequests = false;
      }, () => {
          this.loadingRequests = false;
      });
  }

  clearAllRequestInput() {
      this.requestDepotInputAvail.value = null;
      this.requestDepotInputAvail.filterVal = null;
      this.gradeTypeInputAvail.value = null;
      this.gradeTypeInputAvail.filterVal = null;
      this.gradeGroupInputAvail.value = null;
      this.gradeGroupInputAvail.filterVal = null;
      this.gradeInputAvail.value = null;
      this.gradeInputAvail.filterVal = null;
      this.showGradeButton = false;
      this.showSalesGradeButton = false;
  }

  clearAllContractInput() {
      this.gradeInputDest.value = null;
      this.gradeInputDest.filterVal = null;
      this.salesGradeInputDest.value = null;
      this.salesGradeInputDest.filterVal = null;
      this.partyInputDest.value = null;
      this.partyInputDest.filterVal = null;
      this.contractRefInputDest.value = null;
      this.contractRefInputDest.filterVal = null;
      this.contractDepotInputAvail.value = null;
      this.contractDepotInputAvail.filterVal = null;

      this.commentInputDest.nativeElement.value = null;

      this.fromDateInputDest.value = null;
      this.toDateInputDest.value = null;
      this.internalSalesInputDest.checked = false;

      this._okToLoad = undefined;
      this.contractSearchFilterQueryModel.search.okToLoad = undefined;

      this._classification = undefined;
      this.contractSearchFilterQueryModel.search.classification = undefined;
      this.deliveryPointInputDest.nativeElement.value = null;

      this.setadvancedFilterCount();

      this.clearOrderBook();
  }

  initSelectedRows() {
      for (let index = 0; index < this.requests.length; index++) {
          this.selectedRows[index] = [];
      }
  }

  nameof<T>(key: keyof T): keyof T {
      return key;
  }

  setValueForSearchContracts(property: string, value: string) {
      if (property !== null && (property === 'gradeFilter' || property === 'salesGradeFilter' || property === 'orderBookFilter'
      || property === 'partyFilter')) {
          if (value !== null && value !== '') {
              if (!this.contractSearchFilterQueryModel.search[property].includes(value)) {
                  this.contractSearchFilterQueryModel.search[property].pop();
                  this.contractSearchFilterQueryModel.search[property].push(value);
              }
          }
          else {
              this.contractSearchFilterQueryModel.search[property] = [];
          }
          this.contractSearchFilterQueryModel.sort.reverse = false;
      }
      else if (property !== null && (property === 'contractsSortByDate')) {
          if (this.contractSearchFilterQueryModel.sort.predicate != null) {
              this.contractSearchFilterQueryModel.sort.reverse = !this.contractSearchFilterQueryModel.sort.reverse;
          }
          this.contractSearchFilterQueryModel.sort.predicate = this.nameof<ContractPartyVm>('earliestStartDate');
      }
      else if (!isNullOrUndefined(property)) {
          this.contractSearchFilterQueryModel.search[property] = value?.trim();
      }
  }

  searchContracts(property: string, value: string) {
      if (property !== null && (property === 'gradeFilter' || property === 'salesGradeFilter' || property === 'orderBookFilter'
      || property === 'partyFilter')) {
          if (value !== null && value !== '') {
              if (!this.contractSearchFilterQueryModel.search[property].includes(value)) {
                  this.contractSearchFilterQueryModel.search[property].pop();
                  this.contractSearchFilterQueryModel.search[property].push(value);
              }
          }
          else {
              this.contractSearchFilterQueryModel.search[property] = [];
          }
          this.contractSearchFilterQueryModel.sort.reverse = false;
      }
      else if (property !== null && (property === 'contractsSortByDate')) {
          if (this.contractSearchFilterQueryModel.sort.predicate != null) {
              this.contractSearchFilterQueryModel.sort.reverse = !this.contractSearchFilterQueryModel.sort.reverse;
          }
          this.contractSearchFilterQueryModel.sort.predicate = this.nameof<ContractPartyVm>('earliestStartDate');
      }
      else if (!isNullOrUndefined(property)) {
          this.contractSearchFilterQueryModel.search[property] = value?.trim();
      }
      this.showContracts();
  }

  showContracts() {
      if (isNullOrUndefined(this.contractSearchFilterQueryModel.search['orderBookFilter']) || this.contractSearchFilterQueryModel.search['orderBookFilter'].length === 0) {
          this.toastr.error('Please select at least one order book.');
          return;
      }
      this.toggleOrderBookPanelOpenState(false); //close accordion
      this.showAdditionalFilter = true;
      this.setadvancedFilterCount(); // add numbers to the filter
      this.loadingContracts = true;
      this.noContractsFound = false;
      this.contractSearchFilterQueryModel.search['territoryFilter'] = this.defaultTerritories;
      this.contractSearchFilterQueryModel.search.comment = isNullOrUndefined(this.commentInputDest) ? null : this.commentInputDest.nativeElement.value;
      this.contractSearchFilterQueryModel.search.deliveryPoint = isNullOrUndefined(this.deliveryPointInputDest) ? null : this.deliveryPointInputDest.nativeElement.value;
      this.contractSearchFilterQueryModel.search.depotNo = this.contractSearchFilterQueryModel.search.depotNo === '' || isNullOrUndefined(this.contractSearchFilterQueryModel.search.depotNo) ? null : this.contractSearchFilterQueryModel.search.depotNo;
      this.contractSearchFilterQueryModel.search.contractRef = this.contractSearchFilterQueryModel.search.contractRef === '' || isNullOrUndefined(this.contractSearchFilterQueryModel.search.contractRef) ? null : this.contractSearchFilterQueryModel.search.contractRef;
      this.allocateService.getContracts(this.contractSearchFilterQueryModel).subscribe(c => {
          this.contractsGroup = c;
          this.noContractsFound = this.contractsGroup[0].pagedParties.parties.length === 0 && this.contractsGroup[1].pagedParties.parties.length === 0;
          this.loadingContracts = false;
      },
      () => {
          this.loadingContracts = false;
      });
  }

  onCommentChange(comment: string) {
      if (comment.length > 0)
          this.showCommentCloseButton = true;
      else
          this.showCommentCloseButton = false;
  }

  onDeliveryPointChange(deliverPoint: string) {
      if (deliverPoint.length > 0)
          this.showDeliveryPointCloseButton = true;
      else
          this.showDeliveryPointCloseButton = false;
  }

  toggleAdditional() {
      this.showAdditionalFilter = !this.showAdditionalFilter;
      this.setadvancedFilterCount();
  }

  setadvancedFilterCount() {
      this.advancedFilterCount = 0;

      if (this.commentInputDest?.nativeElement.value.length > 0)
          this.advancedFilterCount++;

      if (this.salesGradeInputDest?.value)
          this.advancedFilterCount++;

      if (this.contractSearchFilterQueryModel.search?.okToLoad != undefined)
          this.advancedFilterCount++;

      if (this.contractSearchFilterQueryModel.search?.classification != undefined)
          this.advancedFilterCount++;

      if (this.deliveryPointInputDest?.nativeElement.value.length > 0)
          this.advancedFilterCount++;
  }

  toggleRow(tableIndex: number, rowIndex: number, item: any) {
      // Multi select rows functionality
      const arrayIndex = this.selectedRows[tableIndex].indexOf(rowIndex);
      if (arrayIndex === -1) {
          this.selectedRows[tableIndex].push(rowIndex);
          this.selectedRowCount++;
          this.bulkUpdateRequests.push(item);
      } else {
          this.selectedRows[tableIndex].splice(arrayIndex, 1);
          this.selectedRowCount--;
          this.bulkUpdateRequests.splice(item, 1);
      }
      this.bulkUpdateMode = this.bulkUpdateRequests.length > 1;
  }

  bulkUpdate(formVal: any) {
      if (formVal.lifecycleStatusId == 0 && !formVal.comment) {
          this.toastr.info('Please select lifecycle status or add comment', 'Bulk Update');
          return;
      }

      this.loadingRequests = true;
      const model = new BulkUpdateModel();
      model.comment = formVal.comment,
      model.lifecycleStatusId = formVal.lifecycleStatusId;
      model.requestIds = this.bulkUpdateRequests.map(x => x.requestId);

      this.allocationService.bulkUpdateRequests(model).subscribe(r => {
          if (r) {
              this.bulkUpdateMode = false;
              this.bulkUpdateRequests = [];
              this.toastr.success('Requests updated successfully', 'Bulk Update');
              this.showRequests();
          } else {
              this.toastr.error('Requests update failed. Please try again', 'Bulk Update');
          }
      });
      this.loadingRequests = false;
  }

  clickTruck(contract: ContractHeaderViewModel, party: ContractPartyVm) {
      let selectedCount = 0;
      let selectedTable: number;

      this.selectedRows.forEach((a, i) => {
          if (a.length > 0) {
              selectedCount++;
              selectedTable = i;
          }
      });

      if (selectedCount === 0) { this.openCreateDialog(contract, party); }
      // check only one table selected
      if (selectedCount !== 1) { return; }
      // check only one row selected
      if (this.selectedRows[selectedTable].length !== 1) { return; }

      const selectedRow = this.selectedRows[selectedTable][0];

      const selectedRequest = this.requests[selectedTable].requests[selectedRow];
      this.openRequestDialog([selectedRequest], contract, party, true);
  }

  clickBasket(event: MouseEvent, contract: ContractHeaderViewModel, party: ContractPartyVm) {
      this.basketStyle = { top: `${event.y - 420}px`, left: `${event.x - 120}px`, position: 'absolute', 'z-index': '10' };
      this.selectedContract = contract;
      this.selectedParty = party;
      for (let tableIndex = 0; tableIndex < this.selectedRows.length; tableIndex++) {
          if (this.selectedRows[tableIndex].length > 0) {
              for (const rowIndex of this.selectedRows[tableIndex]) {
                  const request = this.requests[tableIndex].requests[rowIndex];
                  this.addToRequestBasket(request);
              }
          }
      }
      this.showBasket = true;
      this.clearSelectedRows();
  }

  getBasketStyle() {
      return this.basketStyle;
  }

  dropTruck(event: CdkDragDrop<AllocateRequestVm>, contract: ContractHeaderViewModel, party: ContractPartyVm) {
      if (event.previousContainer !== event.container) {
          const selected = event.previousContainer.data;
          if (this.dragSelectedRequests.length === 0) {
              this.dragSelectedRequests.push(selected);
          }
          const failingGrades = this.requestGradesNotOnContract(this.dragSelectedRequests, contract);

          if (failingGrades.length > 1) {
              const distinctRequests = failingGrades.filter((n, i) => failingGrades.indexOf(n) === i);
              if (distinctRequests.length > 1) {
                  this.toastr.warning('Cannot allocate mixed grades, contract requests must be of same grade');
                  return;
              }
          }
          this.openRequestDialog(this.dragSelectedRequests, contract, party, true);
      }
  }

  dropBasket(event: CdkDragDrop<AllocateRequestVm>, contract: ContractHeaderViewModel, party: ContractPartyVm) {
      if (this.showBasket || this.isMixedGradeContract(contract)) {
          if (!this.showBasket) {
              const rect = event.container.element.nativeElement.getBoundingClientRect();
              this.basketStyle = {
                  top: `${rect.top - 420}px`,
                  left: `${rect.left - 120}px`,
                  position: 'absolute', 'z-index': '10'
              };
          }
          if (!isNullOrUndefined(contract)) { this.selectedContract = contract; }
          if (!isNullOrUndefined(party)) { this.selectedParty = party; }
          this.addToRequestBasket(event.previousContainer.data);
          this.showBasket = true;
      }
  }

  addToRequestBasket(item: AllocateRequestVm) {
      if (this.selectedRequests.find(a => a.grade === item.grade)) {
          this.toastr.warning('Only mixed grade contract requests can be added');
      } else if (!this.selectedRequests.find(a => a.yardCode !== item.yardCode)) {
          this.selectedRequests.push(item);
      } else {
          this.toastr.warning('Mixed grade contract requests must come from the same yard');
      }
  }

  cancelBasket() {
      this.showBasket = false;
      this.selectedRequests = [];
      this.selectedContract = null,
      this.selectedParty = null;
  }

  submitBasket() {
      this.showBasket = false;
      this.openRequestDialog(this.selectedRequests, this.selectedContract, this.selectedParty, false);
  }

  clearSelectedRows() {
      this.selectedRows = [];
      this.selectedRowCount = 0;
      this.initSelectedRows();
      this.bulkUpdateMode = false;
      this.bulkUpdateRequests = [];
      this.dragSelectedRequests = [];
  }

  isInBasket(requestId: number): boolean {
      return this.selectedRequests.findIndex(r => r.requestId === requestId) > -1;
  }

  isMixedGradeContract(contract: ContractHeaderViewModel) {
      return contract && contract.contractLines && contract.isContractMixedGrade;
  }

  toggleVisibility(element) {
      if (element.hidden) {
          element.hidden = false;
      } else {
          element.hidden = true;
      }
  }

  togglePurchases($event: any) {
      if (this.shouldSearch()) {
          this.searchContracts('includeInternalSales', $event.checked);
      } else {
          this.contractSearchFilterQueryModel.search.includeInternalSales = $event.checked;
      }
  }

  resetRequestFilter() {
      this.requestSearchFilter = {
          fromDate: null,
          toDate: null,
          gradeFilter: [],
          gradeTypeFilter: [],
          gradeGroupFilter: [],
          depotFilter: [],
          orderBookFilter: null,
          partyFilter: null,
          groupByField: null,
          lifecycleStatusId: 0,
          territoryFilter: []
      };
  }

  resetContractFilter() {
      this.contractSearchFilterQueryModel.search = {
          fromDate: null,
          toDate: null,
          gradeFilter: [],
          salesGradeFilter: [],
          orderBookFilter: [],
          partyFilter: [],
          contractRef: null,
          includeInternalSales: null,
          okToLoad: null,
          depotNo: null,
          territoryFilter: [],
          comment: null,
          classification: null,
          deliveryPoint: null
      };
  }

  private shouldSearch(): boolean {
      let search = false;
      for (const p in this.contractSearchFilterQueryModel.search) {
          if (this.contractSearchFilterQueryModel.search.hasOwnProperty(p) && p !== 'includeInternalSales') {
              if (p === 'gradeFilter' || p === 'orderBookFilter' || p === 'partyFilter') {
                  if (!isNullOrUndefined(this.contractSearchFilterQueryModel.search[p]) && this.contractSearchFilterQueryModel.search[p].length > 0) {
                      search = true;
                  }
              }
              else {
                  if (!isNullOrUndefined(this.contractSearchFilterQueryModel.search[p]) && this.contractSearchFilterQueryModel.search[p] !== '') {
                      search = true;
                  }
              }
          }
      }
      return search;
  }

  private requestGradesNotOnContract(requests: AllocateRequestVm[], contract: ContractHeaderViewModel): string[] {
      const failingGrades = [];
      for (const r of requests) {
          if (contract.contractLines.map(l => l.grade).indexOf(r.grade) === -1) {
              failingGrades.push(r.grade);
          }
      }
      return failingGrades;
  }

  private openRequestDialog(requests: AllocateRequestVm[], contract: ContractHeaderViewModel, party: ContractPartyVm, multiGrade: boolean) {
      const failingGrades = this.requestGradesNotOnContract(requests, contract);

      if (failingGrades.length > 0) {
          const multipleRequests = failingGrades.length > 1;
          const message = multipleRequests ? 'Missing grades' : 'Missing grade';
          const bodyMsg = multipleRequests
              ? `Some/all of the grades you attempted to allocate; ${failingGrades.join(', ')} are missing from the contract`
              : `The grade you attempted to allocate ${failingGrades[0]} is missing from the contract`;

          const body = `${bodyMsg}. Do you want to continue?`;

          const dialogRef = this.dialog.open(ActionConfirmDialogComponent, {
              minWidth: '240px',
              maxWidth: '80%',
              maxHeight: '80%',
              data: {
                  aria: message,
                  title: message,
                  body,
                  model: 'confirm'
              }
          });

          dialogRef.afterClosed().subscribe(s => {
              if (isUndefined(s)) {
                  this.clearSelectedRows();
                  this.cancelBasket();
                  return;
              }
              this.openAllocateDialog(requests, contract, party, multiGrade);
          });
      } else {
          this.openAllocateDialog(requests, contract, party, multiGrade);
      }
  }

  private success(message: string, title: string) {
      this.searchContracts(null, null);
      this.searchRequests(null, null);
      this.toastr.success(message, title);
  }

  private openAllocateDialog(requests: AllocateRequestVm[], contract: ContractHeaderViewModel, party: ContractPartyVm, multiGrade: boolean) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.minWidth = '900px';
      dialogConfig.maxWidth = '90%';
      dialogConfig.maxHeight = '90vh';
      dialogConfig.data = { party, contract, requests };
      dialogConfig.disableClose = true;

      const dialogRef = multiGrade ? this.dialog.open(AllocateMultiGradeDialogComponent, dialogConfig) :
          this.dialog.open(AllocateDialogComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(() => {
          this.clearSelectedRows();
          this.cancelBasket();

          //Refresh search
          this.searchContracts(null, null);
          this.searchRequests(null, null);
      });
  }

  private openCreateDialog(contract: ContractHeaderViewModel, party: ContractPartyVm) {
      const dialogRef = this.dialog.open(AllocateCreateDialogComponent, {
          minWidth: '900px',
          maxWidth: '90%',
          minHeight: '300px',
          maxHeight: '90vh',
          data: {
              party,
              contract
          },
          disableClose: true,
      });

      dialogRef.afterClosed().subscribe(() => {
          this.clearSelectedRows();
          this.cancelBasket();

          //Refresh search
          this.searchContracts(null, null);
          this.searchRequests(null, null);
      });
  }

  clearRequestFilers() {
      this.clearRequestInput('depotFilter', true);
      this.clearRequestInput('gradeTypeFilter', true);
      this.clearRequestInput('gradeGroupFilter', true);
      this.clearRequestInput('gradeFilter', true);
      this.clearRequestInput('lifecycleStatusId', true);
      this.clearAllRequestInput();
      this.searchRequests(null, null);
  }

  clearContractFilers() {
      this.clearContractInput('gradeFilter', true);
      this.clearContractInput('orderBookFilter', true);
      this.clearContractInput('partyFilter', true);
      this.clearContractInput('contractRef', true);
      this.clearAllContractInput();
      this.contractSearchFilterQueryModel.pagination.start = 1;
      this.contractSearchFilterQueryModel.pagination.number = 10;
      this.contractSearchFilterQueryModel.sort.predicate = null;
      this.contractSearchFilterQueryModel.sort.reverse = false;
  }

  requestSelection(item: any) {
      const iExists: AllocateRequestVm[] = this.dragSelectedRequests.filter(d => d.requestId == item.requestId);
      if (iExists.length > 0) {
          this.dragSelectedRequests = this.dragSelectedRequests.filter(d => d.requestId !== item.requestId);
      }
      else {
          this.dragSelectedRequests.push(item);
      }

      if (this.dragSelectedRequests.length === 1) {
          this.selectedRequest = this.dragSelectedRequests[0];
      }
  }

  editRequest() {
      if (this.selectedRowCount != 1) return;

      const request = this.selectedRequest;
      const requestdata = {
          id: request.requestId,
          depot: request.depotNo,
          depotName: request.depotName,
          heap: request.heap,
          heapNo: request.heapNo,
          readyDate: request.readyDate,
          gradeDesc: request.gradeDesc,
          weight: request.weight,
          remainingWeight: request.remainingWeight,
          uomId: request.remainingWeightUomId,
          containerSizeId: request.containerSizeId,
          packageOptionId: request.packageOptionId,
          statusId: request.statusId,
          remainingLoads: request.remainingLoads,
          totalLoads: request.totalLoads
      };
      const dialogRef = this.dialog.open(EditRequestDialogComponent, {
          minWidth: '240px',
          maxWidth: '80%',
          maxHeight: '80%',
          data: requestdata,
          disableClose: true,
      });

      dialogRef.afterClosed().subscribe(r => {
          this.showRequests();
          if (isUndefined(r)) { return; }
          this.allocationService.updateRequest(r, requestdata.id)
              .subscribe(() => this.success('Request successfully edited', 'Edit Request'),
                  err => {
                      if (err.status === 200) {
                          this.success('Request successfully edited', 'Edit Request');
                      } else {
                          this.dialog.open(AlertDialogComponent, {
                              minWidth: '240px',
                              maxWidth: '80%',
                              maxHeight: '80%',
                              data: {
                                  ok: 'Ok',
                                  aria: 'Edit Failed',
                                  title: 'Edit Failed',
                                  body: 'Something went wrong and the request could not be edited. Please try again later.'
                              }
                          });
                      }
                  });
      });
  }

  sortRequestData(sort: Sort, requestTableIndex: number) {
      if (!sort.active || sort.direction === '') {
          return;
      }
      this.requests[requestTableIndex].requests = this.requests[requestTableIndex].requests.sort((a, b) => {
          const isAsc = sort.direction === 'asc';
          switch (sort.active) {
          case 'depot': return this.compare(a.depotNo, b.depotNo, isAsc);
          case 'description': return this.compare(a.description, b.description, isAsc);
          case 'status': return this.compare(a.status, b.status, isAsc);
          case 'requestDate': return this.compare(a.requestDate, b.requestDate, isAsc);
          case 'readyDate': return this.compare(a.readyDate, b.readyDate, isAsc);
          case 'grade': return this.compare(a.grade, b.grade, isAsc);
          case 'remainingWeight': return this.compare(a.remainingWeight, b.remainingWeight, isAsc);
          case 'remainingLoads': return this.compare(a.remainingLoads, b.remainingLoads, isAsc);
          case 'containerPackaging': return this.compare(a.containerPackaging, b.containerPackaging, isAsc);
          default: return 0;
          }
      });
  }

  showRequestComments(item) {
      if (this.addedCommentSubscription) {
          this.addedCommentSubscription.unsubscribe();
      }
      this.addedCommentSubscription = this.sidenavService.itemAdded$.subscribe((addedComment) => {
          item.comments.push({
              author: addedComment.author,
              commentDate: addedComment.timestamp,
              comment: addedComment.body
          });
      });
      this.sidenavService.next({ type: 'comments', item });
  }

  private compare(a: number | string | null | undefined, b: number | string | null | undefined, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  copyAllocateRequestCommentsToClipboard(items: AllocateRequestCommentVm[]) {
      let textToCopy = '';
      let successfulCopies = 0;
      items.forEach((comment, index, array) => {
          const commentText = this.formatComment(comment);
          if (commentText) {
              textToCopy += commentText;
              if (index !== array.length - 1)
                  textToCopy += '\n\n';

              successfulCopies++;
          }
      });

      this.copyTextToClipboard(textToCopy, false);
      this.toastr.success(`${successfulCopies} Comment${successfulCopies > 1 ? 's' : ''} copied to clipboard.`);
  }

  formatComment(comment: AllocateRequestCommentVm) {
      if (comment && comment.comment && comment.author && comment.commentDate) {
          return `${comment.comment.replace(/<br\s*[/]?>/gi, '\n')}\n${comment.author} - ${comment.isSystemComment ? ' (' + this.systemComment + ')' : ''} ${comment.commentDate}`; //replace breaks with new lines
      }
      return undefined;
  }

  private copyTextToClipboard(val: string, toast = false) {
      const copyBox = document.createElement('textarea');
      copyBox.style.position = 'fixed';
      copyBox.style.left = '0';
      copyBox.style.top = '0';
      copyBox.style.opacity = '0';
      copyBox.value = val;
      document.body.appendChild(copyBox);
      copyBox.focus();
      copyBox.select();
      document.execCommand('copy');
      document.body.removeChild(copyBox);
      if (toast)
          this.toastr.success('Copied to clipboard.');
  }

  clearComment() {
      this.commentInputDest.nativeElement.value = null;
      this.showCommentCloseButton = false;
  }

  clearDeliveryPoint() {
      this.deliveryPointInputDest.nativeElement.value = null;
      this.showDeliveryPointCloseButton = false;
  }

  showAllocationLoads(contract: ContractHeaderViewModel) {
      contract.showAllocations = !contract.showAllocations;

      if (contract.showAllocations) {
          this.loadingContractAllocation = true;
          this.allocateService.geAllocationLoads(contract.contractHeaderId.toString())
              .pipe(
                  finalize(() => this.loadingContractAllocation = false))
              .subscribe(d => {
                  contract.allocationLoads = d;
              });
      }
  }

  toggleShowRequestsSection() {
      this.hideRequestsSection = !this.hideRequestsSection;
  }

  contractsPageChanged(page: number) {
      this.contractsPage = page;
      this.showContracts();
  }
}
