<div aria-label="Edit Request">
    <div class="dialog-header">
        Edit Inventory Reservation
    </div>
    <app-spinner [displayProgressSpinner]="loadingEditRequestDialog" [container]="loadingEditRequestDialogBox"
        #loadingEditRequestDialogSpinner>
    </app-spinner>
    <div class="dialog-body" #loadingEditRequestDialogBox>
        <form [formGroup]="editInventoryReservationForm" (ngSubmit)="ok()">
            
            <div class="row-container">
                    <mat-form-field>
                        <input type="text" matInput formControlName="region" [matAutocomplete]="regionAuto"
                            placeholder="Region" #regionInput 
                            (input)="regionValueChange($event.target.value)">
                    </mat-form-field>
                    <mat-autocomplete #regionAuto="matAutocomplete">
                        <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
                        <mat-option *ngFor="let region of filteredRegion" [value]="region">
                            {{region}}
                        </mat-option>
                    </mat-autocomplete>
                <mat-form-field class="dialog-input-container" flex>
                    <input type="text" matInput formControlName="depot" [matAutocomplete]="depotAuto"
                        placeholder="Depot" oninput="this.value = this.value.toUpperCase()">
                </mat-form-field>
                <mat-autocomplete #depotAuto="matAutocomplete" [displayWith]="displayDepot">
                    <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
                    <mat-option *ngFor="let depot of filteredDepots?.items " [value]="depot">
                        {{depot?.depotNumber}} {{depot?.depotName}}</mat-option>
                </mat-autocomplete>

                <mat-form-field class="dialog-input-container" flex style="margin-bottom: 2px">
                    <mat-label>Ready Date</mat-label>
                    <input matInput [matDatepicker]="picker1" formControlName="readyDate">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>

                <mat-form-field class="dialog-input-container">
                    <mat-label>Expiry Date</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="expiryDate">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
               
            </div>
            <div class="row-container">
                <mat-form-field class="mid-form-field">
                    <input type="text" matInput formControlName="heapNo" [matAutocomplete]="heapAuto" placeholder="Heap"
                      #heapInput minlength="1" (input)="heapValueChange($event.target.value)">
                  </mat-form-field>
                  <mat-autocomplete #heapAuto="matAutocomplete" [displayWith]="displayHeap">
                    <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
                    <mat-option *ngFor="let heap of filteredHeaps?.items" [value]="heap">
                      {{heap?.heapShortName+ ' ' + heap?.heapDesc}}</mat-option>
                  </mat-autocomplete>
                <mat-form-field class="dialog-input-container" flex>
                    <input matInput formControlName="materialDescription" type="text" placeholder="Material Description" >
                </mat-form-field>
                <mat-form-field class="dialog-input-container" flex>
                    <mat-select formControlName="containerSizeId" placeholder="Container Type">
                        <mat-option></mat-option>
                        <mat-option *ngFor="let opt of pageModel?.containerSizes" [value]="opt.id">{{ opt?.displayName }}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="containerSize.touched && containerSize.errors?.required">This is required!
                      </mat-error>
                </mat-form-field>
                <mat-form-field class="dialog-input-container" flex>
                <mat-select formControlName="packaging" placeholder="Packaging">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let opt of pageModel?.packageOptions" [value]="opt.id">{{ opt?.displayName }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="packaging.touched && packaging.errors?.required">This is required!</mat-error>
                </mat-form-field>
            </div>
            <div class="row-container">
                <mat-form-field class="mid-form-field">
                    <input matInput formControlName="grade" type="text" placeholder="Grade" [matAutocomplete]="gradeAuto">
                </mat-form-field>
                <mat-autocomplete #gradeAuto="matAutocomplete" [displayWith]="displayGrade">
                    <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
                    <mat-option *ngFor="let grade of filteredGrade" [value]="grade">
                        {{grade}}
                    </mat-option>
                </mat-autocomplete>
                <mat-form-field class="dialog-input-container" flex>
                    <mat-select placeholder="Status" formControlName="status">
                        <mat-option *ngFor="let opt of pageModel?.statuses" [value]="opt?.id">{{ opt?.name }}
                        </mat-option>
                    </mat-select>
                    <div class="md-errors-spacer"></div>
                </mat-form-field>
                <mat-form-field class="dialog-input-container" flex>
                    <mat-select formControlName="uomId" placeholder="Unit">
                        <mat-option></mat-option>
                        <mat-option *ngFor="let opt of uoms" [value]="opt.id">{{ opt?.name }}</mat-option>
                      </mat-select>
                </mat-form-field>
                <mat-form-field class="dialog-input-container" flex>
                    <input matInput formControlName="remainingWeight" placeholder="Remaining Weight" required>
                    <mat-error *ngIf="remainingWeight.errors?.pattern">Please enter a valid value i.e. 1200</mat-error>
                </mat-form-field>
            </div>
            <div class="row-container">
                <mat-form-field class="dialog-input-container" flex>
                    <input matInput formControlName="weight" type="number" placeholder="Weight" disabled>
                    <mat-error *ngIf="weight.errors?.pattern">Please enter a valid value i.e. 1200</mat-error>
                </mat-form-field>
                <mat-form-field class="dialog-input-container" flex>
                    <input matInput formControlName="remainingLoads" type="number" placeholder="Remaining Loads" >
                    <mat-error *ngIf="remainingLoads.errors?.pattern">Please enter a valid value i.e. 1200</mat-error>
                </mat-form-field>
                <mat-form-field class="dialog-input-container" flex>
                    <input matInput formControlName="totalLoads" placeholder="Total Loads" required disabled>
                </mat-form-field>
            </div>

            <div mat-dialog-actions layout="row" class="dialog-flex-justify-center dialog-margin-bottom-10">
                <button mat-flat-button (click)="cancel()" type="button" class="md-raised md-ink-ripple">Cancel</button>
                <button mat-flat-button ng-disabled="allocationForm.$invalid" type="submit" color="primary"
                    [disabled]="isSubmit" class="md-raised md-primary md-ink-ripple">Save</button>
            </div>
        </form>
    </div>

</div>
