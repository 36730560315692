import { Injectable, ElementRef } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';


@Injectable({
	providedIn: 'root'
})
export class PrintingService {
	private requestSubPrinting = new ReplaySubject<PrintModel>(1);
	print$: Observable<PrintModel> = this.requestSubPrinting.asObservable();
  
	constructor() { }

	public print(request: PrintModel)
	{
		this.requestSubPrinting.next(request);
	}
}

export interface PrintModel {
  printing: boolean;
}
