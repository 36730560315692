<ng-container [formGroup]="allocationAppointments">
    <div *ngFor="let ac of allocationAppointments?.controls; let i = index"
      [formGroupName]="i" class="dialog-input-container" layout="row">
      <div class="row-container shade">
          <i class="fa fa-question-circle" style="margin-top: auto; margin-bottom: auto; padding-left: 5px;" matTooltip="{{datetimeFromToolTipText}}"></i>
          <app-date-time-picker placeholder="Pickup From Date & Time" formControlName="pickupDateTime" required="true"></app-date-time-picker>
          <app-date-time-picker placeholder="Pickup To Date & Time" formControlName="pickupToDateTime"></app-date-time-picker>

          <button type="button" mat-flat-button style="height:50px; width:190px;"
            [disabled]="ValidPickupDate(i) || loadinghaulageRates"
            (click)="getAvailableHauliers(i)" color="primary">Show Rates
          </button>

          <mat-form-field>
            <mat-select name="hpm" id="hpm" placeholder="Available rates"   [(value)]="selectedHaulageMatrix"
            (selectionChange)="updateHaualgeRate($event, i)">
              <mat-option *ngFor="let r of haulageRates" [value]="r">
                {{r.haulageRate}} &nbsp; {{r.currency}} / {{r.haulierName}} &nbsp; ({{r.haulierAccountNo}}) / {{r.shippingLine}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <button type="button" mat-flat-button style="height: 50px; width: 50px; margin-right:10px;"
          [disabled]="haulageRates.length==0"
          (click)="fixHaulageRate(i)" color="primary">Fix</button>
      </div>

      <div class="row-container shade">
        <mat-form-field>
          <input type="text" matInput formControlName="haulier" #haulier [matAutocomplete]="haulierAuto"
              placeholder="Haulier" oninput="this.value = this.value.toUpperCase()">
            <button mat-button *ngIf="showHaulierClear(haulier)" type="button"
              matSuffix mat-icon-button aria-label="Clear"  testName="haulierClear"
              (click)="haulierClear(i)">
              <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <mat-autocomplete #haulierAuto="matAutocomplete" [displayWith]="displayHaulier">
          <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
          <mat-option *ngFor="let haulier of filteredHauliers"
          [value]="haulier">{{haulier?.accountNumber}} - {{haulier?.name}}</mat-option>
        </mat-autocomplete>

        <!-- NB: Commented out the Automated LUNK601 button as asked for on 12/05/2022 -->
        <!-- <button type="button" mat-flat-button color="primary" style="height: 50px; width: 193px;" (click)="setHaulierLunk(i)">LUNK601</button> -->

        <mat-form-field>
          <input matInput type="number" aria-label="agreed haulage rate"
            placeholder="{{spotRateText}}" class="md-input"
            formControlName="agreedHaulageRate" autocomplete="off"
            (change)="updateHaulageRateDropdown($event)" >
        </mat-form-field>
        <mat-form-field>
          <mat-select formControlName="agreedHaulageRateUomId"
            placeholder="Spot Rate UOM">
            <mat-option *ngFor="let uom of uoms" [value]="uom.id">
                {{uom.code + ' - ' + uom.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row-container">
        <div class="checkbox-container">
          <mat-checkbox formControlName="firstComeFirstServe">
            First Come First Serve
          </mat-checkbox>
          <mat-checkbox *ngIf="!hidePendingDispatch"  formControlName="pendingDispatch">
            Pending Dispatch
          </mat-checkbox>
        </div>
      </div>

      <div class="row-container">
        <mat-form-field style="width: 220px;">
          <mat-select formControlName="appointmentId" placeholder="Available Appointments"
            (selectionChange)="selectAppointment(i, $event.value);">
            <mat-option></mat-option>
            <mat-option *ngFor="let app of appointments" [value]="app.appointmentId">
              {{app.startDate | momentUtcAsLocaleDate}} {{app.deliveryWindowStart}} - {{app.deliveryNumber}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <input matInput type="text" aria-label="customer apt ref" placeholder="Customer Apt Ref" class="md-input"
            formControlName="customerAptRef" autocomplete="off" maxlength="45">
        </mat-form-field>
        <app-date-time-picker placeholder="Customer Apt From Date & Time" formControlName="customerAptDateTime">
        </app-date-time-picker>
        <app-date-time-picker placeholder="Customer Apt To Date & Time" formControlName="customerAptToDateTime">
        </app-date-time-picker>
        <mat-form-field>
          <input matInput type="text" aria-label="comments" placeholder="Comments" class="md-input"
            formControlName="appointmentComment" autocomplete="off" maxlength="800">
        </mat-form-field>
        <button mat-button (click)="removeAppointment(i)" [disabled]="disableDelBtn()" class="addButton">
            <fa-icon [icon]="trash"></fa-icon>
        </button>
        <button *ngIf="showAddBtn(i)" [disabled]="disableAddBtn()" mat-button (click)="addAppointment()" class="addButton">
          <i class="fa fa-plus-circle"></i>
        </button>
      </div>
    </div>
<ng-container>
