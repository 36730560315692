import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { REST_CONFIG_TOKEN, RestConfigService } from '../rest-config-service';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from '../../tools';


@Injectable()
export class SalesGradeService {
	constructor(
        private _http: HttpClient,
        @Inject(REST_CONFIG_TOKEN) private _configService: RestConfigService
	) {
	}

	public getSalesGrade: (filter?: string) => Observable<string[]>
		= (filter?: string): Observable<string[]> => {
			let queryParameters = new HttpParams();
			const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

			if (!isNullOrUndefined(filter)) {
				queryParameters = queryParameters.append('filter', filter.toString());
			}

			const uri = '/api/SalesGrade/salesgrade';

			return this._http.get<string[]>(this._configService.apiBaseUrl() + uri, { headers: headers, params: queryParameters });
		};
}