<mat-card layout="column" style="padding: 1px;padding-top:0px;">
    <div id="content">
        <mat-toolbar class="md-hue-3" style="background-color:#eceff1;height: 37px;">
            <div class="md-toolbar-tools" style="color:rgb(46, 73, 97);">
                <button mat-button (click)="refresh()">
                    <i class="fa fa-refresh"></i>
                </button>
                <button mat-button (click)="showSearch = !showSearch">
                    <i class="fa fa-search"></i>
                </button>
                <mat-label class="heading">{{heading}}</mat-label>


                <div style="padding-left: 100px;" *ngIf="!isLoading && bookingsheetdata.length > 0">
                    <mat-form-field style="text-align:right;" title="Apply this adjustment to all Grades below">
                        <input type="number" matInput #adjBlk (change)="applyBulkAdjustment(adjBlk.value)"
                            placeholder="Bulk Adjustment %">
                    </mat-form-field>
                    <button mat-button class="md-raised mat-button mat-ink-ripple hide-xs" aria-label="Export to Excel"
                        (click)="applyBulkAdjustment(0)">Reset</button>
                </div>


                <span flex="" style="-ms-flex: 1; -webkit-flex: 1; flex: 1;"></span>
                <button mat-button class="md-raised mat-button mat-ink-ripple hide-xs" aria-label="Export to Excel"
                    (click)="exportToExcel()">Export to Excel</button>
            </div>
        </mat-toolbar>
        <div [hidden]="!showSearch" class="search-box">
            <form [formGroup]="searchForm" (ngSubmit)="search(searchForm.value)">
                <div class="row-container">
                    <div>
                        <mat-form-field style="width: 250px;">
                            <input type="text" matInput formControlName="depot" [matAutocomplete]="depotAuto"
                                placeholder="Depot" #depotInput oninput="this.value = this.value.toUpperCase()">
                        </mat-form-field>

                        <mat-autocomplete #depotAuto="matAutocomplete" [displayWith]="displayDepot">
                            <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
                            <mat-option *ngFor="let depot of filteredDepots?.items " [value]="depot">
                                {{depot?.depotNumber + ' - ' + depot?.depotName}}</mat-option>
                        </mat-autocomplete>
                    </div>
                    <div style="padding-left: 10px;">
                        <app-autocomplete autoCompleteDelay="750" formControlName="gradeGroup" #gradeGroupInput
                            [getResults]="gradeService.getGradeGroups" placeholder="Grade Group"></app-autocomplete>
                    </div>
                    <div style="padding-left: 10px;">
                        <app-autocomplete autoCompleteDelay="750" formControlName="grade" #gradeInput
                            [getResults]="gradeService.getGrade" placeholder="Grade"></app-autocomplete>
                    </div>
                    <div style="padding-left: 10px;">
                        <app-autocomplete autoCompleteDelay="750" formControlName="grade" #gradeInput
                            [getResults]="gradeService.getGrade" placeholder="Grade"></app-autocomplete>
                    </div>
                    <!-- <mat-form-field style="margin: 0px 0px; max-width: 80px;" mat-no-float>
                        <input matInput type="search" placeholder="Sales Grade"
                            formControlName="salesGrade">
                    </mat-form-field> TODO add this back in when the link from Grade -> SalesGrade is present in the db to allow searching via this -->
                    <mat-form-field style="margin: 0px 0px;" mat-no-float>
                        <input matInput type="search" placeholder="Material Description"
                            formControlName="materialDescription">
                    </mat-form-field>
                    <mat-form-field matTooltip="Forecast" style="width: 100px;">
                        <mat-select placeholder="Forecast" formControlName="dateRangeSelection">
                            <mat-option *ngFor="let range of dateRange" [value]="range">{{range.desc}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-checkbox formControlName="showAll" matTooltip="Show all heaps">Show All</mat-checkbox>
                    <button type="submit" mat-button>Apply Filter</button>
                    <button mat-button (click)="clearFilter()" type="button">Clear Filters</button>

                    <mat-form-field>
                        <mat-select formControlName="switchUomCode" placeholder="Switch UOM"
                            (selectionChange)="switchUOM($event.value);">
                            <mat-option *ngFor="let uom of uoms" [value]="uom.code">
                                {{uom.code + ' - ' + uom.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
        </div>
        <div class="table-responsive">
            <app-spinner [displayProgressSpinner]="isLoading" [container]="mainTable" #spinner></app-spinner>
            <div style="width: 100%;overflow-y: auto;min-height: 800px" #mainTable>
                <table name="bookingtable" id="booking-table" cellpadding="0" cellspacing="0"
                    class="table table-hover table-condensed custom-table" width="90%">
                    <thead>
                        <tr class="heading-row">
                            <th style="width: 200px;text-align:left" appTableSort="GroupName"
                                (sort)="sortByInfo($event)" class="interactive hide-sm hide-xs">Grade Group</th>
                            <th style="width: 50px;text-align:left" appTableSort="Grade" (sort)="sortByInfo($event)"
                                class="interactive hide-sm hide-xs">Grade</th>
                            <th style="width: 200px;text-align:left" appTableSort="HeapDesc" (sort)="sortByInfo($event)"
                                class="interactive hide-sm hide-xs">Heap - Material Description</th>
                            <th style="width: 100px;" appTableSort="OpeningStock" (sort)="sortByInfo($event)"
                                class="interactive hide-sm hide-xs">Opening Stock ({{selectedUOMCode}})</th>

                            <th style="width: 50px;" appTableSort="Adjustment" (sort)="sortByInfo($event)"
                                class="interactive hide-sm hide-xs">Adjustment%</th>

                            <th style="width: 70px;" appTableSort="Flow" (sort)="sortByInfo($event)"
                                class="interactive hide-sm hide-xs">Forecast ({{selectedUOMCode}})
                                {{selectedDateRange.desc}}</th>
                            <th style="width: 80px;" appTableSort="RequestedMovement" (sort)="sortByInfo($event)"
                                class="interactive hide-sm hide-xs">Requested ({{selectedUOMCode}})</th>
                            <th style="width: 70px;" appTableSort="Allocated" (sort)="sortByInfo($event)"
                                class="interactive hide-sm hide-xs">Allocated ({{selectedUOMCode}})</th>
                            <th style="width: 80px;" appTableSort="BalanceWeight" (sort)="sortByInfo($event)"
                                class="interactive hide-sm hide-xs">Balance Weight ({{selectedUOMCode}})</th>
                            <th style="width: 80px;" appTableSort="BalanceLoad" (sort)="sortByInfo($event)"
                                class="interactive hide-sm hide-xs">Balance Loads</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="!isLoading && bookingsheetdata.length == 0 && !hasSearched">
                            <td colspan="10" style="text-align: left; color:red">
                                <mat-label>No Default Depot found. Please select a Depot to search for.</mat-label>
                            </td>
                        </tr>
                        <tr *ngIf="!isLoading && bookingsheetdata.length == 0 && hasSearched">
                            <td colspan="10" style="text-align: left">
                                <mat-label>No records found!</mat-label>
                            </td>
                        </tr>
                        <ng-template ngFor let-item [ngForOf]="bookingsheetdata" *ngIf="bookingsheetdata.length > 0">
                            <tr style="height: 36px;">
                                <td hide-sm hide-xs style="text-align:left">{{item.groupName}}</td>
                                <td hide-sm hide-xs style="text-align:left">{{item.grade}}</td>
                                <td hide-sm hide-xs style="text-align:left">{{item.heapDesc}}</td>
                                <td hide-sm hide-xs>{{item.openingStock.toFixed(3)}}</td>

                                <td hide-sm hide-xs>
                                    <mat-form-field style="text-align:right;width:80px;padding-left: 20px;">
                                        <input type="number" matInput #adj (change)="applyAdjustment(adj.value, item)"
                                            value="{{item.adjustment}}">
                                    </mat-form-field>
                                </td>

                                <td hide-sm hide-xs>{{item.flow.toFixed(3)}}</td>
                                <td hide-sm hide-xs>{{item.requestedMovement.toFixed(3)}}</td>
                                <td hide-sm hide-xs>{{item.allocated.toFixed(3)}}
                                    <div class="icon-placeholder load-times-placeholder" style="text-align:center;"
                                        (click)="showSchedule(item)" *ngIf="item.allocated > 0">
                                        <span><i class="fa fa-clock-o" aria-hidden="true"></i></span>
                                    </div>
                                </td>
                                <td hide-sm hide-xs>{{item.balanceWeight.toFixed(3)}}</td>
                                <td hide-sm hide-xs [ngStyle]="{'color':item.balanceLoad > 0 ? 'black' : 'red' }">
                                    {{item.balanceLoad.toFixed(2)}}</td>
                            </tr>
                        </ng-template>
                    </tbody>
                </table>
            </div>
            <table style="width: 90%;" *ngIf="bookingsheetdata.length > 0">
                <tfoot>
                    <tr>
                        <td style="text-align: center;">
                            <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                                (pageChange)="pageChanged($event)" aria-label="Default pagination"></ngb-pagination>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</mat-card>
