import { Component, Inject, ViewChild } from '@angular/core';
import { ImportStatus } from '../gen/models';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { RouteToMarketService } from '../gen/services/route-to-market.service';

@Component({
    selector: 'app-route-to-market-dialog-import',
    templateUrl: './route-to-market-dialog-import.component.html',
    styleUrls: ['./route-to-market-dialog-import.component.scss']
})

export class RouteToMarketDialogImportComponent {
    importRouteToMarketForm: FormGroup;
    @ViewChild('fileInput', { static: true }) fileInput: any;
    uploadingStatus = false;
    isLoading = false;
    importStatus: ImportStatus;
    isSubmit = false;
    constructor(
        public dialogRef: MatDialogRef<RouteToMarketDialogImportComponent>,
        private routeToMarketService: RouteToMarketService,
        private toastr: ToastrService,
        @Inject(MAT_DIALOG_DATA) public data: ImportStatus
    ) { }

    cancel() {
        this.dialogRef.close();
    }

    ok() {
        if (this.fileInput.nativeElement.files.length > 0) {
            this.isLoading = true;
            this.isSubmit = true;
            const formData = new FormData();
            formData.append('upload', this.fileInput.nativeElement.files[0]);
            this.routeToMarketService.uploadRoutes(this.fileInput.nativeElement.files[0]).subscribe(r => {
                this.importStatus = r;
                if (!this.importStatus.importSuccess) {
                    this.toastr.error(`Something went wrong and the request could not be imported. ${this.importStatus.errMsg}. Please try again later.`, 'Import Route To Market Failed');
                } else {
                    this.success('Routes to market successfully imported', 'Import Route To Market');
                }
                this.isLoading = false;
                this.dialogRef.close(this.importStatus);
            }, err => {
                if (err.error != null && err.error.status === 200) {
                    this.success('Routes to market successfully imported', 'Import Route To Market');
                    this.isLoading = false;
                    this.dialogRef.close(this.importStatus);
                } else {
                    this.isLoading = false;
                    this.isSubmit = false;
                }
            });
        }
    }
    private success(message: string, title: string) {
        this.toastr.success(message, title);
    }
}
