import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Haulier, ContainerType, UomVm, AllocateModel, AppointmentVm, InitModel, AllocateRequestAdditionalCost, DepotVm, ApiPageResponse } from '../gen/models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { startWith, switchMap, debounceTime } from 'rxjs/operators';
import { HauliersService } from '../gen/services/hauliers.service';
import { ContainersService } from '../gen/services/containers.service';
import { UomsService } from '../gen/services/uoms.service';
import { AppointmentService } from '../gen/services/appointment.service';
import { DepotService } from '../gen/services/depot.service';
import { AllocateService } from '../gen/services/allocate.service';
import { Observable, forkJoin } from 'rxjs';
import { isNullOrUndefined } from '../tools';
import { ContractLineAllocationStatuses } from '../gen/enums/enums';
import { CustomValidators } from '../shared/custom-validators';
import { ToastrService } from 'ngx-toastr';
import { InitService } from '../services/init.service';
import * as moment from 'moment-timezone';
import { haulagePriceMatrixVM } from '../gen/models/haulagePriceMatrixVM';
import { ContractLineSearchComponent } from '../contract-line-search/contract-line-search.component';
import { ContractLineQuerySearchModel } from '../gen/models/ContractLineQuerySearchModel';
import { CloneEditAllocatedRequestDialogComponent } from '../clone-edit-allocated-request-dialog/clone-edit-allocated-request-dialog.component';
import { SpinnerComponent } from '../spinner/spinner.component';
import { CurrencySymbolService } from '../services/currency-symbol.service';
@Component({
    selector: 'app-edit-allocated-request-dialog',
    templateUrl: './edit-allocated-request-dialog.component.html',
    styleUrls: ['./edit-allocated-request-dialog.component.scss']
})

export class EditAllocatedRequestDialogComponent implements OnInit {

    allocationForm: FormGroup;
    filteredHauliers: Haulier[];
    containerTypes: ContainerType[];
    uoms: UomVm[];
    costs: AllocateRequestAdditionalCost[];
    appointments: AppointmentVm[];
    savedAppointmentId: number;
    additionalHidden = true;
    initModel: InitModel;
    haulageRates: haulagePriceMatrixVM[] = [];
    selectedHaulageMatrix: haulagePriceMatrixVM = null;
    haulagematrixDropdownOnly = false;
    customerAppointmentFromDateSet = false;
    customerAppointmentFromToSet = false;
    filteredDepots: ApiPageResponse<DepotVm>;
    editCustomerAppointmentDisabled = false;
    contractLineChanged = false;
    @ViewChild('firstComeFirstServeCheckBox', { static: true }) firstComeFirstServeCheckBox: ElementRef;
    @ViewChild('missedDropOffCheckbox', { static: true }) missedDropOffCheckbox: ElementRef;
    defaultHaulageUomId = 6; //LD - Loads
    toggleKpiTracking = false;
    remainingInstructionsText: string;
    instructionsMaxlength = 1500;
    @ViewChild('loadingEditAllocatedRequestDialogSpinner') loadingEditAllocatedRequestDialogSpinner: SpinnerComponent;
    loadingEditAllocatedRequestDialog = false;
    isSubmit = false;
    updateAndPrint=false;

    constructor(
        public dialogRef: MatDialogRef<EditAllocatedRequestDialogComponent>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: { allocate: AllocateModel, request? : number },
        private fb: FormBuilder,
        private hauliersService: HauliersService,
        private containerService: ContainersService,
        private uomsService: UomsService,
        private appointmentService: AppointmentService,
        private depotsService: DepotService,
        private allocateService: AllocateService,
        private toastr: ToastrService,
        private initService: InitService,
        private currencySymbolService: CurrencySymbolService) { }

    get haulier() {
        return this.allocationForm.get('haulier');
    }

    get maxCosts() {
        return this.initModel.config.maxAllocationCosts;
    }

    get depot() {
        return this.allocationForm.get('depot');
    }

    get spotRateText() {
        let spotRateText = 'Spot Rate';

        if (this.data?.allocate?.haulageCurrencyCode) {
            const currencySymbol = this.currencySymbolService.getCurrencySymbol(this.data.allocate.haulageCurrencyCode);
            if (currencySymbol) {
                spotRateText = `${spotRateText} (${currencySymbol})`;
            }
        }
        return spotRateText;
    }

    fixHaulageRate() {
        if (!this.selectedHaulageMatrix) {
            return;
        }
        if (!this.haulagematrixDropdownOnly) {
            this.allocationForm.controls.agreedHaulageRate.setValue(this.selectedHaulageMatrix.haulageRate);
            const h: Haulier = {
                name: this.selectedHaulageMatrix.haulierName,
                accountNumber: this.selectedHaulageMatrix.haulierAccountNo,
            };

            this.allocationForm.controls.haulier.setValue(h);
        }
        this.haulagematrixDropdownOnly = false;
    }

    updateHaualgeRate(hpm: any) {
        this.selectedHaulageMatrix = hpm.value;
        this.haulagematrixDropdownOnly = false;
        this.fixHaulageRate();
    }

    updateHaulageRateDropdown(event: any, fixRate = false) {
        if (this.haulageRates.length > 0) {
            this.selectedHaulageMatrix = this.haulageRates[0];
            this.haulagematrixDropdownOnly = fixRate;
        }
    }

    getAvailableHauliers() {
        if (!this.allocationForm.controls.pickupDateTime.value.isValid()) {
            this.toastr.info('Please select a valid pick up date', 'Haulage Price Matrix');
            return;
        }
        this.haulageRates = [];
        const model = new haulagePriceMatrixVM();
        model.partyDeliveryPointIds = [];
        model.containerTypeId = Number(this.allocationForm.controls.containerTypeId.value);
        model.fromLocationCode = this.data.allocate.depotNo;
        model.contractLineId = this.data.allocate.contractLineId;
        model.allocationFromDate = this.allocationForm.controls.pickupDateTime.value == '' ? null : this.allocationForm.controls.pickupDateTime.value;
        model.allocationToDate = this.allocationForm.controls.pickupToDateTime.value == '' ? null : this.allocationForm.controls.pickupToDateTime.value;
        model.partyDeliveryPointIds.push(Number(this.data.allocate.partyDeliveryPointId));
        model.partyAccountNo = this.data.allocate.partyAccountNumber;
        this.hauliersService.getHaulageRates(model).subscribe(r => {
            this.haulageRates = r;
            if (this.haulageRates.length > 0) {
                this.selectedHaulageMatrix = this.haulageRates[0];
            } else {
                this.toastr.info('No matching haulier found', 'Haulier Information');
            }
        });
    }

    ngOnInit() {

        this.costs = this.data.allocate.additionalCosts;
        console.log(this.data);
        const disable = this.data.allocate.contractLineAllocationStatus === ContractLineAllocationStatuses.Used;
        const allowChangeDepot = 
        (this.data.allocate.contractLineAllocationStatus === ContractLineAllocationStatuses.Open 
            || this.data.allocate.contractLineAllocationStatus === ContractLineAllocationStatuses.Draft)
            && isNullOrUndefined(this.data.request) ;
        this.allocationForm = this.fb.group({
            depot: [{ value: '', disabled: !allowChangeDepot }, Validators.required],
            haulier: [{ value: '', disabled: disable }],
            containerTypeId: [{ value: '', disabled: disable }, Validators.required],
            containerNumber: [{ value: '', disabled: disable }],
            pickupDateTime: [{ value: '', disabled: disable }, Validators.required],
            pickupToDateTime: [{ value: '', disabled: disable }],
            appointmentId: [{ value: '' }],
            customerAptDateTime: [{ value: '' }],
            customerAptToDateTime: [{ value: '' }],
            appointmentComment: [{ value: '' }],
            customerAptRef: [{ value: '' }],
            agreedHaulageRate: [{ value: '', disabled: disable }],
            agreedHaulageRateUomId: [{ value: '', disabled: disable }],
            instructions: [{ value: '', disabled: disable }],
            additionalCosts: [{ value: '', disabled: disable }],
            firstComeFirstServe: [{ value: '', disabled: disable }],
            awaitingDispatch: [{ value: '', disabled: disable }],
            kpiTracking: [{ value: '', disable: disable }],
            approvedToSend: [{ value: false, disabled: disable }],
            isBooked: [{ value: false, disabled: disable }]
        }, {
            validators: [
                CustomValidators.dateLessThan('pickupDateTime', 'pickupToDateTime'),
                CustomValidators.dateLessThan('customerAptDateTime', 'customerAptToDateTime'),
                CustomValidators.requiredWhenSet('agreedHaulageRateUomId', 'agreedHaulageRate', 'Spot Rate UOM', 'Spot Rate')
            ]
        });

        this.allocationForm.controls['instructions'].valueChanges.subscribe((newVal?: string) => {
            this.remainingInstructionsText = `${newVal?.length}/${this.instructionsMaxlength}`;
        });

        this.haulier.valueChanges
            .pipe(
                debounceTime(500),
                startWith(null),
                switchMap(v => this.filterHauliers(v)))
            .subscribe(r => this.filteredHauliers = r);


        this.initService.initData$.subscribe(r =>
            this.initModel = r
        );
        const container$ = this.containerService.get();

        const uoms$ = this.uomsService.getAll();

        this.appointmentService
            .getAvailableAppointmentsForParty(this.data.allocate.partyAccountNumber, this.data.allocate.contractRef, this.data.allocate.appointmentId)
            .subscribe(a => this.appointments = a);

        forkJoin(container$, uoms$).subscribe(r => {
            this.containerTypes = r[0];
            this.uoms = r[1];
            if (!isNullOrUndefined(this.data)) {
                const m = this.data;
                const c = this.allocationForm.controls;
                const haulageRateUom = !isNullOrUndefined(m.allocate.agreedHaulageRateUomId) ? m.allocate.agreedHaulageRateUomId : this.defaultHaulageUomId;
                this.depot.patchValue({ depotNumber: m.allocate.depotNo });
                this.haulier.patchValue({ accountNumber: m.allocate.haulierAccountNumber, name: m.allocate.haulierName });
                c.containerTypeId.patchValue(m.allocate.containerTypeId);
                c.containerNumber.patchValue(m.allocate.containerNumber);
                c.pickupDateTime.patchValue(m.allocate.pickupDateTime);
                c.pickupToDateTime.patchValue(m.allocate.pickupToDateTime);
                c.agreedHaulageRate.patchValue(m.allocate.agreedHaulageRate);
                c.agreedHaulageRateUomId.patchValue(haulageRateUom);
                c.instructions.patchValue(m.allocate.instructions);
                c.additionalCosts.patchValue(m.allocate.additionalCosts);
                c.kpiTracking.patchValue(m.allocate.kpiTracking);
                c.firstComeFirstServe.patchValue(m.allocate.firstComeFirstServe);

                this.savedAppointmentId = m.allocate.appointmentId;
                c.customerAptDateTime.patchValue(m.allocate.customerAptDateTime);
                c.customerAptToDateTime.patchValue(m.allocate.customerAptToDateTime);
                c.appointmentComment.patchValue(m.allocate.appointmentComment);
                c.customerAptRef.patchValue(m.allocate.customerAptRef);
                c.approvedToSend.patchValue(m.allocate.approvedToSend ?? true);
                c.isBooked.patchValue(m.allocate.isBooked ?? false);
            }
        });

        this.depot.valueChanges
            .pipe(
                debounceTime(500),
                startWith(null),
                switchMap(v => this.filterDepots(v)))
            .subscribe(d => this.filteredDepots = d);

        this.updateAndPrint=false;
    }

    filterHauliers(val: string): Observable<Haulier[]> {
        return this.hauliersService.get(val);
    }

    displayHaulier(haulier?: Haulier): string {
        return isNullOrUndefined(haulier.accountNumber) || isNullOrUndefined(haulier.name)
            ? '' : haulier.accountNumber + ' ' + haulier.name;
    }

    showHaulierClear(haulierInput: HTMLInputElement): boolean {
        return !isNullOrUndefined(haulierInput.value) && haulierInput.value !== '';
    }

    haulierClear() {
        this.haulier.setValue({ accountNumber: null, name: null });
    }

    cancel() {
        this.dialogRef.close();
    }

    customerAppointmentFromDateChangedEvent(event: any) {
        this.customerAppointmentFromDateSet = true;
    }

    customerAppointmentToDateChangedEvent(event: any) {
        this.customerAppointmentFromToSet = true;
    }

    firstComeServeValueChange(event: any, manualOverride: boolean | undefined = false) {
        let checked = false;
        if (event && !isNullOrUndefined(event.checked))
            checked = event.checked;

        this.data.allocate.firstComeFirstServe = checked;

        if (manualOverride) {
            this.firstComeFirstServeCheckBox.nativeElement.checked = checked;
        }
    }

    update(formValue: AllocateModel) {
        if (this.data.allocate.contractLineAllocationStatus === ContractLineAllocationStatuses.Used) { return; }

        formValue.partyAccountNumber = this.data.allocate.partyAccountNumber;
        formValue.contractHeaderId = this.data.allocate.contractHeaderId;
        formValue.contractRef = this.data.allocate.contractRef;
        formValue.haulierAccountNumber = formValue['haulier'].accountNumber;
        formValue.firstComeFirstServe = this.data.allocate.firstComeFirstServe;
        formValue.contractLineId = this.data.allocate.contractLineId;
        formValue.missedDropOff = this.data.allocate.missedDropOff;
        let depot = formValue['depot'].depotNumber;
        if (isNullOrUndefined(depot)) //user typed in but didn't click auto-complete
            depot = formValue['depot'];
        formValue.depotNo = depot;

        for (const cost of formValue.additionalCosts) {
            if (cost.partyAccountNo != null && cost.partyAccountNo['partyAccountNo'] != undefined) {
                cost.partyAccountNo = cost.partyAccountNo['partyAccountNo'];
            }
        }

        if (!this.allocationForm.valid) {
            if (!isNullOrUndefined(this.allocationForm.errors.dates)) {
                this.toastr.error(this.allocationForm.errors.dates, 'Form Invalid');
            } else if (!isNullOrUndefined(this.allocationForm.errors.unset)) {
                this.toastr.error(this.allocationForm.errors.unset, 'Form Invalid');
            } else {
                this.toastr.error('Form Invalid');
            }
        }
        if (this.allocationForm.valid) { //do the post here instead - in case of error we do not close the dialog
            this.loadingEditAllocatedRequestDialog = true;
            this.isSubmit = true;
            this.allocateService.updateAllocation(this.data.allocate.contractLineAllocationId, formValue)
                .subscribe(a => {
                    this.toastr.success('Allocation successfully edited', 'Edit Request');
                    this.closeDialog(formValue);
                    if(this.updateAndPrint){
                        this.printAppointments();
                    }

                },
                    err => {
                        if (err.status === 200) {
                            this.toastr.success('Allocation successfully edited', 'Edit Request');
                            this.closeDialog(formValue);
                        }
                        else {
                            this.toastr.error('Something went wrong and the request could not be allocated. Please try again later.', 'Edit Request');
                            this.loadingEditAllocatedRequestDialog = false;
                            this.isSubmit = false;
                        }
                    });
        }
    }

    printAppointments() {
        const allocationId=this.data.allocate.contractLineAllocationId;
        if(allocationId)
        {
            window.open('print-appointment/' + allocationId);
        } 
    }

    updateAndPrintAllocations(formValue: AllocateModel) {
        this.updateAndPrint = true;
        this.update(formValue);
    }

    private closeDialog(formValue: AllocateModel) {
        setTimeout(() => {
            this.loadingEditAllocatedRequestDialog = false;
            this.dialogRef.close(formValue);
        }, 1500);
    }

    selectAppointment(value: any) {
        this.allocationForm.controls.customerAptRef.reset();
        this.allocationForm.controls.customerAptDateTime.reset();
        this.allocationForm.controls.customerAptToDateTime.reset();
        this.allocationForm.controls.appointmentComment.reset();

        if (value > 0) {
            const appointmentDetails = this.appointments.find(a => a.appointmentId === value);
            const startDate = moment.utc(appointmentDetails.startDate);
            const endDate = moment.utc(appointmentDetails.endDate);
            this.allocationForm.controls.customerAptDateTime.setValue(startDate);
            this.allocationForm.controls.customerAptToDateTime.setValue(endDate);
            this.allocationForm.controls.customerAptRef.setValue(appointmentDetails.deliveryNumber);
            this.allocationForm.controls.appointmentComment.setValue(appointmentDetails.comments);
        } else {
            this.allocationForm.controls.customerAptDateTime.setValue('');
            this.allocationForm.controls.customerAptToDateTime.setValue('');
            this.allocationForm.controls.customerAptRef.setValue('');
            this.allocationForm.controls.appointmentComment.setValue('');
            this.customerAppointmentFromDateSet = false;
            this.customerAppointmentFromToSet = false;
        }
    }

    toggleAppointmentControls(enable: boolean) {
        this.editCustomerAppointmentDisabled = !enable;
        if (enable) {
            this.allocationForm.controls.customerAptRef.enable();
            this.allocationForm.controls.appointmentComment.enable();
        } else {
            this.allocationForm.controls.customerAptRef.disable();
            this.allocationForm.controls.appointmentComment.disable();
        }
    }

    displayDepot(depot?: DepotVm): string | undefined {
        let depotString = undefined;
        if (depot && depot.depotNumber && depot.depotNumber !== '' && depot.depotName && depot.depotName !== '')
            depotString = depot.depotNumber + ' - ' + depot.depotName;
        else if (depot && depot.depotNumber && depot.depotNumber !== '' && (isNullOrUndefined(depot.depotName) || depot.depotName === ''))
            depotString = depot.depotNumber;
        return depotString;
    }

    filterDepots(val: string) {
        return this.depotsService.searchDepots(val);
    }

    openContractSearchDialog() {
        const search: ContractLineQuerySearchModel = {
            grade: null,
            customerContractRef: this.data.allocate.contractRef,
            partyAccountNo: this.data.allocate.partyAccountNumber,
            territory: null
        };
        const dialogRef = this.dialog.open(ContractLineSearchComponent, {
            minWidth: '240px',
            maxWidth: '85%',
            maxHeight: '80%',
            data: { search: search }
        });
        dialogRef.afterClosed().subscribe(cl => {
            if (cl && cl.contractLineId && cl.customerContractRef && cl.partyAccountNo && cl.partyName) {
                this.data.allocate.contractLineId = cl.contractLineId;
                this.data.allocate.contractRef = cl.customerContractRef;
                this.data.allocate.partyAccountNumber = cl.partyAccountNo;
                this.data.allocate.partyName = cl.partyName;
                this.contractLineChanged = true;
            }
        });
    }

    clone() {
        const dialogRef = this.dialog.open(CloneEditAllocatedRequestDialogComponent, {
            minWidth: '240px',
            maxWidth: '80%',
            maxHeight: '80%',
            data: { allocate: this.data.allocate }
        });
        dialogRef.afterClosed().subscribe(r => { this.dialogRef.close(); });
    }

    pickupDateChange(event) {
        this.data.allocate.missedDropOff = false;
    }

    missedDropOffChange(event: any) {
        let checked = false;
        if (event && !isNullOrUndefined(event.checked)) {
            checked = event.checked;
        }
        this.data.allocate.missedDropOff = checked;
    }

    ngOnDestroy(): void {
        if (this.loadingEditAllocatedRequestDialogSpinner !== undefined)
            this.loadingEditAllocatedRequestDialogSpinner.overlayRef.dispose();
    }
}
