<ng-container [formGroup]="formGroup">
  <div class="kpi-container" *ngIf="enableCollapsing" [hidden]="!showCollapse" >
    <div class="kpi-fields" (click)="toggleVisibility()">
      KPI Tracking
      <i [ngClass]="{'fa-chevron-up': !kpiHidden, 'fa-chevron-down': kpiHidden}" class="fa fa-chevron-up"
        *ngIf="enableCollapsing"></i>
    </div>
    <div *ngIf="!enableCollapsing" (click)="toggleVisibility()" class="section-header">
      KPI Tracking
    </div>
    <div [hidden]="(kpiHidden && showCollapse) && enableCollapsing">
      <div class="kpi-row-container" layout="row">
        <mat-form-field>
          <mat-select (selectionChange)="doSomething($event)" formControlName="kpiType" [errorStateMatcher]="errorMatcher" placeholder="KPI Type">
            <mat-option *ngFor="let costCode of kpiTypes" [value]="costCode.key">
              {{costCode.desc}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <input matInput type="text" aria-label="Kpi cost" placeholder="KPI Cost" autocomplete="off"
            formControlName="kpiCost" [errorStateMatcher]="errorMatcher" [value]="kpi?.kpiCost">
        </mat-form-field>
      </div>

    </div>
  </div>
</ng-container>