import * as moment from 'moment';
import { Component, OnInit, ViewChild, ChangeDetectorRef, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

@Component({
    selector: 'app-date-range',
    templateUrl: './date-range.component.html',
    styleUrls: ['./date-range.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateRangeComponent),
            multi: true
        }]
})
export class DateRangeComponent implements OnInit, ControlValueAccessor {
    @Input() label = 'Allocation Date';
    showDatePickers = false;
    @ViewChild('select', { static: true }) select: MatSelect;
    @ViewChild('dateFrom', { static: true }) dateFrom: any;
    @ViewChild('dateTo', { static: true }) dateTo: any;
    fromD: string;
    toD: string;
    @Input('fromDate') fromDate: string;
    @Input('toDate') toDate: string;
    setDateToTime = true;
    dateRangeStr = 'Pick Date Range';
    @Output() onDatePicked = new EventEmitter<string>();
    @Output() onDateApply = new EventEmitter<string>();

    @Input('dateType') dateType: string;
    val = 'Quarter';
    @Input('dateValue') dateValue: string;
    constructor(private ref: ChangeDetectorRef, private adapter: DateAdapter<any>) {
        this.adapter.setLocale(moment.locale());
    }

    ngOnInit() {
        if(this.dateType !== null) {
            if (this.dateType !== 'Range') {
                this.dateRangeChange({value:this.dateType});
            } else {
                this.dateFrom.nativeElement.value = this.fromD = this.fromDate;
                this.dateTo.nativeElement.value = this.toD = this.toDate;
                this.propagateChange({
                    dateFrom: this.formatFromDate(this.dateFrom.nativeElement.value),
                    dateTo: this.formatToDate(this.dateTo.nativeElement.value)
                });
                this.dateRangeStr = this.formatDateRange(this.fromDate, this.toDate, 'L');
                this.showDatePickers = false;
                this.ref.detectChanges();
            }
            this.val = this.dateType;
        } else {
            this.val = this.dateValue !== '' ? this.dateValue : 'Quarter';
            this.propagateChange({
                dateFrom: this.firstOfLastQuarter
            });
        }
    }

    writeValue(obj: any): void {
        // throw new Error("Method not implemented.");
    }

    propagateChange = (_: any) => { };

    validatorChange = () => { };

    registerOnChange(fn) {
        this.propagateChange = fn;
    }
    registerOnTouched() { }

    setDisabledState?(isDisabled: boolean): void {
        throw new Error('Method not implemented.');
    }

    get pickersStyle() {
        const rect = this.select._triggerRect;
        return {
            top: `${rect?.top - 30}px` ,
            left: `${rect?.left}px`,
            position: 'absolute',
            'z-index': '10',
            'background-color': 'white',
            'box-shadow': '3px 4px 4px #888888',
            border: 'solid 1px #888888',
            padding: '2px'
        };
    }

    dateRangeChange(event: any) {
        if (event.value === 'Range') {
            // show dates
            this.showDatePickers = true;
        } else {
            this.showDatePickers = false;
            this.dateRangeStr = 'Pick Date Range';
            // emit value
            switch (event.value) {
            case 'Month':
                this.propagateChange({
                    dateFrom: this.firstOfLastMonth
                });
                break;
            case 'Quarter':
                this.propagateChange({
                    dateFrom: this.firstOfLastQuarter
                });
                break;
            case 'Half':
                this.propagateChange({
                    dateFrom: this.firstOfLastHalf
                });
                break;
            case 'Year':
                this.propagateChange({
                    dateFrom: this.firstOfLastYear
                });
                break;
            }
            this.ref.detectChanges();
        }
        this.onDatePicked.emit(event.value);
    }

    applyRange() {
        const dateFrom = this.formatFromDate(this.dateFrom.nativeElement.value);
        const dateTo = this.formatToDate(this.dateTo.nativeElement.value);
        this.propagateChange({
            dateFrom: dateFrom,
            dateTo: dateTo
        });
        this.dateRangeStr = this.formatDateRange(this.dateFrom.nativeElement.value, this.dateTo.nativeElement.value, 'L');
        this.showDatePickers = false;
        this.ref.detectChanges();
        this.onDateApply.emit(this.formatDateRange(this.dateFrom.nativeElement.value, this.dateTo.nativeElement.value));
    }

    get firstOfLastMonth(): Date {
        const d = new Date();
        return new Date(d.getFullYear(), d.getMonth() - 1, 1);
    }
    get firstOfLastQuarter(): Date {
        const d = new Date();
        return new Date(d.getFullYear(), d.getMonth() - 3, 1);
    }
    get firstOfLastHalf(): Date {
        const d = new Date();
        return new Date(d.getFullYear(), d.getMonth() - 6, 1);
    }
    get firstOfLastYear(): Date {
        const d = new Date();
        return new Date(d.getFullYear() - 1, d.getMonth(), 1);
    }

    formatFromDate(date: string): Date {
        return this.momentFromString(date).toDate();
    }

    formatDateRange(fromDate: string, toDate: string, format = ''): string {
        const fromMonent = this.momentFromString(fromDate);
        const toMoment = this.momentFromString(toDate);
        const dateRangeParts = new Array<string>();
        if (fromMonent.isValid()) {
            dateRangeParts.push(fromMonent.format(format));
        }
        dateRangeParts.push(' - ');
        if (toMoment.isValid()) {
            dateRangeParts.push(toMoment.format(format));
        }
        return dateRangeParts.join('');
    }

    formatToDate(date: string): Date {
        const m = this.momentFromString(date);
        if (m.isValid()) {
            if(this.setDateToTime) {
                m.set({
                    'hour': 23,
                    'minute': 59,
                    'second': 59,
                    'millisecond': 999
                });
            }
        }
        return m.toDate();
    }

    momentFromString(date: string): moment.Moment {
        if (!date) {
            return moment.invalid();
        }
        let m = moment(date, 'L');
        if (!m.isValid()) {
            m = moment(date);
        }
        return m;
    }
}
