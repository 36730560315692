import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { REST_CONFIG_TOKEN, RestConfigService } from '../rest-config-service';
import { Observable } from 'rxjs';
import {
    RequestSearchFilter,
    GroupedRequestVm,
} from '../models';

@Injectable()
export class AllocateInventoryService {

    constructor(
        private _http: HttpClient,
        @Inject(REST_CONFIG_TOKEN) private _configService: RestConfigService
    ) {
    }

    public getRequests: (
        filter?: RequestSearchFilter
    ) => Observable<GroupedRequestVm[]>
        = (
            filter?: RequestSearchFilter
        ): Observable<GroupedRequestVm[]> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            const uri = '/api/InventoryAllocate/requests';

            return this._http
                .post<GroupedRequestVm[]>(this._configService.apiBaseUrl() + uri, JSON.stringify(filter),
                    { headers: headers, params: queryParameters });
        };

}

