import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { InitModel, HeapVm, ApiPageResponse, DepotVm, UomVm } from '../gen/models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InitService } from '../services/init.service';
import { startWith, switchMap, debounceTime } from 'rxjs/operators';
import { DepotService } from '../gen/services/depot.service';
import { isNullOrUndefined } from '../tools';
import { UomsService } from '../gen/services/uoms.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { DateAdapter } from '@angular/material/core';
import { SpinnerComponent } from '../spinner/spinner.component';
import { GradeService } from '../gen/services/grade.service';
import { AllocationService } from '../gen/services/allocation.service';
import { of, Subject } from 'rxjs';
import { HeapsService } from '../gen/services/heaps.service';
import { RegionService } from '../gen/services/region.service';
import { CustomValidators } from '../shared/custom-validators';
import { EditInventoryReservationVm } from '../gen/models/EditInventoryReservationVm';

@Component({
    selector: 'app-edit-inventory-reservation-dialog',
    templateUrl: './edit-inventory-reservation-dialog.component.html',
    styleUrls: ['./edit-inventory-reservation-dialog.component.scss']
})
export class EditInventoryReservationDialogComponent implements OnInit {
    editInventoryReservationForm: FormGroup;
    pageModel: InitModel;
    uoms: UomVm[];
    @ViewChild('loadingEditRequestDialogSpinner') loadingEditRequestDialogSpinner: SpinnerComponent;
    loadingEditRequestDialog = false;
    isSubmit = false;
    heapModelChanged: Subject<string> = new Subject<string>();
    regionModelChanged: Subject<string> = new Subject<string>();
    regionindex: number;
    originalHeapNo: number;
    originalDepot: string;
    originalRegion: string;
    disableForm: boolean;
    filteredGrade: string[];
    constructor(
        public dialogRef: MatDialogRef<EditInventoryReservationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private initService: InitService,
        private depotsService: DepotService,
        private uomsService: UomsService,
        private toastr: ToastrService,
        private _adapter: DateAdapter<any>,
        private allocationService: AllocationService,
        public gradeService: GradeService,
        private heapService: HeapsService,
        public regionService: RegionService) {
    }
    statuses = [];
    filteredRegion: string[];
    filteredDepots: ApiPageResponse<DepotVm>;
    filteredHeaps: ApiPageResponse<HeapVm>;
    disableAnimation = true;

    get depot() { return this.editInventoryReservationForm.get('depot'); }
    get readyDate() { return this.editInventoryReservationForm.get('readyDate'); }
    get expiryDate() { return this.editInventoryReservationForm.get('expiryDate'); }
    get containerSize() { return this.editInventoryReservationForm.get('containerSizeId'); }
    get grade() { return this.editInventoryReservationForm.get('grade'); }
    get region() { return this.editInventoryReservationForm.get('region'); }
    get uomId() { return this.editInventoryReservationForm.get('uomId'); }
    get totalLoads() { return this.editInventoryReservationForm.get('totalLoads'); }
    get packaging() { return this.editInventoryReservationForm.get('packaging'); }
    get heapNo() { return this.editInventoryReservationForm.get('heapNo'); }
    get heap() { return this.editInventoryReservationForm.get('heap'); }
    get weight() { return this.editInventoryReservationForm.get('weight'); }
    get materialDescription() { return this.editInventoryReservationForm.get('materialDescription'); }
    get comments() { return this.editInventoryReservationForm.get('comments'); }
    get remainingWeight() { return this.editInventoryReservationForm.get('remainingWeight'); }
    get remainingLoads() { return this.editInventoryReservationForm.get('remainingLoads'); }
    get status() { return this.editInventoryReservationForm.get('status'); }
    onNoClick(): void {
        this.dialogRef.close();
    }

    ngOnInit() {
        this._adapter.setLocale(window.navigator.language);
        this.disableForm = !(this.data?.instructions?.filter(x => x.id != -1).length <= 0);
        this.editInventoryReservationForm = this.fb.group({
            depot: [{ value: '', disabled: this.disableForm }],
            readyDate: [{ value: '', disabled: this.disableForm }, Validators.required],
            expiryDate: [{ value: '', disabled: this.disableForm }],
            grade: [{ value: '', disabled: this.disableForm }],
            region: [{ value: '', disabled: this.disableForm }],
            uomId: [{ value: '', disabled: this.disableForm }, Validators.required],
            heapNo: [{ value: '', disabled: this.disableForm }],
            heap: [{ value: '', disabled: this.disableForm }],
            weight: [{ value: '', disabled: this.disableForm }, [Validators.pattern('^[0-9]{1,7}(\.[0-9]+)?$'), Validators.max(this.data.weight),Validators.required]],
            remainingWeight: ['', [Validators.pattern('^[0-9]{1,7}(\.[0-9]+)?$')]],
            remainingLoads: ['', [Validators.required, Validators.min(0)]],
            totalLoads: [{ value: '', disabled: this.disableForm }, Validators.required],
            containerSizeId: [''],
            packaging: [''],
            comments: [''],
            status: ['', Validators.required],
            materialDescription: [{ value: '', disabled: this.disableForm }],
        },
        {
            validators:
            [CustomValidators.dateLessThan('readyDate', 'expiryDate'), 
                CustomValidators.requiredWhenSet('uomId', 'remainingWeight')]
        });

        this.initService.initData$.subscribe(r => {
            this.pageModel = r;
            this.setData();
        });

        this.editInventoryReservationForm.controls.grade.valueChanges
            .pipe(
                debounceTime(500),
                startWith(null),
                switchMap(v =>
                    this.filterGrade(v)
                )
            )
            .subscribe((r: string[]) => {
                if(!isNullOrUndefined(r)){
                    this.filteredGrade = r;
                }
            });

        
        this.uomsService.getAll().subscribe(r => {
            this.uoms = r;
            this.uomId.patchValue(this.data.uomId);
        });

        this.depot.valueChanges
            .pipe(
                debounceTime(500),
                startWith(null),
                switchMap(v => this.filterDepots(v, this.region.value)))
            .subscribe(r => this.filteredDepots = r);
        this.depot.valueChanges
            .subscribe((h: DepotVm) => {
                if (!isNullOrUndefined(h) && h.depotNumber !== this.originalDepot) {
                    this.heapNo.patchValue(null);
                }
            });
        this.region.valueChanges
            .subscribe((h: string) => {
                if (!isNullOrUndefined(h) && h !== this.originalRegion && this.depot.pristine) {
                    this.depot.patchValue('');
                }
            });
        this.heapNo.valueChanges
            .subscribe((h: HeapVm) => {
                if (!isNullOrUndefined(h)) {
                    this.grade.patchValue(h.gradeCode); 
                }
            });
        this.heapNo.valueChanges
            .pipe(
                debounceTime(500),
                startWith(null),
                switchMap(v =>
                    this.filterHeaps(v)
                )
            )
            .subscribe((r: ApiPageResponse<HeapVm>) => {
                if (!isNullOrUndefined(r)) {
                    this.filteredHeaps = r;
                }
            });

        this.heapNo.valueChanges
            .subscribe((h: HeapVm) => {
                if (!isNullOrUndefined(h) && h.heapNo !== this.originalHeapNo && this.materialDescription.pristine) {
                    this.materialDescription.patchValue(h.heapDesc);
                }
            });
    }

    ngAfterViewInit(): void {
        // having an expansion panel inside dialog the panel animates on loading, workaround taken from https://github.com/angular/components/issues/13870
        // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
        setTimeout(() => this.disableAnimation = false);
    }

    getUom(): string {
        return 'Tonne';
    }
    setData() {
        this.readyDate.patchValue(this.data.readyDate);
        this.expiryDate.patchValue(this.data.expiryDate);
        this.grade.patchValue(this.data.grade);
        this.region.patchValue(this.data.region);
        this.containerSize.patchValue(this.data.containerSizeId);
        this.packaging.patchValue(this.data.packageOptionId);
        this.materialDescription.patchValue(this.data.gradeDesc);
        this.remainingWeight.patchValue(this.data.remainingWeight);
        this.weight.patchValue(this.data.weight);
        this.remainingLoads.patchValue(this.data.remainingLoads);
        this.totalLoads.patchValue(this.data.totalLoads);
        this.heap.patchValue(this.data.heap);
        if (this.data.heapNo != -1) {
            this.heapNo.patchValue(this.data.heapNo);
        }
        this.status.patchValue(this.data.statusId);
        if (!isNullOrUndefined(this.data.depot)) {
            this.originalDepot = this.data.depot;
            this.depotsService.searchDepotsByRegion(this.data.depot, this.data.region).subscribe(r => {
                const depot = r.items[0];
                this.depot.setValue({ depotNumber: depot.depotNumber, depotName: depot.depotName });
            });
        }
        if (this.data.region) {
            this.originalRegion = this.data.region;
        }
        if (this.data.heapNo) { //todo remove this by stopping to pass the heapNo. Just pass a HeapVm as part of this.data
            this.originalHeapNo = this.data.heapNo;
            this.heapService.getHeap(this.data.heapNo).subscribe(h => {
                if (!isNullOrUndefined(h)) {
                    this.heapNo.setValue(h);
                }
            });
        }
    }

    cancel() {
        this.dialogRef.close();
    }

    ok() {

        if (this.editInventoryReservationForm.valid) {
            const formValue = this.editInventoryReservationForm.value;
            const id = this.data.id ? this.data.id : this.data.requestId;
            const packagingId = !isNullOrUndefined(formValue.packaging) ? formValue.packaging : null;
            const containerType = !isNullOrUndefined(formValue.containerSizeId) ? formValue.containerSizeId : null;
            const model: EditInventoryReservationVm = {
                Id: id,
                ReadyAt: new Date(moment(formValue.readyDate).format('YYYY-MM-DD')),
                ExpiryDate: !formValue.expiryDate ? null : new Date(moment(formValue.expiryDate).format('YYYY-MM-DD')),
                ContainerSizeId: containerType,
                PackageOptionId: packagingId,
                UomId: formValue.uomId,
                TotalLoads: formValue.totalLoads,
                Comments: formValue.comments,
                Weight: formValue.weight,
                status: { id: formValue.status ?? 7, name: null, isActive: true },
                RemainingWeight: formValue.remainingWeight,
                RemainingLoads: formValue.remainingLoads,
                MaterialDescription: formValue.materialDescription,
                depot: formValue.depot,
                heap: formValue.heapNo,
                grade: formValue.grade,
                region: formValue.region
            };
            this.isSubmit = true;
            this.loadingEditRequestDialog = true;
            if(model?.depot?.depotName && model.depot.depotName != 'NODEPOT' && !model.heap){
                this.toastr.error('Heap is required', 'Form Invalid');
                this.loadingEditRequestDialog = false;
                this.isSubmit = false;
                return;
            }
            if(model?.heap?.gradeCode && model?.heap?.gradeCode != model.grade){
                this.toastr.error('Invalid Grade, it should be ' + model?.heap?.gradeCode, 'Form Invalid');
                this.loadingEditRequestDialog = false;
                this.isSubmit = false;
                return;
            }
            this.allocationService.updateReservation(model, id).subscribe(() => {
                this.success('Inventory Reservation successfully edited', 'Edit Inventory Reservation');
                this.closeDialog();
            }, err => {
                if (err.error != null && err.error.status === 200) {
                    this.success('Inventory Reservation successfully edited', 'Edit Inventory Reservation');
                    this.closeDialog();
                } else {
                    this.toastr.error('Something went wrong and the Inventory Reservation could not be edited. Please try again later.', 'Edit Failed');
                    this.isSubmit = false;
                }
                this.loadingEditRequestDialog = false;
            });
        } else {
            if (!isNullOrUndefined(this.editInventoryReservationForm.errors)) {
                if(!isNullOrUndefined(this.editInventoryReservationForm.errors.unset)){
                    this.toastr.error(this.editInventoryReservationForm.errors.unset, 'Form Invalid');
                }
                if(!isNullOrUndefined(this.editInventoryReservationForm.errors.dates)){
                    this.toastr.error(this.editInventoryReservationForm.errors.dates, 'Form Invalid');
                }
            }
        }
    }

    ngOnDestroy(): void {
        if (this.loadingEditRequestDialogSpinner !== undefined)
            this.loadingEditRequestDialogSpinner.overlayRef.dispose();
    }

    private closeDialog() {
        setTimeout(() => {
            this.loadingEditRequestDialog = false;
            this.dialogRef.close();
        }, 1500);
    }

    private success(message: string, title: string) {
        this.toastr.success(message, title);
    }


    filterDepots(val: string, region: string) {
        this.filteredHeaps = null;
        return this.depotsService.searchDepotsByRegion(val, region);
    }
    displayDepot(depot?: DepotVm): string | undefined {
        return depot ? depot.depotNumber + ' ' + depot.depotName : undefined;
    }

    displayHeap(heap?: HeapVm): string | undefined {
        return heap ? heap.heapShortName + ' ' + heap.heapDesc : undefined;
    }


    filterHeaps(searchTerm: string) {
        if (!isNullOrUndefined(this.depot.value)) {
            const formDepot = this.depot.value as DepotVm;
            if (!isNullOrUndefined(formDepot) && !isNullOrUndefined(formDepot.depotNumber) && formDepot.depotNumber !== '' && formDepot.depotNumber !== 'NODEPOT' && !isNullOrUndefined(searchTerm) && typeof (searchTerm) === 'string') {
                return this.heapService.search(formDepot.depotNumber, searchTerm);
            }
        }
        return of();
    }

    heapValueChange(val: string) {
        val = val.toUpperCase();
        this.heapModelChanged.next(val);
        this.heapNo.valueChanges
            .pipe(
                debounceTime(500),
                startWith(null),
                switchMap(v =>
                    this.filterHeaps(v)
                )
            )
            .subscribe((r: ApiPageResponse<HeapVm>) => {
                if (!isNullOrUndefined(r)) {
                    this.filteredHeaps = r;
                }
            });
    }
    regionValueChange(val: string) {
        val = val.toUpperCase();
        this.regionModelChanged.next(val);
        this.region.valueChanges
            .pipe(
                debounceTime(500),
                startWith(null),
                switchMap(v => this.filterRegion(this.region.value)))
            .subscribe(d => this.filteredRegion = d);
    }

    filterRegion(val: string) {
        return this.regionService.searchRegions(val);
    }
    filterGrade(val: string) {
        return this.gradeService.getGrade(val);
    }

    displayGrade(grade?: any): string | undefined {
        return grade ? grade : undefined;
    }
}
