<div [attr.aria-label]="data.aria">
  <h1 mat-dialog-title>{{data.title}}</h1>
  <div mat-dialog-content>
    <p>{{data.body}}</p>
    <mat-form-field>
      <input type="text" matInput #input [placeholder]="data.name">
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="buttons-row">
    <button *ngIf="data.cancel" mat-button (click)="onNoClick()">{{data.cancel}}</button>
    <button *ngIf="data.ok" mat-button [mat-dialog-close]="input.value" cdkFocusInitial>{{data.ok}}</button>
  </div>
</div>