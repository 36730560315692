import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ConfigService } from './config-service';

@Injectable({
	providedIn: 'root'
})
export class AppInsightsService {
	instance: ApplicationInsights;
	constructor(private configService: ConfigService) {
		setTimeout(() => {
			console.log(this.configService.config.instrumentationKey);
			this.instance = new ApplicationInsights({
				config: {
					instrumentationKey: this.configService.config.instrumentationKey,
					enableCorsCorrelation: true,
					enableAutoRouteTracking: true
				}
			});
			this.instance.loadAppInsights();
			this.instance.trackPageView();
		}, 1000);
	}

	logPageView(name?: string, url?: string) { // option to call manually
		this.instance.trackPageView({
			name: name,
			uri: url
		});
	}

	logEvent(name: string, properties?: { [key: string]: any }) {
		this.instance.trackEvent({ name: name }, properties);
	}

	logMetric(name: string, average: number, properties?: { [key: string]: any }) {
		this.instance.trackMetric({ name: name, average: average }, properties);
	}

	logException(exception: Error, severityLevel?: number) {
		this.instance.trackException({ exception: exception, severityLevel: severityLevel });
	}

	logTrace(message: string, properties?: { [key: string]: any }) {
		this.instance.trackTrace({ message: message }, properties);
	}
} 