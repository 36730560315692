<div class="newmodalWidth">
  <div class="title" style="width: 86em;min-width: 100%;max-width: 86em;">
    New Request
  </div>
    <form id="allocationForm" name="allocationForm" [formGroup]="allocationForm" (ngSubmit)="submitForm()"
      autocomplete="off">
      <div class="row-container top">
        <div class="depot-search-field">
          <span>Depot:</span>
          <mat-form-field>
            <input type="text" matInput formControlName="depot" [matAutocomplete]="depotAuto" #depotInput testName="depotInput">
            <button mat-button *ngIf="depot" matSuffix mat-icon-button aria-label="Clear"
              (click)="clearDepot(depotInput)" testName="depotAutoClear">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-autocomplete #depotAuto="matAutocomplete" [displayWith]="displayDepot"
            (optionSelected)="selectDepot($event.option.value)">
            <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
            <mat-option *ngFor="let depot of filteredDepots?.items " [value]="depot">
              {{depot?.depotNumber + ' ' + depot?.depotName}}</mat-option>
          </mat-autocomplete>
        </div>
        <div>
          <span>Ready Date:</span>
          <mat-form-field>
            <input matInput [matDatepicker]="picker" formControlName="readyDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>  
        <div>
            <span>Expiry Date:</span>
            <mat-form-field>
              <input matInput [matDatepicker]="expicker" formControlName="expiryDate">
              <mat-datepicker-toggle matSuffix [for]="expicker"></mat-datepicker-toggle>
              <mat-datepicker #expicker></mat-datepicker>
            </mat-form-field>
        </div>          
      </div>
      <div formArrayName="materials">
        <table style="width: 100%;">
          <thead>
            <th>Ready At</th>
            <th>Expiry Date</th>
            <th>Heap</th>
            <th>Material</th>
            <th>Weight</th>
            <th>Unit</th>
            <th>Total Loads</th>
            <th>Status</th>
            <th>Container Type</th>
            <th>Packaging</th>
            <th>Comment</th>
            <th></th>
            <th></th>
            <th></th>
          </thead>
          <tbody>
            <tr *ngFor="let m of materials.controls; let i = index" [formGroupName]="i" autocomplete="off">
              <td>
                <mat-form-field class="mid-form-field">
                  <mat-label>Ready Date</mat-label>
                  <input matInput [matDatepicker]="picker" formControlName="readyDate">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="mid-form-field">
                    <mat-label>Expiry Date</mat-label>
                    <input matInput [matDatepicker]="expicker" formControlName="expiryDate">
                    <mat-datepicker-toggle matSuffix [for]="expicker"></mat-datepicker-toggle>
                    <mat-datepicker #expicker></mat-datepicker>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="mid-form-field">
                  <input type="text" matInput formControlName="heap" [matAutocomplete]="heapAuto" placeholder="Heap"
                    #heapInput minlength="1">
                  <button mat-button *ngIf="m.controls.heap.value" matSuffix mat-icon-button aria-label="Clear"
                    (click)="clearHeap(heapInput, m.controls.heap)" testName="heapAutoClear">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <mat-autocomplete #heapAuto="matAutocomplete" [displayWith]="displayHeap">
                  <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
                  <mat-option *ngFor="let heap of filteredHeaps?.items" [value]="heap">
                    {{heap.heapShortName+ ' ' + heap.heapDesc}}</mat-option>
                </mat-autocomplete>
              </td>
              <td>
                <mat-form-field class="mid-form-field">
                  <input matInput maxlength="500" formControlName="gradedescription" [placeholder]="gradeDescriptionPlaceholder(m.controls.gradedescription)" required>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="small-form-field">
                  <input matInput formControlName="weight" type="number" placeholder="Weight">
                  <mat-error *ngIf="weight.errors?.pattern">Please enter a valid value i.e. 1200</mat-error>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="small-form-field">
                  <mat-select formControlName="uom" placeholder="Unit">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let opt of uoms" [value]="opt.id">{{ opt?.name }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td testName="totalLoadsData">
                <mat-form-field class="small-form-field">
                  <input matInput formControlName="tl" type="number" min="1" mat-select-on-focus
                    placeholder="Total loads">
                  <span style="cursor: pointer" *ngIf="m.controls.loadTimes.controls.length > 0" matSuffix
                    (click)="showHideLoadTimes(i, $event)">
                    <i class="fa fa-clock"></i>
                  </span>
                </mat-form-field>
                <div formArrayName="loadTimes" [hidden]="hiddenLoadTimes(i)" [ngStyle]="loadTimesStyle">
                  <div *ngFor="let l of m.controls.loadTimes.controls; let t = index" [formGroupName]="t">
                    <mat-form-field class="small-form-field">
                      <input matInput id="datetime" formControlName="time" type="time" for
                        placeholder="Pickup load {{t+1}} at">
                    </mat-form-field>

                    <mat-form-field *ngIf="t==0 && m.controls.loadTimes.controls.length > 1">
                      <mat-select id="ddlIncrement" formControlName="increment"
                        placeholder="Pickup other loads at every" (selectionChange)="setIncrements(i, $event.value)">
                        <mat-option *ngFor="let inc of increments" [value]="inc">{{ inc }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </td>
              <td>
                <mat-form-field>
                    <mat-select formControlName="status" placeholder="Status">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let opt of pageModel?.statuses" [value]="opt.id">{{ opt?.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
            </td>
              <td>
                <mat-form-field class="small-form-field">
                  <mat-select formControlName="containerSize" placeholder="Container Type">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let opt of pageModel?.containerSizes" [value]="opt.id">{{ opt?.displayName }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="containerSize.touched && containerSize.errors?.required">This is required!
                  </mat-error>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field class="small-form-field">
                  <mat-select formControlName="packaging" placeholder="Packaging">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let opt of pageModel?.packageOptions" [value]="opt.id">{{ opt?.displayName }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="packaging.touched && packaging.errors?.required">This is required!</mat-error>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field>
                  <textarea matInput formControlName="comment" [placeholder]="commentPlaceholder(m.controls.comment)"
                    maxlength="500" rows="1"></textarea>
                </mat-form-field>
              </td>
              <td>
                <button mat-button type="button" (click)="removeMaterial(i)" class="addButton button-newmodalwidth">
                  <i class="fa fa-trash"></i>
                </button>
              </td>
              <td>
                <button mat-button type="button" (click)="copyMaterial(i)" class="addButton button-newmodalwidth">
                  <i class="fa fa-copy"></i>
                </button>
              </td>
              
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td colspan="11"></td>
              <td>
                <button mat-button type="button" (click)="addMaterialRow()" class="addButton button-newmodalwidth" testName="addMaterial">
                  <i class="fa fa-plus-circle"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div mat-dialog-actions class="buttons-row">
        <button mat-flat-button type="button" (click)="dialogRef.close()">Cancel</button>
        <button mat-flat-button color="primary" type="submit"  [disabled]="isSubmit" testName="create">Create</button>
        <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
      </div>
    </form>
  </div>
