<div class="dialog-header">Import Route To Market</div>
<div aria-label="Import Route To Market" class="dialog-body">
    <div mat-dialog-content layout="row">
        <input class="form-control" type="file" #fileInput />
        <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
    </div>
    <div mat-dialog-actions layout="row" class="dialog-flex-justify-center">
        <button mat-flat-button (click)="cancel()" type="button" class="md-raised md-ink-ripple">Cancel</button>
        <button mat-flat-button type="button" color="primary" class="md-raised md-primary md-ink-ripple" (click)="ok()" [disabled]="isSubmit">Submit</button>
    </div>
</div>