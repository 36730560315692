<div [attr.aria-label]="data.aria">
  <h1 mat-dialog-title>{{title}}</h1>
  <div mat-dialog-content #mainTable>
    <app-spinner [displayProgressSpinner]="isLoading" [container]="mainTable" #spinner></app-spinner>
    <form  [formGroup]="form" class="pos-fixed">
      <table>
        <tr>
          <td>
            <mat-form-field>
              <mat-label>View</mat-label>
              <mat-select formControlName="selectedView">
                <mat-option *ngFor="let view of views" [value]="view">
                  {{view.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
          <td>
            <mat-form-field>
              <mat-label>View Name</mat-label>
              <input formControlName="viewName" matInput placeholder="Enter view name" >
            </mat-form-field>
          </td>
          <td>
            <a [ngClass]="isSaveProgress != true ? '' : 'disableClick'"
            class="mat-button mat-primary mat-flat-button" (click)="save()">{{saveText}}</a>
          </td>
        </tr>
      </table>
    </form>
    <div class="container-column">
      <div cdkDropList class="column-list" (cdkDropListDropped)="drop($event)">
        <div class="column" *ngFor="let col of columns" cdkDrag>
          <mat-checkbox [checked] = "col.isSelected"
                        (change)="onColumnChecked(col, $event.checked)">
            {{col.name}}
          </mat-checkbox>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button  class="mat-button mat-warn mat-flat-button" (click)="DeleteView()">Delete</button>
    <button mat-button (click)="cancel()">Cancel</button>
  </div>
</div>