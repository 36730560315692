import { FormGroup } from '@angular/forms';
import { isNullOrUndefined } from '../tools';

export class CustomValidators {
    static dateLessThan(from: string, to: string) {
        return (group: FormGroup): { [key: string]: any } => {
            const f = group.controls[from];
            const t = group.controls[to];
            const fromValue = !isNullOrUndefined(f.value) ? new Date(f.value) : '';
            const toValue = !isNullOrUndefined(t.value) ? new Date(t.value) : '';
			
            if (toValue != '' && fromValue > toValue) {
                return {
                    dates: `Date from (${from}) should be less than Date to (${to})`
                };
            }             
            return null;
        };
    }
    static lessThanEqualTo(lessValue: string, moreValue: string) {
        return (group: FormGroup): { [key: string]: any } => {
            const f = group.controls[lessValue];
            const t = group.controls[moreValue];
            const fromValue = !isNullOrUndefined(f.value) ? new Number(f.value) : 0;
            const toValue = !isNullOrUndefined(t.value) ? new Number(t.value) : 0;
			
            if (fromValue > toValue) {
                return {
                    dates: `Value (${lessValue}) should be less than equal to (${moreValue})`
                };
            }             
            return null;
        };
    }
    static dateLessThanExactDate(from: string, to: string) {
        return (group: FormGroup): { [key: string]: any } => {
            const f = group.controls[from];
            const fromValue = !isNullOrUndefined(f.value) ? new Date(f.value) : '';
            const toValue = new Date(to);
			
            if (fromValue > toValue) {
                return {
                    dates: `Date from (${from}) should be less than Date to (${to})`
                };
            }             
            return null;
        };
    }
    static allIfAny(keys: string[]) {
        return (group: FormGroup): { [key: string]: any } => {
            if (group.pristine) { return null; }
            let anySet = false;
            const unset = [];
            for (const key of keys) {
                const k = group.controls[key];
                if (!isNullOrUndefined(k.value)) { anySet = true; }
            }

            if (anySet) {
                for (const key of keys) {
                    const k = group.controls[key];
                    if (isNullOrUndefined(k.value)) {
                        unset.push(key);
                    }
                }
                if (unset.length > 0) {
                    return {
                        unset: `The fields: ${unset.join(', ')} must be set`
                    };
                }
            }
            return null;
        };
    }
    static AtLeastOneInputHasValue = () => {
        return (group: FormGroup) => {
            if (!Object.values(group.value).find(value => value !== '')) {
                return { message: 'Please input at least one value' };
            }
            return null;
        };
    };

    //When the 'conditionField' is set, the 'requiredField' must also be set.
    //This is a one-way dependency
    static requiredWhenSet(requiredField: string, conditionField: string, requiredFieldFriendlyName: string = null, conditionFieldFriendlyName: string = null) {
        return (group: FormGroup): { [key: string]: any } => {
            if (group.pristine) { return null; }
            const con = group.controls[conditionField];
            if(!isNullOrUndefined(con.value) && con.value !== '') { //conditionField is set, requiredField is required to be set
                const req = group.controls[requiredField];
                if(isNullOrUndefined(req.value)) {
                    return { unset: `${!isNullOrUndefined(requiredFieldFriendlyName) ? requiredFieldFriendlyName : requiredField} must be set when ${!isNullOrUndefined(conditionFieldFriendlyName) ? conditionFieldFriendlyName : conditionField} is set.` };
                }
            }
            return null;
        };
    }
}

