import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { ImportStatus, QueryModel, QuerySearchPredicate} from '../gen/models';
import { SortInfo } from '../table-sort/table-sort.directive';
import * as _ from 'underscore';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { isNullOrUndefined } from '../tools';
import { EnumVals, ListOfColumnNames } from '../gen/enums/enums';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SpinnerComponent } from '../spinner/spinner.component';
import { Subscription } from 'rxjs';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';
import { DepotViewModel } from '../gen/models/DepotViewModel';
import { ApiPageResponse } from '../gen/models/ApiPageResponse';
import { DepotService } from '../gen/services/depot.service';
import { ADGroupService } from '../gen/services/adgroup.service';
import { UserPreferencesVm } from '../gen/models/UserPreferencesVm';
import { ActivatedRoute } from '@angular/router';
import { UserPreferenceTableEnum, UserTableViewVm } from '../gen/models/UserTableViewVm';
import { UserPreferencesService } from '../gen/services/user-preferences.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { RouteToMarketService } from '../gen/services/route-to-market.service';
import { RouteToMarketViewModel } from '../gen/models/RouteToMarketViewModel';
import { ChangeHistoryDialogComponent } from '../change-history-dialog/change-history-dialog.component';
import { RouteToMarketDialogUpdateComponent } from '../route-to-market-dialog-update/route-to-market-dialog-update.component';
import { RouteToMarketDialogImportComponent } from '../route-to-market-dialog-import/route-to-market-dialog-import.component';
import { RouteToMarketDialogAddComponent } from '../route-to-market-dialog-add/route-to-market-dialog-add.component';

@Component({
    selector: 'app-route-to-market',
    templateUrl: './route-to-market.component.html',
    styleUrls: ['./route-to-market.component.scss']
})
export class RouteToMarketComponent implements OnInit {

    public commentsId: number;
    isLoading = true;
    showSearch = false;
    routesToMarkets: RouteToMarketViewModelExtended[] = [];
    canEditSelected = false;
    lastSelectedIndex = -1;
    edittableState: QueryModel;
    tableState: QueryModel;
    page = 1;
    pageSize = EnumVals.NoOfResults[0].value;
    collectionSize = 1;
    sortReverse = true;
    public tableWidth: number;
    searchForm: FormGroup;
    showAllFields = false;
    sCols: string[] = [];
    querySubscription: Subscription;
    routeToMarketAdmin = false;
    filteredDepots: ApiPageResponse<DepotViewModel>;
    fieldsList: { name: string, colWidth: number, type: string }[];
    userPreferencesData: UserPreferencesVm = { depot: '', territory: '', requestFields: '', allocationFields: '', orderBookType: '', viewMode: '', allocationDate: '', pickUpFromDate: ''  };
    preferedDepot: DepotViewModel;
    routeToMarketColumns: string[] = [];
    routeToMarketTableViewForm: FormGroup;
    dataSource = new MatTableDataSource(this.routesToMarkets);
    selectedUserTableView: UserTableViewVm;
    requestTableViews: UserTableViewVm[];
    displayedColumns: string[] = [];
    routeToMarketDisplayedColumns: string[] = ListOfColumnNames.defaultSelectedColsForRouteToMarkets;
    columnList: string[];
    defaultView: UserTableViewVm[] = [
        {
            id: -1,
            table: UserPreferenceTableEnum.RouteToMarket,
            userId: -1,
            isDeleted: false,
            isSelected: true,
            viewName: 'Default View',
            userTableViewConfigs: [
                { id: -1, columnName: 'Depot', isSelected: true, index: 1 },
                { id: -1, columnName: 'Grade', isSelected: true, index: 2 },
                { id: -1, columnName: 'Destination', isSelected: true, index: 3 },
                { id: -1, columnName: 'EffectiveDate', isSelected: true, index: 4 }
            ]
        }
    ];

    @ViewChild('spinner', { static: true }) spinner: SpinnerComponent;
    @ViewChildren('matRef') matRef: QueryList<MatSelect>;
    @ViewChild(MatSort) sort: MatSort;

    tableHeight = 800;

    constructor(private dialog: MatDialog,
        private fb: FormBuilder,
        private depotsService: DepotService,
        private toastr: ToastrService,
        private adGroupService: ADGroupService,
        private activatedRoute: ActivatedRoute,
        private routeToMarketService: RouteToMarketService,
        private userPreferenceService: UserPreferencesService) {
        const userPreferences = this.activatedRoute.snapshot.data['userpreferencesData'];
        if (userPreferences !== undefined && userPreferences.length > 0)
            this.userPreferencesData = userPreferences[0];

        this.routeToMarketTableViewForm = this.fb.group({
            routeToMarketTableView: [''],
            columnsList: ['']
        });
    }

    addRouteToMarket() {
        const dialogRef = this.dialog.open(RouteToMarketDialogAddComponent, {
            minWidth: '140px',
            maxWidth: '60%',
            maxHeight: '40%',
            data: { depotNo: this.depot, grade: this.grade, route: this.route },
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe(updated => {
            if (updated) {
                this.callServer(this.tableState);
            }
        });
    }

    ngOnInit() {
        const depot = this.activatedRoute.snapshot.queryParamMap.get('depot');
        const grade = this.activatedRoute.snapshot.queryParamMap.get('grade');
        const route = this.activatedRoute.snapshot.queryParamMap.get('route');

        this.fieldsList = ListOfColumnNames.fieldsListForRouteToMarkets;

        this.preferedDepot = { depotNumber: this.userPreferencesData.depot, depotName: '', depotShortName: '', selected: false };

        const deptNo = (this.preferedDepot.depotNumber === '') ? null : this.preferedDepot.depotNumber;

        this.callServer({
            pagination: { number: this.pageSize, start: 0 },
            search: {
                predicateObject: {
                    grade: grade,
                    territory: null,
                    gradeDescription: null,
                    depot: depot != null ? depot : deptNo,
                    depotName: null,
                    description: null,
                    comment: null,
                    containerPackaging: null,
                    heap: null,
                    comments: null,
                    allocationNumber: null,
                    contractRef: null,
                    partyAccountNo: null,
                    partyName: null,
                    partySearchText: null,
                    requestedGrade: null,
                    requestedGradeDescription: null,
                    classification: null,
                    classifications: [],
                    haulierAccountNo: null,
                    haulierName: null,
                    haulierSearchText: null,
                    ticketNumber: null,
                    status: null,
                    gradeGroupName: null,
                    heapDescription: null,
                    review: null,
                    salesGrade: null,
                    containerTypeName: null,
                    requestStatuses: [],
                    allocationStatuses: [],
                    kpiTypes: [],
                    contractHeaderStatuses: [],
                    presetFilterIds: [],
                    dateRange: null,
                    pickupFromDateRange: null,
                    firstComeFirstServe: false,
                    awaitingDispatch: null,
                    lifecycleStatusId: 0,
                    includeDeleted: false,
                    route: null,
                    destination: null
                }
            },
            sort: { predicate: null, reverse: this.sortReverse }
        });
        this.assignDefaultColumns();

        this.searchForm = this.fb.group({
            depot: depot != null ? { depotNumber: depot, depotName: '', depotShortName: '', selected: false } : this.preferedDepot,
            grade: grade != null ? grade : '',
            route: route != null ? route : '',
            noOfResults: this.pageSize,
        });

        this.adGroupService.getMyGroups().subscribe(g => {
            this.routeToMarketAdmin = g.myGroups.includes('RouteToMarketAdmin');
        });

        this.depot.valueChanges
            .pipe(
                debounceTime(500),
                startWith(null),
                switchMap(v => this.filterDepots(v)))
            .subscribe(d => this.filteredDepots = d);

        this.routeToMarketTableViewForm.get('routeToMarketTableView')
            .valueChanges
            .subscribe(value => this.displayedColumns = this.SetDisplayColumns(value));

        this.userPreferenceService
            .getUserTableView(UserPreferenceTableEnum.RouteToMarket)
            .subscribe(view => {
                if (isNullOrUndefined(view) || view.length === 0) {
                    view = this.defaultView;
                }
                this.displayedColumns = this.MapDisplayedColumns(view);
            });

        this.calculateHeight();
    }

    calculateHeight(fromSearch = false) {
        let searchHeight = 0;

        const intFrameHeight = window.innerHeight;

        if (fromSearch)
            searchHeight = window.innerHeight * 0.07;

        const calulatedHeight = intFrameHeight - 50 - 48 - 37 - 50 - searchHeight - 36 - 10; //the height of the inside screen - the top 3 sections (excluding the search - 10 for spacings
        this.tableHeight = calulatedHeight;
    }

    searchClicked() {
        this.showSearch = !this.showSearch;
        this.calculateHeight(this.showSearch);
    }

    SetDisplayColumns(selectedView: UserTableViewVm): string[] {
        if (!selectedView) {
            return this.fieldsList.map(f => f.name);
        }
        this.selectedUserTableView = selectedView;
        const cols = this.selectedUserTableView.userTableViewConfigs.filter(col => col.isSelected && col.columnName != 'Show All').sort((a, b) => {
            return a.index - b.index;
        }).map(x => x.columnName);

        cols.push('History'); //This is static column
        this.sCols = cols;
        this.routeToMarketColumns = cols;
        this.routeToMarketDisplayedColumns = cols;
        return cols;
    }

    MapDisplayedColumns(view: UserTableViewVm[]): string[] {
        this.requestTableViews = view;
        this.selectedUserTableView = view.find(v => v.isSelected);
        if (this.selectedUserTableView) {
            this.routeToMarketTableViewForm.patchValue({
                'routeToMarketTableView': this.selectedUserTableView,
                'columnsList': this.selectedUserTableView.userTableViewConfigs.filter(f => f.isSelected).map(m => m.columnName)
            });
        }
        this.columnList = this.selectedUserTableView ? this.selectedUserTableView.userTableViewConfigs.map(config => config.columnName) : [];
        this.columnList.unshift('Show All');
        this.sCols.unshift('Show All');
        return this.SetDisplayColumns(this.selectedUserTableView);
    }

    filterDepots(val: string) {
        return this.depotsService.searchDepots(val);
    }

    get depot() {
        return this.searchForm.get('depot');
    }

    get grade() {
        return this.searchForm.get('grade');
    }

    get route() {
        return this.searchForm.get('route');
    }

    displayDepot(depot?: DepotViewModel): string | undefined {
        let depotString = undefined;
        if (depot && depot.depotNumber && depot.depotNumber !== '' && depot.depotName !== '')
            depotString = depot.depotNumber + ' - ' + depot.depotName;
        else if (depot && depot.depotNumber && depot.depotNumber !== '' && depot.depotName === '')
            depotString = depot.depotNumber;
        return depotString;
    }

    ngOnDestroy(): void {
        this.isLoading = false;
        this.spinner.overlayRef.dispose();
        if (this.querySubscription != null && !this.querySubscription.closed) {
            this.querySubscription.unsubscribe();
        }
    }

    get selectedRouteToMarkets() {
        return this.routesToMarkets.filter(a => a.isSelected);
    }

    callServer(tableState: QueryModel) {
        this.isLoading = true;

        if (tableState) {
            this.tableState = tableState;
            this.edittableState = tableState;
            this.tableState.pagination.number = isNullOrUndefined(this.searchForm) ? 10 : isNullOrUndefined(this.searchForm.get('noOfResults').value) ? 10 : this.searchForm.get('noOfResults').value;
            this.pageSize = this.tableState.pagination.number;
        }

        if (this.querySubscription != null && !this.querySubscription.closed) {
            this.querySubscription.unsubscribe();
        }

        this.routeToMarketService.getRoutes(tableState).subscribe(r => {
            if (isNullOrUndefined(r)) {
                this.isLoading = false;
                return;
            }

            this.routesToMarkets = r.routes.map(a => {
                const ext = a as RouteToMarketViewModelExtended;
                return ext;
            });

            this.collectionSize = r.recordCount;
            if (this.tableState.pagination.start === 1) {
                this.page = 1;
            }
            this.dataSource = new MatTableDataSource(this.routesToMarkets);
            this.isLoading = false;
        }, () => {
            this.isLoading = false;
        });
    }

    sortByInfo(info: SortInfo) {
        if (info.active) {
            this.tableState.sort.predicate = info.active;
            this.tableState.sort.reverse = info.direction != 'asc';
            this.sortReverse = this.tableState.sort.reverse;
            this.callServer(this.tableState);
        }
    }

    pageChanged(page: number) {
        this.page = page;
        this.tableState.pagination.start = page;
        this.callServer(this.tableState);
    }

    search(formGroup: FormGroup) {

        const formVal = formGroup.value;

        let depot = formVal.depot;
        if (typeof (depot) !== 'string')
            depot = depot.depotNumber;

        const predicate: QuerySearchPredicate = {
            grade: formVal.grade,
            gradeDescription: null,
            gradeGroupName: null,
            salesGrade: null,
            heap: null,
            heapDescription: null,
            requestedGrade: null,
            requestedGradeDescription: null,
            depot: depot,
            depotName: null,
            territory: null,
            contractRef: null,
            classification: null,
            classifications: [],
            partyAccountNo: null,
            partyName: null,
            partySearchText: null,
            haulierAccountNo: null,
            haulierName: null,
            haulierSearchText: null,
            ticketNumber: null,
            allocationNumber: null,
            containerPackaging: null,
            containerTypeName: null,
            requestStatuses: [],
            allocationStatuses: [],
            contractHeaderStatuses: [],
            presetFilterIds: [],
            lifecycleStatusId: 0,
            comment: null,
            comments: null,
            status: null,
            description: null,
            review: null,
            firstComeFirstServe: false,
            awaitingDispatch: null,
            dateRange: null,
            pickupFromDateRange: null,
            includeDeleted: false,
            route: formVal.route,
            kpiTypes: [],
            destination: null,
            isDraft: null
        };
        this.tableState.search.predicateObject = predicate;
        this.tableState.pagination.start = 0;
        this.tableState.pagination.number = formVal.noOfResults;
        this.tableState = Object.assign({}, this.tableState);
        this.callServer(this.tableState);
        this.clearSelected();
        this.updateCanEditSelected();
    }

    isDisabled(): boolean {
        return (!this.canEditSelected || this.selectedRouteToMarkets.length === 0);
    }

    isDeleted(item: RouteToMarketViewModelExtended): boolean {
        return !item.active;
    }

    selectRouteToMarket(item: RouteToMarketViewModelExtended, event: MouseEvent) {
        if (!event.ctrlKey && !event.shiftKey) {
            this.clearSelected();
        }
        if (!item.active) {
            this.toastr.warning('Route to market can\'t be selected because it is deleted.', 'Warning');
            return;
        }
        if (event.shiftKey && this.lastSelectedIndex >= 0) {
            const newIndex = this.routesToMarkets.indexOf(item);
            this.selectRange(this.lastSelectedIndex, newIndex);
            item.isSelected = true;
        }
        else {
            item.isSelected = !item.isSelected;
        }

        this.lastSelectedIndex = this.routesToMarkets.indexOf(item);

        this.updateCanEditSelected();
    }

    updateCanEditSelected() {
        if (this.selectedRouteToMarkets.length === 1) {
            this.canEditSelected = true;
        }
        else if (this.selectedRouteToMarkets.length > 1) {
            this.canEditSelected = this.selectedRouteToMarkets.every(route => route.depotNo === this.selectedRouteToMarkets[0].depotNo);
        }
        else {
            this.canEditSelected = false;
        }
    }

    clearSelected() {
        for (const a of this.selectedRouteToMarkets) {
            a.isSelected = false;
        }
        for (const a of this.routesToMarkets.filter(a => a.isSelected)) {
            a.isSelected = false;
        }

        this.lastSelectedIndex = -1;
    }

    editRouteToMarket() {
        if (this.selectedRouteToMarkets.length > 0 && this.selectedRouteToMarkets.every(route => route.depotNo === this.selectedRouteToMarkets[0].depotNo)) {
            const selectedRouteToMarket = this.selectedRouteToMarkets[0];
            let selectedGrade = '';
            if (this.selectedRouteToMarkets.every(route => route.grade === selectedRouteToMarket.grade)) {
                selectedGrade = selectedRouteToMarket.grade;
            }
            const dialogRef = this.dialog.open(RouteToMarketDialogUpdateComponent, {
                minWidth: '240px',
                maxWidth: '85%',
                maxHeight: '80%',
                data: { depotNo: selectedRouteToMarket.depotNo, grade: selectedGrade, selected: this.selectedRouteToMarkets },
                disableClose: true,
            });
            dialogRef.afterClosed().subscribe(updated => {
                if (updated) {
                    this.callServer(this.tableState);
                }
            });
        }
    }

    bulkUploadRouteToMarkets() {
        const dialogRef = this.dialog.open(RouteToMarketDialogImportComponent, {
            minWidth: '140px',
            maxWidth: '60%',
            maxHeight: '40%',
            data: null,
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe((importStatus : ImportStatus) => {
            if (importStatus && importStatus.importSuccess) {
                this.callServer(this.tableState);
            }
        });
    }

    assignDefaultColumns() {
        if (this.routeToMarketColumns.length > 0)
            this.sCols = this.routeToMarketColumns;
        else
            this.sCols = ListOfColumnNames.defaultSelectedColsForRouteToMarkets;

        if (!isNullOrUndefined(this.sCols))
            this.setTableWidth();
    }

    setTableWidth() {
        this.tableWidth = this.fieldsList
            .filter(f => f.type === 'routeToMarket' && this.sCols.indexOf(f.name) > -1)
            .map(f => f.colWidth)
            .reduce((a, b) => a + b, 0);
    }

    showHistory(id: number) {
        this.dialog.open(ChangeHistoryDialogComponent, {
            minWidth: '240px',
            maxWidth: '80%',
            maxHeight: '80%',
            data: { id, table: 'RouteToMarket' }
        });
    }

    clearFilter() {
        this.matRef.forEach(select => {
            select.options.forEach((data: MatOption) => data.deselect());
        });

        this.searchForm.reset({
            depot: '',
            noOfResults: EnumVals.NoOfResults[1].value,
        });
        this.search(this.searchForm);
    }

    selectRange(startIndex: number, endIndex: number) {
        const reverse = startIndex > endIndex;
        if (reverse) {
            for (let index = endIndex; index < startIndex; index++) {
                const routeToMarket = this.routesToMarkets[index];
                if (!this.isDeleted(routeToMarket)) {
                    routeToMarket.isSelected = true;
                }
            }
        }
        else {
            for (let index = startIndex; index < endIndex; index++) {
                const routeToMarket = this.routesToMarkets[index];
                if (!this.isDeleted(routeToMarket)) {
                    routeToMarket.isSelected = true;
                }
            }
        }
        this.updateCanEditSelected();
        document.getSelection().removeAllRanges(); //deselect highlighted text
    }

    setSpinner(value: boolean) {
        this.isLoading = value;
    }
}

interface RouteToMarketViewModelExtended extends RouteToMarketViewModel {
  isSelected: boolean;
}
