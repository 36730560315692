import {InjectionToken} from '@angular/core';

export const REST_CONFIG_TOKEN =  new InjectionToken<string>('RestConfig');


export interface RestConfigService {

    apiBaseUrl(): string;

    }
