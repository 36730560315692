
import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
// import {ErrorResponse} from '../gen/models/ErrorResponse';



@Injectable()
export class ErrorService {


	constructor(private oauthService: OAuthService
	) { }


	private _error: BehaviorSubject<Error | ErrorResponse | string> = new BehaviorSubject<Error | ErrorResponse | string>('');

	public get error(): BehaviorSubject<Error | ErrorResponse | string>  { return this._error; }




	private redirectOn401 (error: any): boolean {
		if (error instanceof HttpErrorResponse) {
			if (error.status === 401) {
				this.oauthService.initImplicitFlow();
				return true;
			}
		}
		return false;
	}



	public handle(error: any): void {
		console.error(error);
		if (this.redirectOn401(error)) {
			return;
		}


		console.log(error);
		if (error instanceof Error) {
			this.error.next(error);
		} else if (typeof(error) === 'string') {
			this.error.next(error);
		} else if (error instanceof HttpErrorResponse && error.error as ErrorResponse) {
			this.error.next(error.error as ErrorResponse);
		} else {
			const err: string = JSON.stringify(error);
			this.error.next(err);
		}
	}



	public tryCatch(action: () => Promise<void>, onError: () => void = () => {}): void {

		let p: Promise<void>;
		try {
			p = action();
		} catch (err) {
			this.handle(err);
			onError();
			return;
		}

		p.catch((error: any) => {
			this.handle(error);
			onError();
		});
	}
}

interface ErrorResponse {
  message: string;
}
