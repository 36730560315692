import { Directive, ElementRef, HostListener, Output, Input, Renderer2, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TableSortService } from './table-sort.service';

@Directive({
	selector: 'th[appTableSort]'
})
export class TableSortDirective implements OnInit {

  @Input() appTableSort: string;
  @Input() reverseIsDefault: boolean;
  @Input() thirdClickReset: boolean;
  @Output() sort: BehaviorSubject<SortInfo> = new BehaviorSubject<SortInfo>({ active: null, direction: 'asc' });
  columnSubscription: Subscription;
  clicked = 0;

  constructor(private el: ElementRef, private rend: Renderer2, private tableSortService: TableSortService) { }

  ngOnInit(): void {
  	this.columnSubscription = this.tableSortService.selectedColumn().subscribe(column => this.clearIfNotMe(column));
  }

  @HostListener('click', ['$event.target']) onclick() {
  	if (this.thirdClickReset)
  		this.clicked++;
  	this.tableSortService.setSelectedColumn(this.appTableSort);
  	const iElements = Array.from(this.el.nativeElement.children).filter(n => (n as any).localName === 'i');
  	if (iElements.length === 0) {
  		const i = this.rend.createElement('i');
  		this.rend.appendChild(this.el.nativeElement, i);
  		const inner = this.el.nativeElement.lastChild;
  		this.rend.addClass(inner, 'fa');
  		this.rend.addClass(inner, `fa-sort-${this.reverseIsDefault ? 'up' : 'down'}`);
  		this.sort.next({ active: this.appTableSort, direction: this.reverseIsDefault ? 'asc' : 'desc' });
  	}
  	else if (this.thirdClickReset && this.clicked % 3 == 0) {
  		const inner = this.el.nativeElement.lastChild;
  		if (inner.className === 'fa fa-sort-up') {
  			this.rend.removeClass(inner, 'fa-sort-up');
  		} else if (inner.className === 'fa fa-sort-down') {
  			this.rend.removeClass(inner, 'fa-sort-down');
  		}
  		this.sort.next({ active: this.appTableSort, direction: this.reverseIsDefault ? 'asc' : 'desc' });
  		this.clicked = 0;
  	}
  	else {
  		const inner = this.el.nativeElement.lastChild;
  		if (inner.className === 'fa fa-sort-up') {
  			this.rend.removeClass(inner, 'fa-sort-up');
  			this.rend.addClass(inner, 'fa-sort-down');
  			this.sort.next({ active: this.appTableSort, direction: this.reverseIsDefault ? 'asc' : 'desc' });
  		} else if (inner.className === 'fa fa-sort-down') {
  			this.rend.removeClass(inner, 'fa-sort-down');
  			this.rend.addClass(inner, 'fa-sort-up');
  			this.sort.next({ active: this.appTableSort, direction: this.reverseIsDefault ? 'asc' : 'desc' });
  		} else {
  			this.rend.addClass(inner, 'fa');
  			this.rend.addClass(inner, `fa-sort-${this.reverseIsDefault ? 'down' : 'up'}`);
  			this.sort.next({ active: this.appTableSort, direction: this.reverseIsDefault ? 'asc' : 'desc' });
  		}
  	}
  }

  private clearIfNotMe(column: string) {
  	if (this.appTableSort !== column) {
  		const inner = this.el.nativeElement.lastChild;
  		if (inner && inner.nodeName !== '#text') {
  			this.rend.removeClass(inner, 'fa');
  			this.rend.removeClass(inner, 'fa-sort-up');
  			this.rend.removeClass(inner, 'fa-sort-down');
  		}
  	}
  }
}

export interface SortInfo {
  active: string;
  direction: string;
}
