import { Component, OnInit, Input } from '@angular/core';
import { SidenavService } from '../services/sidenav.service';
import { BookingSheetService } from '../gen/services/booking-sheet.service';
import { BookingSheetScheduleVm } from '../gen/models';

@Component({
	selector: 'app-load-schedule-view',
	templateUrl: './load-schedule-view.component.html',
	styleUrls: ['./load-schedule-view.component.scss']
})
export class LoadScheduleViewComponent implements OnInit {
	isLoading = true;
	loadSchedule: BookingSheetScheduleVm[];
	constructor(private sidenavService: SidenavService,
    private bookingSheetService: BookingSheetService
	) { }

	ngOnInit() {
		this.sidenavService.selectedBooking$.subscribe(a => {
			this.isLoading = true;    
			if (a.item) {
				this.bookingSheetService.getScheduleInfo(a.item.depotNumber, a.item.grade, a.dateRangeType).subscribe(
					s => {
						this.loadSchedule = s;
						this.isLoading = false;
					}
				);
			}
		});
	}

}
