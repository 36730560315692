import {
	Component,
	Input,
	Output,
	EventEmitter,
	ChangeDetectorRef,
	OnChanges,
	OnInit,
	OnDestroy,
	LOCALE_ID,
	Inject,
	TemplateRef,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import {
	WeekDay,
	CalendarEvent,
	WeekViewAllDayEvent,
	WeekView,
	ViewPeriod,
	WeekViewHourColumn,
	WeekViewTimeEvent,
	WeekViewHourSegment,
	WeekViewHour,
	WeekViewAllDayEventRow,
} from 'calendar-utils';
import { ResizeEvent } from 'angular-resizable-element';
import { WeekDayViewEvents } from '../../../gen/models/WeekDayViewEvents';
import {
	DragEndEvent,
	DropEvent,
	DragMoveEvent,
	ValidateDrag,
} from 'angular-draggable-droppable';
import { PlacementArray } from 'positioning';
import { CalendarEventTimesChangedEvent, CalendarEventTimesChangedEventType } from 'angular-calendar/esm2015/modules/common/calendar-event-times-changed-event.interface';
import { addDaysWithExclusions, getDefaultEventEnd, getMinimumEventHeightInMinutes, getMinutesMoved, getWeekViewPeriod, isDraggedWithinPeriod, roundToNearest, shouldFireDroppedEvent, trackByHour, trackByHourSegment, trackByWeekAllDayEvent, trackByWeekDayHeaderDate, trackByWeekTimeEvent, validateEvents } from 'angular-calendar/esm2015/modules/common/util';
import { CalendarResizeHelper } from 'angular-calendar/esm2015/modules/common/calendar-resize-helper.provider';
import { CalendarDragHelper } from 'angular-calendar/esm2015/modules/common/calendar-drag-helper.provider';
import { DateAdapter, CalendarUtils } from 'angular-calendar';
import { EventCalendarMoreDialogComponent } from 'src/app/event-calendar-more/event-calendar-more-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CalendarCommonService } from 'src/app/event-calendar/event-calendar-common-service';
import { PendingAndAllocatedViewModel } from 'src/app/gen/models';
import { retry } from 'rxjs-compat/operator/retry';
import { ArrayType } from '@angular/compiler';
import { min } from 'underscore';


export interface WeekViewAllDayEventResize {
  originalOffset: number;
  originalSpan: number;
  edge: string;
}

export interface CalendarWeekViewBeforeRenderEvent extends WeekView {
  header: WeekDay[];
}


@Component({
	selector: 'app-week-view',
	templateUrl: './app-week-view.component.html',
	styleUrls: ['./app-week-view.component.scss']
})
export class AppWeekViewComponent implements OnChanges, OnInit, OnDestroy {

  @Input() isDayView = false;

  @Input() pendingAndAllocated: PendingAndAllocatedViewModel[] = [];

  @Input() viewDate: Date;

  @Input() events: CalendarEvent[] = [];

  @Input() excludeDays: number[] = [];

  @Input() refresh: Subject<any>;

  @Input() locale: string;

  @Input() tooltipPlacement: PlacementArray = 'auto';

  @Input() tooltipTemplate: TemplateRef<any>;

  @Input() tooltipAppendToBody = true;

  @Input() tooltipDelay: number | null = null;

  @Input() weekStartsOn: number;

  @Input() headerTemplate: TemplateRef<any>;

  @Input() eventTemplate: TemplateRef<any>;

  @Input() eventTitleTemplate: TemplateRef<any>;

  @Input() eventActionsTemplate: TemplateRef<any>;

  @Input() precision: 'days' | 'minutes' = 'days';

  @Input() weekendDays: number[];

  @Input() snapDraggedEvents = true;

  @Input() hourSegments = 2;

  @Input() hourSegmentHeight = 30;

  @Input() dayStartHour = 0;

  @Input() dayStartMinute = 0;

  @Input() dayEndHour = 23;

  @Input() dayEndMinute = 59;

  @Input() hourSegmentTemplate: TemplateRef<any>;

  @Input() eventSnapSize: number;

  @Input() allDayEventsLabelTemplate: TemplateRef<any>;

  @Input() daysInWeek: number;

  @Input() currentTimeMarkerTemplate: TemplateRef<any>;

  @Output() dayHeaderClicked = new EventEmitter<{
    day: WeekDay;
    sourceEvent: MouseEvent;
  }>();

  @Output() eventClicked = new EventEmitter<{
    event: CalendarEvent;
    sourceEvent: MouseEvent | any;
  }>();

  @Output() eventTimesChanged =
  	new EventEmitter<CalendarEventTimesChangedEvent>();

  @Output() beforeViewRender =
  	new EventEmitter<CalendarWeekViewBeforeRenderEvent>();

  @Output() hourSegmentClicked = new EventEmitter<{
    date: Date;
    sourceEvent: MouseEvent;
  }>();


  days: WeekDay[];

  weekdayEvents: WeekDayViewEvents[];

  view: WeekView;

  refreshSubscription: Subscription;


  allDayEventResizes: Map<WeekViewAllDayEvent, WeekViewAllDayEventResize> =
  	new Map();


  timeEventResizes: Map<CalendarEvent, ResizeEvent> = new Map();


  eventDragEnterByType = {
  	allDay: 0,
  	time: 0,
  };


  dragActive = false;


  dragAlreadyMoved = false;


  validateDrag: ValidateDrag;


  validateResize: (args: any) => boolean;


  dayColumnWidth: number;


  calendarId = Symbol('angular calendar week view id');


  lastDraggedEvent: CalendarEvent;


  trackByWeekDayHeaderDate = trackByWeekDayHeaderDate;


  trackByHourSegment = trackByHourSegment;


  trackByHour = trackByHour;


  trackByWeekAllDayEvent = trackByWeekAllDayEvent;


  trackByWeekTimeEvent = trackByWeekTimeEvent;


  private lastDragEnterDate: Date;
  moreweekEvents: any[];
  currentDate: Date;
  public eventThreshold = 1;
  public eventWidth = 0;
  weekDays: {};
  constructor(
    protected cdr: ChangeDetectorRef,
    protected utils: CalendarUtils,
    @Inject(LOCALE_ID) locale: string,
    protected dateAdapter: DateAdapter,
    private dialog: MatDialog,
    private eventCalendarService: CalendarCommonService
  ) {
  	this.locale = locale;
  	this.eventThreshold = this.isDayView ? 5 : 1;
  	this.eventWidth = 210;
  }


  trackByHourColumn = (index: number, column: WeekViewHourColumn) =>
  	column.hours[0] ? column.hours[0].segments[0].date.toISOString() : column;


  trackById = (index: number, row: WeekViewAllDayEventRow) => row.id;


  ngOnInit(): void {
  	if (this.refresh) {
  		this.refreshSubscription = this.refresh.subscribe(() => {
  			this.refreshAll();
  			this.cdr.markForCheck();
  		});
  	}
  	this.eventThreshold = this.isDayView ? 4 : 1;
  	this.eventWidth = this.isDayView ? 210 : 240;
  	this.getWeekEvent();
  	//this.getEventsForHourSegment(0,0);
  }


  ngOnChanges(changes: any): void {
  	const refreshHeader =
      changes.viewDate ||
      changes.excludeDays ||
      changes.weekendDays ||
      changes.daysInWeek ||
      changes.weekStartsOn;

  	const refreshBody =
      changes.viewDate ||
      changes.dayStartHour ||
      changes.dayStartMinute ||
      changes.dayEndHour ||
      changes.dayEndMinute ||
      changes.hourSegments ||
      changes.weekStartsOn ||
      changes.weekendDays ||
      changes.excludeDays ||
      changes.hourSegmentHeight ||
      changes.events ||
      changes.daysInWeek;

  	if (refreshHeader) {
  		this.refreshHeader();
  	}

  	if (changes.events) {
  		validateEvents(this.events);
  	}

  	if (refreshBody) {
  		this.refreshBody();
  		this.getWeekEvent();
  	}

  	if (refreshHeader || refreshBody) {
  		this.emitBeforeViewRender();
  	}
  }


  ngOnDestroy(): void {
  	if (this.refreshSubscription) {
  		this.refreshSubscription.unsubscribe();
  	}
  }


  timeEventResizeStarted(
  	eventsContainer: HTMLElement,
  	timeEvent: WeekViewTimeEvent,
  	resizeEvent: ResizeEvent
  ): void {
  	this.timeEventResizes.set(timeEvent.event, resizeEvent);
  	this.resizeStarted(eventsContainer);
  }


  timeEventResizing(timeEvent: WeekViewTimeEvent, resizeEvent: ResizeEvent) {
  	this.timeEventResizes.set(timeEvent.event, resizeEvent);
  	const adjustedEvents = new Map<CalendarEvent, CalendarEvent>();

  	const tempEvents = [...this.events];

  	this.timeEventResizes.forEach((lastResizeEvent, event) => {
  		const newEventDates = this.getTimeEventResizedDates(
  			event,
  			lastResizeEvent
  		);
  		const adjustedEvent = { ...event, ...newEventDates };
  		adjustedEvents.set(adjustedEvent, event);
  		const eventIndex = tempEvents.indexOf(event);
  		tempEvents[eventIndex] = adjustedEvent;
  	});

  	this.restoreOriginalEvents(tempEvents, adjustedEvents, true);
  }


  timeEventResizeEnded(timeEvent: WeekViewTimeEvent) {
  	this.view = this.getWeekView(this.events);
  	const lastResizeEvent = this.timeEventResizes.get(timeEvent.event);
  	if (lastResizeEvent) {
  		this.timeEventResizes.delete(timeEvent.event);
  		const newEventDates = this.getTimeEventResizedDates(
  			timeEvent.event,
  			lastResizeEvent
  		);
  		this.eventTimesChanged.emit({
  			newStart: newEventDates.start,
  			newEnd: newEventDates.end,
  			event: timeEvent.event,
  			type: CalendarEventTimesChangedEventType.Resize,
  		});
  	}
  }


  allDayEventResizeStarted(
  	allDayEventsContainer: HTMLElement,
  	allDayEvent: WeekViewAllDayEvent,
  	resizeEvent: ResizeEvent
  ): void {
  	this.allDayEventResizes.set(allDayEvent, {
  		originalOffset: allDayEvent.offset,
  		originalSpan: allDayEvent.span,
  		edge: typeof resizeEvent.edges.left !== 'undefined' ? 'left' : 'right',
  	});
  	this.resizeStarted(
  		allDayEventsContainer,
  		this.getDayColumnWidth(allDayEventsContainer)
  	);
  }


  allDayEventResizing(
  	allDayEvent: WeekViewAllDayEvent,
  	resizeEvent: ResizeEvent,
  	dayWidth: number
  ): void {
  	const currentResize: WeekViewAllDayEventResize =
      this.allDayEventResizes.get(allDayEvent);

  	if (typeof resizeEvent.edges.left !== 'undefined') {
  		const diff: number = Math.round(+resizeEvent.edges.left / dayWidth);
  		allDayEvent.offset = currentResize.originalOffset + diff;
  		allDayEvent.span = currentResize.originalSpan - diff;
  	} else if (typeof resizeEvent.edges.right !== 'undefined') {
  		const diff: number = Math.round(+resizeEvent.edges.right / dayWidth);
  		allDayEvent.span = currentResize.originalSpan + diff;
  	}
  }


  allDayEventResizeEnded(allDayEvent: WeekViewAllDayEvent): void {
  	const currentResize: WeekViewAllDayEventResize =
      this.allDayEventResizes.get(allDayEvent);

  	if (currentResize) {
  		const allDayEventResizingBeforeStart = currentResize.edge === 'left';
  		let daysDiff: number;
  		if (allDayEventResizingBeforeStart) {
  			daysDiff = allDayEvent.offset - currentResize.originalOffset;
  		} else {
  			daysDiff = allDayEvent.span - currentResize.originalSpan;
  		}

  		allDayEvent.offset = currentResize.originalOffset;
  		allDayEvent.span = currentResize.originalSpan;

  		let newStart: Date = allDayEvent.event.start;
  		let newEnd: Date = allDayEvent.event.end || allDayEvent.event.start;
  		if (allDayEventResizingBeforeStart) {
  			newStart = addDaysWithExclusions(
  				this.dateAdapter,
  				newStart,
  				daysDiff,
  				this.excludeDays
  			);
  		} else {
  			newEnd = addDaysWithExclusions(
  				this.dateAdapter,
  				newEnd,
  				daysDiff,
  				this.excludeDays
  			);
  		}

  		this.eventTimesChanged.emit({
  			newStart,
  			newEnd,
  			event: allDayEvent.event,
  			type: CalendarEventTimesChangedEventType.Resize,
  		});
  		this.allDayEventResizes.delete(allDayEvent);
  	}
  }


  getDayColumnWidth(eventRowContainer: HTMLElement): number {
  	return Math.floor(eventRowContainer.offsetWidth / this.days.length);
  }


  dateDragEnter(date: Date) {
  	this.lastDragEnterDate = date;
  }


  eventDropped(
  	dropEvent: DropEvent<{ event?: CalendarEvent; calendarId?: symbol }>,
  	date: Date,
  	allDay: boolean
  ): void {
  	if (
  		shouldFireDroppedEvent(dropEvent, date, allDay, this.calendarId) &&
      this.lastDragEnterDate.getTime() === date.getTime() &&
      (!this.snapDraggedEvents ||
        dropEvent.dropData.event !== this.lastDraggedEvent)
  	) {
  		this.eventTimesChanged.emit({
  			type: CalendarEventTimesChangedEventType.Drop,
  			event: dropEvent.dropData.event,
  			newStart: date,
  			allDay,
  		});
  	}
  	this.lastDraggedEvent = null;
  }


  dragEnter(type: 'allDay' | 'time') {
  	this.eventDragEnterByType[type]++;
  }


  dragLeave(type: 'allDay' | 'time') {
  	this.eventDragEnterByType[type]--;
  }


  dragStarted(
  	eventsContainer: HTMLElement,
  	event: HTMLElement,
  	dayEvent?: WeekViewTimeEvent
  ): void {
  	this.dayColumnWidth = this.getDayColumnWidth(eventsContainer);
  	const dragHelper: CalendarDragHelper = new CalendarDragHelper(
  		eventsContainer,
  		event
  	);
  	this.validateDrag = ({ x, y, transform }) =>
  		this.allDayEventResizes.size === 0 &&
      this.timeEventResizes.size === 0 &&
      dragHelper.validateDrag({
      	x,
      	y,
      	snapDraggedEvents: this.snapDraggedEvents,
      	dragAlreadyMoved: this.dragAlreadyMoved,
      	transform,
      });
  	this.dragActive = true;
  	this.dragAlreadyMoved = false;
  	this.lastDraggedEvent = null;
  	this.eventDragEnterByType = {
  		allDay: 0,
  		time: 0,
  	};
  	if (!this.snapDraggedEvents && dayEvent) {
  		this.view.hourColumns.forEach((column) => {
  			const linkedEvent = column.events.find(
  				(columnEvent) =>
  					columnEvent.event === dayEvent.event && columnEvent !== dayEvent
  			);
  			// hide any linked events while dragging
  			if (linkedEvent) {
  				linkedEvent.width = 0;
  				linkedEvent.height = 0;
  			}
  		});
  	}
  	this.cdr.markForCheck();
  }


  dragMove(dayEvent: WeekViewTimeEvent, dragEvent: DragMoveEvent) {
  	const newEventTimes = this.getDragMovedEventTimes(
  		dayEvent,
  		dragEvent,
  		this.dayColumnWidth,
  		true
  	);
  	const originalEvent = dayEvent.event;
  	const adjustedEvent = { ...originalEvent, ...newEventTimes };
  	const tempEvents = this.events.map((event) => {
  		if (event === originalEvent) {
  			return adjustedEvent;
  		}
  		return event;
  	});
  	this.restoreOriginalEvents(
  		tempEvents,
  		new Map([[adjustedEvent, originalEvent]]),
  		this.snapDraggedEvents
  	);
  	this.dragAlreadyMoved = true;
  }


  allDayEventDragMove() {
  	this.dragAlreadyMoved = true;
  }


  dragEnded(
  	weekEvent: WeekViewAllDayEvent | WeekViewTimeEvent,
  	dragEndEvent: DragEndEvent,
  	dayWidth: number,
  	useY = false
  ): void {
  	this.view = this.getWeekView(this.events);
  	this.dragActive = false;
  	this.validateDrag = null;
  	const { start, end } = this.getDragMovedEventTimes(
  		weekEvent,
  		dragEndEvent,
  		dayWidth,
  		useY
  	);
  	if (
  		(this.snapDraggedEvents ||
        this.eventDragEnterByType[useY ? 'time' : 'allDay'] > 0) &&
      isDraggedWithinPeriod(start, end, this.view.period)
  	) {
  		this.lastDraggedEvent = weekEvent.event;
  		this.eventTimesChanged.emit({
  			newStart: start,
  			newEnd: end,
  			event: weekEvent.event,
  			type: CalendarEventTimesChangedEventType.Drag,
  			allDay: !useY,
  		});
  	}
  }

  protected refreshHeader(): void {
  	this.days = this.utils.getWeekViewHeader({
  		viewDate: this.viewDate,
  		weekStartsOn: this.weekStartsOn,
  		excluded: this.excludeDays,
  		weekendDays: this.weekendDays,
  		...getWeekViewPeriod(
  			this.dateAdapter,
  			this.viewDate,
  			this.weekStartsOn,
  			this.excludeDays,
  			this.daysInWeek
  		),
  	});
  }

  protected refreshBody(): void {
  	this.view = this.getWeekView(this.events);
  }

  protected refreshAll(): void {
  	this.refreshHeader();
  	this.refreshBody();
  	this.emitBeforeViewRender();
  }

  protected emitBeforeViewRender(): void {
  	if (this.days && this.view) {
  		this.beforeViewRender.emit({
  			header: this.days,
  			...this.view,
  		});
  	}
  }

  protected getWeekView(events: CalendarEvent[]) {
  	return this.utils.getWeekView({
  		events,
  		viewDate: this.viewDate,
  		weekStartsOn: this.weekStartsOn,
  		excluded: this.excludeDays,
  		precision: this.precision,
  		absolutePositionedEvents: true,
  		hourSegments: this.hourSegments,
  		dayStart: {
  			hour: this.dayStartHour,
  			minute: this.dayStartMinute,
  		},
  		dayEnd: {
  			hour: this.dayEndHour,
  			minute: this.dayEndMinute,
  		},
  		segmentHeight: this.hourSegmentHeight,
  		weekendDays: this.weekendDays,
  		...getWeekViewPeriod(
  			this.dateAdapter,
  			this.viewDate,
  			this.weekStartsOn,
  			this.excludeDays,
  			this.daysInWeek
  		),
  	});
  }

  protected getDragMovedEventTimes(
  	weekEvent: WeekViewAllDayEvent | WeekViewTimeEvent,
  	dragEndEvent: DragEndEvent | DragMoveEvent,
  	dayWidth: number,
  	useY: boolean
  ) {
  	const daysDragged = roundToNearest(dragEndEvent.x, dayWidth) / dayWidth;
  	const minutesMoved = useY
  		? getMinutesMoved(
  			dragEndEvent.y,
  			this.hourSegments,
  			this.hourSegmentHeight,
  			this.eventSnapSize
  		)
  		: 0;

  	const start = this.dateAdapter.addMinutes(
  		addDaysWithExclusions(
  			this.dateAdapter,
  			weekEvent.event.start,
  			daysDragged,
  			this.excludeDays
  		),
  		minutesMoved
  	);
  	let end: Date;
  	if (weekEvent.event.end) {
  		end = this.dateAdapter.addMinutes(
  			addDaysWithExclusions(
  				this.dateAdapter,
  				weekEvent.event.end,
  				daysDragged,
  				this.excludeDays
  			),
  			minutesMoved
  		);
  	}

  	return { start, end };
  }

  protected restoreOriginalEvents(
  	tempEvents: CalendarEvent[],
  	adjustedEvents: Map<CalendarEvent, CalendarEvent>,
  	snapDraggedEvents = true
  ) {
  	const previousView = this.view;
  	if (snapDraggedEvents) {
  		this.view = this.getWeekView(tempEvents);
  	}

  	const adjustedEventsArray = tempEvents.filter((event) =>
  		adjustedEvents.has(event)
  	);
  	this.view.hourColumns.forEach((column, columnIndex) => {
  		previousView.hourColumns[columnIndex].hours.forEach((hour, hourIndex) => {
  			hour.segments.forEach((segment, segmentIndex) => {
  				column.hours[hourIndex].segments[segmentIndex].cssClass =
            segment.cssClass;
  			});
  		});

  		adjustedEventsArray.forEach((adjustedEvent) => {
  			const originalEvent = adjustedEvents.get(adjustedEvent);
  			const existingColumnEvent = column.events.find(
  				(columnEvent) =>
  					columnEvent.event ===
            (snapDraggedEvents ? adjustedEvent : originalEvent)
  			);
  			if (existingColumnEvent) {
  				// restore the original event so trackBy kicks in and the dom isn't changed
  				existingColumnEvent.event = originalEvent;
  				existingColumnEvent['tempEvent'] = adjustedEvent;
  				if (!snapDraggedEvents) {
  					existingColumnEvent.height = 0;
  					existingColumnEvent.width = 0;
  				}
  			} else {
  				// add a dummy event to the drop so if the event was removed from the original column the drag doesn't end early
  				const event = {
  					event: originalEvent,
  					left: 0,
  					top: 0,
  					height: 0,
  					width: 0,
  					startsBeforeDay: false,
  					endsAfterDay: false,
  					tempEvent: adjustedEvent,
  				};
  				column.events.push(event);
  			}
  		});
  	});
  	adjustedEvents.clear();
  }

  protected getTimeEventResizedDates(
  	calendarEvent: CalendarEvent,
  	resizeEvent: ResizeEvent
  ) {
  	const minimumEventHeight = getMinimumEventHeightInMinutes(
  		this.hourSegments,
  		this.hourSegmentHeight
  	);
  	const newEventDates = {
  		start: calendarEvent.start,
  		end: getDefaultEventEnd(
  			this.dateAdapter,
  			calendarEvent,
  			minimumEventHeight
  		),
  	};
  	const { end, ...eventWithoutEnd } = calendarEvent;
  	const smallestResizes = {
  		start: this.dateAdapter.addMinutes(
  			newEventDates.end,
  			minimumEventHeight * -1
  		),
  		end: getDefaultEventEnd(
  			this.dateAdapter,
  			eventWithoutEnd,
  			minimumEventHeight
  		),
  	};

  	if (typeof resizeEvent.edges.left !== 'undefined') {
  		const daysDiff = Math.round(
  			+resizeEvent.edges.left / this.dayColumnWidth
  		);
  		const newStart = addDaysWithExclusions(
  			this.dateAdapter,
  			newEventDates.start,
  			daysDiff,
  			this.excludeDays
  		);
  		if (newStart < smallestResizes.start) {
  			newEventDates.start = newStart;
  		} else {
  			newEventDates.start = smallestResizes.start;
  		}
  	} else if (typeof resizeEvent.edges.right !== 'undefined') {
  		const daysDiff = Math.round(
  			+resizeEvent.edges.right / this.dayColumnWidth
  		);
  		const newEnd = addDaysWithExclusions(
  			this.dateAdapter,
  			newEventDates.end,
  			daysDiff,
  			this.excludeDays
  		);
  		if (newEnd > smallestResizes.end) {
  			newEventDates.end = newEnd;
  		} else {
  			newEventDates.end = smallestResizes.end;
  		}
  	}

  	if (typeof resizeEvent.edges.top !== 'undefined') {
  		const minutesMoved = getMinutesMoved(
        resizeEvent.edges.top as number,
        this.hourSegments,
        this.hourSegmentHeight,
        this.eventSnapSize
  		);
  		const newStart = this.dateAdapter.addMinutes(
  			newEventDates.start,
  			minutesMoved
  		);
  		if (newStart < smallestResizes.start) {
  			newEventDates.start = newStart;
  		} else {
  			newEventDates.start = smallestResizes.start;
  		}
  	} else if (typeof resizeEvent.edges.bottom !== 'undefined') {
  		const minutesMoved = getMinutesMoved(
        resizeEvent.edges.bottom as number,
        this.hourSegments,
        this.hourSegmentHeight,
        this.eventSnapSize
  		);
  		const newEnd = this.dateAdapter.addMinutes(
  			newEventDates.end,
  			minutesMoved
  		);
  		if (newEnd > smallestResizes.end) {
  			newEventDates.end = newEnd;
  		} else {
  			newEventDates.end = smallestResizes.end;
  		}
  	}

  	return newEventDates;
  }

  protected resizeStarted(eventsContainer: HTMLElement, minWidth?: number) {
  	this.dayColumnWidth = this.getDayColumnWidth(eventsContainer);
  	const resizeHelper: CalendarResizeHelper = new CalendarResizeHelper(
  		eventsContainer,
  		minWidth
  	);
  	this.validateResize = ({ rectangle }) =>
  		resizeHelper.validateResize({ rectangle });
  	this.cdr.markForCheck();
  }

  private filterItems(items, searchVal) {
  	return items.filter((item) => Object.values(item).includes(searchVal));
  }

  handleMoreEvent(e: any, events: any[], currentDate: Date, calendarEvent: CalendarEvent) {
  	this.moreweekEvents = [];
  	const data = events.map(m => m.event.meta).map(p => p.requestId)
  		.filter((v, i, a) => a.indexOf(v) === i);

  	const startdate = calendarEvent.start;
  	this.moreweekEvents = this.pendingAndAllocated.filter(pa =>
  		data.includes(pa.requestId));

  	this.currentDate = currentDate;
  	const allocationWithoutRequest = events.filter(e => !e.event.meta.requestId)
  		.map(e => e.event.meta);
  	if (allocationWithoutRequest) {
  		const dummyRequest: PendingAndAllocatedViewModel = {
  			allocations: allocationWithoutRequest
  		} as PendingAndAllocatedViewModel;

  		this.moreweekEvents.push(dummyRequest);
  	}

  	const dialogData = this.moreweekEvents.map(me => {
  		me.allocations = me.allocations.filter(a =>
  			events.find(e => e.event.meta.allocationNumber == a.allocationNumber
          && e.event.start <= startdate
          && (e.event.end >= startdate))); return me;
  	});
  	const dialogRef = this.dialog.open(EventCalendarMoreDialogComponent, {
  		maxHeight: '80%',
  		maxWidth: '80%',
  		data: { allocate: dialogData }
  	});
  }

  handleeditEvent(action: string, event: CalendarEvent): void {
  	this.selectedNavItem(event.title);
  	//this.modal.open(this.modalContent, { size: 'lg' });
  }
  selectedNavItem(item: string) {
  	this.eventCalendarService.emitNavChangeEvent(item);
  }

  getLeft(index: number): number {
  	const padding = this.isDayView ? 20 : 1;
  	return (this.eventWidth * index) + padding;
  }

  displayMoreCount(e: WeekViewTimeEvent, events: any[]): string {
  	if (e != undefined && e != null) {
  		const startdate = e.event.start;
  		const eventlist = events.filter(m =>
  			m.event.start <= startdate
        && m.event.end >= startdate);
  		if (eventlist.length - this.eventThreshold > 0)
  			return '+' + (eventlist.length - this.eventThreshold);
  	}
  }

  getEventsForHourSegment(segmentIndex: number, hourIndex: number): any[] {

  	const currentDate = this.view.hourColumns[0].hours[hourIndex].segments[segmentIndex].date;
  	const nextSegDate = currentDate;
  	nextSegDate.setMinutes(nextSegDate.getMinutes() + 30);
  	//get events which comes under 30 minutes of segment with current date
  	const events = this.events.filter(x => x.start >= this.view.hourColumns[0].hours[hourIndex].segments[segmentIndex].date
      && x.start < nextSegDate);

  	events.push(...this.events.filter(x => x.start < this.view.hourColumns[0].hours[hourIndex].segments[segmentIndex].date
      && x.end > this.view.hourColumns[0].hours[hourIndex].segments[segmentIndex].date));
  	return events;
  }

  getWeekEvent() {
  	const columnEvents = this.view.hourColumns;
  	this.weekDays = {};
  	for (let i = 0; i < columnEvents.length; i++) {
  		const date = columnEvents[i].date;
  		const event = this.getGroupOfEvents(columnEvents[i].events, date);
  		this.weekDays[date.toDateString()] = event;
  	}
  }

  getGroupOfEvents(columnEvents: WeekViewTimeEvent[], currentDate: Date): WeekDayViewEvents[] {
  	const weekdayEvents = [];
  	for (let i = 0; i < columnEvents.length; i++) {
  		const minutes = columnEvents[i].event.start.getMinutes();
  		const day = columnEvents[i].event.start.getDate();
  		let startdate;
  		if (day < currentDate.getDate() || (columnEvents[i].event.start.getHours() == 0 && columnEvents[i].event.start.getMinutes() >= 0) && columnEvents[i].event.start.getMinutes() < 30) {
  			startdate = currentDate;
  		}
  		else {
  			if (minutes < 30) {
  				startdate = this.dateAdd(columnEvents[i].event.start, 'minute', -minutes);
  				columnEvents[i].top -= minutes;
  			}
  			else {
  				startdate = this.dateAdd(columnEvents[i].event.start, 'minute', (31 - minutes));
  				columnEvents[i].top += (31 - minutes);
  			}
  			//columnEvents[i].event.meta.start = startdate;
        
  		}
  		const eventExists = weekdayEvents.filter(x => Date.parse(x.requestDate.toString()) == Date.parse(startdate)).length;
  		if (!eventExists) {
  			var weekEvent = new WeekDayViewEvents();
  			weekEvent.requestDate = startdate;
  			weekEvent.events = [];
  			weekEvent.events.push(columnEvents[i]);
  			weekdayEvents.push(weekEvent);
  		}
  		else {
  			const existingEvent = weekdayEvents.find(x => Date.parse(x.requestDate.toString()) == Date.parse(startdate));
  			if (existingEvent != null)
  				existingEvent.events.push(columnEvents[i]);
  			else {
  				var weekEvent = new WeekDayViewEvents();
  				weekEvent.requestDate = startdate;
  				weekEvent.events = [];
  				weekEvent.events.push(columnEvents[i]);
  			}
  		}
  	}
  	return weekdayEvents;
  }
  dateAdd(date, interval, units) {
  	if (!(date instanceof Date))
  		return undefined;
  	let ret = new Date(date); //don't change original date
  	const checkRollover = function () { if (ret.getDate() != date.getDate()) ret.setDate(0); };
  	switch (String(interval).toLowerCase()) {
  	case 'year': ret.setFullYear(ret.getFullYear() + units); checkRollover(); break;
  	case 'quarter': ret.setMonth(ret.getMonth() + 3 * units); checkRollover(); break;
  	case 'month': ret.setMonth(ret.getMonth() + units); checkRollover(); break;
  	case 'week': ret.setDate(ret.getDate() + 7 * units); break;
  	case 'day': ret.setDate(ret.getDate() + units); break;
  	case 'hour': ret.setTime(ret.getTime() + units * 3600000); break;
  	case 'minute': ret.setTime(ret.getTime() + units * 60000); break;
  	case 'second': ret.setTime(ret.getTime() + units * 1000); break;
  	default: ret = undefined; break;
  	}
  	return ret;
  }

}
