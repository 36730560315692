import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { AllocationRequestVm, InitModel, AllocationRequestUpdateModel, HeapVm, ApiPageResponse, DepotVm, UomVm } from '../gen/models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { InitService } from '../services/init.service';
import { of } from 'rxjs';
import { startWith, switchMap, debounceTime, pairwise } from 'rxjs/operators';
import { HeapsService } from '../gen/services/heaps.service';
import { DepotService } from '../gen/services/depot.service';
import { isNullOrUndefined } from '../tools';
import { UomsService } from '../gen/services/uoms.service';
import * as moment from 'moment';
import { CustomValidators } from '../shared/custom-validators';
import { ToastrService } from 'ngx-toastr';
import { DateAdapter } from '@angular/material/core';
import { SpinnerComponent } from '../spinner/spinner.component';
import { AllocationService } from '../gen/services/allocation.service';

@Component({
    selector: 'app-edit-request-dialog',
    templateUrl: './edit-request-dialog.component.html',
    styleUrls: ['./edit-request-dialog.component.scss']
})
export class EditRequestDialogComponent implements OnInit {
    editAllocationForm: FormGroup;
    pageModel: InitModel;
    increments = ['15 minutes', '20 minutes', '25 minutes', '30 minutes', '45 minutes', '60 minutes', '90 minutes', '120 minutes'];
    incrementMinutes = [15, 20, 25, 30, 45, 60, 90, 120];
    uoms: UomVm[];
    @ViewChild('loadingEditRequestDialogSpinner') loadingEditRequestDialogSpinner: SpinnerComponent;
    loadingEditRequestDialog = false;
    isSubmit = false;
    originalHeapNo: number;
    originalDepot: string;
    disableForm: boolean;

    constructor(
        public dialogRef: MatDialogRef<EditRequestDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AllocationRequestVm,
        private fb: FormBuilder,
        private initService: InitService,
        private depotsService: DepotService,
        private heapService: HeapsService,
        private uomsService: UomsService,
        private toastr: ToastrService,
        private _adapter: DateAdapter<any>,
        private allocationService: AllocationService) { }
    statuses = [];
    filteredDepots: ApiPageResponse<DepotVm>;
    filteredHeaps: ApiPageResponse<HeapVm>;
    disableAnimation = true;

    get depot() {
        return this.editAllocationForm.get('depot');
    }
    get readyDate() {
        return this.editAllocationForm.get('readyDate');
    }
    
    get expiryDate() {    return this.editAllocationForm.get('expiryDate'); }
    get heap() {
        return this.editAllocationForm.get('heap');
    }
    get gradedescription() {
        return this.editAllocationForm.get('gradedescription');
    }
    get gradedescriptionplaceholder() {
        const gradeDescriptionControl = this.editAllocationForm.get('gradedescription');
        let remainingCharacters = 500;
        if (gradeDescriptionControl && gradeDescriptionControl.value) {
            remainingCharacters = remainingCharacters - gradeDescriptionControl.value.length;
        }
        return `Material description (${remainingCharacters} / 500)`;
    }
    get weight() {
        return this.editAllocationForm.get('weight');
    }
    get remainingWeight() {
        return this.editAllocationForm.get('remainingWeight');
    }
    get uom() {
        return this.editAllocationForm.get('uom');
    }
    get containerSize() {
        return this.editAllocationForm.get('containerSize');
    }
    get packaging() {
        return this.editAllocationForm.get('packaging');
    }
    get status() {
        return this.editAllocationForm.get('status');
    }
    get remainingLoads() {
        return this.editAllocationForm.get('remainingLoads');
    }
    get increment() {
        return this.editAllocationForm.get('increment');
    }
    get loadTimes() {
        return this.editAllocationForm.get('loadTimes') as FormArray;
    }
    get totalLoads() {
        return this.editAllocationForm.get('totalLoads');
    }

    onNoClick(): void {
        this.dialogRef.close();
    }


    ngOnInit() {
        this._adapter.setLocale(window.navigator.language);
        this.disableForm = !(this.data?.allocations?.filter(x => x.allocationNumber != -1).length <= 0);
        this.editAllocationForm = this.fb.group({
            depot: [{ value: '', disabled: this.disableForm }],
            readyDate: [{ value: '', disabled: this.disableForm }, Validators.required],
            expiryDate: [{ value: '', disabled: this.disableForm }],
            heap: [{ value: '', disabled: this.disableForm }],
            gradedescription: [{ value: '', disabled: this.disableForm }],
            weight: [{ value: '', disabled: this.disableForm }, [Validators.pattern('^[0-9]{1,7}(\.[0-9]+)?$'), Validators.max(this.data.weight),Validators.required]],
            remainingWeight: ['', [Validators.pattern('^[0-9]{1,7}(\.[0-9]+)?$')]],
            uom: [{ value: '', disabled: this.disableForm }],
            containerSize: [''],
            packaging: [''],
            status: ['', Validators.required],
            remainingLoads: ['', [Validators.required, Validators.min(0)]],
            totalLoads: [{ value: '', disabled: this.disableForm }, Validators.required],
            loadTimes: this.fb.array([]),
            increment: ['']
        }, {
            validators: [CustomValidators.requiredWhenSet('uom', 'remainingWeight', 'Unit', 'Remaining Weight'), 
                CustomValidators.dateLessThan('readyDate', 'expiryDate')]
        });

        this.initService.initData$.subscribe(r => {
            this.pageModel = r;
            this.setData();
        });

        this.uomsService.getAll().subscribe(r => this.uoms = r);

        this.depot.valueChanges
            .pipe(
                debounceTime(500),
                startWith(null),
                switchMap(v => this.filterDepots(v)))
            .subscribe(r => this.filteredDepots = r);

        this.heap.valueChanges
            .subscribe((h: HeapVm) => {
                if (!isNullOrUndefined(h) && h.heapNo !== this.originalHeapNo && this.gradedescription.pristine) {
                    this.gradedescription.patchValue(h.heapDesc);
                }
            });

        this.depot.valueChanges
            .subscribe((h: DepotVm) => {
                if (!isNullOrUndefined(h) && this.heap.pristine  && h.depotNumber !== this.originalDepot ) {
                    this.heap.patchValue(null);
                }
            });

        this.heap.valueChanges
            .pipe(
                debounceTime(500),
                startWith(null),
                switchMap(v => this.filterHeaps(v)
                )
            )
            .subscribe((r: ApiPageResponse<HeapVm>) => {
                if (!isNullOrUndefined(r)) {
                    this.filteredHeaps = r;
                }
            });

        this.remainingWeight.valueChanges
            .pipe(
                startWith(this.remainingWeight.value),
                pairwise())
            .subscribe(([prev, next]) => this.recalculateTotalWeight(prev, next));

        this.remainingLoads.valueChanges
            .pipe(startWith(this.remainingLoads.value), pairwise())
            .subscribe(([prev, next]) => this.addRemoveLoadTimes(prev, next, this.editAllocationForm));


    }

    ngAfterViewInit(): void {
        // having an expansion panel inside dialog the panel animates on loading, workaround taken from https://github.com/angular/components/issues/13870
        // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
        setTimeout(() => this.disableAnimation = false);
    }

    getUom(id: number): string {
        return 'Tonne';
    }

    setData() {
        this.readyDate.patchValue(this.data.readyDate);
        this.expiryDate.patchValue(this.data.expiryDate);
        this.gradedescription.patchValue(this.data.gradeDesc);
        this.weight.patchValue(this.data.weight);
        this.remainingWeight.patchValue(this.data.remainingWeight);
        this.uom.patchValue(this.data.uomId);
        this.containerSize.patchValue(this.data.containerSizeId);
        this.packaging.patchValue(this.data.packageOptionId);
        this.status.patchValue(this.data.statusId);
        this.remainingLoads.patchValue(this.data.remainingLoads);
        this.totalLoads.patchValue(this.data.totalLoads);
        if (isNullOrUndefined(this.data.depotName)) {
            this.depotsService.searchDepots(this.data.depot).subscribe(r => {
                const depot = r.items[0];
                this.originalDepot = depot.depotNumber;
                this.depot.setValue({ depotNumber: depot.depotNumber, depotName: depot.depotName });
            });
        } else {
            this.originalDepot = this.data.depot;
            this.depot.setValue({ depotNumber: this.data.depot, depotName: this.data.depotName });
        }

        if (this.data.remainingLoads > 1) {
            if (this.data.loadTimeIncrements) {
                this.increment.patchValue(this.data.loadTimeIncrements);
            }
        }

        if (this.data.loadTimes) {
            const ltArr = this.data.loadTimes.split(',');
            ltArr.forEach(function (obj, key) {
                this.loadTimes.push(this.fb.group({ time: [{ value: obj, disabled: false }] }));
            }, this);
        }
        else {
            for (let index = 0; index < this.data.remainingLoads; index++) {
                this.loadTimes.push(this.fb.group({ time: [{ value: '', disabled: false }] }));
            }
        }
        if (this.data.heapNo) { //todo remove this by stopping to pass the heapNo. Just pass a HeapVm as part of this.data
            this.originalHeapNo = this.data.heapNo;
            this.heapService.getHeap(this.data.heapNo).subscribe(h => {
                if (!isNullOrUndefined(h)) {
                    this.heap.setValue(h);
                }
            });
        }
    }

    cancel() {
        this.dialogRef.close();
    }

    ok() {
        const value = this.editAllocationForm.value;
        if (this.editAllocationForm.valid) {
            const id = this.data.id ? this.data.id : this.data.requestId;
            const model: AllocationRequestUpdateModel = {
                readyDate: moment(value.readyDate).format('YYYY-MM-DD'),
                expiryDate: !value.expiryDate? null : moment(value.expiryDate).format('YYYY-MM-DD'),
                materialDescription: value.gradedescription ?? this.editAllocationForm.controls.gradedescription.value,
                weight: this.editAllocationForm.controls.weight.value, //readonly value passed back here even when disabled
                remainingWeight: value.remainingWeight,
                uomId: value.uom,
                containerSize: { id: value.containerSize, displayName: null },
                packageOption: { id: value.packaging, displayName: null },
                status: { id: value.status, name: null, isActive: true },
                remainingLoads: value.remainingLoads,
                loadTimes: value.loadTimes,
                loadTimeIncrements: value.increment,
                totalLoads: this.editAllocationForm.controls.totalLoads.value,
                depot: this.editAllocationForm.controls.depot.value,
                heap: this.editAllocationForm.controls.heap.value
            };
            this.isSubmit = true;
            this.loadingEditRequestDialog = true;
            this.allocationService.updateRequest(model, id).subscribe(a => {
                this.success('Request successfully edited', 'Edit Request');
                this.closeDialog();
            }, err => {
                if (err.error != null && err.error.status === 200) {
                    this.success('Request successfully edited', 'Edit Request');
                    this.closeDialog();
                } else {
                    this.toastr.error('Something went wrong and the request could not be allocated. Please try again later.', 'Edit Failed');
                    this.isSubmit = false;
                }
                this.loadingEditRequestDialog = false;
            });
        } else {
            if (!isNullOrUndefined(this.editAllocationForm.errors)) {
                if(!isNullOrUndefined(this.editAllocationForm.errors.unset)){
                    this.toastr.error(this.editAllocationForm.errors.unset, 'Form Invalid');
                }
                if(!isNullOrUndefined(this.editAllocationForm.errors.dates)){
                    this.toastr.error(this.editAllocationForm.errors.dates, 'Form Invalid');
                }
            }
        }
    }

    ngOnDestroy(): void {
        if (this.loadingEditRequestDialogSpinner !== undefined)
            this.loadingEditRequestDialogSpinner.overlayRef.dispose();
    }

    private closeDialog() {
        setTimeout(() => {
            this.loadingEditRequestDialog = false;
            this.dialogRef.close();
        }, 1500);
    }

    private success(message: string, title: string) {
        this.toastr.success(message, title);
    }


    filterDepots(val: string) {
        return this.depotsService.searchDepots(val);
    }
    displayDepot(depot?: DepotVm): string | undefined {
        return depot ? depot.depotNumber + ' ' + depot.depotName : undefined;
    }

    filterHeaps(searchTerm: string) {
        const formDepot = this.depot.value as DepotVm;
        if (!this.heap.pristine && !isNullOrUndefined(formDepot) && !isNullOrUndefined(formDepot.depotNumber) && formDepot.depotNumber !== '' && !isNullOrUndefined(searchTerm) && typeof (searchTerm) === 'string') {
            return this.heapService.search(formDepot.depotNumber, searchTerm);
        }

        return of();
    }

    displayHeap(heap?: HeapVm): string | undefined {
        return heap ? heap.heapShortName + ' ' + heap.heapDesc : undefined;
    }

    toUpperCase(event: any) {
        event.target.value = event.target.value.toUpperCase();
    }

    private addRemoveLoadTimes(oldLoads: number, newLoads: number, group: FormGroup) {
        const loadTimes = group.controls.loadTimes as FormArray;
        const currentLoadCount = loadTimes.controls.length;
        if (newLoads < currentLoadCount) {
            loadTimes.controls.splice(newLoads - 1, currentLoadCount - newLoads);
        }
        if (newLoads > currentLoadCount) {
            for (let i = 0; i < newLoads - currentLoadCount; i++) {
                loadTimes.push(this.fb.group({ time: [''] }));
            }
        }
        const updateLoads = this.totalLoads.value - (oldLoads - newLoads);
        this.totalLoads.patchValue(updateLoads);
    }

    getFirstLoadTimeValue() {
        if (!isNullOrUndefined(this.loadTimes) && !isNullOrUndefined(this.loadTimes.controls) && !isNullOrUndefined(this.loadTimes.controls[0].value) && !isNullOrUndefined(this.loadTimes.controls[0].value.time))
            return this.loadTimes.controls[0].value.time;
        return null;
    }

    setIncrements(increment: string) {
        const incrementIndex = this.increments.indexOf(increment);
        if (incrementIndex > -1) {
            const minutesToAdd = this.incrementMinutes[incrementIndex];
            const originalTime = this.getFirstLoadTimeValue();
            const originalMoment = moment.utc(originalTime, 'HH:mm');

            this.loadTimes.controls.forEach((control, index) => {
                if (index == 0) //skip first one since that's the value we're basing increments off
                    return;
                const incrementedTime = originalMoment.add(minutesToAdd, 'minutes').format('HH:mm');
                control.patchValue({ time: incrementedTime });
            });
        }
    }

    private recalculateTotalWeight(oldRemainingWeight: number, newRemainingWeight: number) {
        const newTotalWeight = this.weight.value - (oldRemainingWeight - newRemainingWeight);
        this.weight.patchValue(newTotalWeight);
    }

    pickupTimeChange(event: Event, index: number) {
        if (index) {
            return;
        }

        const input = event.target as HTMLInputElement;
        
        if (!isNullOrUndefined(this.loadTimes) && !isNullOrUndefined(this.loadTimes.controls) && this.loadTimes.controls.length > 1) {
            if (this.increment && this.increment.value) {
                this.setIncrements(this.increment.value);
            } else {
                this.loadTimes.controls.forEach(c => {
                    c.patchValue({ time: input.value });
                });    
            }
        }
    }
}
