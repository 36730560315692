import { Component, OnInit } from '@angular/core';
import * as _ from 'underscore';
import { ConversationService } from '../gen/services/conversation.service';
import { Conversation, PredefinedComment, PendingAndAllocatedViewModel } from '../gen/models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SidenavService } from '../services/sidenav.service';
import { AttachmentsService } from '../gen/services/attachments.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
@Component({
    selector: 'app-conversation-view',
    templateUrl: './conversation-view.component.html',
    styleUrls: ['./conversation-view.component.scss']
})
export class ConversationViewComponent implements OnInit {
    loading = true;
    allocation: PendingAndAllocatedViewModel;
    conversation: Conversation;
    predefinedComments: PredefinedComment[] = [];
    comment = { message: '' };
    commentForm: FormGroup;
    authors = [];

    constructor(private conversationService: ConversationService,
    private fb: FormBuilder,
    private sidenavService: SidenavService,
    private attachmentsService: AttachmentsService,
    private dialog: MatDialog) { }

    ngOnInit() {
        this.conversationService.getPredefinedCommentList()
            .subscribe(p => {
                this.predefinedComments = p;
            });
        this.sidenavService.selectedAllocation$.subscribe(a => {
            this.allocation = a.item;
            this.getConversation();
        });

        this.commentForm = this.fb.group({
            newComment: ['', Validators.required]
        });
    }

    getMessageClass(message) {
        if (message) {
            return _.indexOf(this.authors, message.author) % 2 === 0 ? 'other' : 'self';
        }
    }

    getAuthorIndex(author): string {
        const index = _.indexOf(this.authors, author) % 7;
        if (index > -1) {
            return `${index}`;
        }
        return '';
    }

    sendPredefinedMessage(message) {
        this.sendMessage(message, true);
    }

    sendMessage(message, isPredefined) {
        if (message.length > 0) {
            this.conversationService.addComment({
                body: message, timestamp: new Date().toUTCString()
            }, this.allocation.requestId).subscribe(r => {
                if (!isPredefined) {
                    this.commentForm.reset();
                }
                this.getConversation(true);
            });
        }
    }

    processConversation(conversation) {
        this.authors = _.uniq(_.pluck(conversation.messages, 'author'));
        this.loading = false;
        this.conversation = conversation;
    }

    getConversation(hasAddedMessage = false) {
        if (this.allocation.requestId) {
            this.loading = true;
            this.conversationService.getPendingRequestConversation(this.allocation.requestId)
                .subscribe(c => {
                    this.processConversation(c);
                    if (hasAddedMessage) {
                        this.sidenavService.itemAdded(c.messages.slice(0)[0]);
                    }
                });
        }
    }
    uploadFile(files: FileList) {
        this.loading = true;
        this.attachmentsService
            .upload(this.allocation.requestId, files.item(0))
            .subscribe(() => {
                this.loading = false;
                const dialogRef = this.dialog.open(AlertDialogComponent, {
                    minWidth: '240px',
                    maxWidth: '80%',
                    maxHeight: '80%',
                    data: {
                        ok: 'Ok',
                        aria: 'Attached Successfully',
                        title: 'Attached Successfully',
                        body: 'The photo has successfully been attached to the request.'
                    }
                });
                dialogRef.afterClosed().subscribe(r => {
                    this.sidenavService.close({ closed: true });
                });
            },
            err => {
                this.loading = false;
                this.dialog.open(AlertDialogComponent, {
                    minWidth: '240px',
                    maxWidth: '80%',
                    maxHeight: '80%',
                    data: {
                        ok: 'Ok',
                        aria: 'Attached Failed',
                        title: 'Attached Failed',
                        body: 'The photo has not been attached to the request. Please try again later.'
                    }
                });
            });
    }

    sendConversationAsEmail() {
        this.conversationService.sendConversationAsEmail(this.allocation.requestId).subscribe(() => {
            this.dialog.open(AlertDialogComponent, {
                minWidth: '240px',
                maxWidth: '80%',
                maxHeight: '80%',
                data: {
                    ok: 'Ok',
                    aria: 'Email Sent',
                    title: 'Email Sent',
                    body: 'You should receive an email containing a transcript in the next couple of minutes'
                }
            });
        },
        err => {
            this.dialog.open(AlertDialogComponent, {
                minWidth: '240px',
                maxWidth: '80%',
                maxHeight: '80%',
                data: {
                    ok: 'Ok',
                    aria: 'Email Failed',
                    title: 'Email Failed',
                    body: 'Something went wrong and the email could not be sent. Please try again later.'
                }
            });
        });
    }

    commentPlaceholder() {
        const newCommentControl = this.commentForm.get('newComment');
        if (newCommentControl && newCommentControl.value &&  newCommentControl.value.length) {
            return `Enter comment (${newCommentControl.value.length} / 500)`;
        }
        
        return 'Enter comment';
    }
}
