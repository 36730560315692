/* tslint:disable */

export class haulagePriceMatrixVM {
	id: number;
	currency: string;
	shippingLine: string;
	haulageRate: number;
	containerTypeId: number;
	allocationFromDate?: Date;
	allocationToDate?: Date;
	contractLineId: number;
	toLocationCodes: string[] = [];
	fromLocationCode: string;
	haulierName: string;
	fromLocationName: string;
	toLocationName: string;   
	partyDeliveryPointIds: number[] = []; 
	partyAccountNo: string;  
	haulierAccountNo: string;
}