import { Injectable } from '@angular/core';
import { getCurrencySymbol } from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class CurrencySymbolService {

	getCurrencySymbol(code: string, returnCodeAsSymbol = true, format?: 'wide' | 'narrow', locale?: string): string {
		code = code?.toUpperCase();
		if (code === 'BAHT') {
			code = 'THB';
		} else if (code === 'SFR') {
			code = 'CHF';
		}

		let currencySymbol =  getCurrencySymbol(code, format ?? 'narrow', locale);
		if (!currencySymbol && returnCodeAsSymbol) {
			currencySymbol = code;
		}

		return currencySymbol;
	}
}
