import { Inject, Injectable } from '@angular/core';
import { HttpClient,  HttpHeaders, HttpParams } from '@angular/common/http';
import { REST_CONFIG_TOKEN, RestConfigService } from '../rest-config-service';
import { Observable } from 'rxjs';
import {  map} from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import {
    InitModel,
    ContractLineAllocationLifecycle,
    NewAllocation,
    AllocationRequestVm,
    QueryModel,
    PagedPendingAndAllocatedViewModel,
    PagedAllocatedViewModel,
    AllocationRequestUpdateModel,
    LifecycleRequest,
} from '../models';
import { BulkUpdateModel } from '../models/BulkUpdateModel';
import { ContractLineQuerySearchModel } from '../models/ContractLineQuerySearchModel';
import { ContractLineSearchResult } from '../models/ContractLineSearchResult';
import { AllocationMissedDropOffUpdateModel } from '../models/AllocationMissedDropOffUpdateModel';
import { SchedulerReportType } from '../enums/enums';
import { AllocationFcfsUpdateModel } from '../models/AllocationFcfsUpdateModel';
import { NewInventoryReservation } from '../models/NewInventoryReservation';
import { InventoryReservationVm } from '../models/InventoryReservationVm';
import { EditInventoryReservationVm } from '../models/EditInventoryReservationVm';
import { BookingStatusRequest } from '../models/BookingStatusRequest';

@Injectable()
export class AllocationService {

    constructor(
        private _http: HttpClient,
        @Inject(REST_CONFIG_TOKEN) private _configService: RestConfigService
    ) {
    }

    public init: (
    ) => Observable<InitModel>
        = (
        ): Observable<InitModel> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            const uri = '/api/Allocation/init';

            return this._http
                .get<InitModel>(this._configService.apiBaseUrl() + uri,
                    { headers: headers, params: queryParameters });
        };
    public getDefaultTerritory: (
    ) => Observable<string>
        = (
        ): Observable<string> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            const uri = '/api/Allocation/DefaultTerritory';

            return this._http
                .get<string>(this._configService.apiBaseUrl() + uri,
                    { headers: headers, params: queryParameters });
        };
    public getStatuses: (
    ) => Observable<string[]>
        = (
        ): Observable<string[]> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            const uri = '/api/Allocation/statuses';

            return this._http
                .get<string[]>(this._configService.apiBaseUrl() + uri,
                    { headers: headers, params: queryParameters });
        };
    public createNewRequests: (
        model?: NewInventoryReservation
    ) => Observable<InventoryReservationVm[]>
        = (
            model?: NewInventoryReservation
        ): Observable<InventoryReservationVm[]> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            const uri = '/api/Allocation/newrequest';

            return this._http
                .post<InventoryReservationVm[]>(this._configService.apiBaseUrl() + uri, JSON.stringify(model),
                    { headers: headers, params: queryParameters });
        };
    public createNewRequest: (
        model?: NewAllocation
    ) => Observable<AllocationRequestVm[]>
        = (
            model?: NewAllocation
        ): Observable<AllocationRequestVm[]> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            const uri = '/api/Allocation/new';

            return this._http
                .post<AllocationRequestVm[]>(this._configService.apiBaseUrl() + uri, JSON.stringify(model),
                    { headers: headers, params: queryParameters });
        };
    public getRequests: (
        q?: QueryModel
    ) => Observable<PagedPendingAndAllocatedViewModel>
        = (
            q?: QueryModel
        ): Observable<PagedPendingAndAllocatedViewModel> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            const uri = '/api/Allocation/requests';

            return this._http
                .post<PagedPendingAndAllocatedViewModel>(this._configService.apiBaseUrl() + uri, JSON.stringify(q),
                    { headers: headers, params: queryParameters });
        };

    public bulkUpdateRequests: (
        model: BulkUpdateModel
    ) => Observable<boolean>
        = (
            model: BulkUpdateModel
        ): Observable<boolean> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            const uri = '/api/Allocation/bulkUpdateRequests';

            return this._http
                .post<boolean>(this._configService.apiBaseUrl() + uri, JSON.stringify(model),
                    { headers: headers, params: queryParameters });
        };

    public getAllocations: (
        q?: QueryModel
    ) => Observable<PagedAllocatedViewModel>
        = (
            q?: QueryModel
        ): Observable<PagedAllocatedViewModel> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            const uri = '/api/Allocation';

            return this._http
                .post<PagedAllocatedViewModel>(this._configService.apiBaseUrl() + uri, JSON.stringify(q),
                    { headers: headers, params: queryParameters });
        };

    public exportRequestsToExcel(fileName: string, responseType: string,
        q?: QueryModel
    ): Observable<Object> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const uri = this._configService.apiBaseUrl() + '/api/Allocation/requests/export';
        return this._http.post(uri, JSON.stringify(q), {
            responseType: 'blob', headers
        })
            .pipe(map((response) => {
                const blob = new Blob([response], { type: responseType });
                FileSaver.saveAs(blob, fileName);
                return {};
            }));
    }
    public updateReservation: (
        model?: EditInventoryReservationVm,
        requestId?: number
    ) => Observable<string>
        = (
            model?: EditInventoryReservationVm,
            requestId?: number
        ): Observable<string> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });


            const uri = `/api/Allocation/update/${requestId}`;

            return this._http
                .put<string>(this._configService.apiBaseUrl() + uri, JSON.stringify(model),
                    { headers: headers, params: queryParameters });
        };
    public updateRequest: (
        model?: AllocationRequestUpdateModel,
        requestId?: number
    ) => Observable<string>
        = (
            model?: AllocationRequestUpdateModel,
            requestId?: number
        ): Observable<string> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });


            const uri = `/api/Allocation/request/${requestId}`;

            return this._http
                .put<string>(this._configService.apiBaseUrl() + uri, JSON.stringify(model),
                    { headers: headers, params: queryParameters });
        };
    public deleteRequest: (
        requestId?: number
    ) => Observable<void>
        = (
            requestId?: number
        ): Observable<void> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            const uri = `/api/Allocation/request/${requestId}`;

            return this._http
                .delete<any>(this._configService.apiBaseUrl() + uri,
                    { headers: headers, params: queryParameters });
        };
    public fullyAllocate: (
        requestId?: number
    ) => Observable<void>
        = (
            requestId?: number
        ): Observable<void> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            const uri = `/api/Allocation/fullyallocate/${requestId}`;

            return this._http
                .put<any>(this._configService.apiBaseUrl() + uri,
                    { headers: headers, params: queryParameters });
        };
    public exportAllocationsAndSave(fileName: string, responseType: string,
        q?: QueryModel
    ): Observable<Object> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const uri = this._configService.apiBaseUrl() + '/api/Allocation/export';
        return this._http.post(uri, JSON.stringify(q), {
            responseType: 'blob', headers
        })
            .pipe(map((response) => {
                const blob = new Blob([response], { type: responseType });
                FileSaver.saveAs(blob, fileName);
                return {};
            }));
    }

    public schedulerReportExport(fileName: string, responseType: string, q: QueryModel, type: SchedulerReportType): Observable<Object> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const uri = this._configService.apiBaseUrl() + `/api/Allocation/schedulerreport/${type}`;
        return this._http.post(uri, JSON.stringify(q), {
            responseType: 'blob', headers
        })
            .pipe(map((response) => {
                const blob = new Blob([response], { type: responseType });
                FileSaver.saveAs(blob, fileName);
                return {};
            }));
    }

    public ultimateSchedulerReportExport(fileName: string, responseType: string, q: QueryModel): Observable<Object> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const uri = this._configService.apiBaseUrl() + '/api/Allocation/ultimatescheduler';
        return this._http.post(uri, JSON.stringify(q), {
            responseType: 'blob', headers
        })
            .pipe(map((response) => {
                const blob = new Blob([response], { type: responseType });
                FileSaver.saveAs(blob, fileName);
                return {};
            }));
    }

    public getAllocationLifecycles: (
    ) => Observable<ContractLineAllocationLifecycle[]>
        = (
        ): Observable<ContractLineAllocationLifecycle[]> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            const uri = '/api/Allocation/lifecycle';

            return this._http
                .get<ContractLineAllocationLifecycle[]>(this._configService.apiBaseUrl() + uri,
                    { headers: headers, params: queryParameters });
        };
    public setAllocationLifecycle: (
        request?: LifecycleRequest
    ) => Observable<void>
        = (
            request?: LifecycleRequest
        ): Observable<void> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            const uri = '/api/Allocation/lifecycle';

            return this._http
                .put<any>(this._configService.apiBaseUrl() + uri, JSON.stringify(request),
                    { headers: headers, params: queryParameters });
        };

    public setAllocationBookingStatus: (
            request?: BookingStatusRequest
        ) => Observable<void>
        = (
            request?: BookingStatusRequest
        ): Observable<void> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    
            const uri = '/api/Allocation/bookingStatus';
    
            return this._http
                .put<any>(this._configService.apiBaseUrl() + uri, JSON.stringify(request),
                    { headers: headers, params: queryParameters });
        };

    public getExistingAllocationLifecycle: (ids: number[]
    ) => Observable<number[]>
        = (
            ids: number[]
        ): Observable<number[]> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            const uri = '/api/Allocation/existinglifecycle';

            return this._http
                .post<number[]>(this._configService.apiBaseUrl() + uri, JSON.stringify(ids),
                    { headers: headers, params: queryParameters });
        };

    public getContractLines: (model: ContractLineQuerySearchModel
    ) => Observable<ContractLineSearchResult[]>
        = (
            model: ContractLineQuerySearchModel
        ): Observable<ContractLineSearchResult[]> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            const uri = '/api/Allocation/getcontractlines';

            return this._http
                .post<ContractLineSearchResult[]>(this._configService.apiBaseUrl() + uri, JSON.stringify(model),
                    { headers: headers, params: queryParameters });
        };

    public updateMissedDropOff: (
        model?: AllocationMissedDropOffUpdateModel
    ) => Observable<string>
        = (
            model?: AllocationMissedDropOffUpdateModel
        ): Observable<string> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });


            const uri = '/api/Allocation/misseddropoff';

            return this._http
                .patch<string>(this._configService.apiBaseUrl() + uri, JSON.stringify(model),
                    { headers: headers, params: queryParameters });
        };
    public updateFcfs: (
        model?: AllocationFcfsUpdateModel
    ) => Observable<string>
        = (
            model?: AllocationFcfsUpdateModel
        ): Observable<string> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });


            const uri = '/api/Allocation/fcfs';

            return this._http
                .patch<string>(this._configService.apiBaseUrl() + uri, JSON.stringify(model),
                    { headers: headers, params: queryParameters });
        };
}

