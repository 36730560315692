/* tslint:disable */

export interface UserTableViewVm {
    id: number,
    viewName: string,
    table: UserPreferenceTableEnum,
    userId: number,
    isSelected: boolean,
    isDeleted: boolean,
    userTableViewConfigs: UserTableViewConfigVm[]
}

export interface UserTableViewConfigVm {
    id: number,
    columnName: string,
    isSelected: boolean,
    index: number,
}

export enum UserPreferenceTableEnum{
    Allocation = 1,
    Request = 2,
    RouteToMarket = 3
}