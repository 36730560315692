import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of, observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ADGroupService } from '../gen/services/adgroup.service';
import { ADGroupsModel } from '../gen/models/ADGroupsModel';

@Injectable({
	providedIn: 'root'
})
export class ADGroupGuardService implements CanActivate {

	constructor(private adGroupService: ADGroupService, private router: Router) { }
  
	canActivate(route: ActivatedRouteSnapshot): Observable<boolean>{
		const group = route.data.groupName;
		let canActivate = false;
		return this.adGroupService.getMyGroups().pipe(map(val => canActivate = val.myGroups.includes(group)));
	}
}
