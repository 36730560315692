import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { InstructionToMoveService } from '../gen/services/instruction-to-move.service';
import {
    InitModel, QueryModel,
    FilterNames, TerritoryVm, InstructionToMoveVm, AllocateModel
} from '../gen/models';
import { UomsService } from '../gen/services/uoms.service';
import { SortInfo } from '../table-sort/table-sort.directive';
import * as _ from 'underscore';
import { InitService } from '../services/init.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { SidenavService } from '../services/sidenav.service';
import { isNullOrUndefined, isUndefined } from '../tools';
import { EnumVals, RequestStatuses, IEnumItem, ListOfColumnNames } from '../gen/enums/enums';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AllocateService } from '../gen/services/allocate.service';
import { GradeService } from '../gen/services/grade.service';
import { ActionConfirmDialogComponent } from '../action-confirm-dialog/action-confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { SpinnerComponent } from '../spinner/spinner.component';

import { Subscription } from 'rxjs';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';
import { DepotViewModel } from '../gen/models/DepotViewModel';
import { ApiPageResponse } from '../gen/models/ApiPageResponse';
import { DepotService } from '../gen/services/depot.service';
import { ADGroupService } from '../gen/services/adgroup.service';
import { ActivatedRoute } from '@angular/router';
import { UserPreferencesVm } from '../gen/models/UserPreferencesVm';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Clipboard } from '@angular/cdk/clipboard';
import { NewInstructionToMoveDialogComponent } from '../new-instruction-to-move-dialog/new-instruction-to-move-dialog.component';
import { EditInstructionToMoveDialogComponent } from '../edit-instruction-to-move-dialog/edit-instruction-to-move-dialog.component';
import { InstructionToMoveViewModel } from '../gen/models/InstructionToMoveViewModel';
import * as moment from 'moment';


@Component({
    selector: 'app-requests',
    templateUrl: './instruction-to-move.component.html',
    styleUrls: ['./instruction-to-move.component.scss']
})
export class InstructionToMoveComponent implements OnInit, OnDestroy {
    displayedColumns: string[] = ['depot','destination','dueFrom','dueBy','transportMode','isActive','priority','contractHeader','grade','tonnesToMove','loadsToMove','containerSize','packageOption','uom','createdDate', 'requestId'];
    public pageModel: InitModel;
    bulkUpdateMode = false;
    isLoading = true;
    showSearch = false;
    itemsByPage = 20;
    transportModes: string[] = ['TRUCK', 'CONTAINER', 'RAIL', 'SHIP'];
    pendingAndAllocated: InstructionToMoveVm[] = [];
    selectedRequests: InstructionToMoveVm[] = [];
    groupedAllocations: IntructionToMoveExtended[] = [];
    requestStatuses: IEnumItem[];
    allocationStatuses: IEnumItem[];
    selectedAllocationStatuses: number[] = [];
    hasCentralTeamRole = false;
    canEditSelectedRequest = false;
    canEditSelectedAllocation = false;
    canDeleteSelected = false;
    canFullyAllocateSelected = false;
    statusList: string[];
    selectedIsRequest = true;
    selectedIsAllocation = false;
    selectedRequestHasAllocations = false;
    defaultUoms = [];
    tableState: QueryModel;
    originatorEv: any;
    selectedRequestStatus: RequestStatuses = RequestStatuses.Open;
    page = 1;
    pageSize = EnumVals.NoOfResults[2].value;
    collectionSize = 1;
    sortReverse = true;
    requestStatusColumn = 'Origin';
    defaultFilterStatus = 'Pending Requests';
    public tableWidth: number;
    subTableWidth: number;
    searchForm: FormGroup;
    bulkUpdateForm: FormGroup;
    noRecord = false;
    showAllFields = false;
    sCols: string[] = [];
    querySubscription: Subscription;
    showBookingSheet = false;
    showTransportPlan = false;
    showBookedTransport = false;
    filteredDepots: ApiPageResponse<DepotViewModel>;
    fieldsList: { name: string, colWidth: number, type: string }[];
    userPreferencesData: UserPreferencesVm = { depot: '', territory: '', requestFields: '', allocationFields: '', orderBookType: '', viewMode: '' , allocationDate: '', pickUpFromDate: '' };
    preferedDepot: DepotViewModel;
    preferedTerritory: TerritoryVm;
    requestColumns: string[] = [];
    defaultTerritory: string;
    filteredContractRef: ApiPageResponse<AllocateModel>;
    filteredGrade: string[];

  @ViewChild('spinner', { static: true }) spinner: SpinnerComponent;
  @ViewChild('matRef', { static: true }) matRef: MatSelect;
  @ViewChild(MatSort) sort: MatSort;
  dataSource = new MatTableDataSource(this.pendingAndAllocated);
  tableHeight = 800;
  onHold = [{name: 'Active', value: true},{name: 'In Active', value: false}];
  constructor(
    private instructionToMoveService: InstructionToMoveService,
    private initService: InitService,
    private uomsService: UomsService,
    private dialog: MatDialog,
    private sidenavService: SidenavService,
    private fb: FormBuilder,
    private gradeService: GradeService,
    private depotsService: DepotService,
    private toastr: ToastrService,
    private adGroupService: ADGroupService,
    private route: ActivatedRoute,
    private clipboard: Clipboard,
    private allocateService: AllocateService) {

      const userPreferences = this.route.snapshot.data['userpreferencesData'];
      if (userPreferences !== undefined && userPreferences.length > 0) {
          this.userPreferencesData = userPreferences[0];
      }
  }

  ngOnInit() {
      this.fieldsList = ListOfColumnNames.fieldsListForRequests.filter(f => f.type === 'request');

      const depot = this.route.snapshot.queryParamMap.get('depot');
      const grade = this.route.snapshot.queryParamMap.get('grade');


      const createdDate = this.route.snapshot.queryParamMap.get('createdDate');

      const tonnesToMove= this.route.snapshot.queryParamMap.get('tonnesToMove');
      const loadsToMove= this.route.snapshot.queryParamMap.get('loadsToMove');
      const destination= this.route.snapshot.queryParamMap.get('destination');
      const dueFrom= this.route.snapshot.queryParamMap.get('dueFrom');
      const dueBy= this.route.snapshot.queryParamMap.get('dueBy');
      const priority= this.route.snapshot.queryParamMap.get('priority');
      const transportMode= this.route.snapshot.queryParamMap.get('transportMode');
      const contractHeaderId= this.route.snapshot.queryParamMap.get('contractHeaderId');
      const isActive= this.route.snapshot.queryParamMap.get('isActive');
      if (depot !== null) {
          this.initService.initData$.subscribe(r =>
              this.pageModel = r
          );

          const ts = this.getDefaultSearch(
              depot,
              this.sortReverse,
              this.defaultTerritory,
              new Date(moment(createdDate).format('YYYY-MM-DD')),

              grade != '' ? grade : null,
              tonnesToMove !=''?parseInt(tonnesToMove):null,
              loadsToMove !=''?parseInt(loadsToMove):null,
              destination,
              new Date(moment(dueFrom).format('YYYY-MM-DD')),
              new Date(moment(dueBy).format('YYYY-MM-DD')),

              priority  !=''?parseInt(priority):null,
              transportMode  != '' ? transportMode : null,
              contractHeaderId !=''?parseInt(contractHeaderId):null,
              isActive == 'true' ? true : false
          );
          this.callServer(ts);

          this.searchForm = this.fb.group({
              depot: { depotNumber: depot, depotName: '', depotShortName: '', selected: false },
              destination: { depotNumber: depot, depotName: '', depotShortName: '', selected: false },
              dueFrom: dueFrom != null || dueFrom != '' ? new Date(moment(dueFrom).format('YYYY-MM-DD')) : '',
              dueBy: dueBy != null || dueBy != '' ?  new Date(moment(dueBy).format('YYYY-MM-DD')) : '',
              transportMode: transportMode,
              isActive: isActive == 'true' ? true : false,
              priority: priority,
              contractHeaderId: contractHeaderId != null || contractHeaderId != '' ? contractHeaderId : '',
              grade: grade,
              tonnesToMove: tonnesToMove,
              loadsToMove: loadsToMove,
              createdDate: createdDate != null || createdDate != '' ? new Date(moment(createdDate).format('YYYY-MM-DD')) : ''
          });

      } else {
          if (this.userPreferencesData.territory === '')
              this.defaultTerritory = null;
          else
              this.defaultTerritory = this.userPreferencesData.territory;

          this.preferedDepot = { depotNumber: this.userPreferencesData.depot, depotName: '', depotShortName: '', selected: false };
          const retrievedDepot = { depotNumber: depot, depotName: '', depotShortName: '', selected: false };

          if (this.userPreferencesData.requestFields !== '')
              this.requestColumns = JSON.parse(this.userPreferencesData.requestFields);

          const defaultDepot = !isNullOrUndefined(retrievedDepot.depotNumber) ? retrievedDepot : this.preferedDepot;
          const depotNo = (defaultDepot.depotNumber === '') ? null : defaultDepot.depotNumber;

          this.initService.initData$.subscribe(r =>
              this.pageModel = r
          );
          this.uomsService.getAll().subscribe(r => this.defaultUoms = r);

          const ts = this.getDefaultSearch(
              depotNo,
              this.sortReverse,
              this.defaultTerritory,
              new Date(moment(createdDate).format('YYYY-MM-DD')),

              grade != '' ? grade : null,
              tonnesToMove !=''?parseInt(tonnesToMove):0,
              loadsToMove !=''?parseInt(loadsToMove):0,
              destination,
              new Date(moment(dueFrom).format('YYYY-MM-DD')),
              new Date(moment(dueBy).format('YYYY-MM-DD')),

              priority  !=''?parseInt(priority):0,
              transportMode  != '' ? transportMode : null,
              contractHeaderId !=''?parseInt(contractHeaderId):0,
              true
          );
          this.callServer(ts);

          this.searchForm = this.fb.group({
              depot: { depotNumber: depotNo, depotName: '', depotShortName: '', selected: false },
              destination: { depotNumber: depotNo, depotName: '', depotShortName: '', selected: false },
              dueFrom: '',
              dueBy: '',
              transportMode: '',
              isActive: true,
              priority: '',
              contractHeaderId: '',
              grade: '',
              tonnesToMove: '',
              loadsToMove: '',
              createdDate: ''
          });
      }

      this.requestStatuses = EnumVals.RequestStatuses;
      this.allocationStatuses = EnumVals.ContractLineAllocationStatuses;

      this.bulkUpdateForm = this.fb.group({ lifecycleStatusId: 0, comment: '' });

      this.adGroupService.getMyGroups().subscribe(g => {
          this.showBookingSheet = g.myGroups.includes('BookingSheet');
          this.showTransportPlan = g.myGroups.includes('TransportPlan');
          this.showBookedTransport = g.myGroups.includes('BookedTransport');
          this.hasCentralTeamRole = g.myGroups.includes('AllocationsClerk');
      });

      this.depot.valueChanges
          .pipe(
              debounceTime(500),
              startWith(null),
              switchMap(v => this.filterDepots(v)))
          .subscribe(d => this.filteredDepots = d);

      this.destination.valueChanges
          .pipe(
              debounceTime(500),
              startWith(null),
              switchMap(v => this.filterDepots(v)))
          .subscribe(d => this.filteredDepots = d);

      this.calculateHeight();
  }

  calculateHeight(fromSearch = false) {
      let searchHeight = 0;

      const intFrameHeight = window.innerHeight;

      if (fromSearch)
          searchHeight = window.innerHeight * 0.15;

      const calulatedHeight = intFrameHeight - 50 - 48 - 37 - searchHeight - 36 - 10; //the height of the inside screen - the top 3 sections (excluding the search - 10 for spacings
      this.tableHeight = calulatedHeight;
  }

  filterDepots(val: string) {
      return this.depotsService.searchDepots(val);
  }

  get depot() {
      return this.searchForm.get('depot');
  }

  get contractHeaderId() {
      return this.searchForm.get('contractHeaderId');
  }

  get destination() {
      return this.searchForm.get('destination');
  }

  get grade() {
      return this.searchForm.get('grade');
  }

  displayDepot(depot?: DepotViewModel): string | undefined {
      let depotString = undefined;
      if (depot && depot.depotNumber && depot.depotNumber !== '' && depot.depotName !== '')
          depotString = depot.depotNumber + ' - ' + depot.depotName;
      else if (depot && depot.depotNumber && depot.depotNumber !== '' && depot.depotName === '')
          depotString = depot.depotNumber;
      return depotString;
  }



  displayContractRef(contractRef?: AllocateModel): string | undefined {
      return contractRef && contractRef.contractRef ? contractRef.contractRef + ' ' + contractRef.partyName : undefined;
  }

  contractRefValueChange(val: string) {
      this.contractHeaderId.valueChanges
          .pipe(
              debounceTime(500),
              startWith(null),
              switchMap(v => this.filterContractRef(v)))
          .subscribe(d => this.filteredContractRef = d);
  }

  displayGrade(grade?: any): string | undefined {
      return grade ? grade : undefined;
  }
  gradeValueChange(val: string) {
      if(val) {
          this.grade.valueChanges
              .pipe(
                  debounceTime(500),
                  startWith(null),
                  switchMap(v => this.filterGrade(v)))
              .subscribe(d => this.filteredGrade = d);
      }
  }

  filterGrade(val: string) {
      return this.gradeService.getGrade(val);
  }

  displayDestination(destination?: DepotViewModel): string | undefined {
      let depotString = undefined;
      if (destination && destination.depotNumber && destination.depotNumber !== '' && destination.depotName !== '')
          depotString = destination.depotNumber + ' - ' + destination.depotName;
      else if (destination && destination.depotNumber && destination.depotNumber !== '' && destination.depotName === '')
          depotString = destination.depotNumber;
      return depotString;
  }

  ngOnDestroy(): void {
      this.isLoading = false;
      this.spinner.overlayRef.dispose();
      if (this.querySubscription != null && !this.querySubscription.closed) {
          this.querySubscription.unsubscribe();
      }
  }

  showSearchOptions() {
      this.showSearch = !this.showSearch;
      this.bulkUpdateMode = !this.showSearch && this.selectedRequests.length > 1;
      this.calculateHeight(this.showSearch);
  }

  sideNavShow(info: any) {
      this.sidenavService.next(info);
  }

  callServer(tableState: QueryModel) {
      this.isLoading = true;

      if (tableState) {
          this.tableState = tableState;
      }

      if (this.querySubscription != null && !this.querySubscription.closed) {
          this.querySubscription.unsubscribe();
      }
      this.querySubscription = this.instructionToMoveService.getRequests(tableState).subscribe(r => {
          this.pendingAndAllocated = r.plannedMovements;
          this.dataSource = new MatTableDataSource(this.pendingAndAllocated);
          this.dataSource.sort = this.sort;
          this.collectionSize = r.recordCount;
          if (this.tableState.pagination.start === 1) {
              this.page = 1;
          }
          this.isLoading = false;
      }, () => {
          this.isLoading = false;
      });
      if (!(this.pendingAndAllocated.length > 0)) {
          this.noRecord = true;
          this.page = 1;
      }
  }

  sortByInfo(info: SortInfo) {
      if (info.active) {
          this.tableState.sort.reverse = info.direction != 'asc';
          this.sortReverse = this.tableState.sort.reverse;
          this.tableState.sort.predicate = info.active;
          this.callServer(this.tableState);
      }
  }

  newRequest() {
      const dialogRef = this.dialog.open(NewInstructionToMoveDialogComponent, {
          maxHeight: '80%',
          maxWidth: '100%',
          disableClose: true,
          data: this.userPreferencesData
      });

      dialogRef.afterClosed().subscribe(r => {
          this.disableEditingControls();
          if (isUndefined(r)) { return; }
          this.callServer(this.tableState);
      });
  }

  pageChanged(page: number) {
      this.page = page;
      this.tableState.pagination.start = page;
      this.callServer(this.tableState);
  }

  search(formVal: any) {
      const presetFilters = this.pageModel.filterNames as FilterNamesExtended[];
      let depot = formVal.depot;
      if (typeof (depot) !== 'string')
          depot = depot.depotNumber;
      const predicate: any = {
          contractHeaderId : formVal.contractHeaderId?.contractRef,
          Depot: formVal.depot.depotNumber,
          Grade: formVal.grade,
          CreatedDate :new Date(moment(formVal.createdDate).format('YYYY-MM-DD')),
          TonnesToMove: formVal.tonnesToMove,
          IsActive: formVal.isActive,
          Destination: formVal.destination.depotNumber,
          DueFrom: new Date(moment( formVal.dueFrom).format('YYYY-MM-DD')),
          DueBy: new Date(moment( formVal.dueBy).format('YYYY-MM-DD')),
          LoadsToMove: formVal.loadsToMove,
          Priority: formVal.priority,
          TransportMode: formVal.transportMode,
          route: null
      };
      this.tableState.search.predicateObject = predicate;
      this.tableState.search.predicateObject.presetFilterIds = presetFilters.filter((a) => a.isSelected).map(function (a) { return a.id; });
      this.callServer(this.tableState);
  }

  selectRequest(item: PendingAndAllocatedViewModelExtended, event: MouseEvent) {
      if (this.showSearch) this.showSearch = false;

      this.dataSource.filteredData.forEach(element => {
          if(element.isSelected != undefined) {
              element.isSelected = false;
          }
      });

      if (!event.ctrlKey) {
          this.selectedRequests = [];
          this.selectedRequests.push(item);
      }

      this.bulkUpdateMode = this.selectedRequests.length > 1;
      this.selectedIsRequest = true;
      this.selectedIsAllocation = false;
      this.toggleRequestEditingControls(item);
  }

  toggleRequestEditingControls(item: PendingAndAllocatedViewModelExtended) {
      if (item.isSelected) { //de-select it
          item.isSelected = false;
          this.disableEditingControls();
      } else {
          item.isSelected = true;
          this.canEditSelectedRequest = true;
          this.canDeleteSelected = true;
      }
  }

  disableEditingControls() {
      this.canEditSelectedRequest = false;
      this.canDeleteSelected = false;
      this.canFullyAllocateSelected = false;
  }


  success(message: string, title: string, selectedAllocation: PendingAndAllocatedViewModelExtended) {
      if (selectedAllocation) {
          this.toggleRequestEditingControls(selectedAllocation);
      }
      this.callServer(this.tableState);
      this.toastr.success(message, title);
  }

  editRequest() {
      const selectedAllocation = _.findWhere(this.pendingAndAllocated as PendingAndAllocatedViewModelExtended[], { isSelected: true });
      const dialogRef = this.dialog.open(EditInstructionToMoveDialogComponent, {
          minWidth: '240px',
          maxWidth: '80%',
          maxHeight: '80%',
          data: selectedAllocation,
          disableClose: true,
      });

      dialogRef.afterClosed().subscribe(() => {
          if (selectedAllocation) {
              this.toggleRequestEditingControls(selectedAllocation);
          }
          this.callServer(this.tableState);
      });
  }

  deleteRequest() {
      const selectedAllocation = _.findWhere(this.pendingAndAllocated as PendingAndAllocatedViewModelExtended[], { isSelected: true });
      const dialogRef = this.dialog.open(ActionConfirmDialogComponent, {
          minWidth: '240px',
          maxWidth: '80%',
          maxHeight: '80%',
          data: {
              type: 'request',
              model: selectedAllocation,
              action: 'delete'
          },
          disableClose: true,
      });

      dialogRef.afterClosed().subscribe(r => {
          if (isUndefined(r)) { return; }
          this.instructionToMoveService.deleteInstructionToMove(r.id).subscribe(() => {
              this.success('Intruction to move successfully deleted', 'Delete Intruction to move', selectedAllocation);
          },
          err => {
              if (err.status === 200) {
                  this.success('Intruction to move successfully deleted', 'Delete Intruction to move', selectedAllocation);
              } else {
                  this.dialog.open(AlertDialogComponent, {
                      minWidth: '240px',
                      maxWidth: '80%',
                      maxHeight: '80%',
                      data: {
                          ok: 'Ok',
                          aria: 'Delete Failed',
                          title: 'Delete Failed',
                          body: 'Something went wrong and the request could not be deleted. Please try again later.'
                      }
                  });
              }
          });
      });
  }

  getDefaultSearch(
      depotNo: string,
      sortReverse: boolean,
      territory: string,
      createdDate: Date = null,
      grade: string = null,
      tonnesToMove: number = null,
      loadsToMove: number = null,
      destination: string = null,
      dueFrom: Date = null,
      dueBy: Date = null,
      priority: number = null,
      transportMode: string = null,
      contractHeaderId: number = null,
      isActive: boolean
  ): QueryModel {
      const queryModel: QueryModel = {
          pagination: { number: this.pageSize, start: 0 },
          search: {
              predicateObject: {
                  grade: grade,//this
                  gradeDescription: null,
                  gradeGroupName: null,
                  salesGrade: null,
                  heap: null,
                  heapDescription: null,
                  requestedGrade: null,
                  requestedGradeDescription: null,

                  depot: depotNo, // this
                  depotName: null,
                  territory: territory,

                  contractRef: null,
                  classification: null,
                  classifications: [],

                  partyAccountNo: null,
                  partyName: null,
                  partySearchText: null,
                  haulierAccountNo: null,
                  haulierName: null,
                  haulierSearchText: null,

                  ticketNumber: null,

                  allocationNumber: null,
                  containerPackaging: null,
                  containerTypeName: null,
                  requestStatuses: ['Open'],
                  allocationStatuses: [],
                  contractHeaderStatuses: [],
                  presetFilterIds: [],
                  lifecycleStatusId: 0,
                  comment: null,
                  comments: null,
                  status: null,
                  description: null,
                  review: null,
                  firstComeFirstServe: false,
                  awaitingDispatch: null,

                  dateRange: null,
                  pickupFromDateRange: null,
                  includeDeleted: false,
                  kpiTypes: [],

                  createdDate: createdDate,
                  tonnesToMove: tonnesToMove, // Weight
                  loadsToMove: loadsToMove, //Loads
                  destination: destination,
                  dueFrom: dueFrom,
                  dueBy: dueBy,
                  priority: priority,
                  transportMode: transportMode,
                  contractHeaderId: contractHeaderId,//ContractNumber
                  isActive: isActive,
                  route: null,
                  hold: null
              }
          },
          sort: { predicate: null, reverse: sortReverse }
      };
      return queryModel;
  }

  clearFilter() {
      this.searchForm.patchValue({
          dueFrom: '',
          dueBy: '',
          transportMode: '',
          isActive: true,
          priority: '',
          contractHeaderId: '',
          grade: '',
          tonnesToMove: '',
          loadsToMove: '',
          createDate: ''
      });
  }

  filterContractRef(val: string) {
      return this.allocateService.GetContractRefsForIntructionToMove(val);
  }

  copyLink() {
      try {
          const depot = this.searchForm.get('depot').value;
          const destination = this.searchForm.get('destination').value;
          if (isNullOrUndefined(depot) || depot == '') {
              this.toastr.error('Depot selection required', 'Error');
              return;
          }

          const url = new URL(location.origin + location.pathname);

          url.searchParams.append('depot', '');
          url.searchParams.append('destination', '');
          url.searchParams.append('dueFrom', '');
          url.searchParams.append('dueBy', '');
          url.searchParams.append('transportMode', '');

          url.searchParams.append('isActive', 'true');
          url.searchParams.append('priority', '');
          url.searchParams.append('contractHeaderId', '');
          url.searchParams.append('grade', '');
          url.searchParams.append('tonnesToMove', '');
          url.searchParams.append('loadsToMove', '');
          url.searchParams.append('createDate', '');


          if (typeof (depot) === 'string' && depot.includes(',')) {
              const splitdepotNos = depot.split(',').map(element => element.toUpperCase().trim().slice(0, 7));
              const depotNos = splitdepotNos.join(', ');
              url.searchParams.set('depot', depotNos);
          }
          else {
              if (depot.depotNumber != null && depot.depotNumber != undefined && depot.depotNumber != '') {
                  url.searchParams.set('depot', depot.depotNumber);
              }
              else if (!isNullOrUndefined(depot.depotNumber)) {
                  url.searchParams.set('depot', depot.toUpperCase());
              }
          }


          if (typeof (destination) === 'string' && destination.includes(',')) {
              const splitdepotNos = destination.split(',').map(element => element.toUpperCase().trim().slice(0, 7));
              const depotNos = splitdepotNos.join(', ');
              url.searchParams.set('destination', depotNos);
          }
          else {
              if (destination.depotNumber != null && destination.depotNumber != undefined && destination.depotNumber != '') {
                  url.searchParams.set('destination', destination.depotNumber);
              }
              else if (!isNullOrUndefined(destination.depotNumber)) {
                  url.searchParams.set('destination', destination.toUpperCase());
              }
          }

          const dueFrom = this.searchForm.get('dueFrom').value;
          if (dueFrom != null && dueFrom != undefined && dueFrom != '') {
              url.searchParams.set('dueFrom', dueFrom.getDate());
          }

          const dueBy = this.searchForm.get('dueBy').value;
          if (dueBy != null && dueBy != undefined && dueBy != '') {
              url.searchParams.set('dueBy', dueBy);
          }

          const transportMode = this.searchForm.get('transportMode').value;
          if (transportMode != null && transportMode != undefined && transportMode != '') {
              url.searchParams.set('transportMode', transportMode);
          }


          const isActive = this.searchForm.get('isActive').value;
          if (isActive != null && isActive != undefined && isActive != '') {
              url.searchParams.set('isActive', isActive);
          }
          const priority = this.searchForm.get('priority').value;
          if (priority != null && priority != undefined && priority != '') {
              url.searchParams.set('priority', priority);
          }

          const contractHeaderId = this.searchForm.get('contractHeaderId').value;
          if (contractHeaderId != null && contractHeaderId != undefined && contractHeaderId != '') {
              url.searchParams.set('contractHeaderId', contractHeaderId);
          }
          const grade = this.searchForm.get('grade').value;
          if (grade != null && grade != undefined && grade != '') {
              url.searchParams.set('grade', grade);
          }
          const tonnesToMove = this.searchForm.get('tonnesToMove').value;
          if (tonnesToMove != null && tonnesToMove != undefined && tonnesToMove != '') {
              url.searchParams.set('tonnesToMove', tonnesToMove);
          }

          const loadsToMove = this.searchForm.get('loadsToMove').value;
          if (loadsToMove != null && loadsToMove != undefined && loadsToMove != '') {
              url.searchParams.set('loadsToMove', loadsToMove);
          }
          const createdDate = this.searchForm.get('createdDate').value;
          if (createdDate != null && createdDate != undefined && createdDate != '') {
              url.searchParams.set('createdDate', createdDate);
          }



          this.clipboard.copy(url.toString());

          this.toastr.success('Link successfully copied to clipboard', 'Success');
      } catch (e) {
          this.toastr.error('An error occured while copying the filters. Please try again.', 'Error');
      }
  }
}

interface PendingAndAllocatedViewModelExtended extends InstructionToMoveVm {
  showingAllocations: boolean;
  isSelected: boolean;
}

interface IntructionToMoveExtended extends InstructionToMoveViewModel {
  showingGroup: boolean;
  isSelected: boolean;
}

interface FilterNamesExtended extends FilterNames {
  isSelected: boolean;
}
