import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {REST_CONFIG_TOKEN, RestConfigService } from '../rest-config-service';
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import {isNullOrUndefined} from '../../tools';
import * as FileSaver from 'file-saver';
import { DepotVm,
	BookingSheetQueryModel,
	ApiPageResponse,
} from '../models';
import { PagedTransportPlanViewModel } from '../models/PagedTransportPlanViewModel';
import { LocationViewModel } from '../models/LocationViewModel';
import { TransportPlanViewModel } from '../models/TransportPlanViewModel';
import { TransportPlanFavouriteDepotsViewModel } from '../models/TransportPlanFavouriteDepotsViewModel';
import { BookingsheetPlanQueryModel } from '../models/BookingsheetPlanQueryModel';
import { TransportPlanQueryModel } from '../models/TransportPlanQueryModel';
import { TransportPlanSaveRequestModel } from '../models/TransportPlanSaveRequestModel';

@Injectable()
export class TransportPlanService {

	constructor(
private _http: HttpClient,
@Inject(REST_CONFIG_TOKEN) private _configService: RestConfigService
	) {
	}

	public saveTransportPlanFavouriteDepots: (
    model: TransportPlanFavouriteDepotsViewModel
    ) => Observable<boolean>
		= (
			model: TransportPlanFavouriteDepotsViewModel
		): Observable<boolean> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});

			const uri = '/api/TransportPlan/savetransportplanfavdepots';

			return this._http
				.post<boolean>(this._configService.apiBaseUrl() + uri, JSON.stringify(model),
					{ headers: headers, params: queryParameters  });
		};

	public saveTransportPlanData: (
    model: TransportPlanSaveRequestModel
    ) => Observable<BookingsheetPlanQueryModel>
		= (
			model: TransportPlanSaveRequestModel
		): Observable<BookingsheetPlanQueryModel> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});

			const uri = '/api/TransportPlan/savetransportplandata';

			return this._http
				.post<BookingsheetPlanQueryModel>(this._configService.apiBaseUrl() + uri, JSON.stringify(model),
					{ headers: headers, params: queryParameters  });
		};

	public getTransportPlanDestination: (
        searchTerm?: string
        ) => Observable<ApiPageResponse<LocationViewModel>>
		= (
			searchTerm?: string
		): Observable<ApiPageResponse<LocationViewModel>> => {
			let queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});
			if (!isNullOrUndefined(searchTerm)) {
				queryParameters = queryParameters.append('searchTerm', searchTerm.toString());
			}

			const uri = '/api/TransportPlan/getlocation';

			return this._http
				.get<ApiPageResponse<LocationViewModel>>(this._configService.apiBaseUrl() + uri,
					{ headers: headers, params: queryParameters  });
		};

	public getTransportPlanData: (
        tq?: TransportPlanQueryModel
        ) => Observable<PagedTransportPlanViewModel>
		= (
			tq?: TransportPlanQueryModel
		): Observable<PagedTransportPlanViewModel> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});

			const uri = '/api/TransportPlan/gettransportplandata';

			return this._http
				.post<PagedTransportPlanViewModel>(this._configService.apiBaseUrl() + uri, JSON.stringify(tq),
					{ headers: headers, params: queryParameters  });
		};

	public getPreviousTransportPlanData: (
            tq?: TransportPlanQueryModel
            ) => Observable<PagedTransportPlanViewModel>
		= (
			tq?: TransportPlanQueryModel
		): Observable<PagedTransportPlanViewModel> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});
    
			const uri = '/api/TransportPlan/getprevioustransportplandata';
    
			return this._http
				.post<PagedTransportPlanViewModel>(this._configService.apiBaseUrl() + uri, JSON.stringify(tq),
					{ headers: headers, params: queryParameters  });
		};
        
	public exportTransportPlanToExcel(fileName: string, responseType: string,
		tq?: TransportPlanQueryModel
	): Observable<Object> {
		const headers = new HttpHeaders({'Content-Type': 'application/json'});
		const uri = this._configService.apiBaseUrl() + '/api/TransportPlan/gettransportplan/export';
		return this._http.post(uri, JSON.stringify(tq), {
			responseType: 'blob', headers
		} )
			.pipe(map((response) => {
				const blob = new Blob([response], {type: responseType});
				FileSaver.saveAs(blob, fileName);
				return {};
			}))
		;
	}

	public exportTransportPlanToExcelAllTrucks(fileName: string, responseType: string,
		tq?: TransportPlanQueryModel
	): Observable<Object> {
		const headers = new HttpHeaders({'Content-Type': 'application/json'});
		const uri = this._configService.apiBaseUrl() + '/api/TransportPlan/gettransportplan/exportAllTrucks';
		return this._http.post(uri, JSON.stringify(tq), {
			responseType: 'blob', headers
		} )
			.pipe(map((response) => {
				const blob = new Blob([response], {type: responseType});
				FileSaver.saveAs(blob, fileName);
				return {};
			}))
		;
	}

	public exportTransportPlanToExcelAll(fileName: string, responseType: string,
		tq?: TransportPlanQueryModel
	): Observable<Object> {
		const headers = new HttpHeaders({'Content-Type': 'application/json'});
		const uri = this._configService.apiBaseUrl() + '/api/TransportPlan/gettransportplan/exportAll';
		return this._http.post(uri, JSON.stringify(tq), {
			responseType: 'blob', headers
		} )
			.pipe(map((response) => {
				const blob = new Blob([response], {type: responseType});
				FileSaver.saveAs(blob, fileName);
				return {};
			}))
		;
	}

	public exportTransportPlanForFerian(fileName: string, responseType: string,
		tq?: TransportPlanQueryModel
	): Observable<Object> {
		const headers = new HttpHeaders({'Content-Type': 'application/json'});
		const uri = this._configService.apiBaseUrl() + '/api/TransportPlan/gettransportplan/exportForFerian';
		return this._http.post(uri, JSON.stringify(tq), {
			responseType: 'blob', headers
		} )
			.pipe(map((response) => {
				const blob = new Blob([response], {type: responseType});
				FileSaver.saveAs(blob, fileName);
				return {};
			}))
		;
	}
        
	public exportTransportPlanForFerianAll(fileName: string, responseType: string,
		tq?: TransportPlanQueryModel
	): Observable<Object> {
		const headers = new HttpHeaders({'Content-Type': 'application/json'});
		const uri = this._configService.apiBaseUrl() + '/api/TransportPlan/gettransportplan/exportForFerianAll';
		return this._http.post(uri, JSON.stringify(tq), {
			responseType: 'blob', headers
		} )
			.pipe(map((response) => {
				const blob = new Blob([response], {type: responseType});
				FileSaver.saveAs(blob, fileName);
				return {};
			}))
		;
	}            
            
	public getDefaultDepotDetails: (
                ) => Observable<DepotVm>
		= (
		): Observable<DepotVm> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});
			const uri = '/api/TransportPlan/getdefaultdepotdetails';

			return this._http
				.get<DepotVm>(this._configService.apiBaseUrl() + uri,
					{ headers: headers, params: queryParameters  });
		};
    
	public gettransportdepots: (
        ) => Observable<DepotVm>
		= (
		): Observable<DepotVm> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});
			const uri = '/api/TransportPlan/gettransportdepots';

			return this._http
				.get<DepotVm>(this._configService.apiBaseUrl() + uri,
					{ headers: headers, params: queryParameters  });
		};
}

