import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { AppointmentService } from '../gen/services/appointment.service';
import {
	AppointmentVm, NewAppointment, EditAppointment, PartiesWithContract,
	ContractHeaderViewModel, MissedCategory
} from '../gen/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PartiesService } from '../gen/services/parties.service';
import { ContractsService } from '../gen/services/contracts.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
	selector: 'app-new-appointment-dialog',
	templateUrl: './new-appointment-dialog.component.html',
	styleUrls: ['./new-appointment-dialog.component.scss']
})

export class NewAppointmentDialogComponent implements OnInit {
	newAppointmentForm: FormGroup;
  @ViewChild('loadingNewAppointmentDialogSpinner') loadingNewAppointmentDialogSpinner: SpinnerComponent;
  loadingNewAppointmentDialog = false;
  isSubmit = false;

  constructor(
    public dialogRef: MatDialogRef<NewAppointmentDialogComponent>,
    private fb: FormBuilder,
    public partiesService: PartiesService,
    private contractsService: ContractsService,
    private appointmentService: AppointmentService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: AppointmentVm
  ) { }

  filteredCustomer: PartiesWithContract[] = [];
  contracts: ContractHeaderViewModel[];  
  editMode = false;
  dateEditMode = false;
  appointmentId: number = null;
  contractLineAllocationId: number = null;
  dbContractHeaderId: number;
  dbMissedCategory: number;
  missedCategories: MissedCategory[] = null;
  isReferenceNumberUnique = false;
  loadingReferenceUnique = false;

  get customer() {
  	return this.newAppointmentForm.get('customer');
  }
  get contract() {
  	return this.newAppointmentForm.get('contract');
  }
  get deliveryNumber() {
  	return this.newAppointmentForm.get('deliveryNumber');
  }
  get startDate() {
  	return this.newAppointmentForm.get('customerAptDateTime');
  }
  get endDate() {
  	return this.newAppointmentForm.get('customerAptToDateTime');
  }
  get comments() {
  	return this.newAppointmentForm.get('comments');
  }
  get title() {
  	return this.editMode ? 'Edit Appointment' : 'New Appointment';
  }

  ngOnInit() {

  	if (this.data != null) {
  		this.editMode = true;
  		this.dateEditMode = this.data.contractLineAllocationId && this.data.contractLineAllocationId > 0;
  		this.appointmentId = this.data.appointmentId;
  		this.contractLineAllocationId = this.data.contractLineAllocationId;
  	}

  	if (this.editMode) {

  		this.newAppointmentForm = this.fb.group({
  			customer: [this.data.customer, Validators.required],
  			contract: [this.data.contractHeaderId],
  			deliveryNumber: [this.data.deliveryNumber, Validators.required],
  			customerAptDateTime: ['', Validators.required],
  			customerAptToDateTime: '',
  			missedCategory: [this.data.missedCategoryId],
  			comments: [this.data.comments]
  		});



  		this.contractsService.getHeaders(this.data.partyAccountNo).subscribe(r => {
  			this.contracts = r;
  			this.dbContractHeaderId = this.data.contractHeaderId;
  			this.newAppointmentForm.controls.customerAptDateTime.patchValue(this.data.startDate);
  			this.newAppointmentForm.controls.customerAptToDateTime.patchValue(this.data.endDate);
  		});

  		this.appointmentService.getMissedCategory().subscribe(m => {
  			this.missedCategories = m;
  			this.dbMissedCategory = this.data.missedCategoryId;
  		});

  		this.customer.patchValue({ party_Name: this.data.customer, party_Account_No: this.data.partyAccountNo });
  	} else {

  		this.newAppointmentForm = this.fb.group({
  			customer: ['', Validators.required],
  			contract: [''],
  			deliveryNumber: ['', Validators.required],
  			customerAptDateTime: ['', Validators.required],
  			customerAptToDateTime: [''],
  			missedCategory: [''],
  			comments: ['']
  		});
  	}
  }
  cancel() {
  	this.dialogRef.close();
  }
  ok() {
  	if (this.editMode) {
  		if (this.newAppointmentForm.valid) {
  			this.isSubmit = true;
  			this.loadingNewAppointmentDialog = true;        
  			const value = this.newAppointmentForm.value;
  			const model: EditAppointment = {
  				appointmentId: this.data.appointmentId,
  				contractLineAllocationId: this.data.contractLineAllocationId,
  				contractHeaderId: value.contract,
  				deliveryNumber: value.deliveryNumber,
  				startDate: value.customerAptDateTime,
  				endDate: value.customerAptToDateTime,
  				comments: value.comments,
  				missedCategory: value.missedCategory,
  				partyAccountNo: value.customer.party_Account_No
  			};
  			this.appointmentService.updateAppointment(model).subscribe(a => {
  				this.success('Appointment successfully edited', 'Edit Appointment');
  				this.closeDialog();
  			}, err => {
  				if (err.error != null && err.error.status === 200) {
  					this.success('Appointment successfully edited', 'Edit Appointment');
  					this.closeDialog();
  				} else {
  					this.toastr.error('Something went wrong and the request could not be updated. Please try again later.', 'Edit Appointment Failed');
  					this.isSubmit = false;
  				}
  				this.loadingNewAppointmentDialog = false;
  			});
  		}
  	} else {
  		if (this.newAppointmentForm.valid) {
  			this.isSubmit = true;
  			this.loadingNewAppointmentDialog = true;
  			const value = this.newAppointmentForm.value;
  			const model: NewAppointment = {
  				partyAccountNo: value.customer.party_Account_No,
  				contractHeaderId: value.contract,
  				contractLineAllocationId: null,
  				deliveryNumber: value.deliveryNumber,
  				startDate: value.customerAptDateTime,
  				endDate: value.customerAptToDateTime,
  				comments: value.comments
  			};
  			this.appointmentService.createNewAppointment(model).subscribe(a => {
  				this.success('Appointment successfully created', 'New Appointment');
  				this.closeDialog();
  			}, err => {
  				if (err.error != null && err.error.status === 200) {
  					this.success('Appointment successfully created', 'New Appointment');
  					this.closeDialog();
  				} else {
  					this.toastr.error('Something went wrong and the request could not be created. Please try again later.', 'New Appointment Failed');
  					this.isSubmit = false;
  				}
  				this.loadingNewAppointmentDialog = false;
  			});
  		}
  	}
  }
  private closeDialog() {
  	setTimeout(() => {
  		this.loadingNewAppointmentDialog = false;
  		this.dialogRef.close();
  	}, 1500);
  }

  private success(message: string, title: string) {
  	this.toastr.success(message, title);
  }
  displayCustomer(customer?: PartiesWithContract): string | undefined {
  	return customer ? customer.party_Account_No + ' - ' + customer.party_Name : undefined;
  }
  loadContract(customer?: PartiesWithContract) {
  	if (this.customer != null) {
  		this.contractsService.getHeaders(customer.party_Account_No).subscribe(r => {
  			this.contracts = r;
  		});
  	}
  }
}
