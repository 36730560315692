
import {Injectable} from '@angular/core';
import {ConfigService} from './services/config-service';

@Injectable()
export class StartupService {
	constructor(private configService: ConfigService) {}

	async load(): Promise<void> {
		await this.configService.load();

		// if (window.location.hash === '#e2e=random_dc90329ijcef092ied') {
		//   this._configService.config.apiUrl = 'http://localhost:3000';
		//   this._configService.config['runLocal'] = true;
		//   this._configService.config['addLocalDelay'] = false;
		// }
	}

}
