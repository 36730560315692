<div class="dialog-header">
  Send Email
</div>
<div class="dialog-sub-header">
  From: {{data.from}}
</div>
<app-spinner [displayProgressSpinner]="loadingSendMailDialog" [container]="loadingSendMailDialogBox"
  #loadingSendMailDialogSpinner>
</app-spinner>
<div class="dialog-body" #loadingSendMailDialogBox>
  <form [formGroup]="emailForm" (ngSubmit)="send(emailForm.value)">
    <div>
      <div>
        <!-- <mat-form-field>
            <input matInput formControlName="toAddress" placeholder="To">
          </mat-form-field> -->
        <mat-form-field>
          <input type="text" matInput formControlName="toAddress" placeholder="To" [matAutocomplete]="auto"
            (change)="onAddressTextChange($event.target.value, 'toAddress')">
        </mat-form-field>

        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let e of data.to" [value]="e.email">{{e.email}} ({{e.type}})</mat-option>
        </mat-autocomplete>
      </div>
      <div>
        <mat-form-field>
          <input matInput formControlName="ccAddress" placeholder="Cc"
            (change)="onAddressTextChange($event.target.value, 'ccAddress')">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
          <textarea matInput formControlName="message" rows="20" cols="50" placeholder="Message"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div mat-dialog-actions layout="row" class="buttons-row">
      <button mat-flat-button (click)="dialogRef.close()" type="button">Cancel</button>
      <button mat-flat-button type="submit" color="primary" [disabled]="isSubmit">Send</button>
    </div>
  </form>
</div>