<div class="clear-fix"></div>
<br />
<div class="row text-center">
  <div class="col-md-4">
    <div class="btn-group">
      <div
        class="btn btn-primary"
        mwlCalendarPreviousView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay(view ,viewDate)"
      >
        Previous
      </div>
      <div
        class="btn btn-outline-secondary"
        mwlCalendarToday
        [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay(view ,viewDate)"
      >
        Today
      </div>
      <div
        class="btn btn-primary"
        mwlCalendarNextView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay(view ,viewDate)"
      >
        Next
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
  </div>
  <div class="col-md-4">
    <div class="btn-group">
      <div
        class="btn btn-primary"
        (click)="setView(CalendarView.Month, viewDate)"
        [class.active]="view === 'month'"
      >
        Month
      </div>
      <div
        class="btn btn-primary"
        (click)="setView(CalendarView.Week, viewDate)"
        [class.active]="view === 'week'"
      >
        Week
      </div>
      <div
        class="btn btn-primary"
        (click)="setView(CalendarView.Day, viewDate)"
        [class.active]="view === 'day'"
      >
        Day
      </div>
    </div>
  </div>
</div>
<br />
 
<ng-template #customCellTemplate let-day="day" let-locale="locale" 
    let-tooltipPlacement="tooltipPlacement"
    let-highlightDay="highlightDay" let-unhighlightDay="unhighlightDay" 
    let-eventClicked="eventClicked" style="max-height: 180px;">
    <div class="cal-cell-top" style="max-height:20px">
        <span class="cal-day-badge" style="font-size: 12px;" *ngIf="day.badgeTotal > 0">
          {{ day.badgeTotal }}</span>
        <span class="cal-day-number">
        {{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
    </div>
    <div style="width: 100%;align-items: center;" *ngIf="day.events.length > 0">
        <div style="font-size: 14px;width: 100%;"  *ngFor="let event of day.events;  index as i">
            <ng-template *ngIf="i < 3; then showEventsBlock; else showMoreBlock">
            </ng-template>
            <ng-template #showEventsBlock>
                <div class="cal-events" *ngIf="i < 3" 
                  (click)="!!event.meta.allocationNumber ? handleeditEvent('Clicked', event) : handleReqMoreEvent('Clicked',event,day.date) "
                  [style.height.px]="28"
                  [ngStyle]="
                  {
                    'background-color':event.color.primary,
                    'border-color':event.color.secondary,
                    'border-radius': '5px','color':'#0d62ab',
                    'border':'1px solid',
                    'text-align': 'center'
                  }">
                  <span class="wrap-text">
                    <label>{{event.meta.depot}} | {{ !event.meta.allocationNumber  ? event.meta.requestId : event.meta.allocationNumber}} | {{!event.meta.grade ? event.meta.salesGrade : event.meta.grade}}</label>
                  </span>
                </div>
            </ng-template>
            <ng-template #showMoreBlock>
                <ng-container *ngIf="i === 3">
                <div class="cal-events" style="width: 100%;" (mwlClick)="handleMoreEvent($event,day.events,day.date)">
                    <a class="showmore"> +{{day.events.length - 3}} </a>
                </div>
            </ng-container>
            </ng-template>
        </div>
    </div>

</ng-template>

<div [ngSwitch]="view">
  <mwl-calendar-month-view
    *ngSwitchCase="'month'"
    [viewDate]="viewDate"
    [events]="events"
    [refresh]="refresh"
    (eventTimesChanged)="eventTimesChanged($event)"
    [cellTemplate]="customCellTemplate"
  >
  </mwl-calendar-month-view>
  
  <app-week-view
  *ngSwitchCase="CalendarView.Week"
  [viewDate]="viewDate"
  [events]="events"
  [refresh]="refresh"
  [pendingAndAllocated] = "pendingAndAllocated"
  (eventClicked)="mwlEventClicked($event.event, day?.date)"
  (eventTimesChanged)="eventTimesChanged($event)"
>
</app-week-view>

<app-day-view
  *ngSwitchCase="CalendarView.Day"
  [viewDate]="viewDate"
  [events]="events"
  [refresh]="refresh"
  [pendingAndAllocated] = "pendingAndAllocated"
  (eventClicked)="mwlEventClicked($event.event, day?.date)"
  (eventTimesChanged)="eventTimesChanged($event)"
> 
</app-day-view>
</div>

<ng-template #modalContent let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Event action occurred</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      Action:
      <pre>{{ modalData?.action }}</pre>
    </div>
    <div>
      Event:
      <pre>{{ modalData?.event }}</pre>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="close()">
      OK
    </button>
  </div>
</ng-template> 