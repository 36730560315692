import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams}  from '@angular/common/http';
import { REST_CONFIG_TOKEN, RestConfigService } from '../rest-config-service';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from '../../tools';
import { HeapVm, ApiPageResponse } from '../models';

@Injectable()
export class HeapsService {

	constructor(
private _http: HttpClient,
@Inject(REST_CONFIG_TOKEN) private _configService: RestConfigService
	) {
	}

	public search: (depotCode?: string,searchTerm?: string) => Observable<ApiPageResponse<HeapVm>>
		= (depotCode?: string, searchTerm?: string): Observable<ApiPageResponse<HeapVm>> => {
			let queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});

			if (!isNullOrUndefined(depotCode)) {
				queryParameters = queryParameters.append('depotCode', depotCode.toString());
			}

			if (!isNullOrUndefined(searchTerm)) {
				queryParameters = queryParameters.append('searchTerm', searchTerm.toString());
			}

			const uri = '/api/heaps/search';

			return this._http
				.get<ApiPageResponse<HeapVm>>(this._configService.apiBaseUrl() + uri, { headers: headers, params: queryParameters });
		};
        
	public getHeap: (heapNo: number) => Observable<HeapVm>
		= (heapNo: number): Observable<HeapVm> => {
			let queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});

			queryParameters = queryParameters.append('heapNo', heapNo.toString());

			const uri = '/api/heaps/getheap';

			return this._http
				.get<HeapVm>(this._configService.apiBaseUrl() + uri, { headers: headers, params: queryParameters });
		};

}

