<div class="content-container sidebar-form" style="max-width: 900px;border: none" id="load-schedule-container">
    <mat-card layout-fill>
        <div class="md-hue-2" id="load-schedule-header">
            <div class="md-toolbar-tools sidebar-header">
                <h3 flex mat-truncate>Loads Schedule</h3>
            </div>
        </div>
        <div class="table-responsive">
                <div style="width: 100%;overflow-y: auto;">
                    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
                    <table name="loadstable" id="loads-table" cellpadding="0" cellspacing="0"
                        class="table table-hover table-condensed custom-table" width="100%">
                        <thead>
                            <tr class="heading-row">
                                <th style="width: 150px;text-align:center"
                                    class="interactive hide-sm hide-xs">Loads</th>
                                <th style="text-align:center"
                                    class="interactive hide-sm hide-xs">Allocated Date</th>
                            </tr>
                        </thead>
                        <tbody>
                              <ng-template ngFor let-item [ngForOf]="loadSchedule">
                                <tr style="height: 36px;">
                                    <td hide-sm hide-xs style="text-align:center">{{item.loads}}</td>
                                    <td hide-sm hide-xs style="text-align:center">{{item.allocatedDate  | momentUtcAsLocaleDate}}</td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>
                </div>
            </div>
    </mat-card>
</div>
