<div class="row">
  <div class="col-12">
    <div *ngIf="title" 
      [class]="options.titleClass" 
      [attr.aria-label]="title"
      class="custom-title">
      {{ title }}
    </div>
    <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite"
      class="custom-message"
      [class]="options.messageClass" [innerHTML]="message">
    </div>
    <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite"
      class="custom-message"
      [class]="options.messageClass" [attr.aria-label]="message">
      {{ message }}
    </div>
  </div>
</div>
  <div class="row">
    <a *ngIf = "options.progressBar"
        style="color: white; text-decoration: underline;"
        class="btn btn-sm button"
        (click)="action()"
      >
      Click to view allocation details
      </a>
  </div>

