export interface IEnumItem {
    key: number;
    value: any;
    desc: string;
}

export const enum RequestStatuses {
    Open = 1,
    FullyAllocated = 2,
}

export const enum ContractLineAllocationStatuses {
    Open = 1,
    Closed = 2,
    Used = 3,
    Draft = 4,
}

export const enum ContractLineAllocationLifecycles {
    _InspectionRaised = 1,
    InspectionDate = 2,
    DockReceipt = 3,
    BookingRequested = 4,
}

export const enum BookingSheetRange {
    OneDay = 0,
    ThreeDay = 1,
    FiveDays = 2,
    OneWeek = 3,
    TwoWeeks = 4,
    ThreeWeeks = 5,
    FourWeeks = 6,
    SixWeeks = 7,
    EightWeeks = 8,
    TwelveWeeks = 9,
}

export class RequestStatusesClass {
    Open = 1;
    FullyAllocated = 2;
}

export class ContractLineAllocationStatusesClass {
    Open = 1;
    Closed = 2;
    Used = 3;
    Draft = 4;
}

export class ContractLineAllocationLifecyclesClass {
    _InspectionRaised = 1;
    InspectionDate = 2;
    DockReceipt = 3;
    BookingRequested = 4;
}

export class BookingSheetRangeClass {
    OneDay = 0;
    ThreeDay = 1;
    FiveDays = 2;
    OneWeek = 3;
    TwoWeeks = 4;
    ThreeWeeks = 5;
    FourWeeks = 6;
    SixWeeks = 7;
    EightWeeks = 8;
    TwelveWeeks = 9;
}

export class AdFunctionalGroups {
    TransportPlan = 0;
}

export enum SchedulerReportType {
    AllocatedDate = 0,
    MovementDate = 1
}

export class EnumVals {
    static RequestStatuses: Array<IEnumItem> = [
        { key: 1, value: 'Open', desc: 'Open' },
        { key: 2, value: 'FullyAllocated', desc: 'Fully Allocated' },
    ];

    static ContractHeaderStatuses: Array<IEnumItem> = [
        { key: 1, value: 'Open', desc: 'Open' },
        { key: 2, value: 'Hold', desc: 'Hold' },
        { key: 3, value: 'Draft', desc: 'Draft' },
        { key: 4, value: 'Completed', desc: 'Completed - No Position' },
        { key: 5, value: 'Cancelled', desc: 'Cancelled - No Position' },
        { key: 6, value: 'OnStop', desc: 'On Stop - No Position' },
    ];

    static ContractLineAllocationApprovedToSend: Array<IEnumItem> = [
        { key: 0, value: 'No', desc: 'No' },
        { key: 1, value: 'Yes', desc: 'Yes' },
    ];


    static ContractLineAllocationStatuses: Array<IEnumItem> = [
        { key: 1, value: 'Open', desc: 'Open' },
        { key: 2, value: 'Closed', desc: 'Closed' },
        { key: 3, value: 'Used', desc: 'Used' },
        { key: 4, value: 'Draft', desc: 'Draft' },
    ];

    static ContractLineAllocationClassifications: Array<IEnumItem> = [
        { key: 1, value: 'Domestic', desc: 'Domestic' },
        { key: 2, value: 'European', desc: 'European' },
        { key: 3, value: 'Export', desc: 'Export' },
    ];

    static ContractLineAllocationLifecycles: Array<IEnumItem> = [
        { key: 1, value: '_InspectionRaised', desc: 'Inspection Raised' },
        { key: 2, value: 'InspectionDate', desc: 'Inspection Date' },
        { key: 3, value: 'DockReceipt', desc: 'Dock Receipt' },
        { key: 4, value: 'BookingRequested', desc: 'Booking Requested' },
    ];

    static BookingSheetRange: Array<IEnumItem> = [
        { key: 0, value: 'OneDay', desc: 'OneDay' },
        { key: 1, value: 'ThreeDay', desc: 'ThreeDay' },
        { key: 2, value: 'FiveDays', desc: 'FiveDays' },
        { key: 3, value: 'OneWeek', desc: 'OneWeek' },
        { key: 4, value: 'TwoWeeks', desc: 'TwoWeeks' },
        { key: 5, value: 'ThreeWeeks', desc: 'ThreeWeeks' },
        { key: 6, value: 'FourWeeks', desc: 'FourWeeks' },
        { key: 7, value: 'SixWeeks', desc: 'SixWeeks' },
        { key: 8, value: 'EightWeeks', desc: 'EightWeeks' },
        { key: 9, value: 'TwelveWeeks', desc: 'TwelveWeeks' },
    ];

    static AdFunctionalGroups: Array<IEnumItem> = [
        { key: 0, value: 'TransportPlan', desc: 'Transport Plan' }
    ];

    static  NoOfResults: Array<IEnumItem> = [
        { key: 0, value: 10, desc: '10' },
        { key: 1, value: 25, desc: '25' },
        { key: 2, value: 50, desc: '50' },
        { key: 3, value: 100, desc: '100' },
        { key: 4, value: 150, desc: '150' },
        { key: 5, value: 200, desc: '200' }
    ];

    static  BookingStatuses: Array<IEnumItem> = [
        { key: 0, value: null, desc: 'Both'},
        { key: 1, value: false, desc: 'Pending' },
        { key: 2, value: true, desc: 'Booked' }
    ];

    static  TransportResponsibilities: Array<IEnumItem> = [
        { key: 0, value: 'Depot', desc: 'Depot' },
        { key: 1, value: 'Central', desc: 'Central' }
    ];

    static DateRange: Array<IEnumItem> = [
        { key: 0, value: '1Day', desc: '1 Day' },
        { key: 1, value: '3Days', desc: '3 Days' },
        { key: 2, value: '5Days', desc: '5 Days' },
        { key: 3, value: '1Week', desc: '1 Week' },
        { key: 4, value: '2Weeks', desc: '2 Weeks' },
        { key: 5, value: '3Weeks', desc: '3 Weeks' },
        { key: 6, value: '4Weeks', desc: '4 Weeks' },
        { key: 7, value: '6Weeks', desc: '6 Weeks' },
        { key: 8, value: '8Weeks', desc: '8 Weeks' },
        { key: 9, value: '12Weeks', desc: '12 Weeks' },
    ];

    static KpiTypes: Array<IEnumItem> = [
        { key: 1, value: 'Cancelled1', desc: 'Depot cancelled truck same day' },
        { key: 2, value: 'Cancelled2', desc: 'Depot cancelled truck 24-48 hour notice (less than required but not same day)' },
        { key: 3, value: 'Cancelled3', desc: 'Depot cancelled truck (over 48 hour notice)' },
        { key: 4, value: 'Unpriced', desc: 'Depot unpriced tickets' },
        { key: 5, value: 'DepotCommFailure', desc: 'Depot communication failure' },
        { key: 6, value: 'Detention', desc: 'Depot detention for lack of loading in timely manner' },
        { key: 7, value: 'MiscItem', desc: 'Depot misc item (good or bad) to note' },
        { key: 8, value: 'MissedPickup', desc: 'Truck missed pick-up' },
        { key: 9, value: 'MissedDelivery', desc: 'Truck missed delivery' },
        { key: 10, value: 'LateDelivery', desc: 'Truck late delivery, but not missed' },
        { key: 11, value: 'NotCover', desc: 'Truck did not cover at contracted rate' },
        { key: 12, value: 'TruckCovered', desc: 'Truck covered an SOS for us' },
        { key: 13, value: 'TruckArrived', desc: 'Truck arrived without info sent' },
        { key: 14, value: 'TruckCommFailure', desc: 'Truck communication failure' },
    ];
}

export class ListOfColumnNames {
    static defaultWidth = 8;
    static fieldsListForRequests: { name: string, colWidth: number, type: string }[] = [
        { name: 'Show All', colWidth: ListOfColumnNames.defaultWidth, type: 'showAll' },
        { name: 'Request Status', colWidth: ListOfColumnNames.defaultWidth, type: 'request' },
        { name: 'Request ID', colWidth: ListOfColumnNames.defaultWidth, type: 'request' },
        { name: 'Request Date', colWidth: ListOfColumnNames.defaultWidth, type: 'request' },
        { name: 'Status', colWidth: ListOfColumnNames.defaultWidth, type: 'request' },
        { name: 'Depot No', colWidth: ListOfColumnNames.defaultWidth, type: 'request' },
        { name: 'Heap', colWidth: ListOfColumnNames.defaultWidth, type: 'request' },
        { name: 'Ready Date', colWidth: ListOfColumnNames.defaultWidth, type: 'request' },
        { name: 'Requested Material Detail', colWidth: ListOfColumnNames.defaultWidth, type: 'request' },
        { name: 'Weight', colWidth: ListOfColumnNames.defaultWidth, type: 'request' },
        { name: 'Rem. Weight', colWidth: ListOfColumnNames.defaultWidth, type: 'request' },
        { name: 'Rem. Loads', colWidth: ListOfColumnNames.defaultWidth, type: 'request' },
        { name: 'Container - Packaging', colWidth: ListOfColumnNames.defaultWidth, type: 'request' },
        { name: 'Attachment', colWidth: ListOfColumnNames.defaultWidth, type: 'request' },
        { name: 'Notes/Comments', colWidth: ListOfColumnNames.defaultWidth, type: 'request' },
        { name: 'Grade', colWidth: ListOfColumnNames.defaultWidth, type: 'request' },
        { name: 'Allocation Number', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Allocated Date', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Allocation Status', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Allocation Weight', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Contract Ref', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Party', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Classification', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Instructions', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Haulier', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Sales Grade', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Apt Number', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Apt Date & Time', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Ticket Date', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Ticket Number', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Pick-up From Date & Time', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Pick-up To Date & Time', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Additional Costs', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' }];
    static fieldsListForAllocations: { name: string, colWidth: number, type: string }[] = [
        { name: 'Allocation Number', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Depot', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Request Date', colWidth: ListOfColumnNames.defaultWidth, type: 'request' },
        { name: 'Allocated Date', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Allocation Status', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Contract Header Status', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation'},
        { name: 'Allocated Weight', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Delivered Weight', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Contract Ref', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Grade', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Material Description', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Party', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Classification', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Ready Date', colWidth: ListOfColumnNames.defaultWidth, type: 'request' },
        { name: 'Pick-up From Date & Time', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Pick-up To Date & Time', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Instructions', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Haulier', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Notes/Comments', colWidth: ListOfColumnNames.defaultWidth, type: 'request' },
        { name: 'Container Type', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'FCF Serve', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Missed Drop Off', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Apt Number', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Apt Date & Time', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Apt Date & Time To', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Ticket Date', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Ticket Number', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Additional Costs', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Dock Receipt', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Booking Requested', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Awaiting Appointment', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Pending Dispatch' , colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'Processing Dispatched', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation'},
        { name: 'Dispatched', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation'},
        { name: 'KPI Type', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' },
        { name: 'KPI Cost', colWidth: ListOfColumnNames.defaultWidth, type: 'allocation' }
    ];
    static fieldsListForRouteToMarkets: { name: string, colWidth: number, type: string }[] = [
        { name: 'Depot', colWidth: ListOfColumnNames.defaultWidth, type: 'routeToMarket' },
        { name: 'Grade', colWidth: ListOfColumnNames.defaultWidth, type: 'routeToMarket' },
        { name: 'Destination', colWidth: ListOfColumnNames.defaultWidth, type: 'routeToMarket' },
        { name: 'Effective Date', colWidth: ListOfColumnNames.defaultWidth, type: 'routeToMarket' }
    ];
    static defaultSelectedColsForRequests: string[] = ['Request Date', 'Status', 'Depot No', 'Heap', 'Ready Date', 'Allocation Number',
        'Requested Material Detail', 'Rem. Weight', 'Rem. Loads',
        'Container - Packaging', 'Attachment', 'Allocated Date', 'Allocation Status', 'Contract Ref', 'Party', 'Ticket Number',
        'Pick-up From Date & Time', 'Pick-up To Date & Time', 'Additional Costs'];
    static defaultSelectedColsForAllocations: string[] = [
        'Allocation Number', 'Allocated Date',
        'Allocated Weight', 'Contract Ref',
        'Party', 'Classification',
        'Instructions', 'Haulier', 'Apt Number', 'FCF Serve', 'AwaitingDispatch',
        'Apt Date & Time', 'Apt Date & Time To', 'Ticket Date',
        'Ticket Number', 'Pick-up From Date & Time',
        'Pick-up To Date & Time', 'Depot', 'Container Type',
        'Request Date', 'Ready Date','Contract Header Status','KPI Type','KPI Cost'
    ];
    static defaultSelectedColsForRouteToMarkets: string[] = ['Depot', 'Grade', 'Destination', 'Effective Date'];
}
