export function isNullOrUndefined(value: any) {
	return value === null || value === undefined;
}


export function isUndefined(value: any){
	return value === undefined;
}

export function isNull(value: any){
	return value === null;
}