<mat-card layout="column" style="padding: 1px;padding-top:0px;">
    <div id="content">
        <mat-toolbar class="md-hue-3" style="background-color:#eceff1;height: 37px;">

            <form [formGroup]="requestTableViewForm" novalidate>
                <mat-form-field class=mat-Filer matTooltip="Field chooser" style="width: 18px;margin-right: 20px;">
                    <mat-select formControlName="columnsList" multiple (selectionChange)="updateColumns($event.value);">
                        <mat-option *ngFor="let column of columnList" [value]="column"
                            (onSelectionChange)="showAllColumns($event);">{{column}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class=mat-Filer matTooltip="Field chooser"
                    [ngClass]="!(requestTableViews?.length > 0) ? 'display-hidden' : 'display-block'">
                    <mat-select formControlName="requestTableView">
                        <mat-option *ngFor="let view of requestTableViews" [value]="view">{{view.viewName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
            <div class="md-toolbar-tools" style="color:rgb(46, 73, 97);">
                <button mat-button (click)="showSearchOptions()">
                    <i class="fa fa-search"></i>
                </button>
                <button mat-button class="md-raised" (click)="newRequest();" [disabled]="isLoading">New Request</button>
                <button *ngIf="showInventoryReservation" mat-button class="md-raised" (click)="newReservation();" [disabled]="isLoading">New
                    Reservation</button>

                <button *ngIf="selectedIsRequest" mat-button class="hide show-gt-xs"
                    [disabled]="!canEditSelectedRequest" (click)="editRequest()">Edit</button>
                <button *ngIf="selectedIsRequest && showInstructionToMove" mat-button class="hide show-gt-xs"
                    [disabled]="!canAddNewInstructionToMove" (click)="addNewInstructionToMoveFromRequest()">New
                    Instruction to Move</button>
                <button *ngIf="selectedIsRequest" mat-button class="md-raised mat-primary hide show-gt-xs"
                    [disabled]="!canDeleteSelectedRequest" (click)="deleteRequest()"> Delete </button>

                <button *ngIf="selectedIsAllocation" mat-button class="hide show-gt-xs"
                    [disabled]="!canEditSelectedAllocation" (click)="editAllocation()">Edit Allocation</button>
                <button *ngIf="selectedIsAllocation" mat-button class="md-raised mat-primary hide show-gt-xs"
                    [disabled]="!canDeleteSelected" (click)="deleteAllocation()"> Delete Allocation </button>

                <button *ngIf="selectedIsRequest && selectedRequestHasAllocations" mat-button
                    [disabled]="!canFullyAllocateSelected" (click)="fullyAllocate()">Mark Fully
                    Allocated</button>

                <span flex="" style="-ms-flex: 1; -webkit-flex: 1; flex: 1;"></span>
                <button mat-button class="md-raised mat-button mat-ink-ripple hide-xs" aria-label="Export to Excel"
                    testName="exportToExcel" (click)="exportToExcel()">Export to Excel</button>
            </div>
        </mat-toolbar>

        <div class="search-box">
            <div [hidden]="!bulkUpdateMode" class="row-box">
                <form [formGroup]="bulkUpdateForm" (ngSubmit)="bulkUpdate(bulkUpdateForm.value)">
                    <div class="row-container">
                        <mat-form-field mat-no-float style="margin-top: 0px">
                            <mat-select id="ddlLifecycleStatus" name="ddlLifecycleStatus"
                                formControlName="lifecycleStatusId" style="text-transform:none; font-weight:normal"
                                placeholder="Lifecycle Status">
                                <mat-option [value]="">
                                </mat-option>
                                <mat-option *ngFor="let s of pageModel?.statuses" [value]="s.id">
                                    {{ s.name }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field style="margin: 0px 0px; width: 800px;" mat-no-float>
                            <input matInput type="text" aria-multiline="true" placeholder="Comment"
                                formControlName="comment">
                        </mat-form-field>
                        <button type="submit" mat-button>Bulk Update</button>
                    </div>
                </form>
            </div>

            <form [hidden]="!showSearch" [formGroup]="searchForm" (ngSubmit)="search(searchForm.value)">
                <div class="row-box">
                    <div class="small-margin-right">
                        <mat-form-field style="margin: 0px 0px; max-width: 250px;">
                            <input type="text" matInput formControlName="region" [matAutocomplete]="regionAuto"
                                placeholder="Region" #regionInput oninput="this.value = this.value.toUpperCase()">
                        </mat-form-field>
                        <mat-autocomplete #regionAuto="matAutocomplete" [displayWith]="displayRegion">
                            <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
                            <mat-option *ngFor="let region of filteredRegion" [value]="region">
                                {{region}}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                    <div class="small-margin-right">
                        <mat-form-field style="margin: 0px 0px; max-width: 250px;">
                            <input type="text" matInput formControlName="depot" [matAutocomplete]="depotAuto"
                                placeholder="Depot" #depotInput oninput="this.value = this.value.toUpperCase()">
                        </mat-form-field>

                        <mat-autocomplete #depotAuto="matAutocomplete" [displayWith]="displayDepot">
                            <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
                            <mat-option *ngFor="let depot of filteredDepots?.items " [value]="depot">
                                {{depot?.depotNumber + ' - ' + depot?.depotName}}</mat-option>
                        </mat-autocomplete>
                    </div>
                    <div class="small-margin-right">
                        <mat-form-field mat-no-float style="margin:0px 0px; max-width: 80px;">
                            <input matInput id="heapSearch" type="search" formControlName="heap" placeholder="Heap"
                                oninput="this.value = this.value.toUpperCase()">
                        </mat-form-field>
                    </div>
                    <div class="small-margin-right">
                        <mat-form-field style="margin: 0px 0px; max-width: 80px;" mat-no-float>
                            <input matInput type="search" placeholder="Grade Group" formControlName="gradeGroupName">
                        </mat-form-field>
                    </div>
                    <div class="small-margin-right">
                        <mat-form-field style="margin: 0px 0px; max-width: 80px;" mat-no-float>
                            <input matInput type="number" placeholder="Request ID" formControlName="requestId">
                        </mat-form-field>
                    </div>
                    <div class="small-margin-right">
                        <mat-form-field style="margin: 0px 0px; max-width: 80px;" mat-no-float>
                            <input matInput type="search" placeholder="Grade" formControlName="grade">
                        </mat-form-field>
                    </div>
                    <div class="small-margin-right">
                        <mat-form-field style="margin: 0px 0px; max-width: 80px;" mat-no-float>
                            <input matInput type="search" placeholder="Sales Grade" formControlName="salesGrade"
                                oninput="this.value = this.value.toUpperCase()">
                        </mat-form-field>
                    </div>
                    <div class="small-margin-right">
                        <mat-form-field mat-no-float style="margin-top: 0px">
                            <mat-select id="ddlContainerPackaging" name="ddlContainerPackaging"
                                formControlName="containerPackaging" style="text-transform:none; font-weight:normal"
                                placeholder="Container/Packaging">
                                <mat-option [value]="">
                                </mat-option>
                                <mat-option *ngFor="let o of pageModel?.containerFilterList"
                                    [value]="o.containerPackagingCombinationName">
                                    {{ o.containerPackagingCombinationName }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="small-margin-right">
                        <mat-form-field style="margin: 0px 0px; max-width: 80px;" mat-no-float>
                            <input matInput type="search" placeholder="Classification" formControlName="classification">
                        </mat-form-field>
                    </div>

                    <div class="small-margin-right">
                        <mat-form-field style="margin: 0px 0px; max-width: 80px;" mat-no-float>
                            <input matInput type="search" placeholder="Contract Ref" formControlName="contractRef"
                                oninput="this.value = this.value.toUpperCase()">
                        </mat-form-field>
                    </div>
                    <div class="small-margin-right">
                        <mat-form-field mat-no-float style="margin-top: 0px">
                            <mat-select id="ddlLifecycleStatus" name="ddlLifecycleStatus"
                                formControlName="lifecycleStatusId" style="text-transform:none; font-weight:normal"
                                placeholder="Lifecycle Status">
                                <mat-option [value]="">
                                </mat-option>
                                <mat-option *ngFor="let s of pageModel?.statuses" [value]="s.id">
                                    {{ s.name }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row-box">
                    <div class="small-margin-right">
                        <mat-form-field style="margin: 0px 0px; max-width: 120px;" mat-no-float>
                            <input matInput type="number" min=0 placeholder="Allocation Number"
                                formControlName="allocationNumber">
                        </mat-form-field>
                    </div>
                    <div class="small-margin-right">
                        <mat-form-field style="margin: 0px 0px; max-width: 120px;" mat-no-float>
                            <input matInput type="number" min=0 placeholder="Instruction Number"
                                formControlName="instructionNumber">
                        </mat-form-field>
                    </div>
                    <div class="small-margin-right">
                        <mat-form-field style="margin: 0px 0px; max-width: 90px;" mat-no-float>
                            <input matInput type="search" placeholder="Ticket Number" formControlName="ticketNumber">
                        </mat-form-field>
                    </div>
                    <div class="small-margin-right">
                        <mat-form-field style="margin: 0px 0px; max-width: 80px;" mat-no-float>
                            <input matInput type="search" placeholder="Comment" formControlName="comment">
                        </mat-form-field>
                    </div>
                    <div class="small-margin-right">
                        <mat-form-field style="margin: 0px 0px; max-width: 80px;" mat-no-float>
                            <input matInput type="search" placeholder="Haulier" formControlName="haulierAccountNo"
                                oninput="this.value = this.value.toUpperCase()">
                        </mat-form-field>
                    </div>
                    <div class="small-margin-right">
                        <mat-form-field style="margin: 0px 0px; max-width: 80px;" mat-no-float>
                            <input matInput type="search" placeholder="Party" formControlName="partyAccountNo"
                                oninput="this.value = this.value.toUpperCase()">
                        </mat-form-field>
                    </div>
                    <div class="multi-select small-margin-right" style="max-width: 300x;">
                        <mat-form-field matTooltip="Request Status">
                            <mat-select multiple placeholder="Request Status" formControlName="requestStatusesControl"
                                [compareWith]="compareRequestStatus">
                                <mat-option *ngFor="let status of requestStatuses" [value]="status"
                                    (onSelectionChange)="selectRequestStatus($event, status.key)">
                                    {{status.desc}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="multi-select small-margin-right" style="max-width: 300x;">
                        <mat-form-field matTooltip="Allocation Status">
                            <mat-select multiple placeholder="Allocation Status"
                                formControlName="allocationStatusesControl">
                                <mat-option *ngFor="let status of allocationStatuses" [value]="status"
                                    (onSelectionChange)="selectAllocationStatus($event, status.key)">
                                    {{status.desc}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="small-margin-right">
                        <mat-form-field matTooltip="Preset Filters">
                            <mat-select #matRef multiple placeholder="Preset Fitlers">
                                <mat-option *ngFor="let filter of pageModel?.filterNames" [value]="filter.id"
                                    (onSelectionChange)="filterSelection($event, filter);">{{filter.filterName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="small-margin-right">
                        <mat-form-field style="margin: 0px 0px; max-width: 150px;" mat-no-float>
                            <mat-select formControlName="isActive" placeholder="Instruction to Move Status">
                                <mat-option></mat-option>
                                <mat-option *ngFor="let tm of onHold" [value]="tm.value">{{tm.name}}</mat-option>
                              </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="button-container">
                        <button type="submit" mat-stroked-button>Apply Filter</button>
                        <button mat-stroked-button (click)="clearFilter()" type="button">Clear Filters</button>
                        <button mat-stroked-button (click)="copyLink()" type="button">Copy Link</button>
                    </div>
                </div>
            </form>
        </div>
        <app-spinner [displayProgressSpinner]="isLoading" #spinner></app-spinner>
        <div class="table-responsive">
            <div style="width: 100%;overflow-y: auto;" [ngStyle]="{'height.px': tableHeight}" #mainTable>
                <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort
                    (matSortChange)="sortByInfo($event)" name="pendingtable" id="pending-table" cellpadding="0"
                    cellspacing="0" class="table table-hover table-condensed custom-table" border="0"
                    [style.width.%]="tableWidth">

                    <ng-container matColumnDef="toggle">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="interactive heading-row"
                            sortActionDescription="Sort by number">
                        </th>
                        <td mat-cell *matCellDef="let element" class="expand-icon">
                            <span *ngIf="hasAllocationsOrReservation(element)" (click)="toggleAllocations(element)"
                                class="icon">
                                <i class="fa fa-plus-circle" *ngIf="!element.showingAllocations"></i>
                                <i class="fa fa-minus-circle" *ngIf="element.showingAllocations"></i>
                            </span>
                            
                            <span class="material-symbols-outlined" *ngIf="element.isReservation">
                                all_match
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Request Status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="RequestStatus"
                            class="interactive heading-row" sortActionDescription="Sort by request status">
                            Request Status
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{parseRequestStatus(element.requestStatus)}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="Status" class="interactive heading-row"
                            sortActionDescription="Sort by status">
                            Status
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Request Date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="RequestDate"
                            class="interactive heading-row" sortActionDescription="Sort by requestDate">
                            Request Date
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.requestDate | momentUtcAsLocaleDateTime}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Depot No">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="Depot" class="interactive heading-row"
                            sortActionDescription="Sort by depot">
                            Depot No
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.depot}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Heap">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="Heap" class="interactive heading-row"
                            sortActionDescription="Sort by heap">
                            Heap
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.heap | shorten : '30'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Ready Date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="ReadyDate"
                            class="interactive heading-row" sortActionDescription="Sort by readyDate">
                            Ready Date
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div style="float: left;">{{element.readyDate | momentUtcAsLocaleDate}}</div>
                            <div class="icon-placeholder load-times-placeholder"
                                style="text-align:center;padding-left: 0px;" (click)="showLoadTimes(element)"
                                *ngIf="element.loadTimes">
                                <span><i class="fa fa-clock-o" aria-hidden="true"></i></span>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Requested Material Detail">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="GradeDesc"
                            class="interactive heading-row" sortActionDescription="Sort by gradeDesc">
                            Requested Material Detail
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.gradeDesc | shorten : '30'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Weight">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="Weight" class="interactive heading-row"
                            sortActionDescription="Sort by weight">
                            Weight
                        </th>
                        <td mat-cell *matCellDef="let element">{{element?.weight | number : '1.0-1'}}
                            {{element?.uomCode}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Rem. Weight">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="RemainingWeight"
                            class="interactive heading-row" sortActionDescription="Sort by remainingWeight">
                            Rem. Weight
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element?.remainingWeight | number :
                            '1.0-1'}}
                            {{element?.uomCode}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Rem. Loads">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="RemainingLoads"
                            class="interactive heading-row" sortActionDescription="Sort by remainingLoads">
                            Rem. Loads
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.remainingLoads}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Container - Packaging">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="ContainerPackaging"
                            class="interactive heading-row" sortActionDescription="Sort by containerPackaging">
                            Container - Packaging
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.containerPackaging}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Notes/Comments">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="Comment" class="interactive heading-row"
                            sortActionDescription="Sort by request status">
                            Notes/Comments
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="icon-placeholder comment-placeholder">
                                <span (click)="showComments(element)">
                                    <i class="fa fa-lg fa-commenting" *ngIf="element.comment?.length > 0"></i>
                                    <i class="fa fa-lg fa-comment"
                                        *ngIf="!element.comment || element.comment.length === 0"></i>
                                </span>
                                <span *ngIf="element?.hasHistory" (click)="requestHistory(element?.requestId)">
                                    <fa-icon [icon]="'history'"></fa-icon>
                                </span>
                            </div>
                            <div *ngIf="element.comment?.length > 0">
                                <div class="note-comment">{{element?.comment}}</div>
                                <div *ngIf="element?.commentAuthorName">
                                    <br />
                                    <small style="color:darkgrey">
                                        {{ element?.commentAuthorName }}&nbsp;{{element.commentTimestamp |
                                        momentUtcAsLocaleTime }}
                                    </small>
                                </div>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Attachment">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="AttachmentCount"
                            class="interactive heading-row" sortActionDescription="Sort by attachmentCount">
                            Attachment
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="attachment-container">
                                <i class="fa fa-paperclip" aria-hidden="true" (click)="showAttachments(element)"></i>
                                <span *ngIf="element.attachmentCount > 0">({{element?.attachmentCount}})</span>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Grade">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="Grade" class="interactive heading-row"
                            sortActionDescription="Sort by grade">
                            Grade
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element?.grade}} </td>
                    </ng-container>

                    <!-- allocation-detail-row -->
                    <ng-container matColumnDef="allocationDetail">
                        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                            <ng-container [ngTemplateOutlet]="allocationDetail"
                                *ngIf="element.showingAllocations && hasAllocations(element)"
                                [ngTemplateOutletContext]="{element:element}"></ng-container>

                        </td>
                    </ng-container>
                    <ng-container matColumnDef="instructionDetail">
                        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                            <ng-container [ngTemplateOutlet]="instructionDetail" *ngIf="element.showingAllocations && hasReservation(element)"
                                [ngTemplateOutletContext]="{element:element}"></ng-container>

                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="heading-row sticky"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                        [ngClass]="{ 'selected' : row.isSelected }" style="height: 36px;"
                        (click)="selectRequest(row, $event)"></tr>
                    <tr mat-row *matRowDef="let row; columns: ['allocationDetail']" class="allocation-detail-row"></tr>
                    <tr mat-row *matRowDef="let row; columns: ['instructionDetail']" class="allocation-detail-row"></tr>
                </table>
            </div>

            <table style="width: 100%;" *ngIf="pendingAndAllocated.length > 0">
                <tfoot>
                    <tr>
                        <td style="text-align: center;">
                            <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                                [maxSize]="5" [rotate]="true" [boundaryLinks]="collectionSize >= 100"
                                (pageChange)="pageChanged($event)" aria-label="Default pagination"></ngb-pagination>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>

        <ng-template #allocationDetail let-element='element'>
            <table class="table sub-table" [style.width.%]="85" *ngIf="element.showingAllocations">
                <thead>
                    <th style="width: .1%;">
                    </th>
                    <th *ngIf="allocationDisplayedColumns.includes('Allocation Number')" appTableSort='AllocationNumber'
                        (sort)="sortByInfo($event)" class="interactive">
                        Allocation Number</th>
                    <th *ngIf="allocationDisplayedColumns.includes('Allocation Status')" appTableSort='AllocationStatus'
                        (sort)="sortByInfo($event)" class="interactive">
                        Allocation Status</th>
                    <th *ngIf="allocationDisplayedColumns.includes('Allocated Date')" appTableSort="AllocatedDate"
                        (sort)="sortByInfo($event)" reverseIsDefault="true" class="interactive hide-sm hide-xs">
                        Allocated Date</th>
                    <th *ngIf="allocationDisplayedColumns.includes('Allocated Weight')">Allocated Weight
                    </th>
                    <!-- ({{uomCode}}) -->
                    <th *ngIf="allocationDisplayedColumns.includes('Contract Ref')" appTableSort='ContractRef'
                        (sort)="sortByInfo($event)" class="interactive">Contract Ref</th>
                    <th *ngIf="allocationDisplayedColumns.includes('Party')" appTableSort='Party'
                        (sort)="sortByInfo($event)" class="interactive">Party</th>
                    <th *ngIf="allocationDisplayedColumns.includes('Classification')" appTableSort='Classification'
                        (sort)="sortByInfo($event)" class="interactive">
                        Classification</th>
                    <th *ngIf="allocationDisplayedColumns.includes('Instructions')" appTableSort='Instructions'
                        (sort)="sortByInfo($event)" class="interactive">Instructions</th>
                    <th *ngIf="allocationDisplayedColumns.includes('Haulier')" appTableSort='Haulier'
                        (sort)="sortByInfo($event)" class="interactive">Haulier</th>
                    <th *ngIf="allocationDisplayedColumns.includes('Sales Grade')" appTableSort='SalesGrade'
                        (sort)="sortByInfo($event)" class="interactive">Sales Grade</th>
                    <th *ngIf="allocationDisplayedColumns.includes('Apt Date & Time')" appTableSort="AptDateTime"
                        (sort)="sortByInfo($event)" reverseIsDefault="true" class="interactive hide-sm hide-xs">
                        Apt Date & Time</th>
                    <th *ngIf="allocationDisplayedColumns.includes('Ticket Date')" appTableSort="TicketDate"
                        (sort)="sortByInfo($event)" reverseIsDefault="true" class="interactive hide-sm hide-xs">
                        Ticket Date</th>
                    <th *ngIf="allocationDisplayedColumns.includes('Ticket Number')" appTableSort='TicketNumber'
                        (sort)="sortByInfo($event)" class="interactive">Ticket Number</th>
                    <th *ngIf="allocationDisplayedColumns.includes('Pick-up From Date & Time')" class="interactive">
                        Pick-up From Date & Time
                    </th>
                    <th *ngIf="allocationDisplayedColumns.includes('Pick-up To Date & Time')" class="interactive">
                        Pick-up To Date & Time
                    </th>
                    <th *ngIf="allocationDisplayedColumns.includes('Additional Costs')">Additional Costs</th>
                </thead>
                <tbody>
                    <ng-template ngFor let-a [ngForOf]="element?.allocations"
                        *ngIf="element?.allocations.length > 0 && element.showingAllocations">
                        <tr (click)="selectAllocation(a, true)" [ngClass]="{ 'selected' : a.isSelected }">
                            <td class="expand-icon">
                                <span *ngIf="isGrouped(a)" (click)="toggleGroup(a)" class="icon">
                                    <fa-icon [icon]="'plus-circle'" *ngIf="!a?.showingGroup"></fa-icon>
                                    <fa-icon [icon]="'minus-circle'" *ngIf="a?.showingGroup"></fa-icon>
                                </span>
                            </td>
                            <td *ngIf="allocationDisplayedColumns.includes('Allocation Number')">
                                {{isGrouped(a) ? 'Multi' : a?.allocationNumber}}
                            </td>
                            <td *ngIf="allocationDisplayedColumns.includes('Allocation Status')">
                                {{ isDeleted(a) ? 'Deleted' :
                                parseAllocationStatus(a?.contractLineAllocationStatus)}}
                            </td>
                            <td *ngIf="allocationDisplayedColumns.includes('Allocated Date')" class="hide-sm hide-xs">
                                {{a?.allocatedDate | momentUtcAsLocaleDate}}</td>
                            <td *ngIf="allocationDisplayedColumns.includes('Allocated Weight')">
                                {{a?.allocatedWeight |
                                number : '1.0-1'}}</td>
                            <td *ngIf="allocationDisplayedColumns.includes('Contract Ref')">
                                {{a?.contractRef}}</td>
                            <td *ngIf="allocationDisplayedColumns.includes('Party')">{{a?.party}}</td>
                            <td *ngIf="allocationDisplayedColumns.includes('Classification')">
                                {{a?.classification}}</td>
                            <td *ngIf="allocationDisplayedColumns.includes('Instructions')">
                                {{a?.instructions| shorten : '30'}}</td>
                            <td *ngIf="allocationDisplayedColumns.includes('Haulier')">{{a?.haulier}}</td>
                            <td *ngIf="allocationDisplayedColumns.includes('Sales Grade')">{{a?.salesGrade}}
                            </td>
                            <td *ngIf="allocationDisplayedColumns.includes('Apt Date & Time')" class="hide-sm hide-xs">
                                {{a?.aptDateTime | momentUtcAsLocaleDateTime}}</td>
                            <td *ngIf="allocationDisplayedColumns.includes('Ticket Date')" class="hide-sm hide-xs">
                                {{a?.ticketDate | momentUtcAsLocaleDate}}</td>
                            <td *ngIf="allocationDisplayedColumns.includes('Ticket Number')">
                                {{a?.ticketNumber}}</td>
                            <td *ngIf="allocationDisplayedColumns.includes('Pick-up From Date & Time')"
                                class="hide-sm hide-xs">
                                {{a?.requestedMovementDate | momentUtcAsLocaleDateTime}}
                            </td>
                            <td *ngIf="allocationDisplayedColumns.includes('Pick-up To Date & Time')"
                                class="hide-sm hide-xs">
                                {{a?.requestedMovementDateEnd |
                                momentUtcAsLocaleDateTime}}</td>
                            <td *ngIf="allocationDisplayedColumns.includes('Additional Costs')">
                                {{formatCosts(a?.additionalCosts)}}</td>
                        </tr>
                        <tr *ngIf="a?.showingGroup">
                            <td colspan="25">
                                <table class="table sub-table" [style.width.%]="90">
                                    <thead>
                                        <th *ngIf="allocationDisplayedColumns.includes('Allocation Number')"
                                            class="interactive">Allocation Number</th>
                                        <th *ngIf="allocationDisplayedColumns.includes('Allocation Status')"
                                            class="interactive">Allocation Status</th>
                                        <th *ngIf="allocationDisplayedColumns.includes('Allocated Date')"
                                            class="interactive hide-sm hide-xs">
                                            Allocated Date</th>
                                        <th *ngIf="allocationDisplayedColumns.includes('Allocated Weight')">
                                            Allocated Weight
                                            ({{uomCode}})</th>
                                        <th *ngIf="allocationDisplayedColumns.includes('Contract Ref')"
                                            class="interactive">Contract Ref</th>
                                        <th *ngIf="allocationDisplayedColumns.includes('Contract Ref')"
                                            class="interactive">Party</th>
                                        <th *ngIf="allocationDisplayedColumns.includes('Classification')"
                                            class="interactive">Classification</th>
                                        <th *ngIf="allocationDisplayedColumns.includes('Instructions')"
                                            class="interactive">Instructions</th>
                                        <th *ngIf="allocationDisplayedColumns.includes('Haulier')" class="interactive">
                                            Haulier</th>
                                        <th *ngIf="allocationDisplayedColumns.includes('Sales Grade')"
                                            class="interactive">Sales Grade</th>
                                        <th *ngIf="allocationDisplayedColumns.includes('Apt Number')"
                                            class="interactive">Apt Number</th>
                                        <th *ngIf="allocationDisplayedColumns.includes('Apt Date & Time')"
                                            class="interactive hide-sm hide-xs">Apt Date
                                            & Time</th>
                                        <th *ngIf="allocationDisplayedColumns.includes('Ticket Date')"
                                            class="interactive hide-sm hide-xs">Ticket
                                            Date</th>
                                        <th *ngIf="allocationDisplayedColumns.includes('Ticket Number')"
                                            class="interactive">Ticket Number</th>
                                        <th *ngIf="allocationDisplayedColumns.includes('Pick-up From Date & Time')"
                                            class="interactive">
                                            Pick-up From Date & Time
                                        </th>
                                        <th *ngIf="allocationDisplayedColumns.includes('Pick-up To Date & Time')"
                                            class="interactive">
                                            Pick-up To Date & Time
                                        </th>
                                        <th *ngIf="allocationDisplayedColumns.includes('Additional Costs')">
                                            Additional Costs</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let g of getGroupAllocations(a?.groupId)"
                                            (click)="selectAllocation(g, false)"
                                            [ngClass]="{ 'selected' : g.isSelected }">
                                            <td *ngIf="allocationDisplayedColumns.includes('Allocation Number')">
                                                {{g?.allocationNumber}}
                                            </td>
                                            <td *ngIf="allocationDisplayedColumns.includes('Allocation Status')">
                                                {{ !g?.active ? 'Deleted' :
                                                parseAllocationStatus(g?.contractLineAllocationStatus)}}
                                            </td>
                                            <td *ngIf="allocationDisplayedColumns.includes('Allocated Date')"
                                                class="hide-sm hide-xs">
                                                {{a?.allocatedDate |
                                                momentUtcAsLocaleDate}}</td>
                                            <td *ngIf="allocationDisplayedColumns.includes('Allocated Weight')">
                                                {{g?.allocatedWeight |
                                                number : '1.0-1'}}</td>
                                            <td *ngIf="allocationDisplayedColumns.includes('Contract Ref')">
                                                {{g?.contractRef}}</td>
                                            <td *ngIf="allocationDisplayedColumns.includes('Party')">
                                                {{g?.party}}</td>
                                            <td *ngIf="allocationDisplayedColumns.includes('Classification')">
                                                {{g?.classification}}</td>
                                            <td *ngIf="allocationDisplayedColumns.includes('Instructions')">
                                                {{g?.instructions}}</td>
                                            <td *ngIf="allocationDisplayedColumns.includes('Haulier')">
                                                {{g?.haulier}}</td>
                                            <td *ngIf="allocationDisplayedColumns.includes('Sales Grade')">
                                                {{g?.salesGrade}}</td>
                                            <td *ngIf="allocationDisplayedColumns.includes('Apt Number')">
                                                {{g?.aptNumber}}</td>
                                            <td *ngIf="allocationDisplayedColumns.includes('Apt Date & Time')"
                                                class="hide-sm hide-xs">
                                                {{g?.aptDateTime |
                                                momentUtcAsLocaleDateTime}}</td>
                                            <td *ngIf="allocationDisplayedColumns.includes('Ticket Date')"
                                                class="hide-sm hide-xs">
                                                {{g?.ticketDate |
                                                momentUtcAsLocaleDate}}</td>
                                            <td *ngIf="allocationDisplayedColumns.includes('Ticket Number')">
                                                {{g?.ticketNumber}}</td>
                                            <td *ngIf="allocationDisplayedColumns.includes('Pick-up From Date & Time')"
                                                class="hide-sm hide-xs">
                                                {{g?.requestedMovementDate |
                                                momentUtcAsLocaleDateTime}}</td>
                                            <td *ngIf="allocationDisplayedColumns.includes('Pick-up To Date & Time')"
                                                class="hide-sm hide-xs">
                                                {{g?.requestedMovementDateEnd |
                                                momentUtcAsLocaleDateTime}}</td>
                                            <td *ngIf="allocationDisplayedColumns.includes('Additional Costs')">
                                                {{formatCosts(g?.additionalCosts)}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </ng-template>
                </tbody>
            </table>
        </ng-template>
        <ng-template #instructionDetail let-element='element'>
            <table class="table sub-table" [style.width.%]="85" *ngIf="element.showingAllocations">
                <thead>
                    <th>Type</th>
                    <th appTableSort='id' (sort)="sortByInfo($event)" class="interactive">
                        ID Number</th>
                    <th appTableSort='isActive' (sort)="sortByInfo($event)" class="interactive">
                        Status</th>
                    <th appTableSort='destination' (sort)="sortByInfo($event)" class="interactive">
                        Destination</th>
                    <th appTableSort="dueFrom" (sort)="sortByInfo($event)" reverseIsDefault="true"
                        class="interactive hide-sm hide-xs">
                        Due From Date</th>
                    <th appTableSort="dueBy" (sort)="sortByInfo($event)" reverseIsDefault="true"
                        class="interactive hide-sm hide-xs">
                        Due By Date</th>
                    <th appTableSort="priority" (sort)="sortByInfo($event)" reverseIsDefault="true"
                        class="interactive hide-sm hide-xs">
                        Priority</th>
                    <th appTableSort="contract" (sort)="sortByInfo($event)" reverseIsDefault="true"
                        class="interactive hide-sm hide-xs">
                        Contract Number</th>
                    <th appTableSort="transportMode" (sort)="sortByInfo($event)" reverseIsDefault="true"
                        class="interactive hide-sm hide-xs">
                        Transport Mode</th>

                    <th appTableSort='grade' (sort)="sortByInfo($event)" class="interactive">Grade</th>
                    <th>Weight
                    </th>
                    <th>Loads
                    </th>
                    <th>Container
                    </th>
                    <th>Packaging
                    </th>
                    <th>Unit
                    </th>
                    <th>Creation Date
                    </th>
                </thead>
                <tbody>
                    <ng-template ngFor let-a [ngForOf]="element?.instructions"
                        *ngIf="element?.instructions.length > 0 && element.showingAllocations">
                        <tr [ngClass]="{ 'selected' : a.isSelected }">
                            <td> Instruction to Move</td>
                            <td>
                                {{a?.id}}
                            </td>
                            <td>
                                {{a.isActive ? 'Active' : 'In Active'}}
                            </td>
                            <td>
                                {{ a?.destination }}
                            </td>
                            <td class="hide-sm hide-xs">
                                {{a?.dueFrom | momentUtcAsLocaleDate}}</td>
                            <td class="hide-sm hide-xs">
                                {{a?.dueBy | momentUtcAsLocaleDate}}</td>
                            <td>
                                {{a?.priority }}</td>
                            <td>
                                {{a?.contract}}</td>
                            <td>{{a?.transportMode}}</td>
                            <td>{{a?.grade}}
                            </td>
                            <td>
                                {{a?.weight}}</td>
                            <td>
                                {{a?.loads}}</td>
                            <td>
                                {{a?.container}}</td>
                            <td>
                                {{a?.packaging}}
                            </td>
                            <td>
                                {{a?.unit }}</td>
                            <td class="hide-sm hide-xs">
                                {{a?.createDate | momentUtcAsLocaleDate}}</td>
                        </tr>
                    </ng-template>
                </tbody>
            </table>
        </ng-template>

    </div>
</mat-card>