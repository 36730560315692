import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InitModel, UomVm, DepotVm, ApiPageResponse,
    HeapVm, AllocateModel } from '../gen/models';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators, FormArray, FormGroupDirective, AbstractControl } from '@angular/forms';
import { UomsService } from '../gen/services/uoms.service';
import { DepotService } from '../gen/services/depot.service';
import { HeapsService } from '../gen/services/heaps.service';
import { InitService } from '../services/init.service';
import { isNullOrUndefined } from '../tools';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { DateAdapter } from '@angular/material/core';
import { GradeService } from '../gen/services/grade.service';
import { AllocateService } from '../gen/services/allocate.service';
import { AllocationService } from '../gen/services/allocation.service';
import { NewInventoryReservation } from '../gen/models/NewInventoryReservation';
import { InventoryReservationVm } from '../gen/models/InventoryReservationVm';
import { RegionService } from '../gen/services/region.service';
import { CustomValidators } from '../shared/custom-validators';

@Component({
    selector: 'app-new-inventory-reservation-dialog',
    templateUrl: './new-inventory-reservation-dialog.component.html',
    styleUrls: ['./new-inventory-reservation-dialog.component.scss']
})
export class NewInventoryReservationDialogComponent implements OnInit {

    pageModel: InitModel;
    
    defaultUom: UomVm = { id: 2, code: 'LB', name: 'Pounds' };
    uoms: UomVm[];
    myDate: Date;
    minDate: Date;
    maxDate: Date;
    errorCount = 0;
    transportModes: string[] = ['TRUCK', 'CONTAINER', 'RAIL', 'SHIP'];
    onHold = [{name: 'Yes', value: true},{name: 'No', value: false}];
    selectedDepot: DepotVm;
    defaultDepot: DepotVm;
    filteredOptions: Observable<string[]>;
    cloning = false;
    shownLoadTimes: number;
    loadTimesStyle = {};
    isLoading = false;
    instructionToMoveForm: FormGroup;
    materialSubForm: FormGroup;
    depots = [];
    heaps = [];
    increments = ['15 minutes', '20 minutes', '25 minutes', '30 minutes', '45 minutes', '60 minutes', '90 minutes', '120 minutes'];
    incrementMinutes = [15, 20, 25, 30, 45, 60, 90, 120];
    filteredDepots: ApiPageResponse<DepotVm>;
    filteredHeaps: ApiPageResponse<HeapVm>;
    depotSelected: boolean;
    destinationSelected: boolean;
    isSubmit = false;
    destinationModelChanged: Subject<string> = new Subject<string>();
    destinationindex: number;
    filteredContractRef: ApiPageResponse<AllocateModel>;
    contractHeaderindex: number;
    contractRefModelChanged: Subject<string> = new Subject<string>();
    gradeModelChanged: Subject<string> = new Subject<string>();
    filteredGrade: string[];
    gradeindex: number;
    regionModelChanged: Subject<string> = new Subject<string>();
    filteredRegion: string[];
    regionindex: number;
    depotModelChanged: Subject<string> = new Subject<string>();
    depotindex: number;
    heapModelChanged: Subject<string> = new Subject<string>();
    heapindex: number;
    statuses = [];
    constructor(
		public gradeService: GradeService,
        public regionService: RegionService,
		public dialogRef: MatDialogRef<NewInventoryReservationDialogComponent>,
        private allocationService: AllocationService,
		private uomsService: UomsService,
		private depotsService: DepotService,
		private heapService: HeapsService,
		private initService: InitService,
		private fb: FormBuilder,
		private toastr: ToastrService,
		private _adapter: DateAdapter<any>,
		private allocateService: AllocateService,
		@Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
		
        this._adapter.setLocale(window.navigator.language);
        const defaultReadyDate = new Date();
        defaultReadyDate.setDate(defaultReadyDate.getDate() + 7);
        const defaultExpiryDate  = new Date( moment(defaultReadyDate).add(7, 'days').format('YYYY-MM-DD'));
        this.instructionToMoveForm = this.fb.group({
            readyDate: [defaultReadyDate, Validators.required],
            expiryDate: [defaultExpiryDate, Validators.required],
            time: '',
            materials: this.fb.array([]),
        });
		
        this.initService.initData$.subscribe(r => {
            this.pageModel = r;
            this.addMaterial();
        });
        this.uomsService.getAll().subscribe(r => this.uoms = r);
        this.myDate = new Date();
        this.minDate = new Date(
            this.myDate.getFullYear(),
            this.myDate.getMonth(),
            this.myDate.getDate()
        );
        this.maxDate = new Date(
            this.myDate.getFullYear() + 1,
            this.myDate.getMonth(),
            this.myDate.getDate()
        );
    }

    get depot() {
        return this.materials.controls[this.depotindex !== undefined ? this.depotindex : 0].get('depot');
    }

    get materials() {
        return this.instructionToMoveForm.get('materials') as FormArray;
    }

    get totalWeight() {
        return this.materials.controls.map(c => c.get('weight').value).reduce((a, b) => a + b, 0);
    }
    uom(i: number) {
        return this.materials[i].get('uom');
    }

    get grade() {
        return this.materials.controls[this.gradeindex !== undefined ? this.gradeindex : 0].get('grade');
    }
    get region() {
        return this.materials.controls[this.regionindex !== undefined ? this.regionindex : 0].get('region');
    }
    get heap() {
        return this.materials.controls[this.heapindex !== undefined ? this.heapindex : 0].get('heapNo');
    }
    weight(i: number) {
        return this.materials[i].get('weight');
    }
    tl(i: number) {
        return this.materials[i].get('tl');
    }
    loadTimes(i: number) {
        return this.materials[i].get('loadTimes') as FormArray;
    }
    containerSize(i: number) {
        return this.materials[i].get('containerSize');
    }
    packaging(i: number) {
        return this.materials[i].get('packaging');
    }
    status(i: number) {
        return this.materials[i].get('status');
    }

    filterDepots(val: string, region: string) {
        return this.depotsService.searchDepotsByRegion(val, region);
    }
    displayDepot(depot?: DepotVm): string | undefined {
        return depot && depot.depotNumber && depot.depotName ? depot.depotNumber + ' ' + depot.depotName : undefined;
    }

    filterHeaps(searchTerm: string, depot: AbstractControl) {
        if (!isNullOrUndefined(depot.value)) {
            const formDepot = depot.value as DepotVm;
            if(!isNullOrUndefined(formDepot) && !isNullOrUndefined(formDepot.depotNumber) && formDepot.depotNumber !== '' && 
            formDepot.depotNumber !== 'NODEPOT' && !isNullOrUndefined(searchTerm) && typeof(searchTerm) === 'string') {
                return this.heapService.search(formDepot.depotNumber, searchTerm);
            }
        }
        return of();
    }

    displayHeap(heap?: HeapVm): string | undefined {
        return heap ? heap.heapShortName + ' ' + heap.heapDesc : undefined;
    }
    
    submitForm() {
        this.errorCount = 0;
        if (!this.instructionToMoveForm.valid) { 
            const unsetErrors = this.materials.controls.filter(c => !isNullOrUndefined(c.errors) && !isNullOrUndefined(c.errors.unset)).map(x => x.errors.unset as string);
            if(unsetErrors.length > 0) {
                this.toastr.error(unsetErrors[0], 'Form Invalid');
            }
            return;
        }
        const value = this.instructionToMoveForm.value;
        this.isLoading = true;
        this.isSubmit = true;
        const localMoment =  moment().local();
        const stringDate = localMoment.format('YYYY-MM-DDTHH:mm:ss');
        const readyDate = new Date(moment(value.readyDate).format('YYYY-MM-DD'));
        const expiryDate = new Date(moment(value.expiryDate).format('YYYY-MM-DD'));
        const model: NewInventoryReservation = {
            requestDate: stringDate,
            readyDate: readyDate,
            expiryDate: expiryDate,
            inventoryReservations: value.materials.map(m => {

                if(!((!isNullOrUndefined(m.depot ) && m.depot != '' && !isNullOrUndefined(m.heap ) && m.heap != '') || 
                (!isNullOrUndefined(m.region) && !isNullOrUndefined(m.grade) && m.region != '' && m.grade != '')))
                {
                    this.errorCount++;
                    this.toastr.error('Depot & Heap or Region & Grade are required', 'Form Invalid');
                    return;
                }
                if(!isNullOrUndefined(m.depot) &&  m.depot != '' && m.depot.depotNumber != 'NODEPOT' 
                && (isNullOrUndefined(m.heap)|| m.heap == ''))
                {
                    this.errorCount++;
                    this.toastr.error('Heap is required', 'Form Invalid');
                    return;
                }
                if(m?.heap?.gradeCode && m?.heap?.gradeCode != m.grade){
                    this.errorCount++;
                    this.toastr.error('Invalid Grade, it should be ' + m?.heap?.gradeCode, 'Form Invalid');
                    return;
                }
                const readyAt = !isNullOrUndefined(m.readyAt)
                    ? new Date(moment(m.readyAt).format('YYYY-MM-DD')) : readyDate;
                const expiryAt = !isNullOrUndefined(m.expiryDate) 
                    ? new Date(moment(m.expiryDate).format('YYYY-MM-DD')) : expiryDate;
                if(moment(readyAt).isAfter(moment(expiryAt))){
                    this.errorCount++;
                    this.toastr.error(`Date from (${moment(readyAt).format('YYYY-MM-DD')}) should be less than Date to (${moment(expiryAt).format('YYYY-MM-DD')})`, 'Form Invalid');
                    return;
                }
                const packagingId = !isNullOrUndefined(m.packaging) ? m.packaging : null;
                const containerType =!isNullOrUndefined(m.containerSizeId) ? m.containerSizeId : null;
                const mat: InventoryReservationVm = {
                    Depot: m.depot != null  ? m.depot.depotNumber : null,
                    Grade: m.grade,
                    Region: m.region,
                    MaterialDescription: m.heap !== null ? m.heap.heapDesc : null,
                    HeapNo: m.heap !== null ? m.heap.heapNo : null,
                    Heap: m.heap !== null ? m.heap.heapDesc : null,
                    ReadyAt: readyAt,
                    ExpiryDate: expiryAt,
                    ContainerSizeId: containerType,
                    PackageOptionId: packagingId,
                    TotalLoads: m.totalLoads,
                    UomId: m.uomId,
                    Comments: m.comments,
                    Weight: m.weight,
                    RemainingWeight: m.weight,
                    RemainingLoads: m.totalLoads,
                    status: { id: m.status ?? 7, name: null, isActive: true },
                    isReservation : true
                };
                return mat;
            })
        };
        if(this.errorCount > 0)
        {
            this.isLoading = false;
            this.isSubmit = false;
            return;
        }
        this.allocationService.createNewRequests(model).subscribe(() => {
            this.toastr.success('Inventory reservation successfully created', 'New Inventory reservation');
            this.closeDialog();
        },
        err => {
            if (err.status === 200) {
                this.toastr.success('Inventory reservation successfully created', 'New Inventory reservation');
                this.closeDialog();
            }
            else {
                this.toastr.error('Something went wrong and the Inventory reservation could not be created. Please try again later.', 'New Request Failed');
                this.isLoading = false;
                this.isSubmit = false;
            }     
        });
    }

    commentPlaceholder(commentControl: AbstractControl) {                
        if (commentControl && commentControl.value &&  commentControl.value.length) {
            return `Provide a comment (${commentControl.value.length} / 500)`;
        }
        
        return 'Provide a comment (optional)';
    }

    private closeDialog() {
        setTimeout(() => {
            this.isLoading = false;
            this.dialogRef.close('callServer');
        }, 1500);
    }

    reset(formDir: FormGroupDirective) {
        this.instructionToMoveForm.reset();
        formDir.resetForm();
        this.depot.setValue(this.defaultDepot);
        this.depotSelected = true;
    }

    addMaterial() {
        const lastItemIndex = this.materials.length - 1;
        if (lastItemIndex > -1) {
            const lastMaterial = this.materials.controls[lastItemIndex] as FormGroup;
            lastMaterial.controls.submitted.patchValue(true);
        }
        const group = this.newMaterial();
        group.reset();
        this.materials.push(group);
        this.toggleContainerSizeAndUom();
    }

    removeMaterial(index: number) {
        this.materials.removeAt(index);
        if (this.materials.length === 0) {
            this.addMaterial();
        }
    }

    copyMaterial(index: number) {
        this.cloning = true;
        const copyFrom = this.materials.controls[index] as FormGroup;
        this.addMaterial();
        const lastItemIndex = this.materials.length - 1;
        const copyTo = this.materials.controls[lastItemIndex] as FormGroup;
        for (const key of Object.keys(copyFrom.controls)) {
            copyTo.controls[key].patchValue(copyFrom.controls[key].value);
        }
        this.cloning = false;
    }

    addMaterialRow()
    {
        this.cloning = true;
        this.addMaterial();
        this.cloning = false;
    }

    newMaterial(): FormGroup {
        this.shownLoadTimes = undefined;
        const group = this.fb.group({
            depot: [''],
            readyAt: [''],
            expiryDate: [''],
            grade: [''],
            region: [''],
            uomId: ['', Validators.required],
            heap: [''],
            weight: ['',[Validators.required, Validators.pattern('^[0-9]{1,7}(.[0-9]+)?$')]],
            totalLoads: ['', Validators.required],
            containerSizeId: [''],
            packaging: [''],
            comments: [''],
            status: [''],
            submitted: [false]
        });

        group.controls.heap.valueChanges
            .subscribe((h: HeapVm) => {
                if (!isNullOrUndefined(h)) {
                    if (!this.cloning) {
                        group.controls.grade.patchValue(h.gradeCode);
                    }
                }
            });

        group.controls.heap.valueChanges
            .pipe(
                debounceTime(500),
                startWith(null),
                switchMap(v =>
                    this.filterHeaps(v, group.controls.depot)
                )
            )
            .subscribe((r: ApiPageResponse<HeapVm>) => {
                if(!isNullOrUndefined(r)){
                    this.filteredHeaps = r;
                }
            });
        group.controls.depot.valueChanges
            .subscribe((h) => {
                if (!this.cloning) {
                    group.controls.grade.patchValue('');
                    group.controls.heap.patchValue('');
                }
            });
        group.controls.region.valueChanges
            .subscribe((h) => {
                if (!this.cloning) {
                    group.controls.grade.patchValue('');
                    group.controls.heap.patchValue('');
                    group.controls.depot.patchValue('');
                }
            });
        group.controls.depot.valueChanges
            .pipe(
                debounceTime(500),
                startWith(null),
                switchMap(v =>
                    this.filterDepots(v, group.controls.region.value)
                )
            )
            .subscribe((r: ApiPageResponse<DepotVm>) => {
                if(!isNullOrUndefined(r)){
                    this.filteredDepots = r;
                }
            });
        group.controls.region.valueChanges
            .pipe(
                debounceTime(500),
                startWith(null),
                switchMap(v =>
                    this.filterRegion(v)
                )
            )
            .subscribe((r: string[]) => {
                if(!isNullOrUndefined(r)){
                    this.filteredRegion = r;
                }
            });
        group.controls.grade.valueChanges
            .pipe(
                debounceTime(500),
                startWith(null),
                switchMap(v =>
                    this.filterGrade(v)
                )
            )
            .subscribe((r: string[]) => {
                if(!isNullOrUndefined(r)){
                    this.filteredGrade = r;
                }
            });
        return group;
    }

    selectDepot(depot: DepotVm) {
        this.selectedDepot = depot;
        this.depotSelected = !isNullOrUndefined(depot);
    }

    clearDepot(input: HTMLInputElement) {
        input.value = '';
        this.depot.patchValue('');
        this.depotSelected = false;
    }

    clearHeap(input: HTMLInputElement, cntl: AbstractControl) {
        input.value = '';
        cntl.patchValue('');
    }

    showHideLoadTimes(index: number, event: MouseEvent) {
        if (this.shownLoadTimes === index) {
            this.shownLoadTimes = undefined;
        } else {
            this.shownLoadTimes = index;
        }
        this.loadTimesStyle = {
            top: `${event.y + 20}px` ,
            left: `${event.x - 90}px`,
            position: 'absolute',
            'z-index': '10',
            'background-color': 'white',
            'box-shadow': '3px 4px 4px #888888',
            border: 'solid 1px #888888',
            padding: '2px'
        };
    }

    private toggleContainerSizeAndUom() {
        this.enableContainerSizeAndUom();
    }

    private enableContainerSizeAndUom() {
        this.materials.controls.forEach((c) => {
            c.enable();
        });
    }

    displayGrade(grade?: any): string | undefined {
        return grade ? grade : undefined;
    }
    displayRegion(region?: any): string | undefined {
        return region ? region : undefined;
    }
  
    filterGrade(val: string) {
        return this.gradeService.getGrade(val);
    }
    filterRegion(val: string) {
        return this.regionService.searchRegions(val);
    }
}
