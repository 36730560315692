import { DatePipe, formatDate } from '@angular/common';
import { OnInit, Component, ViewChild, ViewChildren, HostListener } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription, timer } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { isNullOrUndefined } from '../tools';
import { LiveLoadsModel } from '../gen/models/LiveLoadsModel';
import { LiveLoadsQueryModel } from '../gen/models/LiveLoadsQueryModel';
import { YardViewModel } from '../gen/models/YardViewModel';
import { LiveLoadsService } from '../gen/services/live-loads.service';
import { SpinnerComponent } from '../spinner/spinner.component';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { UserPreferencesService } from '../gen/services/user-preferences.service';
import { UserPreferencesVm } from '../gen/models/UserPreferencesVm';
import { SortInfo } from '../table-sort/table-sort.directive';
import { PartiesService } from '../gen/services/parties.service';
import { PartiesWithContract } from '../gen/models/PartiesWithContract';
import { QueryDateRange } from '../gen/models/QueryDateRange';
import { DateAdapter } from '@angular/material/core';

function autocompleteObjectValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (typeof control.value === 'string') {
            return null;  /* valid option selected */
        }
    };
}

@Component({
    selector: 'app-live-loads',
    templateUrl: './live-loads.component.html',
    styleUrls: ['./live-loads.component.css',
        './live-loads-animate.component.css',
        './live-loads-normalize.component.css',
        './live-loads-reset.component.css',
        './live-loads-dark-light-mode.css',
        './live-loads-counter-switch.css',
        './live-loads-tooltip.css']
})

export class LiveLoadsComponent implements OnInit {
    @ViewChild('spinner', { static: true }) spinner: SpinnerComponent;
    @ViewChildren('instruction') instructions: any;

    isLoading = false;
    isDark: boolean;
    printImg = new Image;
    activeWeek: moment.Moment;

    tableState: LiveLoadsQueryModel;
    data: LiveLoadsModel[] = [];
    dataWithCompletedStatus: LiveLoadsModel[] = [];

    liveLoadsSubscription: Subscription;
    yards: YardViewModel[] = [];
    filteredYards: Observable<YardViewModel[]>;

    userPreferencesData: UserPreferencesVm;
    transportData: LiveLoadsModel[] = [];
    filteredTransportData: LiveLoadsModel[] = [];
    defaultFilteredTransportData: LiveLoadsModel[] = [];

    reloadData: Subscription;
    startDate: string;
    endDate: string;
    btnName: string;
    sortReverse = false;
    selectedPredicate = null;
    sortClicked = 0;

    yardCode = '';
    yardName = '';
    dayOfToday: string;
    dateOfToday: string;
    yardViewStatus: string;
    plannedViewStatus: string;
    inProgressViewStatus: string;
    onRouteViewStatus: string;
    inboundViewStatus: string;
    outboundViewStatus: string;
    private _searchTerm = '';

    inboundCount = 0;
    outboundCount = 0;
    total = 0;
    yardCount = 0;
    avgOnsiteTime = 0;

    mastFormStyle: string;
    mastForm_dark = 'mastform';
    mastForm_Light = 'mastform-light';

    matFieldStyle: string;
    matField_Dark = 'dark';
    matField_Light = 'light';

    tableStyle: string;
    tableStyle_dark = 'none';
    tableStyle_Light = 'lighttable';

    thStyle: string;
    thStyle_dark = 'bgcolour-greyed font-roboto-bold fontcolour-backgroundblue';
    thStyle_Light = 'bgcolour-primary-blue font-roboto-regular fontcolour-white';

    tdStyle: string;
    tdTypeStatusStyle: string;
    tdStyle_dark = 'font-roboto-regular fontcolour-white';
    tdStyle_Light = 'font-roboto-regular fontcolour-darkgrey';
    tdStyleFontBold_Light = 'font-roboto-bold fontcolour-darkgrey';

    tdInstructionStyle: string;
    tdInstructionStyle_dark = 'font-roboto-regular fontcolour-white fontsize-instructions';
    tdInstructionStyle_light = 'font-roboto-regular fontcolour-backgroundblue fontsize-instructions';

    textInstructionStyle: string;
    textInstructionStyle_dark = 'form-control instruction-style-dark no-outline';
    textInstructionStyle_light = 'form-control instruction-style-light no-outline';

    requestedStyle: string;
    requestedStyleDark = 'font-roboto-regular fontcolour-white';
    requestedStyleLight = 'font-roboto-regular fontcolour-primary-blue';

    gridBgStyle: string;
    gridBgDark = 'grid-minibox-row1col1span1-vsixth-x4 noborder grid-bgcolur-dark';
    gridBgLight = 'grid-minibox-row1col1span1-vsixth-x4 noborder grid-bgcolur-light';

    dayFontStyle: string;
    dayFontDark = 'font-roboto-regular fontcolour-white date-on-liveview';
    dayFontLight = 'font-roboto-regular fontcolour-darkgrey date-on-liveview';

    dateFontStyle: string;
    dateFontDark = 'font-roboto-bold fontcolour-secondary-yellow';
    dateFontLight = 'font-roboto-regular fontcolour-darkgrey';

    btnNonActiveState_dark = 'button-liveloads-yellow';
    panelNonActiveState_dark = 'border-hover-yellow';
    panelIndicatorActive_dark = 'bgcolour-backgroundblue fontcolour-secondary-yellow';
    panelIndicatorNonActive_dark = 'bgcolour-greyed fontcolour-backgroundblue';

    btnNonActiveState_Light = 'button-liveloads-light';
    panelNonActiveState_Light = 'displaypanel-non-active-white';
    panelIndicatorActive_Light = 'bgcolour-backgroundblue fontcolour-white';
    panelIndicatorNonActive_Light = 'bgcolour-primary-blue fontcolour-white';

    btnActiveStyleYellow = 'button-liveloads-yellow-activestate';
    btnActiveStyleGreen = 'button-liveloads-green-activestate';

    panelActiveStyleYellow = 'displaypanel-active-yellow';
    panelActiveStyleGreen = 'displaypanel-active-green';
    panelActiveStyleCyan = 'displaypanel-active-cyan';

    nonActiveViewStatus = 'View';
    activeViewingStatus = 'Viewing';

    fontcolourWhite = 'fontcolour-white';
    fontColourGreyed = 'fontcolour-greyed';
    fontColourBlue = 'fontcolour-backgroundblue';

    viewModeImagePath = '../../assets/svg/icon-moon.svg';

    darkModeSwitch = 'mode--switch--dark';
    lightModeSwitch = 'mode--switch--light';

    darkModeButton = 'mode--button--dark';
    lightModeButton = 'mode--button--light';

    modeSwitchStyle: string;
    modeButtonStyle: string;

    //button
    btnThisWeekStyle: string;
    btnYesterdayStyle: string;
    btnTodayStyle: string;
    btnTomorrowStyle: string;
    btnNextWeekStyle: string;
    btnClearStyle: string;

    //In Progress
    btnInProgressBgStyle: string;
    btnInProgressFontStyle: string;
    btnInProgressCountFontStyle: string;
    btnInProgressIndicatorStyle: string;
    //On Route
    btnOnRouteBgStyle: string;
    btnOnRouteFontStyle: string;
    btnOnRouteCountFontStyle: string;
    btnOnRouteIndicatorStyle: string;
    //Inbound
    btnInboundBgStyle: string;
    btnInboundFontStyle: string;
    btnInboundCountFontStyle: string;
    btnInboundIndicatorStyle: string;
    //Outbound
    btnOutboundBgStyle: string;
    btnOutboundFontStyle: string;
    btnOutboundCountFontStyle: string;
    btnOutboundIndicatorStyle: string;
    //Default Counter
    counterBgStyle: string;
    counterFontStyle: string;
    counterCountFontStyle: string;
    counterIndicatorStyle: string;
    //Yard
    btnYardBgStyle: string;
    btnYardFontStyle: string;
    btnYardCountFontStyle: string;
    btnYardIndicatorStyle: string;
    //All
    btnPlannedBgStyle: string;
    btnPlannedFontStyle: string;
    btnPlannedCountFontStyle: string;
    btnPlannedIndicatorStyle: string;

    isInProgressClicked = false;
    isOnRouteClicked = false;
    isInboundClicked = false;
    isOutboundClicked = true;
    isYardClicked = false;
    isPlannedClicked = false;

    isTodayClicked = false;
    isTomorrowClicked = false;
    isYesterdayClicked = false;
    isThisWeekClicked = false;
    isNextWeekClicked = false;

    showCounter = true;
    showCounterToggle = false;
    showMobileView = false;
    toggleCounterState = 'Hide';
    toggleCounterStyle = 'toggleCounter-hide';
    currentWindowWidth = 0;
    plannedCounter = 0;
    onSiteCounter = 0;
    onRouteCounter = 0;
    newLiveLoadsForm: FormGroup;
    tableHeight = 800;
    pickupRange: QueryDateRange = { dateFrom: '', dateTo: '' };
    pickupDateType: string;
    isShown = true;
    isPaused = false;
    partyAccountNo: string;
    isDefault = true;
    datePreference = '';

    constructor(public liveLoadsService: LiveLoadsService,
        private userPreferencesService: UserPreferencesService,
        private toastr: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
        public partiesService: PartiesService,
        private _adapter: DateAdapter<any>) {
        this.modeSwitchStyle = this.darkModeSwitch;
        this.modeButtonStyle = this.darkModeButton;
        this.getUserPreferences();
    }

    public yardControl = new FormControl('',
        { validators: [autocompleteObjectValidator(), Validators.required] });

    public validation_msgs = {
        'yardControl': [{ type: 'required', message: 'Please select a Yard.' }]
    };

    private async getUserPreferences() {
        const userPreferences = this.route.snapshot.data['userpreferencesData'];
        if (userPreferences !== undefined && userPreferences.length > 0) {
            this.userPreferencesData = userPreferences[0];
        }
        else {
            this.userPreferencesData = { depot: '', territory: '', requestFields: '', allocationFields: '', orderBookType: '', viewMode: '', allocationDate: '', pickUpFromDate: '' };
        }
    }

    tryStartPolling() {
        if (this.isPaused && !isNullOrUndefined(this.reloadData)) {
            this.reloadData.unsubscribe();
        }
        if (isNullOrUndefined(this.reloadData) && !this.isPaused) {
            this.reloadData = timer(0, 60000).subscribe((() => {
                this.callServer(this.tableState);
            }));
        } else {
            this.callServer(this.tableState);
        }
    }

    ngOnInit() {
        this._adapter.setLocale(window.navigator.language);
        const day = new Date();
        this.isDark = this.userPreferencesData.viewMode === 'Dark' || isNullOrUndefined(this.userPreferencesData.viewMode);

        this.setStylesForLightOrDarkMode();

        this.newLiveLoadsForm = new FormGroup({
            customer: new FormControl(''),
            pickupRange: new FormControl(''),
            search: new FormControl(''),
            status: new FormControl('')
        });

        this.filteredYards = this.yardControl.valueChanges.pipe(
            startWith(''),
            map(value => this.yardFilter(value)));

        this.getBase64ImageFromUrl('../../assets/img/icon.jpg').then(result => this.printImg.src = result.toString()).catch(err => console.error(err));

        this.dateOfToday = formatDate(day, 'dd MMMM yyyy', 'en');
        this.dayOfToday = formatDate(day, 'EEEE', 'en');

        this.currentWindowWidth = window.innerWidth;

        if (this.currentWindowWidth <= 768) {
            this.showCounterToggle = true;
        }

        this.showMobileView = (this.currentWindowWidth <= 425);

        this.processQueryParams();
    }

    toggle() {
        this.showCounter = !this.showCounter;
        this.updateCounterStatus();
    }

    updateCounterStatus() {

        if (this.showCounter) {
            this.toggleCounterState = 'Hide';
            this.toggleCounterStyle = 'toggleCounter-hide';
        }
        else {
            this.toggleCounterState = 'Show';
            this.toggleCounterStyle = 'toggleCounter-show';
        }

    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (event.target.innerWidth > 768) {
            this.showCounter = true;
            this.showCounterToggle = false;
        }
        if (event.target.innerWidth <= 768) {
            this.showCounterToggle = true;
            this.updateCounterStatus();
        }

        this.showMobileView = (window.innerWidth <= 425);

        if ((window.innerWidth < this.currentWindowWidth)
            && (this.currentWindowWidth == 768 ||
                (window.innerWidth > 768 && this.currentWindowWidth >= 1440))) {
            this.currentWindowWidth = window.innerWidth;
            window.location.reload();
        }
        this.currentWindowWidth = window.innerWidth;
    }


    getImageUrl(type: string, index: number) {
        let imgUrl = type == 'Out' ? 'up-' : 'down-';
        imgUrl += index % 2 == 0 ? 'backgroundblue' : 'greyed';
        return imgUrl;
    }

    viewModeClicked() {
        this.isDark = !this.isDark;
        this.userPreferencesService.updateViewMode(this.isDark).subscribe();
        this.setStylesForLightOrDarkMode();
    }

    setStylesForLightOrDarkMode() {
        if (this.isDark) {
            this.btnOutboundIndicatorStyle = this.panelIndicatorActive_dark;
            this.mastFormStyle = this.mastForm_dark;
            this.gridBgStyle = this.gridBgDark;
            this.dayFontStyle = this.dayFontDark;
            this.dateFontStyle = this.dateFontDark;
            this.modeSwitchStyle = this.darkModeSwitch;
            this.modeButtonStyle = this.darkModeButton;
            this.matFieldStyle = this.matField_Dark;
            this.tableStyle = this.tableStyle_dark;
            this.thStyle = this.thStyle_dark;
            this.tdStyle = this.tdStyle_dark;
            this.tdTypeStatusStyle = this.tdStyle_dark;
            this.tdInstructionStyle = this.tdInstructionStyle_dark;
            this.textInstructionStyle = this.textInstructionStyle_dark;
            this.requestedStyle = this.requestedStyleDark;
            this.viewModeImagePath = '../../assets/svg/icon-moon.svg';
        }
        else {
            this.btnOutboundIndicatorStyle = this.panelIndicatorActive_Light;
            this.mastFormStyle = this.mastForm_Light;
            this.gridBgStyle = this.gridBgLight;
            this.dayFontStyle = this.dayFontLight;
            this.dateFontStyle = this.dateFontLight;
            this.modeSwitchStyle = this.lightModeSwitch;
            this.modeButtonStyle = this.lightModeButton;
            this.matFieldStyle = this.matField_Light;
            this.tableStyle = this.tableStyle_Light;
            this.thStyle = this.thStyle_Light;
            this.tdStyle = this.tdStyle_Light;
            this.tdTypeStatusStyle = this.tdStyleFontBold_Light;
            this.tdInstructionStyle = this.tdInstructionStyle_light;
            this.textInstructionStyle = this.textInstructionStyle_light;
            this.requestedStyle = this.requestedStyleLight;
            this.viewModeImagePath = '../../assets/svg/icon-sun.svg';
        }
        this.setDefaultBtnStyle();
        this.setDefaultPanelStyle();
    }

    setDefaultBtnStyle() {
        let nonActiveStyle: string;

        if (this.isDark)
            nonActiveStyle = this.btnNonActiveState_dark;
        else
            nonActiveStyle = this.btnNonActiveState_Light;

        if (!this.isTodayClicked)
            this.btnTodayStyle = nonActiveStyle;

        if (!this.isThisWeekClicked)
            this.btnThisWeekStyle = nonActiveStyle;

        if (!this.isYesterdayClicked)
            this.btnYesterdayStyle = nonActiveStyle;

        if (!this.isTomorrowClicked)
            this.btnTomorrowStyle = nonActiveStyle;

        if (!this.isNextWeekClicked)
            this.btnNextWeekStyle = nonActiveStyle;

        this.btnClearStyle = nonActiveStyle;
    }

    setDefaultPanelStyle() {
        let panelNonActiveState: string;
        let panelIndicatorNonActive: string;
        let panelCountFontStyle: string;
        let fontColour: string;

        if (this.isDark) {
            panelNonActiveState = this.panelNonActiveState_dark;
            panelIndicatorNonActive = this.panelIndicatorNonActive_dark;
            panelCountFontStyle = this.fontcolourWhite;
            fontColour = this.fontColourGreyed;
        }
        else {
            panelNonActiveState = this.panelNonActiveState_Light;
            panelIndicatorNonActive = this.panelIndicatorNonActive_Light;
            panelCountFontStyle = this.fontColourBlue;
            fontColour = this.fontColourBlue;
        }

        if (!this.isInProgressClicked) {
            this.btnInProgressBgStyle = panelNonActiveState;
            this.btnInProgressIndicatorStyle = panelIndicatorNonActive;
            this.btnInProgressFontStyle = fontColour;
            this.btnInProgressCountFontStyle = panelCountFontStyle;
            this.inProgressViewStatus = this.nonActiveViewStatus;
        }

        if (!this.isOnRouteClicked) {
            this.btnOnRouteBgStyle = panelNonActiveState;
            this.btnOnRouteIndicatorStyle = panelIndicatorNonActive;
            this.btnOnRouteFontStyle = fontColour;
            this.btnOnRouteCountFontStyle = panelCountFontStyle;
            this.onRouteViewStatus = this.nonActiveViewStatus;
        }

        if (!this.isInboundClicked) {
            this.btnInboundBgStyle = panelNonActiveState;
            this.btnInboundIndicatorStyle = panelIndicatorNonActive;
            this.btnInboundFontStyle = fontColour;
            this.btnInboundCountFontStyle = panelCountFontStyle;
            this.inboundViewStatus = this.nonActiveViewStatus;
        }

        if (!this.isOutboundClicked) {
            this.btnOutboundBgStyle = panelNonActiveState;
            this.btnOutboundIndicatorStyle = panelIndicatorNonActive;
            this.btnOutboundFontStyle = fontColour;
            this.btnOutboundCountFontStyle = panelCountFontStyle;
            this.outboundViewStatus = this.nonActiveViewStatus;
        }

        this.counterBgStyle = panelNonActiveState;
        this.counterIndicatorStyle = panelIndicatorNonActive;
        this.counterFontStyle = fontColour;
        this.counterCountFontStyle = panelCountFontStyle;

        if (!this.isYardClicked) {
            this.btnYardBgStyle = panelNonActiveState;
            this.btnYardIndicatorStyle = panelIndicatorNonActive;
            this.btnYardFontStyle = fontColour;
            this.btnYardCountFontStyle = panelCountFontStyle;
            this.yardViewStatus = this.nonActiveViewStatus;
        }

        if (!this.isPlannedClicked) {
            this.btnPlannedBgStyle = panelNonActiveState;
            this.btnPlannedIndicatorStyle = panelIndicatorNonActive;
            this.btnPlannedFontStyle = fontColour;
            this.btnPlannedCountFontStyle = panelCountFontStyle;
            this.plannedViewStatus = this.nonActiveViewStatus;
        }

        const m = moment();

        if (this.startDate === undefined) {
            this.startDate = m.format('YYYY-MM-DD');
        }

        if (this.endDate === undefined) {
            this.endDate = m.format('YYYY-MM-DD');
        }
    }

    setDefaultPanelSelection() {
        this.isInProgressClicked = false;
        this.isOnRouteClicked = false;
        this.isInboundClicked = false;
        this.isOutboundClicked = false;
        this.isYardClicked = false;
        this.isPlannedClicked = false;
    }

    setDefaultButtonSelection() {
        this.isTodayClicked = false;
        this.isTomorrowClicked = false;
        this.isYesterdayClicked = false;
        this.isThisWeekClicked = false;
        this.isNextWeekClicked = false;
    }

    assignSelectedDate() {
        this.tableState = {
            search: {
                startDate: this.startDate,
                endDate: this.endDate,
                yardCode: this.yardCode,
                customer: this.partyAccountNo
            },
            datawithcompletedstatus: this.dataWithCompletedStatus,
            sort: { predicate: this.selectedPredicate, reverse: this.sortReverse }
        };
        const urlTree = this.router.createUrlTree([], {
            queryParams: Object.assign(this.tableState.search, { btn: this.btnName }),
            queryParamsHandling: 'merge',
            preserveFragment: true
        });

        this.router.navigateByUrl(urlTree);
    }

    assignSelectedOption() {
        this.tableState = {
            search: {
                startDate: this.startDate,
                endDate: this.endDate,
                yardCode: this.yardCode,
                customer: this.partyAccountNo
            },
            datawithcompletedstatus: this.dataWithCompletedStatus,
            sort: { predicate: this.selectedPredicate, reverse: this.sortReverse }
        };
    }

    get searchTerm(): string {
        return this._searchTerm;
    }

    set searchTerm(value: string) {
        this._searchTerm = value;
        this.filteredTransportData = this.filterDataByText(value);
    }

    onKeyupEvent(event: KeyboardEvent) {
        this.searchTerm = (<HTMLInputElement>event.target).value;
        const urlTree = this.router.createUrlTree([], {
            queryParams: { searchTerm: this.searchTerm },
            queryParamsHandling: 'merge',
            preserveFragment: true
        });
        this.router.navigateByUrl(urlTree);
    }

    yardFilter(value: string): YardViewModel[] {
        const filterValue = value.toLowerCase();
        return this.yards.filter(yard => yard.yardCode.toLowerCase().indexOf(filterValue) === 0 ||
            yard.yardName.toLowerCase().indexOf(filterValue) === 0);
    }

    setYardCode(yard: YardViewModel) {
        this.yardCode = yard.yardCode;
        this.yardName = yard.yardName;
        this.tableState.search.yardCode = this.yardCode;
        const urlTree = this.router.createUrlTree([], {
            queryParams: { yardCode: this.yardCode },
            queryParamsHandling: 'merge',
            preserveFragment: true
        });

        const newYardControlValue = `${this.yardCode} - ${this.yardName}`;

        if (this.yardControl.value != newYardControlValue) {
            this.yardControl.setValue(newYardControlValue);
        }

        this.router.navigateByUrl(urlTree);
    }

    filterDataByText(searchString: string): LiveLoadsModel[] | undefined {
        const selectedYard = this.yardControl.value;
        let data = this.defaultFilteredTransportData;
        const searchStringLower = searchString.toLowerCase();
        if (selectedYard !== '') {
            if (searchStringLower !== '') {
                data = this.transportData.filter(data => ((!isNullOrUndefined(data.direction)) ? data.direction.toLowerCase().indexOf(searchStringLower) !== -1 : false)
                    || ((!isNullOrUndefined(data.type)) ? data.type.toLowerCase().indexOf(searchStringLower) !== -1 : false)
                    || ((!isNullOrUndefined(data.haulierName)) ? data.haulierName.toLowerCase().indexOf(searchStringLower) !== -1 : false)
                    || ((!isNullOrUndefined(data.jobNumber)) ? data.jobNumber.toString().toLowerCase().indexOf(searchStringLower) !== -1 : false)
                    || ((!isNullOrUndefined(data.customerLocation)) ? data.customerLocation.toLowerCase().indexOf(searchStringLower) !== -1 : false)
                    || ((!isNullOrUndefined(data.planned)) ? data.planned.toLowerCase().indexOf(searchStringLower) !== -1 : false)
                    || ((!isNullOrUndefined(data.instruction)) ? data.instruction.toLowerCase().indexOf(searchStringLower) !== -1 : false)
                    || ((!isNullOrUndefined(data.actual)) ? data.actual.toLowerCase().indexOf(searchStringLower) !== -1 : false)
                    || ((!isNullOrUndefined(data.remaining)) ? data.remaining.toLowerCase().indexOf(searchStringLower) !== -1 : false)
                    || ((!isNullOrUndefined(data.status)) ? data.status.toLowerCase().indexOf(searchStringLower) !== -1 : false));
            } else {
                data = this.transportData;
            }

            if (this.newLiveLoadsForm.value.status)
                data = data.filter(d => d.status == this.newLiveLoadsForm.value.status);
            if (this.isInProgressClicked)
                data = data.filter(d => d.status == 'InProgress');
            if (this.isOnRouteClicked)
                data = data.filter(d => d.status == 'OnRoute');
            if (this.isInboundClicked)
                data = data.filter(d => d.direction == 'In');
            if (this.isOutboundClicked)
                data = data.filter(d => d.direction == 'Out');
        }
        return data;
    }

    btnYesterdayClicked() {
        this.setDefaultButtonSelection();
        this.isYesterdayClicked = true;
        this.setDefaultBtnStyle();
        this.btnYesterdayStyle = this.btnActiveStyleGreen;

        const filterDate = new Date();
        filterDate.setDate(filterDate.getDate() - 1);
        this.endDate = this.startDate = moment(filterDate).format('YYYY-MM-DD');
        this.btnName = 'Yesterday';
        this.assignSelectedDate();
        this.tryStartPolling();
    }

    btnTodayClicked() {
        this.setDefaultButtonSelection();
        this.isTodayClicked = true;
        this.setDefaultBtnStyle();
        this.btnTodayStyle = this.btnActiveStyleGreen;

        const filterDate = new Date();
        this.endDate = this.startDate = moment(filterDate).format('YYYY-MM-DD');
        this.btnName = 'Today';
        this.assignSelectedDate();
        this.tryStartPolling();
    }

    btnTomorrowClicked() {
        this.setDefaultButtonSelection();
        this.isTomorrowClicked = true;
        this.setDefaultBtnStyle();
        this.btnTomorrowStyle = this.btnActiveStyleGreen;

        const filterDate = new Date();
        filterDate.setDate(filterDate.getDate() + 1);
        this.endDate = this.startDate = moment(filterDate).format('YYYY-MM-DD');
        this.btnName = 'Tomorrow';
        this.assignSelectedDate();
        this.tryStartPolling();
    }

    btnThisWeekClicked() {
        this.setDefaultButtonSelection();
        this.isThisWeekClicked = true;
        this.setDefaultBtnStyle();
        this.btnThisWeekStyle = this.btnActiveStyleGreen;

        this.activeWeek = moment().startOf('isoWeek');
        this.startDate = this.activeWeek.startOf('isoWeek').format('YYYY-MM-DD');
        this.endDate = this.activeWeek.endOf('isoWeek').format('YYYY-MM-DD');
        this.tableState = {
            search: {
                startDate: this.startDate,
                endDate: this.endDate,
                yardCode: this.yardCode,
                customer: this.partyAccountNo
            },
            datawithcompletedstatus: this.dataWithCompletedStatus,
            sort: { predicate: this.selectedPredicate, reverse: this.sortReverse }
        };
        const urlTree = this.router.createUrlTree([], {
            queryParams: Object.assign(this.tableState.search, { btn: 'ThisWeek' }),
            queryParamsHandling: 'merge',
            preserveFragment: true
        });

        this.router.navigateByUrl(urlTree);
        this.tryStartPolling();
    }

    btnNextWeekClicked() {
        this.setDefaultButtonSelection();
        this.isNextWeekClicked = true;
        this.setDefaultBtnStyle();
        this.btnNextWeekStyle = this.btnActiveStyleGreen;

        this.activeWeek = moment().startOf('isoWeek');
        this.activeWeek = this.activeWeek.add(1, 'week');
        this.startDate = this.activeWeek.startOf('isoWeek').format('YYYY-MM-DD');
        this.endDate = this.activeWeek.endOf('isoWeek').format('YYYY-MM-DD');
        this.tableState = {
            search: {
                startDate: this.startDate,
                endDate: this.endDate,
                yardCode: this.yardCode,
                customer: this.partyAccountNo
            },
            datawithcompletedstatus: this.dataWithCompletedStatus,
            sort: { predicate: this.selectedPredicate, reverse: this.sortReverse }
        };
        const urlTree = this.router.createUrlTree([], {
            queryParams: Object.assign(this.tableState.search, { btn: 'NextWeek' }),

            queryParamsHandling: 'merge',
            preserveFragment: true
        });

        this.router.navigateByUrl(urlTree);
        this.tryStartPolling();
    }

    btnInProgressClicked() {
        this.setDefaultPanelSelection();
        this.isInProgressClicked = true;
        this.setDefaultPanelStyle();
        this.btnInProgressBgStyle = this.panelActiveStyleYellow;
        this.btnInProgressFontStyle = this.fontColourBlue;
        this.btnInProgressCountFontStyle = this.fontColourBlue;
        this.btnInProgressIndicatorStyle = this.panelIndicatorActive_dark;

        this.inProgressViewStatus = this.activeViewingStatus;

        this.assignSelectedOption();
        this.filteredTransportData = this.filterDataByText(this._searchTerm);

        const urlTree = this.router.createUrlTree([], {
            queryParams: { view: 'inprogress' },
            queryParamsHandling: 'merge',
            preserveFragment: true
        });
        this.router.navigateByUrl(urlTree);
    }

    btnOnRouteClicked() {
        this.setDefaultPanelSelection();
        this.isOnRouteClicked = true;
        this.setDefaultPanelStyle();
        this.btnOnRouteBgStyle = this.panelActiveStyleYellow;
        this.btnOnRouteFontStyle = this.fontColourBlue;
        this.btnOnRouteCountFontStyle = this.fontColourBlue;
        this.btnOnRouteIndicatorStyle = this.panelIndicatorActive_dark;

        this.onRouteViewStatus = this.activeViewingStatus;

        this.assignSelectedOption();
        this.filteredTransportData = this.filterDataByText(this._searchTerm);

        const urlTree = this.router.createUrlTree([], {
            queryParams: { view: 'onroute' },
            queryParamsHandling: 'merge',
            preserveFragment: true
        });
        this.router.navigateByUrl(urlTree);
    }


    btnInboundClicked() {
        this.setDefaultPanelSelection();
        this.isInboundClicked = true;
        this.setDefaultPanelStyle();
        this.btnInboundBgStyle = this.panelActiveStyleYellow;
        this.btnInboundFontStyle = this.fontColourBlue;
        this.btnInboundCountFontStyle = this.fontColourBlue;
        this.btnInboundIndicatorStyle = this.panelIndicatorActive_dark;

        this.inboundViewStatus = this.activeViewingStatus;

        this.assignSelectedOption();
        this.filteredTransportData = this.filterDataByText(this._searchTerm);

        const urlTree = this.router.createUrlTree([], {
            queryParams: { view: 'inbound' },
            queryParamsHandling: 'merge',
            preserveFragment: true
        });
        this.router.navigateByUrl(urlTree);
    }

    btnOutboundClicked() {
        this.setDefaultPanelSelection();
        this.isOutboundClicked = true;
        this.setDefaultPanelStyle();
        this.btnOutboundBgStyle = this.panelActiveStyleYellow;
        this.btnOutboundFontStyle = this.fontColourBlue;
        this.btnOutboundCountFontStyle = this.fontColourBlue;
        this.btnOutboundIndicatorStyle = this.panelIndicatorActive_dark;

        this.outboundViewStatus = this.activeViewingStatus;

        this.assignSelectedOption();
        this.filteredTransportData = this.filterDataByText(this._searchTerm);

        const urlTree = this.router.createUrlTree([], {
            queryParams: { view: 'outbound' },
            queryParamsHandling: 'merge',
            preserveFragment: true
        });
        this.router.navigateByUrl(urlTree);
    }

    btnYardClicked() {
        this.setDefaultPanelSelection();
        this.isPlannedClicked = true;
        this.setDefaultPanelStyle();
        this.btnYardBgStyle = this.panelActiveStyleYellow;
        this.btnYardFontStyle = this.fontColourBlue;
        this.btnYardCountFontStyle = this.fontColourBlue;
        this.btnYardIndicatorStyle = this.panelIndicatorActive_dark;

        this.yardViewStatus = this.activeViewingStatus;

        this.assignSelectedOption();
        this.tryStartPolling();
    }

    btnPlannedClicked() {
        this.setDefaultPanelSelection();
        this.isPlannedClicked = true;
        this.setDefaultPanelStyle();
        this.btnPlannedBgStyle = this.panelActiveStyleYellow;
        this.btnPlannedFontStyle = this.fontColourBlue;
        this.btnPlannedCountFontStyle = this.fontColourBlue;
        this.btnPlannedIndicatorStyle = this.panelIndicatorActive_dark;

        this.plannedViewStatus = this.activeViewingStatus;

        this.assignSelectedOption();
        this.filteredTransportData = this.filterDataByText(this._searchTerm);

        const urlTree = this.router.createUrlTree([], {
            queryParams: { view: 'planned' },
            queryParamsHandling: 'merge',
            preserveFragment: true
        });
        this.router.navigateByUrl(urlTree);
    }

    setInstructionEdit(jobNumber: number, index: number) {
        this.transportData.forEach(t => t.isInstructionEdit = false); //make sure all the instruction text input box are not editable in all rows.

        if (jobNumber !== 0)
            this.transportData.find(x => x.jobNumber === jobNumber).isInstructionEdit = true; // setting up the selected row instruction box only editable.

        setTimeout(() => { this.instructions.toArray()[index].nativeElement.focus(); }, 0);
    }

    btnHomeClicked() {
        this.router.navigate(['/']).then(() => { window.location.href = this.router.url; });
    }

    btnPrintClicked() {
        this.printLiveLoads(this.filteredTransportData);
    }

    instructionChange(jobNumber: number, value: string) {
        if (jobNumber !== 0) {
            const row = this.transportData.find(x => x.jobNumber === jobNumber);
            if ((row.instruction !== value) && !row.isRowDataModified)
                row.isRowDataModified = true;
        }
    }

    btnSaveClicked(data: LiveLoadsModel) {
        this.transportData.forEach(t => t.isInstructionEdit = false);

        if (data.isRowDataModified) {
            this.isLoading = true;
            this.liveLoadsSubscription = this.liveLoadsService.saveLiveLoadsInstruction(data).subscribe(r => {
                if (r) {
                    this.isLoading = false;
                    this.transportData.forEach(t => t.isRowDataModified = false);
                    this.toastr.success('Transport data updated successfully', 'Live Loads');
                } else {
                    this.isLoading = false;
                    this.toastr.error('Transport data update failed. Please try again', 'Live Loads');
                }
            });
        }
    }

    sortByInfo(info: SortInfo) {
        if (info.active) {
            this.tableState.sort.reverse = info.direction != 'asc';
            this.sortReverse = this.tableState.sort.reverse;

            //This checks if the column has been clicked three times, on the 3rd it resets the sort
            if (this.selectedPredicate == info.active) {
                this.sortClicked++;
                if (this.sortClicked % 3 == 2) {
                    this.tableState.sort.predicate = null;
                }
                else {
                    this.tableState.sort.predicate = info.active;
                }
            }
            else {
                this.tableState.sort.predicate = info.active;
                this.selectedPredicate = info.active;
                this.sortClicked = 0;
            }
            this.isDefault = this.tableState.sort.reverse;

            this.tryStartPolling();
        }
    }

    searchCustomerLoads(loads: any) {
        if (loads != null) {
            const partyAccountNo = loads.party_Account_No ?? loads;
            if (this.partyAccountNo !== partyAccountNo) {
                this.partyAccountNo = partyAccountNo;
                this.tableState.search.yardCode = this.yardCode;
                this.tableState.search.customer = this.partyAccountNo;
                const urlTree = this.router.createUrlTree([], {
                    queryParams: Object.assign(this.tableState.search, { custFilter: 'Customer', custAccNo: this.partyAccountNo }),
                    queryParamsHandling: 'merge',
                    preserveFragment: true
                });
                this.router.navigateByUrl(urlTree);
                this.tryStartPolling();
            }
        }
    }

    clearCustomerLoads() {
        if (this.partyAccountNo !== '') {
            this.partyAccountNo = '';
            this.tableState.search.yardCode = this.yardCode;
            this.tableState.search.customer = '';
            const urlTree = this.router.createUrlTree([], {
                queryParams: Object.assign(this.tableState.search, { custFilter: '', custAccNo: '' }),
                queryParamsHandling: 'merge',
                preserveFragment: true
            });
            this.router.navigateByUrl(urlTree);
            this.tryStartPolling();
        }
    }


    callServer(tableState: LiveLoadsQueryModel) {
        const selectedYard = this.yardControl.value;
        if (tableState) {
            this.tableState = tableState;
        }
        if (selectedYard !== '' || (tableState && this.tableState.search.yardCode !== '')) {
            this.isLoading = true;

            if (this.liveLoadsSubscription != null && !this.liveLoadsSubscription.closed) {
                this.liveLoadsSubscription.unsubscribe();
            }

            this.liveLoadsSubscription = this.liveLoadsService.getLiveLoadsData(tableState).subscribe(r => {
                this.data = r ? r : null;
                this.transportData = [];
                if (this.data) {
                    this.dataWithCompletedStatus = this.data.filter(d => d.status === 'Completed');
                    this.tableState.datawithcompletedstatus = this.dataWithCompletedStatus;
                    this.total = this.data.filter(d => d.status !== 'Completed' && d.status !== 'Abandoned').length;
                    this.inboundCount = this.data.filter(d => d.direction === 'In' && d.status !== 'Completed' && d.status !== 'Abandoned' && d.status !== 'Requested').length;
                    this.outboundCount = this.data.filter(d => d.direction === 'Out' && d.status !== 'Completed' && d.status !== 'Abandoned' && d.status !== 'Requested').length;
                    const duration = this.dataWithCompletedStatus.filter(d => d.duration > 0);
                    this.avgOnsiteTime = duration.reduce((total, next) => total + next.duration, 0) / duration.length;

                    this.plannedCounter = this.data.filter(d => d.status !== 'Requested').length;
                    this.onSiteCounter = this.data.filter(d => d.status === 'InProgress').length;
                    this.onRouteCounter = this.data.filter(d => d.status === 'OnRoute').length;

                    if (!isNullOrUndefined(this.data)) {
                        if (this.data.length > 0) {
                            this.transportData = this.data;
                            this.transportData.forEach(t => {
                                t.isInstructionEdit = false;
                                t.isRowDataModified = false;
                            });
                        }
                    }
                }
                this.filteredTransportData = this.filterDataByText(this._searchTerm);
                this.isLoading = false;
            });
        }
    }

    displayTime(ticks: number): string {
        const inValidTime = (isNaN(ticks) || ticks < 0);
        const hours = inValidTime ? 0 : Math.floor(ticks / 60);
        const minutes = inValidTime ? 0 : Math.floor(ticks % 60);

        const sHour = hours < 10 ? '0' + hours : hours;
        const sMinute = minutes < 10 ? '0' + minutes : minutes;

        return sHour + ':' + sMinute;
    }

    printLiveLoads(filteredTransportData: LiveLoadsModel[]) {
        if (filteredTransportData.length === 0)
            return;

        const doc = new jsPDF('l', 'pt', 'a4');
        const dtpipe = new DatePipe('en-US');
        const cols = ['Date', 'Direction', 'Job#', 'Status', 'Haulier', 'Customer', 'Planned', 'Actual', 'Remaining', 'Instructions'];
        const rows = [];

        filteredTransportData.forEach(element => {
            const trRow = [dtpipe.transform(element.scheduledDate, 'dd/MM/yyyy'), element.direction, element.jobNumber, element.status, element.haulierName, element.customerLocation, element.planned, element.actual, element.remaining, element.instruction];
            rows.push(trRow);
        });

        doc.page = 1;
        const totalPages = 2;

        doc.cellInitialize();

        for (let i = 0; i < totalPages; i++) {
            doc.setFontType('bold');
            doc.setFontSize(14);
            const now = Date.now();
            doc.text(285, 30, this.yardName + ' (' + this.yardCode + ') Transport Bookings');
            doc.text(300, 50, 'for ' + dtpipe.transform(this.tableState.search.startDate, 'dd/MM/yyyy') + ' through ' + dtpipe.transform(this.tableState.search.endDate, 'dd/MM/yyyy'));



            doc.addImage(this.printImg, 'JPEG', 650, 0, 75, 75);

            doc.setFontType('normal');
            doc.setFontSize(8);
            doc.text(325, 70, 'Report generation date: ' + dtpipe.transform(now, 'dd/MM/yyy h:mm a'));
            doc.setFontType('normal');
        }
        doc.autoTable(cols, rows, {
            margin: { top: 80, bottom: 15 },
            styles: {
                overflow: 'linebreak',
                fontSize: 6
            },
            showHeader: 'everyPage'
        });

        doc.setFontType('normal');
        doc.setFontSize(8);

        doc.save('LiveLoads_' + this.yardCode + '_' + this.yardName + '_' + formatDate(new Date(), 'yyyy/MM/dd', 'en') + '.pdf');
    }

    async getBase64ImageFromUrl(imageUrl) {
        const res = await fetch(imageUrl);
        const blob = await res.blob();

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener('load', function () {
                resolve(reader.result);
            }, false);

            reader.onerror = () => {
                return reject(this);
            };
            reader.readAsDataURL(blob);
        });
    }

    ngOnDestroy(): void {
        this.isLoading = false;

        if (this.spinner !== undefined && this.spinner.overlayRef !== undefined)
            this.spinner.overlayRef.dispose();

        if (this.liveLoadsSubscription != null && !this.liveLoadsSubscription.closed) {
            this.liveLoadsSubscription.unsubscribe();
        }

        if (this.reloadData != null && !this.reloadData.closed)
            this.reloadData.unsubscribe();
    }

    displayCustomer(customer?: PartiesWithContract): string | undefined {
        return customer ? customer.party_Name ? customer.party_Account_No + ' - ' + customer.party_Name : customer.party_Account_No : undefined;
    }


    public setPickerDate(date: string, val: string): void {
        this.tableState = {
            search: {
                startDate: '',
                endDate: '',
                yardCode: this.yardCode
            },
            datawithcompletedstatus: this.dataWithCompletedStatus,
            sort: { predicate: this.selectedPredicate, reverse: this.sortReverse }
        };
        if (!isNullOrUndefined(date) && date != '' && val === 'pickup') {
            this.pickupDateType = date;
            if (date === 'Month') {
                this.isShown = false;
                this.isPaused = true;
                this.startDate = this.tableState.search.startDate = moment(this.firstOfLastMonth).format('YYYY-MM-DD');
            }
            else if (date === 'Quarter') {
                this.isShown = false;
                this.isPaused = true;
                this.startDate = this.tableState.search.startDate = moment(this.firstOfLastQuarter).format('YYYY-MM-DD');
            }
            else if (date === 'Half') {
                this.isShown = false;
                this.isPaused = true;
                this.startDate = this.tableState.search.startDate = moment(this.firstOfLastHalf).format('YYYY-MM-DD');
            }
            else if (date === 'Year') {
                this.isShown = false;
                this.isPaused = true;
                this.startDate = this.tableState.search.startDate = moment(this.firstOfLastYear).format('YYYY-MM-DD');
            }
            if (date !== 'Range') {
                this.endDate = this.tableState.search.endDate = moment().format('YYYY-MM-DD');
                this.tableState.search.customer = this.partyAccountNo;
                this.isPaused = false;
            }
            else {
                this.isShown = true;
            }
            const urlTree = this.router.createUrlTree([], {
                queryParams: Object.assign(this.tableState.search, { filter: date, btn: '' }),
                queryParamsHandling: 'merge',
                preserveFragment: true
            });

            this.router.navigateByUrl(urlTree);
            this.tryStartPolling();
        }
        else {
            this.isShown = true;
        }
    }

    public onDateApply(date: string, val: string): void {
        const selectedDates = date.split(' - ');
        if (selectedDates !== null) {
            this.pickupRange.dateFrom = selectedDates[0];
            this.pickupRange.dateTo = selectedDates[1];
        }
        if (this.pickupRange == null && this.pickupDateType === 'Range') {
            return;
        }
        if (val === 'pickup') {
            if (this.pickupRange != null && this.pickupDateType === 'Range') {
                this.isShown = false;
                const pdf = new Date(this.pickupRange.dateFrom);
                if (pdf.toDateString() === 'Invalid Date') {
                    this.pickupDateValidation(true, false);
                    return;
                }
                const pdt = new Date(this.pickupRange.dateTo);
                if (pdt.toDateString() === 'Invalid Date') {
                    this.pickupDateValidation(false, true);
                    return;
                }
                this.startDate = this.tableState.search.startDate = moment(pdf).format('YYYY-MM-DD');
                this.endDate = this.tableState.search.endDate = moment(pdt).format('YYYY-MM-DD');
                this.tableState.search.customer = this.partyAccountNo;
                this.isPaused = true;

                const urlTree = this.router.createUrlTree([], {
                    queryParams: Object.assign(this.tableState.search, { filter: 'Range', btn: '' }),
                    queryParamsHandling: 'merge',
                    preserveFragment: true
                });

                this.router.navigateByUrl(urlTree);
                this.tryStartPolling();
            }
        }
    }

    private pickupDateValidation(from: boolean, to: boolean) {
        if (from) {
            this.toastr.error('Enter valid Pick up From date.', 'Error');
        }
        if (to) {
            this.toastr.error('Enter valid Pick up To date.', 'Error');
        }
    }

    get customer() {
        return this.newLiveLoadsForm.get('customer');
    }

    get firstOfLastMonth(): Date {
        const d = new Date();
        return new Date(d.getFullYear(), d.getMonth() - 1, 1);
    }
    get firstOfLastQuarter(): Date {
        const d = new Date();
        return new Date(d.getFullYear(), d.getMonth() - 3, 1);
    }
    get firstOfLastHalf(): Date {
        const d = new Date();
        return new Date(d.getFullYear(), d.getMonth() - 6, 1);
    }
    get firstOfLastYear(): Date {
        const d = new Date();
        return new Date(d.getFullYear() - 1, d.getMonth(), 1);
    }

    getDate(date: string) {
        if (date == null || date == '')
            return '';

        const split = date.split('-');

        return (new Date(Number(split[2]), (Number(split[1]) - 1), Number(split[0]))).toDateString();
    }

    getLocalDate(date: string) {
        return moment(date).format('L');
    }

    processQueryParams() {
        this.route.queryParams.pipe(take(1)).subscribe(async params => {
            if (params.filter == 'Range' || params.filter == 'Month' || params.filter == 'Quarter' || params.filter == 'Half' || params.filter == 'Year') {
                this.pickupDateType = params.filter;
                this.pickupRange = params.startDate != null || params.endDate != null ?
                    { dateFrom: params.startDate, dateTo: params.endDate }
                    : null;
                this.isShown = false;
                this.isPaused = true;
            }
            else {
                this.pickupDateType = '';
                this.pickupRange = { dateFrom: '', dateTo: '' };
            }
            
            if (params.custFilter == 'Customer' && params.custAccNo) {
                let customerPatched = false;
                let customerDetails: PartiesWithContract[];
                if (params.interdepot) {
                    customerDetails = await this.partiesService.get(params.custAccNo, params.interdepot).toPromise();
                }
                else {
                    customerDetails = await this.partiesService.get(params.custAccNo).toPromise();
                }
                if (!isNullOrUndefined(customerDetails) && customerDetails.length > 0) {
                    const filterRecords = customerDetails.find(z => z.party_Account_No == params.custAccNo);
                    if (filterRecords) {
                        this.newLiveLoadsForm.get('customer').patchValue({ party_Account_No: filterRecords.party_Account_No, party_Name: filterRecords.party_Name });
                        customerPatched = true;
                    }
                }
                if (!customerPatched) {
                    this.newLiveLoadsForm.get('customer').patchValue({ party_Account_No: params.custAccNo });
                }
            }

            if (params.searchTerm) {
                this.searchTerm = params.searchTerm;
            }

            if (params.status) {
                this.newLiveLoadsForm.get('status').patchValue(params.status);
            }

            this.tableState = {
                search: {
                    yardCode: null
                },
                datawithcompletedstatus: this.dataWithCompletedStatus,
                sort: { predicate: this.selectedPredicate, reverse: this.sortReverse }
            };

            if (params.yardCode) {
                this.tableState.search.yardCode = this.yardCode = params.yardCode;
            }

            if (params.custAccNo) {
                this.tableState.search.customer = params.custAccNo;
            }

            let startDate = moment.invalid();
            if (params.startDate) {
                startDate = moment(params.startDate);
                if (!startDate.isValid()) {
                    startDate = moment();
                }
            }
            if (startDate.isValid()) {
                this.startDate = this.tableState.search.startDate = startDate.format('YYYY-MM-DD');
            }

            let endDate = moment.invalid();
            if (params.endDate) {
                endDate = moment(params.endDate);
                if (!endDate.isValid()) {
                    endDate = moment();
                }
            }
            if (endDate.isValid()) {
                this.endDate = this.tableState.search.endDate = endDate.format('YYYY-MM-DD');
            }

            this.processQueryParamsDependentOnYardCode(params);
        });
    }

    processQueryParamsDependentOnYardCode(params: Params) {
        this.liveLoadsService.getYards().pipe(take(1)).subscribe(yards => {
            if (yards && yards.length) {
                this.yards = yards;
                if (this.yardCode && !this.yardControl.value) {
                    const yardNames = this.yardFilter(this.yardCode);
                    if (yardNames) {
                        const yard = yardNames.find(y => y.yardCode.toLocaleLowerCase() == this.yardCode.toLocaleLowerCase());
                        if (yard) {
                            this.setYardCode(yard);
                        }
                    }
                    this.setViewFromQueryString(params);
                }
            }
            if (!this.yardCode) {
                this.userPreferencesService.getDefaultYard().pipe(take(1)).subscribe(yard => {
                    if (!isNullOrUndefined(yard)) {
                        this.setYardCode(yard);
                    }
                    this.setViewFromQueryString(params);
                });
            }
        });
    }

    setViewFromQueryString(params: Params) {
        switch (params.view?.toLowerCase()) {
            case 'planned': {
                this.btnPlannedClicked();
                break;
            }
            case 'inprogress': {
                this.btnInProgressClicked();
                break;
            }
            case 'onroute': {
                this.btnOnRouteClicked();
                break;
            }
            case 'inbound': {
                this.btnInboundClicked();
                break;
            }
            default: {
                this.btnOutboundClicked();
            }
        }
        if (params.btn == 'Tomorrow') {
            this.btnTomorrowStyle = this.btnActiveStyleGreen;
            this.btnTomorrowClicked();
        } else if (params.btn == 'ThisWeek') {
            this.btnThisWeekStyle = this.btnActiveStyleGreen;
            this.btnThisWeekClicked();
        } else if (params.btn == 'NextWeek') {
            this.btnNextWeekStyle = this.btnActiveStyleGreen;
            this.btnNextWeekClicked();
        } else if (params.btn == 'Yesterday') {
            this.btnYesterdayStyle = this.btnActiveStyleGreen;
            this.btnYesterdayClicked();
        } else if (params.btn == 'Today' || !params.yardCode) {
            this.btnTodayStyle = this.btnActiveStyleGreen;
            this.btnTodayClicked();
        } else {
            this.tryStartPolling();
        }
    }

    clearFilter() {
        const url = this.router.url.split('?')[0];
        window.location.replace(url);
    }

    @HostListener('window:visibilitychange', ['$event'])
    onVisibilityChange($event) {
        const isVisible = $event.target.visibilityState === 'visible';
        if (isVisible) {
            this.reloadData = null;
            this.isPaused = false;
            this.tryStartPolling();
        } else {
            this.isPaused = true;
            if (this.isPaused && !isNullOrUndefined(this.reloadData)) {
                this.reloadData.unsubscribe();
            }
        }
    }

    statusChange() {
        const urlTree = this.router.createUrlTree([], {
            queryParams: { status: this.newLiveLoadsForm.value.status },
            queryParamsHandling: 'merge',
            preserveFragment: true
        });
        this.router.navigateByUrl(urlTree);
        this.tryStartPolling();
    }

    onSelectionChange(yard: YardViewModel) {
        this.setYardCode(yard);
        this.tryStartPolling();
    }
}
