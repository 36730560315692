<div class="newmodalWidth" style="width: fit-content;">
    <div class="title">
        New Instruction To Move
    </div>
    <br/>
    <table>
        <thead>
            <th>Sender Depot</th>
            <th>Remaining Weight</th>
            <th>Unit</th>
            <th>Due By Date</th>
            <th></th>
            <th>Remaining Loads</th>
        </thead>

        <tbody>
            <tr>
                <td>{{ data.depot }}</td>
                <td [innerText]="remainingWeight"></td>
                <td>{{data.uomCode}}</td>
                <td>{{ data.expiryDate }}</td>
                <td></td>
                <td [innerText]="remainingLoads"></td>
            </tr>
        </tbody>
    </table>
    <br/>
    <form id="instructionToMoveForm" name="instructionToMoveForm" [formGroup]="instructionToMoveForm"
        (ngSubmit)="submitForm()" autocomplete="off">
        <div formArrayName="materials">
            <table>
                <thead>
                    <th>Destination Depot</th>
                    <th>Due From Date</th>
                    <th>Transport Mode</th>
                    <th>On Hold</th>
                    <th>Priority</th>
                    <th>Contract Number</th>
                    <th>Weight</th>
                    <th>Total Loads</th>

                    <th></th>
                    <th></th>
                    <th></th>
                </thead>
                <tbody>
                    <tr *ngFor="let m of materials.controls; let i = index" [formGroupName]="i" autocomplete="off">
                        <td>
                            <mat-form-field>
                                <input type="text" matInput [matAutocomplete]="destinationAuto"
                                    placeholder="Destination depot" #i.destination [value]="i.destination"
                                    formControlName="destination" matTooltip={{i.destination}}
                                    (input)="destinationValueChange($event.target.value, i)">
                            </mat-form-field>
                            <mat-autocomplete [panelWidth]="400" #destinationAuto="matAutocomplete"
                                [displayWith]="displayDestination">

                                <app-autocomplete-disclaimer></app-autocomplete-disclaimer>

                                <mat-option *ngFor="let depot of filteredDepots?.items" [value]="depot">
                                    {{depot.depotNumber + ' - ' + depot.depotName}}
                                </mat-option>

                            </mat-autocomplete>
                        </td>
                        <td>
                            <mat-form-field class="small-form-field">
                                <input matInput [matDatepicker]="picker1" formControlName="dueFrom"
                                    placeholder="Due From Date">
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>

                        </td>
                        <td>
                            <mat-form-field class="mid-form-field">


                                <mat-select formControlName="transportMode" placeholder="Transport Mode">
                                    <mat-option></mat-option>
                                    <mat-option *ngFor="let tm of transportModes" [value]="tm">{{tm }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field class="small-form-field">
                                <mat-select formControlName="hold" placeholder="Hold">
                                    <mat-option></mat-option>
                                    <mat-option *ngFor="let opt of onHold" [value]="opt.value">{{ opt.name}}
                                    </mat-option>
                                </mat-select>


                            </mat-form-field>
                        </td>
                        <td testName="totalLoadsData">
                            <mat-form-field class="small-form-field">
                                <input matInput formControlName="priority" type="number" placeholder="Priority" min="1">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field>
                                <input type="text" matInput [matAutocomplete]="contractRefAuto"
                                    placeholder="Contract Ref" #i.contractHeaderId [value]="i.contractHeaderId"
                                    formControlName="contractHeaderId" matTooltip={{i.contractHeaderId}}
                                    (input)="contractRefValueChange($event.target.value, i)">
                            </mat-form-field>
                            <mat-autocomplete [panelWidth]="400" #contractRefAuto="matAutocomplete"
                                [displayWith]="displayContractRef">
                                <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
                                <mat-option *ngFor="let depot of filteredContractRef" [value]="depot">
                                    {{depot.contractRef + ' - ' + depot.partyName}}
                                </mat-option>
                            </mat-autocomplete>
                        </td>
                        <td>
                            <mat-form-field>
                                <input matInput formControlName="tonnesToMove" type="number" placeholder="Weight"
                                    (input)="calculateRemainingWeight()">
                                <mat-error *ngIf="weight.errors?.pattern">Please enter a valid value i.e. 1200
                                </mat-error>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field>
                                <input matInput formControlName="loadsToMove" placeholder="Total Loads" (input)="calculateRemainingLoad()" required>

                            </mat-form-field>
                        </td>
                        <td>
                            <button mat-button type="button" (click)="removeMaterial(i)"
                                class="addButton button-newmodalwidth">
                                <i class="fa fa-trash"></i>
                            </button>
                        </td>
                        <td>
                            <button mat-button type="button" (click)="copyMaterial(i)"
                                class="addButton button-newmodalwidth">
                                <i class="fa fa-copy"></i>
                            </button>
                        </td>

                    </tr>
                    <tr>
                        <td colspan="15">

                        </td>
                        <td>
                            <button mat-button type="button" (click)="addMaterialRow()"
                                class="addButton button-newmodalwidth" testName="addMaterial">
                                <i class="fa fa-plus-circle"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div mat-dialog-actions class="buttons-row">
            <button mat-flat-button type="button" (click)="dialogRef.close()">Cancel</button>
            <button mat-flat-button color="primary" type="submit" [disabled]="isSubmit"
                testName="create">Create</button>
            <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
        </div>
    </form>
</div>