import { Component, OnInit, Input, forwardRef, ViewChild, Renderer2, RendererFactory2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';
import { isNullOrUndefined } from '../tools';

@Component({
	selector: 'app-date-time-picker',
	templateUrl: './date-time-picker.component.html',
	styleUrls: ['./date-time-picker.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DateTimePickerComponent),
			multi: true
		}]
})
export class DateTimePickerComponent implements OnInit, ControlValueAccessor  {

	value: any;
  @Input()placeholder: string;
  @Input()required: boolean;
  @ViewChild('timePicker', { static: true }) timePicker: any;
  @ViewChild('pickerInput', { static: true }) pickerInput: any;
  dateCtrl = new FormControl();
  timeEdit = false;
  lastTime: any;
  
  
  writeValue(obj: any, applyTime = false): void {
  	if(isNullOrUndefined(this.pickerInput)){
  		return;
  	}
  	if (obj) {
  		this.value = moment.utc(obj);
  		if(applyTime){
  			this.setTime(this.pickerInput.nativeElement, this.value, this.value.hour(), this.value.minute());
  		} else {
  			this.setTime(this.pickerInput.nativeElement, this.value, 0, 0);
  		}
  	} else {
  		this.value = null;
  		this.pickerInput.nativeElement.value = '';
  	}
  }

  propagateChange = (_: any) => {};

  validatorChange = () => { };

  registerOnChange(fn) {
  	this.propagateChange = fn;
  }
  registerOnTouched() {}

  setDisabledState?(isDisabled: boolean): void {
  	this._renderer.setProperty(this.dateCtrl, 'disabled', isDisabled);
  }

  constructor(private _renderer: Renderer2) { }

  getPlaceholder() {
  	return this.required ? `${this.placeholder}*` : this.placeholder;
  }

  setValidation()
  {
  	if(this.required)
  	{
  		this.dateCtrl.setValidators([Validators.required]);
  	}
  }

  ngOnInit() {
  	this.setValidation();
  }

  chooseTime(timeEdit: boolean) {
  	this.timeEdit = timeEdit;
    
  	if(timeEdit){ 

  		if(!this.lastTime){
  			this.lastTime='07:00';
  		}

  		this.timePicker.defaultTime = this.lastTime;
  		this.timePicker.open();
  	}
  }

  timePicked(event: any, useClock:boolean) {
    
  	if(useClock)
  	{
  		this.timeEdit = true;
  		this.setTime(this.pickerInput.nativeElement, moment.utc(this.pickerInput.nativeElement.value),
  			this.timePicker.selectedHour.time, this.timePicker.selectedMinute.time);
  	}
  	else{   
  		this.timeEdit = false;   
  		this.setTime(this.pickerInput.nativeElement, moment.utc(this.pickerInput.nativeElement.value),0,0);
  	}
  }

  private setTime(input: any, mnt: any, hour: any, minute: any) {
  	moment.locale(window.navigator.language);

  	if (this.timeEdit) {
  		mnt = mnt.startOf('day');
  		mnt.set('hour', hour).set('minute', minute);
  	}
    
  	const dateStr = mnt.isValid() ? mnt.toISOString().slice(0,16) : '';
  	input.value = dateStr;
  	this.value = mnt;
  	this.propagateChange(mnt);
  	this.lastTime = mnt.hour() + ':' + mnt.minute();
  	if(this.required == undefined || this.required){
  		if(mnt.isValid()){
  			this.dateCtrl.setErrors(null);
  		}
  	}
  }

  private padTimeElement(ele: number): string {
  	if (ele >= 10) { return ele.toString(); }
  	return `0${ele}`;
  }
}
