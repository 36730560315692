import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EmailService } from '../gen/services/email.service';
import { ToastrService } from 'ngx-toastr';
import { PartyEmail } from '../gen/models';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
	selector: 'app-send-mail-dialog',
	templateUrl: './send-mail-dialog.component.html',
	styleUrls: ['./send-mail-dialog.component.scss']
})
export class SendMailDialogComponent implements OnInit {
	emailForm: FormGroup;
  @ViewChild('loadingSendMailDialogSpinner') loadingSendMailDialogSpinner: SpinnerComponent;
  loadingSendMailDialog = false;
  isSubmit = false;

  constructor(
    public dialogRef: MatDialogRef<SendMailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      message: string,
      from: string,
      to: PartyEmail[],
      subject: string,
      filename: string,
      attachment: string
    },
    private fb: FormBuilder,
    private emailService: EmailService,
    private toastr: ToastrService) { }

  //validator pattern based on https://stackoverflow.com/a/59805847 
  ngOnInit() {
  	this.emailForm = this.fb.group({
  		toAddress: ['', [Validators.required, Validators.pattern(/^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4}(, )?)+$/)]],
  		ccAddress: [this.data.from, Validators.pattern(/^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4}(, )?)+$/)],
  		message: [this.data.message, Validators.required]
  	});
  }


  //This function removes any trailing spaces after blocks of text
  onAddressTextChange(value: any, controlName: string) {
  	let split = value.split(',');
  	for (let i = 0; i < split.length; i++) {
  		split[i] = split[i].trim();
  	}
  	//removes any empty strings in array
  	split = split.filter(function (x) { return x; });
  	this.emailForm.controls[controlName].setValue(split.join(', '));
  }

  //TODO: update validator to validate list rather than just string
  send(value: any) {
  	if (this.emailForm.valid) {
  		this.loadingSendMailDialog = true;
  		this.isSubmit = true;

  		let splitTo = value.toAddress.split(',');
  		//done in forloops cause trim isnt working in foreach for these
  		for (var i = 0; i < splitTo.length; i++) {
  			splitTo[i] = splitTo[i].trim();
  		}
  		splitTo = splitTo.filter(t => t); //remove empty entries

  		let splitCc = value.ccAddress.split(',');
  		for (var i = 0; i < splitCc.length; i++) {
  			splitCc[i] = splitCc[i].trim();
  		}
  		splitCc = splitCc.filter(c => c); //remove empty entries

  		this.emailService.send({
  			fromEmail: this.data.from,
  			toEmail: splitTo,
  			ccEmail: splitCc,
  			subject: this.data.subject,
  			message: value.message,
  			fileName: this.data.filename,
  			attachment: this.data.attachment
  		}).subscribe(r => {
  			this.toastr.success('Email sent successfully', 'Email Sent');
  			setTimeout(() => {
  				this.loadingSendMailDialog = false;
  				this.dialogRef.close();
  			}, 1000);
  		});
  	}
  }

  ngOnDestroy(): void {
  	if (this.loadingSendMailDialogSpinner !== undefined)
  		this.loadingSendMailDialogSpinner.overlayRef.dispose();
  }
}
