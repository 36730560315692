<div class="dialog-header">Add Route To Market</div>
<div aria-label="Add Route To Market" class="dialog-body">
    <div mat-dialog-content layout="row">
        <app-spinner [displayProgressSpinner]="spinnerVisible"  [container]="addForm" #spinner></app-spinner>
        <form [formGroup]="routeToMarketAddForm" #addForm>
            <div class="row-box">
                <div class="small-margin-right">
                    <mat-form-field style="margin: 0px 0px; max-width: 250px;">
                      <input type="text" matInput formControlName="depot" [matAutocomplete]="depotAuto" placeholder="Origin"
                        #depotInput oninput="this.value = this.value.toUpperCase()">
                    </mat-form-field>
                    <mat-autocomplete #depotAuto="matAutocomplete" [displayWith]="displayDepot">
                      <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
                      <mat-option *ngFor="let depot of filteredDepots?.items " [value]="depot">
                        {{depot?.depotNumber + ' - ' + depot?.depotName}}</mat-option>
                    </mat-autocomplete>
                </div>
                <div class="small-margin-right">
                    <mat-form-field mat-no-float>
                        <input matInput type="search" placeholder="Grade" formControlName="grade" oninput="this.value = this.value.toUpperCase()">
                    </mat-form-field>
                </div>
                <div class="small-margin-right">
                    <mat-form-field mat-no-float>
                        <input matInput type="search" placeholder="Destination" formControlName="destination" oninput="this.value = this.value.toUpperCase()">
                    </mat-form-field>
                </div>
                <div class="small-margin-right">
                    <mat-form-field mat-no-float>
                        <input matInput [matDatepicker]="picker" placeholder="Effective Date" formControlName="effectiveDate">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>
                </div>
            </div>
        </form>
    </div>
    <div mat-dialog-actions layout="row" class="dialog-flex-justify-center">
        <button mat-flat-button (click)="cancel()" type="button" class="md-raised md-ink-ripple">Cancel</button>
        <button mat-flat-button (click)="ok()" color="primary" class="md-raised md-primary md-ink-ripple" [disabled]="isDsiabled" type="submit">Create</button>
    </div>
</div>