import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserPreferencesVm } from '../../models/UserPreferencesVm';
import { UserPreferencesService } from '../../services/user-preferences.service';

@Injectable()
export class UserPreferencesResolver implements Resolve<UserPreferencesVm[]> {
	constructor(private userPreferencesService: UserPreferencesService, private router: Router) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UserPreferencesVm[]> {
		return this.userPreferencesService.getUserPreference();
	}
}