<div aria-label="Edit Request">
    <div class="dialog-header">
        Edit Request
    </div>
    <app-spinner [displayProgressSpinner]="loadingEditRequestDialog" [container]="loadingEditRequestDialogBox"
        #loadingEditRequestDialogSpinner>
    </app-spinner>
    <div class="dialog-body" #loadingEditRequestDialogBox>
        <form [formGroup]="editAllocationForm" (ngSubmit)="ok()">
            <div class="row-container">
                <mat-form-field class="dialog-input-container" flex>
                    <input type="text" matInput formControlName="depot" [matAutocomplete]="depotAuto"
                        placeholder="Depot" oninput="this.value = this.value.toUpperCase()">
                </mat-form-field>
                <mat-autocomplete #depotAuto="matAutocomplete" [displayWith]="displayDepot">
                    <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
                    <mat-option *ngFor="let depot of filteredDepots?.items " [value]="depot">
                        {{depot?.depotNumber + ' ' + depot?.depotName}}</mat-option>
                </mat-autocomplete>
                <mat-form-field class="dialog-input-container" flex style="margin-bottom: 2px">
                    <mat-label>Ready At</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="readyDate">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="dialog-input-container">
                    <mat-label>Expiry Date</mat-label>
                    <input matInput [matDatepicker]="expicker" formControlName="expiryDate">
                    <mat-datepicker-toggle matSuffix [for]="expicker"></mat-datepicker-toggle>
                    <mat-datepicker #expicker></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="dialog-input-container">
                    <input type="text" matInput formControlName="heap" [matAutocomplete]="heapAuto" placeholder="Heap"
                           oninput="this.value = this.value.toUpperCase()">
                </mat-form-field>
                <mat-autocomplete #heapAuto="matAutocomplete" [displayWith]="displayHeap">
                    <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
                    <mat-option *ngFor="let heap of filteredHeaps?.items" [value]="heap">
                        {{heap.heapShortName + ' ' + heap.heapDesc}}</mat-option>
                </mat-autocomplete>

            </div>
            <div class="row-container">
                <mat-form-field class="dialog-input-container" flex>
                    <input matInput maxlength="500" formControlName="gradedescription" [placeholder]="gradedescriptionplaceholder" required>
                </mat-form-field>
                <mat-form-field class="dialog-input-container" flex>
                    <mat-select formControlName="containerSize" placeholder="Container Type">
                        <mat-option></mat-option>
                        <mat-option *ngFor="let opt of pageModel?.containerSizes" [value]="opt.id">
                            {{ opt?.displayName }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="dialog-input-container" flex>
                    <mat-select formControlName="packaging" placeholder="Packaging">
                        <mat-option></mat-option>
                        <mat-option *ngFor="let opt of pageModel?.packageOptions" [value]="opt.id">
                            {{ opt?.displayName }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="dialog-input-container" flex>
                    <mat-select placeholder="Status" formControlName="status">
                        <mat-option *ngFor="let opt of pageModel?.statuses" [value]="opt?.id">{{ opt?.name }}
                        </mat-option>
                    </mat-select>
                    <div class="md-errors-spacer"></div>
                </mat-form-field>
            </div>
            <div class="row-container">
                <mat-form-field class="dialog-input-container" flex>
                    <input matInput formControlName="remainingWeight" placeholder="Remaining Weight">
                    <mat-error *ngIf="remainingWeight.errors?.pattern">Please enter a valid value i.e. 1200</mat-error>
                    <mat-hint *ngIf="remainingLoads.dirty && !remainingWeight.dirty">Remaining Loads updated</mat-hint>
                </mat-form-field>
                <mat-form-field class="dialog-input-container" flex>
                    <input matInput formControlName="weight" placeholder="Weight"
                        matTooltip="Display only, please adjust the Remaining Weight">
                </mat-form-field>
                <mat-form-field class="dialog-input-container" flex>
                    <mat-select formControlName="uom" placeholder="Unit">
                        <mat-option></mat-option>
                        <mat-option *ngFor="let opt of uoms" [value]="opt.id">{{ opt?.name }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="row-container">
                <mat-form-field class="dialog-input-container" flex>
                    <input matInput type="number" placeholder="Remaining Loads" formControlName="remainingLoads">
                    <mat-hint *ngIf="remainingWeight.dirty && !remainingLoads.dirty">Remaining Weight updated</mat-hint>
                </mat-form-field>
                <mat-form-field class="dialog-input-container" flex>
                    <input matInput formControlName="totalLoads" placeholder="Total Loads"
                        matTooltip="Display only, please adjust the Remaining Loads">
                </mat-form-field>
                <div [@.disabled]="disableAnimation">
                    <mat-accordion>
                        <mat-expansion-panel class="load-times-expansion-panel" [expanded]="false">
                            <mat-expansion-panel-header>
                                <mat-panel-title class="load-times-expansion-panel-title">
                                    Load Times
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <mat-form-field *ngIf="remainingLoads.value > 1">
                                <mat-select id="ddlIncrement" formControlName="increment"
                                    placeholder="Pickup other loads at every"
                                    (selectionChange)="setIncrements($event.value)">
                                    <mat-option *ngFor="let inc of increments" [value]="inc">{{ inc }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="load-times-panel-list-container">
                                <mat-list class="load-times-expansion-panel-mat-list" formArrayName="loadTimes">
                                    <mat-list-item *ngFor="let l of loadTimes.controls; let i = index"
                                        [formGroupName]="i" class="load-times-expansion-panel-mat-list-item">
                                        <mat-form-field>
                                            <input matInput (change)="pickupTimeChange($event, i)" class="md-input" id="datetime" formControlName="time"
                                                type="time" for placeholder="Pickup load {{i+1}} at">
                                        </mat-form-field>
                                    </mat-list-item>
                                </mat-list>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>

            <div mat-dialog-actions layout="row" class="dialog-flex-justify-center dialog-margin-bottom-10">
                <button mat-flat-button (click)="cancel()" type="button" class="md-raised md-ink-ripple">Cancel</button>
                <button mat-flat-button ng-disabled="allocationForm.$invalid" type="submit" color="primary"
                    [disabled]="isSubmit" class="md-raised md-primary md-ink-ripple">Save</button>
            </div>
        </form>
    </div>

</div>
