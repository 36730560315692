<div aria-label="Edit Request">
    <div class="dialog-header">
        Edit Request
    </div>
    <app-spinner [displayProgressSpinner]="loadingEditRequestDialog" [container]="loadingEditRequestDialogBox"
        #loadingEditRequestDialogSpinner>
    </app-spinner>
    <div class="dialog-body" #loadingEditRequestDialogBox>
        <form [formGroup]="editInstructionToMoveForm" (ngSubmit)="ok()">
            <div class="row-container">
                <mat-form-field class="dialog-input-container" flex>
                    <input type="text" matInput formControlName="depot" [matAutocomplete]="depotAuto"
                        placeholder="Depot" oninput="this.value = this.value.toUpperCase()">
                </mat-form-field>
                <mat-autocomplete #depotAuto="matAutocomplete" [displayWith]="displayDepot">
                    <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
                    <mat-option *ngFor="let depot of filteredDepots?.items " [value]="depot">
                        {{depot?.depotNumber}} {{depot?.depotName}}</mat-option>
                </mat-autocomplete>

                <mat-form-field class="dialog-input-container" flex>
                    <input type="text" matInput formControlName="destination" [matAutocomplete]="depotAuto"
                        placeholder="Destination" oninput="this.value = this.value.toUpperCase()">
                </mat-form-field>
                <mat-autocomplete #depotAuto="matAutocomplete" [displayWith]="displayDepot">
                    <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
                    <mat-option *ngFor="let depot of filteredDepots?.items " [value]="depot">
                        {{depot?.depotNumber}} {{depot?.depotName}}</mat-option>
                </mat-autocomplete>

                <mat-form-field class="dialog-input-container" flex style="margin-bottom: 2px">
                    <mat-label>Due From Date</mat-label>
                    <input matInput [matDatepicker]="picker1" formControlName="dueFrom">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="dialog-input-container">
                    <mat-label>Due By Date</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="dueBy">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
               
                <mat-form-field class="dialog-input-container" flex>
                    <mat-select formControlName="transportMode" placeholder="Transport Mode">
                        <mat-option></mat-option>
                        <mat-option *ngFor="let tm of transportModes" [value]="tm">{{tm }}</mat-option>
                      </mat-select>
                </mat-form-field>
            </div>
            <div class="row-container">
                <mat-form-field class="dialog-input-container" flex>
                    <input matInput formControlName="priority" type="number" placeholder="Priority" >
                </mat-form-field>
                <mat-form-field class="dialog-input-container" flex>
                    <input type="text" matInput formControlName="contractHeaderId" [matAutocomplete]="contractRefAuto"
                        placeholder="Contract Ref" oninput="this.value = this.value.toUpperCase()">
                </mat-form-field>
                <mat-autocomplete #contractRefAuto="matAutocomplete" [displayWith]="displayContractRef">
                    <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
                    <mat-option *ngFor="let depot of filteredContractRef" [value]="depot">
                        {{depot.contractRef + ' ' + depot.partyName}}</mat-option>
                </mat-autocomplete>

                <mat-form-field class="small-form-field">
                    <mat-select formControlName="hold" placeholder="Hold">
                      <mat-option></mat-option>
                      <mat-option *ngFor="let opt of onHold" [value]="opt.value">{{ opt.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
            </div>
            <div class="row-container">
                <div style="margin: 10px 0px 0px 10px;">
                    <app-autocomplete autoCompleteDelay="750" formControlName="grade" #gradeInput
                    [getResults]="gradeService.getGrade" placeholder="Grade"></app-autocomplete>
                </div>
                <mat-form-field class="dialog-input-container" flex>
                    <mat-select formControlName="uomId" placeholder="Unit">
                        <mat-option></mat-option>
                        <mat-option *ngFor="let opt of uoms" [value]="opt.id">{{ opt?.name }}</mat-option>
                      </mat-select>
                </mat-form-field>
                <mat-form-field class="dialog-input-container" flex>
                    <input matInput formControlName="tonnesToMove" type="number" placeholder="Weight">
                    <mat-error *ngIf="tonnesToMove.errors?.pattern">Please enter a valid value i.e. 1200</mat-error>
                </mat-form-field>
            </div>
            <div class="row-container">
                <mat-form-field class="dialog-input-container" flex>
                    <input matInput formControlName="loadsToMove" placeholder="Total Loads" required>
                </mat-form-field>
                <mat-form-field class="dialog-input-container" flex>
                    <mat-select formControlName="containerSizeId" placeholder="Container Type">
                        <mat-option></mat-option>
                        <mat-option *ngFor="let opt of pageModel?.containerSizes" [value]="opt.id">{{ opt?.displayName }}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="containerSize.touched && containerSize.errors?.required">This is required!
                      </mat-error>
                </mat-form-field>
                <mat-form-field class="dialog-input-container" flex>
                <mat-select formControlName="packaging" placeholder="Packaging">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let opt of pageModel?.packageOptions" [value]="opt.id">{{ opt?.displayName }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="packaging.touched && packaging.errors?.required">This is required!</mat-error>
                </mat-form-field>
            </div>

            <div mat-dialog-actions layout="row" class="dialog-flex-justify-center dialog-margin-bottom-10">
                <button mat-flat-button (click)="cancel()" type="button" class="md-raised md-ink-ripple">Cancel</button>
                <button mat-flat-button ng-disabled="allocationForm.$invalid" type="submit" color="primary"
                    [disabled]="isSubmit" class="md-raised md-primary md-ink-ripple">Save</button>
            </div>
        </form>
    </div>

</div>
