import { Component, OnInit, Inject, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
	Haulier, ContractHeaderViewModel, ContractPartyVm, ContainerType, UomVm,
	AllocateModel, ContractLineViewModel, AllocateRequestVm, AppointmentVm, InitModel, AllocateRequestWeightLoads
} from '../gen/models';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { HauliersService } from '../gen/services/hauliers.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContainersService } from '../gen/services/containers.service';
import { UomsService } from '../gen/services/uoms.service';
import { isNullOrUndefined } from '../tools';
import { AppointmentService } from '../gen/services/appointment.service';
import { ToastrService } from 'ngx-toastr';
import { InitService } from '../services/init.service';
import * as moment from 'moment-timezone';
import { ContractLineVmExtended } from '../allocate-create-dialog/allocate-create-dialog.component';
import { haulagePriceMatrixVM } from '../gen/models/haulagePriceMatrixVM';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
	selector: 'app-allocate-dialog',
	templateUrl: './allocate-dialog.component.html',
	styleUrls: ['./allocate-dialog.component.scss']
})
export class AllocateDialogComponent implements OnInit {
	@ViewChild('additional') additional;
	@ViewChild('loads') loads: HTMLInputElement;
	@ViewChild('weightPerLoad') weightPerLoad: HTMLInputElement;
	@ViewChild('qty') qty: HTMLInputElement;
	@ViewChild('haulageRatesSpinner') haulageRatesSpinner: SpinnerComponent;
	amounts: number[];
	selectedTab = 'requests';
	canAllocate = false;
	allocationForm: FormGroup;
	filteredHauliers: Haulier[];
	containerTypes: ContainerType[];
	uoms: UomVm[];
	additionalHidden = true;
	noLinesPickedForBulk = false;
	noLoads = false;
	noPickupDate = false;
	bulkAllocate = false;
	bulkAllocationMode: string;
	datePlaceholder: string[] = [];
	weekPlaceholder: string[] = [];
	timePlaceholder: string[] = [];
	firstMonday: string;
	selectedContractLine: ContractLineViewModel;
	requestGradesToAllocate: string[] = [];
	requestsToAllocate: any[];
	contractLinesToAllocate: string[];
	selectedRequest: AllocatedGrade;
	selectedContractLineGrade: string;
	selectedAllocate: AllocatedGrade;
	allocatedGrades: AllocatedGrade[] = [];
	appointments: AppointmentVm[];
	initModel: InitModel;
	defaultAdditionalCostUomId = 0;
	isMixedGradeContract = false;
	haulageRates: haulagePriceMatrixVM[] = [];
	haulagematrixDropdownOnly = false;
	datetimeFromToolTipText = 'No Request Ready Date found';
	containerTypeId: number;
	depotNo: string;
	remainingInstructionsText: string;
	instructionsMaxlength = 1500;
	isBooked = false;

	constructor(public dialogRef: MatDialogRef<AllocateDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { party: ContractPartyVm, contract: ContractHeaderViewModel, requests: any[] },
		private fb: FormBuilder,
		private hauliersService: HauliersService,
		private containerService: ContainersService,
		private uomsService: UomsService,
		private appointmentService: AppointmentService,
		private toastr: ToastrService,
		private initService: InitService,
		private changeDetectorRef: ChangeDetectorRef
	) { }

	get haulier() {
		return this.allocationForm.get('haulier');
	}

	get maxCosts() {
		return this.initModel.config.maxAllocationCosts;
	}

	ngOnInit() {
		this.selectedContractLine = this.data.contract.contractLines[0];
		this.depotNo = this.data.requests[0].depotNo;
		this.isMixedGradeContract = this.data.contract
			&& this.data.contract.contractLines
			&& this.data.contract.contractLines.find(a => Boolean(a.allocatedViaReference)) !== undefined;
		this.allocationForm = this.fb.group({
			haulier: [''],
			containerTypeId: ['', Validators.required],
			containerNumber: [''],
			instructions: [this.data.contract.comments],
			additionalCosts: [''],
			allocationAppointments: [''],
			requests: this.fb.array(this.initRequests(this.data)),
			bulkAllocate: [''],
			loadsByDate: this.fb.array(this.addBulkAllocationFormGroup(this.datePlaceholder)),
			loadsByWeek: this.fb.array(this.addBulkAllocationFormGroup(this.weekPlaceholder)),
			bulkAllocationPickupDate: [''],
			bulkAllocationRequiredLoads: [''],
			loadsByTime: this.fb.array([]),
			bulkAllocateIns: [this.data.contract.comments],
			isBooked: ['']
		});

		this.allocationForm.controls['instructions'].valueChanges.subscribe((newVal: string) => {
			this.remainingInstructionsText = `${newVal.length}/${this.instructionsMaxlength}`;
		});

		this.allocationForm.controls.containerTypeId.setValue(this.data.contract.containerTypeID);

		this.initService.initData$.subscribe(r =>
			this.initModel = r
		);

		this.requestsToAllocate = this.data.requests
			.map(r => ({ requestId: r.requestId, grade: r.grade }))
			.filter(g => this.data.contract.contractLines.map(l => l.grade).indexOf(g.grade) === -1)
			.filter((v, i, s) => s.indexOf(v) === i)
			.sort((a, b) => a.grade.localeCompare(b.grade));

		this.contractLinesToAllocate = this.data.contract.contractLines
			.map(r => r.grade === null || r.grade === '' ? r.salesGrade : r.grade)
			.filter((v, i, s) => s.indexOf(v) === i)
			.sort((a, b) => a.localeCompare(b));

		this.appointmentService
			.getAvailableAppointmentsForParty(this.data.party.partyAccountNo, this.data.contract.customerContractRef, 0)
			.subscribe(a => this.appointments = a);
		this.containerService.get().subscribe(c => this.containerTypes = c);

		this.uomsService.getAll().subscribe(u => {
			this.uoms = u;
			if (this.uoms) {
				this.defaultAdditionalCostUomId = this.uoms.find(a => a.code === 'LD').id;
			}
		});

		if (this.requestGradesToAllocate.length > 0) { this.autoMapGrades(); }

		this.data.contract.contractLines.forEach(l => {
			const line = l as ContractLineVmExtended;
			line.loadsWarning = undefined;
			line.weightWarning = undefined;
		});

		if (this.data.requests && this.data.requests.length === 1 && this.data.requests[0] && this.data.requests[0].readyDate) {
			this.datetimeFromToolTipText = 'Request Ready Date ' + (new Date(this.data.requests[0].readyDate).toLocaleString());
		}
	}

	ngOnDestroy(): void {
		if (this.haulageRatesSpinner !== undefined && this.haulageRatesSpinner.overlayRef !== undefined)
			this.haulageRatesSpinner.overlayRef.dispose();
	}

	unallocatedRequests() {
		return this.requestsToAllocate.filter(r => !this.allocatedGrades.find(m => m.requestGrade === r.grade));
	}

	initRequests(data: { party: ContractPartyVm; contract: ContractHeaderViewModel; requests: any[]; }): FormGroup[] {
		const groupArr: FormGroup[] = [];
		for (const r of data.requests) {
			const contractLines = this.initContractLines(r.grade);
			if (contractLines.length === 0) {
				this.requestGradesToAllocate.push(r.grade);
				this.addRequestGroup(groupArr, r, {}, contractLines);
			} else {
				const grp = this.addRequestGroup(groupArr, r, {}, contractLines);
				this.calculateRequired(r).subscribe(c => {
					grp.controls.requiredWeight.patchValue(c.requiredWeight);
					grp.controls.requiredWeightUomId.patchValue(c.contractUomId);
					grp.controls.requiredLoads.patchValue(c.requiredLoads);
					grp.controls.requiredWeightPerLoad.patchValue(c.requiredWeightPerLoad);
					r.convertedWeight = c.convertedWeight;
				});
			}
		}
		return groupArr;
	}

	private addRequestGroup(groupArr: FormGroup[], r: any, c: any, contractLines: FormGroup[]): FormGroup {
		const grp = this.fb.group({
			requestId: r.requestId,
			depotNo: r.depotNo,
			requiredWeight: c.requiredWeight,
			requiredWeightUomId: c.requiredWeightUomId,
			requiredLoads: c.requiredLoads,
			requiredWeightPerLoad: c.requiredWeightPerLoad,
			contractLines: this.fb.array(contractLines),
			hasLinked: contractLines.length > 0,
			fullyAllocate: false
		});
		groupArr.push(grp);
		return grp;
	}

	initContractLines(grade: string): FormGroup[] {
		const groupArr: FormGroup[] = [];
		const lines = this.getContractLines(grade);
		for (const l of lines) {
			const group = this.fb.group({
				contractLineId: l.contractLineId
			});
			groupArr.push(group);
		}
		return groupArr;
	}

	take(request: any) {
		request.taken = true;
	}

	qtyChange(request: AllocateRequestVmExtended, qty: string, index: number, loadsInput: HTMLInputElement,
		weightPerInput: HTMLInputElement): number {
		request.takenQty = +qty;
		if (!loadsInput.disabled && weightPerInput.disabled) {
			this.weightPerChange(weightPerInput.value, qty, index, loadsInput);
		} else if (!weightPerInput.disabled && loadsInput.disabled) {
			this.loadsChange(loadsInput.value, qty, index, weightPerInput);
		} else {
			const line = this.selectedContractLine as ContractLineVmExtended;
			this.checkTargets(+qty, +loadsInput.value, line);
		}
		return +qty;
	}

	get total() {
		const takenTotal = this.data.requests
			.map(r => {
				return r.takenQty ? r.takenQty : r.remainingWeight;
			})
			.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
		return this.roundWeight(takenTotal);
	}

	cancel() {
		this.dialogRef.close();
	}

	selectTab(tab: string) {
		this.selectedTab = tab;
	}

	roundWeight(weight: number) {
		return Math.round(weight * 10) / 10;
	}

	getDepots(): string {
		const contractlines = this.data.contract.contractLines;
		let depots = '';
		contractlines.forEach(element => {
			if (element.contractLineDepotPremiumDepotNos != null) {
				if (depots === '') {
					depots += element.contractLineDepotPremiumDepotNos.join(', ');
				} else {
					const split = depots.split(', ');
					const filteredDepots = [];
					element.contractLineDepotPremiumDepotNos.forEach(e => {
						if (!split.includes(e)) {
							filteredDepots.push(e);
						}
					});
					if (filteredDepots.length > 0) {
						depots += ', ' + filteredDepots.join(', ');
					}
				}
			}
		});

		return depots;
	}

	getContractLines(grade: string): ContractLineViewModel[] {
		let lines = this.data.contract.contractLines.filter(l => l.grade === grade);
		if (isNullOrUndefined(lines) || lines.length === 0) { //fall back to sales grade 
			lines = this.data.contract.contractLines.filter(l => l.salesGrade === grade);
		}
		if (this.allocatedGrades.length > 0 && (isNullOrUndefined(lines) || lines.length === 0)) {
			const allocatedContractLineGrades = this.allocatedGrades.filter(m => m.requestGrade === grade);
			if (allocatedContractLineGrades.length > 0) {
				const allocatedContractLineGrade = allocatedContractLineGrades[0].contractLineGrade;
				lines = this.data.contract.contractLines.filter(l => l.grade === allocatedContractLineGrade);
				if (isNullOrUndefined(lines) || lines.length === 0) { //fall back to sales grade
					lines = this.data.contract.contractLines.filter(l => l.salesGrade === allocatedContractLineGrade);
				}
			}
		}
		return lines;
	}

	allocate(formValue: AllocateModel): boolean {
		formValue.contractHeaderId = this.data.contract.contractHeaderId;
		formValue.isMixedLoad = this.isMixedGradeContract;
		formValue.partyAccountNumber = this.data.party.partyAccountNo;
		formValue.contractRef = this.data.contract.customerContractRef;
		formValue.haulierAccountNumber = formValue['haulier'].accountNumber;
		formValue.bulkAllocate = this.bulkAllocate;
		formValue.isBooked = this.isBooked;
		formValue.firstComeFirstServe;
		formValue.pendingDispatch;

		if (!this.validateAppointments(formValue.allocationAppointments)) {
			return false;
		}

		for (const value of formValue.allocationAppointments) {
			value.haulierAccountNumber = value['haulier'].accountNumber;
			if (value.agreedHaulageRateUomId <= 0) {
				value.agreedHaulageRateUomId = this.defaultAdditionalCostUomId;
			}
		}

		if (this.bulkAllocate) {
			if (this.bulkAllocationMode == 'date') {
				if (formValue.loadsByDate.length == formValue.loadsByDate.filter(l => !(l.requiredLoads > 0)).length) {
					this.noPickupDate = false;
					this.noLoads = true;
					return;
				}
				else
					this.noLoads = false;

				formValue.bulkAllocationType = 1;
				formValue.pickupDateTime = new Date().toLocaleString();
			}
			else if (this.bulkAllocationMode == 'time') {
				if (formValue.loadsByTime.length == formValue.loadsByTime.filter(l => isNullOrUndefined(l.pickupTime)).length) {
					this.noLoads = false;
					this.noPickupDate = true;
					return;
				}
				else
					this.noPickupDate = false;

				formValue.bulkAllocationType = 2;
				formValue.pickupDateTime = this.allocationForm.controls.bulkAllocationPickupDate.value;
			}
			else if (this.bulkAllocationMode == 'week') {
				if (formValue.loadsByWeek.length == formValue.loadsByWeek.filter(l => !(l.requiredLoads > 0)).length) {
					this.noPickupDate = false;
					this.noLoads = true;
					return;
				}
				else
					this.noLoads = false;

				formValue.bulkAllocationType = 3;
				formValue.pickupDateTime = new Date(this.firstMonday).toLocaleString();
			}

			formValue.instructions = this.allocationForm.controls.bulkAllocateIns.value;
		}
		else { //todo: try and use form validation instead. We can't easily because these fields are not always required (e.g. bulk allocate or when using 'fully allocate')
			if (formValue.requests.length > 0) {
				const requestsWithoutLoads = formValue.requests.filter(r => r.requiredLoads === 0);
				if (requestsWithoutLoads.length !== 0) {
					this.toastr.error('Form Invalid', 'Required Loads must be set.');
					return;
				}

				const requestsWithoutWeight = formValue.requests.filter(r => r.requiredWeight === 0);
				if (requestsWithoutWeight.length !== 0) {
					this.toastr.error('Form Invalid', 'Required Weight must bet set.');
					return;
				}
			}
		}

		if (formValue.requests.length === 1 && formValue.requests[0].contractLines.length > 1) {
			formValue.requests[0].contractLines = [{
				contractLineId: this.selectedContractLine.contractLineId
			}];
		}

		if (this.allocationForm.valid) {
			this.dialogRef.close(formValue);
		} else {
			if (!isNullOrUndefined(this.allocationForm.errors) && !isNullOrUndefined(this.allocationForm.errors.dates)) {
				this.toastr.error('Form Invalid', this.allocationForm.errors.dates);
			}
		}
	}

	validateAppointments(appts: any): boolean {
		if (isNullOrUndefined(appts) || appts === '') {
			this.toastr.error('Form Invalid', 'Pickup date is required');
			return false;
		}
		for (const apt of appts) {
			if (isNullOrUndefined(apt.pickupDateTime) || apt.pickupDateTime === '' || !apt.pickupDateTime.isValid()) {
				this.toastr.error('Form Invalid', 'A valid Pickup date is required');
				return false;
			} else if (apt.pickupToDateTime && apt.pickupDateTime > apt.pickupToDateTime) {
				this.toastr.error('Form Invalid', 'Pickup date should be less than pickupToDateTime');
				return false;
			} else if (apt.customerAptToDateTime &&
				(isNullOrUndefined(apt.customerAptDateTime) || apt.customerAptDateTime === '' || !apt.customerAptDateTime.isValid()
					|| apt.customerAptDateTime > apt.customerAptToDateTime)) {
				this.toastr.error('Form Invalid', 'CustomerAptDateTime should be less than customerAptToDateTime');
				return false;
			} else if (!isNullOrUndefined(apt.agreedHaulageRate) && apt.agreedHaulageRate > 0 && (isNullOrUndefined(apt.agreedHaulageRateUomId) || apt.agreedHaulageRateUomId == 0)) {
				this.toastr.error('Form Invalid', 'Please select a Haulge Spot Rate UOM');
				return false;
			}
		}
		return true;
	}

	loadsChange(
		loads: string, qty: string, index: number,
		weightPerInput: HTMLInputElement) {
		const weightPer = +loads > 0 ? +qty / +loads : 0;
		weightPerInput.value = `${weightPer}`;
		weightPerInput.disabled = true;
		this.getRequestGroup(index).get('requiredWeightPerLoad').setValue(weightPer);
		const line = this.selectedContractLine as ContractLineVmExtended;
		this.checkTargets(+qty, +loads, line);
	}

	weightPerChange(
		weightPer: string, qty: string, index: number,
		loadsInput: HTMLInputElement) {
		const loads = +weightPer > 0 ? Math.ceil(+qty / +weightPer) : 0;
		loadsInput.value = `${loads}`;
		loadsInput.disabled = true;
		this.getRequestGroup(index).get('requiredLoads').setValue(loads);
		const line = this.selectedContractLine as ContractLineVmExtended;
		this.checkTargets(+qty, +loads, line);
	}

	checkTargets(weight: number, loads: number, line: ContractLineVmExtended) {
		if (line.balanceWeight && weight > line.balanceWeight) {
			line.weightWarning = `The weight: ${weight} exceeds the balance weight: ${line.balanceWeight}`;
		} else {
			line.weightWarning = undefined;
		}

		if (line.balanceLoads && loads > line.balanceLoads) {
			line.loadsWarning = `The loads: ${loads} exceeds the balance loads: ${line.balanceLoads}`;
		} else {
			line.loadsWarning = undefined;
		}
	}

	resetInputs(loadsInput: HTMLInputElement, weightPerInput: HTMLInputElement, index: number, request: any) {
		this.calculateRequired(request).subscribe(c => {
			loadsInput.disabled = false;
			weightPerInput.disabled = false;
			loadsInput.value = `${c.requiredLoads}`;
			weightPerInput.value = `${c.requiredWeightPerLoad}`;
			this.getRequestGroup(index).get('requiredLoads').setValue(c.requiredLoads);
			this.getRequestGroup(index).get('requiredWeightPerLoad').setValue(c.requiredWeightPerLoad);
		});
	}

	getRequestGroup(index: number): FormGroup {
		const requestsArray = this.allocationForm.get('requests') as FormArray;
		return requestsArray.controls[index] as FormGroup;
	}

	showHaulierClear(haulierInput: HTMLInputElement): boolean {
		return !isNullOrUndefined(haulierInput.value) && haulierInput.value !== '';
	}

	haulierClear() {
		this.haulier.setValue({ accountNumber: null, name: null });
	}

	selectContractLine(line: ContractLineViewModel, index: number) {
		// only allow select contract line if it's not a mixed load
		// and there is more than one contract line available
		if (this.data.requests.length === 1 && this.data.contract.contractLines.length > 1) {
			this.selectedContractLine = line;
		}
	}

	selectContractType(event: any) {
		this.containerTypeId = event.value;
	}

	contractLineClass(line: ContractLineViewModel) {
		// only allow select contract line if it's not a mixed load
		// and there is more than one contract line available
		if (this.data.requests.length === 1 && this.data.contract.contractLines.length > 1) {
			if (this.selectedContractLine.contractLineId === line.contractLineId) {
				return 'selectable selected';
			}
			return 'selectable';
		}
	}

	autoMapGrades() {
		if (this.requestsToAllocate.length === 1
			&& this.contractLinesToAllocate.length === 1) {
			this.selectRequestGrade(this.requestsToAllocate[0]);
			this.selectContractLineGrade(this.contractLinesToAllocate[0]);
		}
	}

	selectRequestGrade(request: any) {
		this.selectedRequest = {
			requestId: request.requestId,
			requestGrade: request.grade,
			contractLineGrade: null
		};
	}

	requestGradeClass(grade: string) {
		return this.selectedRequest && this.selectedRequest.requestGrade === grade ? 'grade-line-selected' : 'grade-line';
	}

	selectContractLineGrade(grade: string) {
		this.selectedContractLineGrade = grade;
	}

	contractLineGradeClass(grade: string) {
		return this.selectedContractLineGrade === grade ? 'grade-line-selected' : 'grade-line';
	}

	selectAllocateLine(allocateGrade: AllocatedGrade) {
		this.selectedAllocate = allocateGrade;
	}

	allocateLineClass(allocate: AllocatedGrade) {
		return this.selectedAllocate === allocate ? 'grade-line-selected' : 'grade-line';
	}

	allocateGrades() {
		if (this.selectedRequest && this.selectedContractLineGrade) {
			this.selectedRequest.contractLineGrade = this.selectedContractLineGrade;
			this.allocatedGrades.push(this.selectedRequest);
			this.requestGradesToAllocate.splice(this.requestGradesToAllocate.indexOf(this.selectedRequest.requestGrade), 1);

			this.selectedRequest = null;
			this.selectedContractLineGrade = null;
			this.initAllocatedRequests();
		}
	}

	unallocateGrades() {
		if (this.selectedAllocate) {
			this.requestGradesToAllocate.push(this.selectedAllocate.requestGrade);
			this.allocatedGrades.splice(this.allocatedGrades.indexOf(this.selectedAllocate), 1);
			this.requestsToAllocate.find(u => u.grade === this.selectedAllocate.requestGrade).allocated = false;

			this.selectedAllocate = null;
			this.initAllocatedRequests();
		}
	}

	private initAllocatedRequests() {
		for (const mg of this.allocatedGrades) {
			const request = this.data.requests.filter(r => r.requestId === mg.requestId)[0];
			const requestsFormArray = this.allocationForm.controls.requests as FormArray;
			const requestFormGroup = requestsFormArray.controls.filter(c => c.get('requestId').value === mg.requestId)[0] as FormGroup;
			requestFormGroup.controls.contractLines = this.fb.array(this.initContractLines(mg.requestGrade));

			this.calculateRequired(request).subscribe(calc => {
				requestFormGroup.controls.requiredWeight.patchValue(calc.requiredWeight);
				requestFormGroup.controls.requiredLoads.patchValue(calc.requiredLoads);
				requestFormGroup.controls.requiredWeightPerLoad.patchValue(calc.requiredWeightPerLoad);
				requestFormGroup.controls.requiredWeightUomId.patchValue(calc.contractUomId);
				request.convertedWeight = calc.convertedWeight;
			});
		}
	}


	private calculateRequired(request: AllocateRequestVmExtended): Observable<AllocateRequestWeightLoads> {
		const contractUomId = this.getContractLines(request.grade)[0].weightUomId;
		const requestUomId = request.remainingWeightUomId;

		//if the request has a Uom and the Uom is not the same as the contract line, get the conversion rate, otherwise assume 1
		const obs: Observable<number> = (!isNullOrUndefined(requestUomId) && contractUomId !== requestUomId) ? this.uomsService.getConversionFactor(request.remainingWeightUomId, contractUomId) : of(1);

		return obs.pipe(map(c => {
			const weightloads: AllocateRequestWeightLoads = { contractUomId: contractUomId };
			if (isNullOrUndefined(request.remainingWeightUomId)) {
				return weightloads;
			}
			weightloads.convertedWeight = request.remainingWeight * c;
			request.takenQty = this.getBalanceForGrade(request.grade, weightloads.convertedWeight);
			weightloads.requiredWeight = this.roundWeight(request.takenQty);
			const maxRequiredWeightPerLoad = request.remainingLoads > 0 ? this.roundWeight(weightloads.convertedWeight) / request.remainingLoads : 0;
			weightloads.requiredLoads = maxRequiredWeightPerLoad > 0 ? Math.ceil(weightloads.requiredWeight / maxRequiredWeightPerLoad) : 0;
			weightloads.requiredWeightPerLoad = weightloads.requiredLoads > 0 ? weightloads.requiredWeight / weightloads.requiredLoads : 0;

			return weightloads;
		}));
	}
	selectAppointment(value: any) {

		this.allocationForm.controls.customerAptRef.reset();
		this.allocationForm.controls.appointmentComment.reset();
		this.allocationForm.controls.customerAptDateTime.reset();
		this.allocationForm.controls.customerAptToDateTime.reset();

		if (value > 0) {

			const appointmentDetails = this.appointments.find(a => a.appointmentId === value);
			const startDate = moment.utc(appointmentDetails.startDate);
			const endDate = moment.utc(appointmentDetails.endDate);
			this.allocationForm.controls.customerAptDateTime.setValue(startDate);
			this.allocationForm.controls.customerAptToDateTime.setValue(endDate);
			this.allocationForm.controls.customerAptRef.setValue(appointmentDetails.deliveryNumber);

			this.allocationForm.controls.customerAptRef.disable();
			this.allocationForm.controls.appointmentComment.disable();
			this.allocationForm.controls.customerAptDateTime.disable();
			this.allocationForm.controls.customerAptToDateTime.disable();
		} else {
			this.allocationForm.controls.customerAptRef.enable();
			this.allocationForm.controls.appointmentComment.enable();
			this.allocationForm.controls.customerAptDateTime.enable();
			this.allocationForm.controls.customerAptToDateTime.enable();
		}
	}



	private getBalanceForGrade(grade: string, lineAmt: number): number {
		let balance = 0;
		if (this.data.requests.length > 1) {
			// there is more than one request so there's more than one grade
			// we need to find the correct balance
			const lines = this.getContractLines(grade);
			if (isNullOrUndefined(lines[0])) {
				balance = 0;
			} else {
				balance = lines[0].balanceWeight;
			}
		} else {
			// there's only one request and therefore only one grade
			// we need to pick the selected contract line just in case there's more than one
			balance = this.selectedContractLine.balanceWeight;
		}
		return balance < lineAmt ? balance : lineAmt;
	}

	showBulkAllocateMode(event: any) {
		const ctrls = this.allocationForm.controls;
		if (event.checked) {
			this.bulkAllocate = true;
			this.bulkAllocationMode = 'date';
			this.pupulatePlaceholderText();
			ctrls.pickupDateTime.clearValidators();
			ctrls.pickupDateTime.updateValueAndValidity();
		}
		else {
			this.bulkAllocate = false;
			ctrls.pickupDateTime.setValidators([Validators.required]);
			ctrls.pickupDateTime.updateValueAndValidity();
			this.removeTimeValidation();
		}
	}

	private addBulkAllocationFormGroup(arr: any) {
		this.pupulatePlaceholderText();
		const groupArr: FormGroup[] = [];
		for (const a of arr) {
			const grp = this.fb.group({
				PickupDateTime: null,
				requiredLoads: null
			});
			groupArr.push(grp);
		}
		return groupArr;
	}

	selectAllocationMode(event: any) {
		this.bulkAllocationMode = event.value;

		if (this.bulkAllocationMode == 'time') {
			this.addTimeValidation();
		}
		else {
			this.removeTimeValidation();
		}
	}

	removeTimeValidation() {
		const ctrls = this.allocationForm.controls;

		ctrls.bulkAllocationPickupDate.clearValidators();
		ctrls.bulkAllocationPickupDate.updateValueAndValidity();
		ctrls.bulkAllocationRequiredLoads.clearValidators();
		ctrls.bulkAllocationRequiredLoads.updateValueAndValidity();
	}

	addTimeValidation() {
		const ctrls = this.allocationForm.controls;

		ctrls.bulkAllocationPickupDate.setValidators([Validators.required]);
		ctrls.bulkAllocationPickupDate.updateValueAndValidity();
		ctrls.bulkAllocationRequiredLoads.setValidators([Validators.required]);
		ctrls.bulkAllocationRequiredLoads.updateValueAndValidity();
	}

	pupulatePlaceholderText() {
		if (this.datePlaceholder.length > 0)
			return;
		const dt = new Date();
		let i = 0;
		while (i < 14) {
			var d = dt.setDate(dt.getDate() + (i > 0 ? 1 : 0));
			this.datePlaceholder.push(moment(d).format('DD-MMM'));
			i++;
		}

		i = 0;
		let date = new Date();
		const day = date.getDay();
		const diff = date.getDate() - day + (day == 0 ? -6 : 1);

		if (new Date(date.setDate(diff)) < new Date())
			date = new Date(date.setDate(diff + 7));
		else
			date = new Date(date.setDate(diff));

		while (i < 6) {
			var d = date.setDate(date.getDate() + (i > 0 ? 7 : 0));
			if (i == 0)
				this.firstMonday = new Date(d).toLocaleString();
			this.weekPlaceholder.push(moment(d).format('DD-MMM'));
			i++;
		}
	}
	populateControls(event: any) {
		this.timePlaceholder = new Array<string>(+event.target.value);
		const arr = this.allocationForm.get('loadsByTime') as FormArray;
		if (this.timePlaceholder.length < arr.length) {
			while (this.timePlaceholder.length < arr.length) {
				arr.removeAt(arr.length - 1);
			}
		}
		const groupArr: FormGroup[] = [];
		while (this.timePlaceholder.length != arr.length) {
			const grp = this.fb.group({
				pickupTime: null
			});
			arr.push(grp);
		}
	}

	calculateLoadsCount() {
		if (this.isMixedGradeContract) {
			return 1;
		} else {
			const result = (this.allocationForm.controls.requests as any).controls.map(control => control.value.requiredLoads)
				.reduce((sum, current) => sum + current, 0);
			return result;
		}
	}
}

interface AllocatedGrade {
	requestId: number;
	requestGrade: string;
	contractLineGrade: string;
}

interface AllocateRequestVmExtended extends AllocateRequestVm {
	takenQty: number;
}
