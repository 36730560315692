import {
	Component,
	Input,
	Output,
	EventEmitter,
	TemplateRef,
} from '@angular/core';
import { CalendarEvent } from 'angular-calendar';
import {
	WeekViewAllDayEvent,
	WeekViewTimeEvent,
	WeekViewHourColumn,
} from 'calendar-utils';
import { PlacementArray } from 'positioning';
import { CalendarCommonService } from 'src/app/event-calendar/event-calendar-common-service';

@Component({
	selector: 'app-week-view-event',
	templateUrl: './app-week-view-event.component.html',
	styleUrls: ['./app-week-view-event.component.scss']
})
export class AppWeekViewEventComponent {
  @Input() locale: string;

  @Input() weekEvent: WeekViewAllDayEvent | WeekViewTimeEvent;

  @Input() tooltipPlacement: PlacementArray;

  @Input() tooltipAppendToBody: boolean;

  @Input() tooltipDisabled: boolean;

  @Input() tooltipDelay: number | null;

  @Input() customTemplate: TemplateRef<any>;

  @Input() eventTitleTemplate: TemplateRef<any>;

  @Input() eventActionsTemplate: TemplateRef<any>;

  @Input() tooltipTemplate: TemplateRef<any>;

  @Input() column: WeekViewHourColumn;

  @Input() daysInWeek: number;

  @Input() IsDayView: boolean;

  @Output() eventClicked = new EventEmitter<{
    event: CalendarEvent,
    sourceEvent: MouseEvent | any;
  }>();

  constructor(private eventCalendarService: CalendarCommonService) { }

  weekDayEventClick(timeEvent: WeekViewTimeEvent) {
  	this.eventCalendarService.emitNavChangeEvent(timeEvent.event.title);
  }
}
