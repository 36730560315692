import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { QueryModel } from '../gen/models';
import { RouteToMarketViewModel } from '../gen/models/RouteToMarketViewModel';
import { RouteToMarketService } from '../gen/services/route-to-market.service';
import { SpinnerComponent } from '../spinner/spinner.component';
import { SortInfo } from '../table-sort/table-sort.directive';
import { isNullOrUndefined } from '../tools';

@Component({
    selector: 'app-route-to-market-dialog-update',
    templateUrl: './route-to-market-dialog-update.component.html',
    styleUrls: ['./route-to-market-dialog-update.component.scss'],
    providers: [
        {provide: DateAdapter, useClass: MomentDateAdapter},
        {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}}
    ]})
export class RouteToMarketDialogUpdateComponent implements OnInit, OnDestroy {

    isSubmit = false;
    querySubscription: Subscription;
    routeToMarketFilterForm: FormGroup;
    routesToMarkets: RouteToMarketViewModelExtended[] = [];
    dataSource = new MatTableDataSource(this.routesToMarkets);
    spinnerVisible = false;
    updateSubscription: Subscription;

    displayedColumns: string[] = ['grade', 'route', 'effectiveDate', 'action'];

    @ViewChild('spinner', { static: true }) spinner: SpinnerComponent;

    get routeToMarketUpdated() {
        return this.routesToMarkets.some(r => r.isUpdated);
    }
    
    get routeToMarketInvalid() {
        return this.routesToMarkets.some(r => r.isInvalid);
    }
    
    constructor(
        private adapter: DateAdapter<any>,
        @Inject(MAT_DIALOG_DATA) public data: { depotNo: string, grade: string, selected: RouteToMarketViewModel[] },
        public dialogRef: MatDialogRef<RouteToMarketDialogUpdateComponent>,
        private fb: FormBuilder,
        private routeToMarketService: RouteToMarketService,
        private toastr: ToastrService
    ) {
        this.adapter.setLocale(moment.locale());
    }
    
    cancel() {
        this.dialogRef.close();
    }
    
    compareValues(key: string, order = 'asc') {
        return function innerSort(a, b) {
            const varA = (typeof a[key] === 'string')
                ? a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string')
                ? b[key].toUpperCase() : b[key];
    
            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === 'desc') ? (comparison * -1) : comparison
            );
        };
    }
    
    deleteRouteToMarket(routeToMarket: RouteToMarketViewModelExtended) {
        routeToMarket.active = false;
        routeToMarket.isUpdated = true;
        this.dataSource = new MatTableDataSource(this.routesToMarkets.filter(r => r.active));
    }
    
    onRouteToMarketChange(event: any, routeToMarket: RouteToMarketViewModelExtended) {
        if (event instanceof MatDatepickerInputEvent) {
            if (!routeToMarket.effectiveDate && (event.targetElement as HTMLInputElement).value) {
                routeToMarket.isInvalid = true;
            } else {
                routeToMarket.isInvalid = false;
            }
        }
    
        routeToMarket.isUpdated = true;
    }
    
    ngOnDestroy(): void {
        this.spinnerVisible = false;
        this.spinner.overlayRef.dispose();
        if (this.querySubscription != null && !this.querySubscription.closed) {
            this.querySubscription.unsubscribe();
        }
        if (this.updateSubscription != null && !this.updateSubscription.closed) {
            this.updateSubscription.unsubscribe();
        }
    }
    
    ngOnInit(): void {
        this.routeToMarketFilterForm = this.fb.group({
            depot: this.data.depotNo,
            grade: this.data.grade
        });
    
        if (this.data.selected) {
            this.routesToMarkets = JSON.parse(JSON.stringify(this.data.selected));
            this.dataSource = new MatTableDataSource(this.routesToMarkets.filter(r => r.active));
        }
    }
    
    queryServer(formGroup: FormGroup) {
        if (formGroup.valid) {
            this.spinnerVisible = true;
    
            const tableState: QueryModel = {
                pagination: {
                    number: 0,
                    start: 0
                },
                search: {
                    predicateObject: {
                        grade: formGroup.value.grade,
                        gradeDescription: null,
                        gradeGroupName: null,
                        salesGrade: null,
                        heap: null,
                        heapDescription: null,
                        requestedGrade: null,
                        requestedGradeDescription: null,
                        depot: formGroup.value.depot,
                        depotName: null,
                        territory: null,
                        contractRef: null,
                        classification: null,
                        classifications: [],
                        partyAccountNo: null,
                        partyName: null,
                        partySearchText: null,
                        haulierAccountNo: null,
                        haulierName: null,
                        haulierSearchText: null,
                        ticketNumber: null,
                        allocationNumber: null,
                        containerPackaging: null,
                        containerTypeName: null,
                        requestStatuses: [],
                        allocationStatuses: [],
                        contractHeaderStatuses: [],
                        presetFilterIds: [],
                        lifecycleStatusId: 0,
                        comment: null,
                        comments: null,
                        status: null,
                        description: null,
                        review: null,
                        firstComeFirstServe: false,
                        awaitingDispatch: null,
                        dateRange: null,
                        pickupFromDateRange: null,
                        includeDeleted: false,
                        route: null,
                        kpiTypes: [],
                        destination: null
                    }
                },
                sort: {
                    predicate: null,
                    reverse: false
                }
            };
    
            if (this.querySubscription != null && !this.querySubscription.closed) {
                this.querySubscription.unsubscribe();
            }
    
            this.querySubscription = this.routeToMarketService.getRoutes(tableState).subscribe(r => {
                if (isNullOrUndefined(r)) {
                    this.spinnerVisible = false;
                    return;
                }
    
                this.routesToMarkets = r.routes.map(a => {
                    const ext = a as RouteToMarketViewModelExtended;
                    return ext;
                });
    
                this.dataSource = new MatTableDataSource(this.routesToMarkets.filter(r => r.active));
    
                this.spinnerVisible = false;
            },
            () => {
                this.spinnerVisible = false;
            });
        }
    }
    
    sortByInfo(info: SortInfo) {
        if (info.active) {
            this.routesToMarkets = this.routesToMarkets.sort(this.compareValues(info.active, info.direction));
            this.dataSource = new MatTableDataSource(this.routesToMarkets.filter(r => r.active));
        }
    }
    
    update() {
        const updatedRoutesToMarket = this.routesToMarkets.filter(r => r.isUpdated);
       
        if (updatedRoutesToMarket.length > 0) {
            if (this.updateSubscription != null && !this.updateSubscription.closed) {
                this.updateSubscription.unsubscribe();
            }
       
            this.spinnerVisible = true;
       
            this.updateSubscription = this.routeToMarketService.updateRoutes(updatedRoutesToMarket).subscribe(r => {
                this.spinnerVisible = false;
                this.dialogRef.close(true);
            },
            () => {
                this.spinnerVisible = false;
                this.toastr.error('Request to update failed. Please try again', 'Route To Market');
            });
       
        } else {
            this.dialogRef.close(true);
        }
    }
}

interface RouteToMarketViewModelExtended extends RouteToMarketViewModel {
    isUpdated: boolean;
    isInvalid: boolean;
}
