import { AllocationService } from '../gen/services/allocation.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
    PendingAndAllocatedViewModel,
    AllocatedViewModel,
    QueryModel,
    QueryDateRange
} from '../gen/models';
import {
    Component,
    ChangeDetectionStrategy,
    ViewChild,
    OnInit,
    Input,
    Output,
    TemplateRef,
    OnChanges,
    SimpleChanges,
    EventEmitter,
    OnDestroy,
} from '@angular/core';
import {
    startOfDay,
    endOfDay,
    subDays,
    addDays,
    endOfMonth,
    isSameDay,
    isSameMonth,
    addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
    CalendarEvent,
    CalendarEventAction,
    CalendarEventTimesChangedEvent,
    CalendarView,
} from 'angular-calendar';

import { CalendarCommonService } from './event-calendar-common-service';
import { ToastrService } from 'ngx-toastr';
import { EventCalendarMoreDialogComponent } from '../event-calendar-more/event-calendar-more-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { finalize } from 'rxjs/operators';

const colors: any = {
    amber: {
        primary: '#ff9b91b5',
        secondary: '#b93124',
    },
    green: {
        primary: '#aaecdc',
        secondary: '#028e6a',
    }
};

@Component({
    selector: 'event-calendar-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./event-calendar.scss'],
    templateUrl: 'event-calendar.component.html',
    providers: [DatePipe]
})
export class EventCalendarComponent implements OnInit, OnChanges, OnDestroy {

  @Input() allocations: BehaviorSubject<AllocatedViewModelExtended[]>;
  @Input() tableState: QueryModel;
  @Output() selectedallocation = new EventEmitter<any>();
  @Output() selectedallocationForDelete = new EventEmitter<any>();
  @ViewChild('modalContent') modalContent: TemplateRef<any>;
  @Output() spinnerEmitter = new EventEmitter<boolean>();

  reqquerySubscription: Subscription;
  allocationsVm: AllocatedViewModelExtended[];
  events: CalendarEvent[];
  viewDate: Date = new Date();
  querySubscription: Subscription;
  displayedColumns: string[] = ['allocationNumber', 'comment', 'requestDate', 'partyName', 'allocatedDate'];
  refresh: Subject<any> = new Subject();
  moreEvents: PendingAndAllocatedViewModel[];
  moreEventStyles: MoreStyles;
  currentDate: Date;
  pendingAndAllocated: PendingAndAllocatedViewModel[] = [];
  reqtableState: QueryModel;
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  userPreferencesData: any;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  editEventClicked = false;

  constructor(private modal: NgbModal,
    private eventCalendarService: CalendarCommonService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private allocationService: AllocationService,
    private route: ActivatedRoute,
    private datepipe: DatePipe) {
      this.allocations = new BehaviorSubject<AllocatedViewModelExtended[]>(null);
  }
  ngOnChanges(changes: any): void {
      if(changes.tableState)
          this.callServer(null);
  }

  ngOnInit() {
      const userPreferences = this.route.snapshot.data['userpreferencesData'];
      if (userPreferences !== undefined && userPreferences.length > 0)
          this.userPreferencesData = userPreferences[0];


      this.allocations.subscribe(s => {
          this.allocationsVm = s;
          this.bindEvents();
          this.refresh.next(this.events);
      });

      const deptNo = this.userPreferencesData && this.userPreferencesData.depot ? this.userPreferencesData.depot : null;
      this.moreEvents = [];
  }

  actions: CalendarEventAction[] = [
      {
          label: '<i class="fas fa-fw fa-pencil-alt"></i>',
          onClick: ({ event }: { event: CalendarEvent }): void => {

              this.handleeditEvent('Edited', event);
          },
      },
      {
          label: '<i class="fas fa-fw fa-trash-alt"></i>',
          onClick: ({ event }: { event: CalendarEvent }): void => {
              this.events = this.events.filter((iEvent) => iEvent !== event);
              this.handledeleteEvent('Deleted', event);
          },
      },
  ];

  activeDayIsOpen = true;

  ngOnDestroy(): void {
      if (this.reqquerySubscription != null && !this.reqquerySubscription.closed) {
          this.reqquerySubscription.unsubscribe();
      }
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
      if (isSameMonth(date, this.viewDate)) {
          if (
              (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
          ) {
              this.activeDayIsOpen = false;
          } else {
              this.activeDayIsOpen = true;
          }
          this.viewDate = date;
      }
  }

  eventTimesChanged({
      event,
      newStart,
      newEnd,
  }: CalendarEventTimesChangedEvent): void {
      this.events = this.events.map((iEvent) => {
          if (iEvent === event) {
              return {
                  ...event,
                  start: newStart,
                  end: newEnd,
              };
          }
          return iEvent;
      });
      this.handleeditEvent('Dropped or resized', event);
  }

  handleeditEvent(action: string, event: CalendarEvent): void {
      if (this.editEventClicked) {
          return;
      }
      this.editEventClicked = true;
      setTimeout(() => {
          this.editEventClicked = false;
      }, 1000);
      const canSelect = event.meta.active;
      if (!canSelect) {
          this.toastr.warning('Allocation can\'t be selected because it is deleted.', 'Warning');
          return;
      }
      this.selectedNavItem(event.title);
  }
  selectedNavItem(item: string) {
      this.eventCalendarService.emitNavChangeEvent(item);
  }
  handledeleteEvent(action: string, event: CalendarEvent): void {
      this.modalData = { event, action };

      const allocation = this.allocationsVm.filter(function (allocation) {
          return allocation.allocationNumber.toString() == event.title;
      });
      this.selectedallocationForDelete.emit(allocation[0]);
  }

  addEvent(): void {
      this.events.push(
          {
              title: 'New event',
              start: startOfDay(new Date()),
              end: endOfDay(new Date()),
              color: colors.red,
              draggable: false,
              resizable: {
                  beforeStart: true,
                  afterEnd: true,
              },
          },
      );
  }

  deleteEvent(eventToDelete: CalendarEvent) {
      this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView, viewDate) {
      this.view = view;
      this.CallServerWithFilter(view, viewDate);
  }

  closeOpenMonthViewDay(view, viewDate) {
      this.activeDayIsOpen = false;
      this.CallServerWithFilter(view, viewDate);
  }

  private CallServerWithFilter(view: any, viewDate: any) {
      const deptNo = this.userPreferencesData && this.userPreferencesData.depot ? this.userPreferencesData.depot : null;
      const dayFilter = {} as QueryDateRange;
      switch (view) {
      case 'day':
          dayFilter.dateFrom = moment(viewDate).format('YYYY-MM-DDTHH:mm:ss');
          dayFilter.dateTo = moment(viewDate).format('YYYY-MM-DDTHH:mm:ss');
          break;
      case 'week':
          dayFilter.dateFrom = moment(viewDate).add(-7, 'day').format('YYYY-MM-DDTHH:mm:ss');
          dayFilter.dateTo = moment(viewDate).add(7, 'day').format('YYYY-MM-DDTHH:mm:ss');
          break;
      case 'month':
          dayFilter.dateFrom = moment(viewDate).add(-30, 'day').format('YYYY-MM-DDTHH:mm:ss');
          dayFilter.dateTo = moment(viewDate).add(30, 'day').format('YYYY-MM-DDTHH:mm:ss');
          break;
      }

      this.callServer({
          pagination: { number: 30, start: 0 },
          search: {
              predicateObject: {
                  grade: null,
                  gradeDescription: null,
                  gradeGroupName: null,
                  salesGrade: null,
                  heap: null,
                  heapDescription: null,
                  requestedGrade: null,
                  requestedGradeDescription: null,

                  depot: deptNo,
                  depotName: null,
                  territory: null,

                  contractRef: null,
                  classification: null,
                  classifications: [],

                  partyAccountNo: null,
                  partyName: null,
                  partySearchText: null,
                  haulierAccountNo: null,
                  haulierName: null,
                  haulierSearchText: null,

                  ticketNumber: null,

                  allocationNumber: null,
                  containerPackaging: null,
                  containerTypeName: null,
                  requestStatuses: ['Open'],
                  allocationStatuses: [],
                  presetFilterIds: [],
                  lifecycleStatusId: 0,
                  comment: null,
                  comments: null,
                  status: null,
                  description: null,
                  review: null,
                  firstComeFirstServe: false,
                  awaitingDispatch: null,

                  dateRange: dayFilter,
                  pickupFromDateRange: dayFilter,
                  includeDeleted: false,
                  route: null,
                  kpiTypes: [],
                  contractHeaderStatuses: [],
                  destination: null
              }
          },
          sort: { predicate: null, reverse: true }
      });
  }

  private clearEvent(): void {
      this.events = [];
  }

  private addItemInEvent(startDate: Date, endDate: Date, title: string, color: any, meta: any) {
      this.events.push({
          start: startDate,
          end: endDate,
          title: title,
          color: color,
          allDay: false,
          actions: this.actions,
          resizable: {
              beforeStart: true,
              afterEnd: true,
          },
          draggable: false,
          meta: meta
      } as CalendarEvent);
  }

  private addAllocationInEvent(allocations: AllocatedViewModel[]) {
      allocations.forEach(allocation => {
          if(allocation.active){
              this.addItemInEvent(
                  new Date(allocation.requestedMovementDate),
                  !allocation.requestedMovementDateEnd ? moment(allocation.requestedMovementDate).add(30, 'minutes').toDate() : moment(new Date(allocation.requestedMovementDateEnd)).add(30, 'minutes').toDate(),
                  !allocation.requestId ? allocation.allocationNumber.toString() : allocation.requestId.toString(),
                  colors.green,
                  allocation);
          }
      });
  }

  private addRequestInEvent(request: PendingAndAllocatedViewModel) {
      this.addItemInEvent(new Date(request.readyDate),
          new Date(request.readyDate),
          request.requestId.toString(),
          colors.amber,
          request);
  }

  bindEvents() {
      if ((this.pendingAndAllocated && this.pendingAndAllocated.length >= 1) || (this.allocationsVm && this.allocationsVm.length >= 1)) {
          this.events = [];
          this.pendingAndAllocated
              .filter(req => req.allocations.length > 0 && req.allocations[0].allocationNumber && req.allocations[0].allocationNumber === -1)
              .forEach(x => this.addRequestInEvent(x));

          this.pendingAndAllocated
              .filter(req => req.allocations.length > 0 && req.allocations[0].allocationNumber && req.allocations[0].allocationNumber !== -1)
              .forEach(x => this.addAllocationInEvent(x.allocations));

          this.allocationsVm.filter(a => !a.requestId && !this.pendingAndAllocated.find(req => a.requestId && req.allocations.includes(a))).forEach(x => this.addAllocationInEvent([x]));
      }
  }
  private filterItems(items, searchVal) {
      return items.filter((item) => Object.values(item).includes(searchVal));
  }
  handleMoreEvent(e: any, events: CalendarEvent[], currentDate: Date) {
      this.moreEvents = [];
      const data = events.map(m => m.meta).map(p => p.requestId).filter((v, i, a) => a.indexOf(v) === i);
      this.moreEvents = this.pendingAndAllocated.filter(pa => data.includes(pa.requestId));

      this.currentDate = currentDate;
      const allocationWithoutRequest = events.filter(e => !e.meta.requestId).map(e => e.meta);
      if(allocationWithoutRequest){
          const dummyRequest: PendingAndAllocatedViewModel = {
              allocations: allocationWithoutRequest
          } as PendingAndAllocatedViewModel;

          this.moreEvents.push(dummyRequest);
      }
      const dialogData= this.moreEvents.map(me =>  {me.allocations = me.allocations.filter(a => events.find(e => e.meta.allocationNumber == a.allocationNumber)); return me;});
      const dialogRef = this.dialog.open(EventCalendarMoreDialogComponent, {
          maxHeight: '80%',
          maxWidth: '80%',
          data: { allocate:  dialogData}
      });
  }
  handleReqMoreEvent(e: any, event: CalendarEvent, currentDate: Date) {
      this.moreEvents = [];
      this.moreEvents.push(this.filterItems(this.pendingAndAllocated, event.meta.requestId)[0]);


      this.currentDate = currentDate;
      const dialogRef = this.dialog.open(EventCalendarMoreDialogComponent, {
          maxHeight: '80%',
          maxWidth: '80%',
          data: { allocate: this.moreEvents }
      });
  }
  callServer(tableState: QueryModel) {
      this.spinnerEmitter.emit(true);
      if (this.tableState) {
          this.reqtableState = this.tableState;
      }
      this.allocationService.getRequests(this.reqtableState)
          .pipe(finalize(() => this.spinnerEmitter.emit(false)))
          .subscribe(r => {
              this.pendingAndAllocated = r.requestsAndAllocated;
              this.clearEvent();
              this.bindEvents();
          }, err => {

          });
  }
  mwlEventClicked(event: CalendarEvent, date){
      if(event?.meta?.allocationNumber)
          this.handleeditEvent('Clicked', event);
      else
          this.handleReqMoreEvent('Clicked', event, date);
  }
}
interface AllocatedViewModelExtended extends AllocatedViewModel {
  isSelected: boolean;
}
interface MoreStyles {
  top: string;
  left: string;
}
