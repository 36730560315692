<mat-form-field>
  <input type="text" (input)="inputValue($event)" #input (keyup.enter)="enter(input.value)"
  matInput [placeholder]="placeholder" [matAutocomplete]="auto" [formControl]="autoComplete"
  testName="auto" [errorStateMatcher]="errorStateMatcher">
  <!-- <fa-icon class="spinner" matSuffix *ngIf="loading" icon="circle-notch" [spin]="true"></fa-icon> -->
  <mat-spinner [diameter]="20" [strokeWidth]="2" matSuffix *ngIf="loading" [color]="spinColor"></mat-spinner>
  <button mat-button *ngIf="filterVal && !loading" type="button"
    matSuffix mat-icon-button aria-label="Clear"
    (click)="clearRequestInput(input)"
    testName="autoClear">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
<mat-autocomplete #auto panelWidth="300px" [displayWith]="displayWith">
  <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
<mat-option *ngFor="let r of results" [value]="r"
[matTooltip]="tooltip(r)"
(onSelectionChange)="choose(r)">
  {{displayWith(r)}}
</mat-option>
</mat-autocomplete >
