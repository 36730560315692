import { OnInit, Component, ViewChild } from '@angular/core';
import { DepotVm, TerritoryVm } from '../gen/models';
import { UserPreferencesService } from '../gen/services/user-preferences.service';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { ListOfColumnNames } from '../gen/enums/enums';
import { UserPreferencesVm } from '../gen/models/UserPreferencesVm';
import { ToastrService } from 'ngx-toastr';
import { SpinnerComponent } from '../spinner/spinner.component';
import { AllocateService } from '../gen/services/allocate.service';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { OrderBookTypeVm } from '../gen/models/OrderBookTypeVm';
import { isNullOrUndefined } from '../tools';
import { MatDialog } from '@angular/material/dialog';
import { TableView, UserTableViewSettingComponent } from '../user-table-view-setting/user-table-view-setting.component';
import { UserPreferenceTableEnum, UserTableViewVm } from '../gen/models/UserTableViewVm';
import { values } from 'underscore';

@Component({
    selector: 'app-userPreferences',
    templateUrl: './user-preferences.component.html',
    styleUrls: ['./user-preferences.component.scss']
})

export class UserPreferencesComponent implements OnInit {
    defaultDepot: DepotVm;
    defaultTerritory: TerritoryVm;

    userpreferencesForm: FormGroup;
    userPreferencesData: UserPreferencesVm;
    @ViewChild('spinner', { static: true }) requestSpinner: SpinnerComponent;
    isLoading = true;

    showAllRequestsFields = false;
    showAllAllocationsFields = false;

    requestsCols: string[] = [];
    allocationsCols: string[] = [];
    fieldsListForRequests: { name: string, colWidth: number, type: string }[];
    fieldsListForAllocations: { name: string, colWidth: number, type: string }[];
    defaultSelectedColsForRequests: string[];
    defaultSelectedColsForAllocations: string[];

    territoryControl = new FormControl();
    territorys: TerritoryVm[] = [];
    selectedTerritorys: TerritoryVm[] = new Array<TerritoryVm>();
    filteredTerritorys: Observable<TerritoryVm[]>;
    lastTerritoryFilter = '';

    depotControl = new FormControl();
    depots: DepotVm[] = [];
    selectedDepots: DepotVm[] = new Array<DepotVm>();
    filteredDepots: Observable<DepotVm[]>;
    lastDepotFilter = '';

    orderBookTypeControl = new FormControl();
    orderBookTypes: OrderBookTypeVm[] = [];
    selectedOrderBookTypes: OrderBookTypeVm[] = new Array<OrderBookTypeVm>();
    filteredOrderBookTypes: Observable<OrderBookTypeVm[]>;
    lastOrderBookTypeFilter = '';

    requestTableViews: TableView[];
    allocationTableViews: TableView[];
    allocationTable: UserPreferenceTableEnum.Allocation;
    requestTable: UserPreferenceTableEnum.Request;
    myEnum: typeof UserPreferenceTableEnum;
    constructor(private userPreferencesService: UserPreferencesService,
        private fb: FormBuilder,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        public allocateService: AllocateService,
        private dialog: MatDialog,) {

        const territoryList = this.route.snapshot.data['territoryList'];
        if (territoryList !== undefined && territoryList.items !== undefined && territoryList.items.length > 0)
            this.territorys = territoryList.items;
        else
            this.territorys = [];

        const depotList = this.route.snapshot.data['depotList'];
        if (depotList !== undefined && depotList.length > 0)
            this.depots = depotList;
        else
            this.depots = [];

        const orderBookTypeList = this.route.snapshot.data['orderBookTypeList'];
        if (orderBookTypeList !== undefined && orderBookTypeList.length > 0)
            this.orderBookTypes = orderBookTypeList;
        else
            this.orderBookTypes = [];

        const userPreferences = this.route.snapshot.data['userpreferencesData'];
        if (userPreferences !== undefined && userPreferences.length > 0)
            this.userPreferencesData = userPreferences[0];
        else
            this.userPreferencesData = { depot: '', territory: '', requestFields: '', allocationFields: '', orderBookType: '', viewMode: '', allocationDate: '', pickUpFromDate: '' };
    }

    async ngOnInit() {
        this.allocationTable = UserPreferenceTableEnum.Allocation;
        this.requestTable = UserPreferenceTableEnum.Request;
        this.userpreferencesForm = this.fb.group({
            requestsColFields: ['', Validators.required],
            allocationsColFields: ['', Validators.required],
            requestView: ['', Validators.required],
            allocationView: ['', Validators.required],
            allocationDate: ['', Validators.required],
            pickUpFromDate: ['', Validators.required],
        });

        this.filteredTerritorys = this.territoryControl.valueChanges.pipe(
            startWith<string | TerritoryVm[]>(''),
            map(value => typeof value === 'string' ? value : this.lastTerritoryFilter),
            map(filter => this.territoryFilter(filter))
        );

        this.filteredDepots = this.depotControl.valueChanges.pipe(
            startWith<string | DepotVm[]>(''),
            map(value => typeof value === 'string' ? value : this.lastDepotFilter),
            map(filter => this.depotFilter(filter))
        );

        this.filteredOrderBookTypes = this.orderBookTypeControl.valueChanges.pipe(
            startWith<string | OrderBookTypeVm[]>(''),
            map(value => typeof value === 'string' ? value : this.lastOrderBookTypeFilter),
            map(filter => this.orderBookTypeFilter(filter))
        );

        this.assignRequestField();
        this.assignAllocationField();
        this.assignOrderBookType();
        this.assignTerritory();
        this.assignDepot();
        this.getExistingViews(UserPreferenceTableEnum.Allocation, this.allocationTableViews);
        this.getExistingViews(UserPreferenceTableEnum.Request, this.requestTableViews);

        this.userpreferencesForm
            .get('requestView')
            .valueChanges
            .subscribe(value => this.UpdateSelectedView(value));

        this.userpreferencesForm
            .get('allocationView')
            .valueChanges
            .subscribe(value => this.UpdateSelectedView(value));
        this.userpreferencesForm.patchValue({
            allocationDate: this.userPreferencesData.allocationDate,
            pickUpFromDate: this.userPreferencesData.pickUpFromDate
        });
        this.isLoading = false;
    }
    UpdateSelectedView(value: any): void {
        this.userPreferencesService.selectTableView(value.id).subscribe();
    }

    async assignRequestField() {
        this.fieldsListForRequests = ListOfColumnNames.fieldsListForRequests.filter(f => f.type === 'request');
        this.defaultSelectedColsForRequests = ListOfColumnNames.defaultSelectedColsForRequests;
        if (this.userPreferencesData.requestFields !== '')
            this.requestsCols = JSON.parse(this.userPreferencesData.requestFields);

        if (this.requestsCols.length === 0) {
            this.requestsCols = this.defaultSelectedColsForRequests;
        }
    }

    async assignAllocationField() {
        this.fieldsListForAllocations = ListOfColumnNames.fieldsListForAllocations;
        this.defaultSelectedColsForAllocations = ListOfColumnNames.defaultSelectedColsForAllocations;
        if (this.userPreferencesData.allocationFields !== '')
            this.allocationsCols = JSON.parse(this.userPreferencesData.allocationFields);

        if (this.allocationsCols.length === 0) {
            this.allocationsCols = this.defaultSelectedColsForAllocations;
        }
    }

    async assignOrderBookType() {
        if (!isNullOrUndefined(this.userPreferencesData.orderBookType)) {
            this.userPreferencesData.orderBookType.split(', ').forEach(orderBook => {
                if (orderBook) {
                    const obt = this.orderBookTypes.filter(option => { return option.orderBook.toLowerCase().indexOf(orderBook.toLowerCase()) >= 0; })[0];
                    this.orderBookTypeToggleSelection(obt);
                }
            });
        }
    }

    async assignTerritory() {
        if (!isNullOrUndefined(this.userPreferencesData.territory)) {
            this.userPreferencesData.territory.split(', ').forEach(territoryCode => {
                if (territoryCode) {
                    const territory = this.territorys.filter(option => { return option.territoryCode.toLowerCase().indexOf(territoryCode.toLowerCase()) >= 0; })[0];
                    if (territory !== undefined) {
                        this.territoryToggleSelection(territory);
                    }
                }
            });
        }
    }

    async assignDepot() {
        if (!isNullOrUndefined(this.userPreferencesData.depot)) {
            this.userPreferencesData.depot.split(', ').forEach(depotNumber => {
                if (depotNumber) {
                    const dep = this.depots.filter(option => { return option.depotNumber.toLowerCase().indexOf(depotNumber.toLowerCase()) >= 0; })[0];
                    this.depotToggleSelection(dep);
                }
            });
        }
    }

    orderBookTypeFilter(orderBookTypeFilter: string): OrderBookTypeVm[] {
        this.lastOrderBookTypeFilter = orderBookTypeFilter;
        if (orderBookTypeFilter) {
            return this.orderBookTypes.filter(option => {
                return option.orderBook.toLowerCase().indexOf(orderBookTypeFilter.toLowerCase()) >= 0;
            });
        } else {
            return this.orderBookTypes;
        }
    }

    depotFilter(depotFilter: string): DepotVm[] {
        this.lastDepotFilter = depotFilter;
        if (depotFilter) {
            return this.depots.filter(option => {
                return option.depotNumber.toLowerCase().indexOf(depotFilter.toLowerCase()) >= 0
                    || option.depotName.toLowerCase().indexOf(depotFilter.toLowerCase()) >= 0;
            });
        } else {
            return this.depots;
        }
    }

    territoryFilter(territoryFilter: string): TerritoryVm[] {
        this.lastTerritoryFilter = territoryFilter;
        if (territoryFilter) {
            return this.territorys.filter(option => {
                return option.territoryCode.toLowerCase().indexOf(territoryFilter.toLowerCase()) >= 0
                    || option.territoryName.toLowerCase().indexOf(territoryFilter.toLowerCase()) >= 0;
            });
        } else {
            return this.territorys;
        }
    }

    displayOrderBookType(orderBookType: OrderBookTypeVm[] | string): string | undefined {
        let displayValue: string;
        if (Array.isArray(orderBookType)) {
            orderBookType.forEach((orderBookType, index) => {
                if (index === 0) {
                    displayValue = orderBookType.orderBook;
                } else {
                    displayValue += ', ' + orderBookType.orderBook;
                }
            });
        } else {
            displayValue = orderBookType;
        }
        return null;
    }

    displayDepot(depot: DepotVm[] | string): string | undefined {
        let displayValue: string;
        if (Array.isArray(depot)) {
            depot.forEach((depot, index) => {
                if (index === 0) {
                    displayValue = depot.depotNumber;
                } else {
                    displayValue += ', ' + depot.depotNumber;
                }
            });
        } else {
            displayValue = depot;
        }
        return null;
    }

    displayTerritory(territory: TerritoryVm[] | string): string | undefined {
        let displayValue: string;
        if (Array.isArray(territory)) {
            territory.forEach((tt, index) => {
                if (index === 0) {
                    displayValue = tt.territoryCode + ' ' + tt.territoryName;
                } else {
                    displayValue += ', ' + tt.territoryCode + ' ' + tt.territoryName;
                }
            });
        } else {
            displayValue = territory;
        }
        return null;
    }

    orderBookTypeOptionClicked(event: Event, orderBookType: OrderBookTypeVm) {
        event.stopPropagation();
        this.orderBookTypeToggleSelection(orderBookType);
    }

    depotOptionClicked(event: Event, depot: DepotVm) {
        event.stopPropagation();
        this.depotToggleSelection(depot);
    }

    territoryOptionClicked(event: Event, territory: TerritoryVm) {
        event.stopPropagation();
        this.territoryToggleSelection(territory);
    }

    orderBookTypeToggleSelection(orderBookType: OrderBookTypeVm) {
        orderBookType.selected = !orderBookType.selected;
        if (orderBookType.selected) {
            this.selectedOrderBookTypes.push(orderBookType);
        } else {
            const i = this.selectedOrderBookTypes.findIndex(value => value.orderBook === orderBookType.orderBook);
            this.selectedOrderBookTypes.splice(i, 1);
        }

        this.orderBookTypeControl.setValue(this.selectedOrderBookTypes);
    }

    depotToggleSelection(depot: DepotVm) {
        depot.selected = !depot.selected;
        if (depot.selected) {
            this.selectedDepots.push(depot);
        } else {
            const i = this.selectedDepots.findIndex(value => value.depotNumber === depot.depotNumber);
            this.selectedDepots.splice(i, 1);
        }

        this.depotControl.setValue(this.selectedDepots);
    }

    territoryToggleSelection(territory: TerritoryVm) {
        territory.selected = !territory.selected;
        if (territory.selected) {
            this.selectedTerritorys.push(territory);
        } else {
            const i = this.selectedTerritorys.findIndex(value => value.territoryCode === territory.territoryCode);
            this.selectedTerritorys.splice(i, 1);
        }

        this.territoryControl.setValue(this.selectedTerritorys);
    }

    async UpdateRequestsColumns(value: any) {
        const val = value;
        if (this.showAllRequestsFields && val.includes('Show All')) {
            this.requestsCols = this.fieldsListForRequests.map(f => f.name);
        } else if (this.showAllRequestsFields && !val.includes('Show All')) {
            this.requestsCols = this.defaultSelectedColsForRequests;
        } else if (val.length > 0) {
            this.requestsCols = val;
        } else {
            this.requestsCols = this.defaultSelectedColsForRequests;
        }
    }

    async UpdateAllocationsColumns(value: any) {
        const val = value;
        if (this.showAllAllocationsFields && val.includes('Show All')) {
            this.allocationsCols = this.fieldsListForAllocations.map(f => f.name);
        } else if (this.showAllAllocationsFields && !val.includes('Show All')) {
            this.allocationsCols = this.defaultSelectedColsForAllocations;
        } else if (val.length > 0) {
            this.allocationsCols = val;
        } else {
            this.allocationsCols = this.defaultSelectedColsForAllocations;
        }
    }

    async showAllRequestsColumns(event: any) {
        if (event.source.value === 'Show All') {
            this.showAllRequestsFields = true;
        } else {
            this.showAllRequestsFields = false;
        }
    }

    async showAllAllocationsColumns(event: any) {
        if (event.source.value === 'Show All') {
            this.showAllAllocationsFields = true;
        } else {
            this.showAllAllocationsFields = false;
        }
    }

    async submitForm() {
        if (this.userpreferencesForm.valid) {
            this.isLoading = true;
            const formValue = this.userpreferencesForm.value;

            let defaultTerritory: string;
            let defaultDepot: string;
            let defaultOrderBookType: string;

            if (Array.isArray(this.selectedTerritorys)) {
                this.selectedTerritorys.forEach((tt, index) => {
                    if (index === 0) {
                        defaultTerritory = tt.territoryCode;
                    } else {
                        defaultTerritory += ', ' + tt.territoryCode;
                    }
                });
            } else {
                defaultTerritory = '';
            }

            if (Array.isArray(this.selectedDepots)) {
                this.selectedDepots.forEach((d, index) => {
                    if (index === 0) {
                        defaultDepot = d.depotNumber;
                    } else {
                        defaultDepot += ', ' + d.depotNumber;
                    }
                });
            } else {
                defaultDepot = '';
            }

            if (Array.isArray(this.selectedOrderBookTypes)) {
                this.selectedOrderBookTypes.forEach((d, index) => {
                    if (index === 0) {
                        defaultOrderBookType = d.orderBook;
                    } else {
                        defaultOrderBookType += ', ' + d.orderBook;
                    }
                });
            } else {
                defaultOrderBookType = '';
            }

            const model: UserPreferencesVm = {
                depot: defaultDepot,
                territory: defaultTerritory,
                requestFields: JSON.stringify(this.requestsCols),
                allocationFields: JSON.stringify(this.allocationsCols),
                orderBookType: defaultOrderBookType,
                viewMode: this.userPreferencesData.viewMode,
                allocationDate: formValue.allocationDate,
                pickUpFromDate: formValue.pickUpFromDate
            };

            this.userPreferencesService.updateUserPreferences(model).subscribe
            (
                response => {
                    this.isLoading = false;
                    this.toastr.success('User Preference successfully updated', 'User Preference');
                },
                (error) => {
                    this.isLoading = false;
                });
        }
    }

    setTableView(tableName: UserPreferenceTableEnum, columns: { name: string; colWidth: number; type: string; }[], selectedColumns: string[]) {

        const userTableViewData = [];

        columns.map(col => {
            userTableViewData.push({
                name: col['name'],
                isSelected: selectedColumns.includes(col.name)
            });
        });

        this.dialog.open(UserTableViewSettingComponent, {
            minWidth: '480px',
            maxHeight: '80%',
            data: {
                name: tableName,
                columns: userTableViewData
            }
        });
    }

    getExistingViews(tableName: UserPreferenceTableEnum, views: TableView[]) {
        this.userPreferencesService
            .getUserTableView(tableName)
            .subscribe(
                x => this.MapTableViews(tableName, x));
    }

    MapTableViews(tableName: UserPreferenceTableEnum, x: UserTableViewVm[]): void {
        switch (tableName) {
        case UserPreferenceTableEnum.Allocation:
            this.allocationTableViews = this.MapTableView(x);
            const selectedAllocationView = this.allocationTableViews.find(a => a.isSelected);
            if (selectedAllocationView) {
                this.userpreferencesForm.patchValue({
                    allocationView: selectedAllocationView
                });
            }
            break;
        case UserPreferenceTableEnum.Request:
            this.requestTableViews = this.MapTableView(x);
            const selectedRequestView = this.requestTableViews.find(a => a.isSelected);
            if (selectedRequestView) {
                this.userpreferencesForm.patchValue({
                    requestView: selectedRequestView
                });
            }
            break;
        }
    }
    MapTableView(x: UserTableViewVm[]): TableView[] {
        return x.map(view => {
            return {
                id: view.id,
                name: view.viewName,
                columns: view.userTableViewConfigs,
                isSelected: view.isSelected
            } as TableView;
        });
    }
}

