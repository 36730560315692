<mat-card layout="column" style="padding: 1px;padding-top:0px;">
  <div id="content">
    <mat-toolbar class="md-hue-3" style="background-color:#eceff1;height: 37px;">
      <div class="md-toolbar-tools" style="color:rgb(46, 73, 97);">
        <button mat-button (click)="searchClicked()">
          <i class="fa fa-search"></i>
        </button>
        <div *ngIf="routeToMarketAdmin===true" class="header-container">
          <button mat-button (click)="addRouteToMarket()">Add Route To Market</button>
          <button mat-button [disabled]="isDisabled()" (click)="editRouteToMarket()">Update Route To Market</button>
          <button mat-button (click)="bulkUploadRouteToMarkets()">Bulk Upload Route To Markets</button>
        </div>
        <span flex="" style="-ms-flex: 1; -webkit-flex: 1; flex: 1;"></span>
      </div>
    </mat-toolbar>
    <div [hidden]="!showSearch" class="search-box">
      <form [formGroup]="searchForm" (ngSubmit)="search(searchForm)">
        <div class="row-box">
          <div class="small-margin-right">
            <mat-form-field style="margin: 0px 0px; max-width: 250px;">
              <input type="text" matInput formControlName="depot" [matAutocomplete]="depotAuto" placeholder="Origin"
                #depotInput oninput="this.value = this.value.toUpperCase()">
            </mat-form-field>
            <mat-autocomplete #depotAuto="matAutocomplete" [displayWith]="displayDepot">
              <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
              <mat-option *ngFor="let depot of filteredDepots?.items " [value]="depot">
                {{depot?.depotNumber + ' - ' + depot?.depotName}}</mat-option>
            </mat-autocomplete>
          </div>
          <div class="small-margin-right">
            <mat-form-field style="margin: 0px 0px; max-width: 80px;" mat-no-float>
              <input matInput type="search" placeholder="Destination" formControlName="route">
            </mat-form-field>
          </div>
          <div class="small-margin-right">
            <mat-form-field style="margin: 0px 0px; max-width: 120px;" mat-no-float>
              <input matInput type="search" placeholder="Grade" formControlName="grade" oninput="this.value = this.value.toUpperCase()">
            </mat-form-field>
          </div>
          <div class="button-container" style="margin-top: 10px;">
            <button type="submit" mat-stroked-button>Apply Filter</button>
            <button mat-stroked-button (click)="clearFilter()" type="button">Clear Filters</button>
          </div>
        </div>
      </form>
    </div>
    <app-spinner [displayProgressSpinner]="isLoading" #spinner></app-spinner>
    <div style="width: 100%;overflow-y: auto;" [ngStyle]="{'height.px': tableHeight}" #mainTable>
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort (matSortChange)="sortByInfo($event)"
        name="pendingtable" id="pending-table" cellpadding="0" cellspacing="0"
        class="table table-hover table-condensed custom-table" border="0" [style.width.%]="tableWidth">
        <ng-container matColumnDef="Depot">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="DepotNo" class="interactive heading-row"
            sortActionDescription="Sort by Origin">
            Origin
          </th>
          <td mat-cell *matCellDef="let element"> {{element.depotNo}} </td>
        </ng-container>
        <ng-container matColumnDef="Grade">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="Grade" class="interactive heading-row"
            sortActionDescription="Sort by grade">
            Grade
          </th>
          <td mat-cell *matCellDef="let element"> {{element.grade}} </td>
        </ng-container>
        <ng-container matColumnDef="Destination">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="Route" class="interactive heading-row"
            sortActionDescription="Sort by Destination">
            Destination
          </th>
          <td mat-cell *matCellDef="let element"> {{element.route}} </td>
        </ng-container>
        <ng-container matColumnDef="EffectiveDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="EffectiveDate" class="interactive heading-row"
            sortActionDescription="Sort by Effective Date">
            Effective Date
          </th>
          <td mat-cell *matCellDef="let element"> {{element.effectiveDate | momentUtcAsLocaleDate}} </td>
        </ng-container>
        <ng-container matColumnDef="History">
          <th mat-header-cell *matHeaderCellDef class="interactive heading-row"></th>
          <td mat-cell *matCellDef="let element">
            <div class="icon-placeholder">
              <span *ngIf="element?.hasHistory"
                (click)="showHistory(element.id)">
                <i class="fa fa-history"></i>
              </span>
            </div>
          </td>
        </ng-container>
        <!-- route-to-market-detail-row -->
        <ng-container matColumnDef="routeToMarketDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length"></td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns;" class="heading-row sticky"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{ 'selected' : row.isSelected }"
          style="height: 36px;" (click)="selectRouteToMarket(row, $event)"></tr>
        <tr mat-row *matRowDef="let row; columns: ['routeToMarketDetail']" class="route-to-market-detail-row"></tr>
      </table>
    </div>
    <table style="width: 100%;" *ngIf="routesToMarkets.length > 0">
      <tfoot>
        <tr>
          <td style="text-align: center;">
            <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" [maxSize]="3"
              [rotate]="true" [boundaryLinks]="collectionSize >= 100" (pageChange)="pageChanged($event)"
              aria-label="Default pagination"></ngb-pagination>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</mat-card>
