import { Component, OnInit, Input, forwardRef, SimpleChanges, OnChanges } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, FormControl,
	FormGroupDirective, NgForm, ControlValueAccessor, NG_VALUE_ACCESSOR, Validator,
	AbstractControl, ValidationErrors, NG_VALIDATORS } from '@angular/forms';
import { UomVm, AllocateRequestAdditionalCost, PartyVm, AnalysisCodeVm } from '../gen/models';
import { isNullOrUndefined } from '../tools';
import { AllocateService } from '../gen/services/allocate.service';
import { CustomValidators } from '../shared/custom-validators';
import { ErrorStateMatcher } from '@angular/material/core';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

class CrossFieldErrorMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl, form: FormGroupDirective | NgForm): boolean {
		if (!form.dirty) { return false; }
		return form.invalid && (isNullOrUndefined(control.value) || control.invalid);
	}

}

@Component({
	selector: 'app-additional-costs',
	templateUrl: './additional-costs.component.html',
	styleUrls: ['./additional-costs.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => AdditionalCostsComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => AdditionalCostsComponent),
			multi: true,
		}
	]
})
export class AdditionalCostsComponent implements OnInit, ControlValueAccessor, Validator {

	additionalHidden = true;
  @Input() uoms: UomVm[];
  @Input() costs: AllocateRequestAdditionalCost[];
  @Input() maxCosts: number;
  @Input() showCollapse = true;
  @Input() defaultUOMId = null;
  @Input() enableCollapsing = true;
  @Input() hasDefaultRow = true;
  costCodes: AnalysisCodeVm[];
  formGroup: FormGroup;
  errorMatcher = new CrossFieldErrorMatcher();
  additionalCosts = new FormArray([]);
  trash = faTrash;
  constructor(private fb: FormBuilder,
              private allocateService: AllocateService) { }

  propagateChange = (_: any) => { };

  ngOnInit() {
  	if (this.hasDefaultRow && (isNullOrUndefined(this.costs) || this.costs.length === 0)) {
  		this.addAdditionalCost(null, null, null, null, null, this.defaultUOMId);
  	}
  	this.allocateService.getAnalysisCodes(5).subscribe(a => this.costCodes = a);
  	this.additionalCosts.valueChanges.subscribe(val => this.propagateChange(val));
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes.defaultUOMId
  //     && changes.defaultUOMId.currentValue !== changes.defaultUOMId.previousValue
  //     && changes.defaultUOMId.currentValue) {
  //       this.additionalCosts.controls.forEach((a: FormGroup) => {
  //         if (!a.controls.rateUomId.value) {
  //           a.controls.rateUomId.patchValue(changes.defaultUOMId.currentValue);
  //         }
  //       });
  //   }
  // }

  writeValue(obj: any): void {    
  	if (!isNullOrUndefined(obj)) {
  		this.costs = obj;
  		for (const cost of this.costs) {
  			this.addAdditionalCost(cost.costId, cost.costCode,
  				cost.partyAccountNo, cost.partyName, cost.rateUomValue, cost.rateUomId);
  		}
  	}
  }
  registerOnChange(fn: any): void {
  	this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
  	// throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
  	throw new Error('Method not implemented.');
  }

  validate(control: AbstractControl): ValidationErrors {
  	if (control.pristine) { return null; }
  	for (const obj of Array.from<any>(control.value)) {
  		if (obj.costCode === null
        || obj.partyAccountNo === null
        || obj.rateUomValue === null
        || obj.rateUomId === null) {
  			return {
  				nullFields: 'not all required fields set'
  			};
  		}
  	}
  	return {};
  }
  // registerOnValidatorChange?(fn: () => void): void {
  //   throw new Error("Method not implemented.");
  // }

  displayParty(party: PartyVm) {
  	if (isNullOrUndefined(party) || (!party.partyAccountNo && !party.partyName)) { return ''; }
  	return `${party.partyAccountNo} - ${party.partyName}`;
  }
  partyTooltip(party: PartyVm) {
  	return party.partyName;
  }

  toggleVisibility() {
  	if (this.additionalHidden) {
  		this.additionalHidden = false;
  	} else {
  		this.additionalHidden = true;
  	}
  }

  removeAdditionalCost(index: number) {
  	this.additionalCosts.removeAt(index);
  	if (this.additionalCosts.length === 0 && this.hasDefaultRow) {
  		this.additionalCosts = new FormArray([]);
  		this.addAdditionalCost(null, null, null, null, null, this.defaultUOMId); }
  }

  addAdditionalCost(costId: number,
  	costCode: string,
  	partyAccountNo: string,
  	partyName: string,
  	rateUomValue: number,
  	rateUomId: number) {
  	// const arr = this.controlContainer.control as FormArray;
  	this.additionalCosts.push(
  		this.newAdditionalCost(costId,
  			costCode,
  			partyAccountNo,
  			partyName,
  			rateUomValue,
  			rateUomId));
  }

  newAdditionalCost(costId: number,
  	costCode: string,
  	partyAccountNo: string,
  	partyName: string,
  	rateUomValue: number,
  	rateUomId: number): FormGroup {
  	const group = this.fb.group({
  		costId: [null],
  		costCode: [null],
  		partyAccountNo: [null],
  		rateUomValue: [null],
  		rateUomId: [null]
  	}
  	, {validators: CustomValidators.allIfAny(['costCode', 'partyAccountNo', 'rateUomValue', 'rateUomId'])}
  	);

  	if (isNullOrUndefined(costId)
      && isNullOrUndefined(costCode)
      && isNullOrUndefined(partyAccountNo)
      && isNullOrUndefined(rateUomValue)
      && isNullOrUndefined(rateUomId)
  	) {
  		return group;
  	}
  	group.controls.costId.patchValue(costId);
  	group.controls.costCode.patchValue(costCode);
  	if (!isNullOrUndefined(partyAccountNo)) {
  		group.controls.partyAccountNo.patchValue({ partyAccountNo, partyName});
  	}
  	group.controls.rateUomValue.patchValue(rateUomValue);
  	group.controls.rateUomId.patchValue(rateUomId);
  	return group;
  }


  showAddBtn(index: number): boolean {
  	if (this.maxCosts === 0) { return false; }

  	return index === this.additionalCosts.controls.length - 1
      &&  this.additionalCosts.controls.length < this.maxCosts;
  }

	// private init() {
	//   if (this.costs && this.costs.length > 0) {
	//     for (const cost of this.costs) {
	//       this.addAdditionalCost(cost.costId, cost.costCode,
	//         cost.partyAccountNo, cost.partyName, cost.rateUomValue, cost.rateUomId);
	//     }
	//   }    else {
	//     this.addAdditionalCost(null, null, null, null, null, null);
	//   }
	// }
}


