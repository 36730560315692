import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AttachmentsService } from '../gen/services/attachments.service';
import { SidenavService } from '../services/sidenav.service';
import { PendingAndAllocatedViewModel, AttachmentsVm } from '../gen/models';

@Component({
	selector: 'app-attachments-view',
	templateUrl: './attachments-view.component.html',
	styleUrls: ['./attachments-view.component.scss']
})
export class AttachmentsViewComponent implements OnInit {
  @Output() close = new EventEmitter<AttachmentsVm>();
  loading = false;
  uploadError = false;
  allocation: PendingAndAllocatedViewModel;
  attachments: AttachmentsVm;

  constructor(private attachmentsService: AttachmentsService, private sidenavService: SidenavService) { }

  ngOnInit() {
  	this.sidenavService.selectedAllocation$.subscribe(a => {
  		this.allocation = a.item;
  		this.attachmentsService.getAttachmentUrls(a.item.requestId).subscribe(u => {
  			this.attachments = u;
  		});
  	});
  }

  uploadFile(files: FileList) {
  	this.loading = true;
  	this.uploadError = false;
  	this.attachmentsService
  		.upload(this.allocation.requestId, files.item(0))
  		.subscribe(() => {
  			this.loading = false;
  			this.attachmentsService.getAttachmentUrls(this.allocation.requestId).subscribe(u => {
  				this.attachments = u;
  				this.sidenavService.itemAdded(this.attachments.urls.slice(0)[0]);
  				this.close.emit(u);
  			});
  		},
  		err => {
  			this.uploadError = true;
  			this.loading = false;
  		});
  }
}
