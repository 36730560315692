import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { REST_CONFIG_TOKEN, RestConfigService } from '../rest-config-service';
import { Observable } from 'rxjs';
import {
	ContractHeaderViewModel,
} from '../models';

@Injectable()
export class ContractsService {

	constructor(
        private _http: HttpClient,
        @Inject(REST_CONFIG_TOKEN) private _configService: RestConfigService
	) {
	}

	public getHeaders: (partyAccountNo?: string) => Observable<ContractHeaderViewModel[]> = (partyAccountNo?: string): Observable<ContractHeaderViewModel[]> => {
		const queryParameters = new HttpParams();
		const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

		const uri = `/api/contracts/${partyAccountNo}/headers`;

		return this._http
			.get<ContractHeaderViewModel[]>(this._configService.apiBaseUrl() + uri,
				{ headers: headers, params: queryParameters });
	};
}

