<ng-template #defaultTemplate let-weekEvent="weekEvent" let-tooltipPlacement="tooltipPlacement"
  let-eventClicked="eventClicked" let-tooltipTemplate="tooltipTemplate" let-tooltipAppendToBody="tooltipAppendToBody"
  let-tooltipDisabled="tooltipDisabled" let-tooltipDelay="tooltipDelay" let-column="column" let-daysInWeek="daysInWeek">
  <div [ngClass]="IsDayView != true ? 'calendar-event-title' : 'calendar-day-event-title'"
    class="cal-event  calendar-event-title" [ngStyle]="
  {
    'background-color':weekEvent.event.color.primary,
    'border-color':weekEvent.event.color.secondary,
    'border-radius': '5px',
    'color':'#0d62ab',
    'border':'1px solid',
    'font-size': '14px',
    'text-align': 'center',
    'display': 'inline-block'
  }" [mwlCalendarTooltip]="
    !tooltipDisabled
      ? (weekEvent.event.title
        | calendarEventTitle
          : (daysInWeek === 1 ? 'dayTooltip' : 'weekTooltip')
          : weekEvent.tempEvent || weekEvent.event)
      : ''
  " [tooltipPlacement]="tooltipPlacement" [tooltipEvent]="weekEvent.tempEvent || weekEvent.event"
    [tooltipTemplate]="tooltipTemplate" [tooltipAppendToBody]="tooltipAppendToBody" [tooltipDelay]="tooltipDelay"
    (mwlClick)="eventClicked.emit({ sourceEvent: weekEvent.event })"
    (mwlKeydownEnter)="eventClicked.emit({ sourceEvent: $event })" tabindex="0" role="application">
    <mwl-calendar-event-actions [event]="weekEvent.tempEvent || weekEvent.event"
      [customTemplate]="eventActionsTemplate">
    </mwl-calendar-event-actions>
    &ngsp;
    <mwl-calendar-event-title [event]="weekEvent.tempEvent || weekEvent.event" [customTemplate]="customEventTitle"
      [view]="daysInWeek === 1 ? 'day' : 'week'">
      <ng-template #customEventTitle>

        {{weekEvent.event.meta.depot}} |
        {{ !weekEvent.event.meta.allocationNumber ? weekEvent.event.meta.requestId :
        weekEvent.event.meta.allocationNumber}} |
        {{!weekEvent.event.meta.grade ? weekEvent.event.meta.salesGrade : weekEvent.event.meta.grade}}

      </ng-template>

    </mwl-calendar-event-title>
  </div>
</ng-template>
<ng-template [ngTemplateOutlet]="customTemplate || defaultTemplate" [ngTemplateOutletContext]="{
  weekEvent: weekEvent,
  tooltipPlacement: tooltipPlacement,
  eventClicked: eventClicked,
  tooltipTemplate: tooltipTemplate,
  tooltipAppendToBody: tooltipAppendToBody,
  tooltipDisabled: tooltipDisabled,
  tooltipDelay: tooltipDelay,
  column: column,
  daysInWeek: daysInWeek
}">
</ng-template>