import { Component, OnInit, Input } from '@angular/core';
import { MultiGradeDetail } from '../gen/models';

@Component({
	selector: 'app-multi-grade-allocation-detail',
	templateUrl: './multi-grade-allocation-detail.component.html',
	styleUrls: ['./multi-grade-allocation-detail.component.scss']
})
export class MultiGradeAllocationDetailComponent implements OnInit {

  @Input() detail: MultiGradeDetail[];
  showDetail = false;
  detailStyle = {};
  constructor() { }

  ngOnInit() {
  }

  showHideDetail(show: boolean, event: MouseEvent) {
  	this.showDetail = show;
  	this.detailStyle = {
  		top: `${event.y - 45}px` ,
  		left: `${event.x - 250}px`,
  		position: 'absolute',
  		'z-index': '10',
  		'background-color': 'white',
  		'box-shadow': '3px 4px 4px #888888',
  		border: 'solid 1px #888888',
  		padding: '2px'
  	};
  }
}
