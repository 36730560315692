
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JWTInterceptor } from './interceptor';
import { AllocationService } from './services/allocation.service';
import { AppointmentService } from './services/appointment.service';
import { AttachmentsService } from './services/attachments.service';
import { BookingSheetService } from './services/booking-sheet.service';
import { ChangeHistoryService } from './services/change-history.service';
import { ContainersService } from './services/containers.service';
import { ContractsService } from './services/contracts.service';
import { ConversationService } from './services/conversation.service';
import { DepotService } from './services/depot.service';
import { EmailService } from './services/email.service';
import { HauliersService } from './services/hauliers.service';
import { HeapsService } from './services/heaps.service';
import { AllocateService } from './services/allocate.service';
import { GradeService } from './services/grade.service';
import { SalesGradeService } from './services/sales-grade.service';
import { PartiesService } from './services/parties.service';
import { UomsService } from './services/uoms.service';
import { ADGroupService } from './services/adgroup.service';
import { TerritoryService } from './services/territory.service';
import { UserPreferencesService } from './services/user-preferences.service';
import { TransportPlanService } from './services/transport-plan.service';
import { LiveLoadsService } from './services/live-loads.service';
import { RouteToMarketService } from './services/route-to-market.service';
import { RegionService } from './services/region.service';

@NgModule({
	imports: [CommonModule],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JWTInterceptor,
			multi: true,
		},
		AllocationService,
        RegionService,
		AppointmentService,
		AttachmentsService,
		BookingSheetService,
		TransportPlanService,
		ChangeHistoryService,
		ContainersService,
		ContractsService,
		ConversationService,
		DepotService,
		EmailService,
		HauliersService,
		HeapsService,
		GradeService,
		SalesGradeService,
		AllocateService,
		PartiesService,
		UomsService,
		ADGroupService,
		TerritoryService,
		UserPreferencesService,
		LiveLoadsService,
		RouteToMarketService,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class GenServicesModule { }

