import {Injectable} from '@angular/core';
import {RestConfigService} from './gen/rest-config-service';
import {ConfigService} from './services/config-service';

@Injectable()
export class RestConfigImplService implements RestConfigService {

	constructor(private configService: ConfigService) { }


	apiBaseUrl(): string {
		return this.configService.config.apiUrl;
	}


}
