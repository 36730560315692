import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	constructor(private oauthService: OAuthService) { }

	isAuthenticated(): boolean {
		const claims = this.oauthService.getIdentityClaims();
		return claims !== null;
	}

	getToken(): string {
		return this.oauthService.getAccessToken();
	}
}
