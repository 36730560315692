import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InitModel, UomVm, DepotVm, ApiPageResponse,
    InstructionToMoveVm, AllocateModel } from '../gen/models';
import { Subject } from 'rxjs';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators, FormArray, FormGroupDirective } from '@angular/forms';
import { UomsService } from '../gen/services/uoms.service';
import { DepotService } from '../gen/services/depot.service';
import { HeapsService } from '../gen/services/heaps.service';
import { InitService } from '../services/init.service';
import { isNullOrUndefined } from '../tools';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { DateAdapter } from '@angular/material/core';
import { InstructionToMoveService } from '../gen/services/instruction-to-move.service';
import { NewInstructionToMove } from '../gen/models/NewInstructructionToMove';
import { GradeService } from '../gen/services/grade.service';
import { AllocateService } from '../gen/services/allocate.service';
import { CustomValidators } from '../shared/custom-validators';

@Component({
    selector: 'app-new-request-dialog',
    templateUrl: './new-instruction-to-move-dialog.component.html',
    styleUrls: ['./new-instruction-to-move-dialog.component.scss']
})
export class NewInstructionToMoveDialogComponent implements OnInit {

    pageModel: InitModel;
    uoms: UomVm[];
    myDate: Date;
    minDate: Date;
    maxDate: Date;
    transportModes: string[] = ['TRUCK', 'CONTAINER', 'RAIL', 'SHIP'];
    onHold = [{name: 'Yes', value: true},{name: 'No', value: false}];
    defaultDepot: DepotVm;
    cloning = false;
    shownLoadTimes: number;
    isLoading = false;
    instructionToMoveForm: FormGroup;
    depots = [];
    heaps = [];
    filteredDepots: ApiPageResponse<DepotVm>;
    depotSelected: boolean;
    isSubmit = false;
    destinationModelChanged: Subject<string> = new Subject<string>();
    destinationIndex: number;
    filteredContractRef: ApiPageResponse<AllocateModel>;
    contractHeaderIndex: number;
    contractRefModelChanged: Subject<string> = new Subject<string>();
    gradeModelChanged: Subject<string> = new Subject<string>();
    filteredGrade: string[];
    gradeIndex: number;
    depotModelChanged: Subject<string> = new Subject<string>();
    depotIndex: number;
    constructor(
		public gradeService: GradeService,
		public dialogRef: MatDialogRef<NewInstructionToMoveDialogComponent>,
		private instructionToMoveService: InstructionToMoveService,
		private uomsService: UomsService,
		private depotsService: DepotService,
		private heapService: HeapsService,
		private initService: InitService,
		private fb: FormBuilder,
		private toastr: ToastrService,
		private _adapter: DateAdapter<any>,
		private allocateService: AllocateService,
		@Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {

        this._adapter.setLocale(window.navigator.language);
        const defaultReadyDate = new Date();
        defaultReadyDate.setDate(defaultReadyDate.getDate() + 7);
        this.instructionToMoveForm = this.fb.group({
            materials: this.fb.array([])
        });

        this.initService.initData$.subscribe(r => {
            this.pageModel = r;
            this.addMaterial();
        });
        this.uomsService.getAll().subscribe(r => this.uoms = r);
        this.myDate = new Date();
        this.minDate = new Date(
            this.myDate.getFullYear(),
            this.myDate.getMonth(),
            this.myDate.getDate()
        );
        this.maxDate = new Date(
            this.myDate.getFullYear() + 1,
            this.myDate.getMonth(),
            this.myDate.getDate()
        );
    }

    get depot() {
        return this.materials.controls[this.depotIndex].get('depot');
    }



    get materials() {
        return this.instructionToMoveForm.get('materials') as FormArray;
    }

    uom(i: number) {
        return this.materials[i].get('uom');
    }
    get destination(){
        return this.materials.controls[this.destinationIndex].get('destination');
    }

    get contractHeader(){
        return this.materials.controls[this.contractHeaderIndex].get('contractHeaderId');
    }

    get grade() {
        return this.materials.controls[this.gradeIndex].get('grade');
    }

    weight(i: number) {
        return this.materials[i].get('weight');
    }
    tl(i: number) {
        return this.materials[i].get('tl');
    }
    loadTimes(i: number) {
        return this.materials[i].get('loadTimes') as FormArray;
    }
    containerSize(i: number) {
        return this.materials[i].get('containerSize');
    }
    packaging(i: number) {
        return this.materials[i].get('packaging');
    }

    filterDepots(val: string) {
        return this.depotsService.searchDepots(val);
    }

    filterContractRef(val: string) {
        return this.allocateService.GetContractRefsForIntructionToMove(val);
    }

    displayDepot(depot?: DepotVm): string | undefined {
        return depot && depot.depotNumber && depot.depotName ? depot.depotNumber + ' ' + depot.depotName : undefined;
    }

    displayDestination(depot?: DepotVm): string | undefined {
        return depot && depot.depotNumber && depot.depotName ? depot.depotNumber + ' ' + depot.depotName : undefined;
    }

    displayContractRef(contractRef?: AllocateModel): string | undefined {
        return contractRef && contractRef.contractRef ? contractRef.contractRef + ' ' + contractRef.partyName : undefined;
    }

    submitForm() {
        if (!this.instructionToMoveForm.valid) {
            const unsetErrors = this.materials.controls.filter(c => !isNullOrUndefined(c.errors) && !isNullOrUndefined(c.errors.unset)).map(x => x.errors.unset as string);
            if(unsetErrors.length > 0) {
                this.toastr.error(unsetErrors[0], 'Form Invalid');
            }
            const dateErros = this.materials.controls.filter(c => !isNullOrUndefined(c.errors) && !isNullOrUndefined(c.errors.dates)).map(x => x.errors.dates as string);
            if(dateErros.length > 0) {
                this.toastr.error(dateErros[0], 'Form Invalid');
            }
            return;
        }
        this.isLoading = true;
        this.isSubmit = true;
        const value = this.instructionToMoveForm.value;
        const localMoment =  moment().local();
        const stringDate = localMoment.format('YYYY-MM-DDTHH:mm:ss');
        const model: NewInstructionToMove = {

            plannedMovements: value.materials.map(m => {

                const priority = !isNullOrUndefined(m.priority) ? m.priority : null;
                const packagingId = !isNullOrUndefined(m.packaging) ? m.packaging : null;
                const contractHeader =!isNullOrUndefined(m.contractHeaderId?.contractHeaderId) ? m.contractHeaderId.contractHeaderId : null;
                const containerType =!isNullOrUndefined(m.containerSizeId) ? m.containerSizeId : null;
                const mat: InstructionToMoveVm = {
                    Destination: m.destination.depotNumber,
                    Depot: m.depot.depotNumber,
                    Grade: m.grade,
                    ContractHeaderId : contractHeader,
                    TonnesToMove: m.tonnesToMove,
                    LoadsToMove: m.loadsToMove,
                    DueFrom: new Date(moment(m.dueFrom).format('YYYY-MM-DD')) ,
                    DueBy: new Date(moment(m.dueBy).format('YYYY-MM-DD')),
                    Hold:m.hold ,
                    Priority: priority ,
                    TransportMode:m.transportMode ,
                    ContainerSizeId: containerType,
                    PackageOptionId:packagingId ,
                    UomId: m.uomId,
                    CreatedDate: stringDate
                };
                return mat;
            })
        };

        this.instructionToMoveService.createInstructionToMove(model).subscribe(() => {
            this.toastr.success('Instruction to move successfully created', 'New Instruction to move');
            this.closeDialog();
        },
        err => {
            if (err.status === 200) {
                this.toastr.success('Instruction to move successfully created', 'New Instruction to move');
                this.closeDialog();
            }
            else {
                this.toastr.error('Something went wrong and the Instruction to move could not be created. Please try again later.', 'New Request Failed');
                this.isLoading = false;
                this.isSubmit = false;
            }
        });
    }

    private closeDialog() {
        setTimeout(() => {
            this.isLoading = false;
            this.dialogRef.close('callServer');
        }, 1500);
    }

    reset(formDir: FormGroupDirective) {
        this.instructionToMoveForm.reset();
        formDir.resetForm();
        this.depot.setValue(this.defaultDepot);
        this.depotSelected = true;
    }


    addMaterial() {

        const lastItemIndex = this.materials.length - 1;
        if (lastItemIndex > -1) {
            const lastMaterial = this.materials.controls[lastItemIndex] as FormGroup;
            lastMaterial.controls.submitted.patchValue(true);
        }
        const group = this.newMaterial();
        group.reset();
        this.materials.push(group);
        this.toggleContainerSizeAndUom();
    }

    removeMaterial(index: number) {
        this.materials.removeAt(index);
        if (this.materials.length === 0) {
            this.addMaterial();
        }
    }

    copyMaterial(index: number) {
        this.cloning = true;
        const copyFrom = this.materials.controls[index] as FormGroup;
        this.addMaterial();
        const lastItemIndex = this.materials.length - 1;
        const copyTo = this.materials.controls[lastItemIndex] as FormGroup;
        for (const key of Object.keys(copyFrom.controls)) {
            copyTo.controls[key].patchValue(copyFrom.controls[key].value);
        }
        this.cloning = false;
    }

    addMaterialRow()
    {
        this.cloning = true;
        this.addMaterial();
        this.cloning = false;
    }

    newMaterial(): FormGroup {
        this.shownLoadTimes = undefined;
        const group = this.fb.group({
            destination: ['', Validators.required],
            depot: ['', Validators.required],
            dueFrom: ['', Validators.required],
            dueBy: ['', Validators.required],
            transportMode: ['', Validators.required],
            hold: ['', Validators.required],
            priority: ['', Validators.required],
            contractHeaderId: [''],
            grade: ['', Validators.required],
            uomId: ['', Validators.required],

            tonnesToMove: ['',[Validators.required, Validators.pattern('^[0-9]{1,7}(.[0-9]+)?$')]],
            loadsToMove: ['', Validators.required],
            containerSizeId: [''],
            packaging: [''],


            submitted: [false]
        },
        {
            validators:
            [CustomValidators.dateLessThan('dueFrom', 'dueBy')]
        });

        group.get('contractHeaderId').valueChanges
            .pipe(
                debounceTime(500),
                startWith(null),
                switchMap(v => this.filterContractRef(v)))
            .subscribe(d => this.filteredContractRef = d);

        group.get('depot').valueChanges
            .pipe(
                debounceTime(500),
                startWith(null),
                switchMap(v => this.filterDepots(v)))
            .subscribe(d => this.filteredDepots = d);

        group.get('destination').valueChanges
            .pipe(
                debounceTime(500),
                startWith(null),
                switchMap(v => this.filterDepots(v)))
            .subscribe(d => this.filteredDepots = d);

        group.get('grade').valueChanges
            .pipe(
                debounceTime(500),
                startWith(null),
                switchMap(v => this.filterGrade(v)))
            .subscribe(g => this.filteredGrade = g);

        return group;
    }

    private toggleContainerSizeAndUom() {
        this.enableContainerSizeAndUom();
    }

    private enableContainerSizeAndUom() {
        this.materials.controls.forEach((c) => {
            c.enable();
        });
    }

    destinationValueChange(val: string, index: number) {
        if (val.length > 1) {
            val = val.toUpperCase();
            this.destinationModelChanged.next(val);
            this.destinationIndex = index;
        }
    }

    depotValueChange(val: string, index: number) {
        if (val.length > 1) {
            val = val.toUpperCase();
            this.depotModelChanged.next(val);
            this.depotIndex = index;
        }
    }

    contractRefValueChange(val: string, index: number) {
        val = val.toUpperCase();
        this.contractRefModelChanged.next(val);
        this.contractHeaderIndex = index;
    }

    displayGrade(grade?: string): string | undefined {
        return grade ? grade : undefined;
    }
    gradeValueChange(val: string, index: number) {
        val = val.toUpperCase();
        this.gradeModelChanged.next(val);
        this.gradeIndex = index;
    }

    filterGrade(val: string) {
        return this.gradeService.getGrade(val);
    }
}
