import {
	Component,
	Input,
	Output,
	EventEmitter,
	TemplateRef,
} from '@angular/core';
import { CalendarEvent } from 'calendar-utils';
import { Subject } from 'rxjs';
import { PlacementArray } from 'positioning';
import { CalendarWeekViewBeforeRenderEvent } from 'angular-calendar/modules/week/calendar-week.module';
import { CalendarEventTimesChangedEvent } from 'angular-calendar/modules/common/calendar-event-times-changed-event.interface';
import { PendingAndAllocatedViewModel } from 'src/app/gen/models';

export type CalendarDayViewBeforeRenderEvent =
  CalendarWeekViewBeforeRenderEvent;

/**
 * Shows all events on a given day. Example usage:
 *
 * ```typescript
 * <mwl-calendar-day-view
 *  [viewDate]="viewDate"
 *  [events]="events">
 * </mwl-calendar-day-view>
 * ```
 */
@Component({
	selector: 'app-day-view',
	templateUrl: './calendar-day-view.component.html',
})
export class CalendarDayViewComponent {

  @Input() pendingAndAllocated: PendingAndAllocatedViewModel;
  /**
   * The current view date
   */
    @Input() viewDate: Date;

    /**
     * An array of events to display on view
     * The schema is available here: https://github.com/mattlewis92/calendar-utils/blob/c51689985f59a271940e30bc4e2c4e1fee3fcb5c/src/calendarUtils.ts#L49-L63
     */
    @Input() events: CalendarEvent[] = [];
  
    /**
     * The number of segments in an hour. Must divide equally into 60.
     */
    @Input() hourSegments = 2;
  
    /**
     * The height in pixels of each hour segment
     */
    @Input() hourSegmentHeight = 30;
  
    /**
     * The day start hours in 24 hour time. Must be 0-23
     */
    @Input() dayStartHour = 0;
  
    /**
     * The day start minutes. Must be 0-59
     */
    @Input() dayStartMinute = 0;
  
    /**
     * The day end hours in 24 hour time. Must be 0-23
     */
    @Input() dayEndHour = 23;
  
    /**
     * The day end minutes. Must be 0-59
     */
    @Input() dayEndMinute = 59;
  
    /**
     * An observable that when emitted on will re-render the current view
     */
    @Input() refresh: Subject<any>;
  
    /**
     * The locale used to format dates
     */
    @Input() locale: string;
  
    /**
     * The grid size to snap resizing and dragging of events to
     */
    @Input() eventSnapSize: number;
  
    /**
     * The placement of the event tooltip
     */
    @Input() tooltipPlacement: PlacementArray = 'auto';
  
    /**
     * A custom template to use for the event tooltips
     */
    @Input() tooltipTemplate: TemplateRef<any>;
  
    /**
     * Whether to append tooltips to the body or next to the trigger element
     */
    @Input() tooltipAppendToBody = true;
  
    /**
     * The delay in milliseconds before the tooltip should be displayed. If not provided the tooltip
     * will be displayed immediately.
     */
    @Input() tooltipDelay: number | null = null;
  
    /**
     * A custom template to use to replace the hour segment
     */
    @Input() hourSegmentTemplate: TemplateRef<any>;
  
    /**
     * A custom template to use for day view events
     */
    @Input() eventTemplate: TemplateRef<any>;
  
    /**
     * A custom template to use for event titles
     */
    @Input() eventTitleTemplate: TemplateRef<any>;
  
    /**
     * A custom template to use for event actions
     */
    @Input() eventActionsTemplate: TemplateRef<any>;
  
    /**
     * Whether to snap events to a grid when dragging
     */
    @Input() snapDraggedEvents = true;
  
    /**
     * A custom template to use for the all day events label text
     */
    @Input() allDayEventsLabelTemplate: TemplateRef<any>;
  
    /**
     * A custom template to use for the current time marker
     */
    @Input() currentTimeMarkerTemplate: TemplateRef<any>;
  
    /**
     * Called when an event title is clicked
     */
    @Output() eventClicked = new EventEmitter<{
      event: CalendarEvent;
      sourceEvent: MouseEvent | any;
    }>();
  
    /**
     * Called when an hour segment is clicked
     */
    @Output() hourSegmentClicked = new EventEmitter<{
      date: Date;
      sourceEvent: MouseEvent;
    }>();
  
    /**
     * Called when an event is resized or dragged and dropped
     */
    @Output() eventTimesChanged =
    	new EventEmitter<CalendarEventTimesChangedEvent>();
  
    /**
     * An output that will be called before the view is rendered for the current day.
     * If you add the `cssClass` property to an hour grid segment it will add that class to the hour segment in the template
     */
    @Output() beforeViewRender =
    	new EventEmitter<CalendarDayViewBeforeRenderEvent>();

    public isDayView = true;
}
