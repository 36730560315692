import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { UserPreferenceTableEnum, UserTableViewConfigVm, UserTableViewVm } from '../gen/models/UserTableViewVm';
import { UserPreferencesService } from '../gen/services/user-preferences.service';

@Component({
	selector: 'app-user-table-view-setting',
	templateUrl: './user-table-view-setting.component.html',
	styleUrls: ['./user-table-view-setting.component.scss']
})
export class UserTableViewSettingComponent implements OnInit {
  
	title: string;
	columns: Column[];
	views: TableView[];
	form:  FormGroup;
	isLoading = false;
	saveText= 'Save';
	selectedView: TableView;
	table:string;
	isSaveProgress:boolean;
	constructor(
    public dialogRef: MatDialogRef<UserTableViewSettingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private userPreferencesService: UserPreferencesService,
    private toastr: ToastrService,
    private router: Router) { 
		this.form = this.fb.group({
			selectedView: ['', Validators.nullValidator],
			viewName: ['', Validators.minLength(5)],
		});
	}

	ngOnInit() {
		this.table = UserPreferenceTableEnum[this.data.name];
		this.title = `${this.table} columns display views`;
		this.columns = this.data.columns;
		this.columns.forEach((col: any, index: number) => col.index = index);
		this.getExistingViews(this.table);

		this.form.get('selectedView')
			.valueChanges
			.subscribe(value => {
				this.selectedView = value;
				this.form.patchValue(
					{
						viewName: !value ? '' : value.name
					});
				this.SetColumnsForSlectedView(value);
			});

		this.form.get('viewName')
			.valueChanges
			.subscribe(name => {
				if(this.selectedView && this.selectedView.name != name){
					this.saveText = 'Add';
				}else{
					this.saveText= 'Save';
				}
			});
	}

	cancel(): void {
		this.dialogRef.close();
	}

	DeleteView():void{
		this.isLoading = true;
		const selectedView = this.form.get('selectedView').value;
		if(!selectedView || selectedView.id  == '0'){
			this.toastr.error('No view selcted to delete.', 'User preference');
			this.isLoading = false;
			return;
		}
		const data : UserTableViewVm = {
			id: selectedView.id ,
			isDeleted: true,
			table: this.table == 'Allocation' ? UserPreferenceTableEnum.Allocation : UserPreferenceTableEnum.Request,
			viewName: '',
			isSelected: false,
			userId: 0,
			userTableViewConfigs: []
		};
		this.callServer(data);
	}

	save(){
		this.isLoading = true;
		this.isSaveProgress = true;
		const selectedView = this.form.get('selectedView').value;
		const textView = this.form.get('viewName').value;
		const viewId = selectedView.name == textView ? selectedView.id : 0;
		const viewName = selectedView.name == textView ? selectedView.name  : textView;
		const data : UserTableViewVm = {
			id: viewId,
			viewName: viewName,
			isDeleted: false,
			table: this.table == 'Allocation' ? UserPreferenceTableEnum.Allocation : UserPreferenceTableEnum.Request,
			isSelected: false,
			userId: 0,
			userTableViewConfigs: this.columns.map(config => {
				return {
					columnName: config.name,
					id: config.id,
					isSelected: config.isSelected,
					index: config.index
				} as UserTableViewConfigVm;
			})

		};
		this.callServer(data);
	}

	callServer(data : UserTableViewVm){
		this.userPreferencesService.isUserPreferenceExists(data)
			.pipe(finalize (() => this.isLoading = false))
			.subscribe(p => 
			{
				if(!p)
				{
					this.userPreferencesService
						.saveUserTableView(data)
						.pipe(finalize (() => this.isLoading = false))
						.subscribe(x => {
							this.isSaveProgress=false;
							if(x){
								this.toastr.success('User table view saved.', 'User table view');
								this.cancel();
								this.reloadComponent();
							}else{
								this.toastr.error('Failed operation.', 'User table view');
							}
						});
				}
				else{
					this.isSaveProgress=false;
					this.toastr.error('Failed operation.', 'View Name already exists');
				}
			});
    
	}

	drop(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
		this.columns.forEach((col: any, index: number) => col.index = index);
	}

	onColumnChecked(col: any, event: MatCheckboxChange) {
		if(col.name === 'Show All' && event){
			this.columns.forEach((c : any) => c.isSelected = true);
		}
		else{
			col.isSelected = event;
		}

	}

	getExistingViews(tableName: string) {
		let serviceParam: UserPreferenceTableEnum;

		switch (tableName) {
		case 'Allocation':
			serviceParam = UserPreferenceTableEnum.Allocation;
			break;
		case 'Request':
			serviceParam = UserPreferenceTableEnum.Request;
			break;
		}

		this.userPreferencesService
			.getUserTableView(serviceParam)
			.subscribe(
				x => {
					this.views = x.map(view => {
						return {
							id: view.id,
							name: view.viewName,
							columns: view.userTableViewConfigs,
							isSelected: view.isSelected
						} as TableView;
					});

					const selectedView = this.views.find(v => v.isSelected);
					if(selectedView){
						this.form.patchValue({
							selectedView: selectedView
						});
					}
				}
			);
	}

	SetColumnsForSlectedView(view: TableView){
		view.columns.forEach(col => {
			const column = this.columns.find(v => v.name === col.columnName);
			if(column){
				column.index = col.index,
				column.isSelected = col.isSelected,
				column.id = col.id;
			}
		});

		this.columns  = this.columns.sort((a, b) => {
			return a.index - b.index;
		});
	}

	reloadComponent() {
		const currentUrl = this.router.url;
		this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		this.router.onSameUrlNavigation = 'reload';
		this.router.navigate([currentUrl]);
	}

}

export interface TableView {
  id: number,
  name: string,
  columns: UserTableViewConfigVm[],
  isSelected: boolean
}

interface Column{
  id: number,
  name: string,
  isSelected: boolean,
  index: number
}


