import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { isNullOrUndefined } from '../tools';

@Pipe({
	name: 'momentLocaleDateTime'
})
export class MomentLocaleDateTimePipe implements PipeTransform {

	transform(value: any, args?: any): any {
		if (isNullOrUndefined(value)) { return ''; }
		//done using https://stackoverflow.com/a/32540840
		const utc = moment.utc(value);
		const mnt = moment(utc).local();
		return mnt.format('lll');
	}

}
