import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { REST_CONFIG_TOKEN, RestConfigService } from '../rest-config-service';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from '../../tools';

@Injectable()
export class GradeService {

	constructor(
        private _http: HttpClient,
        @Inject(REST_CONFIG_TOKEN) private _configService: RestConfigService
	) {
	}
	public getGrade: (filter?: string) => Observable<string[]>
		= (filter?: string): Observable<string[]> => {
			let queryParameters = new HttpParams();
			const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

			if (!isNullOrUndefined(filter)) {
				queryParameters = queryParameters.append('filter', filter.toString());
			}

			const uri = '/api/Grade/grade';

			return this._http.get<string[]>(this._configService.apiBaseUrl() + uri, { headers: headers, params: queryParameters });
		};

	public getGradeGroups: (filter?: string, searchAnyPosition?: boolean) => Observable<string[]>
		= (filter?: string, searchAnyPosition = true): Observable<string[]> => {
			let queryParameters = new HttpParams();
			const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

			if (!isNullOrUndefined(filter)) {
				queryParameters = queryParameters.append('filter', filter.toString());
			}
            
			if(!isNullOrUndefined(searchAnyPosition)){
				queryParameters = queryParameters.append('searchAnyPosition', searchAnyPosition.toString());
			}

			const uri = '/api/Grade/grade-group';

			return this._http.get<string[]>(this._configService.apiBaseUrl() + uri, { headers: headers, params: queryParameters });
		};

	public getGradeTypes: (filter?: string) => Observable<string[]>
		= (filter?: string): Observable<string[]> => {
			let queryParameters = new HttpParams();
			const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

			if (!isNullOrUndefined(filter)) {
				queryParameters = queryParameters.append('filter', filter.toString());
			}

			const uri = '/api/Grade/grade-types';

			return this._http.get<string[]>(this._configService.apiBaseUrl() + uri, { headers: headers, params: queryParameters });
		};

	//We need to have it as a string [] otherwise a json error is thrown
	public getGradeGroupByGrade: (filter?: string) => Observable<string[]>
		= (filter?: string): Observable<string[]> => {
			let queryParameters = new HttpParams();
			const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

			if (!isNullOrUndefined(filter)) {
				queryParameters = queryParameters.append('filter', filter.toString());
			}

			const uri = '/api/Grade/gradegroupsbygrade';

			return this._http.get<string[]>(this._configService.apiBaseUrl() + uri, { headers: headers, params: queryParameters });
		};
}