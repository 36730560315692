
import {InjectionToken} from '@angular/core';

export const REST_AUTH_TOKEN =  new InjectionToken<string>('RestAuth');


export interface RestAuthService {

    ensureAuth(): boolean;
    signOut(): void;

    }


