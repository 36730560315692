import { Component, OnInit, Input, forwardRef, OnChanges, SimpleChanges } from '@angular/core';
import { AppointmentVm, Haulier, UomVm } from '../gen/models';
import {
    FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor,
    Validator, AbstractControl, ValidationErrors, Validators
} from '@angular/forms';
import * as moment from 'moment-timezone';
import { CustomValidators } from '../shared/custom-validators';
import { AllocateRequestAppointment } from '../gen/models/AllocateRequestAppointment';
import { haulagePriceMatrixVM } from '../gen/models/haulagePriceMatrixVM';
import { ToastrService } from 'ngx-toastr';
import { HauliersService } from '../gen/services/hauliers.service';
import { isNullOrUndefined } from '../tools';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';
import { UomsService } from '../gen/services/uoms.service';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { CurrencySymbolService } from '../services/currency-symbol.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-allocation-appointments',
    templateUrl: './allocation-appointments.component.html',
    styleUrls: ['./allocation-appointments.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AllocationAppointmentsComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AllocationAppointmentsComponent),
            multi: true,
        }
    ]
})
export class AllocationAppointmentsComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {

    @Input() appointments: AppointmentVm[];
    @Input() loadsCount: number;
    @Input() specifiedAppointments: AllocateRequestAppointment[];
    @Input() data: any;
    @Input() containerTypeId: number;
    @Input() depotNo: string;
    @Input() contractLineId = 0;
    @Input() partyDeliveryPointIds: number[] = [];
    @Input() hidePendingDispatch = false;
    allocationAppointments = new FormArray([]);
    loadinghaulageRates = false;
    haulageRates: haulagePriceMatrixVM[] = [];
    selectedHaulageMatrix: haulagePriceMatrixVM = null;
    haulagematrixDropdownOnly = false;
    defaultAdditionalCostUomId?: number = null;
    filteredHauliers: Haulier[];
    uoms: UomVm[];
    trash = faTrash;
    lunk601: Haulier = { accountNumber: 'LUNK601', name: 'UNKNOWN' };

    constructor(private fb: FormBuilder,
        private hauliersService: HauliersService,
        private uomsService: UomsService,
        private toastr: ToastrService,
        private currencySymbolService: CurrencySymbolService) { }

    propagateChange = (_: any) => { };

    writeValue(obj: any): void {
        if (!obj) {
            this.specifiedAppointments = obj;
            for (const appointment of this.specifiedAppointments) {
                this.addAppointment(appointment);
            }
        }
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void { }

    setDisabledState?(isDisabled: boolean): void {
        throw new Error('Method not implemented');
    }

    validate(control: AbstractControl): ValidationErrors {
        if (control.pristine) { return null; }
        for (const obj of Array.from<AllocateRequestAppointment>(control.value)) {
            if (obj.customerAptRef === null
                || obj.customerAptDateTime === null) {
                return {
                    nullFields: 'not all required fields set'
                };
            }
        }
        return {};
    }

    ngOnInit() {
        if (!this.specifiedAppointments) {
            this.addAppointment(null);
        }
        this.allocationAppointments.valueChanges.subscribe(val => this.propagateChange(val));

        this.uomsService.getAll().subscribe(u => {
            this.uoms = u;
            if (this.uoms) {
                this.defaultAdditionalCostUomId = this.uoms.find(a => a.code === 'LD').id;
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.loadsCount && changes.loadsCount.previousValue !== changes.loadsCount.currentValue) {
            while (this.allocationAppointments.controls.length > 1
                && this.allocationAppointments.controls.length > changes.loadsCount.currentValue) {
                this.allocationAppointments.removeAt(this.allocationAppointments.length - 1);
            }
        }
    }

    generateNewAppointmentControls(appointment: AllocateRequestAppointment): FormGroup {
        const group = this.fb.group({
            haulier: [''],
            pickupDateTime: ['', Validators.required],
            pickupToDateTime: [''],
            agreedHaulageRate: [''],
            agreedHaulageRateUomId: [this.defaultAdditionalCostUomId],
            firstComeFirstServe: false,
            pendingDispatch: false,
            appointmentId: '',
            customerAptRef: '',
            customerAptDateTime: '',
            customerAptToDateTime: '',
            appointmentComment: ''
        },
        {
            validators:
            [CustomValidators.dateLessThan('pickupDateTime', 'pickupToDateTime'),
                CustomValidators.dateLessThan('customerAptDateTime', 'customerAptToDateTime'),
                CustomValidators.requiredWhenSet('agreedHaulageRateUomId', 'agreedHaulageRate', 'Spot Rate UOM', 'Spot Rate')]
        }
        );

        group.controls.haulier.valueChanges
            .pipe(
                debounceTime(500),
                startWith(null),
                switchMap(v => this.filterHauliers(v)))
            .subscribe(r => this.filteredHauliers = r);

        if (appointment) {
            group.controls.appointmentId.patchValue(appointment.appointmentId);
            group.controls.customerAptRef.patchValue(appointment.customerAptRef);
            group.controls.customerAptDateTime.patchValue(appointment.customerAptDateTime);
            group.controls.customerAptToDateTime.patchValue(appointment.customerAptToDateTime);
            group.controls.appointmentComment.patchValue(appointment.appointmentComment);
        }
        return group;
    }

    selectAppointment(groupIndex: number, value: number) {
        const formControls = (this.allocationAppointments.controls[groupIndex] as any).controls;
        formControls.customerAptRef.reset();
        formControls.appointmentComment.reset();
        formControls.customerAptDateTime.reset();
        formControls.customerAptToDateTime.reset();

        if (value > 0) {
            const appointmentDetails = this.appointments.find(a => a.appointmentId === value);
            const startDate = moment.utc(appointmentDetails.startDate);
            const endDate = moment.utc(appointmentDetails.endDate);
            formControls.customerAptDateTime.setValue(startDate);
            formControls.customerAptToDateTime.setValue(endDate);
            formControls.customerAptRef.setValue(appointmentDetails.deliveryNumber);
            formControls.appointmentComment.setValue(appointmentDetails.comments);
        }
    }

    removeAppointment(groupIndex: number) {
        this.allocationAppointments.removeAt(groupIndex);
        if (this.allocationAppointments.length === 0) {
            this.allocationAppointments = new FormArray([]);
            this.addAppointment(null);
        }
    }

    addAppointment(appointment: any) {
        this.allocationAppointments.push(this.generateNewAppointmentControls(appointment));
    }

    showAddBtn(index) {
        return index === this.allocationAppointments.length - 1;
    }

    disableAddBtn() {
        return this.allocationAppointments.length >= this.loadsCount;
    }

    disableDelBtn() {
        return this.allocationAppointments.length <= 1;
    }

    ValidPickupDate(i: number): boolean {
        const controls = this.allocationAppointments.value[i];
        return isNullOrUndefined(this.depotNo) || controls.pickupDateTime === '' || !controls.pickupDateTime.isValid();
    }

    getAvailableHauliers(i: number) {

        const controls = this.allocationAppointments.value[i];

        if (!controls.pickupDateTime.isValid()) {
            this.toastr.info('Please select a valid pick up date', 'Haulage Price Matrix');
            return;
        }
        this.loadinghaulageRates = true;
        this.haulageRates = [];
        const model = new haulagePriceMatrixVM();
        model.partyDeliveryPointIds = [];
        model.containerTypeId = Number(this.containerTypeId);
        model.fromLocationCode = this.depotNo;
        if (isNullOrUndefined(this.depotNo)) {
            this.toastr.info('Please select a Depot', 'Haulage Price Matrix');
            return;
        }
        if (this.contractLineId > 0) {
            model.contractLineId = this.contractLineId;
        }
        model.allocationFromDate = controls.pickupDateTime == '' ? null : controls.pickupDateTime;
        model.allocationToDate = controls.pickupToDateTime == '' ? null : controls.pickupToDateTime;
        if (Array.isArray(this.partyDeliveryPointIds)) {
            model.partyDeliveryPointIds = this.partyDeliveryPointIds;
        } else {
            model.partyDeliveryPointIds.push(this.partyDeliveryPointIds);
        }
        model.partyAccountNo = this.data.party.partyAccountNo;
        this.hauliersService.getHaulageRates(model).subscribe(r => {
            this.haulageRates = r;
            if (this.haulageRates.length > 0) {
                this.selectedHaulageMatrix = this.haulageRates[0];
            } else {
                this.toastr.info('No matching haulage rate found', 'Haulage Matrix Rates');
            }
            this.loadinghaulageRates = false;
        },
        err => {
            this.loadinghaulageRates = false;
        });
    }

    fixHaulageRate(i: number) {
        if (!this.selectedHaulageMatrix) {
            return;
        }
        if (!this.haulagematrixDropdownOnly) {
            this.setHaulageRate(this.allocationAppointments.controls[i]);
        }
        this.haulagematrixDropdownOnly = false;
    }

    setHaulageRate(ctrls: any) {
        const h: Haulier = {
            name: this.selectedHaulageMatrix.haulierName,
            accountNumber: this.selectedHaulageMatrix.haulierAccountNo,
        };
        ctrls.controls.agreedHaulageRate.setValue(this.selectedHaulageMatrix.haulageRate);
        ctrls.controls.haulier.setValue(h);
    }

    updateHaualgeRate(hpm: any, i: number) {
        this.selectedHaulageMatrix = hpm.value;
        this.haulagematrixDropdownOnly = false;
        this.fixHaulageRate(i);
    }

    showHaulierClear(haulierInput: HTMLInputElement): boolean {
        return !isNullOrUndefined(haulierInput.value) && haulierInput.value !== '';
    }

    haulierClear(controlIndex: number) {
        const ctrl = this.allocationAppointments.controls[controlIndex];
        if (!isNullOrUndefined(ctrl) && !isNullOrUndefined(ctrl.get('haulier'))) {
            ctrl.get('haulier').setValue({ accountNumber: null, name: null });
        }
    }

    get haulier() {
        return this.allocationAppointments.get('haulier');
    }

    filterHauliers(val: string): Observable<Haulier[]> {
        return this.hauliersService.get(val);
    }

    displayHaulier(haulier?: Haulier): string {
        return isNullOrUndefined(haulier.accountNumber) || isNullOrUndefined(haulier.name)
            ? '' : haulier.accountNumber + ' ' + haulier.name;
    }

    updateHaulageRateDropdown(event: any, fixRate = false) {
        if (this.haulageRates.length > 0) {
            this.selectedHaulageMatrix = this.haulageRates[0];
            this.haulagematrixDropdownOnly = fixRate;
        }
    }

    setHaulierLunk(i: number) {
        this.setHaulier(this.allocationAppointments.controls[i], this.lunk601);
    }

    setHaulier(ctrls: any, haulier: Haulier) {
        ctrls.controls.haulier.setValue(haulier);
    }

    get spotRateText() {
        let spotRateText = 'Spot Rate';

        if (this.data?.contract?.haulageCurrencyCode) {
            const currencySymbol = this.currencySymbolService.getCurrencySymbol(this.data.contract.haulageCurrencyCode);
            if (currencySymbol) {
                spotRateText = `${spotRateText} (${currencySymbol})`;
            }
        }

        return spotRateText;
    }
}
