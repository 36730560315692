import { Component, OnInit, ViewChild, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { AllocationService } from '../gen/services/allocation.service';
import {
    InitModel, QueryModel,
    PendingAndAllocatedViewModel, AllocatedViewModel,
    FilterNames,
    LifecycleRequest,
    ContractLineAllocationLifecycle,
    PartyEmail,
    TerritoryVm,
    PrintAppointmentVm,
    QueryDateRange
} from '../gen/models';
import { UomsService } from '../gen/services/uoms.service';
import { SortInfo } from '../table-sort/table-sort.directive';
import * as _ from 'underscore';
import { InitService } from '../services/init.service';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { EditAllocatedRequestDialogComponent } from '../edit-allocated-request-dialog/edit-allocated-request-dialog.component';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { SidenavService } from '../services/sidenav.service';
import { isUndefined, isNullOrUndefined } from '../tools';
import { EnumVals, IEnumItem, ContractLineAllocationStatuses, ListOfColumnNames, SchedulerReportType } from '../gen/enums/enums';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { AllocateService } from '../gen/services/allocate.service';
import { ActionConfirmDialogComponent } from '../action-confirm-dialog/action-confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { SpinnerComponent } from '../spinner/spinner.component';
import {
    EditMultiAllocatedRequestDialogComponent
} from '../edit-multi-allocated-request-dialog/edit-multi-allocated-request-dialog.component';
import { ChangeHistoryDialogComponent } from '../change-history-dialog/change-history-dialog.component';
import { SingleDataCaptureDialogComponent } from '../single-data-capture-dialog/single-data-capture-dialog.component';
import { MomentLocaleDateTimePipe } from '../pipes/moment-locale-date-time.pipe';
import { SendMailDialogComponent } from '../send-mail-dialog/send-mail-dialog.component';
import { EmailService } from '../gen/services/email.service';
import { NewRequestDialogComponent } from '../new-request-dialog/new-request-dialog.component';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, finalize, startWith, switchMap } from 'rxjs/operators';
import { DepotViewModel } from '../gen/models/DepotViewModel';
import { ApiPageResponse } from '../gen/models/ApiPageResponse';
import { DepotService } from '../gen/services/depot.service';
import { ADGroupService } from '../gen/services/adgroup.service';
import { UserPreferencesVm } from '../gen/models/UserPreferencesVm';
import { ActivatedRoute, Router } from '@angular/router';
import { AppointmentService } from '../gen/services/appointment.service';
import { formatDate } from '@angular/common';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { AllocationMissedDropOffUpdateModel } from '../gen/models/AllocationMissedDropOffUpdateModel';
import { CalendarCommonService } from '../event-calendar/event-calendar-common-service';
import { ViewAllocatedRequestDialogComponent } from '../view-allocated-request-dialog/view-allocated-request-dialog.component';
import { UserPreferenceTableEnum, UserTableViewVm } from '../gen/models/UserTableViewVm';
import { UserPreferencesService } from '../gen/services/user-preferences.service';
import { AllocationFcfsUpdateModel } from '../gen/models/AllocationFcfsUpdateModel';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Clipboard } from '@angular/cdk/clipboard';
import { BookingStatusRequest } from '../gen/models/BookingStatusRequest';

@Component({
    selector: 'app-allocations',
    templateUrl: './allocations.component.html',
    styleUrls: ['./allocations.component.scss']
})
export class AllocationsComponent implements OnInit, OnDestroy {

    public pageModel: InitModel;
    public commentsId: number;
    public loadTimesItem: any;
    public attachmentsItem: any;
    isLoading = true;
    showSearch = false;
    itemsByPage = 20;
    lifecycles: ContractLineAllocationLifecycle[];
    allocationSubject: BehaviorSubject<AllocatedViewModelExtended[]> = new BehaviorSubject<AllocatedViewModelExtended[]>(null);
    allocations: AllocatedViewModelExtended[] = [];
    groupedAllocations: AllocatedViewModelExtended[] = [];
    previouslySelectedAllocationIds: number[];
    requestsStatuses: IEnumItem[];
    kpiTypes: IEnumItem[];
    allocationApprovedToSend: IEnumItem[];
    allocationStatuses: IEnumItem[];
    allocationBookingStatuses: IEnumItem[];
    allocationTransportResponsibilities: IEnumItem[];
    contractHeaderStatuses: IEnumItem[];
    allocationClassifications: IEnumItem[];
    allocationsNoOfResults: IEnumItem[];
    canEditSelected = false;
    lastSelectedIndex = -1;
    canSendEmails = false;
    statusList: string[];
    canAllocateRequests = false;
    selectedIsRequest = true;
    selectedIsAllocation = false;
    selectedRequestHasAllocations = false;
    defaultUoms = [];
    edittableState: QueryModel;
    tableState: QueryModel;
    originatorEv: any;
    pendingAllocationsPath = '/Resources/pending_allocations.csv';
    page = 1;
    pageSize = EnumVals.NoOfResults[0].value;
    isBooked = null;
    transportResponsibility = null;
    collectionSize = 1;
    sortReverse = true;
    defaultFilterStatus = 'Pending Requests';
    subTableWidth: number;
    searchForm: FormGroup;
    noRecord = false;
    showAllFields = false;
    sCols: string[] = [];
    querySubscription: Subscription;
    editquerySubscription: Subscription;
    showTransportPlan = false;
    showBookingSheet = false;
    showAllocationCalendar = false;
    showBookedTransport = false;
    showSchedulerReport = false;
    showUltimateSchedulerReport = false;
    filteredDepots: ApiPageResponse<DepotViewModel>;
    fieldsList: { name: string, colWidth: number, type: string }[];
    defaultSelectedCols: string[];
    defaultWidth: 8;
    importedAllocationRangeStart = 300000000;
    userPreferencesData: UserPreferencesVm = { depot: '', territory: '', requestFields: '', allocationFields: '', orderBookType: '', viewMode: '', allocationDate: '', pickUpFromDate: ''  };
    preferedDepot: DepotViewModel;
    preferedTerritory: TerritoryVm;
    allocationColumns: string[] = [];
    defaultTerritory: string;
    appointments: PrintAppointmentVm[] = [];
    emailAttachmentFileName: string;
    emailAttachmentFile: string;
    selectedPredicate = null;
    sortClicked = 0;
    isEditPermission = false;
    item = '';
    subscription: any;
    allocationTableViewForm: FormGroup;
    dataSource = new MatTableDataSource(this.allocations);
    userTableView: UserTableViewVm[];
    selectedUserTableView: UserTableViewVm;
    requestTableViews: UserTableViewVm[];
    displayedColumns: string[] = [];
    allocationDisplayedColumns: string[] = ['Allocation Number', 'Allocation Status', 'Allocated Date', 'Contract Ref', 'Pick-up From Date & Time',
        'Pick-up To Date & Time', 'Additional Costs'];
    columnList: string[];
    defaultView: UserTableViewVm[] = [
        {
            id: -1,
            table: UserPreferenceTableEnum.Allocation,
            userId: -1,
            isDeleted: false,
            isSelected: true,
            viewName: 'Default View',
            userTableViewConfigs: [
                { id: -1, columnName: 'Allocation Number', isSelected: true, index: 0 },
                { id: -1, columnName: 'Depot', isSelected: true, index: 1 },
                { id: -1, columnName: 'Request Date', isSelected: false, index: 2 },
                { id: -1, columnName: 'Allocated Date', isSelected: false, index: 3 },
                { id: -1, columnName: 'Allocation Status', isSelected: false, index: 4 },
                { id: -1, columnName: 'Allocated Weight', isSelected: true, index: 5 },
                { id: -1, columnName: 'Delivered Weight', isSelected: false, index: 6 },
                { id: -1, columnName: 'Contract Ref', isSelected: true, index: 7 },
                { id: -1, columnName: 'Grade', isSelected: true, index: 8 },
                { id: -1, columnName: 'Material Description', isSelected: false, index: 9 },
                { id: -1, columnName: 'Party', isSelected: true, index: 10 },
                { id: -1, columnName: 'Classification', isSelected: true, index: 11 },
                { id: -1, columnName: 'Ready Date', isSelected: false, index: 12 },
                { id: -1, columnName: 'Pick-up From Date & Time', isSelected: true, index: 13 },
                { id: -1, columnName: 'Pick-up To Date & Time', isSelected: true, index: 14 },
                { id: -1, columnName: 'Instructions', isSelected: true, index: 15 },
                { id: -1, columnName: 'Haulier', isSelected: true, index: 16 },
                { id: -1, columnName: 'Notes/Comments', isSelected: true, index: 17 },
                { id: -1, columnName: 'Container Type', isSelected: true, index: 18 },
                { id: -1, columnName: 'FCF Serve', isSelected: true, index: 19 },
                { id: -1, columnName: 'Missed Drop Off', isSelected: true, index: 20 },
                { id: -1, columnName: 'Apt Number', isSelected: true, index: 21 },
                { id: -1, columnName: 'Apt Date & Time', isSelected: true, index: 22 },
                { id: -1, columnName: 'Apt Date & Time To', isSelected: true, index: 23 },
                { id: -1, columnName: 'Ticket Date', isSelected: true, index: 24 },
                { id: -1, columnName: 'Ticket Number', isSelected: true, index: 25 },
                { id: -1, columnName: 'Additional Costs', isSelected: true, index: 26 },
                { id: -1, columnName: 'Dock Receipt', isSelected: false, index: 27 },
                { id: -1, columnName: 'Booking Requested', isSelected: false, index: 28 },
                { id: -1, columnName: 'Awaiting Appointment', isSelected: false, index: 29 },
                { id: -1, columnName: 'Pending Dispatch', isSelected: false, index: 30 },
                { id: -1, columnName: 'Processing Dispatched', isSelected: false, index: 31 },
                { id: -1, columnName: 'Dispatched', isSelected: false, index: 32 },
                { id: -1, columnName: 'Inspection Date', isSelected: false, index: 33 },
                { id: -1, columnName: 'Booking Status', isSelected: false, index: 34 },
                { id: -1, columnName: 'Transport Responsibility', isSelected: false, index: 35 },
            ]
        },
        {
            id: -2,
            table: UserPreferenceTableEnum.Allocation,
            userId: -1,
            isDeleted: false,
            isSelected: false,
            viewName: 'Depot Manager',
            userTableViewConfigs: [
                { id: -1, columnName: 'Allocated Date', isSelected: true, index: 0 },
                { id: -1, columnName: 'Contract Ref', isSelected: true, index: 1 },
                { id: -1, columnName: 'Grade', isSelected: true, index: 2 },
                { id: -1, columnName: 'Material Description', isSelected: true, index: 3 },
                { id: -1, columnName: 'Allocated Weight', isSelected: true, index: 4 },
                { id: -1, columnName: 'Party', isSelected: true, index: 5 },
                { id: -1, columnName: 'Pick-up From Date & Time', isSelected: true, index: 6 },
                { id: -1, columnName: 'Container Type', isSelected: true, index: 7 },
                { id: -1, columnName: 'Haulier', isSelected: true, index: 8 },
                { id: -1, columnName: 'Instructions', isSelected: true, index: 9 }            
            ]
        }
    ];

    private allocationNumberChangEvent: Subscription;
    contractHeaderStatusesSel: '';

    @ViewChild('spinner', { static: true }) spinner: SpinnerComponent;
    @ViewChildren('matRef') matRef: QueryList<MatSelect>;
    @ViewChild(MatSort) sort: MatSort;

    tableHeight = 800;
    pickupRange: QueryDateRange = { dateFrom: '', dateTo: '' };
    allocationRange: QueryDateRange = { dateFrom: '', dateTo: '' };
    allocationRangeType: string;
    pickupdateType: string;
    contractAllocationIds: any;
    allocationDatePreference = '';
    pickUpFromDatePreference = '';
    constructor(private allocationService: AllocationService,
        private initService: InitService,
        private uomsService: UomsService,
        private dialog: MatDialog,
        private sidenavService: SidenavService,
        private fb: FormBuilder,
        private allocateService: AllocateService,
        private depotsService: DepotService,
        private toastr: ToastrService,
        private emailService: EmailService,
        private adGroupService: ADGroupService,
        private route: ActivatedRoute,
        private appointmentService: AppointmentService,
        private eventCalendarService: CalendarCommonService,
        private userPreferenceService: UserPreferencesService,
        private clipboard: Clipboard,
        private router: Router) {
        const userPreferences = this.route.snapshot.data['userpreferencesData'];
        if (userPreferences !== undefined && userPreferences.length > 0)
            this.userPreferencesData = userPreferences[0];

        this.allocationTableViewForm = this.fb.group({
            allocationTableView: [''],
            columnsList: ['']
        });
        if (this.router.getCurrentNavigation()?.extras?.state !== undefined) {
            this.contractAllocationIds = this.router.getCurrentNavigation().extras.state.data;
        }
    }

    getDate(date: string) {
        if (date == null || date == undefined || date == '')
            return '';

        const split = date.split('-');

        return (new Date(Number(split[2]), (Number(split[1]) - 1), Number(split[0]))).toDateString();
    }

    ngOnInit() {
        const depot = this.route.snapshot.queryParamMap.get('depot');
        const grade = this.route.snapshot.queryParamMap.get('grade');
        const salesgrade = this.route.snapshot.queryParamMap.get('salesgrade');
        const pickupfromdate = this.route.snapshot.queryParamMap.get('pickupfromdate');
        const pickuptodate = this.route.snapshot.queryParamMap.get('pickuptodate');
        this.allocationRangeType = this.route.snapshot.queryParamMap.get('allocationRangeType');
        this.pickupdateType = this.route.snapshot.queryParamMap.get('pickupdateType');
        const allocationView = this.route.snapshot.queryParamMap.get('view');

        this.pickupRange = pickupfromdate != null || pickuptodate != null ?
            { dateFrom: this.getDate(pickupfromdate), dateTo: this.getDate(pickuptodate), }
            : null;

        const allocationdate = this.route.snapshot.queryParamMap.get('allocationdate');
        const allocationtodate = this.route.snapshot.queryParamMap.get('allocationtodate');

        this.allocationRange = allocationdate != null || allocationtodate != null ?
            { dateFrom: this.getDate(allocationdate), dateTo: this.getDate(allocationtodate), }
            : null;

        const contractRef = this.route.snapshot.queryParamMap.get('contractref');
        const gradeGroup = this.route.snapshot.queryParamMap.get('gradegroup');

        this.subscription = this.eventCalendarService.getNavChangeEmitter()
            .subscribe(item => this.selectedNavItem(item.toString()));

        this.fieldsList = ListOfColumnNames.fieldsListForAllocations;
        if (this.userPreferencesData.territory === '')
            this.defaultTerritory = null;
        else
            this.defaultTerritory = this.userPreferencesData.territory;

        this.preferedDepot = { depotNumber: this.userPreferencesData.depot, depotName: '', depotShortName: '', selected: false };
        if (this.userPreferencesData.allocationFields !== '')
            this.allocationColumns = JSON.parse(this.userPreferencesData.allocationFields);

        const deptNo = (this.preferedDepot.depotNumber === '') ? null : this.preferedDepot.depotNumber;

        this.defaultSelectedCols = ListOfColumnNames.defaultSelectedColsForAllocations;

        this.initService.initData$.subscribe(r =>
            this.pageModel = r
        );

        this.allocationService.getStatuses().subscribe(r => this.statusList = r);
        this.allocationService.getAllocationLifecycles().subscribe(l => {
            this.lifecycles = l;
            for (const lc of this.lifecycles) {
                const lifecyclePreviouslyAdded = this.fieldsList.filter(f => f.name == lc.name).length > 0;
                if (!lifecyclePreviouslyAdded) {
                    this.fieldsList.push({ name: lc.name, colWidth: this.defaultWidth, type: 'allocation' });
                }
            }
        });


        const approvedToSendQueryValue = this.route.snapshot.queryParamMap.get('approvedToSend');

        let approvedToSend: boolean = null;

        if (approvedToSendQueryValue) {
            try {
                approvedToSend = JSON.parse(approvedToSendQueryValue);
            } catch (error) {
                console.log(`Invalid approvedToSend query value ${approvedToSendQueryValue} encountered`);
            }
        }

        this.allocationsNoOfResults = EnumVals.NoOfResults;

        this.uomsService.getAll().subscribe(r => this.defaultUoms = r);

        this.allocationStatuses = EnumVals.ContractLineAllocationStatuses;
        this.allocationBookingStatuses = EnumVals.BookingStatuses;
        this.allocationTransportResponsibilities = EnumVals.TransportResponsibilities;
        this.contractHeaderStatuses = EnumVals.ContractHeaderStatuses;

        const openAllocationStatus = this.allocationStatuses.filter(rs => rs.desc === 'Open');
        const openContractHeaderStatus = this.contractHeaderStatuses.filter(rs => rs.desc === 'Open');

        const allocationRequestStatusFilter = (openAllocationStatus as IEnumItem[]).map((val) => val.value);
        const contractHeaderStatusesFilter = (openContractHeaderStatus as IEnumItem[]).map((val) => val.value);

        this.callServer({
            pagination: { number: this.pageSize, start: 0 },
            search: {
                predicateObject: {
                    grade: grade,
                    territory: this.defaultTerritory,
                    gradeDescription: null,
                    depot: depot != null ? depot : deptNo,
                    depotName: null,
                    description: null,
                    comment: null,
                    containerPackaging: null,
                    heap: null,
                    comments: null,
                    allocationNumber: this.contractAllocationIds != undefined ? this.contractAllocationIds: null,
                    contractRef: contractRef,
                    partyAccountNo: null,
                    partyName: null,
                    partySearchText: null,
                    requestedGrade: null,
                    requestedGradeDescription: null,
                    classification: null,
                    classifications: [],
                    haulierAccountNo: null,
                    haulierName: null,
                    haulierSearchText: null,
                    ticketNumber: null,
                    status: null,
                    gradeGroupName: gradeGroup,
                    heapDescription: null,
                    review: null,
                    salesGrade: salesgrade,
                    containerTypeName: null,
                    requestStatuses: [],
                    allocationStatuses: allocationRequestStatusFilter,
                    kpiTypes: [],
                    contractHeaderStatuses: contractHeaderStatusesFilter,
                    presetFilterIds: [],
                    dateRange: this.allocationRange,
                    pickupFromDateRange: this.pickupRange,
                    firstComeFirstServe: false,
                    awaitingDispatch: null,
                    lifecycleStatusId: 0,
                    includeDeleted: false,
                    route: null,
                    destination: null,
                    approvedToSend: approvedToSend,
                    isBooked: null,
                    transportResponsibility: null
                }
            },
            sort: { predicate: null, reverse: this.sortReverse }
        });
        this.assignDefaultColumns();
        this.allocationApprovedToSend = EnumVals.ContractLineAllocationApprovedToSend;

        this.allocationClassifications = EnumVals.ContractLineAllocationClassifications;
        this.kpiTypes = EnumVals.KpiTypes;

        const allocationApprovedToSendSelection = this.allocationApprovedToSend.filter(a => approvedToSend == null || a.key == Number(approvedToSend));

        this.searchForm = this.fb.group({
            depot: depot != null ? { depotNumber: depot, depotName: '', depotShortName: '', selected: false } : this.preferedDepot,
            heap: '',
            grade: grade != null ? grade : '',
            gradeGroupName: gradeGroup != null ? gradeGroup : '',
            containerPackaging: '',
            comment: '',
            salesGrade: salesgrade != null ? salesgrade : '',
            classifications: '',
            contractRef: contractRef != null ? contractRef : '',
            allocationNumber: this.contractAllocationIds != undefined ? this.contractAllocationIds: '',
            haulierSearchText: '',
            ticketNumber: '',
            partySearchText: '',
            dateRange: this.userPreferencesData.allocationDate ? this.dateChange(this.userPreferencesData.allocationDate) : this.allocationRange,
            pickupFromDateRange: this.userPreferencesData.pickUpFromDate ? this.dateChange(this.userPreferencesData.pickUpFromDate) :  this.pickupRange,
            includeDeleted: '',
            allocationStatuses: new FormControl(openAllocationStatus),
            kpiTypes: new FormGroup({}),
            contractHeaderStatuses: new FormControl(openContractHeaderStatus),
            noOfResults: this.pageSize,
            approvedToSend: new FormControl(allocationApprovedToSendSelection),
            isBooked: this.isBooked,
            transportResponsibility: this.transportResponsibility
        });
        this.allocationDatePreference = this.userPreferencesData.allocationDate ? this.userPreferencesData.allocationDate : '';
        this.pickUpFromDatePreference = this.userPreferencesData.pickUpFromDate ? this.userPreferencesData.pickUpFromDate : '';
        for (const s of EnumVals.KpiTypes) {
            const group = this.searchForm.controls.kpiTypes as FormGroup;
            group.addControl(s.desc, new FormControl(false));
        }

        this.adGroupService.getMyGroups().subscribe(g => {
            this.showBookingSheet = g.myGroups.includes('BookingSheet');
            this.showTransportPlan = g.myGroups.includes('TransportPlan');
            this.showBookedTransport = g.myGroups.includes('BookedTransport');
            this.showSchedulerReport = g.myGroups.includes('SchedulerReport');
            this.canAllocateRequests = g.myGroups.includes('AllocationsClerk');
            this.showUltimateSchedulerReport = g.myGroups.includes('UltimateScheduler');
            this.showAllocationCalendar = g.myGroups.includes('AllocationCalendar');
        });

        this.depot.valueChanges
            .pipe(
                debounceTime(500),
                startWith(null),
                switchMap(v => this.filterDepots(v)))
            .subscribe(d => this.filteredDepots = d);

        this.allocationTableViewForm.get('allocationTableView')
            .valueChanges
            .subscribe(value => this.displayedColumns = this.SetDisplayColumns(value));

        this.userPreferenceService
            .getUserTableView(UserPreferenceTableEnum.Allocation)
            .subscribe(view => {
                if (isNullOrUndefined(view) || view.length === 0) {
                    view = this.defaultView;
                }
                // check if Depot Manager view is exist
                if(view.length == 1 && view[0].viewName.toLowerCase() !== this.defaultView[1].viewName.toLowerCase())
                {
                    view.push(this.defaultView[1]);
                }
                if(!isNullOrUndefined(allocationView)){
                    for (let index = 0; index < view.length; index++) {
                        if(!isNullOrUndefined(view[index]) && view[index].viewName.toLowerCase() === allocationView.toLowerCase())
                        {
                            view[index].isSelected = true;
                            break;
                        }
                        else{
                            view[index].isSelected = false;
                        }
                    }
                }
                this.displayedColumns = this.MapDisplayedColumns(view);
            });

        this.calculateHeight();

    }

    compareAllocationStatus(rs1: any, rs2: any): boolean {
        if (rs1 === undefined || rs2 === undefined)
            return false;

        if (rs1.desc && rs2.desc && rs1.desc === rs2.desc)
            return true;
        else
            return false;
    }

    comparecontractHeaderStatus(rs1: any, rs2: any): boolean {
        if (rs1 === undefined || rs2 === undefined)
            return false;

        if (rs1.desc && rs2.desc && rs1.desc === rs2.desc)
            return true;
        else
            return false;
    }

    calculateHeight(fromSearch = false) {
        let searchHeight = 0;

        const intFrameHeight = window.innerHeight;

        if (fromSearch)
            searchHeight = window.innerHeight * 0.15;

        const calulatedHeight = intFrameHeight - 50 - 48 - 37 - 50 - searchHeight - 36 - 10; //the height of the inside screen - the top 3 sections (excluding the search - 10 for spacings
        this.tableHeight = calulatedHeight;
    }

    searchClicked() {
        this.showSearch = !this.showSearch;
        this.calculateHeight(this.showSearch);
    }

    SetDisplayColumns(selectedView: UserTableViewVm): string[] {
        if (!selectedView) {
            return this.fieldsList.map(f => f.name);
        }
        this.selectedUserTableView = selectedView;
        let cols = this.selectedUserTableView.userTableViewConfigs.filter(col => col.isSelected && col.columnName != 'Show All').sort((a, b) => {
            return a.index - b.index;
        }).map(x => x.columnName);

        cols = cols.filter(col => col != 'Approved To Send');
        cols.unshift('toggle');
        cols.push('History'); //This is static column
        this.sCols = cols;
        this.allocationColumns = cols;
        this.allocationDisplayedColumns = cols;
        return cols;
    }

    MapDisplayedColumns(view: UserTableViewVm[]): string[] {
        this.requestTableViews = view;
        this.selectedUserTableView = view.find(v => v.isSelected);
        if (this.selectedUserTableView) {
            this.allocationTableViewForm.patchValue({
                'allocationTableView': this.selectedUserTableView,
                'columnsList': this.selectedUserTableView.userTableViewConfigs.filter(f => f.isSelected).map(m => m.columnName)
            });
        }
        this.columnList = this.selectedUserTableView ? this.selectedUserTableView.userTableViewConfigs.map(config => config.columnName) : [];
        this.columnList.unshift('Show All');
        this.sCols.unshift('Show All');
        return this.SetDisplayColumns(this.selectedUserTableView);
    }

    AddColumnIntoDisplayList(columns: string[]) {
        if (columns.includes('Show All') && this.showAllColumns) {
            this.displayedColumns = this.selectedUserTableView.userTableViewConfigs.filter(col => col.columnName != 'Show All').sort((a, b) => {
                return a.index - b.index;
            }).map(x => x.columnName);

            this.displayedColumns.unshift('toggle');
            this.displayedColumns.push('History'); //This is static column

            this.allocationTableViewForm.patchValue({
                'columnsList': this.columnList
            });
        } else if (!columns.includes('Show All') && this.showAllColumns) {
            const cols = this.selectedUserTableView.userTableViewConfigs.filter(col => columns.includes(col.columnName)).sort((a, b) => {
                return a.index - b.index;
            }).map(x => x.columnName);

            this.allocationTableViewForm.patchValue({
                'columnsList': cols
            });

            cols.unshift('toggle');
            cols.push('History'); //This is static column

            this.displayedColumns = cols;
        } else {
            this.selectedUserTableView.userTableViewConfigs.forEach(config => {
                if (columns.includes(config.columnName)) {
                    config.isSelected = true;
                } else {
                    config.isSelected = false;
                }
            });

            const cols = this.selectedUserTableView.userTableViewConfigs.filter(col => col.isSelected).sort((a, b) => {
                return a.index - b.index;
            }).map(x => x.columnName);


            this.allocationTableViewForm.patchValue({
                'columnsList': cols
            });

            cols.unshift('toggle');
            cols.push('History'); //This is static column

            this.displayedColumns = cols;
        }
    }

    filterDepots(val: string) {
        return this.depotsService.searchDepots(val);
    }

    get depot() {
        return this.searchForm.get('depot');
    }

    displayDepot(depot?: DepotViewModel): string | undefined {
        let depotString = undefined;
        if (depot && depot.depotNumber && depot.depotNumber !== '' && depot.depotName !== '')
            depotString = depot.depotNumber + ' - ' + depot.depotName;
        else if (depot && depot.depotNumber && depot.depotNumber !== '' && depot.depotName === '')
            depotString = depot.depotNumber;
        return depotString;
    }

    ngOnDestroy(): void {
        this.isLoading = false;
        this.spinner.overlayRef.dispose();
        if (this.querySubscription != null && !this.querySubscription.closed) {
            this.querySubscription.unsubscribe();
        }

        this.subscription.unsubscribe();
    }

    get selectedAllocations() {
        const allocations = this.allocations.filter(a => a.isSelected && !this.isGrouped(a));
        this.groupedAllocations.filter(a => a.isSelected).forEach(g => {
            allocations.push(g);
        });
        return allocations;
    }

    sideNavShow(info: any) {
        this.sidenavService.next(info);
    }
    getPackagingSummary(item) {
        const containerSize = _.find(this.pageModel.containerSizes, (cs) => cs.id === item.ContainerSizeId);
        const packagingOption = _.find(this.pageModel.packageOptions, (po) => po.id === item.PackageOptionId);
        return JSON.stringify(containerSize.displayName + ' - ' + packagingOption.displayName);
    }

    callServer(tableState: QueryModel, reselectAllocations = false) {
        this.isLoading = true;

        if (reselectAllocations) { //snapshot the data that was selected by PK
            this.previouslySelectedAllocationIds = Array.from(new Set(this.selectedAllocations.map(s => s.contractLineAllocationId)));
        }

        if (tableState) {
            this.tableState = tableState;
            this.edittableState = tableState;
            this.tableState.pagination.number = isNullOrUndefined(this.searchForm) ? 25 : isNullOrUndefined(this.searchForm.get('noOfResults').value) ? 25 : this.searchForm.get('noOfResults').value;
            this.pageSize = this.tableState.pagination.number;
        }

        if (this.querySubscription != null && !this.querySubscription.closed) {
            this.querySubscription.unsubscribe();
        }

        this.querySubscription = this.allocationService.getAllocations(tableState).subscribe(r => {
            if (isNullOrUndefined(r)) {
                this.isLoading = false;
                return;
            }
            this.allocations = r.allocations.map(a => {
                const ext = a as AllocatedViewModelExtended;
                ext.isSelected = reselectAllocations && this.previouslySelectedAllocationIds.includes(ext.contractLineAllocationId);
                return ext;
            });

            this.allocationSubject.next(this.allocations);
            this.groupedAllocations = r.grouped.map(a => {
                const ext = a as AllocatedViewModelExtended;
                ext.isSelected = reselectAllocations && this.previouslySelectedAllocationIds.includes(ext.contractLineAllocationId);
                return ext;
            });
            this.collectionSize = r.recordCount;
            if (this.tableState.pagination.start === 1) {
                this.page = 1;
            }

            this.dataSource = new MatTableDataSource(this.allocations);
            this.dataSource.sort = this.sort;
            this.isLoading = false;
        }, err => {
            this.isLoading = false;
        });
        if (!(this.allocations.length > 0)) {
            this.noRecord = true;
        }
    }

    openMenu($mdOpenMenu, ev) {
        this.originatorEv = ev;
        $mdOpenMenu(ev);
    }

    sortByInfo(info: SortInfo) {
        if (info.active) {
            if (info.active == 'RequestedMovementDate') {
                if (this.selectedPredicate == info.active) {
                    this.sortClicked++;
                    if (this.sortClicked % 3 == 2) {
                        this.tableState.sort.predicate = null;

                        this.tableState.sort.reverse = info.direction != 'asc';
                        this.sortReverse = this.tableState.sort.reverse;
                        this.callServer(this.tableState);
                        return;
                    }
                    else {
                        this.tableState.sort.predicate = info.active;
                    }
                } else {
                    this.selectedPredicate = info.active;
                    this.sortClicked = 0;
                }
            }

            this.tableState.sort.predicate = info.active;
            this.tableState.sort.reverse = info.direction != 'asc';
            this.sortReverse = this.tableState.sort.reverse;
            this.callServer(this.tableState);
        }
    }

    newRequest() {
        const dialogRef = this.dialog.open(NewRequestDialogComponent, {
            maxHeight: '80%',
            maxWidth: '100%',
        });

        dialogRef.afterClosed().subscribe(r => {
            if (isUndefined(r)) { return; }
            this.callServer(this.tableState);
        });
    }

    showComments(item) {
        this.sideNavShow({ type: 'comments', item });
    }

    requestHistory(id: number) {
        const dialogRef = this.dialog.open(ChangeHistoryDialogComponent, {
            minWidth: '240px',
            maxWidth: '80%',
            maxHeight: '80%',
            data: { id, table: 'AllocationRequest' }
        });
    }

    showLoadTimes(item) {
        this.sideNavShow({ type: 'loadTimes', item });
    }

    showAttachments(item) {
        this.sideNavShow({ type: 'attachments', item });
        this.sidenavService.closed$.subscribe(a => {
            if (isUndefined(a)) { return; }
            if (a.closed) {
                this.callServer(this.tableState);
            }
        });
    }

    pageChanged(page: number) {
        this.page = page;
        this.tableState.pagination.start = page;
        this.callServer(this.tableState);
    }

    search(formVal: any) {
        const exFilters = this.pageModel.filterNames as FilterNamesExtended[];
        const pickupRange = this.searchForm.get('pickupFromDateRange').value;
        const allocationRange = this.searchForm.get('dateRange').value;

        if (pickupRange != null && this.pickupdateType === 'Range') {
            const pdf = new Date(pickupRange.dateFrom);
            if (pdf.toDateString() === 'Invalid Date') {
                this.pickupDateValidation(true, false);
                return;
            }
            const pdt = new Date(pickupRange.dateTo);
            if (pdt.toDateString() === 'Invalid Date') {
                this.pickupDateValidation(false, true);
                return;
            }
        }
        if (pickupRange == null && this.pickupdateType === 'Range') {
            this.pickupFromToDateValidation();
            return;
        }

        if (allocationRange != null && this.allocationRangeType === 'Range') {
            const adf = new Date(allocationRange.dateFrom);
            if (adf.toDateString() === 'Invalid Date') {
                this.allocationDateValidation(true, false);
                return;
            }
            const adt = new Date(allocationRange.dateTo);
            if (adt.toDateString() === 'Invalid Date') {
                this.allocationDateValidation(false, true);
                return;
            }
        }
        if (allocationRange == null && this.allocationRangeType === 'Range') {
            this.allocationFromToDateValidation();
            return;
        }

        let depot = formVal.depot;
        if (typeof (depot) !== 'string')
            depot = depot.depotNumber;


        let allocationRequestStatusFilter = [];
        if (!isNullOrUndefined(formVal.allocationStatuses) && formVal.allocationStatuses.length > 0)
            allocationRequestStatusFilter = (formVal.allocationStatuses as IEnumItem[]).map((val) => val.value);

        let contractHeaderStatusesFilter = [];
        if (!isNullOrUndefined(formVal.contractHeaderStatuses) && formVal.contractHeaderStatuses.length > 0)
            contractHeaderStatusesFilter = (formVal.contractHeaderStatuses as IEnumItem[]).map((val) => val.value);

        let approvedToSend: boolean = null;

        if (!isNullOrUndefined(formVal.approvedToSend) && formVal.approvedToSend.length > 0) {
            const approvedToSendValues = formVal.approvedToSend as IEnumItem[];

            if (approvedToSendValues.every( val => val === approvedToSendValues[0])) {
                approvedToSend = Boolean(approvedToSendValues[0].key).valueOf();
            }
        }

        const predicate: any = {
            territory: this.defaultTerritory,
            depot: depot,
            heap: formVal.heap,
            grade: formVal.grade,
            gradeGroupName: formVal.gradeGroupName,
            containerPackaging: formVal.containerPackaging,
            comment: formVal.comment,
            salesGrade: formVal.salesGrade,
            classifications: formVal.classifications,
            contractRef: formVal.contractRef,
            allocationNumber: formVal.allocationNumber,
            haulierSearchText: formVal.haulierSearchText,
            ticketNumber: formVal.ticketNumber,
            partySearchText: formVal.partySearchText,
            dateRange: formVal.dateRange,
            pickupFromDateRange: formVal.pickupFromDateRange,
            allocationStatuses: allocationRequestStatusFilter,
            kpiTypes: this.getTickedBoxes(formVal.kpiTypes)
                .map(k => EnumVals.KpiTypes.filter(r => r.desc === k)[0].value),
            contractHeaderStatuses: contractHeaderStatusesFilter,
            includeDeleted: formVal.includeDeleted,
            requestStatuses: [],
            approvedToSend: approvedToSend,
            isBooked: formVal.isBooked,
            transportResponsibility: formVal.transportResponsibility
        };
        this.tableState.search.predicateObject = predicate;
        this.tableState.search.predicateObject.presetFilterIds = exFilters.filter((a) => a.isSelected).map(a => a.id);
        this.tableState.pagination.start = 0;
        this.tableState.pagination.number = this.pageSize;
        this.tableState = Object.assign({}, this.tableState);
        this.callServer(this.tableState);
        this.clearSelected();
        this.updateCanEditSelected();
        this.updateCanSendEmails();
    }

    private pickupDateValidation(from: boolean, to: boolean) {
        if (from) {
            this.toastr.error('Enter valid Pick up From date.', 'Error');
        }
        if (to) {
            this.toastr.error('Enter valid Pick up To date.', 'Error');
        }
    }

    private allocationDateValidation(from: boolean, to: boolean) {
        if (from) {
            this.toastr.error('Enter valid Allocation From date.', 'Error');
        }
        if (to) {
            this.toastr.error('Enter valid Allocation To date.', 'Error');
        }
    }

    getTickedBoxes(boxes: any): string[] {
        const keyNames = Object.keys(boxes);
        return keyNames.filter(k => boxes[k]);
    }

    isDisabled(): boolean {
        return (!this.canEditSelected || this.selectedAllocations.length === 0);
    }

    isDeleted(item: AllocatedViewModelExtended): boolean {
        if (this.isGrouped(item)) {
            return this.isAllDeleted(item);
        } else {
            return !item.active;
        }
    }

    selectAllocation(item: AllocatedViewModelExtended, event: MouseEvent, header: boolean) {
        if (!event.ctrlKey && !event.shiftKey) {
            this.clearSelected();
        }
        let canSelect = item.active;

        if (header && this.isGrouped(item)) {
            canSelect = !this.isAllDeleted(item);
        }

        if (!canSelect) {
            this.toastr.warning('Allocation(s) can\'t be selected because it is deleted.', 'Warning');
            return;
        }
        if (event.shiftKey && this.lastSelectedIndex >= 0) {
            const newIndex = this.allocations.indexOf(item);
            this.selectRange(this.lastSelectedIndex, newIndex);
            item.isSelected = true;
        }
        else {
            item.isSelected = !item.isSelected;
            this.selectedIsAllocation = true;
            this.selectedIsRequest = false;
        }

        this.lastSelectedIndex = this.allocations.indexOf(item);
        if (header && item.isSelected && this.isGrouped(item)) {
            this.setGroupSelected(item.groupId);
        }
        this.updateCanEditSelected();
        this.updateCanSendEmails();
    }

    updateCanSendEmails() {
        this.canSendEmails = this.selectedAllocations
            .map(a => a.party)
            .filter((item, pos, ar) => ar.indexOf(item) === pos).length <= 1;
    }

    updateCanEditSelected() {
        if (isNullOrUndefined(this.selectedAllocations) || this.selectedAllocations.length === 0) {
            this.canEditSelected = false;
        } else {
            this.canEditSelected = this.selectedAllocations
                .map(s => s.contractLineAllocationId)
                .findIndex(allocationId => allocationId >= this.importedAllocationRangeStart) === -1;
        }
    }

    clearSelected() {
        for (const a of this.selectedAllocations) {
            a.isSelected = false;
        }
        for (const a of this.allocations.filter(a => a.isSelected && this.isGrouped(a))) {
            a.isSelected = false;
        }

        this.lastSelectedIndex = -1;
    }

    setGroupSelected(groupId: string) {
        for (const a of this.groupedAllocations.filter(g => g.groupId === groupId && g.active)) {
            a.isSelected = true;
        }
    }

    success(message: string, title: string) {
        this.callServer(this.tableState);
        this.toastr.success(message, title);
    }

    valueChange(a: AllocatedViewModelExtended, event) {
        if (event.checked) {
            a.firstComeFirstServe = true;
        } else {
            a.firstComeFirstServe = false;
        }
    }

    fcfsChanged(a: AllocatedViewModelExtended, event) {
        a.firstComeFirstServe = event.checked;

        this.callServerUpdateFcfs(a.contractLineAllocationId, a.firstComeFirstServe);
    }


    missedDropOffChanged(a: AllocatedViewModelExtended, event) {
        a.missedDropOff = event.checked;

        this.callServerUpdateMissedDropOff(a.contractLineAllocationId, a.missedDropOff);
    }

    callServerUpdateMissedDropOff(contractLineAllocationId: number, missedDropOff: boolean) {
        this.isLoading = true;
        const model: AllocationMissedDropOffUpdateModel =
        {
            missedDropOff: missedDropOff,
            contractLineAllocationId: contractLineAllocationId
        };

        this.querySubscription = this.allocationService.updateMissedDropOff(model).subscribe(r => {
            if (isNullOrUndefined(r)) {
                this.isLoading = false;
                return;
            }

            this.isLoading = false;
        }, err => {
            this.isLoading = false;
        });
    }

    callServerUpdateFcfs(contractLineAllocationId: number, fcfs: boolean) {
        this.isLoading = true;
        const model: AllocationFcfsUpdateModel =
        {
            fcfs: fcfs,
            contractLineAllocationId: contractLineAllocationId
        };

        this.querySubscription = this.allocationService.updateFcfs(model).subscribe(r => {
            if (isNullOrUndefined(r)) {
                this.isLoading = false;
                return;
            }

            this.isLoading = false;
        }, err => {
            this.isLoading = false;
        });
    }

    editAllocation() {
        if (this.selectedAllocations.filter(a => a.contractLineAllocationStatus === ContractLineAllocationStatuses.Used).length > 0) {
            this.dialog.open(AlertDialogComponent, {
                minWidth: '240px',
                maxWidth: '80%',
                maxHeight: '80%',
                data: {
                    ok: 'Ok',
                    aria: 'Cannot edit Used allocations',
                    title: 'Cannot edit Used allocations',
                    body: this.selectedAllocations.length > 1 ? 'The group of allocations selected includes a Used allocation, which cannot be edited. Please deselect the Used allocations and try again' : 'The selected allocation is Used and so cannot be edited.'
                }
            });
            return;
        }

        if (this.selectedAllocations.filter(a => a.ticketNumber > 0 && a.contractLineAllocationStatus == ContractLineAllocationStatuses.Open).length > 0) {
            this.dialog.open(AlertDialogComponent, {
                minWidth: '240px',
                maxWidth: '80%',
                maxHeight: '80%',
                data: {
                    ok: 'Ok',
                    aria: 'Cannot edit Open allocations linked to ticket',
                    title: 'Cannot edit Open allocations linked to ticket',
                    body: this.selectedAllocations.length > 1 ? 'The group of allocations selected includes an Open allocation linked to a ticket, which cannot be edited. Please deselect and try again' : 'The selected allocation is Open and linked to a ticket and so cannot be edited.'
                }
            });
            return;
        }

        if (this.selectedAllocations.length === 1) {
            const selectedAllocation = this.selectedAllocations[0];
            this.allocateService.getAllocation(selectedAllocation.allocationNumber)
                .subscribe(m => {
                    m.firstComeFirstServe = selectedAllocation.firstComeFirstServe;
                    const dialogRef = this.dialog.open(EditAllocatedRequestDialogComponent, {
                        minWidth: '240px',
                        maxWidth: '85%',
                        maxHeight: '80%',
                        data: { allocate: m },
                        disableClose: true,
                    });
                    dialogRef.afterClosed().subscribe(() => { this.callServer(this.tableState); });
                });
        } else {
            const dialogRef = this.dialog.open(EditMultiAllocatedRequestDialogComponent, {
                minWidth: '240px',
                maxWidth: '85%',
                maxHeight: '80%',
                data: { allocations: this.selectedAllocations },
                disableClose: true,
            });
            dialogRef.afterClosed().subscribe(() => { this.callServer(this.tableState, true); });
        }
    }

    deleteAllocations() {
        if (this.selectedAllocations.filter(a => a.ticketNumber > 0 && a.contractLineAllocationStatus == ContractLineAllocationStatuses.Open).length > 0) {
            this.dialog.open(AlertDialogComponent, {
                minWidth: '240px',
                maxWidth: '80%',
                maxHeight: '80%',
                data: {
                    ok: 'Ok',
                    aria: 'Cannot delete Open allocations linked to ticket',
                    title: 'Cannot delete Open allocations linked to ticket',
                    body: this.selectedAllocations.length > 1 ? 'The group of allocations selected includes an Open allocation linked to a ticket, which cannot be deleted. Please deselect and try again' : 'The selected allocation is Open and linked to a ticket and so cannot be deleted.'
                }
            });
            return;
        }

        let bodyMessage = 'Requests will not be restored from bulk deletion - Do you want to continue?';

        if (this.selectedAllocations.length >= 1) {
            const contractLineAllocationIds = this.selectedAllocations.map(a => a.allocationNumber);
            if (contractLineAllocationIds.length === 1) {
                const r = this.selectedAllocations[0];

                this.allocateService.hasAllocationRequest(r.allocationNumber)
                    .subscribe(hasRequest => {
                        if (hasRequest) {
                            bodyMessage = `This will restore the request with ${r.allocatedWeight}${r.uomCode} and 1 load. `;
                            bodyMessage += 'Do you want to restore the request ?';
                        } else {
                            bodyMessage = '';
                        }
                    });

            }

            const dialogRef = this.dialog.open(ActionConfirmDialogComponent, {
                minWidth: '240px',
                maxWidth: '80%',
                maxHeight: '80%',
                data: {
                    type: 'allocation',
                    model: contractLineAllocationIds,
                    action: 'delete'
                },
                disableClose: true,
            });

            dialogRef.afterClosed().subscribe(contractLineAllocationIds => {
                if (isUndefined(contractLineAllocationIds)) { return; }

                let requestRestore = false;
                if (bodyMessage !== '') {
                    const dialogConfirmRef = this.dialog.open(AlertDialogComponent, {
                        minWidth: '240px',
                        maxWidth: '80%',
                        maxHeight: '80%',
                        data: {
                            ok: 'Yes',
                            cancel: 'No',
                            aria: 'Deleting Allocation(s)',
                            title: 'Deleting Allocation(s)',
                            body: bodyMessage,
                            model: 'confirm'
                        }
                    });

                    dialogConfirmRef.afterClosed().subscribe(s => {
                        if (!isUndefined(s) && s === 'ok') {
                            if (contractLineAllocationIds.length === 1) {
                                requestRestore = true;
                            }
                        } else if (contractLineAllocationIds.length > 1) {
                            return;
                        }
                        this.delete(contractLineAllocationIds, requestRestore);
                    });
                } else {
                    this.delete(contractLineAllocationIds, requestRestore);
                }
            });
        }
    }

    delete(contractLineAllocationIds: number[], restoreRequest: boolean) {
        this.allocateService.deleteAllocations(restoreRequest, contractLineAllocationIds).subscribe(a => {
            this.success('Allocation successfully deleted', 'Delete Request');
        },
        err => {
            if (err.status === 200) {
                this.success('Allocation successfully deleted', 'Delete Request');
            } else {
                this.dialog.open(AlertDialogComponent, {
                    minWidth: '240px',
                    maxWidth: '80%',
                    maxHeight: '80%',
                    data: {
                        ok: 'Ok',
                        aria: 'Delete Failed',
                        title: 'Delete Failed',
                        body: 'Something went wrong and the allocation could not be deleted. Please try again later.'
                    }
                });
            }
        });
    }

    printAppointments() {
        if (this.selectedAllocations.length > 0) {
            window.open('print-appointment/'
                + this.selectedAllocations.map(s => s.contractLineAllocationId).join(','));
        }
    }

    exportToExcel() {
        const query = this.getTableStateAsQuery(this.tableState);
        this.allocationService
            .exportAllocationsAndSave(
                'Allocations.xlsx',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', query)
            .subscribe();
    }

    ultimateSchedulerExport() {
        const query = this.getTableStateAsQuery(this.tableState);
        this.allocationService.ultimateSchedulerReportExport(
            'Scheduler-US.xlsx',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', query)
            .subscribe();
    }

    allocatedDateExport() {
        this.schedulerExport(SchedulerReportType.AllocatedDate);
    }

    pickUpDateExport() {
        this.schedulerExport(SchedulerReportType.MovementDate);
    }

    getTableStateAsQuery(tableState: QueryModel, notifyWarning = true): any {
        const query = JSON.parse(JSON.stringify(tableState)); //deep copy so our original tableState is not changed
        if (query.search.predicateObject.includeDeleted) {
            if (notifyWarning) {
                this.toastr.warning('Deleted Allocations will not be exported.', 'Warning');
            }
            query.search.predicateObject.includeDeleted = false;
        }
        return query;
    }

    schedulerExport(type: SchedulerReportType) {
        const query = this.getTableStateAsQuery(this.tableState);
        const date = new Date();
        const filename = 'Allocations' + date.toLocaleDateString() + '.xlsx';
        this.allocationService
            .schedulerReportExport(
                filename,
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', query, type)
            .subscribe();
    }

    filterTableByContainerPackaging(selectedOption) {
        this.tableState.search.predicateObject.containerPackaging = selectedOption;
        this.callServer(this.tableState);
    }

    filterTableByStatus(selectedOption) {
        this.tableState.search.predicateObject.status = selectedOption;
        this.callServer(this.tableState);
    }
    filterTableByReview(selectedOption) {
        this.tableState.search.predicateObject.review = selectedOption;
        this.callServer(this.tableState);
    }
    updateColumns(value: any) {

        if (!isNullOrUndefined(value)) {
            if (value.value.length !== this.columnList.length && !this.showAllFields) {
                value.value = value.value.filter(x => x != 'Show All');
            }
            if (value.value.length === this.columnList.length - 1 && !this.showAllFields) {
                value.value.push('Show All');
            }
            const val = value.value;

            this.AddColumnIntoDisplayList(val);
            if (this.showAllFields && val.includes('Show All')) {
                this.sCols = this.fieldsList.map(f => f.name);
            } else if (this.showAllFields && !val.includes('Show All')) {
                this.sCols = this.defaultSelectedCols;
            } else if (val.length > 0) {
                this.sCols = val;
            } else {
                this.sCols = this.defaultSelectedCols;
            }
        }
    }
    showAllColumns(event: any) {
        if (event.source.value === 'Show All') {
            this.showAllFields = true;
        } else {
            this.showAllFields = false;
        }
    }

    filterSelection(event: any, filterName: FilterNamesExtended) {
        if (event.source.selected) {
            filterName.isSelected = true;
        } else {
            filterName.isSelected = false;
        }
    }

    setAllocationSelection(event: any, filterName: FilterNamesExtended) {
        this.searchForm.value.allocationStatuses[filterName['value']] = !this.searchForm.value.allocationStatuses[filterName['value']];
    }

    setApprovedToSendSelection(event: any, filterName: FilterNamesExtended) {
        this.searchForm.value.approvedToSend[filterName['value']] = !this.searchForm.value.approvedToSend[filterName['value']];
    }

    setKpiTypeSelection(event: any, filterName: FilterNamesExtended) {
        this.searchForm.value.kpiTypes[filterName['desc']] = !this.searchForm.value.kpiTypes[filterName['desc']];
    }

    setContractHeaderSelection(event: any, filterName: FilterNamesExtended) {
        this.searchForm.value.contractHeaderStatuses[filterName['value']] = !this.searchForm.value.contractHeaderStatuses[filterName['value']];
    }
    assignDefaultColumns() {
        if (this.allocationColumns.length > 0)
            this.sCols = this.allocationColumns;
        else
            this.sCols = ListOfColumnNames.defaultSelectedColsForAllocations;

    }

    toggleAllocations(item: PendingAndAllocatedViewModelExtended) {
        item.showingAllocations = !item.showingAllocations;
    }
    parseAllocationStatus(key: number): string {
        return EnumVals.ContractLineAllocationStatuses.filter(s => s.key === key)[0].desc;
    }
    parseContractHeaderStatus(key: number): string {
        return EnumVals.ContractHeaderStatuses.filter(s => s.key === key)[0].desc;
    }
    lifecyclesToShow() {
        if (this.lifecycles && this.lifecycles.length > 0) {
            return this.lifecycles.filter(lc => this.sCols.indexOf(lc.name) > -1);
        }
    }

    getLifecycle(lifecycleId: number, allocation: AllocatedViewModelExtended) {
        if (!allocation || !allocation.lifecycleHistory || allocation.lifecycleHistory.length === 0) { return; }
        const lcs = allocation.lifecycleHistory.filter(h => h.lifecycle === lifecycleId);
        if (lcs.length === 0) { return null; }
        const lc = lcs[0];
        if (isNullOrUndefined(lc.lifecycleData)) {
            const pipe = new MomentLocaleDateTimePipe();
            const fDate = pipe.transform(lc.lifecycleDate);
            return fDate;
        }
        return lc.lifecycleData;
    }

    setLifecycle(lifecycle: ContractLineAllocationLifecycle) {
        const allocationIds = this.selectedAllocations.map(a => a.allocationNumber);
        const dialogRef = lifecycle.hasData ?
            this.dialog.open(SingleDataCaptureDialogComponent, {
                minWidth: '240px',
                maxWidth: '80%',
                maxHeight: '80%',
                data: {
                    ok: 'Ok',
                    aria: `Set ${lifecycle.name}`,
                    title: `Set ${lifecycle.name}`,
                    name: lifecycle.name,
                    body: `Set ${lifecycle.name} for allocation(s): ${allocationIds.join(', ')}`
                }
            }) :
            this.dialog.open(AlertDialogComponent, {
                minWidth: '240px',
                maxWidth: '80%',
                maxHeight: '80%',
                data: {
                    ok: 'Ok',
                    aria: 'Set Lifecycle',
                    title: 'Set Lifecycle',
                    body: `Set lifecycle to ${lifecycle.name} for allocation(s): ${allocationIds.join(', ')}`
                }
            });

        dialogRef.afterClosed().subscribe(r => {

            if (isUndefined(r)) { return; }
            const req: LifecycleRequest = {
                allocationIds,
                lifecycle: lifecycle.contractLineAllocationLifecycleId,
                data: r === 'ok' ? null : r
            };
            this.allocationService.setAllocationLifecycle(req).subscribe(a => {
                this.success('Lifecycle set for allocation(s)', 'Set Lifecycle');
            },
            err => {
                if (err.status === 200) {
                    this.success('Lifecycle set for allocation(s)', 'Set Lifecycle');
                } else {
                    this.dialog.open(AlertDialogComponent, {
                        minWidth: '240px',
                        maxWidth: '80%',
                        maxHeight: '80%',
                        data: {
                            ok: 'Ok',
                            aria: 'Set Lifecycle',
                            title: 'Set Lifecycle',
                            body: 'Something went wrong and the lifecycle could not be set on the allocation. Please try again later.'
                        }
                    });
                }
            });
        });
    }

    setBookingStatus(isBooked: boolean) {
        const allocationIds = this.selectedAllocations.map(a => a.allocationNumber);
        const bookingStatusText = isBooked ? 'Booked' : 'Pending';
        const dialogRef = 
            this.dialog.open(SingleDataCaptureDialogComponent, {
                minWidth: '240px',
                maxWidth: '80%',
                maxHeight: '80%',
                data: {
                    ok: 'Ok',
                    aria: `Set ${bookingStatusText}`,
                    title: `Set ${bookingStatusText}`,
                    name: bookingStatusText,
                    body: `Set ${bookingStatusText} for allocation(s): ${allocationIds.join(', ')}`
                }
            });

        dialogRef.afterClosed().subscribe(r => {

            if (isUndefined(r)) { return; }
            const req: BookingStatusRequest = {
                allocationIds,
                isBooked: isBooked
            };
            this.allocationService.setAllocationBookingStatus(req).subscribe(a => {
                this.success('Booking Status set for allocation(s)', 'Set Booking Status');
            },
            err => {
                if (err.status === 200) {
                    this.success('Booking Status set for allocation(s)', 'Set Booking Status');
                } else {
                    this.dialog.open(AlertDialogComponent, {
                        minWidth: '240px',
                        maxWidth: '80%',
                        maxHeight: '80%',
                        data: {
                            ok: 'Ok',
                            aria: 'Set Booking Status',
                            title: 'Set Booking Status',
                            body: 'Something went wrong and the booking status could not be set on the allocation. Please try again later.'
                        }
                    });
                }
            });
        });
    }

    showHistory(id: number) {
        const dialogRef = this.dialog.open(ChangeHistoryDialogComponent, {
            minWidth: '240px',
            maxWidth: '80%',
            maxHeight: '80%',
            data: { id, table: 'ContractLineAllocation' }
        });
    }

    sendDeliveryRequestEmail() {
        if (this.selectedAllocations.every(a => a.party === this.selectedAllocations[0].party)) {
            this.emailService.deliveryAppointmentRequest(this.selectedAllocations)
                .subscribe(i => {
                    this.sendEmailDialog(i.fromEmail, i.toEmails, i.message, 'Allocations', false);
                });
        }
    }

    sendLogisticsEmail() {
        if (this.selectedAllocations.every(a => a.party === this.selectedAllocations[0].party)) {
            this.emailService.logistics(this.selectedAllocations)
                .subscribe(i => {
                    this.sendEmailDialog(i.fromEmail, i.toEmails, i.message, 'Logistics', true);
                });
        }
    }

    private sendEmailDialog(fromEmail: string, toEmails: PartyEmail[], message: string, subject: string, attachments: boolean) {

        if (this.selectedAllocations.length > 0) {
            this.selectedAllocations[0].contractLineAllocationId;
            const contractLineAllocationIds = this.selectedAllocations[0].contractLineAllocationId.toString().replace(/, +/g, ',').split(',').map(Number);

            this.appointmentService.getAppointmentsToPrint(contractLineAllocationIds)
                .subscribe(r => {
                    this.appointments = r;
                    Promise.all(this.appointments)
                        .then(() => { if (attachments) { setTimeout(() => this.getAppointmentAsPDF(this.appointments)); } })
                        .then(() => {
                            setTimeout(() => {
                                this.dialog.open(SendMailDialogComponent, {
                                    minWidth: '900px',
                                    maxWidth: '80%',
                                    maxHeight: '80%',
                                    data: {
                                        from: fromEmail,
                                        to: toEmails,
                                        message,
                                        subject,
                                        filename: attachments ? this.emailAttachmentFileName : null,
                                        attachment: attachments ? this.emailAttachmentFile : null
                                    },
                                    disableClose: true,
                                });
                            }, 500);
                        });
                });
        }
    }

    getAppointmentAsPDF(appointments: PrintAppointmentVm[]) {
        const doc = new jsPDF('p', 'pt');
        let filename = '';
        let pageCount = 0;

        if (appointments.length > 0) {
            for (const appointment of appointments) {
                pageCount++;
                const requestedMovementDate = appointment.requestedMovementDate === null ? '' : formatDate(new Date(appointment.requestedMovementDate), 'MMM dd, yyyy hh:mm a', 'en-US', '');
                const requestedMovementDateEnd = appointment.requestedMovementDateEnd === null ? '' : formatDate(new Date(appointment.requestedMovementDateEnd), 'MMM dd, yyyy hh:mm a', 'en-US', '');
                const startDate = appointment.startDate === null ? '' : formatDate(new Date(appointment.startDate), 'MMM dd, yyyy hh:mm a', 'en-US', '');
                const endDate = appointment.endDate === null ? '' : formatDate(new Date(appointment.endDate), 'MMM dd, yyyy hh:mm a', 'en-US', '');
                const issuedDate = appointment.issuedDate === null ? '' : formatDate(new Date(appointment.issuedDate), 'MM/dd/yyyy', 'en-US', '');
                const printeDateTime = appointment.printeDateTime === null ? '' : formatDate(new Date(appointment.printeDateTime), 'MMM dd, yyyy hh:mm a', 'en-US', '');
                const address1 = (appointment.partyAddress.address1 === null || appointment.partyAddress.address1 === '') ? '' : appointment.partyAddress.address1 + '\n';
                const address2 = (appointment.partyAddress.address2 === null || appointment.partyAddress.address2 === '') ? '' : appointment.partyAddress.address2 + '\n';
                const address3 = (appointment.partyAddress.address3 === null || appointment.partyAddress.address3 === '') ? '' : appointment.partyAddress.address3 + '\n';
                const address4 = (appointment.partyAddress.address4 === null || appointment.partyAddress.address4 === '') ? '' : appointment.partyAddress.address4;
                const address5 = (appointment.partyAddress.address5 === null || appointment.partyAddress.address5 === '') ? '' : appointment.partyAddress.address5;
                const postalCode = (appointment.partyAddress.postalCode === null || appointment.partyAddress.postalCode === '') ? '' : appointment.partyAddress.postalCode;
                const address6 = address4 + ' ' + address5 + ' ' + postalCode;
                const deliveryNumber = appointment.deliveryNumber === null ? '' : appointment.deliveryNumber;
                const partyName = appointment.partyName === null ? '' : appointment.partyName;
                const aptNumber = appointment.aptNumber === null ? '' : appointment.aptNumber;
                const contactFax = appointment.contactFax === null ? '' : appointment.contactFax;
                const contactTelNo = appointment.contactTelNo === null ? '' : appointment.contactTelNo;
                const fromCompany = appointment.fromCompany === null ? '' : appointment.fromCompany;
                const haulierName = appointment.haulierName === null ? '' : appointment.haulierName;
                const customerContractRef = appointment.customerContractRef === null ? '' : appointment.customerContractRef;
                const contractRef = appointment.contractRef === null ? '' : appointment.contractRef;
                const gradeDesc = appointment.gradeDesc === null ? '' : appointment.gradeDesc;
                const agreedHaulageRate = appointment.agreedHaulageRate === null ? '' : appointment.agreedHaulageRate.toString();
                const instructions = appointment.instructions === null ? '' : appointment.instructions;
                const issuedBy = appointment.issuedBy === null ? '' : appointment.issuedBy;
                const contractLineAllocationId = appointment.contractLineAllocationId === null ? '' : appointment.contractLineAllocationId;

                const fromCompanyAddress1 = (appointment.fromCompanyAddress.address1 === null ||
                    appointment.fromCompanyAddress.address1 === '') ? '' : appointment.fromCompanyAddress.address1;
                const fromCompanyAddress2 = (appointment.fromCompanyAddress.address2 === null ||
                    appointment.fromCompanyAddress.address2 === '') ? '' : appointment.fromCompanyAddress.address2;
                const fromCompanyAddress3 = (appointment.fromCompanyAddress.address3 === null ||
                    appointment.fromCompanyAddress.address3 === '') ? '' : appointment.fromCompanyAddress.address3;
                const fromCompanyAddress4 = (appointment.fromCompanyAddress.address4 === null ||
                    appointment.fromCompanyAddress.address4 === '') ? '' : appointment.fromCompanyAddress.address4;
                const fromCompanyAddress5 = (appointment.fromCompanyAddress.address5 === null ||
                    appointment.fromCompanyAddress.address5 === '') ? '' : appointment.fromCompanyAddress.address5;
                const fromCompanyAddress6 = (appointment.fromCompanyAddress.postalCode === null ||
                    appointment.fromCompanyAddress.postalCode === '') ? '' : appointment.fromCompanyAddress.postalCode;

                const deliveryAddress1 = (appointment.deliveryAddress.address1 === null ||
                    appointment.deliveryAddress.address1 === '') ? '' : appointment.deliveryAddress.address1;
                const deliveryAddres2 = (appointment.deliveryAddress.address2 === null ||
                    appointment.deliveryAddress.address2 === '') ? '' : appointment.deliveryAddress.address2;
                const deliveryAddres3 = (appointment.deliveryAddress.address3 === null ||
                    appointment.deliveryAddress.address3 === '') ? '' : appointment.deliveryAddress.address3;
                const deliveryAddres4 = (appointment.deliveryAddress.address4 === null ||
                    appointment.deliveryAddress.address4 === '') ? '' : appointment.deliveryAddress.address4;
                const deliveryAddres5 = (appointment.deliveryAddress.address5 === null ||
                    appointment.deliveryAddress.address5 === '') ? '' : appointment.deliveryAddress.address5;
                const deliveryAddres6 = (appointment.deliveryAddress.postalCode === null ||
                    appointment.deliveryAddress.postalCode === '') ? '' : appointment.deliveryAddress.postalCode;

                let fromCompanyAddress7 = fromCompanyAddress2 + ' ' + fromCompanyAddress3;
                const fromCompanyAddress8 = fromCompanyAddress4 + ' ' + fromCompanyAddress5 + ' ' + fromCompanyAddress6;

                let deliveryAddres7 = deliveryAddres2 + ' ' + deliveryAddres3;
                const deliveryAddres8 = deliveryAddres4 + ' ' + deliveryAddres5 + ' ' + deliveryAddres6;

                if (fromCompanyAddress7 === ' ')
                    fromCompanyAddress7 = '';
                else
                    fromCompanyAddress7 = fromCompanyAddress7.trim() + '\n';

                if (deliveryAddres7 === ' ')
                    deliveryAddres7 = '';
                else
                    deliveryAddres7 = deliveryAddres7.trim() + '\n';

                const section1 = 'Appointment No\n\n\nAppt Issued To\n\n\n\n\nCust Ref #1\n\nCust Ref #2\n\nHaulier';
                let section2 = contractLineAllocationId + '\n\n' + partyName + '\n'
                    + address1 + address2 + address3
                    + address6.trim() + '\n\n';
                const section3 = '\nCompany\n\n\\Address\n\n\n\nPhone\n\nFax\n\nAppointment Ref#\n\nExpected Start Date\n\nExpected End Date\n';
                let section4 = '\n' + fromCompany + '\n\n' + fromCompanyAddress1 + '\n'
                    + fromCompanyAddress7
                    + fromCompanyAddress8.trim() + '\n\n';
                let section5 = '\n' + partyName + '\n\n' + deliveryAddress1 + '\n'
                    + deliveryAddres7
                    + deliveryAddres8.trim() + '\n\n';

                if (pageCount > 1)
                    doc.addPage();

                doc.setTextColor(100);
                doc.setFontType('bold');
                doc.setFontSize(9);
                doc.text('Appointment Confirmation', 40, 30,);
                doc.autoTable({
                    head: [['Appointment Information']],
                    styles: {
                        halign: 'center',
                        width: 20
                    }
                });

                doc.setTextColor(100);
                doc.setFontType('normal');
                doc.setFontSize(10);

                if (address2 === '')
                    section2 = section2 + '\n';
                if (address3 === '')
                    section2 = section2 + '\n';

                section2 = section2 + contractRef + '\n\n'
                    + customerContractRef + '\n\n'
                    + haulierName + '\n';

                if (fromCompanyAddress7 === '')
                    section4 = section4 + '\n';

                section4 = section4 + contactTelNo + '\n\n'
                    + contactFax + '\n\n'
                    + aptNumber + '\n\n'
                    + requestedMovementDate + '\n\n'
                    + requestedMovementDateEnd + '\n\n';

                if (deliveryAddres7 === '')
                    section5 = section5 + '\n';

                section5 = section5 + '\n\n\n\n'
                    + deliveryNumber + '\n\n'
                    + startDate + '\n\n'
                    + endDate + '\n\n';

                //Row 1
                doc.cell(40, 65, 125, 155, section1, 1, '');
                doc.cell(80, 65, 390, 155, section2, 1);

                //Row 2
                doc.cell(40, 250, 125, 25, ' ', 2, '');
                doc.cell(80, 250, 200, 25, '\nFrom', 2);
                doc.cell(120, 250, 190, 25, '\nTo', 2);

                //Row 3
                doc.cell(40, 275, 125, 200, section3, 3);
                doc.cell(80, 275, 200, 200, section4, 3);
                doc.cell(120, 275, 190, 200, section5, 3);

                //Row 4
                doc.setFontType('bold');
                doc.text(40, 460, 'Item Information');

                //Row 5
                doc.setFontType('normal');
                doc.text(40, 475, 'Item');
                doc.text(170, 475, 'Packaging');
                doc.text(370, 475, 'Weight');

                //Row 6
                doc.text(40, 490, gradeDesc);

                //Row 7
                doc.setFontType('bold');
                doc.text(40, 510, 'Haulage Rate:');
                doc.setFontType('normal');
                doc.text(170, 510, agreedHaulageRate);

                //Row 8
                doc.setFontType('bold');
                doc.text(40, 530, 'Instructions:');
                doc.setFontType('normal');
                const splitInstructions = doc.splitTextToSize(instructions, 400);
                doc.text(110, 530, splitInstructions);

                //Row 9
                doc.text(40, 800, 'Issued By:');
                doc.text(90, 800, issuedBy);
                doc.text(220, 800, 'Issued Date:');
                doc.text(280, 800, issuedDate);
                doc.text(380, 800, 'Printed:');
                doc.text(420, 800, printeDateTime);
            }

            if (pageCount == 1)
                filename = appointments[0].contractLineAllocationId.toString();
            else
                filename = 'Multiple_Appointment';

            this.emailAttachmentFileName = 'Appointment_' + formatDate(new Date(), 'yyyy/MM/dd', 'en') + '_' + filename + '.pdf';

            const file = doc.output('blob');
            const reader = new FileReader();

            reader.readAsDataURL(file);
            // I'm not 100% sure what this alias does later on so added the following disable to ignore the issue
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const that = this;

            reader.onload = function () {
                that.emailAttachmentFile = reader.result.toString().split(',')[1];
            };
        }
    }

    printAppointment(contractLineAllocationId: number) {
        window.open('print-appointment/' + contractLineAllocationId);
    }

    clearFilter(formVal: any) {
        this.matRef.forEach(select => {
            select.options.forEach((data: MatOption) => data.deselect());
        });

        this.searchForm.reset({
            depot: '',
            noOfResults: EnumVals.NoOfResults[0].value,
            isBooked: null
        });
        this.contractHeaderStatusesSel = '';
        formVal.includeDeleted = null;
        formVal.isBooked = null;
        formVal.transportResponsibility = null;
        this.search(formVal);
    }

    selectAll() {
        this.allocations.forEach(allocation => {
            this.selectAllocationOrGroup(allocation);
        });
        this.updateCanEditSelected();
    }

    selectRange(startIndex: number, endIndex: number) {
        const reverse = startIndex > endIndex;
        if (reverse) {
            for (let index = endIndex; index < startIndex; index++) {
                const allocation = this.allocations[index];
                this.selectAllocationOrGroup(allocation);
            }
        }
        else {
            for (let index = startIndex; index < endIndex; index++) {
                const allocation = this.allocations[index];
                this.selectAllocationOrGroup(allocation);
            }
        }
        this.updateCanEditSelected();
        document.getSelection().removeAllRanges(); //deselect highlighted text
    }

    selectAllocationOrGroup(item: AllocatedViewModelExtended) {
        if (!this.isDeleted(item))
            item.isSelected = true;
        if (this.isGrouped(item))
            this.setGroupSelected(item.groupId);
    }

    toggleGroup(item: AllocatedViewModelExtended) {
        item.showingGroup = !item.showingGroup;
    }

    getGroupAllocations(item: AllocatedViewModelExtended): AllocatedViewModelExtended[] {
        return this.groupedAllocations.filter(g => g.groupId === item.groupId);
    }

    isGrouped(item: AllocatedViewModelExtended): boolean {
        const isGrouped = !isNullOrUndefined(item.groupId) && item.groupId !== '';
        return isGrouped;
    }

    isAllDeleted(item: AllocatedViewModelExtended): boolean {
        return !(this.groupedAllocations.filter(g => g.groupId === item.groupId
            && g.active).length > 0);
    }

    selectedNavItem(item: string) {
        this.isLoading = true;
        if (this.isEditPermission)
            this.allocateService.getAllocation(Number(item))
                .subscribe(m => {
                    m.firstComeFirstServe = true;
                    const dialogRef = this.dialog.open(EditAllocatedRequestDialogComponent, {
                        minWidth: '240px',
                        maxWidth: '85%',
                        maxHeight: '80%',
                        data: { allocate: m }
                    });
                    this.isLoading = false;
                    dialogRef.afterClosed().subscribe(r => { this.callServer(this.tableState); });
                }, error => { this.isLoading = false; });
        else {
            this.allocateService.getAllocation(Number(item))
                .subscribe(m => {
                    m.firstComeFirstServe = true;
                    const dialogRef = this.dialog.open(ViewAllocatedRequestDialogComponent, {
                        minWidth: '620px',
                        maxWidth: '85%',
                        maxHeight: '80%',
                        data: { allocate: m }
                    });
                    this.isLoading = false;
                }, error => { this.isLoading = false; });

        }
    }

    calendaredit(edittableState: QueryModel) {
        if (this.editquerySubscription != null && !this.editquerySubscription.closed) {
            this.editquerySubscription.unsubscribe();
        }
        this.querySubscription = this.allocationService.getAllocations(edittableState).subscribe(r => {
            if (isNullOrUndefined(r)) {
                this.isLoading = false;
                return;
            }
            const allocatedViewModel = r.allocations.map(a => {
                const ext = a as AllocatedViewModelExtended;
                ext.isSelected = true;
                return ext;
            });
            this.collectionSize = r.recordCount;
            if (this.tableState.pagination.start === 1) {
                this.page = 1;
            }
            this.isLoading = false;
        }, err => {
            this.isLoading = false;
        });

    }

    setSpinner(value: boolean) {
        this.isLoading = value;
    }

    calendardelete(allocation: AllocatedViewModelExtended) {
        allocation.isSelected = true;
        this.deleteAllocations();
    }

    printGrade(grade: string, salesGrade: string): string {
        if (!grade && salesGrade) {
            return salesGrade;
        } else if (grade && !salesGrade) {
            return grade;
        } else {
            return grade + ' - ' + salesGrade;
        }
    }

    onTabChanged(event: MatTabChangeEvent) {
        // if not Calendar tab
        if (event.index != 1) {
            this.allocations = this.allocations.map(a => {
                const ext = a as AllocatedViewModelExtended;
                ext.isSelected = this.previouslySelectedAllocationIds.includes(ext.contractLineAllocationId);
                return ext;
            });
            this.dataSource = new MatTableDataSource(this.allocations);
            this.dataSource.sort = this.sort;
            return;
        }
        this.previouslySelectedAllocationIds = JSON.parse(JSON.stringify(this.selectedAllocations.map(s => s.contractLineAllocationId) ?? []));
        //If selected tab is calendar
        this.isLoading = true;
        this.allocationService.getAllocations(this.tableState).pipe((finalize(() => this.isLoading = false)))
            .subscribe(r => {
                if (isNullOrUndefined(r)) {
                    this.isLoading = false;
                    return;
                }
                this.allocations = r.allocations.map(a => {
                    const ext = a as AllocatedViewModelExtended;
                    return ext;
                });
                this.allocationSubject.next(this.allocations);

            });
    }

    public setPickerDate(date: any, val: any): void {
        if (val === 'allocationRange') {
            this.allocationRangeType = date;
        } else if (val === 'pickup') {
            this.pickupdateType = date;
        }
    }

    copyLink() {
        try {
            const depot = this.searchForm.get('depot').value;
            if (isNullOrUndefined(depot) || depot == '') {
                this.toastr.error('Depot selection required', 'Error');
                return;
            }
            const url = new URL(location.origin + location.pathname);

            url.searchParams.append('depot', '');
            url.searchParams.append('grade', '');
            url.searchParams.append('salesgrade', '');
            url.searchParams.append('pickupfromdate', '');
            url.searchParams.append('pickuptodate', '');
            url.searchParams.append('allocationdate', '');
            url.searchParams.append('allocationtodate', '');
            url.searchParams.append('contractref', '');
            url.searchParams.append('gradegroup', '');
            url.searchParams.append('allocationRangeType', this.allocationRangeType !== undefined ? this.allocationRangeType : null);
            url.searchParams.append('pickupdateType', this.pickupdateType !== undefined ? this.pickupdateType : null);
            url.searchParams.append('allocationView', '');

            if (typeof (depot) === 'string' && depot.includes(',')) {
                //The following splits multiple depot search using the ',' then sets all the text to uppercase and removes any spacing from the front and end of the text
                //after which we take the first 6 letters which will be the depot number which is used in the search
                const splitdepotNos = depot.split(',').map(element => element.toUpperCase().trim().slice(0, 7));

                const depotNos = splitdepotNos.join(', ');
                url.searchParams.set('depot', depotNos);
            }
            else {
                if (depot.depotNumber != null && depot.depotNumber != undefined && depot.depotNumber != '') {
                    url.searchParams.set('depot', depot.depotNumber);
                }
                else if (!isNullOrUndefined(depot)) {
                    url.searchParams.set('depot', depot.toUpperCase());
                }
            }

            const grade = this.searchForm.get('grade').value;
            if (grade != null && grade != undefined && grade != '') {
                url.searchParams.set('grade', grade);
            }

            const salesGrade = this.searchForm.get('salesGrade').value;
            if (salesGrade != null && salesGrade != undefined && salesGrade != '') {
                url.searchParams.set('salesgrade', salesGrade);
            }
            const pickupDateRange = this.searchForm.get('pickupFromDateRange').value;
            if (pickupDateRange == null && this.pickupdateType === 'Range') {
                this.pickupFromToDateValidation();
                return;
            }

            if (pickupDateRange != null && pickupDateRange != undefined && pickupDateRange != '') {
                const pickupRange: QueryDateRange = pickupDateRange;

                if (pickupRange.dateFrom != null && pickupRange.dateFrom != undefined && pickupRange.dateFrom != '') {
                    const pdf = new Date(pickupRange.dateFrom);
                    if (pdf.toDateString() === 'Invalid Date') {
                        this.pickupDateValidation(true, false);
                        return;
                    }
                    const pdfs = pdf.getDate() + '-' + (pdf.getMonth() + 1) + '-' + pdf.getFullYear();
                    url.searchParams.set('pickupfromdate', pdfs);
                }
                else {
                    if (this.pickupdateType === 'Range') {
                        this.pickupDateValidation(true, false);
                        return;
                    }
                }

                if (pickupRange.dateTo != null && pickupRange.dateTo != undefined && pickupRange.dateTo != '') {
                    const pdt = new Date(pickupRange.dateTo);
                    if (pdt.toDateString() === 'Invalid Date') {
                        this.pickupDateValidation(false, true);
                        return;
                    }
                    const pdts = pdt.getDate() + '-' + (pdt.getMonth() + 1) + '-' + pdt.getFullYear();
                    url.searchParams.set('pickuptodate', pdts);
                } else {
                    if (this.pickupdateType === 'Range') {
                        this.pickupDateValidation(false, true);
                        return;
                    }
                }
            }

            const allocationDateRange = this.searchForm.get('dateRange').value;
            if (allocationDateRange == null && this.allocationRangeType === 'Range') {
                this.allocationFromToDateValidation();
                return;
            }

            if (allocationDateRange != null && allocationDateRange != undefined && allocationDateRange != '') {
                const allocationRange: QueryDateRange = allocationDateRange;

                if (allocationRange.dateFrom != null && allocationRange.dateFrom != undefined && allocationRange.dateFrom != '') {
                    const adf = new Date(allocationRange.dateFrom);
                    if (adf.toDateString() === 'Invalid Date') {
                        this.allocationDateValidation(true, false);
                        return;
                    }
                    const adfs = adf.getDate() + '-' + (adf.getMonth() + 1) + '-' + adf.getFullYear();
                    url.searchParams.set('allocationdate', adfs);
                }
                else {
                    if (this.allocationRangeType === 'Range') {
                        this.allocationDateValidation(true, false);
                        return;
                    }
                }
                if (allocationRange.dateTo != null && allocationRange.dateTo != undefined && allocationRange.dateTo != '') {
                    const adt = new Date(allocationRange.dateTo);
                    if (adt.toDateString() === 'Invalid Date') {
                        this.allocationDateValidation(false, true);
                        return;
                    }
                    const adts = adt.getDate() + '-' + (adt.getMonth() + 1) + '-' + adt.getFullYear();
                    url.searchParams.set('allocationtodate', adts);
                }
                else {
                    if (this.allocationRangeType === 'Range') {
                        this.allocationDateValidation(false, true);
                        return;
                    }
                }
            }

            const contractRef = this.searchForm.get('contractRef').value;
            if (contractRef != null && contractRef != undefined && contractRef != '') {
                url.searchParams.set('contractref', contractRef);
            }

            const gradeGroup = this.searchForm.get('gradeGroupName').value;
            if (gradeGroup != null && gradeGroup != undefined && gradeGroup != '') {
                url.searchParams.set('gradegroup', gradeGroup);
            }

            const allocationView = this.allocationTableViewForm.get('allocationTableView').value;
            if (allocationView != null && allocationView != undefined && allocationView != '' && allocationView.viewName!='') {
                url.searchParams.set('allocationView', allocationView.viewName);
            }
            this.clipboard.copy(url.toString());

            this.toastr.success('Link successfully copied to clipboard', 'Success');
        } catch (e) {
            this.toastr.error('An error occured while copying the filters. Please try again.', 'Error');
        }
    }

    private pickupFromToDateValidation() {
        this.toastr.error('Enter valid Pick Up From and To date.', 'Error');
    }

    private allocationFromToDateValidation() {
        this.toastr.error('Enter valid Allocation From and To date.', 'Error');
    }

    dateChange(val: string) {
        let queryDateRange = {}; 
        switch (val) {
        case 'Month':
            queryDateRange = {
                dateFrom: this.firstOfLastMonth
            };
            break;
        case 'Quarter':
            queryDateRange = {
                dateFrom: this.firstOfLastQuarter
            };
            break;
        case 'Half':
            queryDateRange = {
                dateFrom: this.firstOfLastHalf
            };
            break;
        case 'Year':
            queryDateRange = {
                dateFrom: this.firstOfLastYear
            };
            break;
        default:
            queryDateRange = {
                dateFrom: this.firstOfLastQuarter
            };
            break;
        }
        return queryDateRange;
    }

    get firstOfLastMonth(): Date {
        const d = new Date();
        return new Date(d.getFullYear(), d.getMonth() - 1, 1);
    }
    get firstOfLastQuarter(): Date {
        const d = new Date();
        return new Date(d.getFullYear(), d.getMonth() - 3, 1);
    }
    get firstOfLastHalf(): Date {
        const d = new Date();
        return new Date(d.getFullYear(), d.getMonth() - 6, 1);
    }
    get firstOfLastYear(): Date {
        const d = new Date();
        return new Date(d.getFullYear() - 1, d.getMonth(), 1);
    }
}

interface PendingAndAllocatedViewModelExtended extends PendingAndAllocatedViewModel {
    showingAllocations: boolean;
}

interface AllocatedViewModelExtended extends AllocatedViewModel {
    showingGroup: boolean;
    isSelected: boolean;
}

interface FilterNamesExtended extends FilterNames {
    isSelected: boolean;
}
