import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {REST_CONFIG_TOKEN, RestConfigService} from '../rest-config-service';
import {Observable} from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { DepotVm,
	BookingSheetQueryModel,
	PagedAllocationBooking,
	BookingSheetScheduleVm,
	AllocationBookingViewModel,
} from '../models';

@Injectable()
export class BookingSheetService {

	constructor(
private _http: HttpClient,
@Inject(REST_CONFIG_TOKEN) private _configService: RestConfigService
	) {
	}

	public getBookingSheetData: (
        bq?: BookingSheetQueryModel
        ) => Observable<PagedAllocationBooking>
		= (
			bq?: BookingSheetQueryModel
		): Observable<PagedAllocationBooking> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});

			const uri = '/api/BookingSheet/getbookingsheetdata';

			return this._http
				.post<PagedAllocationBooking>(this._configService.apiBaseUrl() + uri, JSON.stringify(bq ),
					{ headers: headers, params: queryParameters  });
		};
	public exportAppointmentsToExcelAndSave(fileName: string, responseType: string,
		bq?: BookingSheetQueryModel
	): Observable<Object> {
		const headers = new HttpHeaders({'Content-Type': 'application/json'});
		const uri = this._configService.apiBaseUrl() + '/api/BookingSheet/getbookingsheetdata/export';
		return this._http.post(uri, JSON.stringify(bq ), {
			responseType: 'blob', headers
		} )
			.pipe(map((response) => {
				const blob = new Blob([response], {type: responseType});
				FileSaver.saveAs(blob, fileName);
				return {};
			}))
		;
	}

	public setBulkAllocationBookingAdjustment: (
        adjustment: number,
        bq?: BookingSheetQueryModel
        ) => Observable<boolean>
		= (
			adjustment: number,
			bq?: BookingSheetQueryModel
		): Observable<boolean> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});
			const uri = `/api/BookingSheet/bulkUpdate/${adjustment}`;
			return this._http
				.post<boolean>(this._configService.apiBaseUrl() + uri, JSON.stringify(bq),
					{ headers: headers, params: queryParameters  });
		};

	public setAllocationBookingAdjustment: (
        model?: AllocationBookingViewModel
        ) => Observable<boolean>
		= (
			model?: AllocationBookingViewModel
		): Observable<boolean> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});
			const uri = '/api/BookingSheet/update';
			return this._http
				.post<boolean>(this._configService.apiBaseUrl() + uri, JSON.stringify(model),
					{ headers: headers, params: queryParameters  });
		};
                
	public getScheduleInfo: (
        depotNo?: string,
grade?: string,
dateRangeType?: number
        ) => Observable<BookingSheetScheduleVm[]>
		= (
			depotNo?: string,
			grade?: string,
			dateRangeType?: number
		): Observable<BookingSheetScheduleVm[]> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});



			const uri = `/api/BookingSheet/schedule/${depotNo}/${grade}/${dateRangeType}`;

			return this._http
				.get<BookingSheetScheduleVm[]>(this._configService.apiBaseUrl() + uri,
					{ headers: headers, params: queryParameters  });
		};
	public getDefaultDepotDetails: (
                ) => Observable<DepotVm>
		= (
		): Observable<DepotVm> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});
			const uri = '/api/BookingSheet/getdefaultdepotdetails';

			return this._http
				.get<DepotVm>(this._configService.apiBaseUrl() + uri,
					{ headers: headers, params: queryParameters  });
		};


}

