import { Component, OnInit, Input, Output, EventEmitter, ViewChild, forwardRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { isNullOrUndefined } from '../tools';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocomplete} from '@angular/material/autocomplete';

@Component({
	selector: 'app-multiselect',
	templateUrl: './multiselect.component.html',
	styleUrls: ['./multiselect.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => MultiselectComponent),
			multi: true
		}
	]
})
export class MultiselectComponent implements OnInit, ControlValueAccessor {

	private filter$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  @Input() autoCompleteDelay: number;
  @Input() displayInit: string[];
  @Input() displayWith: (obj: any) => string = this.defaultDisplayWith;
  @Input() displayValueWith: (obj: any) => string = this.defaultDisplayWith;
  @Input() getResults: (filter: string) => Observable<any>;
  @Input() placeholder: string;
  @Input() tooltip: (obj: any) => string = this.defaultTooltip;
  @Input() formOutputProperty: string;
  @Output() selectionChange = new EventEmitter<any>();
  @Output() cleared = new EventEmitter<void>();
  @ViewChild('autoInput', { static: true }) input: any;
  @Input() errorStateMatcher: ErrorStateMatcher = new ErrorStateMatcher();
  loading = false;
  filterVal: string;
  results: any[] = [];
  allResults: any[] = [];
  filteredResults: Observable<any[]>;
  spinDia = 100;
  spinColor = 'primary';
  autoComplete = new FormControl();
  chosen = false;

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];


  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;
  
  constructor() {}
  
  get value() {
  	return this.input.nativeElement.value;
  }
  set value(val: string) {
  	this.input.nativeElement.value = val;
  }

  propagateChange = (_: any) => { };

  ngOnInit() {
  	this.results = this.displayInit;
  	this.filter$.pipe(debounceTime(this.autoCompleteDelay)).subscribe(f => {
  		if (isNullOrUndefined(f) || f === '') { return; }
  		this.loading = true;
  		if (this.chosen) {
  			this.loading = false;
  		} else {
  			const selectedItems = this.results;
  			this.getResults(f).subscribe(r => {
  				this.allResults = r.filter(function(item: any){
  					return !selectedItems.includes(item);
  				});
  				this.loading = false;
  			});
  		}
  	});
  }

  writeValue(obj: any): void {
  	if (!isNullOrUndefined(obj)) {
  		this.autoComplete.patchValue(obj);
  	}
  }
  registerOnChange(fn: any): void {
  	this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
  	// throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
  	this.input.setDisabledState(isDisabled);
  }

  filter(filter: string) {
  	this.filterVal = filter;
  	this.filter$.next(filter);
  }

  clearRequestInput(input: HTMLInputElement) {
  	input.value = '';
  	if (this.cleared) {
  		this.cleared.emit();
  	}
  	if (this.propagateChange) {
  		this.propagateChange(null);
  	}
  }

  defaultDisplayWith(obj: any) {
  	return obj;
  }
  defaultTooltip(obj: any) {
  	return obj;
  }

  inputValue(event: any) {
  	this.chosen = false;
  	this.allResults = [];
  	if (event.target.value === '') {
  		this.clearRequestInput(this.input.nativeElement);
  	} else {
  		event.target.value = event.target.value.toUpperCase();
  		this.filter(event.target.value);
  	}
  }

  remove(r: string): void {
  	const index = this.results.indexOf(r);

  	if (index >= 0) {
  		this.results.splice(index, 1);
  	}
  	this.allResults = [];
  }

  selected(value: any): void {
  	this.results.push(value);
  	this.input.nativeElement.value = '';
  	this.autoComplete.setValue(null);
  	this.selectionChange.emit(this.results);
  }
}

