import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AllocationsComponent } from './allocations/allocations.component';
import { AuthGuardService } from './auth/auth-guard.service';
import { AllocateComponent } from './allocate/allocate.component';
import { AppointmentComponent } from './appointment/appointment.component';
import { PrintAppointmentComponent } from './print-appointment/print-appointment.component';
import { ADGroupGuardService } from './auth/ad-group-guard.service';
import { RequestsComponent } from './requests/requests.component';
import { InstructionToMoveComponent } from './instruction-to-move/instruction-to-move.component';
import { BookingSheetComponent } from './booking-sheet/booking-sheet.component';
import { UserPreferencesComponent } from './user-preferences/user-preferences.component';
import { LiveLoadsComponent } from './live-loads/live-loads.component';
import { TerritoryResolverService } from './gen/services/resolver/territoryResolver.service';
import { DepotResolverService } from './gen/services/resolver/depotResolver.service';
import { OrderBookTypeResolver } from './gen/services/resolver/orderBookTypeResolver.service';
import { UserPreferencesResolver } from './gen/services/resolver/userPreferencesResolver.service';
import { TransportPlanComponent } from './transport-plan/transport-plan.component';
import { RouteToMarketComponent } from './route-to-market/route-to-market.component';
import { AllocateInventoryComponent } from './allocate-inventory/allocate-inventory.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: '',
        pathMatch: 'full'
    },
    {
        path: 'requests',
        component: RequestsComponent,
        canActivate: [AuthGuardService],
        resolve: {userpreferencesData: UserPreferencesResolver}
    },
    {
        path: 'instructiontomove',
        component: InstructionToMoveComponent,
        canActivate: [AuthGuardService],
        resolve: {userpreferencesData: UserPreferencesResolver}
    },
    {
        path: 'allocations',
        redirectTo: 'allocations',
        pathMatch: 'full'
    },
    {
        path: 'requests/allocations',
        redirectTo: 'allocations',
        pathMatch: 'full'
    },
    {
        path: 'allocations',
        component: AllocationsComponent,
        canActivate: [AuthGuardService],
        resolve: {userpreferencesData: UserPreferencesResolver}
    },
    {
        path: 'bookingsheet',
        redirectTo: 'requests/bookingsheet',
        pathMatch: 'full'
    },
    {
        path: 'requests/bookingsheet',
        component: BookingSheetComponent,
        canActivate: [AuthGuardService, ADGroupGuardService],
        data: { groupName: 'BookingSheet'},
        resolve: {userpreferencesData: UserPreferencesResolver}
    },
    {
        path: 'bookingsheetplan',
        redirectTo: 'transportplan',
        pathMatch: 'full'
    },
    {
        path: 'requests/transportplan',
        redirectTo: 'transportplan',
        pathMatch: 'full'
    },
    {
        path: 'transportplan',
        component: TransportPlanComponent,
        canActivate: [AuthGuardService, ADGroupGuardService],
        data: { groupName: 'TransportPlan'},
        resolve: {userpreferencesData: UserPreferencesResolver}
    },
    {
        path: 'match',
        redirectTo: 'allocate',
        pathMatch: 'full'
    },
    {
        path: 'allocate',
        component: AllocateComponent,
        canActivate: [AuthGuardService, ADGroupGuardService],
        data: { groupName: 'AllocationsClerk' },
        resolve: {userpreferencesData: UserPreferencesResolver,
            orderBookTypeList: OrderBookTypeResolver}
    },
    {
        path: 'appointment',
        component: AppointmentComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'print-appointment/:contractLineAllocationId',
        component: PrintAppointmentComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'userpreferences',
        component: UserPreferencesComponent,
        canActivate: [AuthGuardService],
        resolve: {territoryList: TerritoryResolverService,
            depotList : DepotResolverService,
            orderBookTypeList: OrderBookTypeResolver,
            userpreferencesData: UserPreferencesResolver}
    },
    {
        path: 'bookedtransport',
        redirectTo: 'liveloads',
        pathMatch: 'full'
    },
    {
        path: 'liveloads',
        component: LiveLoadsComponent,
        canActivate: [AuthGuardService, ADGroupGuardService],
        data: { groupName: 'BookedTransport'},
        resolve: {userpreferencesData: UserPreferencesResolver}
    },
    {
        path: 'routetomarket',
        component: RouteToMarketComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'allocate-inventory',
        component: AllocateInventoryComponent,
        canActivate: [AuthGuardService, ADGroupGuardService],
        data: { groupName: 'AllocationsClerk' },
        resolve: {userpreferencesData: UserPreferencesResolver,
            orderBookTypeList: OrderBookTypeResolver}
    },
    
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
