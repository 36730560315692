import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContractLineQuerySearchModel } from '../gen/models/ContractLineQuerySearchModel';
import { AllocationService } from '../gen/services/allocation.service';
import { SpinnerComponent } from '../spinner/spinner.component';
import { CustomValidators } from '../shared/custom-validators';
import { ContractLineSearchResult } from '../gen/models/ContractLineSearchResult';
import { isNullOrUndefined } from '../tools';

@Component({
	selector: 'app-contract-line-search',
	templateUrl: './contract-line-search.component.html',
	styleUrls: ['./contract-line-search.component.scss']
})


export class ContractLineSearchComponent implements OnInit {

	contractLineSearchForm: FormGroup;
	contractLines: ContractLineSearchResult[] = [];
	selectedContractLine: ContractLineSearchResult;
	isSearching = false;

  @ViewChild('spinner', { static: true }) spinner: SpinnerComponent;


  constructor(public dialogRef: MatDialogRef<ContractLineSearchComponent>,
    private allocationService: AllocationService,
    @Inject(MAT_DIALOG_DATA) public data: { search: ContractLineQuerySearchModel },
    private fb: FormBuilder) { }

  ngOnInit() {
  	this.contractLineSearchForm = this.fb.group({
  		grade: this.data.search.grade,
  		partyAccountNo: this.data.search.partyAccountNo,
  		customerContractRef: this.data.search.customerContractRef
  	}, {
  		validators: [
  			CustomValidators.AtLeastOneInputHasValue(),
  		]
  	});
  }

  ngOnDestroy() {
  	if (this.spinner !== undefined && this.spinner.overlayRef !== undefined)
  		this.spinner.overlayRef.dispose();
  }

  searchContractLines() {
  	this.isSearching = true;

  	const model: ContractLineQuerySearchModel = {
  		customerContractRef: this.contractLineSearchForm.controls.customerContractRef.value,
  		partyAccountNo: this.contractLineSearchForm.controls.partyAccountNo.value,
  		grade: this.contractLineSearchForm.controls.grade.value,
  		territory: null //todo pass from user preferences (worked out in the back-end for now when null)
  	};

  	this.allocationService.getContractLines(model).subscribe(cls => {
  		this.contractLines = cls;
  		this.formatContractLineGradeDisplayStrings(this.contractLines);
  		this.isSearching = false;
  	},
  	err => {
  		this.isSearching = false;
  	});
  }

  contractLineSelected(cl: ContractLineSearchResult) {
  	this.selectedContractLine = cl;
  }

  formatContractLineGradeDisplayStrings(cls: ContractLineSearchResult[]) {
  	cls.forEach(cl => { //fall back to sales grade where grade is not set
  		cl.grade = (isNullOrUndefined(cl.grade) || cl.grade === '') ? cl.salesGradeCode : cl.grade;
  		cl.gradeDescription = (isNullOrUndefined(cl.gradeDescription) || cl.gradeDescription == '') ? cl.salesGradeDescription : cl.gradeDescription;
  	});
  }

  submit(){
  	if(!this.selectedContractLine) {
  		return; //todo show error
  	}
  	else {
  		this.dialogRef.close(this.selectedContractLine);
  	}
  }
  
  cancel(){
  	this.dialogRef.close();
  }
}
