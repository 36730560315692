import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {REST_CONFIG_TOKEN, RestConfigService} from '../rest-config-service';
import {Observable} from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { PredefinedComment,
	Conversation,
	NewComment,
	NewCommentViewModel,
} from '../models';

@Injectable()
export class ConversationService {

	constructor(
private _http: HttpClient,
@Inject(REST_CONFIG_TOKEN) private _configService: RestConfigService
	) {
	}

	public getPendingRequestConversation: (
        requestId?: number
        ) => Observable<Conversation>
		= (
			requestId?: number
		): Observable<Conversation> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});

			const uri = `/api/conversation/pending/${requestId}`;

			return this._http
				.get<Conversation>(this._configService.apiBaseUrl() + uri,
					{ headers: headers, params: queryParameters  });
		};
	public getPredefinedCommentList: (
                ) => Observable<PredefinedComment[]>
		= (
		): Observable<PredefinedComment[]> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});
			const uri = '/api/conversation/comment/predefined';

			return this._http
				.get<PredefinedComment[]>(this._configService.apiBaseUrl() + uri,
					{ headers: headers, params: queryParameters  });
		};
	public addComment: (
        comment?: NewComment,
requestId?: number
        ) => Observable<NewCommentViewModel>
		= (
			comment?: NewComment,
			requestId?: number
		): Observable<NewCommentViewModel> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});


			const uri = `/api/conversation/pending/comment/${requestId}`;

			return this._http
				.post<NewCommentViewModel>(this._configService.apiBaseUrl() + uri, JSON.stringify(comment ),
					{ headers: headers, params: queryParameters  });
		};
	public sendConversationAsEmail: (
        requestId?: number
        ) => Observable<void>
		= (
			requestId?: number
		): Observable<void> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});

			const uri = `/api/conversation/email/${requestId}`;

			return this._http
				.post<any>(this._configService.apiBaseUrl() + uri,
					{ headers: headers, params: queryParameters  });
		};


}

