<span *ngIf="detail && detail.length > 0" (mouseenter)="showHideDetail(true, $event)" (mouseleave)="showHideDetail(false, $event)">
  <fa-icon icon="link"></fa-icon>
</span>
<div *ngIf="showDetail" [ngStyle]="detailStyle">
  <table>
    <thead>
      <th>Grade</th>
      <th>Description</th>
      <th>Weight</th>
    </thead>
    <tbody>
      <tr *ngFor="let d of detail">
        <td>{{d.grade}}</td>
        <td>{{d.description}}</td>
        <td>{{d.remainingWeight}} {{d.uomCode}}</td>
      </tr>
    </tbody>
  </table>
</div>
