<div class="cal-week-view" role="grid">
	<div class="cal-day-headers" role="row">
		<div class="cal-header" *ngFor="let day of days; trackBy: trackByWeekDayHeaderDate"
			[class.cal-past]="day.isPast" [class.cal-today]="day.isToday" [class.cal-future]="day.isFuture"
			[class.cal-weekend]="day.isWeekend" [ngClass]="day.cssClass"
			(mwlClick)="dayHeaderClicked.emit({ day: day, sourceEvent: $event })" mwlDroppable
			dragOverClass="cal-drag-over" (drop)="
		  eventDropped.emit({
			event: $event.dropData.event,
			newStart: day.date
		  })
		" (dragEnter)="dragEnter.emit({ date: day.date })" tabindex="0" role="columnheader">
			<b>{{ day.date | calendarDate: 'weekViewColumnHeader':locale }}</b><br />
			<span>{{
				day.date | calendarDate: 'weekViewColumnSubHeader':locale
				}}</span>
		</div>
	</div>
	<div class="cal-all-day-events" #allDayEventsContainer *ngIf="view.allDayEventRows.length > 0" mwlDroppable
		(dragEnter)="dragEnter('allDay')" (dragLeave)="dragLeave('allDay')">
		<div class="cal-day-columns">
			<div class="cal-time-label-column" [ngTemplateOutlet]="allDayEventsLabelTemplate"></div>
			<div class="cal-day-column" *ngFor="let day of days; trackBy: trackByWeekDayHeaderDate" mwlDroppable
				dragOverClass="cal-drag-over" (drop)="eventDropped($event, day.date, true)"
				(dragEnter)="dateDragEnter(day.date)"></div>
		</div>
		<div *ngFor="let eventRow of view.allDayEventRows; trackBy: trackById" #eventRowContainer
			class="cal-events-row">
			<div *ngFor="
			let allDayEvent of eventRow.row;
			trackBy: trackByWeekAllDayEvent
		  " #event class="cal-event-container" [class.cal-draggable]="
			allDayEvent.event.draggable && allDayEventResizes.size === 0
		  " [class.cal-starts-within-week]="!allDayEvent.startsBeforeWeek"
				[class.cal-ends-within-week]="!allDayEvent.endsAfterWeek" [ngClass]="allDayEvent.event?.cssClass"
				[style.width.%]="(100 / days.length) * allDayEvent.span"
				[style.marginLeft.%]="(100 / days.length) * allDayEvent.offset" mwlResizable
				[resizeSnapGrid]="{ left: dayColumnWidth, right: dayColumnWidth }" [validateResize]="validateResize"
				(resizeStart)="
			allDayEventResizeStarted(eventRowContainer, allDayEvent, $event)
		  " (resizing)="
			allDayEventResizing(allDayEvent, $event, dayColumnWidth)
		  " (resizeEnd)="allDayEventResizeEnded(allDayEvent)" mwlDraggable dragActiveClass="cal-drag-active"
				[dropData]="{ event: allDayEvent.event, calendarId: calendarId }" [dragAxis]="{
			x: allDayEvent.event.draggable && allDayEventResizes.size === 0,
			y:
			  !snapDraggedEvents &&
			  allDayEvent.event.draggable &&
			  allDayEventResizes.size === 0
		  }" [dragSnapGrid]="snapDraggedEvents ? { x: dayColumnWidth } : {}" [validateDrag]="validateDrag"
				[touchStartLongPress]="{ delay: 300, delta: 30 }" (dragStart)="dragStarted(eventRowContainer, event)"
				(dragging)="allDayEventDragMove()" (dragEnd)="dragEnded(allDayEvent, $event, dayColumnWidth)">
				<div class="cal-resize-handle cal-resize-handle-before-start" *ngIf="
			  allDayEvent.event?.resizable?.beforeStart &&
			  !allDayEvent.startsBeforeWeek
			" mwlResizeHandle [resizeEdges]="{ left: true }"></div>
				<app-week-view-event [locale]="locale" [weekEvent]="allDayEvent" [tooltipPlacement]="tooltipPlacement"
					[tooltipTemplate]="tooltipTemplate" [tooltipAppendToBody]="tooltipAppendToBody"
					[tooltipDelay]="tooltipDelay" [customTemplate]="eventTemplate"
					[eventTitleTemplate]="eventTitleTemplate" [eventActionsTemplate]="eventActionsTemplate"
					[daysInWeek]="daysInWeek" (eventClicked)="
			  eventClicked.emit({
				event: allDayEvent.event,
				sourceEvent: $event.sourceEvent
			  })
			">
				</app-week-view-event>
				<div class="cal-resize-handle cal-resize-handle-after-end" *ngIf="
			  allDayEvent.event?.resizable?.afterEnd &&
			  !allDayEvent.endsAfterWeek
			" mwlResizeHandle [resizeEdges]="{ right: true }"></div>
			</div>
		</div>
	</div>

	<div class="cal-time-events" mwlDroppable (dragEnter)="dragEnter('time')" (dragLeave)="dragLeave('time')">
		<div class="cal-time-label-column" *ngIf="view.hourColumns.length > 0 && daysInWeek !== 1">
			<div *ngFor="
			let hour of view.hourColumns[0].hours;
			trackBy: trackByHour;
			let odd = odd
		  " class="cal-hour" [class.cal-hour-odd]="odd">
				<mwl-calendar-week-view-hour-segment *ngFor="let segment of hour.segments; trackBy: trackByHourSegment"
					[style.height.px]="hourSegmentHeight" [segment]="segment" [segmentHeight]="hourSegmentHeight"
					[locale]="locale" [customTemplate]="hourSegmentTemplate" [isTimeLabel]="true"
					[daysInWeek]="daysInWeek">
				</mwl-calendar-week-view-hour-segment>
			</div>
		</div>
		<div class="cal-day-columns" [class.cal-resize-active]="timeEventResizes.size > 0" #dayColumns>
			<div class="cal-day-column" *ngFor="let column of view.hourColumns; trackBy: trackByHourColumn">
				<mwl-calendar-week-view-current-time-marker [columnDate]="column.date" [dayStartHour]="dayStartHour"
					[dayStartMinute]="dayStartMinute" [dayEndHour]="dayEndHour" [dayEndMinute]="dayEndMinute"
					[hourSegments]="hourSegments" [hourSegmentHeight]="hourSegmentHeight"
					[customTemplate]="currentTimeMarkerTemplate"></mwl-calendar-week-view-current-time-marker>
				<div *ngFor="
		  let weekdayEvent of weekDays[column.date.toDateString()] ;
		  ">
					<div *ngFor="
		let timeEvent of weekdayEvent.events;let count = index;
		trackBy: trackByWeekTimeEvent;" #event class="cal-events-container"
						[ngClass]="isDayView != true ? '' : 'day-event-container-position'">
						<div *ngIf="count < eventThreshold" class="cal-event-container" [class.cal-draggable]="
				timeEvent.event.draggable && timeEventResizes.size === 0
			  " [class.cal-starts-within-day]="!timeEvent.startsBeforeDay" [class.cal-ends-within-day]="!timeEvent.endsAfterDay"
							[ngClass]="timeEvent.event.cssClass"
							[ngClass]="isDayView != true ? '' : 'day-event-box-no-position'"
							[hidden]="timeEvent.height === 0 && timeEvent.width === 0" [style.top.px]="timeEvent.top"
							[style.height.px]="column.events.length" [style.left.px]="getLeft(count)"
							style="font-size: 14px !important;" mwlResizable [resizeSnapGrid]="{
			  left: dayColumnWidth,
			  right: dayColumnWidth,
			  top: eventSnapSize || hourSegmentHeight,
			  bottom: eventSnapSize || hourSegmentHeight
			}" [validateResize]="validateResize" [allowNegativeResizes]="true"
							(resizeStart)="timeEventResizeStarted(dayColumns, timeEvent, $event)"
							(resizing)="timeEventResizing(timeEvent, $event)"
							(resizeEnd)="timeEventResizeEnded(timeEvent)" mwlDraggable dragActiveClass="cal-drag-active"
							[dropData]="{ event: timeEvent.event, calendarId: calendarId }" [dragAxis]="{
			  x: timeEvent.event.draggable && timeEventResizes.size === 0,
			  y: timeEvent.event.draggable && timeEventResizes.size === 0
			}" [dragSnapGrid]="
			  snapDraggedEvents
				? {
					x: dayColumnWidth,
					y: eventSnapSize || hourSegmentHeight
				  }
				: {}" [touchStartLongPress]="{ delay: 300, delta: 30 }" [ghostDragEnabled]="!snapDraggedEvents"
							[ghostElementTemplate]="weekEventTemplate" [validateDrag]="validateDrag"
							(dragStart)="dragStarted(dayColumns, event, timeEvent)"
							(dragging)="dragMove(timeEvent, $event)"
							(dragEnd)="dragEnded(timeEvent, $event, dayColumnWidth, true)">
							<div class="cal-resize-handle cal-resize-handle-before-start" mwlResizeHandle [resizeEdges]="{
				  left: true,
				  top: true
				}"></div>
							<app-week-view-event [locale]="locale" [weekEvent]="timeEvent"
								[tooltipPlacement]="tooltipPlacement" [tooltipTemplate]="tooltipTemplate"
								[tooltipAppendToBody]="tooltipAppendToBody"
								[tooltipDisabled]="dragActive || timeEventResizes.size > 0"
								[tooltipDelay]="tooltipDelay" [customTemplate]="eventTemplate"
								[eventTitleTemplate]="eventTitleTemplate" [eventActionsTemplate]="eventActionsTemplate"
								[column]="column" [daysInWeek]="daysInWeek" [IsDayView]="isDayView" (eventClicked)="
					eventClicked.emit({
					  event: timeEvent.event,
					  sourceEvent: $event.sourceEvent
					})
				  ">
							</app-week-view-event>

							<div class="cal-resize-handle cal-resize-handle-after-end" *ngIf="
				  timeEvent.event?.resizable?.afterEnd &&
				  !timeEvent.endsAfterDay
				" mwlResizeHandle [resizeEdges]="{
				  right: true,
				  bottom: true
				}"></div>
							<div style="clear: both;"></div>
						</div>
						<div class="cal-event-container cal-event-container-more" *ngIf="count == eventThreshold"
							[style.top.px]="(timeEvent.top+5)"
							[style.left.px]="isDayView == true ? getLeft(count):null"
							style="font-size: 14px; display: inline-block;">
							<a (click)="handleMoreEvent($event, column.events, viewDate,timeEvent.event)"
								class="showmore wrap-text">
								{{(displayMoreCount(timeEvent,column.events))}}
							</a>
						</div>
					</div>
				</div>
				<div *ngFor="
			  let hour of column.hours;
			  trackBy: trackByHour;
			  let odd = odd
			" class="cal-hour" [class.cal-hour-odd]="odd">
					<mwl-calendar-week-view-hour-segment *ngFor="
				let segment of hour.segments;
				trackBy: trackByHourSegment
			  " [style.height.px]="hourSegmentHeight" [segment]="segment" [segmentHeight]="hourSegmentHeight" [locale]="locale"
						[customTemplate]="hourSegmentTemplate" [daysInWeek]="daysInWeek" (mwlClick)="
				hourSegmentClicked.emit({
				  date: segment.date,
				  sourceEvent: $event
				})
			  " [clickListenerDisabled]="
				hourSegmentClicked.observers.length === 0
			  " mwlDroppable [dragOverClass]="
				!dragActive || !snapDraggedEvents ? 'cal-drag-over' : null
			  " dragActiveClass="cal-drag-active" (drop)="eventDropped($event, segment.date, false)"
						(dragEnter)="dateDragEnter(segment.date)" [isTimeLabel]="daysInWeek === 1">
					</mwl-calendar-week-view-hour-segment>
				</div>
			</div>
		</div>
	</div>
</div>