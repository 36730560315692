import { Component, OnInit, Input } from '@angular/core';
import { AppointmentService } from '../gen/services/appointment.service';
import { PrintAppointmentVm } from '../gen/models';
import { Router, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { PrintingService } from '../services/printing.service';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { formatDate } from '@angular/common';

@Component({
	selector: 'app-print-appointment',
	templateUrl: './print-appointment.component.html',
	styleUrls: ['./print-appointment.component.scss']
})
export class PrintAppointmentComponent implements OnInit {
	isLoading = true;
	contractLineAllocationIds: number[];
	appointments: PrintAppointmentVm[] = [];
	constructor(
    private route: ActivatedRoute,
    private appointmentService: AppointmentService,
    private printingService: PrintingService) {
	}

	ngOnInit() {
		const appId: string = this.route.snapshot.params.contractLineAllocationId;
		this.contractLineAllocationIds = appId.replace(/, +/g, ',').split(',').map(Number);

		this.appointmentService.getAppointmentsToPrint(this.contractLineAllocationIds)
			.subscribe(r => {
				this.appointments = r;
				this.printingService.print({ printing: true });
				Promise.all(this.appointments)
					.then(()=> { setTimeout(()=>this.downloadAppointmentAsPDF(this.appointments)); });
			});
	}

	onPrint() {
		this.isLoading = false;
		window.print();
		window.close();
	}

	printAppointment() {
		this.appointmentService.getAppointmentsToPrint(this.contractLineAllocationIds)
			.subscribe(r => {
				this.appointments = r;
				Promise.all(this.appointments)
					.then(() => setTimeout(this.onPrint));
			});
	}

	downloadAppointmentAsPDF(appointments: PrintAppointmentVm[]){
		const doc = new jsPDF('p', 'pt');
		let filename='';
		let pageCount = 0;

		if(appointments.length>0)
		{
			for(const appointment of appointments)
			{
				pageCount++;
				const requestedMovementDate = appointment.requestedMovementDate === null ? '' : formatDate(new Date(appointment.requestedMovementDate),'MMM dd, yyyy hh:mm a', 'en-US', '');
				const requestedMovementDateEnd = appointment.requestedMovementDateEnd === null ? '' : formatDate(new Date(appointment.requestedMovementDateEnd),'MMM dd, yyyy hh:mm a', 'en-US', '');    
				const startDate = appointment.startDate === null ? '' : formatDate(new Date(appointment.startDate),'MMM dd, yyyy hh:mm a', 'en-US', '');
				const endDate = appointment.endDate === null ? '' : formatDate(new Date(appointment.endDate),'MMM dd, yyyy hh:mm a', 'en-US', '');
				const issuedDate = appointment.issuedDate === null ? '' : formatDate(new Date(appointment.issuedDate),'MM/dd/yyyy', 'en-US', '');
				const printeDateTime = appointment.printeDateTime === null ? '' : formatDate(new Date(appointment.printeDateTime),'MMM dd, yyyy hh:mm a', 'en-US', '');
				const address1 = (appointment.partyAddress.address1 === null || appointment.partyAddress.address1 === '') ? '' : appointment.partyAddress.address1 + '\n';
				const address2 = (appointment.partyAddress.address2 === null || appointment.partyAddress.address2 === '') ? '' : appointment.partyAddress.address2 + '\n';
				const address3 = (appointment.partyAddress.address3 === null || appointment.partyAddress.address3 === '') ? '' : appointment.partyAddress.address3 + '\n';
				const address4 = (appointment.partyAddress.address4 === null || appointment.partyAddress.address4 === '') ? '' : appointment.partyAddress.address4;
				const address5 = (appointment.partyAddress.address5 === null || appointment.partyAddress.address5 === '') ? '' : appointment.partyAddress.address5;
				const postalCode = (appointment.partyAddress.postalCode === null || appointment.partyAddress.postalCode === '') ? '' : appointment.partyAddress.postalCode;
				const address6 = address4 + ' ' + address5 + ' ' + postalCode;
				const deliveryNumber = appointment.deliveryNumber === null ? '' : appointment.deliveryNumber;
				const partyName = appointment.partyName === null ? '' : appointment.partyName;
				const aptNumber = appointment.aptNumber === null ? '' : appointment.aptNumber;
				const contactFax = appointment.contactFax === null ? '' : appointment.contactFax;
				const contactTelNo = appointment.contactTelNo === null ? '' : appointment.contactTelNo;
				const fromCompany = appointment.fromCompany === null ? '' : appointment.fromCompany;
				const haulierName = appointment.haulierName === null ? '' : appointment.haulierName;
				const customerContractRef = appointment.customerContractRef === null ? '' : appointment.customerContractRef;
				const contractRef = appointment.contractRef === null ? '' : appointment.contractRef;
				const gradeDesc = appointment.gradeDesc === null ? '' : appointment.gradeDesc;
				const agreedHaulageRate = appointment.agreedHaulageRate === null ? '' : appointment.agreedHaulageRate.toString();
				const instructions = appointment.instructions === null ? '' : appointment.instructions;
				const issuedBy = appointment.issuedBy === null ? '' : appointment.issuedBy;
				const contractLineAllocationId = appointment.contractLineAllocationId === null ? '' : appointment.contractLineAllocationId;

				const fromCompanyAddress1 = (appointment.fromCompanyAddress.address1 === null || 
          appointment.fromCompanyAddress.address1 === '') ? '' : appointment.fromCompanyAddress.address1;
				const fromCompanyAddress2 = (appointment.fromCompanyAddress.address2 === null || 
          appointment.fromCompanyAddress.address2 === '') ? '' : appointment.fromCompanyAddress.address2;
				const fromCompanyAddress3 = (appointment.fromCompanyAddress.address3 === null || 
          appointment.fromCompanyAddress.address3 === '') ? '' : appointment.fromCompanyAddress.address3;
				const fromCompanyAddress4 = (appointment.fromCompanyAddress.address4 === null || 
          appointment.fromCompanyAddress.address4 === '') ? '' : appointment.fromCompanyAddress.address4;
				const fromCompanyAddress5 = (appointment.fromCompanyAddress.address5 === null || 
          appointment.fromCompanyAddress.address5 === '') ? '' : appointment.fromCompanyAddress.address5;
				const fromCompanyAddress6 = (appointment.fromCompanyAddress.postalCode === null || 
          appointment.fromCompanyAddress.postalCode === '') ? '' : appointment.fromCompanyAddress.postalCode;

				const deliveryAddress1 = (appointment.deliveryAddress.address1 === null || 
          appointment.deliveryAddress.address1 === '') ? '' : appointment.deliveryAddress.address1;
				const deliveryAddres2 = (appointment.deliveryAddress.address2 === null || 
          appointment.deliveryAddress.address2 === '') ? '' : appointment.deliveryAddress.address2;
				const deliveryAddres3 = (appointment.deliveryAddress.address3 === null || 
          appointment.deliveryAddress.address3 === '') ? '' : appointment.deliveryAddress.address3;
				const deliveryAddres4 = (appointment.deliveryAddress.address4 === null || 
          appointment.deliveryAddress.address4 === '') ? '' : appointment.deliveryAddress.address4;
				const deliveryAddres5 = (appointment.deliveryAddress.address5 === null || 
          appointment.deliveryAddress.address5 === '') ? '' : appointment.deliveryAddress.address5;
				const deliveryAddres6 = (appointment.deliveryAddress.postalCode === null || 
          appointment.deliveryAddress.postalCode === '') ? '' : appointment.deliveryAddress.postalCode;
        
				let fromCompanyAddress7 = fromCompanyAddress2 + ' ' + fromCompanyAddress3;
				const fromCompanyAddress8 = fromCompanyAddress4 + ' ' + fromCompanyAddress5 + ' ' + fromCompanyAddress6;
        
				let deliveryAddres7 = deliveryAddres2 + ' ' + deliveryAddres3;
				const deliveryAddres8 = deliveryAddres4 + ' ' + deliveryAddres5 + ' ' + deliveryAddres6;

				const pendingDispatch = appointment.pendingDispatch ? 'Pending Dispatch' : ''; 

				if(fromCompanyAddress7 === ' ')
					fromCompanyAddress7 = '';
				else
					fromCompanyAddress7 = fromCompanyAddress7.trim() + '\n';

				if(deliveryAddres7 === ' ')
					deliveryAddres7 = '';
				else
					deliveryAddres7 = deliveryAddres7.trim() + '\n';

				const section1 = 'Appointment No\n\n\nAppt Issued To\n\n\n\n\nCust Ref #1\n\nCust Ref #2\n\nHaulier';
				let section2 = contractLineAllocationId + '\n\n' + partyName + '\n' 
                        + address1 + address2 + address3
                        + address6.trim() + '\n\n';
				const section3 = '\nCompany\n\n\Address\n\n\n\nPhone\n\nFax\n\nAppointment Ref#\n\nExpected Start Date\n\nExpected End Date\n';
				let section4 = '\n' + fromCompany + '\n\n' + fromCompanyAddress1 + '\n'
                        + fromCompanyAddress7  
                        + fromCompanyAddress8.trim() + '\n\n';    
				let section5 = '\n' + partyName + '\n\n' + deliveryAddress1 + '\n'
                        + deliveryAddres7  
                        + deliveryAddres8.trim() + '\n\n';
        
				if(pageCount > 1)
					doc.addPage();

				doc.setTextColor(100);
				doc.setFontType('bold');
				doc.setFontSize(9);
				doc.text(40, 30, 'Appointment Confirmation');
				doc.autoTable({
					head: [['Appointment Information']],
					styles: {
						halign: 'center',
						width:20
					}
				});
        
				doc.setTextColor(100);
				doc.setFontType('normal');
				doc.setFontSize(10);
        
				if(address2 === '')
					section2 = section2 +'\n';  
				if(address3 === '')
					section2 = section2 +'\n';  

				section2 =  section2 + contractRef + '\n\n'
                            + customerContractRef + '\n\n'
                            + haulierName + '\n';

				if(fromCompanyAddress7 === '')
					section4 = section4 +'\n';  
        
				section4 = section4 + contactTelNo + '\n\n'
                  + contactFax + '\n\n'
                  + aptNumber + '\n\n'
                  + requestedMovementDate + '\n\n'
                  + requestedMovementDateEnd + '\n\n';

				if(deliveryAddres7 === '')
					section5 = section5 +'\n';  

				section5 = section5 + '\n\n\n\n'
                + deliveryNumber + '\n\n'
                + startDate + '\n\n'
                + endDate + '\n\n';                    

				//Row 1
				doc.cellInitialize();
				doc.cell(40, 65, 125, 155, section1, 1);
				doc.cell(80, 65, 390, 155, section2, 1);
      
				//Row 2
				doc.cell(40, 250, 125, 25, ' ', 2);
				doc.cell(80, 250, 200, 25, '\nFrom', 2);
				doc.cell(120, 250, 190, 25, '\nTo', 2);
        
				//Row 3
				doc.cell(40, 275, 125, 200, section3, 3);
				doc.cell(80, 275, 200, 200, section4, 3);
				doc.cell(120, 275, 190, 200, section5, 3);

				//Row 4
				doc.setFontType('bold');
				doc.text(40, 460, 'Item Information');

				//Row 5
				doc.setFontType('normal');
				doc.text(40, 475, 'Item');

				//Row 6
				doc.text(40, 490, gradeDesc);
				//Are Packaging and Weight missing deliberately?

				//Row 7
				doc.setFontType('bold');
				doc.text(40, 510, 'Haulage Rate:');
				doc.setFontType('normal');
				doc.text(170, 510, agreedHaulageRate);
      
				//Row 8
				doc.setFontType('bold');
				doc.text(40, 530, 'Instructions:');
				doc.setFontType('normal');
				const splitInstructions = doc.splitTextToSize(instructions, 400);
				doc.text(110, 530, splitInstructions);

				doc.text(40, 700, pendingDispatch);

				//Row 9
				doc.text(40, 800, 'Issued By:');
				doc.text(90, 800, issuedBy);
				doc.text(220, 800, 'Issued Date:');
				doc.text(280, 800, issuedDate);
				doc.text(380, 800, 'Printed:');
				doc.text(420, 800, printeDateTime);
			}
    
			if(pageCount == 1)
				filename = appointments[0].contractLineAllocationId.toString();
			else
				filename = 'Multiple_Appointment';

			doc.save('Appointment_' + formatDate(new Date(), 'yyyy/MM/dd', 'en') + '_' + filename + '.pdf'); 
		}
		else
		{
			window.close();
		}
	}
}
 