<div class="table-responsive">
    <app-spinner  [container]="mainTable" #spinner></app-spinner>
    <div style="width: 1024px;height: 570px;overflow-y: auto;" #mainTable>
        <table name="pendingtable" id="pending-table" width="100%" cellpadding="0" cellspacing="0"
            class="table table-hover table-condensed custom-table" border="0" >
            <thead>
                <tr class="heading-row">
                    <th style="width: .1%;">
                    </th>
                    <th class="interactive hide-sm hide-xs">
                        Request Status</th>
                    <th class="interactive hide-sm hide-xs">
                        Request Date</th>
                    <th class="interactive">Depot No</th>
                    <th class="interactive hide-sm hide-xs">Ready Date</th>
                    <th class="interactive">Grade</th>
                </tr>
            </thead>
            <tbody class="more-content-body" style="height:80%;overflow-y: scroll">
                <tr *ngIf="pendingAndAllocated.length == 0">
                    <td colspan="6">
                        <mat-label>No records found!</mat-label>
                    </td>
                </tr>
                <ng-template ngFor let-item [ngForOf]="pendingAndAllocated"
                    *ngIf="pendingAndAllocated.length > 0">
                    <tr *ngIf="item.requestId" [ngClass]="{ 'selected' : item.isSelected }"
                        style="height: 36px;">
                        <td class="expand-icon">
                            <span *ngIf="hasAllocations(item)" (click)="toggleAllocations(item)" class="icon">
                                <i class="fa fa-plus-circle" *ngIf="!item.showingAllocations"></i>
                                <i class="fa fa-minus-circle" *ngIf="item.showingAllocations"></i>
                            </span>
                        </td>
                        <td>{{parseRequestStatus(item.requestStatus)}}</td>
                        <td class="hide-sm hide-xs">{{displayDate(item.requestDate)}}</td>
                        <td>{{item?.depot}}</td>
                        <td class="hide-sm hide-xs">
                            <div style="float: left;">
                                {{displayDate(item.readyDate)}}</div>
                        </td>
                        <td>{{item?.grade}}</td>
                    </tr>
                    <tr *ngIf="!item.requestId && item.allocations && item.allocations.length > 0" [ngClass]="{ 'selected' : item.isSelected }"
                        style="height: 36px;" (click)="toggleAllocations(item)">
                        <td class="expand-icon">
                            <span *ngIf="hasAllocations(item)"  class="icon">
                                <i class="fa fa-plus-circle" *ngIf="!item.showingAllocations"></i>
                                <i icon="fa fa-minus-circle" *ngIf="item.showingAllocations"></i>
                            </span>
                        </td>
                        <td colspan="5">Allocations without request</td>
                    </tr>
                    <tr *ngIf="item.showingAllocations">
                        <td colspan="25">
                            <table class="table table-striped table-condensed table-hover sub-table custom-table">
                                <thead>
                                    <th class="interactive heading-row wrap-header">Allocation Number</th>
                                    <th class="interactive heading-row wrap-header">Allocation Status</th>
                                    <th class="interactive heading-row hide-sm hide-xs min-width">Allocated Date</th>
                                    <th class="interactive heading-row min-width">Contract Ref</th>
                                    <th class="interactive heading-row min-width">Party</th>
                                    <th class="interactive heading-row min-width">
                                        Pick-up From Date & Time
                                    </th>
                                    <th class="interactive heading-row min-width">
                                        Pick-up To Date & Time
                                    </th>
                                    <th class="interactive heading-row min-width">Additional Costs</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let a of item?.allocations">
                                        <td (click)="handleeditEvent(a?.allocationNumber)" > 
                                            <a href="void:(0);"> {{a?.allocationNumber}}</a>
                                        </td>
                                        <td>
                                            {{parseAllocationStatus(a?.contractLineAllocationStatus)}}</td>
                                        <td class="hide-sm hide-xs">
                                            {{displayDate(a?.allocatedDate)}}</td>
                                        <td>{{a?.contractRef}}</td>
                                        <td>{{a?.party}}</td>
                                        <td class="hide-sm hide-xs">
                                            {{displayDate(a?.requestedMovementDate)}}</td>
                                        <td class="hide-sm hide-xs">
                                            {{displayDate(a?.requestedMovementDateEnd)}}</td>
                                        <td>
                                            {{formatCosts(a?.additionalCosts)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
    </div>
    <div mat-dialog-actions  class="buttons-row" align="center">
        <button mat-flat-button type="button"  (click)="cancel()">Close</button>
    </div>