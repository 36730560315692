import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {REST_CONFIG_TOKEN, RestConfigService} from '../rest-config-service';
import {Observable} from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { ContainerType,
} from '../models';

@Injectable()
export class ContainersService {

	constructor(
private _http: HttpClient,
@Inject(REST_CONFIG_TOKEN) private _configService: RestConfigService
	) {
	}

	public get: (
                ) => Observable<ContainerType[]>
		= (
		): Observable<ContainerType[]> => {
			const queryParameters = new HttpParams();
			const headers = new HttpHeaders({'Content-Type': 'application/json'});
			const uri = '/api/containers';

			return this._http
				.get<ContainerType[]>(this._configService.apiBaseUrl() + uri,
					{ headers: headers, params: queryParameters  });
		};


}

