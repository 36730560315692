import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { EnumVals } from '../gen/enums/enums';
import { KpiTracking } from '../gen/models/KpiTrackingVm';

class CrossFieldErrorMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl, form: FormGroupDirective | NgForm): boolean {
		if (!form.dirty) { return false; }
		return form.invalid && (!control.value || control.invalid);
	}
}

@Component({
	selector: 'app-kpi-tracking',
	templateUrl: './kpi-tracking.component.html',
	styleUrls: ['./kpi-tracking.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => KpiTrackingComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => KpiTrackingComponent),
			multi: true,
		}
	]
})
export class KpiTrackingComponent implements OnInit, ControlValueAccessor, Validator {

	kpiHidden = true;
	formGroup: FormGroup;
	errorMatcher = new CrossFieldErrorMatcher();
  @Input() enableCollapsing = true;
  @Input() showCollapse = true;
  @Input() kpi: KpiTracking;
  kpiTypes = [];

  constructor(private fb: FormBuilder) { }

  propagateChange = (_: any) => { };

  ngOnInit() {
  	this.formGroup =  new FormGroup({
  		kpiType: new FormControl({value: 'Unpriced'}, Validators.required),
  		kpiCost: new FormControl('', Validators.required)
  	});
    
  	this.kpiTypes = EnumVals.KpiTypes;
  	if(this.kpi) {
  		this.formGroup.controls.kpiType.patchValue(this.kpi.kpiType);
  	}

  	this.formGroup.valueChanges.subscribe(val => this.propagateChange(val));
  }

  toggleVisibility() {
  	if (this.kpiHidden) {
  		this.kpiHidden = false;
  	} else {
  		this.kpiHidden = true;
  	}
  }

  writeValue(obj: any): void {
  	if (obj) {
  		this.kpi = obj;
  		this.fb.group({
  			kpiType: '',
  			kpiCost: ''
  		});
  	}
  	console.log(obj);
    
  }

  registerOnChange(fn: any): void {
  	this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
  	// Method from ControlValueAccessor Interface, needed to override but here no use
  }
  setDisabledState?(isDisabled: boolean): void {
  	throw new Error('Method not implemented.');
  }

  validate(control: any): ValidationErrors {
  	if (control.pristine) { return null; }
  	if (control.kpiType === null
        || control.kpiCost === null) {
  		return {
  			nullFields: 'not all required fields set'
  		};
  	}
    
  	return {};
  }
}
