<div class="content-container sidebar-form" style="max-width: 900px;" id="load-times-container">
    <mat-card layout-fill>
        <div class="md-hue-2" id="load-times-header">
            <div class="md-toolbar-tools sidebar-header">
                <h2 flex mat-truncate>Load Times</h2>
            </div>
        </div>
        <div style="padding:0;width:80%;margin:auto;" id="load-times">
            <mat-list>
                <mat-list-item class="md-3-line" *ngFor="let lt of loadTimes">
                    <div class="md-list-item-text">
                        <h3 style="text-align:center;">{{lt}}</h3>
                    </div>
                    <mat-divider ng-if="!$last"></mat-divider>
                </mat-list-item>
            </mat-list>
        </div>
    </mat-card>
</div>
