import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Haulier, UomVm, InitModel, ContainerType, AllocatedViewModel } from '../gen/models';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HauliersService } from '../gen/services/hauliers.service';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UomsService } from '../gen/services/uoms.service';
import { isNullOrUndefined } from '../tools';
import { EnumVals, IEnumItem } from '../gen/enums/enums';
import { InitService } from '../services/init.service';
import { CustomValidators } from '../shared/custom-validators';
import { ToastrService } from 'ngx-toastr';
import { ContainersService } from '../gen/services/containers.service';
import { haulagePriceMatrixVM } from '../gen/models/haulagePriceMatrixVM';
import { AllocateService } from '../gen/services/allocate.service';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { SpinnerComponent } from '../spinner/spinner.component';
import { CurrencySymbolService } from '../services/currency-symbol.service';
import { ContractLineSearchComponent } from '../contract-line-search/contract-line-search.component';

@Component({
    selector: 'app-edit-multi-allocated-request-dialog',
    templateUrl: './edit-multi-allocated-request-dialog.component.html',
    styleUrls: ['./edit-multi-allocated-request-dialog.component.scss']
})
export class EditMultiAllocatedRequestDialogComponent implements OnInit {

    allocationApprovedToSend: IEnumItem[];
    allocationForm: FormGroup;
    filteredHauliers: Haulier[];
    uoms: UomVm[];
    initModel: InitModel;
    costIdsToRemove: number[] = [];
    dateError = false;
    dateErrorDesc: string;
    containerTypes: ContainerType[];
    haulageRates: haulagePriceMatrixVM[] = [];
    selectedHaulageMatrix: haulagePriceMatrixVM = null;
    haulagematrixDropdownOnly = false;
    earliestPickupDate: Date = null;
    showRatesDisabled = true;
    remainingInstructionsText: string;
    readonly originalInstructionsMaxlength = 1500;
    instructionsMaxlength = 1500;
    pickupDateChange: boolean = null;
    trash = faTrash;
    @ViewChild('loadingEditMultiAllocatedRequestDialogSpinner') loadingEditMultiAllocatedRequestDialogSpinner: SpinnerComponent;
    loadingEditMultiAllocatedRequestDialog = false;
    isSubmit = false;
    originalInstructionsMap = new Map<number, string>();
    readonly append = 'Append';
    readonly edit = 'Edit';
    instructionsEditMode = this.append;
    updateAndPrint=false;

    constructor(public dialogRef: MatDialogRef<EditMultiAllocatedRequestDialogComponent>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: { allocations: any[] },
        private fb: FormBuilder,
        private hauliersService: HauliersService,
        private uomsService: UomsService,
        private initService: InitService,
        private toastr: ToastrService,
        private containerService: ContainersService,
        private allocateService: AllocateService,
        private currencySymbolService: CurrencySymbolService
    ) { }

    fixHaulageRate() {
        if (!this.selectedHaulageMatrix) {
            return;
        }
        if (!this.haulagematrixDropdownOnly) {
            this.allocationForm.controls.agreedHaulageRate.setValue(this.selectedHaulageMatrix.haulageRate);
            const h: Haulier = {
                name: this.selectedHaulageMatrix.haulierName,
                accountNumber: this.selectedHaulageMatrix.haulierAccountNo,
            };

            this.allocationForm.controls.haulier.setValue(h);
        }
        this.haulagematrixDropdownOnly = false;
    }

    updateApprovedToSend() {
        const approvedToSend = this.allocationForm.get('approvedToSend');
        if (approvedToSend) {
            this.data.allocations.filter(a => a.isSelected).forEach(a => {
                a.approvedToSend = approvedToSend.value.key;
            });
        }
    }

    updateHaualgeRate(hpm: any) {
        this.selectedHaulageMatrix = hpm.value;
        this.haulagematrixDropdownOnly = false;
        this.fixHaulageRate();
    }

    updateHaulageRateDropdown(fixRate = false) {
        if (this.haulageRates.length > 0) {
            this.selectedHaulageMatrix = this.haulageRates[0];
            this.haulagematrixDropdownOnly = fixRate;
        }
    }

    getAvailableHauliers() {
        if (isNullOrUndefined(this.earliestPickupDate) && (isNullOrUndefined(this.allocationForm.controls.pickupFromDateTime.value) || !this.allocationForm.controls.pickupFromDateTime.value.isValid())) {
            this.toastr.info('Please select a valid pick up date', 'Haulage Price Matrix');
            return;
        }
        this.haulageRates = [];
        const model = new haulagePriceMatrixVM();
        model.partyDeliveryPointIds = [];
        model.containerTypeId = Number(this.allocationForm.controls.containerTypeId.value);
        model.fromLocationCode = this.data.allocations[0].depot;
        model.allocationFromDate = !isNullOrUndefined(this.earliestPickupDate) ? this.earliestPickupDate : isNullOrUndefined(this.allocationForm.controls.pickupFromDateTime.value) ? null : this.allocationForm.controls.pickupFromDateTime.value;
        model.allocationToDate = isNullOrUndefined(this.allocationForm.controls.pickupToDateTime.value) ? null : this.allocationForm.controls.pickupToDateTime.value;
        const ids = this.data.allocations.map(x => x.partyDeliveryPointId);
        for (let i = 0; i < ids.length; i++) {
            model.partyDeliveryPointIds.push(ids[i]);
        }
        model.partyAccountNo = this.data.allocations[0].party;
        this.hauliersService.getHaulageRates(model).subscribe(r => {
            this.haulageRates = r;
            if (this.haulageRates.length > 0) {
                this.selectedHaulageMatrix = this.haulageRates[0];
            } else {
                this.toastr.info('No matching haulier found', 'Haulier Information');
            }
        });
    }

    updatePickupDate() {
        this.showRatesDisabled = false;
        this.pickupDateChange = true;
    }

    ngOnInit() {
        this.data.allocations.forEach(element => {
            this.originalInstructionsMap.set(element.allocationNumber, element.instructions);
            if ((isNullOrUndefined(this.earliestPickupDate) && !isNullOrUndefined(element.requestedMovementDate)) || element.requestedMovementDate < this.earliestPickupDate) {
                this.earliestPickupDate = element.requestedMovementDate;
                this.showRatesDisabled = false;
            }
        });

        this.allocationApprovedToSend = EnumVals.ContractLineAllocationApprovedToSend;

        this.allocationForm = this.fb.group({
            contractRef: '',
            depotNo: '',
            containerTypeId: '',
            customerAptRef: '',
            pickupFromDateTime: null,
            pickupToDateTime: null,
            customerAptDateTime: null,
            customerAptToDateTime: null,
            haulier: [{ value: '' }],
            agreedHaulageRate: [{ value: '' }],
            agreedHaulageRateUomId: [{ value: '' }],
            additionalCosts: [''],
            instructions: '',
            approvedToSend: '',
        }, {
            validators: [
                CustomValidators.dateLessThan('pickupFromDateTime', 'pickupToDateTime'),
                CustomValidators.dateLessThan('customerAptDateTime', 'customerAptToDateTime'),
                CustomValidators.requiredWhenSet('agreedHaulageRateUomId', 'agreedHaulageRate', 'Spot Rate UOM', 'Spot Rate')
            ]
        });

        this.allocationForm.controls['instructions'].valueChanges.subscribe((newVal: string) => {
            this.remainingInstructionsText = `${newVal ? newVal.length : 0}/${this.instructionsMaxlength}`;
        });

        this.initService.initData$.subscribe(r =>
            this.initModel = r
        );
        this.haulier.valueChanges
            .pipe(
                debounceTime(500),
                startWith(null),
                switchMap(v => this.filterHauliers(v)))
            .subscribe(r => this.filteredHauliers = r);

        this.uomsService.getAll().subscribe(u => {
            this.uoms = u;
            for (const a of this.data.allocations) {
                if (!isNullOrUndefined(a.agreedHaulageRateUomId) && a.agreedHaulageRateUomId > 0) {
                    a.agreedHaulageRateUomCode = this.uoms.filter(uom => uom.id === a.agreedHaulageRateUomId)[0].code;
                }
            }
        });
        this.containerService.get().subscribe(x => this.containerTypes = x);

        this.setInstructionsEditMode();
        this.setMaxInstructionLength();

        if (this.instructionsMaxlength == 0) {
            this.toastr.error('One or more of the selected allocations has reached its maximum instruction character length', 'Error');
        }
        this.updateAndPrint=false;
    }

    get haulier() {
        return this.allocationForm.get('haulier');
    }
    get maxCosts() {
        const maxExistingCosts =
            Math.max(...this.data.allocations
                .map(a => !isNullOrUndefined(a.additionalCosts) &&
                    a.additionalCosts.filter((ac: { costId: number; }) => this.costIdsToRemove.indexOf(ac.costId) === -1).length));
        return this.initModel.config.maxAllocationCosts - maxExistingCosts;
    }

    filterHauliers(val: string): Observable<Haulier[]> {
        return this.hauliersService.get(val);
    }

    displayHaulier(haulier?: Haulier): string {
        return isNullOrUndefined(haulier.accountNumber) || isNullOrUndefined(haulier.name)
            ? '' : haulier.accountNumber + ' ' + haulier.name;
    }

    showHaulierClear(haulierInput: HTMLInputElement): boolean {
        return !isNullOrUndefined(haulierInput.value) && haulierInput.value !== '';
    }

    haulierClear() {
        this.haulier.setValue({ accountNumber: null, name: null });
    }

    parseAllocationStatus(key: number): string {
        return EnumVals.ContractLineAllocationStatuses.filter(s => s.key === key)[0].desc;
    }

    cancel() {
        this.dialogRef.close();
    }

    update() {
        const model: any = {};
        if (this.allocationForm.valid) {

            if (this.validateDates()) return;
            this.loadingEditMultiAllocatedRequestDialog = true;
            this.isSubmit = true;

            model.depotNo = this.allocationForm.value.depotNo;
            model.containerTypeId = this.allocationForm.value.containerTypeId;
            model.customerAptRef = this.allocationForm.value.customerAptRef;
            model.pickupFromDateTime = this.allocationForm.value.pickupFromDateTime;
            model.pickupToDateTime = this.allocationForm.value.pickupToDateTime;
            model.customerAptDateTime = this.allocationForm.value.customerAptDateTime;
            model.customerAptToDateTime = this.allocationForm.value.customerAptToDateTime;
            model.haulierAccountNumber = this.allocationForm.value.haulier.accountNumber;
            model.agreedHaulageRate = this.allocationForm.value.agreedHaulageRate.value === '' ? undefined : this.allocationForm.value.agreedHaulageRate;
            model.agreedHaulageRateUomId = this.allocationForm.value.agreedHaulageRateUomId.value === '' ? undefined : this.allocationForm.value.agreedHaulageRateUomId;
            model.additionalCosts = this.allocationForm.value.additionalCosts;
            const selectedAllocations = this.data.allocations.filter(a => a.isSelected);
            model.allocations = selectedAllocations;
            const selectedCosts = selectedAllocations.map(s => s.additionalCosts);
            const selectedCostIds: number[] = [];
            selectedCosts.forEach(costs => { //todo enable es2019 to allow calling array.flat() or array.flatMap() instead of nested looping
                if (isNullOrUndefined(costs)) return;
                costs.forEach(cost => {
                    selectedCostIds.push(cost.costId);
                });
            });
            selectedAllocations.forEach(x => {
                if (this.pickupDateChange != null) {
                    x.missedDropOff = !this.pickupDateChange;
                }
            });
            const selectedCostIdsToRemove = this.costIdsToRemove.filter(c => selectedCostIds.includes(c)); //only actually remove costs for selected allocations
            model.costIdsToRemove = selectedCostIdsToRemove;
            model.instructions = this.allocationForm.value.instructions;
            model.EditInstructions = this.instructionsEditMode == this.edit;
            model.missedDropOff = this.pickupDateChange != null ? !this.pickupDateChange : null;
            if (this.allocationForm.value.approvedToSend) {
                model.approvedToSend = this.allocationForm.value.approvedToSend.key;
            }

            this.allocateService.updateAllocations(model)
                .subscribe(a => {
                    this.updateDisplayAllocations(a);//get updated values returned from end-point to display on screen
                    this.costIdsToRemove = this.costIdsToRemove.filter(c => !selectedCostIdsToRemove.includes(c)); //remove the 'to delete' costs that have now been deleted
                    this.toastr.success('Allocations successfully edited', 'Edit Allocations');
                    this.loadingEditMultiAllocatedRequestDialog = false;
                    this.isSubmit = false;
                    if(this.updateAndPrint){
                       this.printAppointments();
                    }

                },
                    err => {
                        this.loadingEditMultiAllocatedRequestDialog = false;
                        this.isSubmit = false;
                        if (err.status === 200) {
                            this.toastr.success('Allocations successfully edited', 'Edit Allocations');
                        } else {
                            this.toastr.error('Something went wrong and the allocations could not be edited. Please try again.', 'Edit Failed');
                        }
                    });
        }
        else {
            if (!isNullOrUndefined(this.allocationForm.errors)) {
                if (!isNullOrUndefined(this.allocationForm.errors.dates)) {
                    this.toastr.error(this.allocationForm.errors.dates, 'Form Invalid');
                } else if (!isNullOrUndefined(this.allocationForm.errors.unset)) {
                    this.toastr.error(this.allocationForm.errors.unset, 'Form Invalid');
                } else {
                    this.toastr.error('Form Invalid');
                }
            }
        }
    }

    printAppointments() {
        const selectedAllocations = this.data.allocations.filter((a) => a.isSelected);
        if (selectedAllocations.length > 0) {
            window.open('print-appointment/' +
          selectedAllocations.map((s) => s.contractLineAllocationId).join(',')
            );
        }
    }

    updateAndPrintAllocations() {
        this.updateAndPrint = true;
        this.update();
    }

    updateDisplayAllocations(updated: [number, AllocatedViewModel][]) {
        updated.forEach(element => {
            const tup = element as any;
            const originalId = tup.item1;
            const updatedAllocation = tup.item2 as AllocatedViewModel;
            const allocation = this.data.allocations.filter(value => value.allocationNumber == originalId)[0];
            //override all the values that may have changed when updating
            if (!isNullOrUndefined(updatedAllocation.contractRef))
                allocation.contractRef = updatedAllocation.contractRef;

            if (!isNullOrUndefined(updatedAllocation.depot))
                allocation.depot = updatedAllocation.depot;

            if (!isNullOrUndefined(updatedAllocation.depotName))
                allocation.depotName = updatedAllocation.depotName;

            if (!isNullOrUndefined(updatedAllocation.grade))
                allocation.grade = updatedAllocation.grade;

            if (!isNullOrUndefined(updatedAllocation.gradeDesc))
                allocation.gradeDesc = updatedAllocation.gradeDesc;

            if (!isNullOrUndefined(updatedAllocation.allocationNumber)) {
                allocation.allocationNumber = updatedAllocation.allocationNumber;
                allocation.contractLineAllocationId = updatedAllocation.allocationNumber; //why do we have two fields here?
            }

            if (!isNullOrUndefined(updatedAllocation.haulier))
                allocation.haulier = updatedAllocation.haulier;

            if (!isNullOrUndefined(updatedAllocation.haulierName))
                allocation.haulierName = updatedAllocation.haulierName;

            if (!isNullOrUndefined(updatedAllocation.containerId))
                allocation.containerId = updatedAllocation.containerId;

            if (!isNullOrUndefined(updatedAllocation.containerTypeName))
                allocation.containerTypeName = updatedAllocation.containerTypeName;

            if (!isNullOrUndefined(updatedAllocation.aptNumber))
                allocation.aptNumber = updatedAllocation.aptNumber;

            if (!isNullOrUndefined(updatedAllocation.requestedMovementDate))
                allocation.requestedMovementDate = updatedAllocation.requestedMovementDate;

            if (!isNullOrUndefined(updatedAllocation.requestedMovementDateEnd))
                allocation.requestedMovementDateEnd = updatedAllocation.requestedMovementDateEnd;

            if (!isNullOrUndefined(updatedAllocation.agreedHaulageRate))
                allocation.agreedHaulageRate = updatedAllocation.agreedHaulageRate;

            if (!isNullOrUndefined(updatedAllocation.agreedHaulageRateUomId))
                allocation.agreedHaulageRateUomId = updatedAllocation.agreedHaulageRateUomId;

            if (!isNullOrUndefined(updatedAllocation.instructions)) {
                allocation.instructions = updatedAllocation.instructions;
                this.originalInstructionsMap.set(allocation.allocationNumber, allocation.instructions);
            }

            if (!isNullOrUndefined(updatedAllocation.additionalCosts))
                allocation.additionalCosts = updatedAllocation.additionalCosts;

            if (!isNullOrUndefined(updatedAllocation.aptDateTime))
                allocation.aptDateTime = updatedAllocation.aptDateTime;

            if (!isNullOrUndefined(updatedAllocation.aptDateTimeTo))
                allocation.aptDateTimeTo = updatedAllocation.aptDateTimeTo;

            if (!isNullOrUndefined(updatedAllocation.party))
                allocation.party = updatedAllocation.party;

            if (!isNullOrUndefined(updatedAllocation.partyName))
                allocation.partyName = updatedAllocation.partyName;

            if (!isNullOrUndefined(updatedAllocation.salesGrade))
                allocation.salesGrade = updatedAllocation.salesGrade;
        });
    }

    valueChange(a: any, event) {
        if (event.checked) {
            a.isSelected = true;
        } else {
            a.isSelected = false;
            a.instructions = this.originalInstructionsMap.get(a.allocationNumber);
        }
        this.setInstructionsEditMode();
        this.changeInstructionsEditMode();
    }

    validateDates() {
        this.dateError = false;
        this.dateErrorDesc = '';
        if (isNullOrUndefined(this.allocationForm.value.pickupToDateTime) && !isNullOrUndefined(this.allocationForm.value.pickupFromDateTime)) {
            const startDate = new Date(this.allocationForm.value.pickupFromDateTime);
            for (const a of this.data.allocations) {
                if (!isNullOrUndefined(a.requestedMovementDateEnd)) {
                    const pToDate = new Date(a.requestedMovementDateEnd);
                    if (startDate > pToDate) {
                        this.dateError = true;
                        this.dateErrorDesc = 'Pick-up From Date & Time must be less than existing Pick-up To Date & Time';
                        return true;
                    }
                }
            }
        }

        if (isNullOrUndefined(this.allocationForm.value.pickupFromDateTime) && !isNullOrUndefined(this.allocationForm.value.pickupToDateTime)) {
            const endDate = new Date(this.allocationForm.value.pickupToDateTime);
            for (const a of this.data.allocations) {
                if (!isNullOrUndefined(a.requestedMovementDate)) {
                    const pDate = new Date(a.requestedMovementDate);
                    if (pDate > endDate) {
                        this.dateError = true;
                        this.dateErrorDesc = 'Pick-up To Date & Time must be greater than existing Pick-up From Date & Time';
                        return true;
                    }
                }
            }
        }

        return false;
    }

    toggleRemoveCost(costId: number) {
        const index = this.costIdsToRemove.indexOf(costId);
        if (index > -1) {
            this.costIdsToRemove.splice(index, 1);
        } else {
            this.costIdsToRemove.push(costId);
        }
    }

    getRowStyle(costId: number) {
        if (this.costIdsToRemove.indexOf(costId) > -1) {
            return { 'text-decoration': 'line-through' };
        }
        return {};
    }

    close() {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        if (this.loadingEditMultiAllocatedRequestDialogSpinner !== undefined)
            this.loadingEditMultiAllocatedRequestDialogSpinner.overlayRef.dispose();
    }

    get spotRateText() {
        let spotRateText = 'Spot Rate';

        if (this.data.allocations[0]?.haulageCurrencyCode) {
            const currencySymbol = this.currencySymbolService.getCurrencySymbol(this.data.allocations[0].haulageCurrencyCode);
            if (currencySymbol) {
                spotRateText = `${spotRateText} (${currencySymbol})`;
            }
        }
        return spotRateText;
    }

    openContractSearchDialog(ev) {
        if (ev && ev.type === 'keydown' && (ev.key === 'Tab' || ev.key === 'Shift')) {
            return;
        }

        let contractRef: string = null;
        const contractRefs = this.data.allocations.map(a => a.contractRef);
        if (contractRefs.length) {
            if (contractRefs.every(c => c === contractRefs[0])) {
                contractRef = contractRefs[0];
            }
        }

        let grade: string = null;
        const grades = this.data.allocations.map(a => a.grade);
        if (grades.length) {
            if (grades.every(g => g === grades[0])) {
                grade = grades[0];
            }
        }

        let party: string = null;
        const parties = this.data.allocations.map(a => a.party);
        if (parties.length) {
            if (parties.every(p => p === parties[0])) {
                party = parties[0];
            }
        }

        const dialogRef = this.dialog.open(ContractLineSearchComponent, {
            minWidth: '240px',
            maxWidth: '85%',
            maxHeight: '80%',
            data: {
                search: {
                    grade: grade,
                    customerContractRef: contractRef,
                    partyAccountNo: party
                }
            }
        });

        dialogRef.afterClosed().subscribe(cl => {
            if (cl && cl.contractLineId && cl.customerContractRef && cl.partyAccountNo && cl.partyName) {
                this.allocationForm.patchValue({ contractRef: cl.customerContractRef });
                this.allocationForm.markAsDirty();
                this.data.allocations.filter(a => a.isSelected).forEach(a => {
                    a.contractLineId = cl.contractLineId;
                    a.contractRef = cl.customerContractRef;
                    a.grade = cl.grade;
                    a.gradeDesc = cl.gradeDescription;
                    a.party = cl.partyAccountNo;
                    a.partyName = cl.partyName;
                    a.salesGrade = cl.salesGradeCode;
                });
            }
        });
    }

    onInstructionsKeyUp() {
        const instructionsControl = this.allocationForm.get('instructions');
        const instructionsValue = instructionsControl.value;
        this.data.allocations.filter(a => a.isSelected).forEach(a => {
            const originalInstructions = this.originalInstructionsMap.get(a.allocationNumber);
            if (instructionsValue && instructionsValue.length) {
                if (originalInstructions && this.instructionsEditMode == this.append) {
                    a.instructions = `${originalInstructions}; ${instructionsValue}`;
                } else {
                    a.instructions = instructionsValue;
                }
            } else {
                if (this.instructionsEditMode == this.append) {
                    a.instructions = originalInstructions;
                } else {
                    a.instructions = '';
                }
            }
        });
    }

    // Called upon a change to the instructions edit mode. Will set the contents of the instructions form value to
    // blank when appending or the appropriate contents when editing
    changeInstructionsEditMode() {
        let originalInstructions = '';
        const selectedAllocations = this.data.allocations.filter(a => a.isSelected);
        if (selectedAllocations.length > 0) {
            if (selectedAllocations.every(a => a.instructions == selectedAllocations[0].instructions)) {
                originalInstructions = this.originalInstructionsMap.get(selectedAllocations[0].allocationNumber);
            }
        }
        this.allocationForm.patchValue({ 'instructions': this.instructionsEditMode == this.append ? this.allocationForm.value.instructions.replace(originalInstructions, '') : originalInstructions });
        this.onInstructionsKeyUp();
        this.setMaxInstructionLength();
    }

    // Determine the maximum number of characters that the instructions form value can contain
    setMaxInstructionLength() {
        if (this.instructionsEditMode == this.append) {
            let maxSelectedInstructionLength = 0;
            this.data.allocations.filter(a => a.isSelected).forEach(a => {
                const originalInstructions = this.originalInstructionsMap.get(a.allocationNumber);
                if (originalInstructions && maxSelectedInstructionLength < originalInstructions.length) {
                    maxSelectedInstructionLength = originalInstructions.length;
                }
            });
            if (maxSelectedInstructionLength > 0) {
                this.instructionsMaxlength = Math.max(0, this.instructionsMaxlength - maxSelectedInstructionLength - 2); // Subtract two form length to allow for concatentation character applied by Finlay services
            }
        } else if (this.instructionsEditMode == this.edit) {
            this.instructionsMaxlength = this.originalInstructionsMaxlength;
        }
    }

    // Set the instructions edit mode to edit if all selected allocations have the same instruction otherwise to append
    setInstructionsEditMode() {
        const selectedAllocations = this.data.allocations.filter(a => a.isSelected);
        if (selectedAllocations.length > 0) {
            const instructionsControl = this.allocationForm.get('instructions');
            if (typeof (instructionsControl.value) == 'string') {
                const firstSelectedAllocation = selectedAllocations[0];
                if (selectedAllocations.every(a => a.instructions == firstSelectedAllocation.instructions)) {
                    this.instructionsEditMode = this.edit;
                    if (!instructionsControl.dirty && instructionsControl.value != firstSelectedAllocation.instructions) {
                        instructionsControl.patchValue(firstSelectedAllocation.instructions);
                    }
                } else {
                    this.instructionsEditMode = this.append;
                    if (!instructionsControl.dirty && instructionsControl.value != '') {
                        instructionsControl.patchValue('');
                    }
                }
            }
        }
    }
}
