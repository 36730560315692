import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { isNullOrUndefined } from '../tools';

@Pipe({
	name: 'momentLocalTime'
})
export class MomentLocalTimePipe implements PipeTransform {

	transform(value: any, args?: any): any {
		if (isNullOrUndefined(value)) { return ''; }
		moment.locale(window.navigator.language);
		const mnt = moment.utc(value);
		return mnt.format('hh:mm');
	}

}
