import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { REST_CONFIG_TOKEN, RestConfigService } from '../rest-config-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNullOrUndefined } from '../../tools';
import {
    DepotVm,
    RequestSearchFilter,
    GroupedRequestVm,
    AllocateRequestVm,
    ContractSearchFilterQueryModel,
    ContractGroupVm,
    PartyVm,
    AnalysisCodeVm,
    AllocateModel,
    UpdateAllocationsModel,
    AllocateCreateModel,
    AllocationRequestStatus,
    ApiPageResponse,
} from '../models';
import { AllocatedViewModel } from '../models/AllocatedViewModel';
import { AllocationLoadModel } from '../models/AllocationLoadModel';
import { AllocateCreateVm } from '../models/AllocateCreateVm';
import { AllocateRequestGradeValidateModel } from '../models/AllocateRequestGradeValidateModel';
import { AllocateGetPendingRequestModel } from '../models/AllocateGetPendingRequestModel';

@Injectable()
export class AllocateService {

    constructor(
        private _http: HttpClient,
        @Inject(REST_CONFIG_TOKEN) private _configService: RestConfigService
    ) {
    }

    public getRequests: (
        filter?: RequestSearchFilter
    ) => Observable<GroupedRequestVm[]>
        = (
            filter?: RequestSearchFilter
        ): Observable<GroupedRequestVm[]> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            const uri = '/api/Allocate/requests';

            return this._http
                .post<GroupedRequestVm[]>(this._configService.apiBaseUrl() + uri, JSON.stringify(filter),
                    { headers: headers, params: queryParameters });
        };

    public getContracts: (
        filter?: ContractSearchFilterQueryModel
    ) => Observable<ContractGroupVm[]>
        = (
            filter?: ContractSearchFilterQueryModel
        ): Observable<ContractGroupVm[]> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            const uri = '/api/Allocate/contracts';

            return this._http
                .post<ContractGroupVm[]>(this._configService.apiBaseUrl() + uri, JSON.stringify(filter),
                    { headers: headers, params: queryParameters });
        };

    public getPendingRequests: (
        allocateGetPendingRequestModel?: AllocateGetPendingRequestModel
    ) => Observable<AllocateRequestVm[]>
        = (
            allocateGetPendingRequestModel?: AllocateGetPendingRequestModel
        ): Observable<AllocateRequestVm[]> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });


            const uri = '/api/Allocate/pending/depot/grade';

            return this._http
                .post<AllocateRequestVm[]>(this._configService.apiBaseUrl()  + uri, JSON.stringify(allocateGetPendingRequestModel),
                    { headers: headers, params: queryParameters });
        };

    public getRequestGroup: (
        groupId?: number
    ) => Observable<AllocateRequestVm[]>
        = (
            groupId?: number
        ): Observable<AllocateRequestVm[]> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            const uri = `/api/Allocate/group/${groupId}`;

            return this._http
                .get<AllocateRequestVm[]>(this._configService.apiBaseUrl() + uri,
                    { headers: headers, params: queryParameters });
        };

    public getLifecycleStatus: (
    ) => Observable<AllocationRequestStatus[]>
        = (
        ): Observable<AllocationRequestStatus[]> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
            const uri = '/api/Allocation/allocationrequeststatuses';

            return this._http
                .get<AllocationRequestStatus[]>(this._configService.apiBaseUrl() + uri,
                    { headers: headers, params: queryParameters }).pipe(
                    map(m => m)
                );
        };

    public getContractRefs: (
        filter?: string
    ) => Observable<string[]>
        = (
            filter?: string
        ): Observable<string[]> => {
            let queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            if (!isNullOrUndefined(filter)) {
                queryParameters = queryParameters.append('filter', filter.toString());
            }

            const uri = '/api/Allocate/contractRef';

            return this._http
                .get<string[]>(this._configService.apiBaseUrl() + uri,
                    { headers: headers, params: queryParameters });
        };

    public getOrderBookTypes: (
        filter?: string
    ) => Observable<string[]>
        = (
            filter?: string
        ): Observable<string[]> => {
            let queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            if (!isNullOrUndefined(filter)) {
                queryParameters = queryParameters.append('filter', filter.toString());
            }

            const uri = '/api/Allocate/order-book-type';

            return this._http
                .get<string[]>(this._configService.apiBaseUrl() + uri,
                    { headers: headers, params: queryParameters });
        };

    public getParty: (
        filter?: string
    ) => Observable<PartyVm[]>
        = (
            filter?: string
        ): Observable<PartyVm[]> => {
            let queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            if (!isNullOrUndefined(filter)) {
                queryParameters = queryParameters.append('filter', filter.toString());
            }

            const uri = '/api/Allocate/party';

            return this._http
                .get<PartyVm[]>(this._configService.apiBaseUrl() + uri,
                    { headers: headers, params: queryParameters });
        };

    public getPartiesForAdditionalCost: (
        filter?: string
    ) => Observable<PartyVm[]>
        = (
            filter?: string
        ): Observable<PartyVm[]> => {
            let queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            if (!isNullOrUndefined(filter)) {
                queryParameters = queryParameters.append('filter', filter.toString());
            }

            const uri = '/api/Allocate/additionalcostparties';

            return this._http
                .get<PartyVm[]>(this._configService.apiBaseUrl() + uri,
                    { headers: headers, params: queryParameters });
        };

    public getDepots: (
        filter?: string
    ) => Observable<DepotVm[]>
        = (
            filter?: string
        ): Observable<DepotVm[]> => {
            let queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            if (!isNullOrUndefined(filter)) {
                queryParameters = queryParameters.append('filter', filter.toString());
            }

            const uri = '/api/Allocate/depots';

            return this._http
                .get<DepotVm[]>(this._configService.apiBaseUrl() + uri,
                    { headers: headers, params: queryParameters });
        };

    public getAnalysisCodes: (
        analysisGroupId?: number
    ) => Observable<AnalysisCodeVm[]>
        = (
            analysisGroupId?: number
        ): Observable<AnalysisCodeVm[]> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            const uri = `/api/Allocate/analysisCodes/${analysisGroupId}`;

            return this._http
                .get<AnalysisCodeVm[]>(this._configService.apiBaseUrl() + uri,
                    { headers: headers, params: queryParameters });
        };

    public allocate: (
        request?: AllocateModel
    ) => Observable<void>
        = (
            request?: AllocateModel
        ): Observable<void> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            const uri = '/api/Allocate';

            return this._http
                .post<any>(this._configService.apiBaseUrl() + uri, JSON.stringify(request),
                    { headers: headers, params: queryParameters });
        };

    public updateAllocations: (
        model?: UpdateAllocationsModel
    ) => Observable<[number, AllocatedViewModel][]>
        = (
            model?: UpdateAllocationsModel
        ): Observable<[number, AllocatedViewModel][]> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            const uri = '/api/Allocate';

            return this._http
                .put<any>(this._configService.apiBaseUrl() + uri, JSON.stringify(model),
                    { headers: headers, params: queryParameters });
        };

    public create: (
        request?: AllocateCreateModel
    ) => Observable<AllocateCreateVm>
        = (
            request?: AllocateCreateModel
        ): Observable<AllocateCreateVm> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            const uri = '/api/Allocate/create';

            return this._http
                .post<AllocateCreateVm>(this._configService.apiBaseUrl() + uri, JSON.stringify(request),
                    { headers: headers, params: queryParameters });
        };

    public getAllocation: (
        allocationId?: number
    ) => Observable<AllocateModel>
        = (
            allocationId?: number
        ): Observable<AllocateModel> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            const uri = `/api/Allocate/${allocationId}`;

            return this._http
                .get<AllocateModel>(this._configService.apiBaseUrl() + uri,
                    { headers: headers, params: queryParameters });
        };

    public updateAllocation: (
        allocationId?: number,
        allocateModel?: AllocateModel
    ) => Observable<void>
        = (
            allocationId?: number,
            allocateModel?: AllocateModel
        ): Observable<void> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });


            const uri = `/api/Allocate/${allocationId}`;

            return this._http
                .put<any>(this._configService.apiBaseUrl() + uri, JSON.stringify(allocateModel),
                    { headers: headers, params: queryParameters });
        };

    public deleteAllocations: (
        restoreRequest: boolean,
        contractLineAllocationIds: number[]
    ) => Observable<void>
        = (
            restoreRequest: boolean,
            contractLineAllocationIds: number[]
        ): Observable<void> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            const uri = `/api/Allocate/deleteallocations/${restoreRequest}`;

            return this._http
                .patch<any>(this._configService.apiBaseUrl() + uri, JSON.stringify(contractLineAllocationIds),
                    { headers: headers, params: queryParameters });
        };

    public hasAllocationRequest: (
        contractLineAllocationIds: number
    ) => Observable<boolean>
        = (
            contractLineAllocationIds: number
        ): Observable<boolean> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            const uri = `/api/Allocate/hasAllocationRequest/${contractLineAllocationIds}`;

            return this._http
                .get<any>(this._configService.apiBaseUrl() + uri,
                    { headers: headers, params: queryParameters });
        };

    public geAllocationLoads: (
        contractHeaderId: string
    ) => Observable<AllocationLoadModel[]>
        = (
            contractHeaderId: string
        ): Observable<AllocationLoadModel[]> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            const uri = `/api/Allocate/loads/${contractHeaderId}`;

            return this._http
                .get<AllocationLoadModel[]>(this._configService.apiBaseUrl() + uri,
                    { headers: headers, params: queryParameters });
        };

    public clonecreate: (
        allocationId?: number,
        allocateModel?: AllocateModel
    ) => Observable<void>
        = (
            allocationId?: number,
            allocateModel?: AllocateModel
        ): Observable<void> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            const uri = `/api/Allocate/clonecreate/${allocationId}`;

            return this._http
                .post<any>(this._configService.apiBaseUrl() + uri, JSON.stringify(allocateModel),
                    { headers: headers, params: queryParameters });
        };

    public GetContractRefsForIntructionToMove: (
        filter?: string
    ) => Observable<ApiPageResponse<AllocateModel>>
        = (
            filter?: string
        ): Observable<ApiPageResponse<AllocateModel>> => {
            let queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            if (!isNullOrUndefined(filter)) {
                queryParameters = queryParameters.append('filter', filter.toString());
            }

            const uri = '/api/Allocate/GetContractRefsForIntructionToMove';

            return this._http
                .get<ApiPageResponse<AllocateModel>>(this._configService.apiBaseUrl() + uri,
                    { headers: headers, params: queryParameters });
        };

    public GetContractRefsById: (
        contractHeaderId?: string
    ) => Observable<ApiPageResponse<AllocateModel>>
        = (
            contractHeaderId?: string
        ): Observable<ApiPageResponse<AllocateModel>> => {
            let queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            if (!isNullOrUndefined(contractHeaderId)) {
                queryParameters = queryParameters.append('filter', contractHeaderId.toString());
            }

            const uri = `/api/Allocate/GetContractRefsById/${contractHeaderId}`;

            return this._http
                .get<ApiPageResponse<AllocateModel>>(this._configService.apiBaseUrl() + uri,
                    { headers: headers, params: queryParameters });
        };

    public getValidDepoWithGrade: (
            allocateRequestGradeValidateModel?: AllocateRequestGradeValidateModel
        ) => Observable<boolean>
        = (
            allocateRequestGradeValidateModel?: AllocateRequestGradeValidateModel
        ): Observable<boolean> => {
            const queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            const uri = '/api/Allocate/depot/grade/validate';

            return this._http
                .post<boolean>(this._configService.apiBaseUrl() + uri, JSON.stringify(allocateRequestGradeValidateModel),
                    { headers: headers, params: queryParameters });

        };
}

