import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { OrderBookTypeVm } from '../../models/OrderBookTypeVm';
import { UserPreferencesService } from '../user-preferences.service';

@Injectable()
export class OrderBookTypeResolver implements Resolve<OrderBookTypeVm[]> {
	constructor(private userPreferencesService: UserPreferencesService, private router: Router) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<OrderBookTypeVm[]> {
		return this.userPreferencesService.listOfOrderBookTypes('*');
	}
}