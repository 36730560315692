import {InjectionToken} from '@angular/core';

export const REST_TOKEN_TOKEN =  new InjectionToken<string>('RestToken');


export interface RestTokenService {

    token(): string;

    }
