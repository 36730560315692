import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-single-data-capture-dialog',
	templateUrl: './single-data-capture-dialog.component.html',
	styleUrls: ['./single-data-capture-dialog.component.scss']
})
export class SingleDataCaptureDialogComponent implements OnInit {

	constructor(public dialogRef: MatDialogRef<SingleDataCaptureDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

	ngOnInit() {
	}

	onNoClick(): void {
		this.dialogRef.close();
	}
}
