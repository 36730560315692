<div class="newmodalWidth" style="width: fit-content;">
  <div class="title">
    New Inventory Reservation
  </div>
     <form id="instructionToMoveForm" name="instructionToMoveForm" [formGroup]="instructionToMoveForm" 
      (ngSubmit)="submitForm()" autocomplete="off">
      <div class="row-container top">
        <div>
          <span>Ready Date:</span>
          <mat-form-field>
            <input matInput [matDatepicker]="picker" formControlName="readyDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <span>Expiry Date:</span>
          <mat-form-field>
            <input matInput [matDatepicker]="expicker" formControlName="expiryDate">
            <mat-datepicker-toggle matSuffix [for]="expicker"></mat-datepicker-toggle>
            <mat-datepicker #expicker></mat-datepicker>
          </mat-form-field>
        </div>           
      </div>
      <div formArrayName="materials">
         <table>
          <thead>
            <th>Ready Date</th>
            <th>Expiry Date</th>
            <th>Region</th>
            <th>Depot</th>
            <th>Heap</th>
            <th>Grade</th>
            <th>Unit</th>
            <th>Weight</th>
            <th>Total Loads</th>
            <th>Container Type</th>
            <th>Packaging</th>
            <th>Status</th>
            <th>Comments</th>
            <th></th>
            <th></th>
            <th></th>
          </thead>
          <tbody>
             <tr *ngFor="let m of materials.controls; let i = index" [formGroupName]="i" autocomplete="off">
                <td>
                    <mat-form-field class="small-form-field">
                      <input matInput [matDatepicker]="picker1" formControlName="readyAt" placeholder="Ready At Date">
                      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                      <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                      
                  </td> 
                  <td>
                    <mat-form-field class="small-form-field">
                      <input matInput [matDatepicker]="picker" formControlName="expiryDate" placeholder="Expiry Date">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </td>
                <td>
                    <mat-form-field style="margin: 0px 0px; max-width: 250px;">
                        <input type="text" matInput formControlName="region" [matAutocomplete]="regionAuto"
                            placeholder="Region" #gradeInput >
                    </mat-form-field>
                    <mat-autocomplete #regionAuto="matAutocomplete" [displayWith]="displayRegion">
                        <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
                        <mat-option *ngFor="let region of filteredRegion" [value]="region">
                            {{region}}
                        </mat-option>
                    </mat-autocomplete>
                </td>
                <td>
                    <mat-form-field>
                        <input type="text" matInput [matAutocomplete]="sendingDepotAuto"
                            placeholder="Depot" #i.depot
                            [value]="i.depot" formControlName="depot"
                            matTooltip={{i.depot}}
                            >
                    </mat-form-field>
                    <mat-autocomplete [panelWidth]="400" #sendingDepotAuto="matAutocomplete"
                    [displayWith]="displayDepot">
                    
                    <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
                    
                        <mat-option *ngFor="let depot of filteredDepots?.items" [value]="depot">
                            {{depot.depotNumber + ' - ' + depot.depotName}}
                        </mat-option>
                    
                    </mat-autocomplete> 
                  </td>
              <td>
                <mat-form-field class="mid-form-field">
                    <input type="text" matInput formControlName="heap" [matAutocomplete]="heapAuto" placeholder="Heap"
                      #heapInput minlength="1">
                    <button mat-button *ngIf="m.controls.heap.value" matSuffix mat-icon-button aria-label="Clear"
                      (click)="clearHeap(heapInput, m.controls.heap)" testName="heapAutoClear">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                  <mat-autocomplete #heapAuto="matAutocomplete" [displayWith]="displayHeap">
                    <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
                    <mat-option *ngFor="let heap of filteredHeaps?.items" [value]="heap">
                      {{heap.heapShortName+ ' ' + heap.heapDesc}}</mat-option>
                  </mat-autocomplete>
              </td>
              <td>
                <mat-form-field style="margin: 0px 0px; max-width: 250px;">
                    <input type="text" matInput formControlName="grade" [matAutocomplete]="gradeAuto"
                        placeholder="Grade" #gradeInput >
                </mat-form-field>
                <mat-autocomplete #gradeAuto="matAutocomplete" [displayWith]="displayGrade">
                    <app-autocomplete-disclaimer></app-autocomplete-disclaimer>
                    <mat-option *ngFor="let grade of filteredGrade" [value]="grade">
                        {{grade}}
                    </mat-option>
                </mat-autocomplete>
              </td>
              <td>
                <mat-form-field>
                  <mat-select formControlName="uomId" placeholder="Unit" required>
                    <mat-option></mat-option>
                    <mat-option *ngFor="let opt of uoms" [value]="opt.id">{{ opt?.name }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field>
                  <input matInput formControlName="weight" placeholder="Weight" required>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field>
                  <input matInput formControlName="totalLoads" placeholder="Total Loads" required>
                </mat-form-field>
              </td>
              <td>
              <mat-form-field class="small-form-field">
                <mat-select formControlName="containerSizeId" placeholder="Container Type">
                  <mat-option></mat-option>
                  <mat-option *ngFor="let opt of pageModel?.containerSizes" [value]="opt.id">{{ opt?.displayName }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="containerSize.touched && containerSize.errors?.required">This is required!
                </mat-error>
              </mat-form-field>
              </td>

              <td>
                <mat-form-field>
                    <mat-select formControlName="packaging" placeholder="Packaging">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let opt of pageModel?.packageOptions" [value]="opt.id">{{ opt?.displayName }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="packaging.touched && packaging.errors?.required">This is required!</mat-error>
                </mat-form-field>
                </td>
                  <td>
                    <mat-form-field>
                        <mat-select formControlName="status" placeholder="Status">
                        <mat-option></mat-option>
                        <mat-option *ngFor="let opt of pageModel?.statuses" [value]="opt.id">{{ opt?.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field>
                          <textarea matInput formControlName="comments" [placeholder]="commentPlaceholder(m.controls.comments)"
                            maxlength="500" rows="1"></textarea>
                        </mat-form-field>
                      </td>
              <td>
                <button mat-button type="button" (click)="removeMaterial(i)" class="addButton button-newmodalwidth">
                  <i class="fa fa-trash"></i>
                </button>
              </td>
              <td>
                <button mat-button type="button" (click)="copyMaterial(i)" class="addButton button-newmodalwidth">
                  <i class="fa fa-copy"></i>
                </button>
              </td>
              
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td colspan="13">
                
              </td>
              <td>
                <button mat-button type="button" (click)="addMaterialRow()" class="addButton button-newmodalwidth" testName="addMaterial">
                  <i class="fa fa-plus-circle"></i>
                </button>
              </td>
            </tr> 
          </tbody>
        </table> 
      </div>
      <div mat-dialog-actions class="buttons-row">
        <button mat-flat-button type="button" (click)="dialogRef.close()">Cancel</button>
        <button mat-flat-button color="primary" type="submit"  [disabled]="isSubmit" testName="create">Create</button>
        <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
      </div>
    </form> 
</div>

