import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { REST_CONFIG_TOKEN, RestConfigService } from '../rest-config-service';
import { Observable } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import { isNullOrUndefined } from '../../tools';
import * as FileSaver from 'file-saver';
import {
    DepotVm,
    ApiPageResponse,
} from '../models';

@Injectable()
export class DepotService {

    constructor(
        private _http: HttpClient,
        @Inject(REST_CONFIG_TOKEN) private _configService: RestConfigService
    ) {
    }

    public searchDepots: (
        SearchTerm?: string
    ) => Observable<ApiPageResponse<DepotVm>>
        = (
            SearchTerm?: string
        ): Observable<ApiPageResponse<DepotVm>> => {
            let queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            if (!isNullOrUndefined(SearchTerm)) {
                queryParameters = queryParameters.append('SearchTerm', SearchTerm.toString());
            }

            const uri = '/api/depot/search';

            return this._http
                .get<ApiPageResponse<DepotVm>>(this._configService.apiBaseUrl() + uri,
                    { headers: headers, params: queryParameters });
        };

    public searchDepotsByRegion: (
        SearchTerm?: string, Region?: string
    ) => Observable<ApiPageResponse<DepotVm>>
        = (
            SearchTerm?: string, Region?: string
        ): Observable<ApiPageResponse<DepotVm>> => {
            let queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            if (!isNullOrUndefined(SearchTerm)) {
                queryParameters = queryParameters.append('SearchTerm', SearchTerm.toString());
            }
            if (!isNullOrUndefined(Region)) {
                queryParameters = queryParameters.append('SearchRegion', Region.toString());
            }

            const uri = '/api/depot/searchbyregion';

            return this._http
                .get<ApiPageResponse<DepotVm>>(this._configService.apiBaseUrl() + uri,
                    { headers: headers, params: queryParameters });
        };

    public listOfDepots: (
        SearchTerm?: string
    ) => Observable<DepotVm[]>
        = (
            SearchTerm?: string
        ): Observable<DepotVm[]> => {
            let queryParameters = new HttpParams();
            const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

            if (!isNullOrUndefined(SearchTerm)) {
                queryParameters = queryParameters.append('SearchTerm', SearchTerm.toString());
            }

            const uri = '/api/depot/list';

            return this._http
                .get<DepotVm[]>(this._configService.apiBaseUrl() + uri,
                    { headers: headers, params: queryParameters });
        };

}

