import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'app-action-confirm-dialog',
	templateUrl: './action-confirm-dialog.component.html',
	styleUrls: ['./action-confirm-dialog.component.scss']
})
export class ActionConfirmDialogComponent implements OnInit {
	title: string;
	body: string;

	constructor(
    public dialogRef: MatDialogRef<ActionConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

	ngOnInit() {
		this.title = this.data.title ? this.data.title :
			`Are you sure you want to ${this.data.action} this ${this.data.type}?`;
		this.body = this.data.body ? this.data.body :
			`Are you sure you want to ${this.data.action} this ${this.data.type}? This will permanently ${this.data.action} the selected item.`;
	}
	cancel(): void {
		this.dialogRef.close();
	}
}


