import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { AppointmentService } from '../gen/services/appointment.service';
import { NewAppointment, ImportStatus } from '../gen/models';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { windowCount } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-import-appointment-dialog',
	templateUrl: './import-appointment-dialog.component.html',
	styleUrls: ['./import-appointment-dialog.component.scss']
})

export class ImportAppointmentDialogComponent implements OnInit {
	importAppointmentForm: FormGroup;
  @ViewChild('fileInput', { static: true }) fileInput: any;
  uploadingStatus = false;
  isLoading = false;
  importStatus: ImportStatus;
  isSubmit = false;

  constructor(
    public dialogRef: MatDialogRef<ImportAppointmentDialogComponent>,
    private fb: FormBuilder,
    private appointmentService: AppointmentService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: ImportStatus,//private _configService: RestConfigService
  ) { }

  ngOnInit() {

  }
  cancel() {
  	this.dialogRef.close();
  }
  ok() {
  	if (this.fileInput.nativeElement.files.length > 0) {
  		this.isLoading = true;
  		this.isSubmit = true;  
  		const formData = new FormData();
  		formData.append('upload', this.fileInput.nativeElement.files[0]);

  		this.appointmentService.uploadExcel(this.fileInput.nativeElement.files[0]).subscribe(r => {
  			this.success('Appointments successfully imported', 'Import Appointment');
  			this.importStatus = r;
  			this.isLoading = false;
  			this.dialogRef.close(this.importStatus);
  		}, err => {
  			if (err.error != null && err.error.status === 200) {
  				this.success('Appointments successfully imported', 'Import Appointment');
  				this.isLoading = false;
  				this.dialogRef.close(this.importStatus);
  			} else {
  				this.toastr.error('Something went wrong and the request could not be imported. Please try again later.', 'Import Appointment Failed');
  				this.isLoading = false;
  				this.isSubmit = false;
  			}
  		});
  	}
  }

  private success(message: string, title: string) {
  	this.toastr.success(message, title);
  }
}
