import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { InitModel, HeapVm, ApiPageResponse, DepotVm, UomVm, InstructionToMoveVm, AllocateModel } from '../gen/models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InitService } from '../services/init.service';
import { startWith, switchMap, debounceTime } from 'rxjs/operators';
import { DepotService } from '../gen/services/depot.service';
import { isNullOrUndefined } from '../tools';
import { UomsService } from '../gen/services/uoms.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { DateAdapter } from '@angular/material/core';
import { SpinnerComponent } from '../spinner/spinner.component';
import { InstructionToMoveService } from '../gen/services/instruction-to-move.service';
import { NewInstructionToMove } from '../gen/models/NewInstructructionToMove';
import { GradeService } from '../gen/services/grade.service';
import { AllocateService } from '../gen/services/allocate.service';
import { CustomValidators } from '../shared/custom-validators';

@Component({
    selector: 'app-edit-instruction-to-move-dialog',
    templateUrl: './edit-instruction-to-move-dialog.component.html',
    styleUrls: ['./edit-instruction-to-move-dialog.component.scss']
})
export class EditInstructionToMoveDialogComponent implements OnInit {
    editInstructionToMoveForm: FormGroup;
    pageModel: InitModel;
    uoms: UomVm[];
  @ViewChild('loadingEditRequestDialogSpinner') loadingEditRequestDialogSpinner: SpinnerComponent;
  loadingEditRequestDialog = false;
  isSubmit = false;
  onHold = [{name: 'Yes', value: true},{name: 'No', value: false}];
  constructor(
    public dialogRef: MatDialogRef<EditInstructionToMoveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private initService: InitService,
    private depotsService: DepotService,
    private uomsService: UomsService,
    private toastr: ToastrService,
    private _adapter: DateAdapter<any>,
    private instructionToMoveService : InstructionToMoveService,
    public gradeService: GradeService,
    private allocateService: AllocateService) { }
  statuses = [];
  filteredDepots: ApiPageResponse<DepotVm>;
  filteredDestinationDepots: ApiPageResponse<DepotVm>;
  filteredHeaps: ApiPageResponse<HeapVm>;
  filteredContractRef: ApiPageResponse<AllocateModel>;
  disableAnimation = true;
  transportModes: string[] = ['TRUCK', 'CONTAINER', 'RAIL', 'SHIP'];

  get depot() {    return this.editInstructionToMoveForm.get('depot'); }
  get destination() { return this.editInstructionToMoveForm.get('destination'); }
  get dueFrom() { return this.editInstructionToMoveForm.get('dueFrom'); }
  get dueBy() {    return this.editInstructionToMoveForm.get('dueBy'); }
  get transportMode() {    return this.editInstructionToMoveForm.get('transportMode'); }
  get containerSize() {    return this.editInstructionToMoveForm.get('containerSizeId'); }
  get priority() { return this.editInstructionToMoveForm.get('priority'); }
  get contractHeaderId() { return this.editInstructionToMoveForm.get('contractHeaderId'); }
  get grade() {    return this.editInstructionToMoveForm.get('grade'); }
  get uomId() {    return this.editInstructionToMoveForm.get('uomId'); }
  get tonnesToMove() { return this.editInstructionToMoveForm.get('tonnesToMove'); }
  get loadsToMove() { return this.editInstructionToMoveForm.get('loadsToMove'); }
 
  get packaging() {    return this.editInstructionToMoveForm.get('packaging'); }

  get hold() { return this.editInstructionToMoveForm.get('hold'); }

  onNoClick(): void {
      this.dialogRef.close();
  }

  ngOnInit() {
      this._adapter.setLocale(window.navigator.language);

      this.editInstructionToMoveForm = this.fb.group({
          depot: ['', Validators.required],
          destination: ['', Validators.required],
          dueFrom: ['', Validators.required],
          dueBy: ['', Validators.required],
          transportMode: ['', Validators.required],
          hold: ['', Validators.required],
          priority: ['', Validators.required],
          contractHeaderId: [''],
          grade: ['', Validators.required],
          uomId: ['', Validators.required],
          tonnesToMove: ['',[Validators.required, Validators.pattern('^[0-9]{1,7}(.[0-9]+)?$')]],
          loadsToMove: ['', Validators.required],
          containerSizeId: [''],
          packaging: [''],
          loadTimes: this.fb.array([]),
          increment: ['']
      },
      {
          validators:
          [CustomValidators.dateLessThan('dueFrom', 'dueBy')]
      });

      this.initService.initData$.subscribe(r => {
          this.pageModel = r;
          this.setData();
      });

      this.uomsService.getAll().subscribe(r => {
          this.uoms = r;
          this.uomId.patchValue(this.data.uomId);
      });

      this.depot.valueChanges
          .pipe(
              debounceTime(500),
              startWith(null),
              switchMap(v => this.filterDepots(v)))
          .subscribe(r => this.filteredDepots = r);

      this.destination.valueChanges
          .pipe(
              debounceTime(500),
              startWith(null),
              switchMap(v => this.filterDepots(v)))
          .subscribe(r => this.filteredDestinationDepots = r);

      this.contractHeaderId.valueChanges
          .pipe(
              debounceTime(500),
              startWith(null),
              switchMap(v => this.filterContractRef(v)))
          .subscribe(d => this.filteredContractRef = d);
  }

  ngAfterViewInit(): void {
      // having an expansion panel inside dialog the panel animates on loading, workaround taken from https://github.com/angular/components/issues/13870
      // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
      setTimeout(() => this.disableAnimation = false);
  }

  getUom(): string {
      return 'Tonne';
  }

  setData() {
      this.dueFrom.patchValue(this.data.dueFrom);
      this.dueBy.patchValue(this.data.dueBy);
      this.transportMode.patchValue(this.data.transportMode);
      this.grade.patchValue(this.data.grade);
      this.containerSize.patchValue(this.data.containerSizeId);
      this.priority.patchValue(this.data.priority);
      this.hold.patchValue(this.data.hold);
      this.tonnesToMove.patchValue(this.data.tonnesToMove);
      this.loadsToMove.patchValue(this.data.loadsToMove);
      this.packaging.patchValue(this.data.packageOptionId);

      if (!isNullOrUndefined(this.data.depot)) {
          this.depotsService.searchDepots(this.data.depot).subscribe(r => {
              const depot = r.items[0];
              this.depot.setValue({ depotNumber: depot.depotNumber, depotName: depot.depotName });
          });
      }

      if(!isNullOrUndefined(this.data.requestId)){
          this.depot.disable();
      }
      if (!isNullOrUndefined(this.data.destination)) {
          this.depotsService.searchDepots(this.data.destination).subscribe(r => {
              const depot = r.items[0];
              this.destination.setValue({ depotNumber: depot.depotNumber, depotName: depot.depotName });
          });
      }

      if (!isNullOrUndefined(this.data.contractHeaderId)) {
          this.allocateService.GetContractRefsById(this.data.contractHeaderId).subscribe(r => {
              const depot = r[0];
              this.filteredContractRef = r;
              this.contractHeaderId.patchValue({contractRef: depot.contractRef, partyName:depot.partyName,contractHeaderId: depot.contractHeaderId});
          });
      }
  }

  cancel() {
      this.dialogRef.close();
  }

  ok() {
      const value = this.editInstructionToMoveForm.value;
      const arr = [];
      arr.push(value);
      if (this.editInstructionToMoveForm.valid) {
          const id = this.data.id;
          const model: NewInstructionToMove = {
              plannedMovements: arr.map(() => {
                  const priority = !isNullOrUndefined(value.priority) ? value.priority : null;
                  const packagingId = !isNullOrUndefined(value.packaging) ? value.packaging : null;
                  const contractHeader =!isNullOrUndefined(value.contractHeaderId.contractHeaderId) ? value.contractHeaderId.contractHeaderId : null;
                  const containerType =!isNullOrUndefined(value.containerSizeId) ? value.containerSizeId : null;
                  const mat: InstructionToMoveVm = {
                      Depot:  isNullOrUndefined(this.data.requestId) ? value.depot.depotNumber: this.data.depot,
                      Id : id,
                      Destination: value.destination.depotNumber,
                      Grade: value.grade,
                      ContractHeaderId : contractHeader,
                      TonnesToMove: value.tonnesToMove,
                      LoadsToMove: value.loadsToMove,
                      DueFrom: new Date(moment(value.dueFrom).format('YYYY-MM-DD')) ,
                      DueBy: new Date(moment(value.dueBy).format('YYYY-MM-DD')),
                      Hold:value.hold ,
                      Priority: priority ,
                      TransportMode:value.transportMode ,
                      ContainerSizeId: containerType,
                      PackageOptionId: packagingId,
                      UomId: value.uomId,
                  };
                  return mat;
              })
          };
          this.isSubmit = true;
          this.loadingEditRequestDialog = true;
          this.instructionToMoveService.updateInstructionToMove(model).subscribe(() =>{
              this.success('Instruction To Move successfully edited', 'Edit Intruction to move');
              this.closeDialog();
          }, err => {
              if (err.error != null && err.error.status === 200) {
                  this.success('Instruction To Move successfully edited', 'Edit Intruction to move');
                  this.closeDialog();
              } else {
                  this.toastr.error('Something went wrong and the Instruction To Move could not be edited. Please try again later.', 'Edit Failed');
                  this.isSubmit = false;
              }
              this.loadingEditRequestDialog = false;
          });
      } else {
          if (!isNullOrUndefined(this.editInstructionToMoveForm.errors) && !isNullOrUndefined(this.editInstructionToMoveForm.errors.unset)) {
              this.toastr.error(this.editInstructionToMoveForm.errors.unset, 'Form Invalid');
          }
          else if (!isNullOrUndefined(this.editInstructionToMoveForm.errors) && !isNullOrUndefined(this.editInstructionToMoveForm.errors.dates)) {
              this.toastr.error(this.editInstructionToMoveForm.errors.dates, 'Form Invalid');
          }
      }
  }

  ngOnDestroy(): void {
      if (this.loadingEditRequestDialogSpinner !== undefined)
          this.loadingEditRequestDialogSpinner.overlayRef.dispose();
  }

  private closeDialog() {
      setTimeout(() => {
          this.loadingEditRequestDialog = false;
          this.dialogRef.close();
      }, 1500);
  }

  private success(message: string, title: string) {
      this.toastr.success(message, title);
  }


  filterDepots(val: string) {
      return this.depotsService.searchDepots(val);
  }
  displayDepot(depot?: DepotVm): string | undefined {
      return depot ? depot.depotNumber + ' ' + depot.depotName : undefined;
  }

  filterContractRef(val: string) {
      return this.allocateService.GetContractRefsForIntructionToMove(val);
  }

  displayContractRef(contractRef?: AllocateModel): string | undefined {
      return contractRef && contractRef.contractRef ? contractRef.contractRef + ' ' + contractRef.partyName : undefined;
  }

  displayHeap(heap?: HeapVm): string | undefined {
      return heap ? heap.heapShortName + ' ' + heap.heapDesc : undefined;
  }
}
